import to from 'await-to-js'
import { AxiosError } from 'axios'

import { getDocument } from '../../api'
import { saveBusinessProtectionProducts } from '../../api/businessProtection'
import {
  addCaseProtectionProduct,
  createProtectionProduct,
  deleteCaseProtectionProduct,
  getProtectionProduct,
  updateProtectionProduct,
} from '../../api/protection_product'
import {
  checkAuthProtection,
  getRiskReality,
  saveProtectionProductsToCase,
  splitProductEstimate,
  syncProtectionDocuments,
} from '../../api_protection/methods'
import {
  ProductDocuments,
  ProtectionProduct,
  ProtectionProductUpdateInput,
  Resolvers,
  SplitCoverBenefitsInput,
  SyncProtectionDocumentsResponse,
} from '../../generated/resolvers'
import { DocumentType } from '../../main'
import { CdmGetProtectionProductRequest } from '../../service/luther/model'
import { GraphqlException } from '../util'

const ERROR_COULD_NOT_SAVE = 'Could not save. Please try again or contact support.'
const ERROR_COULD_NOT_REMOVE_RECOMMENDATION = 'Could not remove recommendation. Please try again or contact support.'

const resolvers: Resolvers = {
  Query: {
    protectionProducts: async (_, variables) => {
      const data = await getProtectionProduct(variables as CdmGetProtectionProductRequest)
      return data?.protection_products as ProtectionProduct[]
    },
    protectionProductsForClients: async (_, variables) => {
      const { protectionProductDetails, filterClientIds } = variables

      //we need to use looped fetch,because the API is designed so that
      //if you request products for both clients at once it searches for
      //products with both clients on the product
      const promises = await Promise.allSettled(
        filterClientIds.map(async (id) => {
          const res = await getProtectionProduct({
            protectionProductDetails,
            filter_client_ids: [id],
          } as CdmGetProtectionProductRequest)

          return res
        }),
      )

      //fitler out the rejected responses
      const fullfilled = promises.filter(
        (promise) => promise.status === 'fulfilled' && Object.keys(promise.value).length !== 0,
      )
      // merge the fullfilled ones
      const merged = fullfilled.flatMap((promise) => {
        if (promise.status === 'fulfilled') {
          return promise.value.protection_products
        }
      })
      // remove duplicates
      const myMap = new Map()
      merged.forEach((el) => {
        myMap.set(el?.protection_id, el)
      })
      const unique = Array.from(myMap.values())

      return unique as ProtectionProduct[]
    },
    isProtectionAuthValid: async (_, { input }) => {
      const res = await checkAuthProtection(input)
      return res?.credentials_valid
    },
    quoteSource: async (_, { input }) => {
      const p = await splitProductEstimate(input as SplitCoverBenefitsInput)

      return p.quotes
    },
  },
  Mutation: {
    saveBusinessProtectionProducts: async (_, { input }) => {
      return await saveBusinessProtectionProducts(input)
    },
    saveProtectionProductsToCase: async (_, { inputs }) => {
      return await saveProtectionProductsToCase(inputs)
    },
    saveProtectionProducts: async (_, { inputs }) => {
      return await Promise.all(
        inputs.map(async (input) => {
          const { case_ids, ...rest } = input
          const hasCaseId = case_ids && case_ids.length > 0
          if (!hasCaseId) throw new Error(ERROR_COULD_NOT_SAVE)

          // Create the protection product
          const createBody = { protection_product: { details: rest } }
          // @ts-ignore
          // The tool that generates types from Luther's swagger is making a mistake
          // despite the fact that it's pointed to integration, so we'll ignore it
          // until they've pushed to staging
          const createData = await createProtectionProduct(createBody)
          const { protection_product, exception: createException } = createData
          if (createException) throw new Error(ERROR_COULD_NOT_SAVE)

          // Associate it with the case
          const { protection_id: newId } = protection_product || {}
          const caseId = case_ids![0] // Can assert with '!' because of the return case above
          const linkBody = { case_id: caseId }
          const linkData = await addCaseProtectionProduct(newId!, linkBody)
          const { exception: linkException } = linkData
          if (linkException) throw new Error(ERROR_COULD_NOT_SAVE)

          return protection_product as ProtectionProduct
        }),
      )
    },
    removeProtectionProducts: async (_, { protection_ids, case_id }) => {
      await Promise.all(
        protection_ids.map(async (protection_id) => {
          const res = await deleteCaseProtectionProduct(protection_id, case_id)
          // Display an error message within the toast if something goes wrong
          const { exception } = res
          const exceptionKey = ERROR_COULD_NOT_REMOVE_RECOMMENDATION
          if (exception) throw new Error(exceptionKey)

          return protection_id
        }),
      )
      return protection_ids
    },

    updateProtectionProduct: async (_, { input, protection_id }) => {
      const body: ProtectionProductUpdateInput = {
        details: input,
      }
      return updateProtectionProduct(protection_id, body)
    },
    syncProtectionDocuments: async (_, { input }) => {
      const [error, data] = await to<SyncProtectionDocumentsResponse, AxiosError>(syncProtectionDocuments(input))
      //Error messages from BE are horrid and mostly returns 500s so I came up with this generic user friendly error message
      //I'm down to change it if needed
      if (error && error.response) {
        const errorMessage =
          'Documents are either not ready for download or already downloaded. Please try again later if you cannot find them in the document section or contact support.'
        throw new GraphqlException(errorMessage)
      }

      const documents: ProductDocuments = {
        application_doc_id: undefined,
        key_facts_doc_id: undefined,
        illustration_doc_id: undefined,
        policy_letter_doc_id: undefined,
      }

      const documentIds = data?.uploads && data?.uploads[0]?.document_ids
      const protectionId = data?.uploads && data.uploads[0]?.product_id

      if (documentIds) {
        await Promise.all(
          documentIds.map(async (document_id) => {
            const res = await getDocument(document_id)
            const { document } = res
            if (document?.type === DocumentType.InsuranceApplication) {
              documents.application_doc_id = document_id
            } else if (document?.type === DocumentType.InsuranceKeyFacts) {
              documents.key_facts_doc_id = document_id
            } else if (document?.type === DocumentType.InsuranceIllustration) {
              documents.illustration_doc_id = document_id
            } else if (document?.type === DocumentType.PolicyLetter) {
              documents.policy_letter_doc_id = document_id
            }
          }),
        )
      }

      const protectionProduct = {
        protection_id: protectionId,
        details: {
          documents: documents,
        },
      }

      return protectionProduct
    },
    getRiskReality: async (_, { input }) => {
      return getRiskReality(input)
    },
  },
}

export default resolvers
