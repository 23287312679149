import React, { ChangeEvent, memo, ReactNode, useCallback } from 'react'

import { useFormatMessage, YesNo, yesNoOptions } from '@acre/utils'
import { HELPER_TYPE_ERROR, HelperText, Radio, RadioGroup, Variant } from '@acre/design-system'

type Props = {
  id: string
  name: string
  label?: ReactNode
  value?: boolean | null
  onChange: (value: boolean) => void
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  opposite?: boolean
  tooltip?: string
  disabled?: boolean
  error?: boolean
  message?: string
  labelWidth?: string
}

const YesNoRadioGroup = ({
  id,
  name,
  label,
  value,
  onChange,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant = 'default',
  opposite,
  tooltip,
  disabled,
  error,
  message,
  labelWidth,
}: Props) => {
  const formatMessage = useFormatMessage()

  // We convert the boolean value into a yes/no string for use in the radio group
  let internalValue: YesNo | undefined = undefined
  if (value === true) internalValue = !opposite ? YesNo.Yes : YesNo.No
  if (value === false) internalValue = !opposite ? YesNo.No : YesNo.Yes

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value as YesNo
    if (value === YesNo.Yes) !opposite ? onChange(true) : onChange(false)
    if (value === YesNo.No) !opposite ? onChange(false) : onChange(true)
  }, [])

  return (
    <>
      <RadioGroup
        id={id}
        label={label}
        isMissing={isMissing}
        isIncomplete={isIncomplete}
        verificationError={verificationError}
        variant={variant}
        tooltip={tooltip}
        labelWidth={labelWidth}
      >
        {yesNoOptions.map((option) => {
          const label = formatMessage(option.label)
          const checked = internalValue === option.value
          return (
            <Radio
              id={`${id}${option.value}`}
              key={option.value}
              name={name}
              label={label}
              value={option.value}
              checked={checked}
              onChange={handleChange}
              variant={variant}
              error={error}
              disabled={disabled}
            />
          )
        })}
      </RadioGroup>
      {message && (
        <HelperText
          id={`${id}Helper`}
          message={message || ''}
          textType={error ? HELPER_TYPE_ERROR : ''}
          variant={variant}
        />
      )}
    </>
  )
}

export default memo(YesNoRadioGroup)
