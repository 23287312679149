import { Box } from '@mui/material'
import { styled } from '@mui/material'

export type SizeVariant = 'xlarge' | 'large' | 'medium' | 'small'

export type StackProps = { variant?: SizeVariant }

// Defining a stack with grid-row-gap allows
// not having to define Box with spacers for every
// component inside Stack (with all elements evenly spaced out at 16px)
export const Stack = styled(Box)<StackProps>(({ theme, variant }) => {
  let spacing = 2

  if (variant === 'xlarge') {
    spacing = 4
  }
  if (variant === 'large') {
    spacing = 3
  }
  if (variant === 'small') {
    spacing = 1
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(spacing),
  }
})

export default Stack
