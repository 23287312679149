import { DataProxy, MutationFunction } from '@apollo/client'
import { clone, isNumber, isPlainObject, merge, mergeWith, set, some } from 'lodash'

import { updateCachedItem } from '@acre/utils'
import {
  Case,
  CaseDetailsFlag,
  CaseDetailsFlagFlagType,
  CaseInput,
  CaseStatus,
  CaseVerification,
  GetCaseDocument,
  GetCaseQuery,
  GetClientsQuery,
  GetProtectionProductsQuery,
  Maybe,
  MiDocumentSummary,
  MortgageReason,
  omitTypename,
  ProtectionProductInput,
  RenderAndStoreDocumentMutation,
  TemplateType,
  UpdateCaseSuitabilityMutation,
  UpdateClientMutation,
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables,
  UpdateProtectionProductMutation,
} from '@acre/graphql'

import { Gate } from '../CaseOverview/TaskManagement/CaseVerifications.fixtures'
import { StructuredInlineFieldValues, SuitabilityInlineField } from './SuitabilityReport.types'

const nestedStringTransform = (transformFn: (str: string) => string) => (obj: { [key: string]: any }) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'string') {
      return { ...acc, [key]: transformFn(obj[key]) }
    }
    return { ...acc, [key]: obj[key] }
  }, {})
}

export const nl2br = (html: string) => html.replace(/\n/g, '<br>')
export const br2nl = (html: string) => html.replace(/<br>/g, '\n')

export const nestedNl2br = nestedStringTransform(nl2br)
export const nestedBr2nl = nestedStringTransform(br2nl)

const omitNull = (key: string, value: string | null) => {
  return value === null ? undefined : value
}

export const removeNullValues = (obj: any) => JSON.parse(JSON.stringify(obj), omitNull)

export const includedFieldsToInitialState = (fields: SuitabilityInlineField[]) => {
  return nestedBr2nl(
    fields.reduce((initialState, field) => {
      return {
        ...initialState,
        [`${field.name}-${field.entityId}`]: field.initialValue,
      }
    }, {}),
  )
}

/**
 * Merges two objects, recursively merging any nested objects and overwriting arrays based on index.
 * If `objectB` has a nested property that's an object with number keys, it's assumed to be an array.
 * In this case, `objectB`'s array will overwrite `objectA`'s array at the corresponding indices.
 *
 * This function uses lodash's mergeWith function internally to customize the merge behavior.
 *
 * @param {T} objectA - The original object. The types of its properties will be used for the merged object.
 * @param {Partial<T>} objectB - The object to merge with `objectA`. Its properties will overwrite `objectA`'s on conflict.
 *
 * @example
 *
 * let objectA = { a: [ 'original' ] };
 * let objectB = { a: { 0: 'new' } };
 * let result = mergeNestedObjects(objectA, objectB);
 * console.log(result);  // Output: { a: [ 'new' ] }
 */

export const mergeNestedObjects = <T>(objectA: T, objectB: Partial<T>): T => {
  return mergeWith({}, objectA, objectB, (objValue, srcValue) => {
    if (isPlainObject(srcValue) && some(Object.keys(srcValue), isNumber)) {
      return Object.keys(srcValue).reduce((arr, k) => {
        arr[Number(k)] = merge({}, arr[Number(k)], srcValue[k])
        return arr
      }, clone(objValue))
    }
  })
}

/**
 * Turns form state into structured data so that we can submit it cleanly
 * @param fields An array of SuitabilityInlineFields containing information about the fields in the report
 * @param formState The state of the form with keys in the `name-id` format
 */
export const generateStructuredFields = (
  fields: SuitabilityInlineField[],
  formState: { [key: string]: string },
): StructuredInlineFieldValues => {
  return fields.reduce(
    (acc, field) => {
      const newValue: StructuredInlineFieldValues = { ...acc }

      if (!newValue[field.entity][field.entityId]) {
        const entityFields = fields
          .filter(
            (inlineField) =>
              inlineField.entity === field.entity &&
              inlineField.entityId === field.entityId &&
              formState[`${inlineField.name}-${inlineField.entityId}`] !== undefined,
          )
          .reduce((acc, inlineField) => {
            return {
              ...acc,
              [inlineField.path || inlineField.name]: formState[`${inlineField.name}-${inlineField.entityId}`],
            }
          }, {})

        if (Object.keys(entityFields).length > 0) {
          newValue[field.entity][field.entityId] = entityFields
        }
      }

      return newValue
    },
    { client: {}, case: {}, protection_product: {}, property: {} },
  )
}

/**
 * Executes the mutation to update case fields in the SR using structured data
 * @param initialValues Initial values of the SR
 * @param entities The StructuedInlineFieldValues generated by generateStructuredFields
 * @param caseDetails Case Details in order to update protection requirements the whole requirements array needs to be passed to BE
 * @param updateCase The mutation function for UpdateCaseSuitabilityMutation
 */
export const handleUpdateCase =
  (
    initialValues: {
      [key: string]: string
    },
    entities: StructuredInlineFieldValues,
    caseDetails: Case,
    updateCase: MutationFunction<UpdateCaseSuitabilityMutation>,
  ) =>
  async (caseId: string) => {
    const caseFields = entities?.case[caseId]

    const caseInput =
      caseFields &&
      Object.keys(caseFields).reduce((acc, field) => {
        return set(acc, field, caseFields[field])
      }, {})

    const input = caseInput ? (nestedNl2br(caseInput) as CaseInput) : {}

    if (input.protection?.requirements) {
      const updatedProtection = omitTypename(
        mergeNestedObjects(caseDetails.protection?.requirements, input.protection.requirements),
      )
      input.protection.requirements = updatedProtection
    }
    const updateCasePayload = {
      variables: {
        id: caseId,
        input,
      },
    }

    await updateCase(updateCasePayload)
  }

/**
 * Executes the mutation to update protection products in the SR using the structured data
 * @param initialValues Initial values of the SR
 * @param entities The StructuedInlineFieldValues generated by generateStructuredFields
 * @param protectionProducts The protection products on the case
 * @param updateCase The mutation function for UpdateProtectionProductMutation
 */
export const handleUpdateProtection =
  (
    initialValues: {
      [key: string]: string
    },
    entities: StructuredInlineFieldValues,
    protectionProducts: GetProtectionProductsQuery,
    updateProduct: MutationFunction<UpdateProtectionProductMutation>,
  ) =>
  async (protectionProductId: string) => {
    const productFields = entities.protection_product[protectionProductId]

    const input: ProtectionProductInput = Object.keys(productFields).reduce((acc, field) => {
      return set(acc, field, productFields[field])
    }, {})

    input.case_ids = protectionProducts.protectionProducts?.find(
      (product) => product.protection_id === protectionProductId,
    )?.details.case_ids

    const updateProductPayload = {
      variables: {
        protection_id: protectionProductId,
        input: nestedNl2br(input) as ProtectionProductInput,
      },
    }

    await updateProduct(updateProductPayload)
  }

/**
 * Executes the mutation to update protection products in the SR using the structured data
 * @param clients Output of GetClients query so we can update clients (should include the data for clients being updated)
 * @param entities The StructuedInlineFieldValues generated by generateStructuredFields
 * @param updateCase The mutation function for UpdateClientMutation
 */
export const handleUpdateClients =
  (
    clients: GetClientsQuery,
    entities: StructuredInlineFieldValues,
    updateClient: MutationFunction<UpdateClientMutation>,
  ) =>
  async (clientId: string) => {
    const clientDetails = clients?.clients.find((client) => client.id === clientId)

    if (clientDetails) {
      // We have to remove null fields from income_and_employment or the submission fails
      const cleanedClientDetails = {
        ...clientDetails,
        details: {
          ...clientDetails.details,
          income_and_employment: removeNullValues(clientDetails.details.income_and_employment),
        },
      }

      // For each of the fields. use the path to update the nested object
      const { outgoings_material_change_details, income_and_employment } = Object.keys(
        entities.client[clientId],
      ).reduce(
        (clientDetails, path) => {
          return set(clientDetails, path, entities.client[clientId][path])
        },
        { ...omitTypename(cleanedClientDetails.details) },
      )

      // Do the update
      await updateClient({
        variables: {
          id: clientId,
          input: {
            outgoings_material_change_details,
            income_and_employment,
          },
        },
      })
    }
  }

/**
 * Executes the mutation to update property fields in the SR using structured data
 * @param initialValues Initial values of the SR
 * @param entities The StructuedInlineFieldValues generated by generateStructuredFields
 * @param updateCase The mutation function for UpdatePropertyMutation
 */
export const handleUpdateProperty =
  (
    initialValues: {
      [key: string]: string
    },
    entities: StructuredInlineFieldValues,
    updateCase: MutationFunction<UpdatePropertyMutation>,
  ) =>
  async (propertyId: string) => {
    const propertyFields = entities.property[propertyId]

    const input = Object.keys(propertyFields).reduce((acc, field) => {
      return set(acc, field, propertyFields[field])
    }, {})

    const updateCasePayload: { variables: UpdatePropertyMutationVariables } = {
      variables: {
        propertyId: propertyId,
        input: nestedNl2br(input),
        includeCaseDetailsInProperty: true,
      },
    }

    await updateCase(updateCasePayload)
  }

export const updateProtectionDocumentRenderCache = async (
  cache: DataProxy,
  data: RenderAndStoreDocumentMutation,
  id: string,
) => {
  const cachedCase: Maybe<GetCaseQuery> = cache.readQuery({
    query: GetCaseDocument,
    variables: { id: id },
  })
  if (cachedCase?.case) {
    updateCachedItem(cache, {
      query: GetCaseDocument,
      variables: { id: id },
      data: {
        ...cachedCase.case,
        details: {
          ...cachedCase.case.details,
          miDocuments: {
            ...(cachedCase.case.details.miDocuments || {}),
            documents: [...(cachedCase.case.details.miDocuments?.documents || []), data.renderAndStoreDocument],
          },
        },
      },
      key: 'case',
    })
  }
}

export const shouldShowPublishedSRPdf = (status?: Maybe<CaseStatus>) => {
  if (!status) return false
  return ![CaseStatus.Lead, CaseStatus.PreRecommendation, CaseStatus.Review].includes(status)
}

export const isCaseStatusWithEnabledPublishPSR = (status?: Maybe<CaseStatus>) => {
  if (!status) return false
  return [
    CaseStatus.PreRecommendation,
    CaseStatus.PreApplication,
    CaseStatus.ApplicationSubmitted,
    CaseStatus.AwaitingValuation,
    CaseStatus.AwaitingOffer,
    CaseStatus.OfferReceived,
    CaseStatus.Exchange,
  ].includes(status)
}

export const isTemplateInExistingDocs = (templateName: TemplateType, documents?: Maybe<MiDocumentSummary[]>) =>
  documents?.find((document) => document?.template_type === templateName && document.archived !== true) ? true : false

// Return a boolean which tells whether we only want to render the template (not update the status)
export const isOnlyRenderTemplateOnPublish = (
  templateType: TemplateType,
  preferenceMortgageReason?: Maybe<MortgageReason>,
) => {
  if (
    preferenceMortgageReason !== MortgageReason.ReasonProtection &&
    preferenceMortgageReason !== MortgageReason.ReasonBusinessProtection &&
    templateType === TemplateType.ProtectionSuitabilityReport
  ) {
    return true
  }

  return false
}

const protectionSrGates = [Gate.GATE_3_4_1, Gate.GATE_3_5_2, Gate.GATE_3_6_2, Gate.GATE_3_7_2]
const publishMortgageSrGate = Gate.GATE_3_7_1

export const hasPassedVerifications = (
  verificationList?: Maybe<CaseVerification>,
  flags?: Maybe<CaseDetailsFlag[]>,
  isProtection?: boolean,
) => {
  const hasProvisionallyCompetentFlag =
    Boolean(flags) && flags!.some(({ flag_type }) => flag_type === CaseDetailsFlagFlagType.FlagProvisionalCompetency)

  const { verification_collections: collections, will_need_review } = verificationList || {}
  if (!verificationList || !collections) return true

  // If checking tasks for the publish PSR modal, check that all protection tasks have passed
  // ignoring required for transition status, also check that the publish mortgage SR task is not failing
  // as this should be done prior to publishing PSR
  if (isProtection) {
    const publishMortgageSrCollection = collections?.find(
      ({ collection_id }) => collection_id === publishMortgageSrGate,
    )
    const protectionSrCollections = collections?.filter(({ collection_id }) =>
      protectionSrGates.includes(collection_id as Gate),
    )
    return (
      protectionSrCollections.every(({ passed }) => Boolean(passed)) &&
      (!publishMortgageSrCollection || publishMortgageSrCollection?.passed)
    )
  }

  const haveAllGatesPassed = collections.every((collection) => {
    if (will_need_review === true && collection?.collection_id === Gate.GATE_4_4) {
      return hasProvisionallyCompetentFlag
    }

    if (!collection || collection.passed) {
      return true
    }

    if (!collection || collection.passed || !collection.required_for_next_transition) {
      return true
    }

    return collection?.verifications?.every(({ passed, optional, verification_id }) => {
      // This verification: b55a82c8-d7e7-4266-84e2-d15c270de492, is the one that requires
      // the publishing of the SR, so restricting the publishing of SR based on the fact that
      // this verification doesn't pass does not make sense
      if (verification_id === 'b55a82c8-d7e7-4266-84e2-d15c270de492') {
        return true
      }
      return passed || (!passed && optional)
    })
  })

  return haveAllGatesPassed
}

export const protectionSROptionalFields = [
  'suitability_report_recommendation_introduction_protection',
  'additional_budget_details',
  'additional_considerations_detail',
]
