import { styled } from '@mui/material'
import { Box } from '@mui/material'

import { Variant } from '@acre/design-system'

export const Div = styled('div')`
  margin-top: ${({ theme }) => theme.spacers.size16};
  margin-bottom: ${({ theme }) => theme.spacers.size16};
  border-top: ${({ theme }) => `1px solid ${theme.colours.baseLight}`};
`

export const FieldContainer = styled(Box)<{ variant?: Variant }>`
  width: ${({ theme, variant }) =>
    variant === 'compactLeftAligned' ? '100%' : `calc(100% * (1/2) - ${theme.spacers.size4})`};
  margin-top: ${({ theme, variant }) =>
    variant === 'compactLeftAligned' ? theme.spacers.size8 : theme.spacers.size16};
  margin-bottom: ${({ theme, variant }) =>
    variant === 'compactLeftAligned' ? theme.spacers.size8 : theme.spacers.size16};
`
