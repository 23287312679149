import { addDays, format } from 'date-fns'

export type ShortcutButtonConfig = {
  messageId: string
  id: string
  date: string
}

export const isValidDay = (cleanedValue: string) => {
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 2 || !isNaN(cleaned) || !cleanedValue
}

export const isValidMonth = (cleanedValue: string) => {
  if (cleanedValue.length === 0) {
    return false
  }
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 2 || !isNaN(cleaned) || !cleanedValue || cleaned <= 12
}

export const isValidYear = (cleanedValue: string) => {
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 4 || !isNaN(cleaned) || !cleanedValue
}

export const createDateFnsDate = (day: string, month: string, year: string) => new Date(`${day}-${month}-${year}`)

export const createChangeEvent = (el: HTMLInputElement, value: string) => {
  const ev = new Event('HTMLChangeEvent')

  // Set e.target
  Object.defineProperty(ev, 'target', { value: el, writable: false })

  // Set e.target.value
  Object.defineProperty(ev.target, 'value', {
    get: function () {
      return value
    },
  })

  return ev
}

// doesn't check for valid date based on leap years and max number of days in a month
// because it would be impossible to type the date starting from the day (not year and month)
// this type of check should be done as part of yup validation in react-final-form
export const isNumberWithinParameters = (value: string | null, length: number, max?: number) => {
  if (value === null) {
    return false
  }
  const isValidNumber = !!value && !isNaN(parseInt(value, 10))
  const isCorrectLength = value.length <= length
  const isBelowMax = !max || parseInt(value, 10) <= max
  const isNumber = /^[0-9]+$/g.test(value)

  return isValidNumber && isCorrectLength && isBelowMax && isNumber
}

const today = format(new Date(), 'yyyy-MM-dd')
const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd')
const nextWeek = format(addDays(new Date(), 7), 'yyyy-MM-dd')

export const shortcutButtonsConfig: ShortcutButtonConfig[] = [
  {
    messageId: 'reminders.table.today',
    id: 'Today',
    date: today,
  },
  {
    messageId: 'reminders.table.tomorrow',
    id: 'Tomorrow',
    date: tomorrow,
  },
  {
    messageId: 'reminders.table.nextWeek',
    id: 'NextWeek',
    date: nextWeek,
  },
]
