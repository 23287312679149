import { getDocumentRequirements } from '../api'
import {} from '../api_client_portal/document'
import { DocumentRequirement, Resolvers } from '../generated/resolvers'

const DocumentRequirementResolvers: Resolvers = {
  Query: {
    documentRequirements: async (_, { filter_organisation_ids, requirement_details }) => {
      const data = await getDocumentRequirements(filter_organisation_ids, requirement_details)
      return data.document_requirements as DocumentRequirement[]
    },
  },
}

export default DocumentRequirementResolvers
