import React from 'react'
import { styled } from '@mui/material'

const TableStickyFooterStyles = styled('div')`
  border-top: 1px solid #d7dae0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: white;
  height: 50px;
  left: 0;
  width: 100%;

  & > div {
    width: 100%;
    max-width: 2000px;
    margin: 0 2rem;

    & > div {
      padding: 0 2rem;
    }
  }
`

const TableStickyFooter = ({ children }: React.PropsWithChildren<{}>) => (
  <TableStickyFooterStyles>
    <div>
      <div>{children}</div>
    </div>
  </TableStickyFooterStyles>
)

export default TableStickyFooter
