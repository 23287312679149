import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { formatRelative, Locale } from 'date-fns'
import locale from 'date-fns/locale/en-GB'
import { capitalize } from 'lodash'
import { IntlShape } from 'react-intl'

import {
  Client,
  ClientVersion,
  DocumentType,
  GetCaseDocumentsDocument,
  GetCaseDocumentsQuery,
  GetCaseDocumentsQueryVariables,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetNotesDocument,
  GetNotesQuery,
  GetNotesQueryVariables,
  GetNotificationsDocument,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  Notification,
  ResolveNotificationDetailsQuery,
  User,
} from '@acre/graphql'

import { ProductTypes } from '../../fields/Case/sharedFields'
import { FENoteType } from '../../pages/Cases/CaseDetails/CaseNotes/CaseNote.helpers'
import {
  DocumentUploadNotificationMessage,
  NewSecureMessageNotification,
  NotificationEventSourceType,
  NotificationWithTypename,
} from './notifications.types'

// We get US formatted dates by default with formatRelative, so this fixes that.
const customLocale: Locale = {
  ...locale,
  formatRelative: (token: string) => {
    if (token === 'other') return 'dd/MM/yyyy'
    return locale.formatRelative!(token)
  },
}

/**
 * Event name we listen for an push for notifications
 */
export const NOTIFICATION_EVENT_NAME = 'notifications'

/**
 * Add __Typename into notification objects so they can be cached correctly
 * @param items An array of notification items which may ot may not contain __typename
 * @returns The items with the correct __Typename attached, so they can be cached
 */
export const addTypenamesToNotifications = (items: Notification[]): NotificationWithTypename[] => {
  return items.map((item) => ({ ...item, __typename: 'Notification' }))
}

/**
 * Write a new and/or updated version of notifications into the cache
 * @param client Apollo client instance
 * @param userId The userID under whom we should write the notifications to the cache
 * @param notifications The notification items including __typename to write
 */
export const writeNotificationsToCache = (
  client: ApolloClient<NormalizedCacheObject>,
  userId: string,
  notifications: NotificationWithTypename[],
): void => {
  client.writeQuery<GetNotificationsQuery, GetNotificationsQueryVariables>({
    query: GetNotificationsDocument,
    variables: { userId },
    data: {
      notifications,
    },
  })
}

/**
 * Read the notifications list from the cache
 * @param client Apollo client instance
 * @param userId The userID for whom we should read the notifications from the cache
 * @returns The cached notification list or an empty array if the cache is empty
 */
export const readNotificationsFromCache = (
  client: ApolloClient<NormalizedCacheObject>,
  userId: string,
): Notification[] => {
  let cachedNotifications: GetNotificationsQuery['notifications'] = []

  try {
    const cachedNotificationQuery = client.readQuery<GetNotificationsQuery, GetNotificationsQueryVariables>({
      query: GetNotificationsDocument,
      variables: { userId },
    })

    if (cachedNotificationQuery) {
      cachedNotifications = cachedNotificationQuery.notifications || []
    }
  } catch (err) {
    console.warn('No notifications in the cache yet')
    cachedNotifications = []
  }

  return cachedNotifications
}

/**
 * Delete user notifications by ID or clear them completely
 * @param client Apollo client instance
 * @param userId the userID for whom we should delete notifications in the cache
 * @param ids An array of notification IDs to delete - If undefined, deletes all notificiations
 * @returns void
 */
export const deleteNotifcations = (client: ApolloClient<NormalizedCacheObject>, userId: string, ids?: string[]) => {
  const cachedNotifications = readNotificationsFromCache(client, userId)

  const prunedNotifications = Array.isArray(ids)
    ? cachedNotifications.filter((notification) => !ids.includes(notification.notification_id))
    : []

  return writeNotificationsToCache(client, userId, addTypenamesToNotifications(prunedNotifications))
}

/**
 * Get the intl message ID for the notification message depending on document ID
 * @param docType The type of document which was uploaded
 * @returns string The intl message ID to use
 */
export const getNewDocMessageIntlId = (docType: DocumentType) => {
  const messagePrefix = 'notifications.NEW_DOCUMENT'
  switch (docType) {
    case DocumentType.CreditReport:
      return `${messagePrefix}.${DocumentType.CreditReport}`
    default:
      return `${messagePrefix}.BASE`
  }
}

/**
 * Invalidate GraphQL cache for documents if a new document is uploaded to a case
 * @param client Apollo client instance
 * @param notification The notification message with type NEW_DOCUMENT
 */
export const invalidateDocumentsCache = (
  client: ApolloClient<NormalizedCacheObject>,
  notification: DocumentUploadNotificationMessage,
) => {
  // See if we have already documents notes for the relevant case.
  const existingDocs = client.readQuery<GetCaseDocumentsQuery, GetCaseDocumentsQueryVariables>({
    query: GetCaseDocumentsDocument,
    variables: { id: notification.newDocumentNotification.caseId },
  })

  if (existingDocs) {
    client.cache.modify({
      id: client.cache.identify(existingDocs.case.details),
      fields: {
        documents(documents, { DELETE }) {
          return DELETE
        },
      },
    })
  }
}

/**
 * Invalidate GraphQL cache for notes if a new note is added to the case
 * @param client Apollo client instance
 * @param notification The notification message with type NEW_SECURE_MESSAGE
 */
export const invalidateNotesCache = (
  client: ApolloClient<NormalizedCacheObject>,
  notification: NewSecureMessageNotification,
) => {
  const currentUser = client.readQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument })

  // See if we have already loaded notes for the relevant case.
  const existingNotes = client.readQuery<GetNotesQuery, GetNotesQueryVariables>({
    query: GetNotesDocument,
    variables: {
      params: {
        filter_case_id: notification.newSecureMessageNotification.caseId,
        organisation_id: currentUser?.currentUser?.organisation_id as string,
      },
    },
  })

  if (existingNotes) {
    client.cache.modify({
      id: client.cache.identify(existingNotes.getNotes),
      fields: {
        notes(notes, { DELETE }) {
          return DELETE
        },
      },
    })
  }
}

export const buildDocumentNotification = (
  notification: DocumentUploadNotificationMessage,
  intl: IntlShape,
  resolvedEntities?: ResolveNotificationDetailsQuery,
): Notification => {
  const documentType = notification.newDocumentNotification.documentType
  let caseEntity = resolvedEntities?.cases.find(
    (caseEntity) => caseEntity.id === notification.newDocumentNotification.caseId,
  )
  let sourceEntities: (
    | Pick<User, 'id' | 'first_name' | 'last_name'>
    | (Pick<ClientVersion, 'id'> & { details: Pick<Client, 'id' | 'first_name' | 'last_name'> })
  )[] =
    notification.source.sourceType === NotificationEventSourceType.CLIENT
      ? resolvedEntities?.clients || []
      : resolvedEntities?.users || []
  let sourceEntity = notification.source.id && sourceEntities.find((user) => user.id === notification.source.id)
  let clientEntity = resolvedEntities?.clients.find(
    (client) => client.id === notification.newDocumentNotification.clientIds[0],
  )

  if (sourceEntity && 'details' in sourceEntity) sourceEntity = sourceEntity.details
  if (!sourceEntity) sourceEntity = clientEntity?.details

  let caseType = ProductTypes.find((type) => type.value === caseEntity?.details.preference_mortgage_reason)

  let message =
    notification.message ||
    intl.formatMessage(
      { id: getNewDocMessageIntlId(documentType) },
      {
        caseType: caseType
          ? intl.formatMessage({ id: caseType.label })
          : caseEntity?.details.preference_mortgage_reason,
        clientName: [clientEntity?.details.first_name, clientEntity?.details.last_name].join(' '),
        userName: [sourceEntity?.first_name, sourceEntity?.last_name].join(' '),
      },
    )

  let subtext = intl.formatMessage(
    { id: 'notifications.whoAndWhen' },
    {
      who: [
        notification.source.firstName || sourceEntity?.first_name,
        notification.source.lastName || sourceEntity?.last_name,
      ].join(' '),
      when: capitalize(
        formatRelative(new Date(notification.newDocumentNotification.uploadedAt), new Date(), {
          locale: customLocale,
        }),
      ),
    },
  )

  const newNotification: Notification = {
    notification_type: notification.type,
    notification_id: notification.id,
    message,
    subtext,
    url: `/cases/${notification.newDocumentNotification.caseId}/documents`,
    created_at: notification.newDocumentNotification.uploadedAt,
    document_type: documentType,
  }

  return newNotification
}

export const buildSecureCommsNotification = (notification: NewSecureMessageNotification, intl: IntlShape) => {
  const { senderFirstName, senderLastName } = notification.newSecureMessageNotification
  const sender = [senderFirstName, senderLastName].join(' ')
  // the reason for the weird formatter is that we're making a string containing HTML, not react elements
  let message = intl.formatMessage(
    { id: `notifications.${notification.type}` },
    { sender, span: (c) => `<span>${c}</span>` },
  )

  let subtext = capitalize(
    formatRelative(new Date(notification.createdAt), new Date(), {
      locale: customLocale,
    }),
  )

  const newNotification: Notification = {
    notification_type: notification.type,
    notification_id: notification.id,
    message,
    subtext,
    url: `/cases/${notification.newSecureMessageNotification.caseId}/notes?view=${FENoteType.Messaging}`,
    created_at: notification.createdAt,
    document_type: null,
  }

  return newNotification
}
