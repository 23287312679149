import { BankAccountType, ClientBankAccount } from '@acre/graphql'
import envConfig from '@acre/config'

import { CasePropertyType } from '../pages/Properties/Property.helpers'
import { HeaderSize, Layout } from './customTypes'

export const LOGOUT_URL = envConfig.LOGOUT_URL

export const STORAGE_COOKIE = 'cookie'
export const STORAGE_LOCAL = 'localStorage'

export const FEE_TYPE_PREADVICE_FIXED = 'PREADVICE_FIXED'

export const CUSTOM = 'custom'

export const emptyAddress = {
  address1: '',
  address2: '',
  address3: '',
  posttown: '',
  postcode: '',
  country: '',
  county: '',
}

export const emptyClientAddress = {
  address: emptyAddress,
  is_current_correspondence: false,
  move_in_date: '',
  move_out_date: '',
  residential_status: '',
}

export const emptyAccount: ClientBankAccount = {
  sort_code: '',
  number: '',
  type: BankAccountType.InvalidType,
  provider_code: '',
  overdraft: '',
  number_of_months_held: NaN,
}

export const emptyVal = '-'

export const COLUMN: Layout = 'column'
export const ROW: Layout = 'row'

// Sizes

export const SMALL: HeaderSize = 'small'
export const MEDIUM: HeaderSize = 'medium'
export const LARGE: HeaderSize = 'large'

export const CLIENT_QUERY_PARAM_ID: string = 'acreClientID'

// Card icon names
export const CARD_ICON_BLUE_BANKCARD = 'blueBankCard'
export const CARD_ICON_BLUE_FOLDER = 'blueFolder'
export const CARD_ICON_GREEN_HOUSE = 'greenHouse'
export const CARD_ICON_GREY_HOUSE = 'greyHouse'
export const CARD_ICON_ORANGE_PROFILE = 'orangeProfile'
export const CARD_ICON_PURPLE_BRIEFCASE = 'purpleBriefcase'
export const CARD_ICON_GREEN_BRIEFCASE = 'greenBriefcase'
export const CARD_ICON_BLUE_MULTISTOREY = 'blueMultiStorey'
export const CARD_ICON_AQUA_BLUE_SHIELD_POUND = 'aquaBlueShieldPound'

// URLS

type PropertyUrl = {
  [key: string]: CasePropertyType | string
}

export const urlProperty: PropertyUrl = {
  new: 'new',
  client: 'client',
  case: 'case',
  targetProperty: 'preference-target-property',
  saleProperty: 'preference-related-property-sale',
}

export const userType = {
  principal: 'a43f1c0e-90f5-4c80-ad3d-b538f85435e8',
  firmManager: '90997f32-8118-4aa9-bbf8-10d6f688e999',
  advisor: '53a15527-4b5c-41a4-93ff-a2ee04005f86',
  administrator: '36c0dc91-60f4-41c3-9e86-81b6d2d19819',
  serviceAccount: 'eeebf5b7-8117-4239-8f02-2b6a293225e9',
  introducerManager: '85dc12d8-ddfe-4b2f-8a7a-cb90a6021965',
  introducer: '989c772d-d959-4339-b3b9-18f45badfa5',
  readOnlySupport: '1c4198d7-d373-4f1e-9da2-966fec6ac2ff',
  modifySupport: 'e833b23c-7730-409b-a123-023a95cd37e6',
  acreImporter: 'd66fa95e-72b5-4175-87f8-1efa823329ab',
  acre: '2fd20ae8-e0ee-44b9-bfc8-f11f4cf69ffc',
}

export const introducerType = {
  introducer: 'Introducer',
  introducerManager: 'Introducer Manager',
}
