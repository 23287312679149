import { FieldReadFunction } from '@apollo/client'

import { MiDocumentCategory, MiDocumentTotal } from '@acre/graphql'

const read: FieldReadFunction<MiDocumentCategory[]> = (_, { readField, args, toReference }) => {
  const caseId = args?.id

  const caseRef = toReference(`Case:${caseId}`)
  const totals = readField<MiDocumentTotal[]>({
    fieldName: 'document_totals',
    args: args?.filters ? { filters: args.filters } : {},
    from: caseRef,
  })

  return totals?.map(({ group_id, count }) => ({
    categoryId: group_id,
    title: group_id,
    count,
  }))
}

export default {
  read,
}
