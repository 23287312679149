// https://www.apollographql.com/docs/react/pagination/core-api/#improving-the-merge-function
const mergeWithOffset = ({ existing, incoming, offset }: { existing: any[]; incoming: any[]; offset: number }) => {
  const merged = existing ? existing.slice(0) : []
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i]
  }
  return merged
}

export default mergeWithOffset
