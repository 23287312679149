import React, { ComponentPropsWithoutRef } from 'react'
import { styled, Theme } from '@mui/material'

const SectionDivider = styled('hr')(
  ({ theme }) => `
  border: 0;
  height: 0;
  border-top: 1px solid ${theme.colours.grey};
`,
)

export const UnsetMarginWrapper = styled('div')<{
  margin: keyof Theme['spacers']
}>(
  ({ theme, margin }) => `
  margin-left: -${theme.spacers[margin]};
  margin-right: -${theme.spacers[margin]};
`,
)

export type UnsetSectionDividerProps = {
  margin?: keyof Theme['spacers']
}

export const UnsetSectionDivider = ({ margin = 'size48' }: UnsetSectionDividerProps) => (
  <UnsetMarginWrapper margin={margin}>
    <SectionDivider />
  </UnsetMarginWrapper>
)

export type SectionDividerProps = ComponentPropsWithoutRef<'hr'>

export default SectionDivider
