import { useCallback, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { Typography } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import {
  CaseStatus,
  MortgageReason,
  ProtectionProductStatus,
  TemplateType,
  useGetCaseVerificationsQuery,
} from '@acre/graphql'
import { Card, CenteredLoadingSpinner } from '@acre/design-system'

import IddModal from '../../../../Shared/IddModal'
import { requiredSuitabilityGates, requiredSuitabilityMortgageVerification } from '../../NavItems/CaseSuitability'
import { isTemplateInExistingDocs } from '../../Suitability/Suitability.helpers'
import { TasksLocation } from '../CaseOverview.helpers'
import CaseTransitions from './CaseTransitions'
import { Gate } from './CaseVerifications.fixtures'
import {
  getExceptionCollections,
  groupCollectionsToOptionalAndNonOptional,
  shouldShowPreSalesCheckTask,
  verificationComparator as comparator,
} from './CaseVerifications.helpers'
import SubmitForCaseCheckButton from './SubmitForCaseCheckButton'
import TasksCardHeader from './TasksCardHeader'
import UpdateClientAddressModal from './TransitionToCompleteTask/UpdateClientAdddressModal/UpdateClientAddressModal'
import VerificationCollectionComponent from './VerificationCollection'

type Props = {
  caseId: string
  clientIds: string[]
  caseStatus: CaseStatus
  tasksLocation?: TasksLocation
  detailSuitabilityReportTasks?: boolean
  protectionOnly?: boolean
}

type IddModalTuple = [boolean, string[] | null]

const CaseVerifications = ({ caseId, caseStatus, tasksLocation, detailSuitabilityReportTasks }: Props) => {
  const [initialDisclosureOpen, setInitialDisclosure] = useState<IddModalTuple>([false, null]) // IDD Reverification

  const {
    details: { miDocuments, preference_mortgage_reason, protection, protection_products },
  } = useCaseContext()

  const { pathname } = useLocation()
  const isProtectionRoute = pathname?.endsWith('suitability/protection')

  const [updateAddressModalOpen, setUpdateAddressModalOpen] = useState<boolean>(false)

  const { data, loading, error } = useGetCaseVerificationsQuery({
    variables: { id: caseId },
    fetchPolicy: 'cache-first',
  })

  const { has_client_accepted_protection_advice } = protection || {}

  const isCaseSidebarDrawer = tasksLocation === TasksLocation.CaseSidebarDrawer

  const handleClose = useCallback(() => {
    setUpdateAddressModalOpen(false)
  }, [])

  const collections = data?.case?.details?.verifications?.verification_collections || []

  if (!isEmpty(collections)) {
    // We want to organise our collections such that those that require action
    // appear above any that have been completed. We also want to filter out one
    // item in particular, because Luther have inadvertently left in an "Unknown" item
    //
    const collectionsSorted = [...collections].sort(comparator)

    const showCaseCheckButton = shouldShowPreSalesCheckTask(collections, caseStatus)

    // skip protection gates as protection tasks needs to show up early, we want to ignore required transition status
    const protectionGates = [Gate.GATE_3_1_3, Gate.GATE_3_4_1, Gate.GATE_3_5_2, Gate.GATE_3_6_2, Gate.GATE_3_7_2]

    // Show verifications regardless of transition requirement when viewing PSR publish modal
    let collectionsToDisplay = collectionsSorted.filter(
      (collection) =>
        (!collection.passed && collection.required_for_next_transition) ||
        (!collection.passed &&
          detailSuitabilityReportTasks &&
          isProtectionRoute &&
          protectionGates.includes(collection.collection_id as Gate)),
    )

    // Filter out the publish SR verification task if the requirements to access the SR have not been met (matches logic in CaseSuitability.tsx)
    const failedCollections = collectionsSorted?.filter((col) => !col.passed)

    const failedRequiredSuitabilityGates = failedCollections?.some((col) =>
      requiredSuitabilityGates.includes(col.collection_id),
    )

    const missingSelectedMortgage = failedCollections?.some((col) =>
      col.verifications?.some((ver) => ver.verification_id === requiredSuitabilityMortgageVerification),
    )

    const missingRecommendedProtectionProduct =
      failedCollections?.some((col) =>
        col.verifications?.some((ver) => ver.verification_id === requiredSuitabilityMortgageVerification),
      ) ||
      isEmpty(
        protection_products?.filter((product) => product.details?.status !== ProtectionProductStatus.NotProceeding),
      )

    const isProtectionOnly = [MortgageReason.ReasonProtection, MortgageReason.ReasonBusinessProtection].includes(
      preference_mortgage_reason!,
    )

    const hidePublishSrTask =
      failedRequiredSuitabilityGates ||
      missingSelectedMortgage ||
      caseStatus === CaseStatus.ImportedComplete ||
      preference_mortgage_reason === MortgageReason.ReasonGeneralInsurance

    const hidePublishProtectionSrTask =
      hidePublishSrTask ||
      missingRecommendedProtectionProduct ||
      isTemplateInExistingDocs(TemplateType.ProtectionSuitabilityReport, miDocuments?.documents) ||
      (!isProtectionOnly && caseStatus !== CaseStatus.OfferReceived) ||
      (isProtectionOnly && caseStatus !== CaseStatus.PreRecommendation)

    // Exception collections are collections which do not respect the above rules
    // so should be added using some new conditionals
    // they are collection tasks that have passed but should display some tasks nonetheless
    const exceptionCollections = getExceptionCollections(
      collectionsSorted,
      has_client_accepted_protection_advice,
      caseStatus,
      preference_mortgage_reason,
      hidePublishSrTask,
      hidePublishProtectionSrTask,
      // Need to cast it to be of this type, as gql generated type only includes a subset of fields in verifications
    ) as typeof collections

    // Hide complete and publish SR tasks if SR menu items are disabled
    if (hidePublishSrTask) {
      collectionsToDisplay = collectionsToDisplay?.filter(
        (col) => col.collection_id !== Gate.GATE_3_7_1 && col.collection_id !== Gate.GATE_3_7_2,
      )
    }

    collectionsToDisplay.push(...exceptionCollections)

    // If PSR is not yet published, Gate 8 will display a duplicate verification task as it is blocked by not having an active PSR
    if (collectionsToDisplay.find((col) => col.collection_id === 'publishProtectionSuitabilityReport')) {
      collectionsToDisplay = collectionsToDisplay.filter((col) => col.collection_id !== Gate.GATE_8)
    }

    if (isProtectionRoute && detailSuitabilityReportTasks) {
      // When viewing the SR/PSR verification modal we only want to display the relevant verifications that document
      collectionsToDisplay = collectionsToDisplay.filter(({ collection_id }) =>
        protectionGates.includes(collection_id as Gate),
      )
    } else if (detailSuitabilityReportTasks) {
      collectionsToDisplay = collectionsToDisplay.filter(
        ({ collection_id }) => !protectionGates.includes(collection_id as Gate),
      )
    }

    const { optional, nonOptional } = groupCollectionsToOptionalAndNonOptional(collectionsToDisplay)

    // Max number of tasks that are shown on the case overview should be 7
    const limitedVerifications = [...nonOptional, ...optional].slice(0, 7)

    // If all verifications have passed, we will want to show the eNBS call to action
    const isCaseComplete = caseStatus === CaseStatus.Complete

    const hasTasks = Boolean(!isCaseComplete && collectionsToDisplay.length > 0)

    const heading = hasTasks ? 'caseOverview.headings.tasks' : 'caseOverview.headings.noTasks'

    const allNonOptionalPassed =
      nonOptional.length === 0 || nonOptional.every(({ collection_id }) => collection_id === Gate.GATE_3_1_3)

    const isFTBOrHouseMoveCaseType = Boolean(
      preference_mortgage_reason === MortgageReason.ReasonFtb ||
        preference_mortgage_reason === MortgageReason.ReasonHouseMove,
    )

    const taskContent = (
      <>
        {!isCaseComplete && (
          <>
            {showCaseCheckButton && (
              <SubmitForCaseCheckButton caseId={caseId} caseStatus={caseStatus} tasksLocation={tasksLocation} />
            )}
            {/* Display case transition tasks as the first tasks in the list, if the rest of verifications are optional */}
            {allNonOptionalPassed && (
              <CaseTransitions
                caseId={caseId}
                caseStatus={caseStatus}
                preferenceMortgageReason={preference_mortgage_reason}
                tasksLocation={tasksLocation}
                onCompleteTransitionTaskSubmit={() => {
                  setUpdateAddressModalOpen(true)
                }}
              />
            )}
            {limitedVerifications.length > 0 &&
              // If there are collections to display display them
              limitedVerifications.map((collection, index) => (
                <VerificationCollectionComponent
                  triggerInitialDisclosure={(clientIds) => setInitialDisclosure([true, clientIds])}
                  key={index}
                  collection={collection}
                  showAllVerifications={limitedVerifications.length <= 5}
                  tasksLocation={tasksLocation}
                  detailSuitabilityReportTasks={detailSuitabilityReportTasks}
                />
              ))}
          </>
        )}
        {isFTBOrHouseMoveCaseType && (
          <UpdateClientAddressModal caseId={caseId} isModalOpen={updateAddressModalOpen} handleClose={handleClose} />
        )}
        <IddModal
          isOpen={initialDisclosureOpen[0]}
          clientIds={initialDisclosureOpen[1]!} // We can ! here because if [0] is true, this will be an array of IDs
          caseId={caseId}
          onClose={() => setInitialDisclosure([false, null])}
        />
      </>
    )

    return isCaseSidebarDrawer ? (
      <>
        {isCaseComplete && (
          <Typography>
            <FormattedMessage id="caseOverview.verifications.noTasksDescription" />
          </Typography>
        )}
        {taskContent}
      </>
    ) : (
      <Card id="Tasks" border={false}>
        <TasksCardHeader heading={heading} />
        {taskContent}
      </Card>
    )
  }

  const loadingOrErrorContent = (
    <>
      {loading && <CenteredLoadingSpinner />}
      {error && `${error.message}`}
    </>
  )

  return isCaseSidebarDrawer ? (
    loadingOrErrorContent
  ) : (
    <Card id="Tasks" border={false}>
      {loadingOrErrorContent}
    </Card>
  )
}

export default CaseVerifications
