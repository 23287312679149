import { useCallback, useMemo, useState } from 'react'
import useFormApiRef from '@broker-crm-hooks/useFormApiRef'
import { Box, dialogContentClasses } from '@mui/material'
import { isEmpty, isEqual } from 'lodash'
import { FormProps } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { differenceInObjects, testHandle } from '@acre/utils'
import {
  GetCaseOverviewDetailsDocument,
  GetMortgageProductDocument,
  Maybe,
  Mortgage,
  MortgageInput,
  NewFee,
  omitTypename,
  TemplateType,
  useGetFeesForCaseQuery,
  useGetMortgageProductQuery,
  useRenderAndStoreDocumentMutation,
  useUpdateCaseFeesMutation,
} from '@acre/graphql'
import { DialogForm, MasonryCell, MasonryColumn, MasonryLayout } from '@acre/design-system'

import { getMortgageProductFees } from '../../pages/MortgageProducts/MortgageProductCard/MortgageProductCard.helpers'
import { useMutateMortgage } from '../hooks'
import { removeTypeNameFromFees } from './Fees/Fees.helpers'
import FeesForm from './Fees/FeesForm'
import FeesTable from './Fees/FeesTable'
import IllustrationConfirmationModal from './IllustrationConfirmationModal'
import { IllustrationModalFormData } from './IllustrationModal.helpers'
import IllustrationModalFooter from './IllustrationModalFooter'
import useMortgageDetails from './useMortgageDetails'
import { FormSectionHeader } from './IllustrationModal.styles'

type Props = {
  caseId: string
  mortgageId: string
  onCloseHandleClick: () => void
  open?: boolean
  onDownloadIllustration?: () => void | Promise<void>
  isRecommendationAndEsis: boolean
}

const IllustrationModal = ({
  caseId,
  mortgageId,
  onCloseHandleClick,
  open = false,
  onDownloadIllustration,
  isRecommendationAndEsis,
}: Props) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const { data, loading: loadingFees } = useGetFeesForCaseQuery({
    variables: { caseId, mortgageId },
  })

  const mortgage = useMemo(
    () => data?.case?.details?.mortgages?.find(({ id }) => id === mortgageId) as Mortgage | undefined,
    [data?.case?.details?.mortgages, mortgageId],
  )

  const {
    preferredClubsLoading,
    mortgageClubCode,
    mortgageGrossProcFees,
    mortgageClubProcFees,
    noAlternativeProcFees,
    procFeeMissing,
    options,
    selectedClub,
  } = useMortgageDetails({ mortgage, isRecommendationAndEsis })

  // Display Case Fees related to particular mortgage
  const caseFees = data?.case?.details?.fees as NewFee[]

  const [updateCaseFees, { loading: caseUpdateLoading }] = useUpdateCaseFeesMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetMortgageProductDocument,
        variables: { id: caseId },
      },
    ],
  })

  const [renderAndStoreDocument, { loading: loadingDocument }] = useRenderAndStoreDocumentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetCaseOverviewDetailsDocument,
        variables: { id: caseId },
      },
    ],
  })

  const { handleUpdateMortgage, loading: updateMortgageLoading } = useMutateMortgage({ caseId })

  const { refetch: refetchMortgages } = useGetMortgageProductQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: caseId!,
    },
  })

  const { productId, version }: { productId: Maybe<string>; version: Maybe<string> } = useMemo(() => {
    const productId = mortgage?.mortgage_product?.id
    const version = mortgage?.version

    return { productId, version }
  }, [mortgage?.mortgage_product?.id, mortgage?.version])

  const mortgageProductFees = getMortgageProductFees(mortgage)

  const confirmSubmit = useCallback(
    async ({ Fees, mortgageClubCode }: IllustrationModalFormData) => {
      let updateCaseResult
      // Only update fees if they actually changed
      if ((caseFees || []).length !== Fees.length || differenceInObjects(omitTypename(caseFees), Fees).length) {
        updateCaseResult = await updateCaseFees({
          variables: {
            id: caseId,
            input: Fees,
          },
        })
      }

      // If mortgage club code changed (ie field labelled as "Select mortgage club"), then patch the mortgage with the new code
      if (productId && mortgageClubCode !== mortgage?.selected_mortgage_club_code) {
        await handleUpdateMortgage(
          {
            mortgageInput: {
              selected_mortgage_club_code: mortgageClubCode,
              version,
            } as MortgageInput,
            mortgageProductInput: {},
          },
          mortgageId,
          productId,
        )
      }

      // Render and save ESIS (but do not if update case has failed)
      if (!updateCaseResult?.errors) {
        const storedDocument = await renderAndStoreDocument({
          variables: {
            input: {
              template_name: TemplateType.Esis,
              case_ids: [caseId],
              client_ids: data?.case?.details?.client_ids || [],
              mortgage_ids: [mortgageId],
              product_ids: productId ? [productId] : [],
            },
          },
        })
        const base64 = storedDocument?.data?.renderAndStoreDocument?.data_base64
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${base64}`
        link.download = 'ESIS.pdf'
        link.click()
      }

      await refetchMortgages()
      await onDownloadIllustration?.()
    },
    [
      caseFees,
      productId,
      mortgage?.selected_mortgage_club_code,
      onDownloadIllustration,
      refetchMortgages,
      updateCaseFees,
      caseId,
      handleUpdateMortgage,
      version,
      mortgageId,
      renderAndStoreDocument,
      data?.case?.details?.client_ids,
    ],
  )

  const handleSubmit = useCallback<FormProps<IllustrationModalFormData>['onSubmit']>(
    async (values) => {
      // Initial fees have __typename by default, and this needs removing to check for equality
      const formattedInitialFees = removeTypeNameFromFees(caseFees)
      const feesHaveChanged = !isEqual(formattedInitialFees, values.Fees)

      if (feesHaveChanged) {
        onCloseHandleClick()
        setConfirmationModalOpen(true)
      } else {
        await confirmSubmit(values)
      }
    },
    [caseFees, confirmSubmit, onCloseHandleClick],
  )

  const submitting = loadingDocument || caseUpdateLoading || updateMortgageLoading || loadingFees

  const submitMessage = isRecommendationAndEsis ? 'recommend' : 'download'

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false)
  }, [])

  const formApiRef = useFormApiRef<IllustrationModalFormData>({
    config: {
      onSubmit: handleSubmit,
      initialValues: {
        Fees: caseFees ? omitTypename(caseFees) : [],
        mortgageClubCode: selectedClub || '',
      },
    },
    skip: !data?.case || !open,
  })

  const downloadButtonDisabled = useMemo(() => {
    if (!open) {
      return false
    }
    const noProcFeesOrClubCode = isEmpty(mortgageGrossProcFees) || mortgageClubCode === ''
    const isNotLenderProposed = !mortgage?.lender_proposed
    const noOptions = !options.length

    return (
      (noProcFeesOrClubCode && isNotLenderProposed) ||
      preferredClubsLoading ||
      noOptions ||
      !formApiRef.current?.getState().values.mortgageClubCode
    )
  }, [
    formApiRef,
    mortgage?.lender_proposed,
    mortgageClubCode,
    mortgageGrossProcFees,
    open,
    options.length,
    preferredClubsLoading,
  ])

  const handleConfirmedSubmit = useCallback(async () => {
    if (formApiRef.current?.getState().values) {
      await confirmSubmit(formApiRef.current?.getState().values)
    }
  }, [confirmSubmit, formApiRef])

  if (!formApiRef.current) {
    return null
  }

  return (
    <>
      <DialogForm<IllustrationModalFormData>
        actions={
          <IllustrationModalFooter
            isRecommendationAndEsis={isRecommendationAndEsis}
            submitting={submitting}
            downloadButtonDisabled={downloadButtonDisabled}
            submitMessage={submitMessage}
          />
        }
        open={open}
        onClose={onCloseHandleClick}
        maxWidth="md"
        sx={{
          [`& .${dialogContentClasses.root}`]: {
            padding: 0,
          },
        }}
        FormProps={{
          form: formApiRef.current,
          subscription: { submitting: true },
        }}
        title={<FormattedMessage id="mortgageProduct.generateEsis" />}
        onSubmit={handleSubmit}
      >
        <MasonryLayout>
          <MasonryColumn>
            <MasonryCell sx={{ px: 0, py: 4 }}>
              <Box mb={2} ml={4}>
                <FormSectionHeader data-testid={testHandle('ProductFeesHeading')}>
                  <FormattedMessage id="cases.products.options.generateIllustration.productFees" />
                </FormSectionHeader>
              </Box>
              <FeesTable mortgageProductFees={mortgageProductFees} />
            </MasonryCell>
          </MasonryColumn>
          <MasonryColumn>
            {!loadingFees && (
              <FeesForm
                initialCaseFees={caseFees || []}
                mortgageClubsDisabled={!!noAlternativeProcFees || !!procFeeMissing || preferredClubsLoading}
                preferredClubsLoading={preferredClubsLoading}
                options={options}
                mortgageClubProcFees={mortgageClubProcFees ?? undefined}
              />
            )}
          </MasonryColumn>
        </MasonryLayout>
      </DialogForm>
      {confirmationModalOpen && (
        <IllustrationConfirmationModal
          open={confirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          onSubmit={handleConfirmedSubmit}
        />
      )}
    </>
  )
}

export default IllustrationModal
