import { createContext, useContext } from 'react'

import { GateName } from '@acre/graphql'

export type PermissionContextType = {
  gates: GateName[]
}

export const PermissionContext = createContext<PermissionContextType>({ gates: [] })
export const PermissionConsumer = PermissionContext.Consumer
export const PermissionProvider = PermissionContext.Provider

export const usePermissionsContext = () => useContext<PermissionContextType>(PermissionContext)
