import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface ModalFormClasses {
  root: string
  content: string
  actions: string
}

export type ModalFormClassKey = keyof ModalFormClasses

export function getModalFormUtilityClass(slot: string) {
  return generateUtilityClass('ModalForm', slot)
}

const modalFormClasses: ModalFormClasses = generateUtilityClasses('ModalForm', ['root', 'content', 'actions'])

export default modalFormClasses
