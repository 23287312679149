import { Component, getPromise, x64hash128 } from 'fingerprintjs2'
import { GraphQLError } from 'graphql'

import { OrganisationLoader } from '../api'
import { getClientUserDocumentDetails } from '../api_client_portal'
import { Document, Maybe, Metadata, TemplateType } from '../generated/resolvers'

export const createFingerprint = async () => {
  const options = { excludes: { canvas: true } }
  const fingerprint: Component[] = await getPromise(options)
  const hash = x64hash128(fingerprint.map(getComponentValue).join(''), 31)
  return hash
}

const getComponentValue = (component: Component): Component['value'] => {
  return component.value
}

export const getNetworkPrivacyMeta = (metadata?: Metadata[] | null) => {
  return metadata?.find((m) => m.name === 'privacy.network_privacy')
}

export const shouldRenderNetworkPrivacyNotice = (networkPrivacyMeta?: Metadata) => {
  return !networkPrivacyMeta ? networkPrivacyMeta : networkPrivacyMeta.bool_value
}

export const shouldRenderNetworkPrivacyNoticeTemplate = (
  arOrgMeta?: Metadata[] | null,
  daOrgMetadata?: Metadata[] | null,
) => {
  const arNetworkPrivacyMeta = getNetworkPrivacyMeta(arOrgMeta)
  const arRenderNetworkPrivacyNotice = shouldRenderNetworkPrivacyNotice(arNetworkPrivacyMeta)

  const daNetworkPrivacyMeta = getNetworkPrivacyMeta(daOrgMetadata)
  const daRenderNetworkPrivacyNotice = shouldRenderNetworkPrivacyNotice(daNetworkPrivacyMeta)

  switch (true) {
    // if DA is undefined, return TRUE
    case daRenderNetworkPrivacyNotice === undefined:
      return true
    // if DA is true always return TRUE, despite what AR is
    case daRenderNetworkPrivacyNotice === true:
      return true
    // if DA is false but AR is true, return TRUE
    case daRenderNetworkPrivacyNotice === false && arRenderNetworkPrivacyNotice === true:
      return true
    default:
      return false
  }
}
// if there is no regulated_by, org is a DA, logic needs to be simple
// undefined and true will generate, false will not
export const shouldRenderNetworkPrivacyNoticeTemplateNoRegulatedBy = (arOrgMeta?: Metadata[] | null) => {
  const arNetworkPrivacyMeta = getNetworkPrivacyMeta(arOrgMeta)
  const arRenderNetworkPrivacyNotice = shouldRenderNetworkPrivacyNotice(arNetworkPrivacyMeta) ?? true
  return arRenderNetworkPrivacyNotice
}

export const renderNetworkPrivacyPolicy = async (
  orgIds: string[],
  renderTemplateForEachItem: (templateType: TemplateType, verify: boolean) => Promise<Document>,
) => {
  const arOrg = await OrganisationLoader.load({ id: orgIds[0], useClientApi: false })

  if (arOrg instanceof GraphQLError) {
    throw arOrg
  } else {
    let renderTemplate
    if (!arOrg.representative_of) {
      renderTemplate = shouldRenderNetworkPrivacyNoticeTemplateNoRegulatedBy(arOrg.metadata)
    } else {
      const daOrg = await OrganisationLoader.load({ id: arOrg.representative_of, useClientApi: false })

      if (daOrg instanceof GraphQLError) {
        throw daOrg
      } else {
        renderTemplate = shouldRenderNetworkPrivacyNoticeTemplate(arOrg.metadata, daOrg.metadata)
      }
    }
    if (renderTemplate) {
      return await renderTemplateForEachItem(TemplateType.NetworkPrivacyNotice, false)
    }
  }
}

export const getClientUserIddDocumentsDetails = async (documents: Maybe<Document[]>) => {
  if (!documents) {
    return []
  }

  const iddDocuments = documents?.filter((document) => {
    return document.template_type === TemplateType.Idd || document.template_type === TemplateType.ClientPortalIdd
  })

  if (!iddDocuments) {
    return []
  }

  const idds = []

  for (const iddDocument of iddDocuments) {
    const document = await getClientUserDocumentDetails(iddDocument.document_id!, { document_details: true })

    if (!document) {
      throw new GraphQLError('Unable to find document details', { extensions: { status: 404 } })
    }

    idds.push(document)
  }

  return idds
}
