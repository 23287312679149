import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { useFormatMessage, useUserContext } from '@acre/utils'
import {
  GetOrganisationsDocument,
  type GetOrganisationsQuery,
  type GetOrganisationsQueryVariables,
  type Mortgage,
} from '@acre/graphql'

import { createMortgageClubOptions } from './Fees/Fees.helpers'

export interface UseMortgageDetails {
  mortgage?: Mortgage
  isRecommendationAndEsis: boolean
}

const useMortgageDetails = ({ mortgage, isRecommendationAndEsis }: UseMortgageDetails) => {
  const formatMessage = useFormatMessage()

  const user = useUserContext()

  const preferredClubIds = useMemo(
    () => user?.organisation?.representative_of_org?.panel_management?.preferred_clubs || [],
    [user],
  )

  const { data: preferredClubs, loading: preferredClubsLoading } = useQuery<
    GetOrganisationsQuery,
    GetOrganisationsQueryVariables
  >(GetOrganisationsDocument, {
    variables: {
      ids: preferredClubIds,
    },
    skip: !preferredClubIds.length,
  })

  const mortgageCalculatedValues = mortgage?.calculated_values

  const mortgageGrossProcFees = mortgageCalculatedValues?.calculated_gross_proc_fee

  const mortgageClubProcFees = mortgageCalculatedValues?.calculated_club_proc_fees

  const selectedClub = mortgage?.selected_mortgage_club_code

  const noAlternativeProcFees =
    !mortgageCalculatedValues || (mortgageGrossProcFees && !mortgageClubProcFees)
      ? formatMessage('cases.products.options.generateIllustration.noAlternativeProcFeeOptionAvailable')
      : null

  const procFeeMissing =
    !mortgageGrossProcFees && !mortgageClubProcFees
      ? formatMessage('cases.products.options.generateIllustration.procFeeMissing')
      : null

  const defaultCub = (preferredClubs?.organisations || []).find(
    (club) => club.id === user?.organisation?.panel_management?.default_club,
  )

  const defaultClubCode = defaultCub?.metadata?.find((meta) => meta.name === 'mortgage_club_code')?.string_value

  const preferredClubMortgageClubCodes = useMemo(() => {
    const mortgageClubCodes = mortgageClubProcFees?.map((fee) => fee.mortgage_club_code)

    const initialValue =
      isRecommendationAndEsis && selectedClub && !mortgageClubCodes?.includes(selectedClub) ? [selectedClub] : []

    if (preferredClubs && preferredClubs.organisations) {
      return preferredClubs.organisations.reduce((acc, organisation) => {
        const mortgageClubMeta = organisation?.metadata?.find((meta) => meta.name === 'mortgage_club_code')

        if (mortgageClubMeta && mortgageClubMeta.string_value) {
          return [...acc, mortgageClubMeta.string_value]
        }

        return acc
      }, initialValue)
    }

    return initialValue
  }, [isRecommendationAndEsis, mortgageClubProcFees, preferredClubs, selectedClub])

  const options = useMemo(() => {
    return createMortgageClubOptions(mortgageClubProcFees, preferredClubMortgageClubCodes)
  }, [mortgageClubProcFees, preferredClubMortgageClubCodes])

  const mortgageClubCode = useMemo(() => {
    if (defaultClubCode && options.map((option) => option?.value).includes(defaultClubCode)) {
      return defaultClubCode
    } else if (options && options.length) {
      return options[0]?.value
    }
  }, [defaultClubCode, options])

  return {
    preferredClubsLoading,
    mortgageClubCode,
    mortgageGrossProcFees,
    mortgageClubProcFees,
    noAlternativeProcFees,
    procFeeMissing,
    options,
    selectedClub,
  }
}

export default useMortgageDetails
