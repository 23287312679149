import { FieldMergeFunction } from '@apollo/client'

import { ClientVersion } from '@acre/graphql'

const merge: FieldMergeFunction<ClientVersion[]> = (existing, incoming) => {
  return incoming
}

export default {
  merge,
}
