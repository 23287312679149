import { useMemo, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { getUserNameByUserId } from '@broker-crm-utils'
import { Box, Typography, useTheme } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

import { useFormatMessage } from '@acre/utils'
import { Maybe, Note, useGetNotesQuery, useGetUsersByIdLazyQuery, User } from '@acre/graphql'
import { ButtonNewest, CenteredLoadingSpinner, ShowHideTextBox } from '@acre/design-system'

import { dateFormatter } from '../../../../sections/ReviewMode/FactFind/DataDrivenComponent'
import { getFilteredPageNotes, messagePrefix, noteBodyFormatter, sortPageNotesByTime } from '../PageNotes.helpers'

type Props = {
  orgIdForCaseNotes: string
  factFindSection: string
}

const ExistingPageNotes = ({ orgIdForCaseNotes, factFindSection }: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()

  const [displayAllNotes, setDisplayAllNotes] = useState(false)
  const [users, setUsers] = useState<Maybe<User[]>>(null)

  const { propertyId, clientId } = useParams<{ caseId: string; propertyId: string; clientId?: string }>()

  const { id: caseId } = useCaseContext()

  // Get notes' creators by user IDs linked to notes
  const [getUsers, { loading: usersLoading }] = useGetUsersByIdLazyQuery({
    onCompleted: (data) => setUsers(data?.users),
  })

  const { data, loading: notesLoading } = useGetNotesQuery({
    skip: !orgIdForCaseNotes,
    variables: {
      params: {
        organisation_id: orgIdForCaseNotes!,
        filter_case_id: caseId,
      },
    },
    onCompleted: (data) => {
      const user_ids = data?.getNotes?.notes?.map((note: Note) => note.created_by as string)

      if (user_ids?.length) {
        getUsers({ variables: { userIds: user_ids } })
      }
    },
  })

  const notes = useMemo(() => data?.getNotes?.notes || [], [data])

  const filteredPageNotes = getFilteredPageNotes(notes, factFindSection, clientId, propertyId)
  const sortedPageNotes = sortPageNotesByTime(filteredPageNotes)
  const displayedPageNotes = displayAllNotes ? sortedPageNotes : sortedPageNotes.slice(0, 2)

  if (usersLoading || notesLoading) {
    return <CenteredLoadingSpinner />
  }

  if (isEmpty(filteredPageNotes)) {
    return (
      <Box mt={3}>
        <Typography fontSize={theme.typography.pxToRem(14)}>{formatMessage(`${messagePrefix}.noPageNotes`)}</Typography>
      </Box>
    )
  }

  return (
    <>
      {displayedPageNotes.map((note) => (
        <Box key={note.note_id} mt={3} display="flex" flexDirection="column" gap={1}>
          <Typography
            component="span"
            fontSize={theme.typography.pxToRem(12)}
            lineHeight={1.6}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 5,
              whiteSpace: 'normal',
            }}
          >
            <ShowHideTextBox
              // maxHeight by default in pixels
              textBoxMaxHeight={110}
              showMoreText={formatMessage('generic.showMore')}
              showLessText={formatMessage('generic.showLess')}
            >
              {noteBodyFormatter(note.body || '')}
            </ShowHideTextBox>
          </Typography>
          <Typography fontSize={theme.typography.pxToRem(12)} color={theme.colours.new.grey2}>
            {dateFormatter(note.created_at)} {formatMessage('generic.by')} {getUserNameByUserId(users, note.created_by)}
          </Typography>
        </Box>
      ))}
      {filteredPageNotes.length > 2 && (
        <ButtonNewest variant="text" sx={{ marginTop: 3 }} onClick={() => setDisplayAllNotes(!displayAllNotes)}>
          <FormattedMessage
            id={displayAllNotes ? `${messagePrefix}.seeLessNotes` : `${messagePrefix}.seeAllNotes`}
            values={{ length: filteredPageNotes.length - 2 }}
          />
        </ButtonNewest>
      )}
    </>
  )
}

export default ExistingPageNotes
