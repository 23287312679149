import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface MenuItemClasses {
  root: string

  danger: string
}

export type MenuItemClassKey = keyof MenuItemClasses

export function getMenuItemUtilityClass(slot: string) {
  return generateUtilityClass('MenuItem', slot)
}

const menuItemClasses: MenuItemClasses = generateUtilityClasses('MenuItem', ['root', 'danger'])

export default menuItemClasses
