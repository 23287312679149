import { useEffect, useMemo, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { omit } from 'lodash'

import { useGetClientProtectedFields } from '@acre/utils'
import { ClientVersion, GateName, isNaturalClient } from '@acre/graphql'

import { formatAddress } from '../../../Introducers/IntroducersTable.helpers'
import { formatName, InfoList, InfoListProps } from './ChaseInfoModal.helpers'

type Props = {
  heading: InfoListProps['heading']
  client: ClientVersion
}

const ChaseInfoClientInfo = ({ heading, client }: Props) => {
  const caseContext = useCaseContext()

  const address = isNaturalClient(client.details)
    ? client.details.addresses?.find((address) => address.is_current_correspondence)?.address
    : client.details.addresses?.[0]?.address

  const [clientDob, setClientDob] = useState(client.details.date_of_birth)

  const { fetchDob } = useGetClientProtectedFields(client.id)

  const displayProtectedFields = useMemo(
    () => caseContext?.permissions?.includes(GateName.CLIENT_VIEW_PROTECTED),
    [caseContext?.permissions],
  )

  useEffect(() => {
    const getProtectedDob = async () => {
      const result = await fetchDob()
      setClientDob(result)
    }
    if (displayProtectedFields) {
      getProtectedDob()
    }
  }, [displayProtectedFields, fetchDob])

  const items = isNaturalClient(client.details)
    ? [
        {
          label: 'cases.chaseInfo.name',
          value: formatName(client.details),
        },
        {
          label: 'cases.chaseInfo.dob',
          value: clientDob,
        },
        {
          label: 'cases.chaseInfo.currentAddress',
          value: address ? formatAddress(omit(address, '__typename')) : null,
        },
      ]
    : [
        {
          label: 'cases.chaseInfo.companyName',
          value: client.details.organisation_name,
        },
        {
          label: 'cases.chaseInfo.companyAddress',
          value: address ? formatAddress(omit(address, '__typename')) : null,
        },
        {
          label: 'cases.chaseInfo.sicCode',
          value: client.details.organisation_sic,
        },
      ]

  return <InfoList heading={heading} items={items} />
}

export default ChaseInfoClientInfo
