import { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { isEmpty, isEqual, pick } from 'lodash'
import { useParams } from 'react-router-dom'

import { differenceInObjects } from '@acre/utils'
import {
  CaseStatus,
  CreateMortgageDocument,
  CreateMortgageMutation,
  DeleteMortgageDocument,
  DeleteMortgageMutation,
  DeleteMortgageMutationVariables,
  DeleteMortgagesDocument,
  DeleteMortgagesMutation,
  DeleteMortgagesMutationVariables,
  GetCasePreferencesDocument,
  GetCaseVerificationsDocument,
  GetMortgageProductDocument,
  GetPropertyDocument,
  Mortgage,
  UpdateMortgageAndMortgageProductDocument,
  UpdateMortgageAndMortgageProductMutation,
  UpdateMortgageAndMortgageProductMutationVariables,
  UpdateMortgagesDocument,
  UpdateMortgagesInput,
} from '@acre/graphql'

import { useCaseContext } from '../../../contexts/CaseContext'
import { MortgageState, stateToInput } from '../../../forms/MortgageFormHelpers/MortgageForm.helpers'
import { updateMortgageCacheDelete } from '../../../graphql/cache/mortgage'
import { MortgageFnArgs } from './useMutateMortgage.types'

export const useMutateMortgage = ({ caseId }: { caseId?: string }) => {
  const [loading, setLoading] = useState(false)
  const [mortgageToEdit, setMortgageToEdit] = useState<Mortgage>()
  const { details: caseDetails } = useCaseContext()
  const isLeadCase = caseDetails?.status === CaseStatus.Lead
  const { propertyId } = useParams<{ propertyId: string }>()

  const updateMortgageRefetchQueries = !isLeadCase
    ? [
        {
          query: GetCaseVerificationsDocument,
          variables: { id: caseId },
        },
      ]
    : undefined

  if (!isLeadCase && propertyId) {
    updateMortgageRefetchQueries?.push({
      query: GetPropertyDocument,
      variables: { id: propertyId },
    })
  }

  const [updateMortgages, { loading: loadingUpdateMortgages }] = useMutation(UpdateMortgagesDocument, {
    refetchQueries: updateMortgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const createMortgageRefetchQueries = !isLeadCase
    ? [
        {
          query: GetMortgageProductDocument,
          variables: { id: caseId },
        },
        {
          query: GetCasePreferencesDocument,
          variables: { id: caseId },
        },
      ]
    : [
        {
          query: GetMortgageProductDocument,
          variables: { id: caseId },
        },
      ]

  if (!isLeadCase && propertyId) {
    createMortgageRefetchQueries?.push({
      query: GetPropertyDocument,
      variables: { id: propertyId },
    })
  }

  const [createMortgage, { loading: loadingCreateMortgage, data }] = useMutation<CreateMortgageMutation>(
    CreateMortgageDocument,
    {
      refetchQueries: createMortgageRefetchQueries,
      awaitRefetchQueries: true,
    },
  )

  const deleteMorgageRefetchQueries = propertyId
    ? [{ query: GetPropertyDocument, variables: { id: propertyId } }]
    : undefined

  const [deleteMortgage, { loading: loadingDeleteMortgage }] = useMutation<
    DeleteMortgageMutation,
    DeleteMortgageMutationVariables
  >(DeleteMortgageDocument, {
    update: (cache, { data }) => {
      if (data) {
        return updateMortgageCacheDelete(cache, data)
      }
    },
    refetchQueries: deleteMorgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const [deleteMortgages, { loading: loadingDeleteMortgages }] = useMutation<
    DeleteMortgagesMutation,
    DeleteMortgagesMutationVariables
  >(DeleteMortgagesDocument, {
    refetchQueries: deleteMorgageRefetchQueries,
    awaitRefetchQueries: true,
  })

  const [updateMortgageAndMortgageProduct, { loading: loadingUpdateMortgageAndMortgageProduct }] = useMutation<
    UpdateMortgageAndMortgageProductMutation,
    UpdateMortgageAndMortgageProductMutationVariables
  >(UpdateMortgageAndMortgageProductDocument, {
    refetchQueries: !isLeadCase
      ? [
          { query: GetCaseVerificationsDocument, variables: { id: caseId }, fetchPolicy: 'network-only' },
          {
            query: GetMortgageProductDocument,
            variables: { id: caseId },
            fetchPolicy: 'network-only',
          },
        ]
      : undefined,
    awaitRefetchQueries: true,
  })

  const handleUpdateMortgage = async (
    mortgage: MortgageFnArgs,
    mortgageId: string,
    mortgageProductId?: string,
    mortgageInitialValues?: MortgageFnArgs,
    savedProductId?: string,
  ) => {
    // Ensure that the mortgage product doesn't get updated when the mortgage is sourced (and not lender proposed)
    // as patching any fields on mortgage product should not be possible for a sourced product (all mortgage product fields
    // are disabled in the UI).
    const isSourcedMortgage =
      Boolean(mortgage.mortgageInput.raw_results_reference) && !mortgage.mortgageInput.lender_proposed

    // retrieve the keys from the mortgage product object
    const mortgageProductKeys = Object.keys(mortgage.mortgageProductInput)
    const mortgageKeys = Object.keys(mortgage.mortgageInput)

    const mortgageInitialVals = mortgageInitialValues?.mortgageInput
    const mortgageProductInitialVals = mortgageInitialValues?.mortgageProductInput

    // to prevent unneccessary calls, we check diff between initial and new values to see which mortgages should be updated
    const shouldUpdateMortgageProduct = () => {
      if (isSourcedMortgage) {
        return false
      }
      const doesMortgageProductExist = !isEmpty(mortgage.mortgageProductInput)
      if (!doesMortgageProductExist) return false
      else if (doesMortgageProductExist) {
        return !isEqual(pick(mortgageProductInitialVals, mortgageProductKeys), mortgage.mortgageProductInput)
      }
      return true
    }

    const shouldUpdateMortgage = () => {
      const doesMortgageExist = !isEmpty(mortgage.mortgageInput)
      if (!doesMortgageExist) return false
      else if (doesMortgageExist) {
        return !isEqual(pick(mortgageInitialVals, mortgageKeys), mortgage.mortgageInput)
      }
      return true
    }

    const _shouldUpdateMortgageProduct = shouldUpdateMortgageProduct()

    let net_proc_fee_percentage = mortgage?.mortgageProductInput?.net_proc_fee_percentage

    if (_shouldUpdateMortgageProduct) {
      // Ensure that net_proc_fee_percentage is rounded to the nearest whole number
      net_proc_fee_percentage = net_proc_fee_percentage ? Math.round(net_proc_fee_percentage) : net_proc_fee_percentage
    }

    return await updateMortgageAndMortgageProduct({
      variables: {
        shouldUpdateMortgage: shouldUpdateMortgage(),
        shouldUpdateMortgageProduct: _shouldUpdateMortgageProduct,
        mortgageProductIdToDelete: savedProductId,
        mortgageId,
        mortgageProductId,
        mortgageInput: differenceInObjects(mortgage.mortgageInput, mortgageInitialVals || {}),
        mortgageProductInput: _shouldUpdateMortgageProduct
          ? { ...mortgage?.mortgageProductInput, net_proc_fee_percentage: net_proc_fee_percentage }
          : undefined,
      },
    })
  }

  const handleUpdateMortgages = async (mortgages: MortgageState[], initialValuesMortgages: MortgageState[]) => {
    const mortgageInputs = mortgages?.reduce<UpdateMortgagesInput[]>((acc, mortgage, index) => {
      if (!isEmpty(initialValuesMortgages?.[index]) && !isEqual(mortgage, initialValuesMortgages?.[index])) {
        const { mortgageInput, mortgageProductInput } = stateToInput(mortgage)
        const mortgageId = mortgage.mortgage_id
        const mortgageProductId = mortgage.mortgage_product_id

        if (mortgageId && mortgageProductId) {
          const input: UpdateMortgagesInput = {
            mortgageId,
            mortgageProductId,
            mortgageInput,
            mortgageProductInput,
          }
          return [...acc, input]
        }
      }

      return acc
    }, [])

    if (!isEmpty(mortgageInputs)) {
      await updateMortgages({ variables: { input: mortgageInputs } })
    }
  }

  const handleCreateMortgage = useCallback(
    async (clientIds: string[], caseId: string, mortgage: MortgageFnArgs) => {
      return await createMortgage({
        variables: {
          clientIds,
          caseId,
          ...mortgage,
        },
      })
    },
    [createMortgage],
  )

  const handleDeleteMortgage = useCallback(
    async (caseId: string, mortgageId: string, isPropertyPortfolio?: boolean) => {
      return await deleteMortgage({
        variables: { caseId, mortgageId, isPropertyPortfolio },
      })
    },
    [deleteMortgage],
  )

  const handleDeleteMortgages = useCallback(
    async (caseId: string, mortgages: Mortgage[], isPropertyPortfolio?: boolean) => {
      if (!isEmpty(mortgages)) {
        const mortgageIds = mortgages.map((mortgage) => mortgage.id)
        return await deleteMortgages({
          variables: { caseId, mortgageIds, isPropertyPortfolio },
        })
      }
    },
    [deleteMortgages],
  )

  useEffect(() => {
    setLoading(
      loadingCreateMortgage ||
        loadingDeleteMortgage ||
        loadingUpdateMortgages ||
        loadingUpdateMortgageAndMortgageProduct ||
        loadingDeleteMortgages,
    )
  }, [
    loadingCreateMortgage,
    loadingDeleteMortgage,
    loadingUpdateMortgages,
    loadingUpdateMortgageAndMortgageProduct,
    loadingDeleteMortgages,
  ])

  return {
    createMortgage,
    handleCreateMortgage,
    handleDeleteMortgage,
    handleUpdateMortgage,
    handleUpdateMortgages,
    handleDeleteMortgages,
    loading,
    mortgageToEdit,
    setMortgageToEdit,
    createdMortgage: data?.createMortgage,
  }
}
