import { FieldReadFunction } from '@apollo/client'
import { parseISO } from 'date-fns'

import {
  Client,
  DocumentCaseStatus,
  LutherAddress,
  MiDocumentCategory,
  MiDocumentTotal,
  MortgageReason,
  Property,
  PropertyVersion,
} from '@acre/graphql'

const read: FieldReadFunction<MiDocumentCategory[]> = (_, { readField, args, toReference }) => {
  const id = args?.id
  const version = args?.version

  if (!id || !version) {
    return
  }

  const clientVersionRef = toReference({ __typename: 'ClientVersion', id, version })

  const clientRef = readField<Client>('details', clientVersionRef)

  const totals = readField<MiDocumentTotal[]>({
    fieldName: 'document_totals',
    args: args?.filters ? { filters: args.filters } : {},
    from: clientRef,
  })

  const categoriesByCase: MiDocumentCategory[] = []
  let hasUnassigned = false

  totals?.forEach((total) => {
    const count = total.count || 0
    const caseId = total.group_id

    if (caseId) {
      const caseRef = toReference(`Case:${caseId}`)

      const propertyVersionRef = readField<PropertyVersion>('new_property', caseRef)
      const propertyRef = readField<Property>('details', propertyVersionRef)

      const date = readField<string>('created_at', caseRef)
      const type = readField<MortgageReason>('preference_mortgage_reason', caseRef)
      const addressRef = readField<LutherAddress>('address', propertyRef)
      const street = readField<string>('address1', addressRef) || ''

      const year = date ? parseISO(date).getFullYear() : ''

      categoriesByCase.push({
        categoryId: caseId,
        title: `${year} ${type}`,
        subTitle: street,
        count,
      })
    } else {
      // Handle unassigned documents (no case)
      hasUnassigned = true

      categoriesByCase.unshift({
        categoryId: DocumentCaseStatus.Unassigned,
        title: DocumentCaseStatus.Unassigned,
        subTitle: null,
        count,
      })
    }
  })

  const categriesToSort = hasUnassigned ? categoriesByCase.slice(1) : categoriesByCase

  const categoriesSortedByDate = categriesToSort.sort((a, b) => {
    const caseADate = readField('created_at', toReference(`Case:${a.categoryId}`)) || 0
    const caseBDate = readField('created_at', toReference(`Case:${b.categoryId}`)) || 1

    return caseADate >= caseBDate ? -1 : 1
  })

  return hasUnassigned ? categoriesByCase.slice(0, 1).concat(categoriesSortedByDate) : categoriesSortedByDate
}

export default {
  read,
}
