import React from 'react'
import { styled, Typography, TypographyProps, unstable_composeClasses } from '@mui/material'

import { getGreyTextUtilityClass } from './greyTextClasses'

export interface GreyTextProps extends TypographyProps {
  children?: React.ReactNode
}

const GreyTextRoot = styled(Typography, {
  name: 'GreyText',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.colours.baseMid,
}))

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getGreyTextUtilityClass, undefined)
}

const GreyText = (props: GreyTextProps) => {
  const { ...rootProps } = props

  const slotClasses = useUtilityClasses()

  return <GreyTextRoot {...rootProps} className={slotClasses.root} />
}

export default React.memo(GreyText) as typeof GreyText
