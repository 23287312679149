import { useContext, useMemo } from 'react'

import { UserContext } from '@acre/utils'

export enum NetworkStatus {
  DirectlyAppointed = 'da',
  AuthorisedRepresentative = 'ar',
}

export default () => {
  const user = useContext(UserContext)

  const isDa = useMemo(() => {
    if (user) {
      return user.organisation?.representative_of_org?.id === user?.organisation?.id
    }
    return false
  }, [user])

  return isDa ? NetworkStatus.DirectlyAppointed : NetworkStatus.AuthorisedRepresentative
}
