import { styled } from '@mui/material'
import { rem, transparentize } from 'polished'

export const FilterButtonContainer = styled('div')`
  display: inline-block;
  width: 100%;
`

export const FilterButton = styled('button')<{ highlight?: boolean }>`
  appearance: none;
  border-width: 0;
  border-radius: ${({ theme }) => theme.display.borderRadius};
  background-color: ${({ theme, highlight }) =>
    highlight ? transparentize(theme.opacity.op90, theme.colours.primary) : theme.colours.foreground};
  color: ${({ theme }) => theme.colours.primary};
  font-size: 14px;
  line-height: 1.6;
  padding: ${rem(8)} ${({ theme }) => theme.spacers.size12};

  &:hover {
    background-color: ${({ theme }) => transparentize(theme.opacity.op90, theme.colours.primary)};
  }
`
