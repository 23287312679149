/**
 * TO BE DEPRICATED: improt from utils workspace
 */
import { useEffect, useState } from 'react'

export type ResponsiveLayout = 'vertical' | 'horizontal'
export const DEFAULT_BREAKPOINT = 1200
export const LAYOUT_VERTICAL = 'vertical'
export const LAYOUT_HORIZONTAL = 'horizontal'

export const useResponsiveLayout = (breakpoint: number = DEFAULT_BREAKPOINT): ResponsiveLayout => {
  const [layout, setLayout] = useState(window.innerWidth < breakpoint ? LAYOUT_VERTICAL : LAYOUT_HORIZONTAL)

  useEffect(() => {
    // MatchMedia only fires an event when query starts or stops matching,
    // so it is much more efficient than a handler on window.resize
    const query = window.matchMedia(`only screen and (max-width: ${breakpoint}px)`)

    const onQueryChange = (query: MediaQueryListEvent) => {
      if (query.matches) {
        setLayout(LAYOUT_VERTICAL)
      } else {
        setLayout(LAYOUT_HORIZONTAL)
      }
    }

    query.addEventListener('change', onQueryChange)

    return () => query.removeEventListener('change', onQueryChange)
  }, [])

  return layout as ResponsiveLayout
}
