import { FieldMetaState, useField } from 'react-final-form'

export type ShowErrorFunc = (props: ShowErrorProps) => boolean

export interface ShowErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldMetaState<any>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Validator = (value: any) => { message: string } | string | undefined

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
}

export const useFieldForErrors = (name: string) => useField(name, config)

export const showErrorOnChange: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (modified ||
      (touched &&
        (error.type === 'optionality' || error.type === 'required' || error.message === 'errors.missingFieldRequired')))
  )

export const showErrorOnBlur: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched },
}: ShowErrorProps) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    touched &&
    (error.type === 'required' || error.message === 'errors.missingFieldRequired')
  )

export const composeValidators =
  (...validators: Validator[]) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined as { message: string } | string | undefined,
    )
