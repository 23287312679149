// @types/feather-icons doesn't appear to include icon names, so here's
// a declaration to help with intellisense when this package is used

export enum IconName {
  Activity = 'activity',
  Airplay = 'airplay',
  AlertCircle = 'alert-circle',
  AlertOctagon = 'alert-octagon',
  AlertTriangle = 'alert-triangle',
  AlignCenter = 'align-center',
  AlignJustify = 'align-justify',
  AlignLeft = 'align-left',
  AlignRight = 'align-right',
  Anchor = 'anchor',
  Aperture = 'aperture',
  Archive = 'archive',
  ArrowDownCircle = 'arrow-down-circle',
  ArrowDownLeft = 'arrow-down-left',
  ArrowDownRight = 'arrow-down-right',
  ArrowDown = 'arrow-down',
  ArrowLeftCircle = 'arrow-left-circle',
  ArrowLeft = 'arrow-left',
  ArrowRightCircle = 'arrow-right-circle',
  ArrowRight = 'arrow-right',
  ArrowUpCircle = 'arrow-up-circle',
  ArrowUpLeft = 'arrow-up-left',
  ArrowUpRight = 'arrow-up-right',
  ArrowUp = 'arrow-up',
  AtSign = 'at-sign',
  Award = 'award',
  BarChart2 = 'bar-chart-2',
  BarChart = 'bar-chart',
  BatteryCharging = 'battery-charging',
  Battery = 'battery',
  BellOff = 'bell-off',
  Bell = 'bell',
  Bluetooth = 'bluetooth',
  Bold = 'bold',
  BookOpen = 'book-open',
  Book = 'book',
  Bookmark = 'bookmark',
  Box = 'box',
  Briefcase = 'briefcase',
  Calendar = 'calendar',
  CameraOff = 'camera-off',
  Camera = 'camera',
  Cast = 'cast',
  CheckCircle = 'check-circle',
  CheckSquare = 'check-square',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ChevronsDown = 'chevrons-down',
  ChevronsLeft = 'chevrons-left',
  ChevronsRight = 'chevrons-right',
  ChevronsUp = 'chevrons-up',
  Chrome = 'chrome',
  Circle = 'circle',
  Clipboard = 'clipboard',
  Clock = 'clock',
  CloudDrizzle = 'cloud-drizzle',
  CloudLightning = 'cloud-lightning',
  CloudOff = 'cloud-off',
  CloudRain = 'cloud-rain',
  CloudSnow = 'cloud-snow',
  Cloud = 'cloud',
  Code = 'code',
  Codepen = 'codepen',
  Codesandbox = 'codesandbox',
  Coffee = 'coffee',
  Columns = 'columns',
  Command = 'command',
  Compass = 'compass',
  Copy = 'copy',
  CornerDownLeft = 'corner-down-left',
  CornerDownRight = 'corner-down-right',
  CornerLeftDown = 'corner-left-down',
  CornerLeftUp = 'corner-left-up',
  CornerRightDown = 'corner-right-down',
  CornerRightUp = 'corner-right-up',
  CornerUpLeft = 'corner-up-left',
  CornerUpRight = 'corner-up-right',
  Cpu = 'cpu',
  CreditCard = 'credit-card',
  Crop = 'crop',
  Crosshair = 'crosshair',
  Database = 'database',
  Delete = 'delete',
  Disc = 'disc',
  DivideCircle = 'divide-circle',
  DivideSquare = 'divide-square',
  Divide = 'divide',
  DollarSign = 'dollar-sign',
  DownloadCloud = 'download-cloud',
  Download = 'download',
  Dribbble = 'dribbble',
  Droplet = 'droplet',
  Edit2 = 'edit-2',
  Edit3 = 'edit-3',
  Edit = 'edit',
  ExternalLink = 'external-link',
  EyeOff = 'eye-off',
  Eye = 'eye',
  Facebook = 'facebook',
  FastForward = 'fast-forward',
  Feather = 'feather',
  Figma = 'figma',
  FileMinus = 'file-minus',
  FilePlus = 'file-plus',
  FileText = 'file-text',
  File = 'file',
  Film = 'film',
  Filter = 'filter',
  Flag = 'flag',
  FolderMinus = 'folder-minus',
  FolderPlus = 'folder-plus',
  Folder = 'folder',
  Framer = 'framer',
  Frown = 'frown',
  Gift = 'gift',
  GitBranch = 'git-branch',
  GitCommit = 'git-commit',
  GitMerge = 'git-merge',
  GitPullRequest = 'git-pull-request',
  Github = 'github',
  Gitlab = 'gitlab',
  Globe = 'globe',
  Grid = 'grid',
  HardDrive = 'hard-drive',
  Hash = 'hash',
  Headphones = 'headphones',
  Heart = 'heart',
  HelpCircle = 'help-circle',
  Hexagon = 'hexagon',
  Home = 'home',
  Image = 'image',
  Inbox = 'inbox',
  Info = 'info',
  Instagram = 'instagram',
  Italic = 'italic',
  Key = 'key',
  Layers = 'layers',
  Layout = 'layout',
  LifeBuoy = 'life-buoy',
  Link2 = 'link-2',
  Link = 'link',
  Linkedin = 'linkedin',
  List = 'list',
  Loader = 'loader',
  Lock = 'lock',
  LogIn = 'log-in',
  LogOut = 'log-out',
  Mail = 'mail',
  MapPin = 'map-pin',
  Map = 'map',
  Maximize2 = 'maximize-2',
  Maximize = 'maximize',
  Meh = 'meh',
  Menu = 'menu',
  MessageCircle = 'message-circle',
  MessageSquare = 'message-square',
  MicOff = 'mic-off',
  Mic = 'mic',
  Minimize2 = 'minimize-2',
  Minimize = 'minimize',
  MinusCircle = 'minus-circle',
  MinusSquare = 'minus-square',
  Minus = 'minus',
  Monitor = 'monitor',
  Moon = 'moon',
  MoreHorizontal = 'more-horizontal',
  MoreVertical = 'more-vertical',
  MousePointer = 'mouse-pointer',
  Move = 'move',
  Music = 'music',
  Navigation2 = 'navigation-2',
  Navigation = 'navigation',
  Octagon = 'octagon',
  Package = 'package',
  Paperclip = 'paperclip',
  PauseCircle = 'pause-circle',
  Pause = 'pause',
  PenTool = 'pen-tool',
  Percent = 'percent',
  PhoneCall = 'phone-call',
  PhoneForwarded = 'phone-forwarded',
  PhoneIncoming = 'phone-incoming',
  PhoneMissed = 'phone-missed',
  PhoneOff = 'phone-off',
  PhoneOutgoing = 'phone-outgoing',
  Phone = 'phone',
  PieChart = 'pie-chart',
  PlayCircle = 'play-circle',
  Play = 'play',
  PlusCircle = 'plus-circle',
  PlusSquare = 'plus-square',
  Plus = 'plus',
  Pocket = 'pocket',
  Power = 'power',
  Printer = 'printer',
  Radio = 'radio',
  RefreshCcw = 'refresh-ccw',
  RefreshCw = 'refresh-cw',
  Repeat = 'repeat',
  Rewind = 'rewind',
  RotateCcw = 'rotate-ccw',
  RotateCw = 'rotate-cw',
  Rss = 'rss',
  Save = 'save',
  Scissors = 'scissors',
  Search = 'search',
  Send = 'send',
  Server = 'server',
  Settings = 'settings',
  Share2 = 'share-2',
  Share = 'share',
  ShieldOff = 'shield-off',
  Shield = 'shield',
  ShoppingBag = 'shopping-bag',
  ShoppingCart = 'shopping-cart',
  Shuffle = 'shuffle',
  Sidebar = 'sidebar',
  SkipBack = 'skip-back',
  SkipForward = 'skip-forward',
  Slack = 'slack',
  Slash = 'slash',
  Sliders = 'sliders',
  Smartphone = 'smartphone',
  Smile = 'smile',
  Speaker = 'speaker',
  Square = 'square',
  Star = 'star',
  StopCircle = 'stop-circle',
  Sun = 'sun',
  Sunrise = 'sunrise',
  Sunset = 'sunset',
  Tablet = 'tablet',
  Tag = 'tag',
  Target = 'target',
  Terminal = 'terminal',
  Thermometer = 'thermometer',
  ThumbsDown = 'thumbs-down',
  ThumbsUp = 'thumbs-up',
  ToggleLeft = 'toggle-left',
  ToggleRight = 'toggle-right',
  Tool = 'tool',
  Trash2 = 'trash-2',
  Trash = 'trash',
  Trello = 'trello',
  TrendingDown = 'trending-down',
  TrendingUp = 'trending-up',
  Triangle = 'triangle',
  Truck = 'truck',
  Tv = 'tv',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Type = 'type',
  Umbrella = 'umbrella',
  Underline = 'underline',
  Unlock = 'unlock',
  UploadCloud = 'upload-cloud',
  Upload = 'upload',
  UserCheck = 'user-check',
  UserMinus = 'user-minus',
  UserPlus = 'user-plus',
  UserX = 'user-x',
  User = 'user',
  Users = 'users',
  VideoOff = 'video-off',
  Video = 'video',
  Voicemail = 'voicemail',
  Volume1 = 'volume-1',
  Volume2 = 'volume-2',
  VolumeX = 'volume-x',
  Volume = 'volume',
  Watch = 'watch',
  WifiOff = 'wifi-off',
  Wifi = 'wifi',
  Wind = 'wind',
  XCircle = 'x-circle',
  XOctagon = 'x-octagon',
  XSquare = 'x-square',
  X = 'x',
  Youtube = 'youtube',
  ZapOff = 'zap-off',
  Zap = 'zap',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
}
