import React from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { components, ControlProps } from 'react-select'
import { ValueContainerProps } from 'react-select'
import { NoticeProps } from 'react-select/src/components/Menu'

import { SearchLoadingSpinner } from './AsyncSearch.styles'

export const MenuListLoadingSpinner = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box m={4}>
        <SearchLoadingSpinner />
      </Box>
    </Box>
  )
}

export const ValueContainer = ({ clearValue, children, ...props }: ValueContainerProps<any, any>) => {
  return (
    <components.ValueContainer clearValue={clearValue} {...props}>
      <div
        style={{
          display: 'flex',
          flex: '1',
          alignItems: 'center',
        }}
      >
        <div>{children}</div>
      </div>
    </components.ValueContainer>
  )
}

export const ControlContainer = (props: ControlProps<any, any>) => {
  return (
    <components.Control className={classNames({ props })} {...props}>
      <>{props.children}</>
    </components.Control>
  )
}

const NoOptions = (props: NoticeProps<any, boolean>) => {
  return (
    <Box p={4}>
      <components.NoOptionsMessage {...props} />
    </Box>
  )
}

export const NoOptionsMessage = React.memo(NoOptions)
