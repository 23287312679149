import { MortgageClass, MortgageProduct, MortgageProductDetailsMortgageType } from '@acre/graphql'

const mortgageProduct: MortgageProduct = {
  __typename: 'MortgageProduct',
  id: 'TEST_ID',
  product_id: 'TEST_ID',
  product_code: null,
  product_name: null,
  lender_code: null,
  lender_name: null,
  lender_reference: null,
  initial_pay_rate: null,
  mortgage_class: MortgageClass.ClassTracker,
  applicant_type_conditions: null,
  full_rate_description: null,
  apr_lenders: null,
  max_ltv_available: null,
  selected_mortgage_club_code: null,
  applicant1_income_multiplier: null,
  applicant2_income_multiplier: null,
  joint_applicant_income_multiplier: null,
  standard_variable_rate: null,
  initial_monthly_payment: null,
  cashback: null,
  cashback_conditions: null,
  valuation_fee: null,
  valuation_fee_conditions: null,
  arrangement_fee: null,
  arrangement_fee_conditions: null,
  booking_fee: null,
  booking_fee_conditions: null,
  higher_lending_charge: null,
  higher_lending_charge_conditions: null,
  free_legal_fees: null,
  free_legal_fee_conditions: null,
  chaps_fee: null,
  fees_payable_on_application: null,
  fees_payable_on_completion: null,
  fees_total: null,
  early_repayment_charge_applies_until: null,
  early_repayment_charge_applies_until_calculated_date: null,
  product_notes: null,
  employed_prob_period: null,
  max_property_valuation: null,
  studio_flat_flag: null,
  proc_fee_code: null,
  proc_fee_fixed: null,
  proc_fee_percentage: null,
  proc_fee_min: null,
  proc_fee_max: null,
  monthly_payment_after_initial_period_plus_1perc: null,
  monthly_payment_after_initial_period_plus_2perc: null,
  monthly_payment_after_initial_period_plus_3perc: null,
  self_build: null,
  shared_ownership: null,
  mortgage_discharge_fee: null,
  deeds_release_fee: null,
  funds_transfer_fee_rule: null,
  capital_rest_period: null,
  help_to_buy_shared_equity: null,
  help_to_buy_mortgage_guarantee: null,
  broker_fee: null,
  broker_fee_conditions: null,
  packager_name: null,
  packager_tel_no: null,
  packager_web_address: null,
  lender_criteria_url: null,
  lender_product_reference: null,
  homebuyers_fee: null,
  homebuyers_fee_conditions: null,
  legal_fee: null,
  legal_fee_conditions: null,
  product_type: null,
  net_proc_fee: null,
  net_proc_fee_fixed: null,
  net_proc_fee_percentage: null,
  net_proc_fee_min: null,
  net_proc_fee_max: null,
  mortgage_discharge_fee_when_to_pay: null,
  product_match_status: null,
  reject_reasons: null,
  max_loan_available: null,
  buy_to_let_rental_income_calculation_rate: null,
  stress_test_payments: [],
  disbursement_fee: null,
  disbursement_fee_conditions: null,
  true_cost_full_term: null,
  initial_monthly_payment_inc_existing_mortgage: null,
  true_cost_full_term_inc_existing_mortgage: null,
  true_cost_initial_period_inc_existing_mortgage: null,
  true_cost_inc_existing_mortgage: null,
  mortgage_club_proc_fees: [],
  max_overpayment_percent_no_erc: null,
  overpayments_allowed: null,
  underpayments_allowed: null,
  payment_holidays_allowed: null,
  date_last_modified: null,
  optional_partial_repayment: null,
  apply_online: null,
  lender_tel_no: null,
  lender_web_address: null,
  initial_rate_period: null,
  initial_rate_period_months: null,
  repayment_acceptable: null,
  product_availability: null,
  available_to_purchase: null,
  available_to_remortgage: null,
  available_to_ftb: null,
  max_loan: null,
  min_loan: null,
  min_time_in_current_position_months: null,
  min_time_in_continuous_service_months: null,
  min_self_employed_accounts_years: null,
  early_repayment_charge_applies: null,
  early_repayment_charge: null,
  ccj_maximum_number: null,
  ccj_maximum_amount: null,
  arrears_not_in_last_months: null,
  true_cost: null,
  true_cost_over_calculated_period: null,
  true_cost_over_initial_period: null,
  cashback_paid_on: null,
  remort_home_improv_max_ltv: null,
  remort_debt_con_max_ltv: null,
  remort_bus_purpose_max_ltv: null,
  remort_hol_cars_max_ltv: null,
  affordability_calculator: null,
  self_years_avg_income: null,
  flat_over_shop_flag: null,
  flat_over_shop_notes: null,
  flat_over_shop_owner: null,
  flat_over_shop_sep_ent: null,
  flat_over_shop_sep_title: null,
  flat_over_shop_restaurant: null,
  flat_over_shop_retail: null,
  flat_over_shop_fast_food: null,
  flat_over_shop_fast_industry_unit: null,
  flat_max_floors: null,
  flat_max_floors_no_lift: null,
  deeds_release_fee_when_to_pay: null,
  applicant1_minimum_age: null,
  applicant2_minimum_age: null,
  applicant_maximum_age: null,
  freehold_flag: null,
  leasehold_flag: null,
  commonhold_flag: null,
  feudal_flag: null,
  flying_freehold_flag: null,
  flying_freehold_max_percentage: null,
  product_available_date: null,
  product_discontinued_date: null,
  is_offset_product: null,
  is_further_advance: null,
  is_porting: null,
  mortgage_type: MortgageProductDetailsMortgageType.Residential,
  early_repayment_charge_periods: [],
  exclude_retention: null,
  rate_tiers: [],
  fees: [],
  incentives: [],
}

export default mortgageProduct
