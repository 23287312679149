import React, { useCallback } from 'react'
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@mui/material'

import { testHandle } from '@acre/utils'

import { CloseButtonSmaller } from '../Modal/Modal.styles'
import { Close } from '../../assets'

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
}

const DialogTitle = ({ children, onClose, ...props }: DialogTitleProps) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (ev) => {
      if (typeof onClose === 'function') {
        onClose(ev, 'escapeKeyDown')
      }
    },
    [onClose],
  )

  return (
    <MuiDialogTitle variant="h5" sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      {children}
      {onClose && (
        <CloseButtonSmaller aria-label="Close modal" data-testid={testHandle('CloseModal')} onClick={handleClick}>
          <Close />
        </CloseButtonSmaller>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
