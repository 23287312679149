import { styled } from '@mui/material'

const fadingTextStyles = () => `
  &:after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(to bottom, transparent 0 80%, white 97% 100%);
    pointer-events: none; /* so the text is still selectable */
  }
`

export const TextBox = styled('div')<{ maxHeight: null | string; padding?: string }>(
  ({ maxHeight }) => `
  ${maxHeight ? `max-height: ${maxHeight}` : ''};
  ${maxHeight && fadingTextStyles};
  font-size: 14px;

  overflow: hidden;
  background-clip: text;
  position: relative;
  p {
    word-wrap: break-word;
    padding: unset;
    margin: unset;
    line-height: 1.6;
  }
`,
)

export const ButtonWrapper = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  & > button {
    font-size: 14px;
  }
`,
)
