import { useCallback } from 'react'
import { ArrayPath, FieldArray, FieldArrayPath, FieldValues, get, useFieldArray, useFormContext } from 'react-hook-form'

export interface UseFieldArrayComponentProps<TFieldValues extends FieldValues> {
  name: FieldArrayPath<TFieldValues>
}

const useFieldArrayComponent = <TFieldValues extends FieldValues>({
  name,
}: UseFieldArrayComponentProps<TFieldValues>) => {
  const { control, formState } = useFormContext<TFieldValues>()

  const fieldArrayMethods = useFieldArray<TFieldValues>({
    control,
    name,
  })

  const handleAddClick = useCallback(() => {
    const blankRecords = get(formState.defaultValues, name) as FieldArray<TFieldValues, ArrayPath<TFieldValues>>[]

    if (blankRecords) {
      fieldArrayMethods.append(blankRecords[0])
    }
  }, [formState.defaultValues, name, fieldArrayMethods])

  const handleRemoveClick = useCallback((index: number) => () => fieldArrayMethods.remove(index), [fieldArrayMethods])

  return { ...fieldArrayMethods, handleAddClick, handleRemoveClick }
}

export default useFieldArrayComponent
