import { useEffect } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { uniq } from 'lodash'
import { IntlShape, useIntl } from 'react-intl'

import { useUserContext } from '@acre/utils'
import {
  Notification,
  NotificationEventType,
  ResolveNotificationDetailsDocument,
  ResolveNotificationDetailsQuery,
  ResolveNotificationDetailsQueryVariables,
} from '@acre/graphql'
import config from '@acre/config'

import {
  addTypenamesToNotifications,
  buildDocumentNotification,
  buildSecureCommsNotification,
  deleteNotifcations as deleteNotifications,
  invalidateDocumentsCache,
  invalidateNotesCache,
  readNotificationsFromCache,
  writeNotificationsToCache,
} from './notifications.helpers'
import {
  IdCollection,
  NotificationEnvelope,
  NotificationEventSourceType,
  NotificationMessage,
  NotificationOperation,
  NotificationSubEvent,
  NotificationWithTypename,
} from './notifications.types'
import { useSocket } from './websocket'

const { NOTIFICATIONS_URL } = config

/**
 * Resolve all data required for displaying the list of notifications and return them in a
 * format which is ready to go into the graphql cache
 * @param client Apollo client instance
 * @param intl instance of react-intl
 * @param notifications An array of notifications returned from the websocket or pushed
 * @returns A list of notifications ready to be fed into the graphql cache
 */
const resolveNotificationDependencies = async (
  client: ApolloClient<NormalizedCacheObject>,
  intl: IntlShape,
  notifications: NotificationMessage[],
) => {
  const hasMissingMessages = notifications.filter((notification) => !notification.message)

  const itemsToFetch: IdCollection | null = hasMissingMessages?.length
    ? hasMissingMessages.reduce(
        (collection, message) => {
          switch (message.type) {
            case NotificationEventType.NewDocument: {
              const idsCollection = {
                client: [...collection.client, ...message.newDocumentNotification.clientIds],
                user: [...collection.user],
                case: [...collection.case, message.newDocumentNotification.caseId],
              }

              if (message.source.id) {
                if (message.source.sourceType === NotificationEventSourceType.CLIENT) {
                  idsCollection.client.push(message.source.id)
                } else if (message.source.sourceType === NotificationEventSourceType.USER) {
                  idsCollection.user.push(message.source.id)
                }
              }

              return idsCollection
            }
            default:
              return collection
          }
        },
        {
          client: [],
          user: [],
          case: [],
        } as IdCollection,
      )
    : null

  let resolvedEntities: ResolveNotificationDetailsQuery | undefined

  if (itemsToFetch) {
    // Dedupe the IDs that we are requesting
    const clientIds = uniq(itemsToFetch?.client)
    const userIds = uniq(itemsToFetch?.user)
    const caseIds = uniq(itemsToFetch?.case)

    // If any of the messages are missing, get "all of the things" - If we need more we can add them to this query
    resolvedEntities = (
      await client.query<ResolveNotificationDetailsQuery, ResolveNotificationDetailsQueryVariables>({
        query: ResolveNotificationDetailsDocument,
        variables: { userIds, clientIds, caseIds },
      })
    )?.data
  }

  // Invalidate the cache for entities which have been updated due to a notification
  notifications.forEach((notification) => {
    if ('newDocumentNotification' in notification) {
      invalidateDocumentsCache(client, notification)
    } else if ('newSecureMessageNotification' in notification) {
      invalidateNotesCache(client, notification)
    }
  })

  // Build the actual notifications to write into the cache
  return notifications.reduce((notificationList, notification) => {
    switch (notification.type) {
      case NotificationEventType.NewDocument: {
        const newNotification = buildDocumentNotification(notification, intl, resolvedEntities)

        if (newNotification.document_type) {
          return [...notificationList, newNotification]
        }

        return notificationList
      }
      case NotificationEventType.NewSecureMessage: {
        const newNotification = buildSecureCommsNotification(notification, intl)

        return [...notificationList, newNotification]
      }
      default:
        return notificationList
    }
  }, [] as Notification[])
}

/**
 * Use notifications in the app and begin listening to the socket
 * @param client Apollo client instance
 */
export const useNotifications = (client: ApolloClient<NormalizedCacheObject>) => {
  const socket = useSocket(NOTIFICATIONS_URL)
  const intl = useIntl()
  const user = useUserContext()

  const callback = async (data: NotificationEnvelope<NotificationOperation.INVALID_OPERATION>) => {
    // Read cached notifications
    const cachedNotifications = readNotificationsFromCache(client, user!.id) || []
    const cachedNotificationIds = cachedNotifications.map((notification) => notification.notification_id)

    // Build new one from the recieved data
    const builtNotifications = await resolveNotificationDependencies(client, intl, data.notifications || [])

    // Add typename so the cache can do its job correctly
    const updatedNotifications: NotificationWithTypename[] = addTypenamesToNotifications([
      ...builtNotifications.filter((notification) => !cachedNotificationIds.includes(notification.notification_id)),
      ...cachedNotifications,
    ])

    // Write the new notifications into the cache
    writeNotificationsToCache(client, user!.id, updatedNotifications)
  }

  const clearNotificationsById = (ids: string[]) => {
    const payload: NotificationEnvelope<NotificationOperation.DELETE> = {
      targetId: user!.id,
      operation: NotificationOperation.DELETE,
      notifications: ids.map(
        (id) =>
          ({
            id,
          }) as Pick<NotificationSubEvent, 'id'>,
      ),
    }

    // Delete the selected notification IDs
    deleteNotifications(client, user!.id, ids)

    socket.send(JSON.stringify(payload))
  }

  const clearAllNotifcations = () => {
    const payload: NotificationEnvelope<NotificationOperation.DELETE_ALL> = {
      targetId: user!.id,
      operation: NotificationOperation.DELETE_ALL,
    }

    // Delete all cached notifications
    deleteNotifications(client, user!.id)

    socket.send(JSON.stringify(payload))
  }

  const getAllNotifications = () => {
    const payload: NotificationEnvelope<NotificationOperation.GET_ALL> = {
      targetId: user!.id,
      operation: NotificationOperation.GET_ALL,
    }

    socket.send(JSON.stringify(payload))
  }

  useEffect(() => {
    if (socket) {
      getAllNotifications()
      // Listen for a single notification that is pushed
      socket.onmessage = (message) => callback(JSON.parse(message.data))

      socket.onerror = (error) => {
        console.error(error)
      }
    }
  }, [socket])

  return {
    clearNotificationsById,
    clearAllNotifcations,
  }
}
