import React, { cloneElement } from 'react'
import { Theme } from '@mui/material'
import classNames from 'classnames'

import { flattenChildren } from '@acre/utils'

import { TableVariant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { StyledTr } from './Table.styles'

type TrProps = React.PropsWithChildren<{
  selected?: boolean
  highlight?: boolean
  styled?: boolean
  id?: string
  fontSize?: number
  fontWeight?: 'normal' | 'bold'
  rowPadding?: keyof Theme['spacers']
  gutterPadding?: keyof Theme['spacers']
  cellPadding?: keyof Theme['spacers']
  colour?: 'grey' | 'blue'
  variant?: TableVariant
  borderTop?: boolean
  'data-pii'?: boolean
  onClick?: (args: any) => any
  innerRef?: null | ((node: HTMLTableRowElement) => void)
  style?: object
}>

const Tr = ({
  id,
  onClick,
  children,
  selected = false,
  highlight = false,
  styled = false,
  fontSize = 16,
  fontWeight = 'normal',
  rowPadding = 'size16',
  cellPadding = 'size16',
  variant = 'default',
  colour = 'grey',
  borderTop,
  'data-pii': dataPii,
  gutterPadding,
  innerRef,
  style,
  ...rest
}: TrProps) => {
  const dataTestId = id ? testHandle(id) : undefined
  const className = classNames({ highlight, selected, styled })

  const flattenedChildren = flattenChildren(children)

  const childrenWithProps = (flattenedChildren || []).map((child) => {
    const childCopy = child as React.ReactElement
    return cloneElement(childCopy, { gutterPadding, rowPadding, fontSize, cellPadding, ...childCopy.props })
  })

  return (
    <StyledTr
      {...rest}
      id={id}
      className={className}
      onClick={onClick}
      fontSize={fontSize}
      rowPadding={rowPadding}
      gutterPadding={gutterPadding}
      data-testid={dataTestId}
      data-pii={dataPii}
      colour={colour}
      variant={variant}
      borderTop={borderTop}
      fontWeight={fontWeight}
      ref={innerRef}
      style={style}
    >
      {childrenWithProps}
    </StyledTr>
  )
}

export default Tr
