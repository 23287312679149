import { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import StepContent from '@mui/material/StepContent'
import { format } from 'date-fns'
import { isEmpty, isNumber } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { testHandle, useFormatMessage } from '@acre/utils'
import { TemplateType, useGetDocumentSummariesQuery } from '@acre/graphql'

import { getDocumentStatusKey } from '../../../sections/ReviewMode/VersionHeader/VersionHeader.helpers'
import CollapsableSection, { SidebarSection } from '../CollapsableSection'
import { SectionWrapper } from '../ReviewModeDrawer.styles'
import { StyledStep, StyledStepper } from '../VersionNavigation/VersionNavigation.styles'

const messagePrefix = 'cases.reviewMode.documentHistory'

type Props = {
  caseId: string
}

const DocumentNavigation = ({ caseId }: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()
  const navigate = useNavigate()

  const { documentType, mortgageId, documentId } = useParams()

  // Do not switch this out for an MiDocument endpoint until rendered_mortgage_ids is properly returned by BE
  const { data } = useGetDocumentSummariesQuery({
    variables: { filter_case_id: caseId! },
    skip: !caseId,
    fetchPolicy: 'no-cache',
  })

  const [activeStep, setActiveStep] = useState<number>(0)

  // Filter documents by selected type and sort by date stored
  const filteredDocuments = useMemo(() => {
    const filteredDocs = data?.documentSummaries?.filter((doc) => doc.template_type === documentType)
    return filteredDocs?.sort((a, b) =>
      a?.date_stored && b?.date_stored ? Date.parse(a.date_stored) - Date.parse(b.date_stored) : 0,
    )
  }, [data?.documentSummaries, documentType])

  const esisDocumentsPerMortgage = filteredDocuments?.filter(
    (doc) => doc.rendered_mortgage_ids?.includes(mortgageId ?? ''),
  )
  const displayedDocuments = documentType === TemplateType.Esis ? esisDocumentsPerMortgage : filteredDocuments

  useEffect(() => {
    const activeDocIndex = displayedDocuments?.map((doc) => doc.document_id).indexOf(documentId)
    if (isNumber(activeDocIndex)) {
      setActiveStep(activeDocIndex)
    }
  }, [displayedDocuments, documentId])

  if (isEmpty(displayedDocuments)) return

  return (
    <SectionWrapper>
      <CollapsableSection
        sectionId={SidebarSection.DocumentsNav}
        header={formatMessage(`${messagePrefix}.sidebarTitle`)}
        subHeader={formatMessage(`${messagePrefix}.sidebarSubtitle`)}
      >
        <StyledStepper orientation="vertical" activeStep={activeStep}>
          {displayedDocuments?.map((doc, index) => {
            const { date_stored, verification_count, archived, document_id } = doc
            const documentStatusKey = getDocumentStatusKey(verification_count, archived)
            const dateTime = date_stored ? format(new Date(date_stored), 'HH.mm • dd.MM.yy') : '-'

            const newPath =
              documentType === TemplateType.Esis
                ? `/cases/${caseId}/review-mode/documents/${documentType}/${document_id}/mortgage/${mortgageId}`
                : `/cases/${caseId}/review-mode/documents/${documentType}/${document_id}`

            return (
              <StyledStep
                key={document_id}
                data-testid={testHandle(`Step${document_id}`)}
                expanded={true}
                active={index === activeStep}
                onClick={() => navigate(newPath)}
                sx={{
                  ['& .MuiStepContent-root']: {
                    borderLeft: 0,
                    paddingLeft: theme.spacing(0),
                  },
                }}
              >
                <StepContent>
                  <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px' }}>
                    <span>{`${formatMessage(`${messagePrefix}.${documentType}`)} • ${formatMessage(
                      `${messagePrefix}.${documentStatusKey}`,
                    )}`}</span>
                    <span>{dateTime}</span>
                  </p>
                </StepContent>
              </StyledStep>
            )
          })}
        </StyledStepper>
      </CollapsableSection>
    </SectionWrapper>
  )
}

export default DocumentNavigation
