import React, { MutableRefObject, ReactElement, useRef } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import classNames from 'classnames'

import { useClickOutsideListener } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { BUTTON_OVERFLOW, BUTTON_TYPE_BUTTON, Variant } from '../../utils/constants'
import { ButtonTheme, ColourId } from '../../utils/types'
import Button from '../Button'
import Icon, { IconTypes } from '../Icon'
import PopOver from '../PopOver'
import PopOverItem from '../PopOverItem'
import { ButtonOverflowWrapper, StyledText } from './ButtonOverflow.styles'

export type ButtonOverflowProps = React.PropsWithChildren<{
  id: string
  isOpen: boolean
  onClick: (() => void) | ((e: MouseEvent) => void)
  onClose: (() => void) | ((e: MouseEvent) => void)
  disabled?: boolean
  icon?: IconTypes
  customIcon?: ReactElement
  isLoading?: boolean
  colourID?: ColourId
  variant?: Variant
  showText?: boolean
  buttonText?: string
  style?: ButtonTheme
  overflowMargin?: boolean
  fontColor?: string
  fontSize?: string
  showLeftMargin?: boolean
  maxSize?: boolean
  overflowYVisible?: boolean
}>

const ButtonOverflow = ({
  id,
  isOpen,
  children,
  onClick,
  onClose,
  disabled: disabledProp,
  icon = IconTypes.Overflow,
  customIcon,
  isLoading,
  colourID,
  variant = 'default',
  showText = false,
  buttonText,
  style = BUTTON_OVERFLOW,
  overflowMargin,
  fontColor,
  fontSize,
  showLeftMargin = false,
  maxSize,
  overflowYVisible,
}: ButtonOverflowProps) => {
  const theme = useTheme()
  const className = classNames({ open: isOpen })
  const popoverRef: MutableRefObject<any> = useRef<HTMLDivElement>(null)
  useClickOutsideListener(popoverRef, onClose, isOpen)
  const disabled = useFieldDisabledState(disabledProp)

  const dropDownElements = React.Children.map(children, (child) => <PopOverItem>{child}</PopOverItem>)

  return (
    <ButtonOverflowWrapper ref={(elem) => (popoverRef.current = elem)} showLeftMargin={showLeftMargin}>
      <Button
        id={id}
        type={BUTTON_TYPE_BUTTON}
        buttonStyle={style}
        onClick={isOpen ? onClose : onClick}
        className={className}
        disabled={disabled}
        isLoading={isLoading}
        variant={variant}
        colourID={colourID}
      >
        {showText ? (
          <>
            <StyledText fontSize={fontSize} fontColor={fontColor}>
              {buttonText}
            </StyledText>
            {customIcon && (
              <Box display="flex" justifyContent="center" alignItems="center" ml={theme.spacers.size4}>
                {customIcon}
              </Box>
            )}
          </>
        ) : (
          customIcon || <Icon name={icon} />
        )}
      </Button>
      <PopOver isVisible={isOpen} overflowMargin={overflowMargin} maxSize={maxSize} overflowYVisible={overflowYVisible}>
        {dropDownElements}
      </PopOver>
    </ButtonOverflowWrapper>
  )
}

export default withDisabled(ButtonOverflow)
