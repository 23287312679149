import Case from './Case'
import Client from './Client'
import Document from './Document'
import Query from './Query'

export default {
  Case,
  Client,
  Document,
  Query,
}
