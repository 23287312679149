import { Panel, PanelType } from '@acre/graphql'

const lenderPanel: Panel = {
  __typename: 'Panel',
  default_club: 'TEST_ID',
  excluded_lenders: [],
  fee_configurations: [],
  panel: PanelType.WholeIncDirect,
  preferred_clubs: [],
}

export default lenderPanel
