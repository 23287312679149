import React, { ChangeEvent, FocusEvent, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import { preventNonNumericChars } from '../../utils/helpers'
import testHandle from '../../utils/testHandle'
import HelperText from '../HelperText'
import Label from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import { FormControlWrapper, LabelAndInputWrapper, StyledInputAddonRight } from '../../styles/form-control.styles'
import { StyledInput } from './PercentageInput.styles'

export type PercentageInputProps = {
  value?: number | null
  id?: string
  label?: string | ReactElement
  error?: boolean
  message?: ReactNode
  name?: string
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  isMissing?: boolean
  variant?: Variant
  doNotConvert?: boolean
  onChange: (val: number | null) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

const PercentageInput = ({
  id,
  label,
  error,
  message,
  name,
  disabled: disabledProp,
  ariaLabel,
  doNotConvert,
  placeholder,
  value,
  onBlur,
  onChange,
  onFocus,
  isMissing,
  variant = 'default',
}: PercentageInputProps) => {
  const className = classNames({ error })
  const testId = id ? testHandle(id) : null
  const intl = useIntl()
  const formatMessage = (id: string): string => intl.formatMessage({ id })

  const convertFromLutherFormat = useCallback(() => {
    if (typeof value !== 'number') {
      return ''
    }
    return doNotConvert ? value : value / 100000
  }, [value, doNotConvert])

  // We control the state internally, so that we can have the Luther-expected format in the form state
  // (5 significant figures) and human-readable decimal formatting in the actual input
  const [internalValue, setInternalValue] = useState<number | ''>(convertFromLutherFormat())

  const disabled = useFieldDisabledState(disabledProp)

  // If null or undefined, we render nothing. If it is 0, we still want to render 0
  // because it was probably explicitly set. We use the empty string rather than
  // null or undefined because these values cause the input to switch between
  // controlled and uncontrolled
  useEffect(() => {
    setInternalValue(convertFromLutherFormat())
  }, [convertFromLutherFormat, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueAsNumber = parseFloat(e.target.value)

    // Set the internal value to either the empty string, if cleared, or
    if (isNaN(valueAsNumber)) {
      setInternalValue('')
    } else {
      const valueAsNumber5dp = parseFloat(valueAsNumber.toFixed(5))
      setInternalValue(valueAsNumber5dp)
    }

    // We send 'null' to the parent component because that is how we un-set values in the backend,
    // but we set 'undefined' as the internal value because it is compatible with the native DOM element
    const newValue = doNotConvert ? valueAsNumber : valueAsNumber * 100000
    const value = isNaN(valueAsNumber) ? null : newValue
    const valueAsIntOrNull = value === null ? null : Math.round(value)

    onChange(doNotConvert ? value : valueAsIntOrNull)
  }

  return (
    <FormControlWrapper data-testid={`${testId}Wrapper`} className={className}>
      <LabelAndInputWrapper variant={variant}>
        {label && <Label isMissing={isMissing} htmlFor={id} text={label} variant={variant} />}
        <Box display="flex">
          <MatomoWrapper onChange={handleChange} id={id} eventType="percentageInputChange" trackEventTrigger="onBlur">
            <StyledInput
              id={id}
              name={name}
              disabled={disabled}
              type="number"
              value={internalValue}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              aria-label={ariaLabel}
              placeholder={placeholder}
              data-testid={testId}
              className={className}
              onKeyDown={preventNonNumericChars}
              autoComplete="off"
              variant={variant}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </MatomoWrapper>
          <StyledInputAddonRight htmlFor={id} variant={variant}>
            &#37;
          </StyledInputAddonRight>
        </Box>
      </LabelAndInputWrapper>
      {message && (
        <HelperText
          id={`${id}Helper`}
          message={message || ''}
          textType={error ? HELPER_TYPE_ERROR : ''}
          variant={variant}
        />
      )}
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </FormControlWrapper>
  )
}

export default withDisabled(PercentageInput)
