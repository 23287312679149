import React, { useContext } from 'react'
import { CaseContext } from '@broker-crm-contexts'
import { Box } from '@mui/material'

import { TasksLocation } from './CaseOverview.helpers'
import CaseVerifications from './TaskManagement/CaseVerifications'

type Props = {
  tasksLocation?: TasksLocation
}

const VerificationsContainer = ({ tasksLocation }: Props) => {
  const caseVersion = useContext(CaseContext)
  const clientIds = caseVersion.details?.clients.map((client) => client.id)

  return (
    <>
      <Box
        display="flex"
        flex="1"
        alignSelf="flex-start"
        ml={tasksLocation === TasksLocation.CaseSidebarDrawer ? 0 : 2}
        flexDirection="column"
      >
        <CaseVerifications
          caseId={caseVersion.id}
          clientIds={clientIds}
          caseStatus={caseVersion.details.status!}
          tasksLocation={tasksLocation}
        />
      </Box>
    </>
  )
}

export default VerificationsContainer
