import to from 'await-to-js'
import { GraphQLError } from 'graphql'
import { v4 as generateUniqueId } from 'uuid'

import config from '@acre/config'

import {
  CaseLoader,
  createDocumentVerification,
  DocumentDetailsLoader,
  DocumentSummariesLoader,
  fetchAllCases,
  fetchCase,
  fetchClient,
  fetchClients,
  fetchTemplates,
  getDocument,
  getDocumentSummaries,
  renderTemplate,
  renderTestTemplate,
  updateCaseStatus,
  updateDocument,
  updateMortgage,
  uploadDocument,
  UserLoader,
} from '../api'
import {
  addCaseUserDocument,
  createClientUserDocumentVerification,
  DocumentSummariesLoader as DocumentSummariesLoaderCp,
  getClientUserDocumentDetails,
  getClientUserDocumentSummaries,
  renderClientUserTemplate,
  updateDocumentCp,
  uploadClientUserDocument,
} from '../api_client_portal'
import { DocumentVerification, Template, VerificationSourceType, VerificationType } from '../generated/resolvers'
import {
  Document,
  DocumentSourceType,
  DocumentType,
  DocumentUploadInput,
  FileType,
  Maybe,
  MortgageInput,
  MortgageStatus,
  Resolvers,
  TemplateType,
  UpdateCaseStatusRequestUpdateType,
} from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'
import {
  CdmDocument,
  CdmGetDocumentResponse,
  CdmRenderTemplateRequest,
  CdmTestRenderTemplateRequest,
  CdmUploadDocumentRequest,
  SrvAcreProcessorCreateDocumentVerificationBody,
} from '../service/luther/model'
import { IovationBlackbox } from '../types'
import { getClientName } from '../utils/clients'
import { createFingerprint, getClientUserIddDocumentsDetails, renderNetworkPrivacyPolicy } from './Document.helpers'

const { ACRE_SUPPORT_ORG_ID } = config

const DocumentsResolver: Resolvers = {
  Query: {
    renderedDocument: async (_, params) => {
      const payload = {
        ...params,
        return_signature: true,
      }
      const data = await renderTemplate(payload as CdmRenderTemplateRequest)
      return { ...data.document, internalHash: generateUniqueId() } as Document
    },
    renderedTestTemplate: async (_, params) => {
      const data = await renderTestTemplate(params as CdmTestRenderTemplateRequest)
      return { ...data.document, internalHash: generateUniqueId() } as Document
    },
    documentDetails: async (_, { docId, params }) => {
      const data = await getDocument(docId, params)
      return data?.document as Document
    },
    documentSummaries: async (_, variables) => {
      const data = await getDocumentSummaries(variables)
      return (data.documents as Document[]) || []
    },
    documentDetailsList: async (_, { docIds, documentTypes, isCp }) => {
      // WARNING: use with caution as can cause performance issues, only if documentSummaries  are not sufficiant
      console.warn(
        'We are in the process of deprecating this method, as it causes performance issues. Please consider using document summaries as an alternative.',
      )

      if (!Array.isArray(docIds) || docIds.length === 0) {
        return []
      }

      const documentDetailsList = await Promise.all(
        docIds.map(async (docId) => {
          if (isCp) {
            return await getClientUserDocumentDetails(docId, {
              document_details: true,
            })
          }

          const documentResponse = await getDocument(docId, {
            document_details: true,
          })
          return documentResponse?.document
        }),
      )
      if (documentTypes?.length) {
        return documentDetailsList.filter(
          (details) => details && details.type && documentTypes.includes(details.type as DocumentType),
        ) as Document[]
      }
      return documentDetailsList as Document[]
    },
    // we need this as a separate key in resolvers because it makes 2 calls to doc endpoint
    // if BE returns base 65 in client summary or fetches dos with client ide then bellow can be refactored
    brokersEula: async (_parent, { brokerClientId }) => {
      // Using the broker-client-id  we can check to see if a EULA has been created for the broker using
      const { documents } = await DocumentSummariesLoader.load({
        filter_owning_client_id: brokerClientId,
        filter_archived: false,
      })

      // check if accepted idd exist
      const acceptedDisclosure = documents?.find((doc: Maybe<CdmDocument>) => doc?.document_name?.includes('IDD'))

      if (acceptedDisclosure?.document_id) {
        const fullDocument = await DocumentDetailsLoader.load({
          documentId: acceptedDisclosure?.document_id,
          params: {
            document_details: true,
          },
        })
        // If broker had existing idd, it means broker has already accepted eula
        return fullDocument.document as Document
      }
      return null
    },
    clientUserIddDocuments: async (_, params) => {
      const { documents } = await getClientUserDocumentSummaries(params)
      return await getClientUserIddDocumentsDetails(documents)
    },
    dashboardDocumentsCp: async (_, params) => {
      const { documents } = await getClientUserDocumentSummaries(params)
      const iddDocumentsDetails = await getClientUserIddDocumentsDetails(documents)

      return { documentSummaries: documents || [], iddDocumentsDetails }
    },
    clientUserDocumentsSummaries: async (_, params) => {
      const { documents } = await getClientUserDocumentSummaries(params)
      return documents || []
    },
    clientUserDocumentDetails: async (_, { docId, params }) => {
      const document = await getClientUserDocumentDetails(docId, { ...params, document_details: true })
      return document
    },
  },
  Mutation: {
    verifyDocument: async (_, { input, extendedFingerprint }) => {
      const { document_id } = input
      const fingerprint = await createFingerprint()

      const send_bb = () => {
        let bb: IovationBlackbox
        try {
          bb = window.acreIovation.getBlackbox()
          return bb.blackbox
        } catch (e) {
          console.log('Unable to get blackbox. ' + e)
        }
        return
      }
      const blackbox = extendedFingerprint ? send_bb() : ''

      const extendedDetails = extendedFingerprint
        ? {
            extended_device_details: {
              extended_device_identity_fingerprint: blackbox,
            },
          }
        : {}

      const body = {
        ...input,
        verification_fingerprint_browser_id: fingerprint,

        ...extendedDetails,
      } as SrvAcreProcessorCreateDocumentVerificationBody

      const { verification } = await createDocumentVerification(document_id!, body)
      // For now, we need to add the 'id' property. This will be removed when the
      // document verification entity is aligned with Luther (a ticket doesn't
      // exist for this task yet)
      return {
        ...verification,
        id: verification?.verification_id!,
      } as DocumentVerification
    },
    verifyDocuments: async (_, params) => {
      const results: DocumentVerification[] = []
      if (params.input && params.input.length) {
        for (const input of params.input) {
          const { document_id } = input
          // Document verifications require a unique hash
          const fingerprint = await createFingerprint()
          const body = {
            ...input,
            verification_fingerprint_browser_id: fingerprint,
          } as SrvAcreProcessorCreateDocumentVerificationBody
          const { verification } = await createDocumentVerification(document_id!, body)
          // For now, we need to add the 'id' property. This will be removed when the
          // document verification entity is aligned with Luther (a ticket doesn't
          // exist for this task yet)
          results.push({
            ...verification,
            id: verification?.verification_id!,
          } as DocumentVerification)
        }
      }
      return results
    },
    uploadDocument: async (_, params) => {
      const { case_id, mortgage_id, ...documentDetails } = params.input
      const verifyOnUpload = params.verifyOnUpload

      // link the document to the mortgage if there's a case_id
      const cases = case_id ? [case_id] : []
      const body = { document: { ...documentDetails, case_ids: cases }, mortgage_id } as CdmUploadDocumentRequest

      const { document } = await uploadDocument(body)
      CaseLoader.clearAll()

      if (verifyOnUpload && document?.document_id) {
        const verificationPayload = {
          document_signature: document.document_signature,
          client_id: document.owning_client_ids ? document.owning_client_ids[0] : undefined,
          verification_type: VerificationType.AdvisorValidation,
          source: VerificationSourceType.User,
        }

        const fingerprint = await createFingerprint()
        const body = {
          ...verificationPayload,
          verification_fingerprint_browser_id: fingerprint,
        }
        await createDocumentVerification(document?.document_id, body)
      }

      return document as Document
    },
    updateDocument: async (_, { id, input, isArchiving, mortgageStatus }) => {
      const { application_date, mortgage_id, ...documentInput } = input
      const { document } = await updateDocument(id, documentInput as CdmDocument)

      // If the document is an offer letter and has the required information,
      // we'll update the associated mortgage as well
      const isOfferLetter =
        documentInput.type === DocumentType.ApplicationLetter || documentInput.type === DocumentType.OfferLetter
      if (isOfferLetter && mortgage_id && application_date) {
        await updateMortgage(mortgage_id, { application_date })
      }

      if (isArchiving) {
        // Disconnect esis_document_id from selected mortgage
        if (document?.template_type === TemplateType.Esis) {
          if (mortgage_id) {
            const input: MortgageInput = { esis_document_id: null }

            if (mortgageStatus === MortgageStatus.StatusSelected) {
              input.status = MortgageStatus.StatusProposed
            }

            await updateMortgage(mortgage_id, input)
          }
        }

        // unset offer expiry date
        if (document?.type === DocumentType.OfferLetter && mortgage_id) {
          const input: MortgageInput = { offer_expiry_date: null }
          await updateMortgage(mortgage_id, input)
        }

        document?.case_ids?.map(async (caseId) => {
          // If this is rendered within a case and this doc is a suitability
          // report, changes the case status to pre-recommendation
          const isSuitability = document?.template_type === TemplateType.SuitabilityReport

          if (caseId && isSuitability) {
            await updateCaseStatus(caseId, {
              update: UpdateCaseStatusRequestUpdateType.ArchiveRecommendation,
            })
          }
        })
      }

      return document as Document
    },
    updateDocumentCp: async (_, { id, input }) => {
      const { document } = await updateDocumentCp(id, input)
      return document
    },
    renderAndStoreDocument: async (_, { input }) => {
      // We don't want to include 'verify' with the initial render, but we *do* want
      // to ensure we're storing a document from within this resolver
      const { verify, ...body } = input
      const extendedBody = { ...body, store_document: true }
      const { document } = await renderTemplate(extendedBody as CdmRenderTemplateRequest)
      let verification_count = 0

      // When we render documents, mortgages are updated and then don't refetch,
      // this way we clear the loader so they refetch as expected
      if (body.case_ids) {
        mortgageLoader.clear({
          filter_case_id: body.case_ids[0],
        })
      }

      // Verify the newly created document
      if (verify && document) {
        const { document_id, document_signature, owning_client_ids } = document
        if (owning_client_ids) {
          let results = []
          for (const client_id of owning_client_ids) {
            // Document verifications require a unique hash
            const fingerprint = await createFingerprint()
            const result = await createDocumentVerification(document_id!, {
              client_id,
              document_signature,
              source: VerificationSourceType.UserOnBehalfOfClient,
              verification_type: VerificationType.VerbalAcceptance,
              verification_fingerprint_browser_id: fingerprint,
            })
            results.push(result)
          }

          // We want a verification for each verifying client
          verification_count = results.length
        }
      }

      return { ...document, verification_count } as Document
    },

    renderAndStoreIddDocument: async (_, { input, caseId, clientIdsAccept }) => {
      if (caseId) {
        mortgageLoader.clear({
          filter_case_id: caseId,
        })
      }

      const renderTemplateForEachItem = async (templateName: TemplateType, verify: boolean) => {
        const extendedBody = { ...input, template_name: templateName, store_document: true }

        if (templateName === TemplateType.VerbalIdd) {
          extendedBody.client_ids = clientIdsAccept
        }

        const { document } = await renderTemplate({
          ...extendedBody,
          case_ids: caseId ? [caseId] : [],
        } as CdmRenderTemplateRequest)
        let verification_count = 0

        // When we render documents, mortgages are updated and then don't refetch,
        // this way we clear the loader so they refetch as expected
        if (caseId) {
          mortgageLoader.clear({
            filter_case_id: caseId,
          })
        }

        // Verify the newly created document
        if (verify && document) {
          const { document_id, document_signature, owning_client_ids } = document
          if (owning_client_ids) {
            let results = []
            for (const client_id of owning_client_ids) {
              // Document verifications require a unique hash
              const fingerprint = await createFingerprint()
              const result = await createDocumentVerification(document_id!, {
                client_id,
                document_signature,
                source: VerificationSourceType.UserOnBehalfOfClient,
                verification_type: VerificationType.VerbalAcceptance,
                verification_fingerprint_browser_id: fingerprint,
              })
              results.push(result)
            }

            // We want a verification for each verifying client
            verification_count = results.length
            //@ts-ignore
            DocumentDetailsLoader.prime({ documentId: document_id! }, { ...document, verification_count })
          }
        }

        return { ...document, verification_count } as Document
      }

      let VerbalIdd: Document | undefined
      let NetworkPrivacy: Document | undefined
      let Privacy: Document | undefined
      const Idd = await renderTemplateForEachItem(TemplateType.Idd, false)

      if (Idd) {
        Privacy = await renderTemplateForEachItem(TemplateType.OrganisationPrivacyNotice, false)
      }

      if (caseId && input?.organisation_ids) {
        const caseDetails = await fetchCase(caseId)
        if ((input.organisation_ids && input.organisation_ids[0]) !== caseDetails.details.regulated_by) {
          NetworkPrivacy = await renderNetworkPrivacyPolicy(input?.organisation_ids, renderTemplateForEachItem)
        }
      }

      if (clientIdsAccept?.length) {
        VerbalIdd = await renderTemplateForEachItem(TemplateType.VerbalIdd, true)
      }

      return [VerbalIdd, Idd, Privacy, NetworkPrivacy]
    },

    uploadDocumentList: async (_, { input }) =>
      (await Promise.all(
        input.map(async (document: DocumentUploadInput) => {
          const { mortgage_id, ...documentDetails } = document
          const body = { document: documentDetails, mortgage_id } as CdmUploadDocumentRequest
          return await uploadDocument(body)
        }),
      )) as Document[],

    // Client Portal
    uploadClientUserDocument: async (_, params) => {
      const { case_id, ...documentDetails } = params.document
      const body = { document: documentDetails } as CdmUploadDocumentRequest
      const { document } = await uploadClientUserDocument(body)

      // If a document is associated with a case, we'll need to manually create that
      // link using the 'AddCaseDocument' endpoint
      if (case_id) {
        // It's safe to assert the existence of the document we just uploaded
        const { document_id } = document!
        await addCaseUserDocument(document_id!, {
          case_id,
        })
      }
      DocumentSummariesLoader.clearAll()

      return document
    },
    verifyClientUserDocument: async (_, { input }) => {
      const { document_id } = input
      const fingerprint = await createFingerprint()

      const send_bb = () => {
        let bb: IovationBlackbox
        try {
          bb = window.acreIovation.getBlackbox()
          return bb.blackbox
        } catch (e) {
          console.log('Unable to get blackbox. ' + e)
        }
        return
      }
      const blackbox = send_bb()
      const body = {
        ...input,
        verification_fingerprint_browser_id: fingerprint,
        extended_device_details: {
          extended_device_identity_fingerprint: blackbox,
        },
      } as SrvAcreProcessorCreateDocumentVerificationBody
      const { verification } = await createClientUserDocumentVerification(document_id!, body)
      // For now, we need to add the 'id' property. This will be removed when the
      // document verification entity is aligned with Luther (a ticket doesn't
      // exist for this task yet)
      DocumentSummariesLoaderCp.clearAll()
      // DocumentSummariesLoaderCp.load({
      //   filter_owning_client_id: input.client_id,
      // })
      return {
        ...verification,
        id: verification?.verification_id!,
      } as DocumentVerification
    },
    renderAndStoreClientUserDocument: async (_, { input }) => {
      const extendedBody = { input, store_document: true }
      const document = await renderClientUserTemplate(extendedBody)

      return (document as Document) || null
    },
  },
  Document: {
    cases: async ({ case_ids }, { params }) => {
      if (case_ids) {
        const result = await fetchAllCases({ ...params, case_ids })
        return result?.cases || []
      }

      return null
    },

    uploaded_by: async (parent) => {
      const { source, source_user_id, source_client_id } = parent
      if (source === DocumentSourceType.Client && source_client_id) {
        const client = await fetchClient({ client_ids: [source_client_id] })

        if (!client) {
          return null
        }

        const clientsFullName = getClientName({ client: client.details })
        return clientsFullName
      } else if (source === DocumentSourceType.User && source_user_id) {
        const user = await UserLoader.load(source_user_id)

        if (user instanceof GraphQLError) {
          throw user
        }

        const usersFullName = user ? `${user.first_name} ${user.last_name}` : ''
        return usersFullName
      }
      return source
    },
    template: async (parent) => {
      if (!(parent && parent.template_id)) return null
      const { template_id } = parent
      const template = await fetchTemplates([template_id])
      if (!template) return null
      return {
        id: template_id,
        name: template[0].name,
        template: template[0].template,
      } as Template
    },
    owning_clients: async ({ owning_client_ids }, { include_details }) => {
      if (owning_client_ids && owning_client_ids.length > 0) {
        const [error, clients] = await to(
          fetchClients({ client_ids: owning_client_ids, client_details: include_details ?? false }),
        )

        if (error) {
          throw new GraphQLError('Unable to find owning clients', { extensions: { status: 404 } })
        }

        return clients
      }
      return null
    },
  },
  Client: {
    credit_idd: async ({ id }) => {
      const documentSummaries = await DocumentSummariesLoader.load({
        filter_owning_client_id: id,
        filter_archived: false,
      })

      const iddDocuments = documentSummaries.documents?.filter((document) => {
        return document.template_type === TemplateType.VerbalIdd
      })

      const iddDetails = await DocumentDetailsLoader.loadMany(
        (iddDocuments || []).map((document) => ({
          documentId: document.document_id!,
          params: { document_details: true },
        })),
      )

      let creditIdd = iddDetails.find(
        (document) =>
          document.document?.rendered_organisation_ids?.includes(ACRE_SUPPORT_ORG_ID) &&
          document.document.file_type === FileType.Html,
      )

      if (!creditIdd) {
        const renderedDocument = await renderTemplate({
          template_name: TemplateType.VerbalIdd,
          organisation_ids: [ACRE_SUPPORT_ORG_ID],
          organisation_id: ACRE_SUPPORT_ORG_ID,
          client_ids: [id],
          store_document: true,
        })

        creditIdd = renderedDocument as CdmGetDocumentResponse
      }

      return creditIdd && creditIdd.document ? (creditIdd.document as Document) : null
    },
  },
  MIDocumentSummary: {
    clients: async ({ client_ids }) => {
      if (!client_ids?.length) {
        return []
      }

      return (await fetchClients({ client_ids })) ?? null
    },
  },
}

export default DocumentsResolver
