import { useMemo } from 'react'

import useOrganisationNetworkStatus from './useOrganisationNetworkStatus'

export default (caseId?: string, suffix?: string) => {
  const network = useOrganisationNetworkStatus()

  const url = useMemo(() => {
    const caseSuffix = caseId ? `case/${caseId}/` : ''

    return `/sourcing-frontend/network/${network}/${caseSuffix}${suffix || ''}`
  }, [caseId, network])

  return url
}
