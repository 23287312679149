import React from 'react'

import { Icon, IconTypes } from '@acre/design-system'

import { IconBackground } from '../ItemCard.styles'

export const blueBankCard = (
  <IconBackground colour="skyBlue">
    <Icon name={IconTypes.BankCard} />
  </IconBackground>
)

export const blueFolder = (
  <IconBackground colour="lightBlue">
    <Icon name={IconTypes.Folder} />
  </IconBackground>
)

export const greenHouse = (
  <IconBackground colour="lightGreen">
    <Icon name={IconTypes.HouseGBP} />
  </IconBackground>
)

export const greyHouse = (
  <IconBackground colour="grey">
    <Icon name={IconTypes.House} />
  </IconBackground>
)

export const orangeProfile = (
  <IconBackground colour="lightOrange">
    <Icon name={IconTypes.Profile} />
  </IconBackground>
)

export const purpleBriefcase = (
  <IconBackground colour="lightPurple">
    <Icon name={IconTypes.Briefcase} />
  </IconBackground>
)

export const greenBriefcase = (
  <IconBackground colour="lightGreen">
    <Icon name={IconTypes.Briefcase} />
  </IconBackground>
)

export const blueMultiStorey = (
  <IconBackground colour="lightBlue">
    <Icon name={IconTypes.MultiStorey} />
  </IconBackground>
)

export const aquaBlueShieldPound = (
  <IconBackground colour="aquaBlue">
    <Icon name={IconTypes.ShieldPound} />
  </IconBackground>
)

export const purpleShieldPound = (
  <IconBackground colour="purple">
    <Icon name={IconTypes.ShieldPound} />
  </IconBackground>
)

export default {
  aquaBlueShieldPound,
  blueBankCard,
  blueFolder,
  greenHouse,
  greyHouse,
  orangeProfile,
  purpleBriefcase,
  greenBriefcase,
  blueMultiStorey,
  purpleShieldPound,
}
