import { FieldReadFunction } from '@apollo/client'

import { CancellationReasonColumns, formatAsCurrency, ReportsCancellationReasonResponse } from '@acre/graphql'

import { defaultValue, grandTotal } from './helpers'

const read: FieldReadFunction<CancellationReasonColumns> = (_, { readField, variables }) => {
  const reportsCancellationReasonResponse = readField<ReportsCancellationReasonResponse>({
    fieldName: 'reportsCancellationReason',
    args: variables?.body ? { body: variables.body } : {},
  })

  const columnValues = {
    NO_RESPONSE: defaultValue,
    CLIENT_DECLINED_PRODUCT: defaultValue,
    NO_PRODUCT_AVAILABLE: defaultValue,
    INCORRECT_CONTACT_DETAILS: defaultValue,
    CLIENT_NO_LONGER_REQUIRES_PRODUCT: defaultValue,
    CLIENT_OBTAINED_PRODUCT_ELSEWHERE: defaultValue,
    OTHER: defaultValue,
    TOTAL: defaultValue,
  }

  reportsCancellationReasonResponse?.totals?.forEach((total) => {
    if (total.cancellation_reason) {
      columnValues[total.cancellation_reason as keyof CancellationReasonColumns] = {
        value: formatAsCurrency(total?.value || 0),
        count: total.count || 0,
      }
    }
  })

  return { ...columnValues, TOTAL: grandTotal(reportsCancellationReasonResponse?.totals) } as CancellationReasonColumns
}

export default {
  read,
}
