import { isEmpty, uniq } from 'lodash'

//
// Replaces empty strings, NaNs, and deleted fields with
// null, so we can unset values with a patch request
//
export default function <T extends object>(initialState: T, currentState: T) {
  const currentKeys = Object.keys(currentState)
  const allKeys = [...new Set([...Object.keys(initialState), ...Object.keys(currentState)])]

  const clearedItems = allKeys.reduce((acc, field) => {
    const value = currentState[field as keyof T]

    // We only want to check isEmpty if not a number, because isEmpty(1) returns
    // true because it is not an object that can be destructured
    const item: any =
      !currentKeys.includes(field) ||
      (typeof value === 'number' && isNaN(value)) ||
      (typeof value !== 'number' && typeof value !== 'boolean' && isEmpty(value))
        ? null
        : value

    return { ...acc, [field]: item }
  }, {})

  return { ...currentState, ...clearedItems }
}

// replaceClearedFields doesn't work on nested objects,
// but it appears to be doing stuff above and beyond replacing missing values with null,
// so have created a new function, just in case

const replace: any = (objFirst: any, objSecond: any) => {
  if (!objFirst) return objSecond
  const obj2 = objSecond || {}
  const allKeys = uniq([...Object.keys(objFirst), ...Object.keys(obj2)])

  return allKeys.reduce((obj, key) => {
    // if the value is an object (nested), then recurse into it for more matching
    if (objFirst[key] && objFirst[key].constructor.name === 'Object') {
      return Object.assign(obj, {
        [key]: replace(objFirst[key], obj2[key] || null),
      })
    }
    // work out the value, which is either the value, or it's a false boolean or an empty string.
    // or if it's undefined then its missing and needs to be set as null
    // this assignment could be simplified using ES2020's null-coalescing operator: const value = objSecond[key] ?? null
    const value = obj2[key] || typeof obj2[key] === 'boolean' || typeof obj2[key] === 'string' ? obj2[key] : null
    return Object.assign(obj, { [key]: value })
  }, {})
}

export const replaceMissing = (initial: any, current: any) => replace(initial, current)

const mergeArrays = (sourceArray: any[], targetArray: any[]): any[] => {
  const newArray = [...targetArray]
  const lengthDiff = sourceArray.length - targetArray.length

  if (lengthDiff > 0) {
    newArray.push(...sourceArray.slice(targetArray.length, sourceArray.length))
  }

  return newArray
}
/**
 * Populates missing fields in currentValues with values from initialValues. We use it to check whether the form is pristine and detemernine whether submit buttons are disabled or not
 *
 * @param {Partial<Record<string, any>>} initialValues - The initial values to populate from
 * @param {Record<string, any>} currentValues - The current values to populate
 * @return {Record<string, any>} The current values with missing fields populated
 */
export const populateMissingFields = (
  initialValues: Partial<Record<string, any>>,
  currentValues: Record<string, any>,
) => {
  // ts throws error because u cant modify readonly array
  // we make a copy of currentValues so we dont mutate it
  const clonedCurrentValues = { ...currentValues }

  for (const key in initialValues) {
    if (!(key in clonedCurrentValues)) {
      clonedCurrentValues[key] = initialValues[key]
    } else if (
      typeof initialValues[key] === 'object' &&
      initialValues[key] !== null &&
      !Array.isArray(initialValues[key])
    ) {
      // Recursively merge objects
      clonedCurrentValues[key] = populateMissingFields(initialValues[key], clonedCurrentValues[key])
    } else if (Array.isArray(initialValues[key]) && Array.isArray(clonedCurrentValues[key])) {
      // Handle arrays without mutating original arrays
      clonedCurrentValues[key] = mergeArrays(initialValues[key], clonedCurrentValues[key])
    }
  }

  return clonedCurrentValues
}
