import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { ApolloClient } from '@apollo/client'
import { TrackSiteSearchParams } from '@jonkoops/matomo-tracker-react/es/types'
import { useIntl } from 'react-intl'
import { matchPath, NavigateFunction } from 'react-router-dom'

import { testHandle, uuidRegex } from '@acre/utils'
import {
  Client,
  GetCaseDocument,
  GetOrganisationDocument,
  GetSearchResultsDocument,
  GetUserDocument,
} from '@acre/graphql'
import { Icon, IconTypes } from '@acre/design-system'

import { Title } from './Nav.styles'

export enum PageType {
  Login,
  Documents,
  Cases,
  CaseDetails,
  CaseNew,
  CaseSourcing,
  Clients,
  ClientDetails,
  Sourcing,
  CompanySettings,
  Default,
  ClientProperty,
  CaseProperty,
  CaseProtection,
  CaseProtectionSourcing,
  GeneralInsuranceCompareQuotes,
  ComplianceCaseDetails,
  MySettings,
  Reminders,
}

export type MainNavSuperProps = {
  pathname: string
}

export type MainNavProps = {
  pathname: string
}

export type NavContentProps = {
  iconName?: IconTypes
  id?: string
  copyId?: string
  children?: ReactNode
}

export const getPageType = (pathname: string): PageType => {
  let pageType: PageType = PageType.Default
  if (pathname === '/login' || pathname === '/') {
    pageType = PageType.Login
  }
  if (pathname === '/sourcing') {
    pageType = PageType.Sourcing
  }
  if (pathname === '/clients') {
    pageType = PageType.Clients
  }
  if (pathname === '/cases') {
    pageType = PageType.Cases
  }
  const match = matchPath(
    {
      path: '/cases/:caseId/',
    },
    pathname,
  )
  if (match && match.params.caseId !== 'new') {
    pageType = PageType.CaseDetails
  }
  if (match && match.params.caseId === 'new') {
    pageType = PageType.CaseNew
  }
  if (
    matchPath(
      {
        path: '/sourcing/:id',
      },
      pathname,
    )
  ) {
    pageType = PageType.CaseSourcing
  }
  if (
    matchPath(
      {
        path: '/documents/:id',
      },
      pathname,
    )
  ) {
    pageType = PageType.Documents
  }

  if (matchPath({ path: '/cases/:id/general-insurance-compare-quotes' }, pathname)) {
    pageType = PageType.GeneralInsuranceCompareQuotes
  }

  if (
    matchPath(
      {
        path: '/clients/:clientId/',
      },
      pathname,
    )
  ) {
    pageType = PageType.ClientDetails
  }
  if (
    matchPath(
      {
        path: '/company/:id/',
      },
      pathname,
    )
  ) {
    pageType = PageType.CompanySettings
  }
  if (
    matchPath(
      {
        path: '/properties/:propertyId/client/:clientId',
      },
      pathname,
    )
  ) {
    pageType = PageType.ClientProperty
  }
  if (
    matchPath(
      {
        path: '/properties/:propertyId/case/:caseId/:propertyType',
      },
      pathname,
    )
  ) {
    pageType = PageType.CaseProperty
  }
  if (
    matchPath(
      {
        path: '/compliance/cases/:caseId/',
      },
      pathname,
    )
  ) {
    pageType = PageType.ComplianceCaseDetails
  }
  if (
    matchPath(
      {
        path: '/user/settings',
      },
      pathname,
    )
  ) {
    pageType = PageType.MySettings
  }
  if (
    matchPath(
      {
        path: '/reminders',
      },
      pathname,
    )
  ) {
    pageType = PageType.Reminders
  }
  return pageType
}

export const NavContent = ({ id, iconName, copyId, children }: NavContentProps) => {
  const intl = useIntl()
  const _formatMessage = (id: string) => intl.formatMessage({ id })
  const dataTestId = id ? testHandle(id) : null
  const icon = iconName !== undefined && <Icon name={iconName} />

  return (
    <Title id={id} data-testid={dataTestId}>
      {icon}
      {copyId && _formatMessage(copyId)}
      {children}
    </Title>
  )
}

export const isPageWithoutNav = (pathname: string) =>
  getPageType(pathname) === PageType.Documents || getPageType(pathname) === PageType.Login

export const randomColourIdGenerator = () => Math.floor(Math.random() * 13) + 1
export const formatNaturalClient = ({ first_name, last_name }: Partial<Client>) =>
  `${first_name || ''} ${last_name || ''} - `

export const getClientSearchResultsAsync =
  (
    navigate: NavigateFunction,
    client: ApolloClient<unknown>,
    trackSiteSearch: (params: TrackSiteSearchParams) => void | undefined,
    setDisplayDropDownMenu: Dispatch<SetStateAction<boolean>>,
  ) =>
  async (query: string) => {
    trackSiteSearch({
      keyword: 'redacted - PII', // query
    })

    const encodedQuery = encodeURIComponent(query)

    const { data } = await client.query({
      query: GetSearchResultsDocument,
      variables: { q: encodedQuery },
    })

    if (data.search?.link) {
      setDisplayDropDownMenu(false)
      navigate(data.search.link)
    }

    const results = [...(data?.search?.results || [])]
    if (data?.search?.total_results > results.length) {
      setDisplayDropDownMenu(true)
      results.push({ more: data?.search?.total_results - results.length })
    }
    return results
  }

export const getCaseSearchResultsAsync = (client: ApolloClient<unknown>) => async (id: string) => {
  // if there is a uuid provided
  if (id.match(new RegExp(uuidRegex))) {
    const { data: caseData } = await client.query({
      query: GetCaseDocument,
      variables: { id },
    })
    const ownerOrganisationId = caseData?.case?.details?.owner?.organisation?.id
    const ownerId = caseData?.case?.details?.owner?.id

    if (ownerOrganisationId && ownerId) {
      const { data: organisationData } = await client.query({
        query: GetOrganisationDocument,
        variables: { id: ownerOrganisationId },
      })
      const { data: userData } = await client.query({
        query: GetUserDocument,
        variables: { id: ownerId! },
      })
      return [
        {
          ...caseData,
          ...organisationData,
          ...userData,
        },
      ]
    }
  }
  return new Promise<void>((resolve) => resolve())
}
