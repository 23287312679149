import { useCallback, useRef } from 'react'
import { unstable_BlockerFunction, useBlocker } from 'react-router-dom'

export type UseNavigationPromptCallback = (...args: Parameters<unstable_BlockerFunction>) => string

export default function useNavigationPrompt(shouldPrompt: UseNavigationPromptCallback) {
  const messageRef = useRef('')

  const shouldBlock = useCallback<unstable_BlockerFunction>(
    ({ currentLocation, nextLocation, historyAction }) => {
      if (!messageRef.current) {
        const message = shouldPrompt({ currentLocation, nextLocation, historyAction })

        if (message) {
          messageRef.current = message
        }
      }

      return !!messageRef.current
    },
    [shouldPrompt],
  )

  const blocker = useBlocker(shouldBlock)

  const onConfirm = useCallback(() => {
    messageRef.current = ''

    if (blocker.proceed) {
      blocker.proceed()
    }
  }, [blocker])

  const onCancel = useCallback(() => {
    if (blocker.state === 'blocked') {
      messageRef.current = ''
      blocker.reset()
    }
  }, [blocker])

  return {
    message: messageRef.current,
    onConfirm,
    onCancel,
  }
}
