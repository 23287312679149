import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Link } from '@phosphor-icons/react'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'

import { getName, useFormatMessage } from '@acre/utils'
import { GetCaseQuery, RelatedCase, useGetCaseLazyQuery, useGetUserLazyQuery, User } from '@acre/graphql'
import { ButtonNewest, LoadingSpinner, STATUS_INTL, STATUS_TAG_COLOUR, Tag } from '@acre/design-system'

import CollapsableSection, { SidebarSection } from '../CollapsableSection'
import { CardTableCell } from '../Info/CaseInfo'
import { LINKED_CASE_TYPE_MAP, messagePrefix } from './RelatedCases.helpers'
import { InfoTable } from '../Info/CaseInfo.styles'
import { SectionWrapper } from '../ReviewModeDrawer.styles'

const RelatedCases = () => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()
  const navigate = useNavigate()
  const caseContext = useCaseContext()

  const relatedCases = useMemo(() => caseContext?.details?.related_cases ?? [], [caseContext?.details?.related_cases])
  const [relatedCaseData, setRelatedCaseData] = useState<GetCaseQuery['case'][]>([])
  const [advisorData, setAdvisorData] = useState<User[]>([])

  const [getCase, { loading }] = useGetCaseLazyQuery()
  const [getUser] = useGetUserLazyQuery()

  const fetchRelatedCaseData = useCallback(
    async (ids: string[]) => {
      let results = []
      let advisors = []

      for (const id of ids) {
        // Retrieve case data
        const result = await getCase({ variables: { id } })
        result?.data?.case && results.push(result?.data?.case)

        // Retrieve advisor data
        if (result.data?.case.details.owner_user_id) {
          const caseOwner = await getUser({
            variables: { id: result.data.case.details.owner_user_id, useClientApi: false },
          })
          caseOwner?.data?.user && advisors.push(caseOwner?.data?.user)
        }
      }

      setAdvisorData(advisors)
      setRelatedCaseData(results)
    },
    [getCase, getUser],
  )

  useEffect(() => {
    const filteredCases = relatedCases?.filter((c) => c.case_id)
    fetchRelatedCaseData(filteredCases?.map((c) => c.case_id))
  }, [fetchRelatedCaseData, getCase, relatedCases])

  if (isEmpty(relatedCases)) {
    return (
      <SectionWrapper>
        <CollapsableSection
          sectionId={SidebarSection.Tasks}
          header={formatMessage(`${messagePrefix}.title`, { count: relatedCases?.length })}
        >
          <Typography fontSize={theme.typography.pxToRem(14)}>
            {formatMessage(`${messagePrefix}.noRelatedCases`)}
          </Typography>
        </CollapsableSection>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper>
      <CollapsableSection
        sectionId={SidebarSection.Tasks}
        header={formatMessage(`${messagePrefix}.title`, { count: relatedCases?.length })}
      >
        <Stack spacing={2}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            relatedCases.map((relatedCase: RelatedCase) => {
              const { id, relationship_type, case_id } = relatedCase

              const caseData = relatedCaseData?.find((c) => c.id === case_id)
              const { status, owner_user_id } = caseData?.details || {}

              const advisor = advisorData?.find((a) => a.id === owner_user_id)
              const advisorName = advisor ? getName(advisor) : '-'

              if (!relationship_type || !status) return

              return (
                <Box key={`RelatedCase${id}`}>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <ButtonNewest
                      variant="link"
                      sx={{ fontSize: theme.typography.pxToRem(12) }}
                      onClick={() => navigate(`/cases/${case_id}/overview`)}
                    >
                      <Box mr={1 / 2}>
                        <Link size={16} />
                      </Box>
                      <strong>{formatMessage(LINKED_CASE_TYPE_MAP[relationship_type])}</strong>
                    </ButtonNewest>
                    <Tag text={formatMessage(STATUS_INTL[status])} colourID={STATUS_TAG_COLOUR[status]} />
                  </Box>
                  <InfoTable>
                    <tbody>
                      <tr>
                        <CardTableCell color="secondary">
                          <p>{formatMessage(`${messagePrefix}.advisor`)}</p>
                        </CardTableCell>
                        <CardTableCell textAlign="right">{advisorName}</CardTableCell>
                      </tr>
                    </tbody>
                  </InfoTable>
                </Box>
              )
            })
          )}
        </Stack>
      </CollapsableSection>
    </SectionWrapper>
  )
}

export default RelatedCases
