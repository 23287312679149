import { ClientIncome, UnearnedIncome, UnearnedIncomeFrequency } from '@acre/graphql'

export const getNetMonthlyUnearnedIncome = (amount: number, frequency: UnearnedIncomeFrequency) => {
  // The switch statement below is used to normalise
  // the amount based on the frequency so that it can be expressed in the unit amount per
  // month. i.e. if a client receives an amount of £1000 per year for a given additional
  // income source, this value must be divided by 12 to be expressed in months.

  if (amount && frequency) {
    switch (frequency) {
      case UnearnedIncomeFrequency.Annual:
        return amount / 12

      case UnearnedIncomeFrequency.Monthly:
        return amount

      case UnearnedIncomeFrequency.Quarterly:
        return amount / 3

      case UnearnedIncomeFrequency.Weekly:
        return amount * (52 / 12)

      default:
        return 0
    }
  }

  return 0
}

export const getNetMonthlyIncome = (incomes: ClientIncome[], unearnedIncomes: UnearnedIncome[]) => {
  const netMonthlyUnearnedIncome = unearnedIncomes.reduce((total: number, { amount, frequency }) => {
    if (amount && frequency) {
      total += getNetMonthlyUnearnedIncome(Number(amount), frequency)
    }

    return total
  }, 0)

  const netMonthlyIncome = incomes.reduce((netTotal, clientIncome: ClientIncome) => {
    if (clientIncome.is_current && clientIncome.monthly_net_income) {
      netTotal += Number(clientIncome.monthly_net_income)
    }

    return netTotal
  }, 0)

  const result = netMonthlyUnearnedIncome + netMonthlyIncome

  return Number(result.toFixed(0))
}
