import classnames from 'classnames'

import type { FieldProps } from '../Field.types'

const useFieldSlotProps = (classes: Record<string, string>, props: FieldProps) => {
  const { InputLabelProps, FormHelperTextProps, ErrorTextProps, helperText, error } = props

  const labelProps = { ...InputLabelProps, className: classnames(classes.label, InputLabelProps?.className) }

  const helperTextProps = helperText
    ? {
        ...FormHelperTextProps,
        className: classnames(classes.helperText, FormHelperTextProps?.className),
      }
    : undefined

  const errorProps = error
    ? { ...ErrorTextProps, className: classnames(classes.errorText, ErrorTextProps?.className) }
    : undefined

  return { labelProps, helperTextProps, errorProps }
}

export default useFieldSlotProps
