import React, { ReactElement } from 'react'
import { MockedProvider as MockedProviderHooks } from '@apollo/client/testing'
import { render } from '@testing-library/react'
import { IntlProvider } from 'react-intl'

import { FlagProvider, flagsAllEnabled, flattenMessages, getMessages, Locales } from '@acre/utils'
import { muiTheme, ThemeProvider } from '@acre/design-system'

import messages from '../../intl/messagesBrokerCrm'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))
export default (tree: ReactElement, mocks: any) => {
  return render(
    <FlagProvider value={flagsAllEnabled}>
      <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
        <MockedProviderHooks mocks={mocks}>
          <ThemeProvider theme={muiTheme}>{tree}</ThemeProvider>
        </MockedProviderHooks>
      </IntlProvider>
    </FlagProvider>,
  )
}
