import { CaseRelationshipType } from '@acre/graphql'

export const messagePrefix = 'cases.reviewMode.relatedCases'

export const LINKED_CASE_TYPE_MAP: { [key in CaseRelationshipType]?: string } = {
  [CaseRelationshipType.ReferralSource]: `${messagePrefix}.linkedCase`,
  [CaseRelationshipType.ReferralTarget]: `${messagePrefix}.linkedProtectionCase`,
  [CaseRelationshipType.RemortgageLead]: `${messagePrefix}.linkedRemortgageLead`,
  [CaseRelationshipType.RemortgageOf]: `${messagePrefix}.linkedCase`,
}
