import config from '@acre/config'

export const fetchEnvironment = () => {
  switch (config.API_URL) {
    case 'https://api.acre.software/v1/acre':
      return 'integration'
    case 'https://api.acreplatforms.co.uk/v1/acre':
      return 'staging'
    case 'https://api.myac.re/v1/acre':
      return 'master'
  }
}

export enum ServiceMessageSection {
  Global = 'Global',
  Sourcing = 'Sourcing',
  Protection = 'Protection',
  Accounting = 'Accounting',
  DIP = 'DIP',
  GI = 'GI',
  CaseBoard = 'CaseBoard',
  Login = 'Login',
}

export enum ServiceBannerColour {
  Yellow = 'Yellow',
  Green = 'Green',
}
