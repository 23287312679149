import React, { useMemo } from 'react'
import { ApolloProvider } from '@apollo/client'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import enGB from 'date-fns/locale/en-GB'
import { IntlProvider } from 'react-intl'

import { ErrorProvider, flattenMessages, getMessages, Locales } from '@acre/utils'
import { Alert, DefaultErrorBoundary, muiTheme, PrettyErrorBoundary, ThemeProvider } from '@acre/design-system'

import initApollo from './graphql/initApollo'
import messages from './intl/messagesBrokerCrm'
import { GlobalStyle } from './index.styles'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

type Props = {
  children: JSX.Element
}

const AppProviders = ({ children }: Props) => {
  const client = useMemo(initApollo, [])

  return (
    <DefaultErrorBoundary>
      <LocalizationProvider adapterLocale={enGB} dateAdapter={AdapterDateFns}>
        <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={muiTheme}>
              <ErrorProvider alertSlot={Alert}>
                <GlobalStyle />
                <PrettyErrorBoundary>{children}</PrettyErrorBoundary>
              </ErrorProvider>
            </ThemeProvider>
          </ApolloProvider>
        </IntlProvider>
      </LocalizationProvider>
    </DefaultErrorBoundary>
  )
}
export default AppProviders
