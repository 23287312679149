import { FieldReadFunction, Reference } from '@apollo/client'

export const read: FieldReadFunction<number> = (_, { readField, variables }) => {
  const predictedRevenueEntries = readField({
    fieldName: 'predictedRevenue',
    variables,
  }) as Reference[]

  const total = predictedRevenueEntries?.reduce<number>((acc, predictedRevenue) => {
    acc += readField('value', predictedRevenue) as number
    return acc
  }, 0)

  return total
}

export { read as default }
