import { Verification, VerificationCollection } from '@acre/graphql'

export const doTheseVerificationsNotFail = ({
  verificationIdsToBeChecked,
  allVerificationCollections,
  clientId,
}: {
  verificationIdsToBeChecked: string[]
  allVerificationCollections?: VerificationCollection[]
  clientId?: string
}) => {
  // get all verifications from all collections
  const allVerifications = allVerificationCollections?.reduce(
    (acc, { verifications }) => (verifications ? [...acc, ...verifications] : acc),
    [] as Verification[],
  )

  // Get all outstanding verifications by getting only the ones which do not pass
  // Because verification can come from case, we need to get only the relevant client's outstanding verifications
  const outstandingVerifications = allVerifications?.filter(({ passed, client_id }) =>
    clientId ? client_id === clientId && !passed : !passed,
  )

  // Map outstanding verifications to their ids
  const outstandingVerificationsIds = outstandingVerifications?.map(({ verification_id }) => verification_id)

  // check for overlap between outstanding and toBeChecked verification ids and return false (meaning there are some toBeChecked verifications that are failing)
  if (outstandingVerificationsIds?.some((verificationId) => verificationIdsToBeChecked.includes(verificationId))) {
    return false
  }
  // if there is no overlap between outstanding and toBeChecked verification ids => return true (meaning out of all outstanding verifications none from the toBeChecked list are failing)
  return true
}
