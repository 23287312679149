import React from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import { Button, BUTTON_LINK_SMALL, FeatherIcon, IconName, Size } from '@acre/design-system'

type Props = {
  onClick: () => void
  showAdditionalDetails?: boolean
  disabled: boolean
}

const AdditionalDetailsButton = ({ onClick, showAdditionalDetails, disabled }: Props) => {
  const intl = useIntl()
  const formatMessage = (id: string) => intl.formatMessage({ id })
  return (
    <Box mt={1}>
      <Button id="AdditionalDetails" disabled={disabled} onClick={onClick} buttonStyle={BUTTON_LINK_SMALL}>
        {showAdditionalDetails
          ? formatMessage('buttons.hideAdditionalDetails')
          : formatMessage('buttons.viewAdditionalDetails')}
        <Box ml={1} height="16px">
          <FeatherIcon name={showAdditionalDetails ? IconName.ChevronUp : IconName.ChevronDown} size={Size.Small} />
        </Box>
      </Button>
    </Box>
  )
}

export default AdditionalDetailsButton
