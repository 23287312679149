import React, { useCallback, useMemo } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer } from '@mui/material'
import { get } from 'lodash'

import { testHandle, useFormatMessage } from '@acre/utils'
import { H2 } from '@acre/design-system'

import { DataComponentValueSchema } from './DataComponent.types'
import { StyledTableRow } from '../../ReviewMode.styles'

type Props = {
  field: DataComponentValueSchema
  array: any[]
  messagePrefix?: string
  titlePrefix?: string
}

const DataComponentArray = ({ field, array, messagePrefix, titlePrefix }: Props) => {
  const formatMessage = useFormatMessage()

  const tag = useMemo(() => field.sectionTag, [field.sectionTag])

  const getTitleText = useCallback(
    (index: number, noIndex: boolean, messagePrefix?: string, titlePrefix?: string) => {
      const indexTag = noIndex ? '' : `(${index + 1})`
      if (titlePrefix) {
        return `${titlePrefix} ${formatMessage(`${messagePrefix}.${tag}`)} ${indexTag}`
      } else {
        return `${formatMessage(`${messagePrefix}.${tag}`)} ${indexTag}`
      }
    },
    [formatMessage, tag],
  )

  return array.map((entry: any, index: number) => (
    <TableContainer key={`${tag}${index}`}>
      <Box px={1} pb={1}>
        <H2 styledAs="h6" data-testid={testHandle(`${field.keyPath}${index}Title`)}>
          {getTitleText(index, array.length === 1, messagePrefix, titlePrefix)}
        </H2>
      </Box>
      <Table size="small">
        <TableBody>
          {field.children?.map((field) => {
            if (field.kind !== 'value') return
            const entryValue = get(entry, field.keyPath)
            if (!entryValue && field.hideNull) return
            return (
              <StyledTableRow
                key={`${field.keyPath}${index}`}
                data-testid={testHandle(`${field.keyPath}${index}Label`)}
              >
                <TableCell component="th" scope="row">
                  {formatMessage(field.messageId)}
                </TableCell>
                <TableCell align="right" data-testid={testHandle(`${field.keyPath}${index}Value`)}>
                  {field.Formatter && field.Formatter(entryValue)}
                </TableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ))
}

export default React.memo(DataComponentArray)
