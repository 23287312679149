import type { AllFieldProps } from '../Field.types'

const useAriaProps = (props: AllFieldProps) => {
  const { id, name, error, helperText, label } = props

  const ariaId = id ?? name?.replace(/\./g, '-')

  const testId = props['data-testid'] || `e2e-${ariaId}`
  const descriptionId = helperText ? `${ariaId}-helper-text` : ''
  const errorId = error ? `${ariaId}-error` : ''
  const labelId = label ? `${ariaId}-label` : ''

  const fieldAriaProps = {
    'aria-describedby': descriptionId,
    'aria-labelledby': labelId,
    'aria-errormessage': errorId,
    'aria-invalid': !!errorId,
  }

  return { ariaId, testId, descriptionId, errorId, labelId, fieldAriaProps }
}

export default useAriaProps
