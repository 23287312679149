import React from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'
import { useMatch } from 'react-router-dom'

import MenuItem, { MenuItemProps } from '../MenuItem/MenuItem'
import { getMenuItemLinkUtilityClass } from './menuItemLinkClasses'

export interface MenuItemLinkProps extends MenuItemProps {
  to?: string
}

const MenuItemLinkRoot = styled(MenuItem, {
  name: 'MenuItemLink',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})<MenuItemLinkProps>``

const useUtilityClasses = (ownerState: Partial<MenuItemLinkProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getMenuItemLinkUtilityClass, ownerState.classes)
}

const _MenuItemLink = (props: MenuItemLinkProps) => {
  const { className, classes, ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes })

  const routeMatch = useMatch(props.to as string)

  return (
    <MenuItemLinkRoot
      {...rootProps}
      className={classnames(slotClasses.root, className)}
      selected={props.to === routeMatch?.pathname}
    />
  )
}

const MenuItemLink_ = React.memo(_MenuItemLink) as typeof _MenuItemLink

export default MenuItemLink_
