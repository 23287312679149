import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { omit, unionWith } from 'lodash'

import {
  CommissionStructure,
  GetCaseMiDocumentsQueryVariables,
  GetClientMiDocumentsQueryVariables,
  GetFilteredNoteRequestInput,
  GetFilteredNoteResponse,
  GetMiCaseListFilterInput,
  NoteStatus,
  NoteType,
  PermissionAssignment,
  QueryClientListArgs,
  QueryGetFirmListArgs,
} from '@acre/graphql'

import mergeWithOffset from '../utils/mergeWithOffset'
import { getKeyArgs } from './helpers/fieldPolicy'
import typePolicies from './typePolicies'
import { readLedgerPayerName } from './typePolicies/ledgerPayerName'
import { readPredictedRevenue, readPredictedRevenueTotal } from './typePolicies/PredictedRevenue'

export const cacheConfig: InMemoryCacheConfig = {
  addTypename: true,
  // This util allows us to specify something other than 'id' for GraphQL's cache mechanism
  typePolicies: {
    ...typePolicies,
    ProtectionProduct: { keyFields: ['protection_id'], fields: { details: { merge: true } } },
    ProtectionProductDetails: { keyFields: false },
    CommissionStructure: { keyFields: ['commission_structure_id'] },
    Commission: { keyFields: ['internalHash'] },
    Note: { keyFields: ['note_id'] },
    ClientVersion: {
      keyFields: ['id', 'version'],
      fields: {
        details: {
          merge: true,
        },
      },
    },
    Client: { ...typePolicies.Client, keyFields: false },
    Mortgage: { keyFields: ['id', 'version'], fields: { calculated_values: { merge: true } } },
    MortgageCalculatedValues: { keyFields: false },
    PropertyVersion: {
      keyFields: ['id', 'version'],
      fields: {
        details: {
          merge: true,
        },
      },
    },
    Property: { keyFields: false },
    PermissionAssignmentResponse: {
      fields: {
        permission_assignments: {
          keyArgs: ['permission_assignment_id'],
          merge(existing, incoming, { readField }) {
            // The same permission can be added twice, one disabled and one enabled.
            // To prevent the duplication from occurring and to ensure the correct permission is recognized we dedup by keeping Latest permission
            const uniquePermissions: { [key: string]: PermissionAssignment } = {}
            incoming?.forEach((obj: PermissionAssignment) => {
              const permissionId = readField('permission_assignment_id', obj) as string
              uniquePermissions[permissionId] = obj
            })
            return Object.values(uniquePermissions)
          },
        },
      },
    },
    PermissionAssignment: { keyFields: ['permission_assignment_id'] },
    PermissionResolution: { keyFields: ['permission_name', 'scope', ['id', 'type'], 'assignee', ['id']] },
    Notification: { keyFields: ['notification_id'] },
    MiOrganisationSummary: { keyFields: ['organisation_id'] },
    FirstTimeAccess: { keyFields: false },
    FirstTimeFeature: { keyFields: false },
    Debt: { keyFields: ['id', 'internalHash'] },
    Deposit: { keyFields: ['id', 'internalHash'] },
    Dip: { keyFields: ['mortgage_id'] },
    UnearnedIncome: { keyFields: ['id', 'internalHash'] },
    ClientDetailsBankruptcyEvent: { keyFields: ['id', 'internalHash'] },
    ClientDetailsCountyCourtJudgement: { keyFields: ['id', 'internalHash'] },
    ClientDetailsCreditRefusal: { keyFields: ['id', 'internalHash'] },
    ClientDetailsDebtManagementPlan: { keyFields: ['id', 'internalHash'] },
    ClientDetailsIndividualVoluntaryArrangement: { keyFields: ['id', 'internalHash'] },
    PreviousNames: { keyFields: ['id', 'internalHash'] },
    ReportsCommissionsSummaries: { keyFields: ['internalHash'] },
    Dependant: { keyFields: ['id', 'internalHash'] },
    ClientBankAccount: { keyFields: ['id', 'internalHash'] },
    ClientAddress: { keyFields: ['id', 'internalHash'] },
    ClientIncome: { keyFields: ['id', 'internalHash'] },
    Document: { keyFields: ['document_id', 'internalHash'] },
    ReportColumn: { keyFields: false },
    Ledger: {
      fields: {
        payments: {
          merge(existing, incoming) {
            return incoming // Payments have no ID so this is how we merge them - Always use the updated ones
          },
        },
      },
    },
    MIDocumentTotal: {
      keyFields: false,
    },
    MIDocumentSummary: {
      keyFields: ['document_id'],
    },
    MIDocumentSummarySource: {
      keyFields: false,
    },
    OneOffLedger: {
      fields: {
        payerName: {
          read: readLedgerPayerName,
        },
      },
    },
    RecurringLedger: {
      fields: {
        payerName: {
          read: readLedgerPayerName,
        },
      },
    },
    Query: {
      fields: {
        ...typePolicies.Query.fields,
        predictedRevenue: {
          read: readPredictedRevenue,
        },
        predictedRevenueTotal: {
          read: readPredictedRevenueTotal,
        },
        getCommissionStructures: {
          keyArgs: getKeyArgs({ keys: ['bookmark', 'page_size'], argsKey: 'input' }),
        },
        introducers: {
          keyArgs: getKeyArgs({ keys: ['bookmark', 'page_size'], argsKey: 'input' }),
        },
        getActiveNotes: {
          // Required as `storage` is different for each set of cache keys
          keyArgs: getKeyArgs<GetFilteredNoteRequestInput>({ keys: ['bookmark', 'page_size'], argsKey: 'params' }),
          read(_, { readField, args, storage }) {
            // initialize storage
            storage.offset = storage.offset || args?.params.bookmark || 0
            storage.data = storage.data || []

            // Get Notes from last `getFilteredNotes` query
            const noteRefs =
              readField<GetFilteredNoteResponse>({
                fieldName: 'getFilteredNotes',
                args: args?.params ? { params: args.params } : {},
              })?.notes || []

            // Return cached data if there are no new Notes
            if (!noteRefs.length || storage.offset === noteRefs.length) {
              return storage.data
            }

            // Create new ActiveNotes (slice Notes from offset)
            const newNotes =
              noteRefs.slice(storage.offset).filter((noteRef) => {
                const status = readField('status', noteRef)
                const primaryType = readField('primary_type', noteRef)
                const deadline = readField('deadline', noteRef)

                return (
                  status !== NoteStatus.Complete &&
                  status !== NoteStatus.Archived &&
                  (primaryType == NoteType.Reminder || primaryType == NoteType.Review) &&
                  !!deadline
                )
              }) || []

            // Update offset to current length of getFilteredNotes
            storage.offset = noteRefs.length

            // Cache the latest ActiveNotes
            storage.data = storage.data.concat(newNotes)

            return storage.data
          },
        },
        notifications: {
          merge(existing, incoming) {
            return incoming // If we update the cached notifications we should always take the new ones
          },
        },
        getFirmList: {
          // When we cache the query for getFirmList, only use org_types and page_size
          // as the key, ignoring bookmark as then there are multiple cached queries
          keyArgs(params?: { filter?: QueryGetFirmListArgs } | null) {
            return JSON.stringify(params && params.filter ? omit(params.filter, ['bookmark', 'page_size']) : {})
          },
        },
        getMiCaseList: {
          keyArgs(params?: { input?: GetMiCaseListFilterInput } | null) {
            return JSON.stringify(params && params.input ? omit(params.input, 'bookmark') : {})
          },
        },
        case: {
          keyArgs(params?: Partial<GetCaseMiDocumentsQueryVariables> | null) {
            const updatedParams = {
              id: params?.id,
              ...(params?.filters &&
                Object.keys(params.filters).length > 2 && {
                  filters: omit(params.filters, ['bookmark', 'page_size']),
                }),
            }
            return JSON.stringify(params ? updatedParams : {})
          },
        },
        client: {
          keyArgs(params?: Partial<GetClientMiDocumentsQueryVariables> | null) {
            const updatedParams = {
              id: params?.id,
              ...(params?.filters &&
                Object.keys(params.filters).length > 2 && {
                  filters: omit(params.filters, ['bookmark', 'page_size']),
                }),
            }
            return JSON.stringify(params ? updatedParams : {})
          },
        },
        getFilteredNotes: {
          keyArgs(params?: { params?: GetFilteredNoteRequestInput } | null) {
            return JSON.stringify(params && params.params ? omit(params.params, ['bookmark', 'page_size']) : {})
          },
        },
        getLedgers: {
          keyArgs: getKeyArgs({ keys: ['bookmark', 'pageSize'], argsKey: 'input' }),
        },
        clientList: {
          keyArgs: getKeyArgs<QueryClientListArgs>({ keys: ['bookmark', 'page_size'] }),
        },
      },
    },
    GetMiCaseListStats: { keyFields: false },
    GetClientResponse: {
      fields: {
        clients: {
          merge(existing = [], incoming) {
            const offset = existing.length

            return offset ? mergeWithOffset({ existing, incoming, offset }) : incoming
          },
        },
      },
    },
    IntroducerCommissions: {
      fields: {
        organisations: {
          keyArgs: ['commission_structure_id'],
          // The BE api returns us bookmarks that do not go up necessarily in order,
          // For example, bookmark of 30 can be followed by 61
          // So indexed based merging is not possible here because in the example above 60th item in the array would be emtpy
          // Hence use a dictionary based approach when writing to cache
          merge(existing = {}, incoming, { readField }) {
            const merged = { ...existing } as Record<string, CommissionStructure>
            const incomingArray = incoming as CommissionStructure[]

            incomingArray?.forEach((item) => {
              const commissionStructureId = readField('commission_structure_id', item) as string
              merged[commissionStructureId] = item
            })

            return merged
          },
          // Return all items stored so far from the dictionary when reading from cache,
          // to avoid ambiguities about the order of the items.
          read(existing: Record<string, CommissionStructure>) {
            return existing && Object.values(existing)
          },
        },
      },
    },
    CommissionStructureResponse: {
      fields: {
        commission_structures: {
          keyArgs: ['commission_structure_id'],
          // The BE api returns us bookmarks that do not go up necessarily in order,
          // For example, bookmark of 30 can be followed by 61
          // So indexed based merging is not possible here because in the example above 60th item in the array would be emtpy
          // Hence use a dictionary based approach when writing to cache
          merge(existing = {}, incoming, { readField }) {
            const merged = { ...existing } as Record<string, CommissionStructure>
            const incomingArray = incoming as CommissionStructure[]

            incomingArray.forEach((item) => {
              const commissionStructureId = readField('commission_structure_id', item) as string
              merged[commissionStructureId] = item
            })

            return merged
          },
          // Return all items stored so far from the dictionary when reading from cache,
          // to avoid ambiguities about the order of the items.
          read(existing: Record<string, CommissionStructure>) {
            return existing && Object.values(existing)
          },
        },
      },
    },
    GetLedgersResponse: {
      fields: {
        ledgers: {
          merge(existing = [], incoming) {
            return unionWith<{ __ref: string }>(existing, incoming, (a, b) => a.__ref === b.__ref)
          },
        },
      },
    },
    Organisation: {
      fields: {
        panel_management: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    OrganisationResponse: {
      fields: {
        organisations: {
          merge(existing = [], incoming, { variables }) {
            const offset = variables?.bookmark || 0
            return mergeWithOffset({ existing, incoming, offset })
          },
        },
      },
    },
    MiOrganisationsResponse: {
      fields: {
        organisations: {
          merge(existing = [], incoming, { variables }) {
            // If we specify org ID, we are adding a specific org into the list
            // (Probably because we just created it)
            // so we put it first
            if (variables?.filter.org_ids) {
              return [...incoming, ...existing]
            }
            const offset = variables?.filter?.bookmark || 0
            return mergeWithOffset({ existing, incoming, offset })
          },
        },
      },
    },
    ReportsRevenueTotalsItem: {
      keyFields: false,
    },
    ReportsRevenueResponse: {
      keyFields: false,
    },
    MICase: {
      keyFields: ['case_id'],
    },
    GetMiCaseListResponse: {
      fields: {
        cases: {
          merge(existing = [], incoming, { variables }) {
            if (variables?.input?.bookmark) {
              // NB this is not to deal with dupes in the data - this function gets called repeatedly
              // for pages after the first for reasons we have not been able to discern.
              return unionWith<{ __ref: string }>(existing, incoming, (a, b) => a.__ref === b.__ref)
            }
            return incoming
          },
        },
      },
    },
    MIDocumentSummaryResponse: {
      fields: {
        documents: {
          merge(existing = [], incoming, { variables }) {
            if (variables?.filters?.bookmark >= 0) {
              return unionWith<{ __ref: string }>(existing, incoming, (a, b) => a.__ref === b.__ref)
            }
            return incoming
          },
        },
      },
    },
    KbaChoice: {
      keyFields: ['id', 'text'],
    },
    GetFilteredNoteNextPage: {
      keyFields: false,
    },
    GetFilteredNoteResponse: {
      fields: {
        notes: {
          merge(existing = [], incoming) {
            let merged = existing

            if (incoming?.length) {
              merged = unionWith<{ __ref: string }>(existing, incoming, (a, b) => a.__ref === b.__ref)
            }

            return merged
          },
        },
      },
    },
    GetReportsFilterOptions: {
      keyFields: false,
    },
  },
}

export default new InMemoryCache(cacheConfig)
