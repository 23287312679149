import React, { memo, useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { useForm } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { testHandle, useFormatMessage } from '@acre/utils'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  CenteredLoadingSpinner,
  COMPACT,
  DropdownOption,
  MasonryCell,
} from '@acre/design-system'
import type { MortgageClubFeeCalculated, NewFee } from '@acre/graphql'

import { InputTableBuilder, Row, TableStructure } from '../../InputTableBuilder'
import { IllustrationModalFormData } from '../IllustrationModal.helpers'
import {
  caseFeeOptions,
  convertFeesToRows,
  generateRow,
  getFormattedValues,
  messagePrefix,
  updateRowFieldTypes,
} from './Fees.helpers'
import MortgageClubSection from './MortgageClubSection'
import { FormSectionHeader } from '../IllustrationModal.styles'

export interface FeesFormProps {
  initialCaseFees: NewFee[]
  mortgageClubsDisabled?: boolean
  preferredClubsLoading?: boolean
  options: DropdownOption[]
  mortgageClubProcFees?: MortgageClubFeeCalculated[]
}

const FeesForm = ({
  initialCaseFees,
  options,
  mortgageClubProcFees,
  mortgageClubsDisabled = false,
  preferredClubsLoading = false,
}: FeesFormProps) => {
  const intl = useIntl()
  const form = useForm<IllustrationModalFormData>()
  const formatMessage = useFormatMessage()

  const [values, setValues] = useState<TableStructure>({
    columnHeads: [
      { heading: formatMessage(`${messagePrefix}.term`), width: '60%' },
      { heading: formatMessage(`${messagePrefix}.amount`), width: '30%' },
      { heading: '', width: '10%' },
    ],
    rows: convertFeesToRows(intl, initialCaseFees, caseFeeOptions),
  })

  const addRow = useCallback(() => {
    setValues({
      ...values,
      rows: [...values.rows, generateRow(intl)],
    })
  }, [intl, values])

  const handleChange = useCallback(
    (newValues: TableStructure) => {
      if (values !== newValues) {
        setValues({
          columnHeads: newValues.columnHeads,
          rows: updateRowFieldTypes(newValues) as Row[],
        })

        form.change('Fees', getFormattedValues(newValues))
      }
    },
    [values, form],
  )

  return (
    <>
      <MasonryCell data-testid={testHandle('ProductFeesForm')} sx={{ px: 0, pt: 4, pb: 1 }}>
        <Box mb={2} ml={4}>
          <FormSectionHeader data-testid={testHandle('ProductAdviserFeesHeading')}>
            {formatMessage('cases.products.options.generateIllustration.adviserFees')}
          </FormSectionHeader>
        </Box>

        <InputTableBuilder id="Fees" onChange={handleChange} values={values} />

        <Box ml={4} mt={1}>
          <Button
            id="AddFee"
            type={BUTTON_TYPE_BUTTON}
            buttonStyle={BUTTON_SECONDARY}
            variant={COMPACT}
            onClick={addRow}
          >
            <FormattedMessage id={`${messagePrefix}.addButton`} />
          </Button>
        </Box>
      </MasonryCell>
      <MasonryCell sx={{ py: 4 }}>
        <Box mb={2}>
          <FormSectionHeader data-testid={testHandle('MortgageClubHeading')}>
            <FormattedMessage id="cases.products.options.generateIllustration.mortgageClub" />
          </FormSectionHeader>
        </Box>

        {preferredClubsLoading ? (
          <CenteredLoadingSpinner />
        ) : (
          <MortgageClubSection
            disabled={mortgageClubsDisabled}
            options={options}
            mortgageClubProcFees={mortgageClubProcFees}
          />
        )}
      </MasonryCell>
    </>
  )
}

const FeesFormMemo = memo(FeesForm)

export default FeesFormMemo
