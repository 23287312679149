import React, { memo, useCallback, useState } from 'react'
import { UnsetMarginWrapper } from '@broker-crm-common'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'

import { Button, Card, CheckboxNew, Divider, H2, PdfRenderer } from '@acre/design-system'

import { PdfDocWrapper } from './Eula.styles'

type EulaProps = {
  headerText: JSX.Element
  buttonText: JSX.Element
  checkboxText: JSX.Element
  doc: string
  loading: boolean
  onSubmit: () => void
}

type ControlsProps = Pick<EulaProps, 'buttonText' | 'checkboxText' | 'loading' | 'onSubmit'>

const Controls = ({ checkboxText, buttonText, loading, onSubmit }: ControlsProps) => {
  const theme = useTheme()
  const [isSignedEula, setSignedEula] = useState<boolean>(false)
  const handleClick = useCallback(() => {
    setSignedEula(!isSignedEula)
  }, [isSignedEula])

  return (
    <>
      <CheckboxNew id={'123'} label={checkboxText} checked={isSignedEula} onChange={() => handleClick()} />
      <Box my={theme.spacers.size16}>
        <Button
          id="SubmitEulaConfirmation"
          type="button"
          buttonStyle="primary"
          fullWidth
          disabled={!isSignedEula}
          isLoading={loading}
          onClick={() => onSubmit()}
        >
          {buttonText}
        </Button>
      </Box>
    </>
  )
}

const EulaForm = ({ headerText, buttonText, checkboxText, doc, loading, onSubmit }: EulaProps) => {
  const theme = useTheme()

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box width={theme.wrappers.large} height={theme.wrappers.large} my={theme.spacers.size128}>
        <Card padding={`${theme.spacers.size32} ${theme.spacers.size48}`} border={false}>
          <Box mb={theme.spacers.size32}>
            <H2 styledAs="h4">{headerText}</H2>
          </Box>

          <UnsetMarginWrapper margin="size48">
            <Divider />
          </UnsetMarginWrapper>

          <Box mb={theme.spacers.size16} mt={theme.spacers.size32}>
            <PdfDocWrapper>
              <PdfRenderer id="Eula" base64={doc} showControls={false} showBorder={false} />
            </PdfDocWrapper>

            <Box mt={theme.spacers.size32}>
              <Controls checkboxText={checkboxText} buttonText={buttonText} loading={loading} onSubmit={onSubmit} />
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default memo(EulaForm)
