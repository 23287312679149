import React from 'react'
import { camelCase } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { CaseStatus } from '@acre/graphql'

import { Variant } from '../../utils'
import testHandle from '../../utils/testHandle'
import Tag from '../Tag'
import { GreyText } from '../Text'
import { STATUS_INTL, STATUS_TAG_COLOUR } from './CaseStatusTag.helpers'

export type CaseStatusProps = {
  status?: CaseStatus | null
  variant?: Variant
}

const CaseStatusTag = ({ status, variant }: CaseStatusProps) => {
  const intl = useIntl()
  const formatMessage = (id: string) => intl.formatMessage({ id })

  if (status) {
    return (
      <Tag
        id="CaseOverviewCaseType"
        text={formatMessage(STATUS_INTL[status])}
        colourID={STATUS_TAG_COLOUR[status]}
        data-testid={testHandle(`case-${camelCase(status)}`)}
        variant={variant}
      />
    )
  }
  return (
    <GreyText>
      <FormattedMessage id="generic.noInformation" />
    </GreyText>
  )
}

export default CaseStatusTag
