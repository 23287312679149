import { styled } from '@mui/material'

export const P = styled('p')(({ theme }) => ({
  paddingTop: theme.display.ms0,
}))

export const RedCode = styled('code')`
  padding: 10px;
  color: red;
  margin-top: 10px;
  display: block;
  word-break: break-all;
`
export const Code = styled('code')`
  white-space: pre;
  display: block;
  font-family: monospace;
  margin-bottom: 20px;
  overflow: auto;
`

export const Container = styled('div')`
  width: 100%;
  height: 100vh;
`

export const CardContainer = styled('div')(
  ({ theme }) => `
  max-width: 800px;
  padding: ${theme.spacing(2)}
  margin-left: auto;
  margin-right: auto;
`,
)

export const Logo = styled('div')(
  ({ theme }) => `
  display: flex;
  padding-left: ${theme.spacing(2.5)};
  padding-right: ${theme.spacing(2.5)};
`,
)
