import { styled } from '@mui/material'

export const Div = styled('div')<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
  width: 100%;
  position: relative;
  z-index: ${theme.display.overflowZIndex};
  display: ${!isVisible ? 'none' : 'block'};
`,
)
export const Container = styled('div')<{
  align: 'left' | 'right'
  maxSize: boolean
  overflowYVisible: boolean
  overflowMargin?: boolean
}>(
  ({ theme, align, maxSize, overflowMargin, overflowYVisible }) => `
  position: absolute;
  top: ${theme.spacing(1)};
  ${align}: 0;
  max-width: ${maxSize ? theme.display.popOverWidthMax : 'fit-content'};
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: ${theme.display.borderRadius};
  border: 1px solid ${theme.colours.baseLight};
  margin-top: ${overflowMargin && '0.7rem;'};
  max-height: 30vh;
  overflowX: hidden;
  overflow-y: ${overflowYVisible ? 'visible' : 'scroll'};
`,
)
export const LinkStyle = styled('div')(
  ({ theme }) => `
  a {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: ${theme.display.ms1};
    text-decoration: none;
    color: ${theme.colours.base};

    &:hover {
      color: white;
      text-decoration: none;
      background-color: ${theme.colours.primary};
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      color: ${theme.colours.baseLight};
    }
  }
`,
)
