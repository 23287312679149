import React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'

import { CustomTippyStyles, InnerContainer, OuterContainer } from './Tooltip.styles'

export type TooltipProps = TippyProps & { hide?: boolean; wide?: boolean }

const Tooltip = (props: TooltipProps) => {
  const { children, hide, wide, ...rest } = props
  const defaultProps: TippyProps = {
    allowHTML: true,
    theme: 'acre',
    trigger: 'click',
    maxWidth: wide ? '530px' : '350px',
  }
  const tippyProps: TippyProps = { ...defaultProps, ...rest }

  // If hide is true, then return just the children that tippy is wrapper around
  if (hide) {
    return <>{children}</>
  }
  return (
    // stop propagation of any clicks inside this container
    <OuterContainer onClick={(e) => e.stopPropagation()}>
      <CustomTippyStyles wide={wide || false} />
      <Tippy {...tippyProps} className="acre">
        <InnerContainer>{children}</InnerContainer>
      </Tippy>
    </OuterContainer>
  )
}

export default Tooltip
