import {
  BenefitBasis,
  CoverUnits,
  DeferredPeriod,
  Frequency,
  IndexationType,
  PayoutType,
  PremiumType,
  ProtectionProductStatus,
  ProtectionProductType,
  SolutionProductType,
  UnderwritingLevel,
} from '@acre/graphql'

export const productTypeMap: Record<SolutionProductType | ProtectionProductType, string> = {
  // Solution product types (microservice)
  [SolutionProductType.ProductTypeInvalidProductType]: 'protection.productType.invalid',
  [SolutionProductType.ProductTypeTerm]: 'protection.productType.term',
  [SolutionProductType.ProductTypeGroupLife]: 'protection.businessProtection.benefitOptions.groupLife',
  [SolutionProductType.ProductTypeGroupPmi]: 'protection.businessProtection.benefitOptions.groupPMI',
  [SolutionProductType.ProductTypeGroupAsu]: 'protection.businessProtection.benefitOptions.groupASU',
  [SolutionProductType.ProductTypeGroupCic]: 'protection.businessProtection.benefitOptions.groupCIC',
  [SolutionProductType.ProductTypeGroupIp]: 'protection.businessProtection.benefitOptions.groupIP',
  [SolutionProductType.ProductTypeRelevantLife]: 'protection.businessProtection.benefitOptions.relevantLife',
  [SolutionProductType.ProductKeyMan]: 'protection.businessProtection.benefitOptions.keyMan',
  [SolutionProductType.ProductTypePmi]: 'protection.businessProtection.benefitOptions.PMI',
  [SolutionProductType.ProductTypeShareholderProtection]: 'protection.businessProtection.benefitOptions.groupSP',
  [ProtectionProductType.ProductTypeGroupPrivateMedicalInsurance]:
    'protection.businessProtection.benefitOptions.productTypeGroupPrivateMedicalInsurance',
  // Protection product types (luther)
  [ProtectionProductType.ProductTypeInvalid]: 'protection.productType.invalid',
  [ProtectionProductType.ProductTypeDecreasingTerm]: 'protection.productType.decreasingTermAssurance',
  [ProtectionProductType.ProductTypeLevelTerm]: 'protection.productType.levelTermAssurance',
  [ProtectionProductType.ProductTypeIncomeProtection]: 'protection.productType.incomeProtection',
  [ProtectionProductType.ProductTypeFamilyIncomeBenefit]: 'protection.productType.familyIncomeBenefit',
  [ProtectionProductType.ProductTypeWholeOfLife]: 'protection.productType.wholeOfLife',
  [ProtectionProductType.ProductTypeMortgageProtection]: 'protection.productType.mortgageProtection',
  [ProtectionProductType.ProductTypeMortgagePaymentProtection]: 'protection.productType.mortgagePaymentProtection',
  [ProtectionProductType.ProductTypeSickPay]: 'protection.productType.sickPay',
  [ProtectionProductType.ProductTypeOther]: 'generic.other',
  [ProtectionProductType.ProductTypeChildAccidentSickness]: 'protection.productType.childAccidentSickness',
  [ProtectionProductType.ProductTypeCoreAccidentSickness]: 'protection.productType.coreAccidentSickness',
  [ProtectionProductType.ProductTypeSportsLifestyle]: 'protection.productType.sportsLifestyle',
  [ProtectionProductType.ProductTypeSpecialistHealthcareCover]: 'protection.productType.specialistHealthcareCover',
  [ProtectionProductType.ProductTypePrivateMedicalInsurance]: 'protection.productType.pmi',
  [ProtectionProductType.ProductTypeEmployeeBenefit]: 'protection.productType.employeeBenefit',
}

export const productTypeMapShorthand: Record<SolutionProductType | ProtectionProductType, string> = {
  [SolutionProductType.ProductTypeInvalidProductType]: 'protection.productType.invalid',
  [SolutionProductType.ProductTypeIncomeProtection]: 'protection.common.ip',
  [SolutionProductType.ProductTypeTerm]: 'protection.common.term',
  [SolutionProductType.ProductTypeLevelTerm]: 'protection.common.term',
  [SolutionProductType.ProductTypeDecreasingTerm]: 'protection.common.term',
  [SolutionProductType.ProductTypeGroupLife]: 'protection.businessProtection.benefitOptions.groupLife',
  [SolutionProductType.ProductTypeGroupPmi]: 'protection.businessProtection.benefitOptions.groupPMI',
  [SolutionProductType.ProductTypeGroupAsu]: 'protection.businessProtection.benefitOptions.groupASU',
  [SolutionProductType.ProductTypeGroupCic]: 'protection.businessProtection.benefitOptions.groupCIC',
  [SolutionProductType.ProductTypeGroupIp]: 'protection.businessProtection.benefitOptions.groupIP',
  [SolutionProductType.ProductTypeRelevantLife]: 'protection.businessProtection.benefitOptions.relevantLife',
  [SolutionProductType.ProductKeyMan]: 'protection.businessProtection.benefitOptions.keyMan',
  [SolutionProductType.ProductTypePmi]: 'protection.businessProtection.benefitOptions.PMI',
  [SolutionProductType.ProductTypeShareholderProtection]: 'protection.businessProtection.benefitOptions.groupSP',
  [ProtectionProductType.ProductTypePrivateMedicalInsurance]: 'protection.productType.privateMedicalInsurance',
  [ProtectionProductType.ProductTypeGroupPrivateMedicalInsurance]:
    'protection.productType.productTypeGroupPrivateMedicalInsurance',
  // unsupported type, avoiding unreadable values
  [ProtectionProductType.ProductTypeInvalid]: '',
  [ProtectionProductType.ProductTypeFamilyIncomeBenefit]: 'protection.common.fib',
  [ProtectionProductType.ProductTypeWholeOfLife]: 'protection.common.wol',
  [ProtectionProductType.ProductTypeMortgageProtection]: 'protection.productType.mortgageProtection',
  [ProtectionProductType.ProductTypeMortgagePaymentProtection]: 'protection.productType.mortgagePaymentProtection',
  [ProtectionProductType.ProductTypeSickPay]: 'protection.productType.sickPay',
  [ProtectionProductType.ProductTypeOther]: 'generic.other',
  [ProtectionProductType.ProductTypeChildAccidentSickness]: 'protection.productType.childAccidentSickness',
  [ProtectionProductType.ProductTypeCoreAccidentSickness]: 'protection.productType.coreAccidentSickness',
  [ProtectionProductType.ProductTypeSportsLifestyle]: 'protection.productType.sportsLifestyle',
  [ProtectionProductType.ProductTypeSpecialistHealthcareCover]: 'protection.productType.specialistHealthcareCover',
  [ProtectionProductType.ProductTypeEmployeeBenefit]: 'protection.productType.employeeBenefit',
}

export const payoutTypeMap: Record<PayoutType, string> = {
  [PayoutType.PayoutTypeInvalid]: 'generic.noInformation',
  [PayoutType.PayoutTypeLife]: 'protection.common.life',
  [PayoutType.PayoutTypeCic]: 'protection.common.cic',
  [PayoutType.PayoutTypeLifeOrEarlierCic]: 'protection.common.lifeOrEarlierCic',
}

export const payoutTypeMapShorthand: Record<PayoutType, string> = {
  [PayoutType.PayoutTypeInvalid]: 'generic.noInformation',
  [PayoutType.PayoutTypeLife]: 'protection.common.life',
  [PayoutType.PayoutTypeCic]: 'protection.common.cic',
  [PayoutType.PayoutTypeLifeOrEarlierCic]: 'protection.common.loec',
}

export const coverUnitsMap: Record<CoverUnits, string> = {
  [CoverUnits.CoverUnitsInvalid]: 'generic.noInformation',
  [CoverUnits.CoverUnitsUnit_1]: 'protection.coverUnits.one',
  [CoverUnits.CoverUnitsUnit_2]: 'protection.coverUnits.two',
  [CoverUnits.CoverUnitsUnit_3]: 'protection.coverUnits.three',
  [CoverUnits.CoverUnitsUnit_4]: 'protection.coverUnits.four',
  [CoverUnits.CoverUnitsUnit_5]: 'protection.coverUnits.five',
}

export const underwritingLevelMap: Record<UnderwritingLevel, string> = {
  [UnderwritingLevel.UnderwritingLevelFullMedicalUnderwriting]:
    'protection.underwritingLevel.underwritingLevelFullMedicalUnderwriting',
  [UnderwritingLevel.UnderwritingLevelMedicalHistoryDisregarded]:
    'protection.underwritingLevel.underwritingLevelMedicalHistoryDisregarded',
  [UnderwritingLevel.UnderwritingLevelMoratorium]: 'protection.underwritingLevel.underwritingLevelMoratorium',
  [UnderwritingLevel.UnderwritingLevelInvalid]: 'generic.noInformation',
  [UnderwritingLevel.UnderwritingLevelContinuedPersonalMedicalExclusions]:
    'protection.underwritingLevel.underwritingLevelContinuedPersonalMedicalExclusions',
  [UnderwritingLevel.UnderwritingLevelContinuedMoratorium]:
    'protection.underwritingLevel.underwritingLevelContinuedMoratorium',
}

export const premiumTypeMap: Record<PremiumType, string> = {
  [PremiumType.PremiumTypeInvalid]: 'protection.premiumType.invalid',
  [PremiumType.PremiumTypeReviewable]: 'protection.premiumType.reviewable',
  [PremiumType.PremiumTypeGuaranteed]: 'protection.premiumType.guaranteed',
}

export const indexationMap: Record<IndexationType, string> = {
  [IndexationType.IndexationTypeInvalid]: 'protection.indexation.invalid',
  [IndexationType.IndexationTypeAwei]: 'protection.indexation.awei',
  [IndexationType.IndexationTypeFixed]: 'protection.indexation.fixed',
  [IndexationType.IndexationTypeLevel]: 'protection.indexation.level',
  [IndexationType.IndexationTypeRpi]: 'protection.indexation.rpi',
}

export const benefitBasisMap: Record<BenefitBasis, string> = {
  [BenefitBasis.BenefitBasisMonthly]: 'protection.benefitBasis.monthly',
  [BenefitBasis.BenefitBasisMaximum]: 'protection.benefitBasis.maximum',
}

export const premiumFrequencyMap: Record<Frequency, string> = {
  [Frequency.Invalid]: 'protection.productPremiumFrequency.invalid',
  [Frequency.Once]: 'protection.productPremiumFrequency.invalid',
  [Frequency.Quarterly]: 'protection.productPremiumFrequency.invalid',
  [Frequency.Weekly]: 'protection.productPremiumFrequency.invalid',
  [Frequency.Undefined]: 'protection.productPremiumFrequency.invalid',
  [Frequency.Monthly]: 'protection.productPremiumFrequency.monthly',
  [Frequency.Annually]: 'protection.productPremiumFrequency.annual',
}

export const deferredPeriodMap: Record<DeferredPeriod, string> = {
  [DeferredPeriod.DeferredPeriodZero]: 'protection.deferredPeriod.zero',
  [DeferredPeriod.DeferredPeriodOneWeek]: 'protection.deferredPeriod.oneWeek',
  [DeferredPeriod.DeferredPeriodTwoWeeks]: 'protection.deferredPeriod.twoWeeks',
  [DeferredPeriod.DeferredPeriodOneMonth]: 'protection.deferredPeriod.oneMonth',
  [DeferredPeriod.DeferredPeriodTwoMonths]: 'protection.deferredPeriod.twoMonths',
  [DeferredPeriod.DeferredPeriodThreeMonths]: 'protection.deferredPeriod.threeMonths',
  [DeferredPeriod.DeferredPeriodSixMonths]: 'protection.deferredPeriod.sixMonths',
  [DeferredPeriod.DeferredPeriodOneYear]: 'protection.deferredPeriod.oneYear',
  [DeferredPeriod.DeferredPeriodTwoYears]: 'protection.deferredPeriod.twoYears',
}

export const protectionProductStatusMap = {
  [ProtectionProductStatus.Current]: 'protection.productStatus.current',
  [ProtectionProductStatus.InvalidStatus]: 'protection.productStatus.invalid',
  [ProtectionProductStatus.Proposed]: 'protection.productStatus.proposed',
  [ProtectionProductStatus.Selected]: 'protection.productStatus.selected',
  [ProtectionProductStatus.NotProceeding]: 'protection.productStatus.notProceeding',
}
