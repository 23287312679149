import { FieldReadFunction, Reference } from '@apollo/client'

import {
  Client,
  isNonNaturalClient,
  LedgerPaymentSourceDetail,
  LedgerPaymentSourceId,
  LedgerSourceHydrationType,
  Organisation,
  User,
} from '@acre/graphql'

const read: FieldReadFunction<string | null> = (_, { readField }) => {
  const sourceIdRef = readField<LedgerPaymentSourceId>('sourceId')
  const hydrationType = readField('hydrationType', sourceIdRef)

  const details = readField<LedgerPaymentSourceDetail>('details', sourceIdRef)

  if (hydrationType === LedgerSourceHydrationType.Client) {
    const clients = readField<Reference[]>('clients')

    const nonNaturalClientRef = clients?.find((clientRef) => {
      const clientDetailsRef = readField<Reference>('details', clientRef)
      const isNaturalPerson = readField<Client['is_natural_person']>('is_natural_person', clientDetailsRef)
      const organisationName = readField<Client['organisation_name']>('organisation_name', clientDetailsRef)
      return isNonNaturalClient({ is_natural_person: isNaturalPerson, organisation_name: organisationName })
    })

    if (nonNaturalClientRef) {
      const clientDetailsRef = readField<Reference>('details', nonNaturalClientRef)
      const organisationName = readField<Client['organisation_name']>('organisation_name', clientDetailsRef)
      return organisationName
    }

    return clients?.reduce<string>((acc: string, clientRef: Reference, index: number) => {
      const clientDetailsRef = readField<Reference>('details', clientRef)
      const firstName = readField<Client['first_name']>('first_name', clientDetailsRef)
      const lastName = readField<Client['last_name']>('last_name', clientDetailsRef)
      return acc + `${index === 0 ? '' : ', '}${firstName} ${lastName}`
    }, '')
  }

  if (hydrationType === LedgerSourceHydrationType.Organisation) {
    const orgRef = details
    const name = readField<Organisation['name']>('name', orgRef)
    return name
  }
  if (hydrationType === LedgerSourceHydrationType.User) {
    const userRef = details
    const firstName = readField<User['first_name']>('first_name', userRef)
    const lastName = readField<User['last_name']>('last_name', userRef)
    if (firstName || lastName) {
      return `${firstName ? firstName + ' ' : ''}${lastName}`
    }
  }
  return null
}

export default read
