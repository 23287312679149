import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import TextareaAutosize from 'react-textarea-autosize'

export const NoteHeaderContainer = styled('div')(
  ({ theme }) => `
  text-align: left;
  background: ${theme.colours.foreground};
  height: auto;
  width: 100%;
`,
)

export const NoteWrapper = styled('div')(
  ({ theme }) => `
  background: ${theme.colours.foreground};
  box-sizing: border-box;
  border-radius: ${theme.display.borderRadius};
  box-shadow: ${theme.boxShadow};
  color: ${theme.colours.base};
  border: ${`1px solid ${theme.colours.baseLight}`};
  &.isEditModeOn {
    border: ${`1px solid ${theme.colours.primary}`};
  }
`,
)

export const FormNoteWrapper = styled(NoteWrapper)(
  ({ theme }) => `
  transition: border 0.1s ease-out;
  &:focus-within {
    border: ${`1px solid ${theme.colours.primary}`};
  }
`,
)

export const InputNote = styled(TextareaAutosize)(
  ({ theme }) => `
  color: ${theme.colours.base};
  transition: all 0.1s ease-in-out;
  line-height: 1.6;
  font-size: 16px;
  appearance: none;
  width: 100%;
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;

  &::placeholder {
    color: ${theme.colours.baseLight};
  }
`,
)

export const ReviewDate = styled('span')(
  ({ theme }) => `
  color: ${theme.colours.baseMid};
  margin-top: ${theme.spacing(1)};
  display: block;
`,
)

export const FlagReview = styled(Box)(
  ({ theme }) => `
  position: relative;

  & + &:before {
    content: '';
    display: block;
    background: ${theme.colours.baseLight};
    height: 1px;
    position: absolute;
    top: -${theme.spacing(2)};
    right: 0;
    left: 0;
  }
`,
)
