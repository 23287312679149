import { styled, Theme } from '@mui/material'
import { rem } from 'polished'

import { Maybe } from '@acre/graphql'

import Button from '../components/Button'
import { IconName } from '../components/FeatherIcon'
import { Variant } from '../utils/constants'

export const ProtectedButton = styled(Button)<{ variant?: Variant }>(
  ({ theme, variant }) => `
  max-width: 80px;
  width: 100%;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid ${theme.colours.baseLight};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: ${theme.display.borderRadius};
  border-bottom-right-radius: ${theme.display.borderRadius};
  color: ${theme.colours.primary};
  &.error {
    color: ${theme.colours.danger};
    border-left: ${`1px solid ${theme.colours.danger}`};
  }
  &:hover {
    background-color: ${theme.colours.primary10};
  }
  max-width: ${variant === 'compactLeftAligned' ? '61px' : ''};
`,
)

export const ProtectedFieldsContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  &:focus-within {
    input,
    button,
    div,
    & {
      border-color: ${theme.colours.primary};
    }
  }

  input,
  & > div {
    border: none;
  }
  border: 1px solid ${theme.colours.baseLight};
  border-radius: ${theme.display.borderRadius};

  &.error {
    input,
    button,
    & {
      border-color: ${theme.colours.danger};
    }
    button {
      color: ${theme.colours.danger};
    }
  }
`,
)

export const StyledInputAddonRight = styled('label')<{ variant?: Variant; width?: string }>(
  ({ theme, variant }) => `
  color: ${theme.colours.base};
  background-color: ${theme.colours.baseExtraLight};
  border: 1px solid ${theme.colours.baseLight};
  border-top-right-radius: ${theme.display.borderRadius};
  border-bottom-right-radius: ${theme.display.borderRadius};
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;

  font-size: 1rem;
  line-height: 1.6;

  order: 1;
  height: ${theme.display.inputHeight};
  padding: 0 ${theme.spacing(2)};
  ${
    (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
    compactAddonStyles(theme)
  };
`,
)

export const StyledInputAddonLeft = styled(StyledInputAddonRight as any)<{ variant?: Variant }>(
  ({ theme, variant }) => `
  border-radius: ${theme.display.borderRadius} 0 0 ${theme.display.borderRadius};
  ${variant?.includes('compact') && compactAddonStyles(theme)};
`,
)

export const LabelStyles = styled('label')(
  ({ theme }) => `
  color: ${theme.colours.base};
  display: block;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: ${theme.spacing(0.5)};
`,
)

export const FormControlWrapper = styled('div')<{ variant?: Variant }>(
  ({ theme, variant }) => `
  &.error {
    input,
    label {
      border-color: ${theme.colours.danger} !important;
    }
  }
  ${variant === 'compact' && formControlWrapperCompactStyles(theme)}
  ${variant === 'compactLeftAligned' && formControlWrapperNewCompactStyles(theme)}
`,
)

const formControlWrapperCompactStyles = (theme: Theme) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div > div {
    width: 100%;
  }
  & > label {
    width: 33%;
    min-width: 33%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    transform: translateX(-${theme.spacing(1)});
  }
`

const formControlWrapperNewCompactStyles = (theme: Theme) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div > div {
    width: 100%;
  }
  & > label {
    width: 30%;
    min-width: 30%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    margin-right: ${theme.spacing(1)};
  }
`

export const LabelAndInputWrapper = styled('div')<{
  variant?: Variant
  hasValue?: boolean
  iconName?: IconName
  labelWidth?: Maybe<string>
}>(
  ({ theme, variant, hasValue, iconName, labelWidth }) => `
  position: relative;
  display: flex;
  flex-direction: column;

  ${variant === 'compact' && labelAndInputWrapperCompactStyles(theme)};
  ${variant === 'compactRightAligned' && labelAndInputWrapperRightStyles()};
  ${
    variant === 'compactLeftAligned' || variant === 'compactLeftNarrow'
      ? labelAndInputWrapperNewCompactStyles(theme, variant, labelWidth)
      : null
  };
  ${iconName && variant === 'compactTopAligned' && IconStyles(theme)};
  ${hasValue && hasValueStyles(theme)};
`,
)

export const hasValueStyles = (theme: Theme) => `
  & input {
    border-color: ${theme.colours.primary};
    color: ${theme.colours.primary};
    padding-right: ${theme.spacing(4)};
  }
`

export const IconStyles = (theme: Theme) => `
  & .icon {
    position: absolute;
    right: ${theme.spacing(1.5)};
    bottom: ${theme.spacers.size0};
  }
`

export const labelAndInputWrapperCompactStyles = (theme: Theme) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  & > div {
    width: 100%;
  }
  & > label,
  legend {
    width: 33%;
    min-width: 33%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    transform: translateX(-${theme.spacing(1)});
  }
`

const setWidth = (labelWidth: Maybe<string>, variant?: Variant) => {
  if (labelWidth) {
    return labelWidth
  } else if (variant === 'compactLeftNarrow') {
    return '20%'
  } else return '30%'
}

const labelAndInputWrapperNewCompactStyles = (theme: Theme, variant?: Variant, labelWidth?: Maybe<string>) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > label,
  legend {
    min-width: ${setWidth(labelWidth, variant)};
    width: ${setWidth(labelWidth, variant)};
    text-align: ${variant === 'compactLeftNarrow' ? 'right' : 'left'};
    font-size: 12px;
  }
`

export const labelAndInputWrapperRightStyles = () => `
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > label
`

export const commonControlStyles = (theme: Theme) => `
  transition: all 0.1s ease-in-out;

  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  background-color: ${theme.colours.foreground};
  border-radius: ${theme.display.borderRadius};
  border: 1px solid ${theme.colours.baseLight};
  box-sizing: border-box;
  font-size: 16px;
  padding: ${rem(11)} 1rem;
  color: ${theme.colours.base};
  line-height: ${rem(26)};
  width: 100%;
  min-width: 0;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colours.baseMid};
  }

  /* For IE this declaration needs to be separate */
  ::-ms-input-placeholder {
    color: ${theme.colours.baseMid};
  }

  &:focus,
  &:active:not(:disabled):not(.error) {
    border-color: ${theme.colours.primary};
    outline: none;
  }

  &:disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }
`

const compactAddonStyles = (theme: Theme) => `
  padding: 0 ${theme.spacing(1.5)};
  height: ${theme.spacing(4)};
  font-size: 14px;
`

export const NumberInputForAddon = styled('input')(
  ({ theme }) => `
  ${commonControlStyles(theme)}
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-width: 0;
  height: ${theme.spacing(4)};
  font-size: 14px;
  padding-left: ${theme.spacing(1.5)};
`,
)
