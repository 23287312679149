import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ERROR_CANNOT_COMPLETE_CASE, ErrorProvider, useFormatMessage } from '@acre/utils'
import {
  CaseStatus,
  formatAsCurrency,
  GetFeesForCaseDocument,
  GetFeesForCaseQuery,
  GetMortgageProductDocument,
  GetMortgageProductQuery,
  Maybe,
  MortgageStatus,
  ProtectionProduct,
} from '@acre/graphql'
import { BUTTON_PRIMARY, GreyText, H2, Modal, ModalFooter, Table, Tbody, Td, Th, Thead, Tr } from '@acre/design-system'

import { useUpdateCaseStatus } from '../../../../../../../utils/hooks/useUpdateCaseStatus'
import { getTotal, messagePrefix } from '../CompleteTransitionTask.helpers'
import { getMortgageFeesWithLabels, getProtectionFeesWithLabels } from './ConfirmFeesModal.helpers'

type Props = {
  caseId: string
  onSubmit?: () => any
  onClose: () => void
  isModalOpen: boolean
  protectionProducts?: Maybe<ProtectionProduct[]>
}

const ConfirmFeesModal = ({ caseId, onSubmit, onClose, isModalOpen, protectionProducts }: Props) => {
  const formatMessage = useFormatMessage()
  const handleErrorUpload = useCallback(() => {
    const message = formatMessage(ERROR_CANNOT_COMPLETE_CASE)
    ErrorProvider.showError(new Error(message))
  }, [formatMessage])

  const handleComplete = useCallback(async () => {
    onSubmit && (await onSubmit())
    onClose()
  }, [onClose, onSubmit])

  const [updateCaseStatus, { loading: isLoading }] = useUpdateCaseStatus(caseId, handleComplete, handleErrorUpload)

  const handleSubmit = useCallback(
    async () =>
      await updateCaseStatus({
        variables: {
          id: caseId,
          input: {
            update: CaseStatus.Complete,
          },
        },
      }),
    [caseId, updateCaseStatus],
  )

  const { data: mortgageData, loading } = useQuery<GetMortgageProductQuery>(GetMortgageProductDocument, {
    variables: {
      id: caseId,
    },
  })

  const selectedMortgage = useMemo(() => {
    return loading
      ? null
      : mortgageData?.case.details.mortgages?.find((mortgage) => mortgage.status === MortgageStatus.StatusSelected)
  }, [mortgageData, loading])

  const { data: feesData } = useQuery<GetFeesForCaseQuery>(GetFeesForCaseDocument, {
    variables: {
      caseId: caseId,
      mortgageId: selectedMortgage?.mortgage_id,
    },
    skip: !selectedMortgage?.mortgage_id,
  })

  const mortgageFeesWithLabels = useMemo(
    () => getMortgageFeesWithLabels(formatMessage, selectedMortgage, feesData),
    [feesData, formatMessage, selectedMortgage],
  )

  const protectionFeesWithLabels = useMemo(
    () => (protectionProducts ? getProtectionFeesWithLabels(protectionProducts, formatMessage) : []),
    [formatMessage, protectionProducts],
  )

  const allFeesWithLabels = useMemo(
    () => [...mortgageFeesWithLabels, ...protectionFeesWithLabels],
    [mortgageFeesWithLabels, protectionFeesWithLabels],
  )
  const feesTotal = useMemo(() => getTotal(allFeesWithLabels.map((fee) => fee.feeAmount)), [allFeesWithLabels])

  return (
    <Modal
      className="modal-reduced-padding"
      id="ConfirmCaseTransitionModal"
      open={isModalOpen}
      onClose={onClose}
      header={{
        left: (
          <H2 styledAs="h5">
            <FormattedMessage id={`${messagePrefix}.confirmFinalCaseFees`} />
          </H2>
        ),
      }}
      footer={<ConfirmFeesModalFooter isLoading={isLoading} onSubmit={handleSubmit} onClose={onClose} />}
    >
      <Box mb={2}>
        {allFeesWithLabels.length > 0 ? (
          <Table gutterPadding="size12" fontSize={14} rowPadding="size8">
            <Thead borderTop={true}>
              <Tr variant="greyed-out">
                <Th>
                  <FormattedMessage id={`${messagePrefix}.feeType`} />
                </Th>
                <Th>
                  <FormattedMessage id={`${messagePrefix}.feeAmount`} />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* List all fees */}
              {allFeesWithLabels.map(({ feeType, feeAmount }) => (
                <Tr key={feeType}>
                  <Td>{feeType}</Td>
                  {/* use ! becasue feesToDisplay only contains valid feeAmounts */}
                  <Td>{formatAsCurrency(feeAmount!)} </Td>
                </Tr>
              ))}
              {/* Show total */}
              <Tr key="x" fontWeight="bold">
                <Td>
                  <FormattedMessage id={`${messagePrefix}.total`} />
                </Td>
                <Td>{formatAsCurrency(feesTotal)} </Td>
              </Tr>
            </Tbody>
          </Table>
        ) : (
          <Box display="flex" justifyContent="center">
            <GreyText>
              <FormattedMessage id={`${messagePrefix}.noFinalCaseFeesFound`} />
            </GreyText>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
export default ConfirmFeesModal

const ConfirmFeesModalFooter = ({
  isLoading,
  onSubmit,
  onClose,
}: {
  isLoading: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  return (
    <ModalFooter
      secondaryButton={{ onClick: () => onClose(), id: 'CancelReviewFinalCaseFees', isDisabled: false }}
      primaryButton={{
        id: 'ConfirmCaseTransitionToCOMPLETE',
        style: BUTTON_PRIMARY,
        onClick: () => onSubmit(),
        isLoading: isLoading,
        text: `${messagePrefix}.markCaseCompleted`,
        isDisabled: false,
      }}
    />
  )
}
