import React, { memo } from 'react'

import { testHandle } from '@acre/utils'
import { Table, Tbody, Td, Th, Thead, Tr } from '@acre/design-system'

import { ChangeValue, TableStructure, updateRows } from './InputTableBuilder.helpers'
import InputTableBuilderElement from './InputTableBuilderElement'
import { InputTableBuilderTd } from './InputTableBuilderElement.styles'

type Props = {
  id: string
  onChange: (values: TableStructure) => any
  values: TableStructure
  styled?: boolean
}

const _InputTableBuilder = ({ id, onChange, values }: Props) => {
  const handleChange = (id: number, value: ChangeValue) => {
    if (value) {
      const newRows = updateRows(values.rows, id, value)
      const newValues = { ...values, rows: newRows }
      onChange(newValues)
    }
  }

  const handleRemove = (values: TableStructure, index: number) => {
    // Use 'slice' rather than the in-place 'splice'
    const newRows = [...values.rows.slice(0, index), ...values.rows.slice(index + 1, values.rows.length)]
    onChange({
      ...values,
      rows: newRows,
    })
  }

  const columns = (values.columnHeads && values.columnHeads.length > 0 && values.columnHeads) || null

  return (
    <Table data-testid={testHandle(id)} gutterPadding="size32" rowPadding="size8" borderBottom>
      <Thead fontSize={12}>
        <Tr styled>
          {columns
            ? columns.map((item, index) => (
                <Th cellPadding="size8" key={index} padLeftForInputTable={true} width={item.width}>
                  {item.heading}
                </Th>
              ))
            : []}
        </Tr>
      </Thead>
      <Tbody fontSize={14}>
        {values.rows.map((row, rowIndex) => (
          <Tr key={`${row.rowId || rowIndex}`} cellPadding="size4">
            {row.columns.map((column, columnIndex) => {
              const props = {
                ...column,
                onChange: (values: ChangeValue) => handleChange(rowIndex, values),
                onRemove: () => handleRemove(values, rowIndex),
              }
              return (
                <Td key={columnIndex} padLeftForInputTable={true}>
                  <InputTableBuilderTd>
                    <InputTableBuilderElement {...props} />
                  </InputTableBuilderTd>
                </Td>
              )
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const InputTableBuilder = memo(_InputTableBuilder)

export default InputTableBuilder
