import { useMemo, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { Box, Typography, TypographyProps } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { isBusinessProtection, isGeneralProtection, useFormatMessage } from '@acre/utils'
import { formatAsCurrency, useGetUserQuery } from '@acre/graphql'
import { ButtonNewest } from '@acre/design-system'

import ChaseInfoModal from '../../../pages/Cases/CaseDetails/CaseOverview/ChaseInfoModal'
import { InfoTable } from './CaseInfo.styles'

export const CardTableCell = (props: Omit<TypographyProps<'td'>, 'component'>) => (
  <Typography component="td" {...props} fontSize="inherit" />
)

const sidebarInfoPrefix = 'cases.sidebarInfo'
const defaultValue = '-'

const CaseInfo = () => {
  const formatMessage = useFormatMessage()
  const { details: caseDetails } = useCaseContext()

  const [isModalOpen, setModalOpen] = useState(false)

  const isProtectionCase = isGeneralProtection(caseDetails?.preference_mortgage_reason)
  const isBusinessProtectionCase = isBusinessProtection(caseDetails?.preference_mortgage_reason)

  const { data: leadCaseOwner } = useGetUserQuery({
    variables: {
      id: caseDetails?.owner_user_id!,
      useClientApi: false,
    },
    skip: !caseDetails?.owner_user_id,
  })

  const advisor = useMemo(
    () =>
      caseDetails?.owner_user_id && leadCaseOwner?.user?.first_name && leadCaseOwner?.user?.last_name
        ? `${leadCaseOwner?.user?.first_name} ${leadCaseOwner?.user?.last_name}`
        : formatMessage(`${sidebarInfoPrefix}.unassigned`),
    [caseDetails?.owner_user_id, formatMessage, leadCaseOwner?.user?.first_name, leadCaseOwner?.user?.last_name],
  )

  const address = useMemo(
    () =>
      caseDetails?.preference_target_property
        ? `${caseDetails?.new_property?.details.address?.address1}, ${caseDetails?.new_property?.details.address?.postcode}`
        : defaultValue,
    [
      caseDetails?.new_property?.details.address?.address1,
      caseDetails?.new_property?.details.address?.postcode,
      caseDetails?.preference_target_property,
    ],
  )

  const purchasePrice = useMemo(
    () =>
      caseDetails?.new_property?.details.property_price
        ? formatAsCurrency(caseDetails?.new_property?.details.property_price)
        : defaultValue,
    [caseDetails?.new_property?.details.property_price],
  )

  const valuation = useMemo(
    () =>
      caseDetails?.new_property?.details.property_valuation
        ? formatAsCurrency(caseDetails?.new_property?.details.property_valuation)
        : defaultValue,
    [caseDetails?.new_property?.details.property_valuation],
  )

  const loanAmount = useMemo(
    () => (caseDetails?.preference_loan_amount ? formatAsCurrency(caseDetails?.preference_loan_amount) : defaultValue),
    [caseDetails?.preference_loan_amount],
  )

  const preference_term_years = useMemo(
    () =>
      caseDetails?.preference_term && Number(caseDetails?.preference_term) > 0
        ? Math.round(Number(caseDetails?.preference_term) / 12)
        : null,
    [caseDetails?.preference_term],
  )

  const term = useMemo(
    () =>
      preference_term_years ? `${preference_term_years} ${formatMessage(`${sidebarInfoPrefix}.years`)}` : defaultValue,
    [formatMessage, preference_term_years],
  )

  return (
    <>
      <InfoTable>
        <tbody>
          <tr>
            <CardTableCell color="secondary">
              <FormattedMessage id={`${sidebarInfoPrefix}.advisor`} />
            </CardTableCell>
            <CardTableCell textAlign="right">{advisor}</CardTableCell>
          </tr>

          {!isProtectionCase && !isBusinessProtectionCase && (
            <>
              <tr>
                <CardTableCell color="secondary">
                  <FormattedMessage id={`${sidebarInfoPrefix}.address`} />
                </CardTableCell>
                <CardTableCell textAlign="right">{address}</CardTableCell>
              </tr>
              <tr>
                <CardTableCell color="secondary">
                  <FormattedMessage id={`${sidebarInfoPrefix}.purchasePrice`} />
                </CardTableCell>
                <CardTableCell textAlign="right">{purchasePrice}</CardTableCell>
              </tr>
              <tr>
                <CardTableCell color="secondary">
                  <FormattedMessage id={`${sidebarInfoPrefix}.valuation`} />
                </CardTableCell>
                <CardTableCell textAlign="right">{valuation}</CardTableCell>
              </tr>
              <tr>
                <CardTableCell color="secondary">
                  <FormattedMessage id={`${sidebarInfoPrefix}.loanAmount`} />
                </CardTableCell>
                <CardTableCell textAlign="right">{loanAmount}</CardTableCell>
              </tr>
              <tr>
                <CardTableCell color="secondary">
                  <FormattedMessage id={`${sidebarInfoPrefix}.term`} />
                </CardTableCell>
                <CardTableCell textAlign="right">{term}</CardTableCell>
              </tr>
            </>
          )}
        </tbody>
      </InfoTable>
      <Box px={1 / 2} py={1 / 4}>
        <ChaseInfoModal open={isModalOpen} onClose={() => setModalOpen(false)} />
        <ButtonNewest onClick={() => setModalOpen(true)} variant="link" sx={{ fontSize: '12px' }}>
          <FormattedMessage id={`${sidebarInfoPrefix}.snapshotInfo`} />
        </ButtonNewest>
      </Box>
    </>
  )
}

export default CaseInfo
