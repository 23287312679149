import { ReactNode, useMemo } from 'react'
import { usePermissionsContext } from '@broker-crm-contexts'

import { GateName } from '@acre/graphql'
import { FieldDisabledProvider, useFieldDisabledContext } from '@acre/design-system'

const SectionPermission = ({
  children,
  gatesToCheck,
  permissionResolutionGates,
}: {
  children: ReactNode
  gatesToCheck: GateName[]
  permissionResolutionGates?: GateName[]
}) => {
  const { gates } = usePermissionsContext()

  const hasPermission = useMemo(() => {
    if (gatesToCheck.length === 0) {
      return true
    }

    return gatesToCheck.some((gateToCheck) =>
      permissionResolutionGates
        ? permissionResolutionGates?.includes(gateToCheck)
        : gates && gates.includes(gateToCheck),
    )
  }, [gates, gatesToCheck, permissionResolutionGates])

  const areFieldsDisabled = useFieldDisabledContext()

  return <FieldDisabledProvider value={!hasPermission || areFieldsDisabled}>{children}</FieldDisabledProvider>
}

export default SectionPermission
