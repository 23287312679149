import { DataProxy } from '@apollo/client'
import { GraphQLError } from 'graphql/error'

import { CachedListUpdateStrategy, updateCachedList } from '@acre/utils'
import {
  CaseVersion,
  CreateMortgageMutation,
  DeleteMortgageMutation,
  GetMortgageProductDocument,
  GetPropertyDocument,
  Maybe,
  SaveMortgageProductToCaseMutation,
  UpdateMortgageMutation,
} from '@acre/graphql'

export const updateMortgageCacheCreate = async (cache: DataProxy, data: CreateMortgageMutation, caseId: string) => {
  const { createMortgage } = data
  if (createMortgage) {
    try {
      await updateCachedList<CreateMortgageMutation['createMortgage']>(cache, {
        readQuery: {
          query: GetMortgageProductDocument,
          variables: { id: caseId },
        },
        writeQuery: {
          query: GetMortgageProductDocument,
          variables: { id: caseId },
        },
        newItem: createMortgage,
        strategy: CachedListUpdateStrategy.Prepend,
        key: 'case.details.mortgages',
      })
    } catch (e) {
      console.error(e)
    }

    const { property_secured_ids } = createMortgage
    if (property_secured_ids && property_secured_ids.length > 0) {
      const propertyId = property_secured_ids[0]!
      try {
        // Updating cache for property mortgage
        await updateCachedList<CreateMortgageMutation['createMortgage']>(cache, {
          readQuery: {
            query: GetPropertyDocument,
            variables: { id: propertyId }, // Can assert with '!' because of the above check
          },
          writeQuery: {
            query: GetPropertyDocument,
            variables: { id: propertyId }, // Can assert with '!' because of the above check
          },
          newItem: createMortgage,
          strategy: CachedListUpdateStrategy.Prepend,
          key: 'property.details.mortgages',
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export const updateMortgageCacheDelete = async (cache: DataProxy, data: DeleteMortgageMutation) => {
  const { deleteMortgage } = data
  const { case_id, mortgage_id } = deleteMortgage || {}
  const cachedProducts: Maybe<{
    case: Maybe<CaseVersion>
  }> = cache.readQuery({
    query: GetMortgageProductDocument,
    variables: { id: case_id },
  })
  const cachedCase = cachedProducts && cachedProducts.case

  if (cachedCase && cachedCase.details && cachedCase.details.mortgages) {
    try {
      const cacheData = cachedCase.details.mortgages.filter((mortgage) => {
        return mortgage_id !== mortgage.id
      })
      return await cache.writeQuery({
        query: GetMortgageProductDocument,
        data: {
          case: {
            ...cachedCase,
            details: {
              ...cachedCase.details,
              mortgages: cacheData,
            },
          },
        },
      })
    } catch (e) {
      console.error(`There was a problem updating the cache: ${(e as GraphQLError).message}`)
    }
  }
}

export const updateMortgageCacheUpdate = async (cache: DataProxy, data: UpdateMortgageMutation, case_id: string) => {
  const { updateMortgage } = data
  const { id } = updateMortgage || {}
  const cachedData: Maybe<{
    case: Maybe<CaseVersion>
  }> = cache.readQuery({
    query: GetMortgageProductDocument,
    variables: { id: case_id },
  })
  const cachedCase = cachedData && cachedData.case
  if (cachedCase && cachedCase.details && cachedCase.details.mortgages) {
    try {
      const cachedMortgages = cachedCase.details.mortgages.filter((mortgage) => {
        return id !== mortgage.id
      })
      return await cache.writeQuery({
        query: GetMortgageProductDocument,
        data: {
          case: {
            ...cachedCase,
            details: {
              ...cachedCase.details,
              mortgages: [...cachedMortgages, ...[updateMortgage]],
            },
          },
        },
      })
    } catch (e) {
      console.error(`There was a problem updating the cache: ${(e as GraphQLError).message}`)
    }
  }
}

export const updateSaveMortgageProductToCaseMutationCache = async (
  cache: DataProxy,
  data: SaveMortgageProductToCaseMutation,
  caseId: string,
) => {
  const { saveMortgageProductToCase } = data
  const cacheUpdatePayload = {
    readQuery: {
      query: GetMortgageProductDocument,
      variables: { id: caseId },
    },
    writeQuery: {
      query: GetMortgageProductDocument,
      variables: { id: caseId },
    },
    newItem: saveMortgageProductToCase,
    strategy: CachedListUpdateStrategy.Append,
    // Update this key to match whenever the structure of
    // @acre/broker-crm/src/graphql/queries/getMortgageProduct.gql
    // is updated
    key: 'case.details.mortgages',
  }

  await updateCachedList<SaveMortgageProductToCaseMutation['saveMortgageProductToCase']>(cache, cacheUpdatePayload)
}
