import { isAfter } from 'date-fns'

import { Document, Maybe } from '@acre/graphql'
import config from '@acre/config'

export const getLatestCreditReportIdd = (documents: Maybe<Document>[]) => {
  const creditIdds = documents.filter(
    (doc) => doc?.rendered_organisation_ids && doc.rendered_organisation_ids.includes(config.ACRE_SUPPORT_ORG_ID),
  ) as Document[]

  if (creditIdds.length === 0) return undefined

  return creditIdds.reduce((latestCreditIdd, creditIdd) => {
    if (creditIdd?.date_stored && latestCreditIdd?.date_stored) {
      return isAfter(new Date(creditIdd.date_stored), new Date(latestCreditIdd.date_stored))
        ? creditIdd
        : latestCreditIdd
    } else if (!latestCreditIdd?.date_stored) {
      return creditIdd
    } else {
      return latestCreditIdd
    }
  })
}
