import React, { useCallback, useMemo, useState } from 'react'
import { CardRowLine } from '@broker-crm-common'
import { TaskRowSidebarDraw } from '@broker-crm-common/ReviewModeDrawer/Tasks/Tasks.styles'
import { useCaseContext } from '@broker-crm-contexts'
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types'
import { Location } from 'history'
import { FormattedMessage, useIntl } from 'react-intl'

import { isGeneralProtection, isHomeInsurance, testHandle, useUserContext } from '@acre/utils'
import {
  Case,
  Maybe,
  MortgageReason,
  UpdateCaseStatusRequestUpdateType,
  useGeneralInsuranceProductsQuery,
  useGetOrganisationQuery,
  User,
} from '@acre/graphql'
import { trackEventFiltered } from '@acre/design-system'

import AddReminderModal from '../../../../Reminders/AddReminder/AddReminderModal'
import { setInitialReminderValues, TasksLocation } from '../CaseOverview.helpers'
import {
  ChevronIcon,
  getCaseSelectedMortgage,
  getEarliestGIProductEndDate,
  getTaskTextByStatus,
  validTransitionStatusType,
} from './CaseVerifications.helpers'
import ConfirmCaseTransitionModal from './ConfirmationCaseTransitionModal'
import { TransitionToCompleteStage } from './TransitionToCompleteTask/CompleteTransitionTask.helpers'
import { VerificationTaskButton } from '../CaseOverview.styles'

type Props = {
  status: validTransitionStatusType
  caseId: string
  preferenceMortgageReason: Maybe<MortgageReason>
  onSubmit?: () => any
  tasksLocation?: TasksLocation
  trackEvent?: (params: TrackEventParams) => void | undefined
  location?: Location<unknown>
}

const { ADD_REMINDER, UPDATE_CASE_STATUS } = TransitionToCompleteStage

export const StatusTransitionTask = ({
  status,
  caseId,
  preferenceMortgageReason,
  location,
  tasksLocation,
  onSubmit,
  trackEvent,
}: Props) => {
  const caseVersion = useCaseContext()
  const user = useUserContext()
  const intl = useIntl()

  const [isModalOpen, setModalOpen] = useState(false)
  const handleClose = () => setModalOpen(false)

  const [transitionStage, setTransitionStage] = useState(ADD_REMINDER)

  const { taskName } = getTaskTextByStatus(status, preferenceMortgageReason)

  const buttonContent = useMemo(() => {
    return (
      <VerificationTaskButton type="button" id={`CaseTransitionTo${status}`}>
        <FormattedMessage id={taskName} />
        {ChevronIcon}
      </VerificationTaskButton>
    )
  }, [status, taskName])

  const handleTransitionToCompleteClick = useCallback(() => {
    setModalOpen(true)
    if (status === UpdateCaseStatusRequestUpdateType.Complete) {
      setTransitionStage(ADD_REMINDER)
    } else {
      setTransitionStage(UPDATE_CASE_STATUS)
    }

    trackEvent && trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
  }, [location, status, tasksLocation, trackEvent])

  const handleTransitionFromReminderModal = useCallback(() => {
    setModalOpen(true)
    setTransitionStage(UPDATE_CASE_STATUS)
  }, [])

  const renderButton = useMemo(() => {
    if (tasksLocation === TasksLocation.CaseSidebarDrawer) {
      return <TaskRowSidebarDraw onClick={handleTransitionToCompleteClick}>{buttonContent}</TaskRowSidebarDraw>
    } else {
      return (
        <CardRowLine
          style={{ display: 'flex' }}
          onClick={handleTransitionToCompleteClick}
          data-testid={testHandle(`CaseTransitionTo${status}`)}
        >
          {buttonContent}
        </CardRowLine>
      )
    }
  }, [buttonContent, handleTransitionToCompleteClick, status, tasksLocation])

  const mortgage = caseVersion.details && getCaseSelectedMortgage(caseVersion.details as Case)
  const isHomeInsuranceCase = isHomeInsurance(caseVersion.details?.preference_mortgage_reason)
  const isProtectionCase = isGeneralProtection(caseVersion.details?.preference_mortgage_reason)

  const { data: lenderData } = useGetOrganisationQuery({
    variables: { id: mortgage?.mortgage_product?.lender_reference! },
    skip: !mortgage?.mortgage_product?.lender_reference,
  })
  const lenderName = (lenderData?.organisation && lenderData?.organisation.name) || ''

  const { data: giProductsOnCaseData } = useGeneralInsuranceProductsQuery({
    variables: {
      input: {
        filter_case_ids: [caseVersion.id],
      },
    },
    skip: !isHomeInsuranceCase,
  })
  const earliestGIProductEndDate = getEarliestGIProductEndDate(
    giProductsOnCaseData?.generalInsuranceProducts?.general_insurance_products || [],
  )

  const initialValues = setInitialReminderValues(
    caseVersion.details as Case,
    user as User,
    intl,
    lenderName,
    earliestGIProductEndDate,
  )

  return (
    <>
      {renderButton}
      {transitionStage === ADD_REMINDER && (
        <AddReminderModal
          initialValues={initialValues}
          modalOpen={isModalOpen}
          transition={handleTransitionFromReminderModal}
          isPartOfTheFlow={true}
          layout="review"
          isProtection={isProtectionCase}
          isHomeInsurance={isHomeInsuranceCase}
        />
      )}
      {transitionStage === UPDATE_CASE_STATUS && (
        <ConfirmCaseTransitionModal
          status={status}
          caseId={caseId}
          preferenceMortgageReason={preferenceMortgageReason}
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}
