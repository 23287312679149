import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MasonryLayout = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const MasonrySection = styled('div')(
  ({ theme }) => `
display: flex;
flex-direction: row;
width: 100%;
border-bottom: 1px solid ${theme.colours.baseLight};
padding: ${theme.spacing(4)};
gap: 16px;
`,
)

export const MasonryColumn = styled('div')<{ width?: string }>(
  ({ width, theme }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: ${width || '50%'};
  & + div {
    border-left: 1px solid ${theme.colours.baseLight};
  }s
`,
)

export const BorderlessMasonryColumn = styled('div')<{ width?: string }>(
  ({ width }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: ${width || '50%'};
`,
)

export const MasonryCell = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacers.size24,
  paddingLeft: theme.spacers.size32,
  paddingRight: theme.spacers.size32,
  paddingBottom: theme.spacers.size16,
  '& + div': {
    borderTop: `1px solid ${theme.colours.baseLight}`,
  },
  '&:last-of-type': {
    height: '100%',
  },
}))
