import React, { createContext, ReactNode, useState } from 'react'

type Props = {
  children: ReactNode
  keepDirtyOnReinitialize: boolean
}

type KeepDirtyOnReinitializeProps = {
  keepDirtyOnReinitialize: boolean
  saveKeepDirtyOnReinitialize?: (values: boolean) => void
}

const KeepDirtyOnReinitializeContext = createContext<KeepDirtyOnReinitializeProps>({ keepDirtyOnReinitialize: false })

export const KeepDirtyOnReinitializeProvider = ({ children, keepDirtyOnReinitialize }: Props) => {
  const [currentKeepDirtyOnReinitialize, setCurrentKeepDirtyOnReinitialize] = useState<boolean>(
    keepDirtyOnReinitialize || false,
  )

  const saveKeepDirtyOnReinitialize = (values: boolean) => {
    setCurrentKeepDirtyOnReinitialize(values)
  }

  return (
    <KeepDirtyOnReinitializeContext.Provider
      value={{ keepDirtyOnReinitialize: currentKeepDirtyOnReinitialize, saveKeepDirtyOnReinitialize }}
    >
      {children}
    </KeepDirtyOnReinitializeContext.Provider>
  )
}

export const KeepDirtyOnReinitializeConsumer = KeepDirtyOnReinitializeContext.Consumer

export default KeepDirtyOnReinitializeContext
