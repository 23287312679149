import { ReactElement, ReactNode } from 'react'

import { ClientInput } from '@acre/graphql'

export const formatValue = (
  method: (value: string) => ReactNode,
  fallback: ReactElement,
  // Using 'any' because the union of string, number, boolean is incompatible with any single primitive
  value?: any | null,
) => (value ? method(value) : fallback)

export const MortgageClassMap = {
  CLASS_FIXED: 'Fixed',
  CLASS_DISCOUNT: 'Discount',
  CLASS_VARIABLE: 'Variable',
  CLASS_CAPPED: 'Capped',
  CLASS_LIBOR: 'Libor',
  CLASS_TRACKER: 'Tracker',
  CLASS_STEPPED: 'Stepped',
}

export const formatSortCode = (sortCode: string) => {
  return sortCode.replace(/[\s-]+/g, '')
}

export const formatAccounts = (clientValues: ClientInput) => {
  return clientValues.accounts?.map((account) => {
    return account.sort_code ? { ...account, sort_code: formatSortCode(account.sort_code) } : account
  })
}
