import { differenceInDays, format, isAfter, isBefore, isValid, sub } from 'date-fns'

import {
  ERROR_DATE_GENERIC,
  ERROR_DAY,
  ERROR_FUTURE,
  ERROR_INPUT,
  ERROR_MONTH,
  ERROR_OVER_AGE,
  ERROR_UNDER_AGE,
  ErrorMessagesKey,
} from './errorConstants'

export type ISODateString = string

type Integer = number
type IntString = string
type MomentTimePeriods = 'years' | 'months' | 'days'
type Any = string | number | boolean | null | typeof undefined | {}

export const MIN_USER_AGE: number = 18
export const MAX_USER_AGE: number = 125
export const MAX_DAYS_COUNT: number = 31
export const MIN_DAYS_COUNT: number = 1
export const MAX_MONTHS_COUNT: number = 12
export const MIN_MONTHS_COUNT: number = 1
export const years: MomentTimePeriods = 'years'
export const months: MomentTimePeriods = 'months'
export const days: MomentTimePeriods = 'days'

export const minimumPossibleDate = sub(new Date(), { years: MAX_USER_AGE })
// FIXME: suggested moment type and yet still comes up as any
export const getMinimumAgeDate = (minimumAge: Integer): Date => sub(new Date(), { years: minimumAge })

export const isNullOrUndefined = (data: Any): boolean =>
  data === null || data === undefined || data === '' || data === '0'

/*
 *   HELPER FUNCTIONS
 *   not to rely only on html validation for number input
 *   helper functions run second validations
 */
function convertToInteger(numberString: IntString): Integer | null {
  if (isStringNumber(numberString)) {
    return parseInt(numberString, 10)
  }
  return null
}

function isStringNumber(str: IntString): boolean {
  const num: number = parseFloat(str)
  if (isNaN(num)) {
    return false
  }
  return true
}

/*
 *   Individual validator for each date input
 */

export function validateDayInput(day: IntString): ErrorMessagesKey | null | undefined {
  if (day === '') return null
  if (day === '0') return null

  const dayInt: Integer | null = convertToInteger(day)

  if (dayInt === null) return ERROR_INPUT
  if (dayInt < MIN_DAYS_COUNT || dayInt > MAX_DAYS_COUNT) return ERROR_DAY

  return null
}

export function validateMonthInput(month: IntString): ErrorMessagesKey | null | undefined {
  if (month === '') return null
  if (month === '0') return null

  const monthInt: Integer | null = convertToInteger(month)

  if (monthInt === null) return ERROR_INPUT
  if (monthInt < MIN_MONTHS_COUNT || monthInt > MAX_MONTHS_COUNT) return ERROR_MONTH

  return null
}

export function validateYearInput(year: IntString): ErrorMessagesKey | null | undefined {
  const userCompletedYearInput = year.length === 4
  if (!userCompletedYearInput) return ERROR_INPUT

  const yearMax = format(minimumPossibleDate, 'YYYY')
  const yearMaxInt: Integer | null = convertToInteger(yearMax)
  const yearSelectedInt: Integer | null = convertToInteger(year)

  if (yearSelectedInt === null) {
    return ERROR_INPUT
  }
  if (yearMaxInt !== null && yearMaxInt > yearSelectedInt) {
    return ERROR_OVER_AGE
  }
  return null
}

export function fullDateValidation(dateToValidate: Date, minimumAge?: Integer): ErrorMessagesKey | null {
  const isDateValid = isValidDate(dateToValidate.toString())

  if (!isDateValid) {
    return ERROR_DATE_GENERIC
  }
  if (isBefore(new Date(), dateToValidate)) {
    return ERROR_FUTURE
  }
  if (minimumAge !== null && minimumAge !== undefined) {
    const minimumAgeDate = getMinimumAgeDate(minimumAge)
    if (isBefore(minimumAgeDate, dateToValidate)) {
      return ERROR_UNDER_AGE
    }
  }
  if (isAfter(minimumPossibleDate, dateToValidate)) {
    return ERROR_OVER_AGE
  }

  return null
}
export const validateIsBefore = (startDate: ISODateString | undefined | null, endDate: ISODateString): boolean => {
  // we should not be returning a validation error before the user has filled out the start date
  if (!startDate) {
    return true
  }
  return isBefore(new Date(startDate), new Date(endDate))
}

export const validateIsAfter = (startDate: ISODateString, endDate: ISODateString): boolean => {
  // we should not be returning a validation error before the user has filled out the start date
  if (!startDate) {
    return true
  }
  return isAfter(new Date(startDate), new Date(endDate))
}

export const isValidDate = (dateToValidate: string) => !!dateToValidate && isValid(new Date(dateToValidate))

export const isProtectedDate = (dateToValidate: string) => !!dateToValidate && !!dateToValidate.includes('*')

// true when dateToValidate is more than the minimum age
export const isAboveMinimumAge = (dateToValidate: string, minimumAge: Integer) =>
  minimumAge >= 0 && !!dateToValidate && isBefore(getMinimumAgeDate(minimumAge), new Date(dateToValidate))

// true when dateToValidate is more than today
export const isInTheFuture = (dateToValidate: string) =>
  !!dateToValidate && isBefore(new Date(), new Date(dateToValidate))

// true when dateToValidate less than the maximum age
export const isBelowMaximumAge = (dateToValidate: string) =>
  !!dateToValidate && isAfter(minimumPossibleDate, new Date(dateToValidate))

export const validateYearAndMonth = (dateToValidate: string) => {
  const date = dateToValidate.split('-')
  return validateMonthInput(date[1]) === null && validateYearInput(date[0]) === null
}

export const validateYearAndMonthNotFuture = (dateToValidate: string) => {
  const date = dateToValidate.split('-')
  return (
    validateMonthInput(date[1]) === null &&
    validateYearInput(date[0]) === null &&
    !isBefore(new Date(), new Date(dateToValidate))
  )
}

export const validateWithinDateRange = (startDate: string, endDate: string, dateRangeDays: number) => {
  const days = differenceInDays(new Date(endDate), new Date(startDate))
  return days <= dateRangeDays
}
