import React, { memo, useMemo } from 'react'
import { styled, Theme } from '@mui/material'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import testHandle from '../../utils/testHandle'
import { ColourId } from '../../utils/types'
import UserAvatar from '../UserAvatar'

export type AvatarNameBuilderProps = {
  firstName: string
  lastName?: string
  colour?: ColourId.BaseLightColor | number
  size?: keyof Pick<Theme['spacers'], 'size24' | 'size32' | 'size48'>
  className?: string
  to?: string
  hideUserName?: boolean
}

const AvatarName = styled(Box)`
  textwrap: pretty;
  whitespace: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AvatarNameBuilder = ({
  firstName = '',
  lastName = '',
  colour,
  size,
  className,
  to,
  hideUserName,
}: AvatarNameBuilderProps) => {
  const randomColourIdGenerator = () => Math.floor(Math.random() * 13) + 1
  const colourId = useMemo(() => randomColourIdGenerator(), [])
  const firstNameInitial = firstName.charAt(0).toUpperCase() // Note: ''.charAt(0) is ''
  const displayName = [firstName, lastName].filter((name) => name !== '').join(' ')

  const avatarUserName = !hideUserName && (
    <AvatarName ml={1} data-testid={testHandle(`AvatarUserName`)} className={className}>
      {displayName}
    </AvatarName>
  )

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <UserAvatar colourId={colour || colourId} initials={firstNameInitial} size={size} />
      {to ? <Link to={to}>{avatarUserName}</Link> : avatarUserName}
    </Box>
  )
}

export default memo(AvatarNameBuilder)
