import { ReactElement, ReactNode, useMemo, useRef } from 'react'
import { PermissionProvider } from '@broker-crm-contexts'

import { Locales, messagesAlerts } from '@acre/utils'
import {
  GateName,
  GetPermissionResolutionQuery,
  ResolvedPermissionResolution,
  useGetPermissionResolutionQuery,
} from '@acre/graphql'

import { excludeInputsBasedOnGates, mergeObjectArrays, transformPermissionInputs } from './permission.helpers'
import { PermissionInput } from './permission.types'

export type Props = {
  inputs: PermissionInput[]
  children: ReactNode | (({ gates }: { gates: GateName[] }) => ReactNode)
  // these exceptions are an object
  // they can be ussed for any kind of exceptions
  exceptions?: { gates: GateName[] }
}

const messages = messagesAlerts[Locales.GB]

const PermissionResolution = ({ inputs, children, exceptions }: Props) => {
  const { permissionInputs, allGates } = useMemo(() => {
    let inputsToTansform = inputs
    // if there are exceptions for gates present, we need to exclude those gates from inputs
    // and potentially inputs which contain those gates completely
    if (exceptions && exceptions.gates) {
      inputsToTansform = excludeInputsBasedOnGates({
        inputs,
        gateExceptions: exceptions.gates,
      })
    }
    return transformPermissionInputs({ inputs: inputsToTansform })
    // We want to enforce that the inputs only be calculated once to prevent re-rendering issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptions])

  const { data, loading, error } = useGetPermissionResolutionQuery({
    variables: {
      input: permissionInputs,
    },
  })

  // Use useRef to store a local cache for permission resolution call
  // so if data is not available, the last successful data value is kept in cacheData.current
  const cacheData = useRef<GetPermissionResolutionQuery>()

  cacheData.current = data || cacheData.current
  const permissionResolution = cacheData.current?.permissionResolution

  // we only receive an array of has_permissions from the api,
  // the order of these are expected to be the same as the inputs.
  // we need to merge the results from the api request and what was
  // sent so that the frontend code can have both the frontend
  // permissions gates as well as the corresponding response together
  let mergedArray: ResolvedPermissionResolution[] = []
  if (permissionResolution && allGates && allGates.length) {
    mergedArray = mergeObjectArrays(permissionResolution, allGates)
  }

  // when using this component in the code, we will most commonly
  // need to check which permission gates have validated permissions
  // the validated permission gates are returned here
  const gates = mergedArray.reduce((acc, element) => {
    let result = acc
    if (element && element.gate && element.has_permission) {
      result = [...result, element.gate]
    }
    return result
  }, [] as GateName[])

  // gates without duplicates
  const uniqueGates = gates.filter((a, b) => gates.indexOf(a) === b)

  // We redirect on login error, so there is no need to display the error here
  // It would only display for a split second which is poor UX
  const hasLoginError = error?.message === messages.alerts.login

  if (error && !hasLoginError) {
    return <>{error.message}</>
  }

  if ((loading && !cacheData.current && !error) || hasLoginError) {
    return null
  }

  if (!cacheData.current || !cacheData.current.permissionResolution) {
    return typeof children === 'function' ? (children({ gates: [] }) as ReactElement) : <>{children}</>
  }

  const finalGates = cacheData.current?.permissionResolution ? uniqueGates : []

  return (
    <PermissionProvider value={{ gates: finalGates }}>
      {typeof children === 'function' ? (
        children({
          gates: finalGates,
        })
      ) : (
        <>{children}</>
      )}
    </PermissionProvider>
  )
}

export default PermissionResolution
