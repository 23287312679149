import React, { useCallback, useRef } from 'react'
import { type InputProps, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import DisplayField from '../DisplayField/DisplayField'
import type { ExtendField, FieldProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper from '../FieldWrapper/FieldWrapper'
import TextFieldBase from '../TextFieldBase'
import type { TextFieldBaseProps } from '../TextFieldBase/TextFieldBase.types'
import { getTextFieldUtilityClass } from './textFieldClasses'
import { useProtectedTextField } from './useProtectedTextField'

export interface TextFieldProps extends FieldProps, ExtendField<TextFieldBaseProps> {
  enableProtected?: boolean
  loadData?: () => Promise<string>
  InputProps?: InputProps
}

export interface ProtectedTextFieldProps extends Omit<TextFieldProps, 'inputRef' | 'enableProtected'> {
  inputRef: React.RefObject<HTMLInputElement | null>
}

const useUtilityClasses = (ownerState: Partial<TextFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    input: ['input'],
    helperText: ['helperText'],
    errorText: ['errorText'],
    displayField: ['displayField'],
  }

  return unstable_composeClasses(slots, getTextFieldUtilityClass, ownerState.classes)
}

/**
 * This internal component is used to render the protected text field.
 * It is NOT intended to be used directly, and is only exported for testing purposes.
 */
const _ProtectedTextField = (props: ProtectedTextFieldProps) => {
  const {
    className,
    classes,
    inputRef,
    inputProps,
    value,
    name,
    label,
    loadData,
    onChange,
    helperText,
    error,
    WrapperProps,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    InputProps,
    layout = 'row',
    size = 'medium',
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout, size })

  const initialValue = (value || props.defaultValue) as string

  const { testId, fieldAriaProps } = useAriaProps(props)

  const { isProtected, isEditMode, controlButtons, protectedValue, setProtectedValue } = useProtectedTextField({
    name,
    inputRef,
    initialValue,
    loadData,
    disabled: props.disabled,
    dataTestId: testId,
  })

  const handleChange = useCallback<Required<TextFieldProps>['onChange']>(
    (event) => {
      setProtectedValue(event.target.value)

      if (onChange) {
        onChange(event)
      }
    },
    [onChange, setProtectedValue],
  )

  const isShowMode = !isEditMode && !isProtected

  const commonFieldProps = {
    name,
    label,
    layout,
    size,
    helperText,
    error,
  }

  return (
    <>
      {/* Actual Text Field */}
      {isEditMode && (
        <FieldWrapper
          {...WrapperProps}
          {...commonFieldProps}
          className={classnames(slotClasses.root, WrapperProps?.className, className)}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={FormHelperTextProps}
          ErrorTextProps={ErrorTextProps}
        >
          <TextFieldBase
            {...rootProps}
            {...InputProps}
            inputProps={{ ...fieldAriaProps, ...inputProps }}
            className={classnames(slotClasses.field, InputProps?.className)}
            mode="editable"
            autoComplete="off"
            name={name}
            error={Boolean(error)}
            disabled={rootProps.disabled}
            inputRef={inputRef}
            value={protectedValue || ''}
            onChange={handleChange}
          />
        </FieldWrapper>
      )}

      {/* Obfuscated Text Field */}
      {!isEditMode && (
        <DisplayField
          {...commonFieldProps}
          className={classnames(slotClasses.root)}
          disabled={rootProps.disabled}
          value={isShowMode ? (protectedValue as string) : initialValue}
          valueClassName={classnames(slotClasses.displayField)}
          controls={controlButtons}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={FormHelperTextProps}
          ErrorTextProps={ErrorTextProps}
        />
      )}
    </>
  )
}

export const ProtectedTextField = React.memo(_ProtectedTextField)

/**
 * This is the basic text field component.
 *
 * It used used to power all input types that require keyboard input, such as:
 * - Text
 * - Date
 * - Select
 */
const TextField = (props: TextFieldProps) => {
  const {
    className,
    classes,
    inputRef,
    enableProtected,
    loadData,
    error,
    helperText,
    label,
    layout = 'row',
    size = 'medium',
    inputProps,
    WrapperProps,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    InputProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout, size })

  const textFieldRef = useRef<HTMLInputElement | null>((inputRef as React.RefObject<HTMLInputElement>)?.current || null)

  const { ariaId, fieldAriaProps } = useAriaProps(props)

  const commonInputProps = {
    ...fieldAriaProps,
    id: ariaId,
    ...inputProps,
    className: classnames(slotClasses.input, inputProps?.className),
  }

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  if (enableProtected) {
    return (
      <ProtectedTextField
        {...rootProps}
        inputProps={commonInputProps}
        label={label}
        loadData={loadData}
        inputRef={textFieldRef}
        error={error}
        helperText={helperText}
        WrapperProps={WrapperProps}
        size={size}
        layout={layout}
        InputLabelProps={labelProps}
        FormHelperTextProps={helperTextProps}
        ErrorTextProps={errorProps}
      />
    )
  }

  return (
    <FieldWrapper
      {...WrapperProps}
      layout={layout}
      label={label}
      size={size}
      name={rootProps.name}
      error={error}
      helperText={helperText}
      className={classnames(slotClasses.root, WrapperProps?.className, className)}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
    >
      <TextFieldBase
        {...rootProps}
        {...InputProps}
        error={Boolean(error)}
        inputProps={commonInputProps}
        className={classnames(slotClasses.field, InputProps?.className)}
        autoComplete="off"
        inputRef={textFieldRef}
      />
    </FieldWrapper>
  )
}

export default React.memo(TextField)
