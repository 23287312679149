import getCancellationReasonReport from './cancellation_reasons'
import getCancellationReasonTotals from './cancellation_reasons_totals'
import getCaseWaterfallReport from './case_waterfall'
import getCaseWaterfallTotals from './case_waterfall_totals'
import getCommissions from './commissions'
import getCurrentPipelineReport from './current_pipeline'
import getCurrentPipelineTotals from './current_pipeline_totals'
import getPermissionsAllowList from './permissions_allow_list'
import getPredictedRevenueReport from './predicted_revenue_report'
import getPredictedRevenueTotals from './predicted_revenue_report_totals'
import getRevenueReport from './revenue_report'
import getRevenueTotals from './revenue_report_totals'

export default {
  fields: {
    getCancellationReasonReport,
    getCancellationReasonTotals,
    getCaseWaterfallReport,
    getCaseWaterfallTotals,
    getCommissions,
    getCurrentPipelineReport,
    getCurrentPipelineTotals,
    getPredictedRevenueReport,
    getPredictedRevenueTotals,
    getPermissionsAllowList,
    getRevenueReport,
    getRevenueTotals,
  },
}
