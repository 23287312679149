import { styled, Theme } from '@mui/material'
import { transparentize } from 'polished'

import { Variant } from '../../utils/constants'

export const DateInputSection = styled('input')(
  ({ theme }) => `
  font-size: 16px;
  color: ${theme.colours.base};
  border: none;
  text-align: center;
  width: 33%;
  outline: none;
  min-width: 0;
  &:disabled {
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }
`,
)

export const DateInputField = styled('div')<{
  error: boolean
  variant?: Variant
}>(
  ({ theme, error, variant }) => `
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: ${theme.display.inputHeight};
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
  border-style: solid;
  border-width: ${theme.display.inputBorderWidth};
  border-radius: ${theme.display.borderRadius};
  border-color: ${error ? theme.colours.danger : theme.colours.baseLight};
  transition: border-color 0.2s ease-in-out;
  background-color: white;

  ${DateInputSection}:focus & {
    border-color: ${theme.colours.primary};
  }

  &:focus-within {
    border-color: ${theme.colours.primary};
  }

  &.disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }

  ${
    (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
    dateCompactStyles(theme)
  };
`,
)

const dateCompactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
  & > * {
    font-size: 14px!important;
  }
`

export const DateFieldset = styled('fieldset')`
  padding: 0;
  margin: 0;
  border: 0;
`

export const DateShortcutButton = styled('button')(
  ({ theme }) => `
  color: ${theme.colours.primary};
  background-color: ${transparentize(theme.opacity.op95, theme.colours.primary)};
  font-size: 12px;
  border-radius: 12px;
  padding: ${theme.spacing(0.5)} ${theme.spacing(1)}
    ${theme.spacing(0.5)} ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.primary)};
  }
  &:focus {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.primary)};
  }
  &:focus-visible {
    outline: none;
  }
`,
)
