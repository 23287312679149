import React, { ChangeEvent, FocusEvent, ReactNode, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { cleanValue } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { isValidMonth } from '../DateInput/DateInput.helpers'
import HelperText from '../HelperText'
import { Legend } from '../Label'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { DateFieldset, DateInputField, DateInputSection } from '../DateInput/DateInput.styles'

export type YearMonthInputProps = {
  id: string
  label?: string
  value?: string
  error?: boolean
  message?: ReactNode
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  variant?: Variant
}

enum Period {
  month,
  year,
}

const DateSeperator = () => <span style={{ display: 'flex', alignItems: 'center' }}>/</span>

const YearMonthInput = ({
  id,
  label,
  value = '',
  error = false,
  message,
  onChange,
  onBlur,
  disabled: disabledProp,
  variant = 'default',
}: YearMonthInputProps) => {
  const intl = useIntl()
  const _formatMessage = (id: string) => intl.formatMessage({ id })

  const disabled = useFieldDisabledState(disabledProp)

  const className = classNames({ disabled })

  // for automatically shifting cursor
  const yearInput = useRef<HTMLInputElement>(null)

  //initial Value
  const yearAndMonth = value.split('-')
  const initialMonth = yearAndMonth[1] || ''
  const initialYear = yearAndMonth[0] || ''

  //date
  const [month, setMonth] = useState(initialMonth)
  const [year, setYear] = useState(initialYear)

  //touched status
  const [hasTouchedMonth, setHasTouchedMonth] = useState(false)
  const [hasTouchedYear, setHasTouchedYear] = useState(false)

  const dateEvent = {
    target: {
      value: '',
    },
  } as ChangeEvent<HTMLInputElement>

  useEffect(() => {
    if (!onChange) return
    if (isValidMonth(month)) {
      dateEvent.target.value = `${year}-${month}`
    }

    if ((hasTouchedMonth || month.length === 2) && (hasTouchedYear || year.length == 4)) {
      onChange(dateEvent)
    }
  }, [month, year, value])

  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    return (hasTouchedMonth || month.length === 2) && (hasTouchedYear || year.length == 4) && onBlur && onBlur(evt)
  }

  const handleChange = (evt: ChangeEvent<HTMLInputElement>, period: Period) => {
    const cleanedValue = cleanValue(evt.target.value)
    if (period == Period.month) {
      setMonth(cleanedValue)
      setHasTouchedMonth(true)
      if (yearInput.current && cleanedValue.length === 2) {
        yearInput.current.focus()
        yearInput.current.select()
      }
    } else if (period == Period.year) {
      setYear(cleanedValue)
      setHasTouchedYear(true)
    }
  }

  return (
    <DateFieldset data-testid={testHandle(id)}>
      <LabelAndInputWrapper variant={variant}>
        {label && <Legend text={label} isDisabled={false} />}
        <DateInputField className={className} error={error} variant={variant}>
          <DateInputSection
            placeholder="MM"
            value={month}
            onBlur={handleBlur}
            onChange={(evt) => handleChange(evt, Period.month)}
            aria-label={_formatMessage('generic.month')}
            maxLength={2}
            disabled={disabled}
            data-testid={`${testHandle(id)}MM`}
          />
          <DateSeperator />
          <DateInputSection
            ref={yearInput}
            placeholder="YYYY"
            value={year}
            onBlur={handleBlur}
            onChange={(evt) => handleChange(evt, Period.year)}
            aria-label={_formatMessage('generic.year')}
            maxLength={4}
            disabled={disabled}
            data-testid={`${testHandle(id)}YYYY`}
          />
        </DateInputField>
      </LabelAndInputWrapper>

      {message && <HelperText id={id} message={message || ''} textType={HELPER_TYPE_ERROR} variant={variant} />}
    </DateFieldset>
  )
}

export default withDisabled(YearMonthInput)
