import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'

import { ERROR_REQUIRED, useFormatMessage } from '@acre/utils'
import { formatAsCurrency, Frequency, Maybe, ProductBenefit } from '@acre/graphql'

import { createTagLabel, flattenProductBenefits } from '../../../../../../Protection/Protection.helpers'
import { getProtectionProductInitialValues } from '../../../../../../Protection/ProtectionCommissionFields/ProtectionCommissionFields.helpers'

export const messagePrefix = 'protection.common'

export const getBenefitFrequencyText = {
  [Frequency.Weekly]: 'generic.perWeek',
  [Frequency.Monthly]: 'generic.perMonth',
  [Frequency.Quarterly]: 'generic.quarterly',
  [Frequency.Annually]: 'generic.annually',
  [Frequency.Once]: 'generic.lumpSum',
  [Frequency.Invalid]: 'generic.invalid',
  [Frequency.Undefined]: 'generic.invalid',
}

type Props = {
  benefits?: Maybe<ProductBenefit>
  formatMessage: ReturnType<typeof useFormatMessage>
}

export const getIndividualBenefitFields = ({ benefits, formatMessage }: Props) => {
  if (!benefits) return []
  const flattenedBenefits = flattenProductBenefits(benefits!)

  type returnType = { id: string; label: JSX.Element; value: string }[]

  return flattenedBenefits.reduce((acc, benefit, idx) => {
    const benefitLabel =
      flattenedBenefits.length === 1 ? (
        <FormattedMessage id={`${messagePrefix}.benefit`} />
      ) : (
        <FormattedMessage id={`${messagePrefix}.benefitX`} values={{ x: idx + 1 }} />
      )

    const benefitCoverAmountLabel =
      flattenedBenefits.length === 1 ? (
        <FormattedMessage id={`${messagePrefix}.benefitCoverAmount`} />
      ) : (
        <FormattedMessage id={`${messagePrefix}.benefitXCoverAmount`} values={{ x: idx + 1 }} />
      )

    const benefitCoverAmount = formatAsCurrency(benefit.cover_amount)
    const benefitFrequency = benefit.cover_amount_frequency
      ? `(${formatMessage(getBenefitFrequencyText[benefit.cover_amount_frequency])})`
      : ''

    const benefitsToDisplay = [
      ...acc,
      {
        id: `Benefit${idx}`,
        label: benefitLabel,
        value: createTagLabel(benefit, formatMessage),
      },
    ]

    if (benefitCoverAmount && benefitFrequency) {
      benefitsToDisplay.push({
        id: `Benefit${idx}CoverAmount`,
        label: benefitCoverAmountLabel,
        value: `${benefitCoverAmount} ${benefitFrequency}`,
      })
    }

    return benefitsToDisplay
  }, [] as returnType)
}

export const protectionCommissionCompulsoryFieldsSchema = yup
  .object<ReturnType<typeof getProtectionProductInitialValues>>()
  .shape({
    underwritten_premium_amount: yup.string().required(ERROR_REQUIRED),
    commission_type: yup.string().required(ERROR_REQUIRED),
    policy_number: yup.string().required(ERROR_REQUIRED),
    initial_commission: yup.string().required(ERROR_REQUIRED),
  })
