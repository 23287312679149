import { styled, Theme } from '@mui/material'
import { rem } from 'polished'

import { Maybe } from '@acre/graphql'

import { Variant } from '../../utils/constants'
import { commonControlStyles } from '../../styles/form-control.styles'

export const TextAreaWrapper = styled('div', {
  name: 'TextArea',
  slot: 'Wrapper',
})(
  ({ theme }) => `
  & p {
    margin: 0;
    margin-top: ${rem(-4)};
  }
  &.error textarea {
    border-color: ${theme.colours.danger};
  }
`,
)

export const StyledTextArea = styled('textarea', { name: 'TextArea', slot: 'root' })<{ variant?: Variant }>(
  ({ theme, variant }) => `
  ${commonControlStyles(theme)}
  font-family: ${theme.typography.fontFamily};
  resize: none;
  ${variant?.includes('compact') && compactStyles(theme)};
`,
)

const compactStyles = (theme: Theme) => `
  font-size: 14px;
  padding-top: ${theme.spacing(0.5)};
  padding-left: ${theme.spacing(1.5)};
  padding-right: ${theme.spacing(1.5)};
  padding-bottom: ${theme.spacing(0.5)};
`

export const LabelAndInputWrapper = styled('div')<{
  variant?: Variant
  alignItems?: string
  labelCentered?: boolean
  labelWidth?: Maybe<string>
}>(
  ({ variant, theme, labelCentered, labelWidth }) => `
  display: flex;
  flex-direction: column;
  ${variant === 'compact' && labelAndInputWrapperCompactStyles(theme)};
  ${variant === 'compactLeftAligned' && labelAndInputWrapperNewCompactStyles(theme, labelCentered, labelWidth)};
`,
)

export const labelAndInputWrapperCompactStyles = (theme: Theme) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  & > div {
    width: 100%;
  }
  & > label {
    width: 33%;
    min-width: 33%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    margin-top: ${theme.spacing(0.5)};
    margin-bottom: -${theme.spacing(1.5)};
    transform: translateX(-${theme.spacing(1)});
  }
`

export const labelAndInputWrapperNewCompactStyles = (
  theme: Theme,
  labelCentered?: boolean,
  labelWidth?: Maybe<string>,
) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  ${labelCentered && 'align-items: center;'}
  & > div {
    width: 100%;
  }
  & > label {
    width: ${labelWidth || '30%'};
    min-width: ${labelWidth || '30%'};
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: -${theme.spacing(1.5)};
  }
`
