import React, { MutableRefObject, ReactElement, useMemo, useRef } from 'react'

import { testHandle, useClickOutsideListener } from '@acre/utils'
import { PopOver } from '@acre/design-system'

import { FilterButton, FilterButtonContainer } from './FilterButtonPopover.styles'

type Props = {
  id: string
  isHighlighted: boolean
  isOpen: boolean
  label: string
  children: ReactElement
  onClick: () => void
  onClose: () => void
  align?: 'left' | 'right'
}

const FilterButtonPopover = ({ id, isHighlighted, isOpen, label, children, onClick, onClose, align }: Props) => {
  const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const testId = useMemo(() => testHandle(id), [id])

  useClickOutsideListener(containerRef, onClose, isOpen)

  return (
    <FilterButtonContainer ref={(elem) => (containerRef.current = elem)}>
      <FilterButton id={id} onClick={onClick} highlight={isOpen || isHighlighted} data-testid={testId} type="button">
        {label}
      </FilterButton>
      <PopOver align={align} isVisible={isOpen} overflowYVisible>
        {children}
      </PopOver>
    </FilterButtonContainer>
  )
}

export default FilterButtonPopover
