import React, { useMemo } from 'react'
import { isEmpty, omit, pick } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { useRouteForCase } from '@acre/utils'
import { ClientAddressInput, omitTypename, useUpdateClientsMutation } from '@acre/graphql'
import { DialogForm, H5 } from '@acre/design-system'

import { messagePrefix } from '../CompleteTransitionTask.helpers'
import UpdateClientAddressFooter from './UpdateClientAddressFooter'
import { UpdateClientsAddressFormValues, validateUpdateClientAddressModal } from './UpdateClientAddressModal.helpers'
import UpdateClientAddressesModalFields from './UpdateClientAddressModalFields'

type Props = {
  caseId: string
  isModalOpen: boolean
  handleClose?: () => void
}

const UpdateClientAddressModal = ({ isModalOpen, handleClose }: Props) => {
  const caseVersion = useRouteForCase()
  const { details } = caseVersion?.data || {}

  const purchasedPropertyAddress = details?.new_property?.details?.address
  const { address1, address2, address3, county, country, postcode, posttown } = purchasedPropertyAddress || {}

  const [updateClients, { loading: loadingUpdateClients }] = useUpdateClientsMutation()

  const handleOnSubmit = async (values: UpdateClientsAddressFormValues) => {
    const newCurrentAddress: ClientAddressInput = {
      address: {
        address1: address1 || '',
        address2: address2 || '',
        address3: address3 || '',
        county: county || '',
        country: country || '',
        posttown: posttown || '',
        postcode: postcode || '',
      },
      is_current_correspondence: true,
      move_in_date: values.move_in_date,
    }

    const updateClientsPayload = values.selectedClients?.map((client) => ({
      ...pick(client, ['id', 'addresses', 'first_name', 'last_name']),
      addresses: [
        newCurrentAddress, // Add the new address as the first item
        ...(client.addresses?.map((address) => {
          if (address.is_current_correspondence === true) {
            return {
              ...omitTypename(address),
              is_current_correspondence: false,
              move_out_date: values.move_in_date,
            }
          }
          return omitTypename(address)
        }) ?? []),
      ],
    }))

    // id is always gonna be there so we'd like to see if the diff except the id will be there or not
    if (!isEmpty(omit(updateClientsPayload, 'id'))) {
      await updateClients({
        variables: {
          clients: updateClientsPayload!,
        },
      })
    }
  }

  const initialValues = useMemo(
    () => ({
      selectedClients: [],
    }),
    [],
  )

  const Header = (
    <H5>
      <FormattedMessage id={`${messagePrefix}.updateClientAddressModal.heading`} />
    </H5>
  )

  return (
    <DialogForm<UpdateClientsAddressFormValues>
      data-testid="e2e-UpdateClientAddressesModal"
      open={isModalOpen}
      FormProps={{
        subscription: { submitting: true },
        initialValues,
        validate: validateUpdateClientAddressModal,
      }}
      loading={loadingUpdateClients}
      onSubmit={handleOnSubmit}
      onClose={handleClose}
      title={Header}
      actions={<UpdateClientAddressFooter onClick={handleClose} />}
    >
      <UpdateClientAddressesModalFields />
    </DialogForm>
  )
}

export default UpdateClientAddressModal
