import clients from './clients'
import document_categories from './document_categories'
import fees from './fees'
import miDocuments from './miDocuments'
import mortgages from './mortgages'

export default {
  fields: {
    clients,
    mortgages,
    document_categories,
    fees,
    miDocuments,
  },
}
