import React, { useRef, useState } from 'react'
import { ApolloClient } from '@apollo/client'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { testHandle, useClickOutsideListener, useFormatMessage } from '@acre/utils'
import { GetAddressLookupDocument, useGetAddressLookupSummaryQuery } from '@acre/graphql'
import { AddressAndFieldUpdates } from '@acre/graphql'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  CenteredLoadingSpinner,
  HELPER_TYPE_ERROR,
  HelperText,
  Label,
  TextInput,
  Variant,
} from '@acre/design-system'

import { searchFragmentVar } from './AddressLookup.helpers'
import AddressDropDown from './AddressLookupDropDown'
import { Results, RowWrapper, SearchWrapper } from './AddressLookup.styles'

type Props = {
  onClick: () => void
  onGetAddress: (address: AddressAndFieldUpdates) => void
  label?: string
  variant?: Variant
  disabled?: boolean
  isMissing?: boolean
}

const minSearchChar = 3

const Lookup = ({ onClick, onGetAddress, label, variant = 'default', disabled, isMissing }: Props) => {
  const formatMessage = useFormatMessage()
  const [searchFragment, setSearchFragment] = useState<string>('')

  const [showDropDown, setShowDropDown] = useState<boolean>(false)
  const addressDropDownRef = useRef<HTMLUListElement | null>(null)

  const { data, loading, error } = useGetAddressLookupSummaryQuery({
    variables: { searchFragment },
    skip: searchFragment.trim().length < minSearchChar,
  })

  const lookupLabel = label || formatMessage('common.addressLookup.lookupLabel')

  const handleSearchFragmentChange = (value: string) => {
    const stringWithoutSpecialCharacters = value.replace(/[^a-z\d\s]+/gi, '')
    setShowDropDown(stringWithoutSpecialCharacters.trim().length >= minSearchChar)
    setSearchFragment(stringWithoutSpecialCharacters)
  }

  const handleAddressLookup = async (client: ApolloClient<unknown>, searchFragment: string, id: string) => {
    const { data } = await client.query({
      query: GetAddressLookupDocument,
      variables: { searchFragment, id },
      fetchPolicy: 'cache-first',
    })

    searchFragmentVar(searchFragment)

    onGetAddress(data.addressLookup[0])
  }

  useClickOutsideListener(
    addressDropDownRef,
    () => {
      setShowDropDown(false)
      setSearchFragment('')
    },
    showDropDown,
  )

  const initialAddresses = data && data.addressLookupSummary

  return (
    <>
      <SearchWrapper>
        <RowWrapper variant={variant}>
          <Label variant={variant} text={lookupLabel} htmlFor="SearchFragment" isMissing={isMissing} />
          <Box display="flex" width="100%" alignItems="center" style={{ position: 'relative' }}>
            <TextInput
              id="SearchFragment"
              value={searchFragment}
              placeholder="e.g. W8 5EH"
              onChange={(evt) => handleSearchFragmentChange(evt.target.value)}
              variant={variant}
              disabled={disabled}
              name="AddressLookupFragment"
            />
            <Button
              type={BUTTON_TYPE_BUTTON}
              id="AddressLookupAction"
              buttonStyle={BUTTON_SECONDARY}
              onClick={onClick}
              variant="compact"
            >
              <FormattedMessage id="common.addressLookup.manualButton" />
            </Button>

            {loading && (
              <Results isVisible style={{ padding: 10 }} variant={variant}>
                <CenteredLoadingSpinner />
              </Results>
            )}

            {error && <>Error! {error.message}</>}

            {initialAddresses && (
              <Results
                isVisible={showDropDown}
                data-testid={testHandle('AddressLookupResults')}
                variant={variant}
                ref={addressDropDownRef}
              >
                <AddressDropDown
                  addresses={initialAddresses}
                  searchFragment={searchFragment}
                  onClick={handleAddressLookup}
                />
              </Results>
            )}
          </Box>
        </RowWrapper>
      </SearchWrapper>

      {isMissing && (
        <HelperText
          id="AddressLookupHelper"
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </>
  )
}

export default Lookup
