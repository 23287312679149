import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface FieldWrapperClasses {
  root: string
  inputContainer: string
  label: string
  labelContainer: string
  helperText: string
  error: string

  // variants
  row: string
  column: string

  // size
  large: string
  medium: string
  small: string
}

export type FieldWrapperClassKey = keyof FieldWrapperClasses

export function getFieldWrapperUtilityClass(slot: string) {
  return generateUtilityClass('FieldWrapper', slot)
}

const fieldWrapperClasses: FieldWrapperClasses = generateUtilityClasses('FieldWrapper', [
  'root',
  'inputContainer',
  'label',
  'labelContainer',
  'helperText',
  'error',
  'row',
  'column',
  'large',
  'medium',
  'small',
])

export default fieldWrapperClasses
