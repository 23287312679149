import { Box, styled, TableRow } from '@mui/material'

type ReviewContainerProps = {
  boxShadow?: string
  marginTop?: string
  backgroundColor?: string
}

export const ReviewContainer = styled(Box)<ReviewContainerProps>(
  ({ theme, boxShadow, marginTop, backgroundColor }) => ({
    width: '100%',
    minWidth: theme.wrappers.large,
    maxWidth: theme.wrappers.Large,
    backgroundColor: backgroundColor,
    borderRadius: theme.display.borderRadius,
    boxShadow: boxShadow,
    marginTop: marginTop,
    marginBottom: theme.spacing(2),
  }),
)

export const ReviewCardStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.colours.baseLight}`,
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  color: theme.colours.base,

  '&:nth-of-type(odd)': {
    backgroundColor: theme.colours.baseExtraLight,
  },

  '& th': {
    border: 0,
    paddingLeft: `${theme.spacing(1)}!important`,
    borderTopLeftRadius: theme.display.borderRadius,
    borderBottomLeftRadius: theme.display.borderRadius,
  },

  '& td': {
    border: 0,
    paddingRight: `${theme.spacing(1)}!important`,
    borderTopRightRadius: theme.display.borderRadius,
    borderBottomRightRadius: theme.display.borderRadius,
  },
}))
