import React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'

import theme from '../../theme'
import { CustomTippyStyles, MissingInnerContainer, MissingOuterContainer } from '../Tooltip/Tooltip.styles'

export type MissingTipProps = TippyProps

const MissingTip = (props: MissingTipProps) => {
  const { children } = props
  const defaultProps: TippyProps = {
    arrow: true,
    zIndex: theme.display.missingFieldTooltipZIndex,
    allowHTML: true,
    placement: 'left',
    theme: 'missing',
    showOnCreate: true,
    hideOnClick: false,
    trigger: 'manual',
    appendTo: 'parent',
  }

  const tippyProps: TippyProps = { ...defaultProps, ...props }

  return (
    <MissingOuterContainer>
      <CustomTippyStyles wide={false} />
      <Tippy {...tippyProps}>
        <MissingInnerContainer>{children}</MissingInnerContainer>
      </Tippy>
    </MissingOuterContainer>
  )
}

export default MissingTip
