import { FieldReadFunction } from '@apollo/client'

import { CurrentPipelineReport, formatAsCurrency, ReportsCurrentPipelineResponse } from '@acre/graphql'

import { CaseStatusColumns } from './helpers'

const read: FieldReadFunction<CurrentPipelineReport[]> = (_, { readField, variables }) => {
  const reportsCurrentPipelineResponse = readField<ReportsCurrentPipelineResponse>({
    fieldName: 'reportsCurrentPipeline',
    args: variables?.body ? { body: variables.body } : {},
  })

  const columns = Object.values(CaseStatusColumns)

  const reportsCurrentPipelineGroupedResults = reportsCurrentPipelineResponse?.grouped_results?.map((groupResult) => {
    const columnValues = {} as Partial<CurrentPipelineReport>

    columns.forEach((column) => {
      const presentValue = groupResult.results?.find((statRef) => readField<string>('case_status', statRef) === column)

      columnValues[column] = {
        value: formatAsCurrency(presentValue?.value || 0),
        count: presentValue && presentValue.count ? presentValue.count : 0,
      }
    })

    const row = {
      id: groupResult.group_id,
      view_by: variables?.body.view_by || '',
      ...columnValues,
    } as CurrentPipelineReport

    return row
  })

  return reportsCurrentPipelineGroupedResults || []
}

export default {
  read,
}
