import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface TextAreaClasses {
  root: string
  wrapper: string
}

export type TextAreaClassKey = keyof TextAreaClasses

export function getTextAreaUtilityClass(slot: string) {
  return generateUtilityClass('TextArea', slot)
}

const TextAreaClasses: TextAreaClasses = generateUtilityClasses('TextArea', ['root', 'wrapper'])

export default TextAreaClasses
