import React from 'react'
import { useReportProblemContext } from '@broker-crm-contexts'
import { IconButton } from '@mui/material'

import { testHandle, useFormatMessage } from '@acre/utils'
import { Maybe } from '@acre/graphql'
import { AvatarNameBuilder, ColourId, useButtonMenu } from '@acre/design-system'

import ReportProblem from '../../../ReportProblem'
import { useSettingsOptions } from './UserSettings.helpers'

type Props = {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  organisationId?: Maybe<string>
  userId?: Maybe<string>
}

const UserSettings = ({ firstName, lastName, organisationId, userId }: Props) => {
  const options = useSettingsOptions({ organisationId, userId })
  const formatMessage = useFormatMessage()
  const { isProblemReported, setIsProblemReported } = useReportProblemContext()

  const reportButtonConfig = {
    id: 'ReportButtonNav',
    onClick: () => setIsProblemReported(!isProblemReported),
    children: formatMessage('nav.settings.reportProblem'),
  }

  const menu = [reportButtonConfig, ...options]

  const { buttonProps, Menu } = useButtonMenu({
    menu: menu,
    size: 'large',
  })

  return (
    <>
      <ReportProblem />
      <IconButton data-testid={testHandle('NavGlobalUserSettings')} {...buttonProps}>
        <AvatarNameBuilder
          className="userName"
          firstName={firstName!}
          lastName={lastName!}
          colour={ColourId.LightBlue}
          hideUserName
        />
      </IconButton>
      <Menu />
    </>
  )
}

export default UserSettings
