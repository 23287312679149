import { isNil } from 'lodash'

import { Metadata, UserMetadata } from '@acre/graphql'

type UserOrOrganisationMetadata = Omit<UserMetadata | Metadata, '__typename'>[]

export const getMetadata = (metadata: UserOrOrganisationMetadata, name: string) => {
  const item = metadata.find((metadataItem) => metadataItem.name === name)
  return item?.string_value || undefined
}

export const getMetadataBoolean = (metadata: UserOrOrganisationMetadata, name: string) => {
  const item = metadata.find((metadataItem) => metadataItem.name === name)
  return item?.bool_value || false
}

export const getMetadataIndex = (metadata: UserOrOrganisationMetadata, name: string) => {
  return metadata.findIndex((metadataItem) => metadataItem.name === name)
}

export const deleteMetadata = (metadata: UserOrOrganisationMetadata, names: string[]) =>
  metadata.filter((item) => !names.includes(item.name!))

export const addOrOverwriteMetadata = (
  metadata: UserOrOrganisationMetadata,
  name: string,
  value?: string | boolean | null,
  isUserEditable?: boolean | null,
) => {
  const targetIndex = getMetadataIndex(metadata, name)
  const exists = targetIndex > -1
  let newMetadata = metadata.map((item, index) => {
    if (targetIndex === index && typeof value === 'string') {
      return { ...item, string_value: value }
    } else if (targetIndex === index && typeof value === 'boolean') {
      return { ...item, bool_value: value }
    }
    return item
  })

  if (typeof value === 'string' && !exists) {
    // by default set user editable to true
    newMetadata.push({ name, user_editable: isNil(isUserEditable) ? true : isUserEditable, string_value: value })
  } else if (typeof value === 'boolean' && !exists) {
    newMetadata.push({ name, user_editable: isNil(isUserEditable) ? true : isUserEditable, bool_value: value })
  }

  return newMetadata
}
