import React, { ChangeEvent, FocusEvent, ReactNode } from 'react'
import { Theme } from '@mui/material'
import classNames from 'classnames'

import { isNil } from '@acre/utils'
import { Maybe } from '@acre/graphql'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import useFormatMessage from '../../hooks/useFormatMessage'
import { InputCoreProps } from '../../types'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { DropdownOption } from '../../utils/types'
import HelperText from '../HelperText'
import Label from '../Label'
import LoadingSpinner from '../LoadingSpinner'
import MatomoWrapper from '../MatomoWrapper'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { Select, SpinnerWrapper } from './Dropdown.styles'

interface DropdownProps extends InputCoreProps<HTMLSelectElement> {
  name?: string
  value?: string | null
  options: DropdownOption<string | number>[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  onFocus?: (e: FocusEvent<HTMLSelectElement>) => void
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void
  defaultValue?: string
  id?: string
  label?: string
  error?: boolean
  message?: string
  disabled?: boolean
  ariaLabel?: string
  selectMessage?: string
  selectMessageRaw?: string
  placeholderColour?: keyof Theme['colours']
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  isLoading?: boolean
  secondaryText?: ReactNode
  labelWidth?: Maybe<string>
}

const Dropdown = ({
  inputRef,
  id,
  name,
  label,
  error,
  value,
  message,
  options,
  disabled: disabledProp,
  ariaLabel,
  defaultValue = '',
  onChange,
  onFocus,
  onBlur,
  selectMessageRaw,
  selectMessage = 'select.message',
  placeholderColour = 'baseMid',
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant = 'default',
  isLoading = false,
  secondaryText,
  labelWidth,
}: DropdownProps) => {
  const formatMessage = useFormatMessage()
  const dataTestId = id ? testHandle(id) : null

  const disabled = useFieldDisabledState(disabledProp)

  const textLimit = 45

  const className = classNames({
    error,
    disabled,
    empty: (value === selectMessage && !error) || isNil(value) || value === '',
  })

  return (
    <MatomoWrapper onChange={onChange} id={id} eventType={'dropdownChange'}>
      <div data-testid={`${dataTestId}Wrapper`} style={{ position: 'relative' }}>
        <LabelAndInputWrapper labelWidth={labelWidth} variant={variant}>
          {label && (
            <Label
              htmlFor={id}
              isDisabled={false}
              text={label}
              isMissing={isMissing}
              isIncomplete={isIncomplete}
              verificationError={verificationError}
              secondaryText={secondaryText}
              variant={variant}
            />
          )}

          <div>
            <Select
              ref={inputRef}
              id={id}
              name={name || id}
              value={value ?? ''}
              disabled={disabled || isLoading}
              className={className}
              aria-label={label || ariaLabel}
              data-testid={dataTestId}
              onChange={(e) => {
                e.stopPropagation()
                onChange(e)
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholderColour={placeholderColour}
              variant={variant}
              isLoading={isLoading}
            >
              {defaultValue.toLowerCase() !== 'hide' && (
                <option value={defaultValue}>{selectMessageRaw || formatMessage(selectMessage)}</option>
              )}
              {options.map((option: DropdownOption<string | number>, index: number) => (
                <option key={`${option.value}${index}`} value={option.value} disabled={option.disabled}>
                  {option.label.length < textLimit ? option.label : option.label.substring(0, textLimit - 3) + '...'}
                </option>
              ))}
            </Select>
          </div>
          {isLoading && (
            <SpinnerWrapper>
              <LoadingSpinner variant={variant} inverted />
            </SpinnerWrapper>
          )}
        </LabelAndInputWrapper>

        {message && (
          <HelperText
            id={`${id}Helper`}
            message={message || ''}
            textType={error ? HELPER_TYPE_ERROR : ''}
            variant={variant}
          />
        )}
        {isMissing && (
          <HelperText
            id={`${id}Helper`}
            message={formatMessage('errors.missingFieldRequired')}
            textType={HELPER_TYPE_ERROR}
            variant={variant}
          />
        )}
      </div>
    </MatomoWrapper>
  )
}

export default withDisabled(Dropdown)
