import { DepositState } from '@broker-crm-common/types'

import { YesNo } from '@acre/utils'
import {
  ClientIncome,
  ConveyancingPartner,
  ConveyancingStatus,
  ConveyancingType,
  Maybe,
  RelatedUser,
} from '@acre/graphql'

export enum CapitalRepaymentType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum RateType {
  Fixed = 'FIXED',
  Discount = 'DISCOUNT',
  Tracker = 'TRACKER',
  InvalidType = 'INVALID_TYPE',
}

export enum RepaymentTypes {
  Repayment = 'REPAYMENT',
  InterestOnly = 'INTEREST_ONLY',
  PartAndPart = 'PART_AND_PART',
}

export type CaseDetailsFormState = {
  preference_fixed_rate?: Maybe<boolean>
  preference_early_year_discount?: Maybe<boolean>
  preference_no_early_repayment_charge?: Maybe<boolean>
  preference_no_tie_in?: Maybe<boolean>
  preference_completion_speed_important?: Maybe<boolean>
  preference_fee_to_loan?: Maybe<boolean>
  preference_vary_repayment_amount?: Maybe<boolean>
  preference_payment_holiday?: Maybe<boolean>
  preference_link_finances?: Maybe<boolean>
  lender_has_proposed_new_product?: Maybe<boolean>
  preference_cashback?: Maybe<boolean>
  preference_overpayment?: Maybe<boolean>
  preference_free_conveyancing?: Maybe<boolean>
  preference_free_valuations?: Maybe<boolean>
  preference_rate_type?: Maybe<RateType>
  preference_capital_repayment_type?: Maybe<CapitalRepaymentType>
  preference_repayment_amount?: Maybe<number>
  has_previously_owned_property?: Maybe<YesNo>
  has_seen_face_to_face?: Maybe<boolean>
  impersonation_check_performed?: Maybe<boolean>
  impersonation_check_details?: Maybe<string>
  is_debt_consolidation?: Maybe<boolean>
  btl_has_lived?: Maybe<boolean>
  btl_will_lived?: Maybe<boolean>
  btl_is_operating_a_business?: Maybe<boolean>
  btl_through_company?: Maybe<boolean>
  preference_tracker?: Maybe<boolean>
  is_introduced?: Maybe<boolean>
  preference_details?: Maybe<string>
  preference_interest_only_repayment_strategy?: Maybe<string>
  preference_upper_limit?: Maybe<string>
  preference_target_property?: Maybe<string>
  preference_target_deposit?: Maybe<string>
  preference_target_property_value?: Maybe<string>
  preference_mortgage_loan_amount?: Maybe<string>
  additional_loan_required?: Maybe<boolean>
  additional_loans?: [
    {
      loan_amount?: Maybe<string>
      loan_purpose?: Maybe<string>
    },
  ]
  preference_loan_amount?: Maybe<string>
  preference_related_property_sale?: Maybe<string>
  recommendation_term_reason?: Maybe<string>
  recommendation_reason_for_affordability?: Maybe<string>
  recommendation_reason_for_recommendation?: Maybe<string>
  reason_for_recommendation_product_type?: Maybe<string>
  reason_for_recommendation_initial_term?: Maybe<string>
  recommendation_reason_for_remortgage_cost_increase?: Maybe<string>
  recommendation_reason_for_recommendation_term_increase?: Maybe<string>
  recommendation_reason_term_into_retirement?: Maybe<string>
  debt_consolidation_alternative_options?: Maybe<string>
  debt_consolidation_reason_for_consolidated_debts?: Maybe<string>
  recommendation_reason_additional_borrowing?: Maybe<string>
  suitability_report_recommendation_introduction?: Maybe<string>
  recommendation_reason_strategy_for_btl_void_period_coverage?: Maybe<string>
  recommendation_reason_help_to_buy_loan_repayment_strategy?: Maybe<string>
  recommendation_reason_retirement_interest_only?: Maybe<string>
  introducer_details?: Maybe<string>
  change_of_preference_reason?: Maybe<string[]>
  preference_term?: Maybe<number>
  preference_repayment_percentage?: Maybe<number>
  preference_initial_period_months?: Maybe<number>
  preference_repayment_method?: Maybe<RepaymentTypes>
  protection?: {
    has_client_accepted_protection_advice?: Maybe<boolean>
    inheritance_advice_indicator?: Maybe<boolean>
    insistent_client_indicator?: Maybe<boolean>
    client_protection_objectives_circumstances?: Maybe<string>
    client_advice_limit_details?: Maybe<string>
    include_employee_benefits_in_shortfall?: Maybe<boolean>
    employee_benefits_shortfall_reasoning?: Maybe<string>
    death_lump_sum_needs_met_by_preexisting?: Maybe<boolean>
    illness_needs_met_by_preexisting?: Maybe<boolean>
    death_needs_lump_sum_amount?: Maybe<string>
    death_needs_lump_sum_term?: Maybe<number | string>
    death_needs_lump_sum_term_justification?: Maybe<string>
    death_needs_income_amount?: Maybe<string>
    death_needs_income_term?: Maybe<number | string>
    death_needs_income_term_justification?: Maybe<string>
    illness_needs_income_amount?: Maybe<string>
    illness_needs_income_term?: Maybe<number | string>
    illness_needs_deferrment_period_weeks?: Maybe<number>
    illness_needs_term_justification?: Maybe<string>
    income_has_critical_illness_requirement?: Maybe<boolean>
    current_employee_benefits_exist?: Maybe<boolean>
    has_employee_benefits?: Maybe<boolean>
    income_has_death_requirement?: Maybe<boolean>
    lump_sum_has_critical_illness_requirement?: Maybe<boolean>
    lump_sum_has_death_requirement?: Maybe<boolean>
    death_needs_lump_sum_client_ids?: Maybe<string[]>
    death_needs_income_client_ids?: Maybe<string[]>
    illness_needs_client_ids?: Maybe<string[]>
    funeral_needs_client_ids?: Maybe<string[]>
    funeral_needs_lump_sum_amount?: Maybe<string>
    funeral_needs_justification?: Maybe<string>
    funeral_needs_met_by_preexisting?: Maybe<boolean>
    recommend_placing_in_trust?: Maybe<boolean>
    specific_trust_recommendation?: Maybe<boolean>
    trust_details?: Maybe<string>
    client_confirms_terms_exceeding_retirement_affordable?: Maybe<boolean>
    client_advice_limit?: Maybe<Boolean>
  }
  deposits?: DepositState[]
  relatedUsers?: RelatedUser[]
  conveyancing_type?: Maybe<ConveyancingType>
  own_solicitor_details?: {
    firm_name?: Maybe<string>
    contact_name?: Maybe<string>
    email_address?: Maybe<string>
    phone_number?: Maybe<string>
    dx_number?: Maybe<string>
    dx_town?: Maybe<string>
    case_reference?: Maybe<string>
  }
  conveyancer_acre_quote_reference?: Maybe<string>
  conveyancing_status?: Maybe<ConveyancingStatus>
  conveyancing_partner?: Maybe<ConveyancingPartner>
  [key: string]: any
} & (
  | {
      // Values needed for the protection needs form
      valuesAcrossBothClients?: ClientIncome[]
    }
  | ClientIncome
)
