import { ChangeEvent, useEffect, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Form } from 'react-final-form'
import { useLocation, useParams } from 'react-router-dom'

import { NoteStatus, UiReferenceSectionType } from '@acre/graphql'
import { trackEventFiltered } from '@acre/design-system'

import {
  FENoteType,
  feToBeNoteTypeMapping,
  useAddNote,
} from '../../../../pages/Cases/CaseDetails/CaseNotes/CaseNote.helpers'
import { UI_REFERENCE_AND_SECTIONNAME_MAP } from '../PageNotes.helpers'
import AddNewPageNotesContent from './AddNewPageNotesContent'

type Props = {
  orgIdForCaseNotes: string
  orgIds: string[]
  factFindSection: string
}

const AddNewPageNotes = ({ orgIds, orgIdForCaseNotes, factFindSection }: Props) => {
  const { id: caseId } = useCaseContext()
  const { trackEvent } = useMatomo()

  const location = useLocation()

  const [noteBody, setNoteBody] = useState<string>('')
  const [section, setSection] = useState<UiReferenceSectionType>()

  const { loading: addingNote, addNote } = useAddNote({ orgId: orgIdForCaseNotes!, caseId })

  const { propertyId, clientId } = useParams<{ caseId: string; propertyId: string; clientId?: string }>()

  const handleAddNote = () => {
    addNote({
      ...feToBeNoteTypeMapping(FENoteType.General),
      organisation_ids: orgIds,
      case_id: caseId,
      body: noteBody,
      property_id: propertyId,
      client_id: clientId,
      status: NoteStatus.Read,
      ui_reference: { section: section },
    })
    setNoteBody('')
    trackEventFiltered(trackEvent, location, 'addNoteToPageButtonClick', `page-${factFindSection}`)
  }

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setNoteBody(newValue)
  }

  useEffect(() => {
    setSection(UI_REFERENCE_AND_SECTIONNAME_MAP[factFindSection])
  }, [factFindSection])

  return (
    <Form onSubmit={handleAddNote}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="AddPageNotesForm">
          <AddNewPageNotesContent
            handleNoteChange={handleNoteChange}
            handleAddNote={handleAddNote}
            addingNote={addingNote}
            noteBody={noteBody}
          />
        </form>
      )}
    </Form>
  )
}

export default AddNewPageNotes
