import { FieldReadFunction, Reference } from '@apollo/client'

import {
  FeeType,
  Maybe,
  Mortgage,
  MortgageCalculatedValues,
  MortgageProduct,
  MortgageStatus,
  NewFee,
  PredictedRevenueEntry,
  ProtectionProductDetails,
} from '@acre/graphql'

import { getPredictedRevenueFromClient } from './getPredictedRevenueFromClient'
import { getPredictedRevenueFromMortgage } from './getPredictedRevenueFromMortgage'
import { getPredictedRevenueFromProtection } from './getPredictedRevenueFromProtection'

const read: FieldReadFunction<PredictedRevenueEntry[]> = (_, { readField, variables, toReference }) => {
  const caseRef = toReference(`Case:${variables!.caseId}`)
  const mortgagesRef = readField<undefined | Reference[]>('mortgages', caseRef)
  const preferenceLoanAmount = readField<Maybe<string> | undefined>('preference_loan_amount', caseRef)

  const mortgages = (mortgagesRef || []).map((mortgageRef) => {
    const mortgageProductRef = readField<MortgageProduct>('mortgage_product', mortgageRef)
    const lenderName = readField<string>('lender_name', mortgageProductRef)
    return {
      status: readField<MortgageStatus>('status', mortgageRef),
      calculated_values: readField<MortgageCalculatedValues>('calculated_values', mortgageRef),
      mortgage_amount: readField<string>('mortgage_amount', mortgageRef),
      mortgage_product: {
        lender_name: lenderName,
      },
    }
  })

  // Getting all case fees
  const NewFees = readField<NewFee[]>('fees', caseRef)?.map((feeRef) => ({
    fee_type: readField('fee_type', feeRef) as FeeType,
    value: readField('value', feeRef) as string,
  }))

  // Getting all protection products
  const protectionProductsRef = readField<undefined | Reference[]>({
    fieldName: 'protectionProducts',
    args: {
      protectionProductDetails: true,
      filterCaseIds: [variables!.caseId],
    },
  })
  const protectionProducts = protectionProductsRef?.map((ref) => {
    return readField('details', ref) as ProtectionProductDetails
  })
  const res = calculatePredictedRevenue({ mortgages, protectionProducts, NewFees, preferenceLoanAmount })

  return res
}

type Props = {
  NewFees?: NewFee[]
  mortgages?: Partial<Mortgage>[]
  protectionProducts?: ProtectionProductDetails[]
  preferenceLoanAmount?: Maybe<string>
}

const calculatePredictedRevenue = ({
  mortgages,
  protectionProducts,
  NewFees,
  preferenceLoanAmount,
}: Props): PredictedRevenueEntry[] => {
  const res = [
    ...getPredictedRevenueFromProtection(protectionProducts || []),
    ...getPredictedRevenueFromClient(NewFees, mortgages, preferenceLoanAmount),
  ] as PredictedRevenueEntry[]

  const revenueFromMortgageProducts = getPredictedRevenueFromMortgage(mortgages)
  if (revenueFromMortgageProducts) {
    res.push(revenueFromMortgageProducts)
  }

  return res
}

export default read
