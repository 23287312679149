import { isBetweenPositive0And100 } from '@acre/utils'

const messagePrefix = 'common.validation'

export function checkPercentageInputError(value: number | string) {
  if (!isNaN(Number(value)) && !isBetweenPositive0And100(value)) {
    return `${messagePrefix}.isBetweenPositive0And100`
  }

  return ''
}
