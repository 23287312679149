import React, { memo } from 'react'
import { Theme } from '@mui/material'

import { getColourHex } from '../../utils/colourHex'
import { ColourId } from '../../utils/types'
import { InitialsWrapper } from './UserAvatar.styles'

export type UserAvatarProps = {
  initials: string
  colourId?: ColourId
  size?: keyof Pick<Theme['spacers'], 'size24' | 'size32' | 'size48'>
  id?: string
  onClick?: () => void
}

const UserAvatar = ({ initials, colourId = ColourId.LightGreen, onClick, size = 'size24', id }: UserAvatarProps) => {
  const colourHex = getColourHex(colourId)

  return (
    <InitialsWrapper id={id} color={colourHex} onClick={onClick} size={size}>
      <p>{initials || '-'}</p>
    </InitialsWrapper>
  )
}

export default memo(UserAvatar)
