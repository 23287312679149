import React, { ReactNode } from 'react'
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, LinearProgress } from '@mui/material'

import DialogTitle, { DialogTitleProps } from '../DialogTitle'

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  title?: ReactNode
  DialogTitleProps?: Omit<DialogTitleProps, 'children'>
  loading?: boolean
}

const Dialog = ({ children, loading, title, DialogTitleProps, ...props }: DialogProps) => (
  <MuiDialog {...props}>
    {title && (
      <DialogTitle {...DialogTitleProps} onClose={props.onClose}>
        {title}
      </DialogTitle>
    )}
    {loading && <LinearProgress variant="query" />}
    {children}
  </MuiDialog>
)

export default Dialog
