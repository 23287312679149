import { styled } from '@mui/material'
import type { DocumentProps, PageProps } from 'react-pdf'

export const Div = styled('div')`
  display: flex;
  justify-content: flex-start;
`

export const DocumentWrapper = styled('div')`
  display: flex;
  justify-content: center;
`

export const HtmlWrapper = styled('div')(
  ({ theme }) => `
  max-width: ${theme.wrappers.a4Width};
  padding: ${theme.spacing(2)};

  & h1,
  h2,
  h3 {
    font-size: 16;
    margin-bottom: ${theme.display.ms0};
  }

  & p {
    margin-bottom: ${theme.display.ms2};
    line-height: 1.6;
  }

  & table {
    border: 1px solid black;
    margin-top: 1em;
    margin-bottom: 1em;
  }
`,
)

export const ControlPanelStyle = styled('div')`
  position: absolute;
  width: 192px;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ZoomControlsWrapper = styled('div')(
  ({ theme }) => `
  position: fixed;
  left: 0;
  bottom: ${theme.display.ms3};
  width: 100%;
  display: flex;
  justify-content: center;
`,
)

export const ZoomButtons = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;

  & button {
    background-color: ${theme.colours.base};
    transition: background-color 0.1s ease-in-out;

    color: #fff;
    width: 3.5rem;
    height: 3.5rem;

    & svg {
      margin-top: 2px;
    }

    &:hover {
      background-color: ${theme.colours.baseMid};
    }
  }

  & > :first-of-type {
    border-top-left-radius: ${theme.display.borderRadius};
    border-bottom-left-radius: ${theme.display.borderRadius};
  }

  & > :last-of-type {
    border-top-right-radius: ${theme.display.borderRadius};
    border-bottom-right-radius: ${theme.display.borderRadius};
  }
`,
)

export const StyledPage = styled('div')<{ border: boolean } & PageProps>(
  ({ theme, border }) => `
  border: ${border ? `1px solid ${theme.colours.baseLight}` : 'none'};
`,
)

export const FullWidthPdfDocument = styled('div')<{ fullWidth: boolean } & DocumentProps>(
  ({ fullWidth }) => `
  width: ${fullWidth ? '100%' : 'auto'};
`,
)

export const FullWidthDocument = styled('div')<{ fullWidth: boolean }>(
  ({ fullWidth }) => `
  width: ${fullWidth ? '100%' : 'auto'};
`,
)

export const ScaledImage = styled('img')<{ scale: number }>(
  ({ scale, theme }) => `
  width: calc(${scale} * ${theme.wrappers.medium});
  height: auto;
`,
)

export const Spreadsheet = styled(Div)(
  ({ theme }) => `
  max-width: ${theme.wrappers.a4Width};
  flex-direction: column;
  > div {
    margin-bottom: ${theme.spacing(4)};
  }
`,
)

export const WorksheetWrapper = styled(HtmlWrapper)(
  ({ theme }) => `
  overflow-x: scroll;
  & table {
    table-layout: fixed;
    border: 0;
    margin: 0;
  }
  & table,
  td,
  th {
    border-collapse: collapse;
    border: 1px solid ${theme.colours.grey};
  }
  td {
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
    height: ${theme.spacing(4)};
    min-width: ${theme.spacing(4)};
  }
`,
)
