import { useCaseContext } from '@broker-crm-contexts'

import { useFormatMessage } from '@acre/utils'
import { CaseStatus } from '@acre/graphql'

import { TasksLocation } from '../../../pages/Cases/CaseDetails/CaseOverview/CaseOverview.helpers'
import VerificationsContainer from '../../../pages/Cases/CaseDetails/CaseOverview/VerificationsContainer'
import CollapsableSection, { SidebarSection } from '../CollapsableSection'
import { SectionWrapper } from '../ReviewModeDrawer.styles'

const Tasks = () => {
  const { details: caseDetails } = useCaseContext()
  const formatMessage = useFormatMessage()

  const isNotProceeding = caseDetails.status === CaseStatus.NotProceeding
  const isCaseLead = caseDetails.status === CaseStatus.Lead

  if (isNotProceeding || isCaseLead) {
    return null
  }

  return (
    <SectionWrapper>
      <CollapsableSection sectionId={SidebarSection.Tasks} header={formatMessage('cases.reviewMode.tasks')}>
        <VerificationsContainer tasksLocation={TasksLocation.CaseSidebarDrawer} />
      </CollapsableSection>
    </SectionWrapper>
  )
}

export default Tasks
