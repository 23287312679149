import { useMemo } from 'react'

import { useUserContext } from '@acre/utils'
import { useGetIntroducersCommissionsListQuery, User } from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

export type OptionWithUsers = DropdownOption & { users: Pick<User, 'id' | 'first_name' | 'last_name'>[] }

export const useIntroducerOptions = () => {
  const user = useUserContext()

  const { data: commissionStructures, loading } = useGetIntroducersCommissionsListQuery({
    variables: { input: { org_id: user?.organisation_id! }, includeUsers: true },
    skip: !user?.organisation_id,
  })

  const introducerOptions: OptionWithUsers[] = useMemo(() => {
    if (!commissionStructures?.introducers?.organisations) {
      return []
    }

    return commissionStructures.introducers?.organisations.reduce((orgs, structure) => {
      if (structure?.users && structure?.users.length > 0) {
        const newOption: OptionWithUsers = {
          value: structure?.organisation_id,
          label: structure?.name!,
          users: structure?.users! as User[],
        }

        return [...orgs, newOption]
      }
      return orgs
    }, [] as OptionWithUsers[])
  }, [commissionStructures])
  return { options: introducerOptions, loading }
}
