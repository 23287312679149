import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface SelectFieldClasses {
  root: string
  field: string
  label: string
  select: string
  helperText: string
  errorText: string
  list: string
  option: string
  placeholder: string
}

export type SelectFieldClassKey = keyof SelectFieldClasses

export function getSelectFieldUtilityClass(slot: string) {
  return generateUtilityClass('SelectField', slot)
}

const selectFieldClasses: SelectFieldClasses = generateUtilityClasses('SelectField', [
  'root',
  'field',
  'label',
  'select',
  'helperText',
  'errorText',
  'list',
  'option',
  'placeholder',
])

export default selectFieldClasses
