import React from 'react'
import { Location } from 'history'
import { useIntl } from 'react-intl'

import ConfirmationNavigationModal from '../ConfirmationNavigationModal'

export type ConfirmationModalSaveChangesProps = {
  when: (location: Location) => boolean
}

const ConfirmationModalSaveChanges = ({ when }: ConfirmationModalSaveChangesProps) => {
  const intl = useIntl()
  const formatMessage = (id: string) => intl.formatMessage({ id })
  return (
    <ConfirmationNavigationModal
      message={(location) => (when(location) ? formatMessage('generic.unsavedChanges') : '')}
    >
      {({ displayMessage }) => displayMessage}
    </ConfirmationNavigationModal>
  )
}

export default ConfirmationModalSaveChanges
