import { Height, ModalSize } from './types'

export type Variant =
  | 'default'
  | 'compact'
  | 'small'
  | 'compactLeftAligned'
  | 'compactTopAligned'
  | 'compactLeftNarrow'
  | 'compactRadioLeft'
  | 'compactRightAligned'
  | 'large'

export type TableVariant = 'default' | 'greyed-out'
export const GREYED_OUT = 'greyed-out'

/*
 * Button constants
 */
export const BUTTON_PRIMARY = 'primary'
export const BUTTON_SECONDARY = 'secondary'
export const BUTTON_SECONDARY_SMALL = 'secondarySmall'
export const BUTTON_TAG = 'tag'
export const BUTTON_CLEAR = 'clear'
export const BUTTON_CLEAR_SMALL = 'clearSmall'
export const BUTTON_LINK = 'link'
export const BUTTON_LINK_SMALL = 'linkSmall'
export const BUTTON_OVERFLOW = 'overflow'
export const BUTTON_DANGER = 'danger'
export const BUTTON_DANGER_SECONDARY = 'dangerSecondary'
export const BUTTON_POPOVER = 'popOver'
export const BUTTON_POPOVER_DANGER = 'popOverDanger'
export const BUTTON_DASHED = 'dashed'
export const BUTTON_HOVER = 'hoverButton'
export const BUTTON_DROPDOWN_MENU = 'dropdownMenu'
export const BUTTON_COLOURED = 'coloured'

export const BUTTON_TYPE_BUTTON = 'button'
export const BUTTON_TYPE_SUBMIT = 'submit'

export const HELPER_TYPE_SUCCESS: string = 'success'
export const HELPER_TYPE_ERROR: string = 'error'
export const HELPER_TYPE_TIP: string = 'tip'
export const COMPACT = 'compact'
export const DEFAULT = 'default'
export const SMALL = 'small'
export const COMPACT_LEFT_ALIGNED = 'compactLeftAligned'
export const COMPACT_RIGHT_ALIGNED = 'compactRightAligned'
export const COMPACT_TOP_ALIGNED = 'compactTopAligned'
/*
 * Height constants
 */
export const SHORT: Height = 'short'
export const MEDIUM: Height = 'medium'
export const HIGH: Height = 'high'

// Table variant
export const LARGE: ModalSize = 'large'
export const EXTRA_LARGE: ModalSize = 'extraLarge'
export const FULL_SCREEN: ModalSize = 'fullScreen'
