import { FieldReadFunction } from '@apollo/client'

import { MortgageStatus } from '@acre/graphql'

const read: FieldReadFunction<MortgageStatus | undefined> = (_, { readField, args, toReference }) => {
  const documentRef = toReference({ __typename: 'Document', document_id: args?.id })
  const mortgageId = readField<string>('rendered_mortgage_ids', documentRef)?.[0]
  const mortgageRef = toReference(`Mortgage:${mortgageId}`)

  return readField('status', mortgageRef)
}

export default { read }
