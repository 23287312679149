import { v4 } from 'uuid'

/**
 * Type for a function that generates a unique ID
 * @param length - 1 TO 36 -  Optional parameter for the length of the ID, uuid returns string of 36 characters
 * @returns A string representing the unique ID
 */
type generateUniqueIdType = (length?: number) => string

const generateUniqueId: generateUniqueIdType = (length = 36) => v4().substring(0, length)

export default generateUniqueId
