import { useMemo } from 'react'
import usePreviewMode from '@broker-crm-common/hooks/usePreviewMode'
import { useLocation } from 'react-router-dom'

import { useFormatMessage } from '@acre/utils'
import { Maybe } from '@acre/graphql'
import envConfig from '@acre/config'

const { API1_URL, LOGOUT_URL } = envConfig

export const useSettingsOptions = ({
  organisationId,
  userId,
}: {
  organisationId: Maybe<string>
  userId: Maybe<string>
}) => {
  const formatMessage = useFormatMessage()
  const { pathname } = useLocation()
  const { active: previewModeActive, allowed: previewModeAllowed } = usePreviewMode()

  const env = window.location.hostname.includes('myac.re') ? 'prod' : 'staging'

  const hostname = `crm${previewModeActive ? '' : '-next'}.${env === 'prod' ? 'myac.re' : 'acreplatforms.co.uk'}`

  return useMemo(
    () => [
      {
        id: 'CompanySettingsNav',
        to: `/company/${organisationId}/information`,
        children: formatMessage('nav.settings.companySettings'),
      },
      {
        id: 'UserSettingsNav',
        to: `/user/${userId}/settings/credentials`,
        children: formatMessage('nav.settings.mySettings'),
      },
      {
        id: 'PreviewModeNav',
        to: `https://${hostname}${pathname}`,
        children: formatMessage(
          previewModeActive ? 'nav.settings.disablePreviewMode' : 'nav.settings.enablePreviewMode',
        ),
        externalLink: true,
        hide: !previewModeAllowed,
      },
      {
        id: 'KnowledgeBaseNav',
        to: `${API1_URL}/acre/authentication/zendesk/login`,
        children: formatMessage('nav.settings.knowledgeBase'),
        openInNewTab: true,
        externalLink: true,
      },
      {
        id: 'SignOutNav',
        to: LOGOUT_URL,
        children: formatMessage('nav.settings.signOut'),
        externalLink: true,
      },
    ],
    [organisationId, formatMessage, userId, hostname, pathname, previewModeActive, previewModeAllowed],
  )
}
