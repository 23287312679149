import * as yup from 'yup'

import { unwrapErrors } from '@acre/utils'
import { GetCaseOwnersQuery } from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

import { CaseListCaseOwnersFilterType, CaseListOwnersFormFields } from './CaseOwnersFilterForm.types'

const schema = yup.object<CaseListCaseOwnersFilterType>({
  [CaseListOwnersFormFields.OwnerUserId]: yup.string().ensure().nullable(),
})

export const validate = unwrapErrors(schema)

export const getCaseOwnersOptions = (caseOwners: GetCaseOwnersQuery['caseOwners']): DropdownOption[] =>
  caseOwners
    ? caseOwners.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }))
    : []

export const filterCaseOwnersInitialState: CaseListCaseOwnersFilterType = {
  [CaseListOwnersFormFields.OwnerUserId]: '',
}
