import React, { useContext } from 'react'
import { PermissionResolution } from '@broker-crm-common'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'

import { UserContext } from '@acre/utils'
import { getUserOrganisationIds } from '@acre/utils'
import { GateName, PermissionScopeType } from '@acre/graphql'

import { checkIfFirstTimeUser } from '../pages/Onboarding/Onboarding.helpers'

export const RedirectOnRoot = () => {
  const user = useContext(UserContext)

  return (
    <PermissionResolution
      inputs={[
        {
          gates: [GateName.VIEW_CASE_COMPLIANCE],
          entityIdsCollection: {
            [PermissionScopeType.organisation]: user ? getUserOrganisationIds({ user }).all : [],
          },
        },
        {
          gates: [GateName.EDIT_CASE],
          entityIdsCollection: {
            [PermissionScopeType.organisation]: user ? getUserOrganisationIds({ user }).all : [],
          },
        },
      ]}
    >
      {({ gates }) => {
        const redirect = Cookies.get('login_redirect')
        const isComplianceUser = gates.includes(GateName.VIEW_CASE_COMPLIANCE) && !gates.includes(GateName.EDIT_CASE)
        const { isFirstTimeUsingAcre } = checkIfFirstTimeUser(user)

        if (redirect) {
          Cookies.remove('login_redirect')
        }

        let path = '/compliance/cases'

        if (isFirstTimeUsingAcre) {
          path = '/welcome'
        } else if (!isComplianceUser) {
          path = redirect || '/cases/board-view'
        }

        return <Navigate to={path} />
      }}
    </PermissionResolution>
  )
}
