import { DropdownOption } from '../../utils/types'

export type OptionGroup = { label: string; options: DropdownOption[] }

export const groupifyOptions = (options: DropdownOption[]) =>
  [
    {
      label: 'Data',
      options: options,
    },
  ] as OptionGroup[]
