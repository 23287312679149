import React, { ReactNode } from 'react'
import { styled, Theme } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

import { testHandle } from '@acre/utils'

import { StyledTd, TdContent } from './Table.styles'

export interface TdProps extends React.ComponentProps<typeof StyledTd> {
  padLeftForInputTable?: boolean
  bold?: boolean
  padding?: boolean
  fontSize?: number
  rowPadding?: keyof Theme['spacers']
  gutterPadding?: keyof Theme['spacers']
  cellPadding?: keyof Theme['spacers']
  verticallyAlign?: boolean
  colSpan?: number
  showIncomplete?: boolean
  width?: string
  isHidden?: boolean
  align?: 'justify' | 'left' | 'right' | 'center'
  routerTo?: string
  target?: string
  rel?: string
  id?: string
  style?: object
}

type StyleTypes = {
  align?: 'justify' | 'left' | 'right' | 'center'
}

// padded prop is passed to spacing styles and should not be on the Link component
const ReactRouterLink = (props: LinkProps & StyleTypes) => {
  const linkProps = { ...props, padded: undefined }
  return <Link {...linkProps}></Link>
}

const StyledLink = styled(ReactRouterLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

// What even is this component? It's a table cell, but it's also a link, but it's also a router link.
const Td = ({
  children,
  isHidden = false,
  align = 'justify',
  routerTo,
  target,
  rel,
  gutterPadding,
  rowPadding,
  fontSize,
  cellPadding,
  width,
  id,
  verticallyAlign = false,
  colSpan,
  showIncomplete,
  style,
  ...rest
}: TdProps) => {
  const styleProps = { align, colSpan }
  const content = children !== null && !isHidden && children
  const incompleteBanner = showIncomplete ? 'incompleteBanner' : ''

  const conditionalChild = (content: ReactNode) =>
    routerTo ? (
      <StyledLink
        data-testid={id && testHandle(id)}
        target={target}
        rel={rel}
        {...(rest as LinkProps & StyleTypes)}
        to={routerTo}
      >
        {content}
      </StyledLink>
    ) : (
      <TdContent data-testid={id && testHandle(id)} {...styleProps}>
        {content}
      </TdContent>
    )

  return (
    <StyledTd
      gutterPadding={gutterPadding}
      rowPadding={rowPadding}
      cellPadding={cellPadding}
      fontSize={fontSize}
      id={id}
      verticallyAlign={verticallyAlign}
      width={width || 'auto'}
      {...styleProps}
      className={incompleteBanner}
      style={style}
    >
      {conditionalChild(content)}
    </StyledTd>
  )
}

export default Td
