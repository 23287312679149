import { ClientVersion, getClientName } from '@acre/graphql'

const getName = (clients: ClientVersion[]): string => {
  const nClients = clients.length
  const name = getClientName({ client: clients[0].details })
  const extra = nClients > 1 ? ` + ${nClients - 1} other` : ''
  const s = nClients > 2 ? 's' : ''
  return `${name}${extra}${s}`
}

export default getName
