import { Typography } from '@mui/material'
import { lighten, styled } from '@mui/material/styles'
import { NavLink, NavLinkProps } from 'react-router-dom'

export type StyledDashboardsNavLinkProps = { danger?: boolean }

export type DashboardsNavButtonProps = {
  active: boolean
  open?: boolean
}

export const StyledDashboardsNavButton = styled('a')<DashboardsNavButtonProps>(({ theme, active, open }) => ({
  position: 'relative',
  display: 'flex',
  flex: 1,

  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  marginLeft: theme.spacing(3 / 2),
  marginRight: theme.spacing(3 / 2),

  textDecoration: 'none',
  transition: 'all 0.15s ease-in-out',

  cursor: 'pointer',

  color: active ? theme.colours.primary : theme.colours.baseMid,

  '&::after': {
    content: '""',
    position: 'absolute',
    pointerEvents: 'none',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    transition: 'all 0.15s ease-in-out',
    backgroundColor: open || active ? theme.colours.primary : theme.colours.transparent,
  },

  '&:hover': {
    textDecoration: 'none',
    '&::after': {
      backgroundColor: theme.colours.primary,
    },
  },

  '&:disabled': {
    color: theme.colours.baseLight,
    fontSize: theme.typography.pxToRem(14),
    pointerEvent: 'none',
    cursor: 'unset',
    padding: 0,

    '&::after': {
      display: 'none',
    },
  },
}))

export const StyledDashboardsNavLink = styled(NavLink, { shouldForwardProp: (prop) => prop !== 'danger' })<
  NavLinkProps & StyledDashboardsNavLinkProps
>(({ theme, danger }) => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: danger ? theme.colours.danger : theme.colours.baseMid,
  width: theme.spacing(43.5),
  padding: theme.spacing(2),
  borderRadius: theme.display.borderRadius,
  textDecoration: 'none',
  transition: 'all 0.15s ease-in-out',

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    transition: 'all 0.15s ease-in-out',
    backgroundColor: 'transparent',
  },

  '&.active': {
    color: danger ? theme.colours.danger : theme.colours.primary,
    backgroundColor: lighten(theme.colours.new.blue1, 0.95),
  },

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: lighten(theme.colours.new.blue1, 0.95),
  },

  '&:disabled': {
    color: theme.colours.baseLight,
    fontSize: theme.typography.pxToRem(14),
    pointerEvent: 'none',
    cursor: 'unset',
    padding: 0,

    '&::after': {
      display: 'none',
    },
  },
}))

export const NavLinkIconStyles = styled('div')(({ theme }) => ({
  borderRadius: theme.display.borderRadius,
  width: theme.spacing(5),
  height: theme.spacing(5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const NavLinkWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(1.2),
}))

export type NavLinkMenuWrapperProps = {
  shouldRenderSingleColumn: boolean
}

export const NavLinkMenuWrapper = styled('div')<NavLinkMenuWrapperProps>(({ theme, shouldRenderSingleColumn }) => ({
  display: 'grid',
  gridTemplateColumns: shouldRenderSingleColumn ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
  rowGap: theme.spacing(1.5),
  columnGap: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}))
