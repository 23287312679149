import { useCallback } from 'react'
import { ValidationErrors } from 'final-form'
import { get } from 'lodash'
import { FieldRenderProps } from 'react-final-form'
import { IntlShape } from 'react-intl'

import { useFormatMessage } from '../hooks'

type GetFormMetaErrors = {
  meta: FieldRenderProps<any, HTMLElement>['meta']
  intl: IntlShape
  messageInsert?: { [key: string]: string }
  includeTouched?: boolean
  includeModified?: boolean
  isRadioGroup?: boolean
  showMessage?: boolean
}

export const useGetFormMetaErrors = () => {
  const formatMessage = useFormatMessage()

  return useCallback(
    ({
      meta,
      messageInsert,
      includeTouched = true,
      includeModified = false,
      isRadioGroup = false,
    }: Omit<GetFormMetaErrors, 'intl'>) => {
      const hasErrorTouched = includeTouched ? meta.touched && meta.error : meta.error
      const hasErrorModified = includeModified ? meta.modified && meta.error : meta.error
      const hasError = !isRadioGroup ? hasErrorTouched && hasErrorModified : meta.error

      return hasError && meta.error?.message ? formatMessage(meta.error.message, { ...messageInsert }) : undefined
    },
    [formatMessage],
  )
}

export const getFormMetaErrors = ({
  meta,
  intl,
  messageInsert,
  includeTouched = true,
  includeModified = false,
  isRadioGroup = false,
  showMessage = true,
}: GetFormMetaErrors) => {
  const hasErrorTouched = includeTouched ? meta.touched && meta.error : meta.error
  const hasErrorModified = includeModified ? meta.modified && meta.error : meta.error
  const hasError = !isRadioGroup ? hasErrorTouched && hasErrorModified : meta.error

  return {
    error: hasError,
    message: hasError && showMessage && intl.formatMessage({ id: meta.error?.message }, { ...messageInsert }),
  }
}

export const getError = (
  fieldName: string,
  meta: FieldRenderProps<any, HTMLElement>['meta'],
  intl: IntlShape,
  errors: ValidationErrors,
) => {
  const { message } = get(errors, fieldName) || {}

  return meta.touched && message ? { message: intl.formatMessage({ id: message }), error: true } : {}
}

type GetFieldArrayMetaErrors = {
  error: ValidationErrors
  meta: FieldRenderProps<any, HTMLElement>['meta']
  intl: IntlShape
  messageInsert?: { [key: string]: string }
  includeTouched?: boolean
}

export const getFieldArrayMetaErrors = ({
  error,
  meta,
  intl,
  messageInsert,
  includeTouched = true,
}: GetFieldArrayMetaErrors) => {
  const errorMessage = error && error.message
  const hasError = includeTouched ? meta.touched && errorMessage : errorMessage

  return {
    error: hasError,
    message: hasError && intl.formatMessage({ id: errorMessage }, { ...messageInsert }),
  }
}
