import Icon, { IconTypes } from '../Icon'
import { ZoomButtons } from './styles'

export type ZoomIconsProps = {
  stepSize: number
  updateScale: (step: number) => void
}

const ZoomIcons = ({ stepSize, updateScale }: ZoomIconsProps) => {
  return (
    <ZoomButtons>
      <button type="button" aria-label="Zoom out" value="Zoom out" onClick={() => updateScale(-stepSize)}>
        <Icon name={IconTypes.Minus} />
      </button>
      <button type="button" aria-label="Zoom in" value="Zoom in" onClick={() => updateScale(+stepSize)}>
        <Icon name={IconTypes.Plus} />
      </button>
    </ZoomButtons>
  )
}

export default ZoomIcons
