import { InMemoryCache } from '@apollo/client'

import { MockedProvider as BaseMockedProvider, MockedProviderProps as BaseMockedProviderProps } from '@acre/test'

import { cacheConfig } from '../../graphql/cache'

export interface MockedProviderProps extends Pick<BaseMockedProviderProps, 'mocks' | 'children'> {}

const MockedProvider = ({ mocks, children }: MockedProviderProps) => {
  const cache = new InMemoryCache(cacheConfig)

  return (
    <BaseMockedProvider mocks={mocks} cache={cache}>
      {children}
    </BaseMockedProvider>
  )
}

export default MockedProvider
