import { FieldReadFunction } from '@apollo/client'

import { generateUniqueId } from '@acre/utils'
import { Commission, CommissionStructureResponse } from '@acre/graphql'

const read: FieldReadFunction = (_, { readField, variables }) => {
  const commissionResponse = readField<CommissionStructureResponse>({
    fieldName: 'getCommissionStructures',
    args: variables?.input ? { input: variables.input, includePayingOrg: variables.includePayingOrg } : {},
  })

  const commissionRefKey = commissionResponse?.commission_structures[0]

  const commissionRecord = readField<Commission[]>('commissions', commissionRefKey)

  const commissionsColumn = commissionRecord?.map((commission) => {
    const id = generateUniqueId()
    const commissionWithId = {
      ...commission,
      id,
    }
    return commissionWithId
  })

  return commissionsColumn
}

export default {
  read,
}
