import { styled } from '@mui/material'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { Button, greyTextClasses, H5 } from '@acre/design-system'

export const NotificationsToggleButton = styled('button')<{ active: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  border-style: solid;
  border-width: 0 0 4px 0;
  border-color: ${({ active, theme }) => (active ? theme.colours.primary : 'transparent')};

  svg {
    position: relative;
    top: 2px;
    stroke: ${({ active, theme }) => (active ? theme.colours.primary : theme.colours.base)};
  }
`

export const NotificationsDot = styled('span')`
  width: ${({ theme }) => theme.spacers.size8};
  height: ${({ theme }) => theme.spacers.size8};
  border-radius: 50%;
  background: ${({ theme }) => theme.colours.danger};
  position: absolute;
  right: 6px;
  top: 10px;
`

export const NotificationsTray = styled(Box)`
  position: absolute;
  top: calc(100% + 1px);
  right: 0px;
  padding: 0;
  background: #fff;
  box-shadow: ${({ theme }) => theme.clientBoxShadow};
  min-width: ${({ theme }) => theme.wrappers.medium};
  text-align: left;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`

export const NotificationsTrayHeader = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.spacers.size16};
  border-bottom: 1px solid ${({ theme }) => theme.colours.new.grey3};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacers.size4};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const NotificationsTrayBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
`

export const NotificationsEmptyStateIconBackground = styled(Box)`
  display: flex;
  width: ${({ theme }) => theme.spacers.size64};
  height: ${({ theme }) => theme.spacers.size64};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colours.primaryPaleColor};
  margin-bottom: ${({ theme }) => theme.spacers.size8};

  svg {
    stroke: ${({ theme }) => theme.colours.primary};
  }
`

export const NotificationsEmptyStateHeading = styled('h4')`
  color: ${({ theme }) => theme.colours.primary};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: ${({ theme }) => theme.spacers.size8};
`

export const NotificationItemStyles = styled(Link)<{ background?: string }>`
  padding: ${({ theme }) => theme.spacers.size16};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacers.size16};
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.base};

  &:hover {
    background: ${({ theme }) => theme.colours.primary10};
    text-decoration: none;
  }
`

export const NotificationDescription = styled(Box)`
  display: flex;
  flex-direction: column;
  p > span {
    color: ${({ theme }) => theme.colours.primary};
  }
  .${greyTextClasses.root} {
    font-size: 12px;
  }
`

export const NotificationGroupTitle = styled(H5 as any)`
  font-size: 12px;
  display: block;
  padding: ${({ theme }) => theme.spacers.size8} ${({ theme }) => theme.spacers.size16}
    ${({ theme }) => theme.spacers.size4} ${({ theme }) => theme.spacers.size16};
  color: ${({ theme }) => theme.colours.baseMid};
  width: 100%;
  display: block;
`

export const NotificationsClearButton = styled(Button)`
  font-size: 14px;
  color: ${({ theme }) => theme.colours.primary};
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`
