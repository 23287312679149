import configs from '@acre/config'
const { API1_URL } = configs
import { AxiosError } from 'axios'

import { NotificationToken } from '../generated/resolvers'
import request from '../requesters/default'

export const fetchAuthToken = async () => {
  try {
    const response = await request.get<NotificationToken>(`/notifications/ws/auth`, {
      baseURL: `${API1_URL}/acre`,
    })

    return response.data
    // common to get glitch server errors here, we want to swallow them as they are not useful the the user
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      console.error(ex.response.data?.error)
    }
    console.error('something went wrong')
  }
}
