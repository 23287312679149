import { styled, Theme } from '@mui/material'

import { Variant } from '../../utils/constants'
import { commonControlStyles } from '../../styles/form-control.styles'

export const StyledInput = styled('input')<{ variant?: Variant }>(
  ({ theme, variant = 'default' }) => `
  ${commonControlStyles(theme)};
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-width: 0;
  height: ${theme.display.inputHeight};
  ${
    (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
    compactStyles(theme)
  };
`,
)

const compactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
  font-size: 14px;
  padding-left: ${theme.spacing(1.5)};
`
