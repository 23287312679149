import React from 'react'

import testHandle from '../../utils/testHandle'
import { FooterProps } from './Footer.types'
import { FooterContent, Hidden, StyledFooter } from './Footer.styles'

const Footer = ({ sideNav, children, pristine = true }: FooterProps) => (
  <StyledFooter sideNav={sideNav}>
    <FooterContent>{children}</FooterContent>
    {/* To prevent a whole bunch of tests from breaking, it's necessary to include this hidden span*/}
    {!pristine && <Hidden data-testid={testHandle('UnsavedChanges')} />}
  </StyledFooter>
)

export default Footer
