import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import { Maybe, PropertyInput, PropertyVersion } from '../generated/resolvers'
import request from '../requesters/default'
import oathKeeper from '../requesters/oathKeeper'
import {
  CdmCreatePropertyResponse,
  CdmGetPropertyRequest,
  CdmGetPropertyResponse,
  CdmGetPropertyVersionResponse,
  CdmUpdatePropertyResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { formatProperty } from '../utils/schemaMapping/property'

const fetchPropertyBatchFn = async (ids: UUID[]) => {
  const response = await request.get<CdmGetPropertyResponse>('/property', {
    params: {
      property_ids: ids,
      property_details: true,
      show_protected: true,
    },
  })

  const properties = response.data.properties

  return properties?.map(formatProperty) || null
}

export const PropertyLoader = new DataLoader(async (ids: string[]) => {
  const properties = await fetchPropertyBatchFn(ids)

  if (!properties) {
    return ids.map(
      (id) =>
        new GraphQLError(`Property not found for id: ${id}`, {
          extensions: {
            code: 'PROPERTY_NOT_FOUND',
            status: 404,
            id,
          },
        }),
    )
  }

  return ids.map(
    (id) =>
      properties.find((property) => property.id === id) ||
      new GraphQLError(`Property not found for id: ${id}`, {
        extensions: {
          code: 'PROPERTY_NOT_FOUND',
          status: 404,
          id,
        },
      }),
  )
})

// clients have properties that are already owned, and target properties, ie to purchase
// filter_registered_owner_client_id
// filter_target_or_registered_owner_client_id
// passing fetchAllPropertiesForClient will fetch all properties for a client, not just the ones they own
export const fetchClientProperties = async (
  clientId: UUID,
  queryParams?: CdmGetPropertyRequest,
  fetchAllPropertiesForClient?: boolean,
) => {
  const baseParams: CdmGetPropertyRequest = {
    ...queryParams,
    property_details: true,
  }

  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetPropertyResponse>('/property', {
    params: fetchAllPropertiesForClient
      ? { ...baseParams, filter_target_or_registered_owner_client_id: clientId }
      : { ...baseParams, filter_registered_owner_client_id: clientId },
  })

  return response.data.properties ? response.data.properties.map(formatProperty) : null
}

export const ClientPropertyLoader = new DataLoader(async (ids: string[]) =>
  Promise.all(ids.map((id) => fetchClientProperties(id))),
)

export const fetchProperty = async (id: string, version?: Maybe<number>) => {
  if (version) {
    PropertyLoader.clear(id)
    const result = await fetchPropertyByVersion({ property_id: id, version })

    if (!result) {
      throw new GraphQLError(`Property not found for id: ${id}`, {
        extensions: {
          code: 'PROPERTY_NOT_FOUND',
          status: 404,
          id,
        },
      })
    }

    return result as PropertyVersion
  }

  const result = await PropertyLoader.load(id)

  if (result instanceof GraphQLError) {
    throw result
  }

  return result
}

export const fetchProperties = async () => {
  const response = await request.get<CdmGetPropertyResponse>('/property', {
    params: {
      property_details: true,
      show_protected: true,
    },
  })

  if (!response.data.properties) {
    return null
  }

  return response.data.properties.map(formatProperty)
}

export const addProperty = async (input: PropertyInput) => {
  const response = await request.post<CdmCreatePropertyResponse>(`/property`, {
    property: {
      details: input,
    },
  })

  const { property } = response.data

  if (!property) {
    return null
  }

  if (property.details?.registered_owners_details) {
    property.details.registered_owners_details.map(async (clientId) => {
      const alreadyLoadedProperties = await ClientPropertyLoader.load(clientId)
      const allProperties =
        alreadyLoadedProperties &&
        !alreadyLoadedProperties.find((_property) => property.property_id === _property.property_id)
          ? [...alreadyLoadedProperties, formatProperty(property)]
          : [formatProperty(property)]
      ClientPropertyLoader.clear(clientId).prime(clientId, allProperties)
    })
  }

  if (property.property_id) {
    PropertyLoader.clear(property.property_id).prime(property.property_id, formatProperty(property))
  }

  return formatProperty(property)
}

export const updateProperty = async (input: PropertyInput, id: string) => {
  const response = await request.patch<CdmUpdatePropertyResponse>(`/property/${id}`, {
    details: input,
  })

  if (!response.data.property) {
    return null
  }

  PropertyLoader.clear(id).prime(id, formatProperty(response.data.property))
  if (input.registered_owners_details) {
    input.registered_owners_details.map(async (clientId) => {
      const existingProperties = await ClientPropertyLoader.load(clientId)
      ClientPropertyLoader.clear(clientId).prime(clientId, existingProperties)
    })
  } else {
    ClientPropertyLoader.clearAll()
  }

  return formatProperty(response.data.property)
}

// GetProperty
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetProperty
export const getProperty = async (params: CdmGetPropertyRequest = {}) => {
  params.property_details = params.property_details ?? true

  const response = await request.get<CdmGetPropertyResponse>('/property', { params })

  return response?.data
}

export const fetchPropertyByVersion = async (params: { property_id: string; version: number }) => {
  const response = await request.get<CdmGetPropertyVersionResponse>(
    `/property/${params.property_id}/version/${params.version}`,
  )

  return response.data.property ? formatProperty(response.data.property) : null
}

export const fetchPropertiesForClients = async ({
  clientIds,
  excludeIds,
  queryParams,
  fetchAllPropertiesForClient,
}: {
  clientIds: string[]
  excludeIds?: string[]
  queryParams?: CdmGetPropertyRequest
  fetchAllPropertiesForClient?: boolean
}) => {
  const dataLoader = new DataLoader(async (ids: string[]) => {
    const filteredIds = excludeIds?.length ? ids.filter((id) => !excludeIds.includes(id)) : ids

    const promises = filteredIds.map((id) => fetchClientProperties(id, queryParams, fetchAllPropertiesForClient))

    return await Promise.all(promises)
  })

  const properties = await dataLoader.loadMany(clientIds)

  return properties.flat().filter(Boolean) || []
}
