import React, { ReactNode, useCallback, useState } from 'react'
import { Location } from 'history'
import { useForm } from 'react-final-form'

import ConfirmationModal from '../ConfirmationModal'
import { FooterButtonType } from '../ModalFooter'
import useNavigationPrompt, { UseNavigationPromptCallback } from './useNavigationPrompt'

export type ConfirmationNavigationModalProps = {
  children: ({ displayMessage }: { displayMessage: string }) => ReactNode
  message: (location: Location) => string
}

const ConfirmationNavigationModal = ({ children, message }: ConfirmationNavigationModalProps) => {
  const [displayMessage, setDisplayMessage] = useState<string>('')
  const form = useForm()

  const footer = useCallback(
    (
      onConfirm: () => void,
      onCancel: () => void,
    ): { primaryButton: FooterButtonType; secondaryButton: FooterButtonType } => {
      return {
        primaryButton: {
          onClick: () => {
            form.reset()
            onConfirm()
          },
          id: 'ConfirmNavigationModal',
        },
        secondaryButton: {
          onClick: onCancel,
          id: 'CancelNavigationModal',
          isDisabled: false,
        },
      }
    },
    [form],
  )

  const shouldPrompt = useCallback<UseNavigationPromptCallback>(
    ({ nextLocation }) => {
      let result = ''

      if (location) {
        result = message(nextLocation) || ''
        setDisplayMessage(result)
      }

      return result
    },
    [message],
  )

  const { message: navigationPromptMessage, onConfirm, onCancel } = useNavigationPrompt(shouldPrompt)

  return (
    <ConfirmationModal
      isVisible={Boolean(navigationPromptMessage)}
      footer={footer(onConfirm, onCancel)}
      handleClose={onCancel}
    >
      <>{children({ displayMessage })}</>
    </ConfirmationModal>
  )
}

export default ConfirmationNavigationModal
