import { styled } from '@mui/material'

const checkboxSize = '24px'

export const StyledCheckboxWrapper = styled('label')(
  ({ theme }) => `
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;

  &.disabled {
    cursor: not-allowed;
    &:hover {
      /* We want to style the checkbox when the label is hovered */
      & > .custom-checkbox {
        border-color: ${theme.colours.baseLight};
      }
    }
  }

  &:hover {
    /* We want to style the checkbox when the label is hovered */
    & > .custom-checkbox {
      border-color: ${theme.colours.primary};
    }
  }
`,
)

export const CustomCheckbox = styled('span')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  min-width: ${checkboxSize};
  width: ${checkboxSize};
  height: ${checkboxSize};
  margin: 0;
  padding: 0;

  border-style: solid;
  border-width: 1px;
  border-color: ${theme.colours.baseLight};
  border-radius: ${theme.display.borderRadius};
  background-color: ${theme.colours.foreground};
  transition: all 0.1s ease-out;

  &:hover {
    border-color: ${theme.colours.primary};
  }

  &.disabled {
    cursor: not-allowed;

    background-color: ${theme.colours.baseExtraLight};
    svg path {
      fill: ${theme.colours.baseExtraLight};
      color: ${theme.colours.baseExtraLight};
      stroke: ${theme.colours.baseExtraLight};
    }
  }

  &.checked {
    border-color: ${theme.colours.primary};
    background-color: ${theme.colours.primary};
    svg path {
      fill: ${theme.colours.foreground};
      color: ${theme.colours.foreground};
      stroke: ${theme.colours.foreground};
    }
  }

  &.checked.disabled {
    background-color: ${theme.colours.primaryColorDisabled};
    border-color: ${theme.colours.primaryColorDisabled};
  }
`,
)

export const StyledCheckboxInput = styled('div')`
  flex: 1;
`

export const StyledInput = styled('input')`
  min-width: ${checkboxSize};
  width: ${checkboxSize};
  height: ${checkboxSize};
  margin: 0;
  padding: 0;
  appearance: none;
  cursor: pointer;
  outline: none;
`

export const RoundCheckbox = styled(CustomCheckbox)`
  border-radius: 16px;
`
