import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { Feature, FlagType, useFlag, useUserContext } from '@acre/utils'
import { GateName } from '@acre/graphql'
import { HoverPopOver, NavLinkMenuWrapper, StyledDashboardsNavButton } from '@acre/design-system'

import DashboardsNavPopoverItem from './DashboardsNavPopoverItem'

type Props = {
  gates: GateName[]
}

const DashboardsNavPopover = ({ gates }: Props) => {
  const { pathname } = useLocation()
  const user = useUserContext()

  const complianceDashboardNavLinksFlag = useFlag([FlagType.Feature, Feature.ComplianceDashboardNavLinks], false)
  const dashboardsFlag = useFlag([FlagType.Feature, Feature.Dashboards], false)
  const networkFlag = useFlag([FlagType.Feature, Feature.Network], false)

  // Hard coded the styling,
  // if any condition changes in the future need to double check the styling.
  const shouldRenderSingleColumn = !complianceDashboardNavLinksFlag || !gates.includes(GateName.VIEW_MI_DASHBOARD)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  if (!dashboardsFlag) return

  return (
    <>
      <StyledDashboardsNavButton
        data-testid="e2e-DashboardsButton"
        onMouseEnter={handleOpen}
        onSelect={handleClose}
        active={pathname.includes('/dashboard/')}
        href="#"
        open={!!anchorEl}
      >
        <FormattedMessage id="nav.dashboards" />
      </StyledDashboardsNavButton>
      <HoverPopOver
        interactive
        id="dashboardsPopover"
        onClose={handleClose}
        anchorEl={anchorEl}
        placement="bottom-start"
        offsetY={4}
      >
        <NavLinkMenuWrapper shouldRenderSingleColumn={shouldRenderSingleColumn}>
          <DashboardsNavPopoverItem
            to={`/dashboard/performance/user/${user?.id}`}
            id="PerformanceUser"
            pathname={pathname}
            iconColour="violet"
            onClick={handleClose}
          />
          {complianceDashboardNavLinksFlag && (
            <DashboardsNavPopoverItem
              to={`/dashboard/compliance/user/${user?.id}`}
              id="ComplianceUser"
              pathname={pathname}
              iconColour="violet"
              onClick={handleClose}
            />
          )}
          {gates.includes(GateName.VIEW_MI_DASHBOARD) && (
            <DashboardsNavPopoverItem
              to={`/dashboard/performance/company/${user?.organisation_id}`}
              id="PerformanceCompany"
              pathname={pathname}
              iconColour="orange"
              onClick={handleClose}
            />
          )}
          {gates.includes(GateName.VIEW_MI_DASHBOARD) && complianceDashboardNavLinksFlag && (
            <DashboardsNavPopoverItem
              to={`/dashboard/compliance/company/${user?.organisation_id}`}
              id="ComplianceCompany"
              pathname={pathname}
              iconColour="orange"
              onClick={handleClose}
            />
          )}
          {gates.includes(GateName.VIEW_MI_DASHBOARD) && networkFlag && (
            <DashboardsNavPopoverItem
              to={`/dashboard/performance/network/${user?.organisation?.representative_of}`}
              id="PerformanceNetwork"
              pathname={pathname}
              iconColour="turquoise"
              onClick={handleClose}
            />
          )}
          {gates.includes(GateName.VIEW_MI_DASHBOARD) && complianceDashboardNavLinksFlag && networkFlag && (
            <DashboardsNavPopoverItem
              to={`/dashboard/compliance/network/${user?.organisation?.representative_of}`}
              id="ComplianceNetwork"
              pathname={pathname}
              iconColour="turquoise"
              onClick={handleClose}
            />
          )}
        </NavLinkMenuWrapper>
      </HoverPopOver>
    </>
  )
}

export default DashboardsNavPopover
