import React from 'react'

import { LazyReact } from '@acre/utils'

const CasePropertyNav = LazyReact(() => import('./CasePropertyNav'))
const CaseSourcingNav = LazyReact(() => import('./CaseSourcingNav'))
const ClientPropertyNav = LazyReact(() => import('./ClientPropertyNav'))
const ComplianceCaseDetailsNav = LazyReact(() => import('./ComplianceCaseDetailsNav'))
const GeneralInsuranceCompareQuotesNav = LazyReact(() => import('./GeneralInsuranceCompareQuotesNav'))
import { getPageType, MainNavProps, PageType } from './Nav.helpers'

const MainNavContent = ({ pathname }: MainNavProps) => {
  switch (getPageType(pathname)) {
    case PageType.CaseSourcing:
      return <CaseSourcingNav pathname={pathname} />

    case PageType.ClientProperty:
      return <ClientPropertyNav pathname={pathname} />

    case PageType.CaseProperty:
      return <CasePropertyNav pathname={pathname} />

    case PageType.GeneralInsuranceCompareQuotes:
      return <GeneralInsuranceCompareQuotesNav pathname={pathname} />
    case PageType.ComplianceCaseDetails:
      return <ComplianceCaseDetailsNav pathname={pathname} />
  }
  return null
}

export default MainNavContent
