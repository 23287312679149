import { styled } from '@mui/material'

export const InfoTable = styled('table')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  width: '100%',
  borderCollapse: 'collapse',
  tr: {
    borderTop: 'none',
    '&:nth-of-type(odd)': {
      td: {
        backgroundColor: theme.colours.new.grey1,
        '&:first-of-type': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },
        '&:last-of-type': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
    },
    td: {
      padding: `${theme.spacing(1 / 4)} ${theme.spacing(1 / 2)}`,
      textWrap: 'pretty',
      whiteSpace: 'pre-wrap',
    },
  },
}))
