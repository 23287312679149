import { styled, Theme } from '@mui/material'
import { Styles } from 'react-select/src/styles'

import { Variant } from '../../utils/constants'

export const SingleSearchSelectWrapper = styled('div')(
  ({ theme }) => `
  &.error {
    .react-select__control {
      border-color: ${theme.colours.danger};
    }
  }
`,
)

export const selectStyles: (theme: Theme, variant?: Variant, highlightSelected?: boolean) => Partial<{}> = (
  theme,
  variant,
  highlightSelected,
) => {
  return {
    input: (styles: Styles<any, any>) => ({
      ...styles,
      padding: 0,
      margin: 0,
    }),
    singleValue: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
      ...styles,
      color: isDisabled ? theme.colours.baseMid : theme.colours.base,
    }),
    container: (styles: Styles<any, any>) => ({
      ...styles,
      borderWidth: theme.display.inputBorderWidth,
      color: theme.colours.base,
      outlineWidth: theme.display.inputBorderWidth,
      '&:hover': {
        borderColor: theme.colours.primary,
      },
      '&:focus': {
        borderColor: theme.colours.primary,
        outlineColor: theme.colours.primary,
      },
      fontFamily: theme.typography.fontFamily,
    }),
    control: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
      ...styles,
      ...(isDisabled
        ? {
            backgroundColor: theme.colours.baseExtraLight,
            color: theme.colours.baseMid,
          }
        : {}),
      minHeight: theme.spacing(6),
      padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: theme.display.borderRadius,
      boxShadow: 'none',
      borderColor: theme.colours.baseLight,
      borderWidth: theme.display.inputBorderWidth,
      outlineWidth: theme.display.inputBorderWidth,
      '&:hover': {
        cursor: 'text',
        borderColor: theme.colours.baseLight,
      },
      '&:focus-within': {
        cursor: 'text',
        borderColor: theme.colours.primary,
        outlineColor: theme.colours.primary,
      },

      ...((variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
        compactStyles(theme)),
    }),
    valueContainer: (styles: Styles<any, any>) => ({
      ...styles,
      padding: 0,
    }),
    dropdownIndicator: (styles: Styles<any, any>) => ({
      ...styles,
      padding: 0,
      paddingLeft: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '& > svg': {
        transform:
          (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
          'scale(calc(2/3))',
      },
    }),
    indicatorsContainer: (styles: Styles<any, any>) => ({
      ...styles,
      border: 'none',
      borderColor: theme.colours.baseLight,
      paddingRight: theme.spacing(1),
    }),
    indicatorSeparator: () => ({
      border: 'none',
    }),
    clearIndicator: () => ({
      color: theme.colours.baseMid,
      cursor: 'pointer',
    }),
    menu: (styles: Styles<any, any>) => ({
      ...styles,
      overflow: 'auto',
      borderRadius: theme.display.borderRadius,
      zIndex: theme.zIndex.modal,
      boxShadow: theme.boxShadow,
    }),
    menuPortal: (styles: Styles<any, any>) => ({
      ...styles,
      zIndex: theme.zIndex.modal,
    }),
    menuList: () => ({
      padding: '0',
      borderRadius: '0',
      maxHeight: 300,
      overflow: 'auto',
    }),
    option: (
      styles: Styles<any, any>,
      { isFocused, isSelected, isDisabled }: { isFocused: boolean; isSelected: boolean; isDisabled: boolean },
    ) => {
      const shared = {
        backgroundColor: theme.colours.primary10,
        color: theme.colours.primary,
        cursor: 'pointer',
      }

      const disabled = {
        backgroundColor: 'transparent',
        color: theme.colours.baseMid,
      }

      const selected = highlightSelected
        ? {
            backgroundColor: theme.colours.primary,
            color: '#fff',
          }
        : {
            backgroundColor: 'transparent',
            color: theme.colours.base,
          }

      const compact = {
        fontSize: 14,
      }

      return {
        ...styles,
        ...(isFocused ? shared : {}),
        ...(isSelected ? selected : {}),
        ...(isDisabled ? disabled : {}),
        ...((variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') && compact),
        padding:
          variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned'
            ? theme.spacing(1)
            : theme.spacing(1.5),
        '&:hover': isDisabled ? disabled : shared,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }
    },
    placeholder: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
      ...styles,
      margin: 0,
      padding: 0,
      ...(isDisabled
        ? {
            color: theme.colours.baseMid,
          }
        : {}),
    }),
  }
}

const compactStyles = (theme: Theme) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4),
  fontSize: 14,
  padding: 0,
  paddingLeft: theme.spacing(1.5),
})
