import isEmpty from 'lodash/isEmpty'

import { ExactObject } from '../types'

/**
 * return true if object is empty or all nested object values are falsy
 * @param obj
 */
export const isObjectEmpty = (obj: ExactObject): boolean => {
  for (let key in obj) {
    if (typeof obj[key] == 'object' && obj[key] !== null) {
      if (!isObjectEmpty(obj[key])) {
        return false
      }
    } else {
      if (!isEmpty(obj[key]?.toString())) {
        return false
      }
    }
  }
  return true
}
