import React, { useCallback, useState } from 'react'

import { Maybe, ProtectionProduct } from '@acre/graphql'

import ProtectionDetailsModal from './ProtectionDetailsModal'

type Props = {
  isModalOpen: boolean
  onSubmit: () => void
  onClose: () => void
  products?: Maybe<ProtectionProduct[]>
}
const ConfirmProtectionDetailsFlow = ({ isModalOpen, onSubmit, onClose, products }: Props) => {
  const [currentProductIndex, setCurrentProductIndex] = useState(0)
  const numberOfProducts = products?.length || 0

  const handleClose = useCallback(() => {
    setCurrentProductIndex(0)
    onClose()
  }, [onClose])

  const increaseProductIndex = useCallback(() => {
    setCurrentProductIndex((c) => c + 1)
  }, [])

  return (
    <>
      {numberOfProducts > 0
        ? products!.map((product, idx) => {
            if (idx !== currentProductIndex || !isModalOpen) {
              return null
            }

            // if it's the last product we don't want to increment currentProductIndex
            // but instead call the onSubmit handler
            const isLastProduct = idx + 1 === numberOfProducts
            const handleSubmit = isLastProduct ? onSubmit : increaseProductIndex

            return (
              <ProtectionDetailsModal
                key={product.protection_id || idx}
                product={product}
                onSubmit={handleSubmit}
                isModalOpen={isModalOpen}
                onClose={handleClose}
                stage={`${idx + 1}/${numberOfProducts}`}
              />
            )
          })
        : null}
    </>
  )
}

export default ConfirmProtectionDetailsFlow
