import { applyForDip, canDoDip, getDipCert, getDipStatus, submitDip } from '../api'
import { Resolvers } from '../generated/resolvers'

const resolvers: Resolvers = {
  Mutation: {
    submitDip: async (_parent, { payloadInput, queryInput }) => await submitDip(payloadInput, queryInput),
    getDipCert: async (_parent, { id, mortgageId, case: _case }) => await getDipCert(id, mortgageId, _case),
  },
  Query: {
    canDoDip: async (_parent, { input }) => await canDoDip(input),
    getDipStatus: async (_parent, { input }) => await getDipStatus(input),
    applyForDip: async (_parent, { id, mortgageId, reservation }) => await applyForDip(id, mortgageId, reservation),
  },
}

export default resolvers
