import React from 'react'
import { FieldValues,Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { NumberField, NumberFieldProps } from '@acre/design-system'
import { FieldBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'

export interface NumberFieldRHFProps<T extends FieldValues = FieldValues> extends FieldBaseProps, NumberFieldProps {
  name: Path<T>
}

function _NumberFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: NumberFieldRHFProps<T>,
) {
  const { name, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()

  const {
    field,
    fieldState: { error: fieldError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { label, error, helperText } = getCommonTranslatedProps(props, { error: fieldError, parseText })

  return (
    <NumberField
      {...other}
      onChange={field.onChange}
      value={field.value}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
    />
  )
}

const NumberFieldRHF = React.memo(_NumberFieldRHF) as typeof _NumberFieldRHF

export default NumberFieldRHF
