import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import { Button } from '@acre/design-system'

export const Title = styled('span')`
  display: inline-block;
  font-size: 16px;
  line-height: 1.6;
  svg {
    vertical-align: middle;
    margin-right: ${({ theme }) => theme.spacers.size8};
    margin-top: -2px;
  }
  margin: 0 ${({ theme }) => theme.spacers.size16};
`

export const GreyLink = styled(Link)`
  color: ${({ theme }) => theme.colours.baseMid};
  transition: color 0.1s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colours.primary};

    path {
      stroke: ${({ theme }) => theme.colours.primary};
    }
  }
`

export const ColouredLink = styled(Link)<{ color?: Theme['colours'] }>`
  color: ${({ color, theme }) => (color ? `${theme.colours}.${color}` : theme.colours.base)};
  transition: color 0.1s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colours.primary};

    path {
      fill: ${({ theme }) => theme.colours.primary};
    }
  }
`

export const ColouredButton = styled(Button)<{ color?: Theme['colours'] }>`
  color: ${({ color, theme }) => (color ? `${theme.colours}.${color}` : theme.colours.base)};
  border: 0;

  & * > svg > path,
  & > svg > path {
    fill: ${({ color, theme }) => (color ? `${theme.colours}.${color}` : theme.colours.base)};
    stroke: ${({ color, theme }) => (color ? `${theme.colours}.${color}` : theme.colours.base)};
  }

  &:hover {
    color: ${({ theme }) => theme.colours.primary};

    path {
      fill: ${({ theme }) => theme.colours.primary};
      stroke: ${({ theme }) => theme.colours.primary};
    }
  }
`
