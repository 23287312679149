import React from 'react'
import { ExtraErrorData } from '@sentry/integrations'
import { init, setTag } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom/client'
import { pdfjs } from 'react-pdf'

// Set the workerSrc manually because react-pdf no direct support for vite.
//
// File is copied during dev & build via vite-static-copy-plugin [see apps/broker-crm/vite.config.ts].
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

import { LutherException } from '@acre/graphql'
import '@acre/design-system/mui-x-license'
import acreConfig from '@acre/config'

import App from './App'
import AppProviders from './AppProviders'

if (import.meta.env.MODE === 'production') {
  init({
    dsn: 'https://ee7d1ac2845d4d9a998f53da3575f68a@sentry.io/1876005',
    environment: acreConfig.SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing(), new ExtraErrorData({ depth: 5 })],
    tracesSampleRate: 0,
    beforeSend(event, hint) {
      // This ensures that sentry is grouping our events as we want them, rather than putting all failing API calls together
      const error = hint?.originalException as Error

      if (error && typeof error !== 'string' && error.name === 'LutherException') {
        const err = error as LutherException

        event.fingerprint = [err.message]
      }

      return event
    },
  })

  setTag('issue_type', 'frontend')
  setTag('app', 'broker-crm')
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
)
