import React, { MouseEvent } from 'react'
import { useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { OptionProps } from 'react-select'

import { SearchResultItem } from '@acre/graphql'

import { HELPER_TYPE_ERROR } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import HelperText from '../HelperText'
import Label from '../Label'
import AsyncSearchCommon from './AsyncSearch'
import { NoOptionsMessage, ValueContainer } from './SearchComponents'
import { GlobalSearchWrapper, globalSelectStyles } from './AsyncSearch.styles'

export type GlobalSearchProps = {
  id: string
  onClick: (value: SearchResultItem) => any
  executeSearch: (inputValue: string) => Promise<SearchResultItem[]>
  onCloseModal: (e: MouseEvent) => void
  optionComponent?: (props: OptionProps<any, any>) => JSX.Element | null
  label?: string
  error?: string
  placeholder?: string
  menuIsOpen?: boolean
}

const GlobalSearch = ({
  id,
  onClick,
  executeSearch,
  onCloseModal,
  optionComponent,
  menuIsOpen,
  label,
  error = '',
  placeholder,
}: GlobalSearchProps) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const message = formatMessage({ id: 'nav.search.noResults' })
  const handleDropdownIndicatorClick = (e: MouseEvent) => {
    onCloseModal(e)
  }

  return (
    <GlobalSearchWrapper data-testid={testHandle(id)}>
      {label ? <Label htmlFor={id} isDisabled={false} text={label} /> : null}
      <AsyncSearchCommon
        error={error}
        onClick={onClick}
        customStyles={globalSelectStyles}
        placeholder={placeholder}
        executeSearch={executeSearch}
        theme={theme}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={document.body}
        noOptionsMessage={message}
        handleDropdownIndicatorClick={handleDropdownIndicatorClick}
        components={{
          ...(optionComponent ? { Option: optionComponent } : {}),
          ValueContainer,
          NoOptionsMessage,
        }}
      />
      {!!error && <HelperText id={id} message={error || ''} textType={HELPER_TYPE_ERROR} />}
    </GlobalSearchWrapper>
  )
}

export default GlobalSearch
