import { isEqual, isObject, transform } from 'lodash'

import { ExactObject } from '../types'

export const differenceInObjects = (object: ExactObject, base: ExactObject) => {
  const changes = (object: ExactObject, base: ExactObject) => {
    return transform<ExactObject, ExactObject>(object, (result, value, key) => {
      if (!isEqual(value, base?.[key])) {
        // If this is an array, then return the updated version of that array instead of the difference in array items
        if (Array.isArray(value) && Array.isArray(base?.[key])) {
          result[key] = value
        } else {
          result[key] = isObject(value) && isObject(base?.[key]) ? changes(value, base?.[key]) : value
        }
      }
    })
  }
  return changes(object, base)
}
