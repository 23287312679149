export const getMissingDirtyFields = (
  dirtyFields: { [key: string]: boolean },
  // index: 0 | 1 | undefined | null,
  index: number | undefined | null,
  initialMissingFields: string[],
) => {
  let localDirtyFields: { [key: string]: boolean } = {}

  // For single view (1 or 3+ clients) theres no index, but for 2 clients
  // Dual fact find handles having both clients in the form by adding a prefix
  // to their state key, this logic cleans that into the relevant client fields
  if (index === 0 || index === 1) {
    for (let field in dirtyFields) {
      // In react-final-form state, keys are e.g. clients[0].first_name
      const key = `clients[${index}].`

      if (field !== 'clients' && field.startsWith(key)) {
        // Remove the prefix on the key
        localDirtyFields[`${field.replace(key, '')}`] = dirtyFields[field]
      }
    }
  } else {
    localDirtyFields = dirtyFields
  }

  const newMissingFields = initialMissingFields.reduce((acc: string[], field: string) => {
    if (!localDirtyFields[field]) {
      acc.push(field)
    }

    return acc
  }, [])

  return newMissingFields
}
