import { useMutation } from '@apollo/client'

import { updateCachedItem } from '@acre/utils'
import {
  GetCaseDocument,
  GetCaseQuery,
  GetCaseVerificationsDocument,
  GetProtectionProductsDocument,
  Maybe,
  UpdateCaseStatusDocument,
  UpdateCaseStatusMutation,
} from '@acre/graphql'

export const useUpdateCaseStatus = (caseId: string, onCompleted?: () => any, onError?: () => any) => {
  const mutation = useMutation<UpdateCaseStatusMutation>(UpdateCaseStatusDocument, {
    update: (cache, { data }) => {
      if (data?.updateCaseStatus) {
        const cachedCase: Maybe<GetCaseQuery> = cache.readQuery({
          query: GetCaseDocument,
          variables: { id: caseId },
        })

        if (cachedCase?.case) {
          updateCachedItem(cache, {
            query: GetCaseDocument,
            variables: { id: caseId },
            data: {
              ...cachedCase.case,
              details: {
                ...cachedCase.case.details,
                status: data.updateCaseStatus.case?.details.status,
                miDocuments: {
                  ...cachedCase.case.details.miDocuments,
                  documents: [
                    ...(cachedCase.case.details.miDocuments?.documents || []),
                    ...(data.updateCaseStatus.documents || []), // Add suitability report documents
                  ],
                },
                transition_mortgage_versions: data.updateCaseStatus.case?.details.transition_mortgage_versions,
              },
            },
            key: 'case',
          })
        }
      }
    },
    onCompleted,
    onError,
    refetchQueries: [
      { query: GetCaseVerificationsDocument, variables: { id: caseId } },
      {
        query: GetProtectionProductsDocument,
        variables: { protectionProductDetails: true, filterCaseIds: [caseId] },
      },
    ],
    awaitRefetchQueries: true,
  })

  return mutation
}
