import React, { MouseEvent as ReactMouseEvent, ReactElement, ReactNode, useMemo } from 'react'
import AdditionalDetailsButton from '@broker-crm-common/AdditionalDetailsButton/AdditionalDetailsButton'
import { CardIcon } from '@broker-crm-utils'
import { Box } from '@mui/material'

import { Card, FieldListItem, GreyText, ItemCardData, theme } from '@acre/design-system'

import icons from './ItemCardIcons'
import { CardHeader, CardTitle, Title } from './ItemCard.styles'

type Props = {
  title: string | ReactElement
  data?: ItemCardData[]
  id?: string
  cardIcon?: CardIcon
  error?: string
  cardOptions?: ReactElement
  verbalDisclosure?: ReactElement
  children?: ReactNode
  fullHeight?: boolean
  defaultValue?: string
  border?: boolean
  shadow?: boolean
  isIncomplete?: boolean
  showAdditionalDetails?: boolean
  greyKeyText?: boolean
  disabled?: boolean
  setShowAdditionalDetails?: (arg: boolean) => void
  shouldRenderAdditionalDetailsButton?: boolean
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ItemCard = ({
  id,
  title,
  data = [],
  cardIcon,
  cardOptions,
  verbalDisclosure,
  children,
  fullHeight,
  border = false,
  shadow = true,
  defaultValue,
  isIncomplete = false,
  greyKeyText = false,
  disabled = false,
  showAdditionalDetails,
  setShowAdditionalDetails,
  shouldRenderAdditionalDetailsButton = false,
  onClick,
}: Props) => {
  const Icon = (cardIcon && icons[cardIcon]) || null

  const alignOptions = useMemo(
    () => (!cardIcon && Boolean(cardOptions)) || Boolean(verbalDisclosure),
    [cardIcon, cardOptions, verbalDisclosure],
  )

  const CardTitleElement = () => {
    const cardTitle = typeof title === 'string' ? <CardTitle data-pii>{title}</CardTitle> : title
    return (
      <Title className={alignOptions ? 'align-options' : undefined}>
        {cardTitle}
        {cardOptions && alignOptions ? cardOptions : null}
        {verbalDisclosure}
      </Title>
    )
  }

  return (
    <Card
      id={id}
      border={border}
      shadow={shadow}
      fullHeight={fullHeight}
      isIncomplete={isIncomplete}
      clickable={!!onClick}
      onClick={onClick}
    >
      <CardHeader>
        {Icon}
        {cardOptions && !alignOptions ? cardOptions : null}
      </CardHeader>
      <CardTitleElement />

      {data.map(({ id, label, value, leftWidth }, index) => {
        value = value || <GreyText fontSize={14}>{defaultValue}</GreyText>

        return (
          <FieldListItem
            key={index}
            id={id}
            value={value}
            label={label}
            defaultValue={defaultValue}
            leftWidth={leftWidth}
            smallFont
            greyKeyText={greyKeyText}
          />
        )
      })}
      {children}
      {setShowAdditionalDetails && shouldRenderAdditionalDetailsButton && (
        <Box display="flex" justifyContent="center" borderTop={`1px solid ${theme.colours.baseLight}`}>
          <AdditionalDetailsButton
            showAdditionalDetails={showAdditionalDetails}
            onClick={() => setShowAdditionalDetails(!showAdditionalDetails)}
            disabled={disabled}
          />
        </Box>
      )}
    </Card>
  )
}

export default ItemCard
