import React, { useCallback, useMemo, useRef } from 'react'
import { MenuItemProps, Select, SelectProps, unstable_composeClasses } from '@mui/material'
import type { SelectInputProps } from '@mui/material/Select/SelectInput'
import classnames from 'classnames'

import type { ExtendField, FieldProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InputBase from '../InputBase/InputBase'
import { getSelectFieldUtilityClass } from './selectFieldClasses'

export interface SelectFieldProps extends FieldProps, ExtendField<SelectProps> {}

const useUtilityClasses = (ownerState: Partial<SelectFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    select: ['select'],
    helperText: ['helperText'],
    errorText: ['errorText'],
    list: ['list'],
    option: ['option'],
    placeholder: ['placeholder'],
  }

  return unstable_composeClasses(slots, getSelectFieldUtilityClass, ownerState.classes)
}

const _SelectField = (props: SelectFieldProps) => {
  const {
    className,
    children,
    inputRef,
    inputProps,
    name,
    label,
    error,
    helperText,
    WrapperProps,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    placeholder = 'Select an Item',
    layout = 'row',
    size = 'medium',
    value,
    onChange,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ ...props, layout, size })

  const fieldRef = useRef<HTMLInputElement | null>((inputRef as React.RefObject<HTMLInputElement>)?.current || null)

  const { ariaId, labelId, fieldAriaProps } = useAriaProps(props)

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  const emptyValue = useRef<[] | string | undefined>(props.multiple ? [] : '')

  const [inputValue, setInputValue] = React.useState(value ?? emptyValue.current)

  const handleChange = useCallback<Required<SelectInputProps>['onChange']>(
    (event, child) => {
      if (Array.isArray(event.target.value) && event.target.value.includes('')) {
        setInputValue(emptyValue.current)
      } else {
        setInputValue((event.target.value as string) || emptyValue.current)
      }

      onChange?.(event, child)
    },
    [onChange],
  )

  const { options, data } = useMemo(() => {
    const data: Record<string, unknown> = {}

    const options = React.Children.map(children as React.FunctionComponentElement<MenuItemProps>[], (child) => {
      if (React.isValidElement(child)) {
        data[child.props.value as string] = child.props.children

        return React.cloneElement<MenuItemProps>(child, {
          className: classnames(slotClasses.option),
        })
      }
    })

    return {
      options,
      data,
    }
  }, [children, slotClasses.option])

  const renderValue = useCallback<Required<SelectProps>['renderValue']>(
    (value) => {
      const isArray = value instanceof Array

      if (isArray ? value.length === 0 : !value) {
        return <span className={slotClasses.placeholder}>{placeholder}</span>
      }

      const mappedValue = isArray
        ? value
            .reduce((acc, curr) => {
              if (curr in data) {
                acc.push(data[curr])
              }

              return acc
            }, [])
            .join(', ')
        : data[value as string]

      return <>{mappedValue}</>
    },
    [data, placeholder, slotClasses.placeholder],
  )

  return (
    <FieldWrapper
      {...WrapperProps}
      name={name}
      label={label}
      layout={layout}
      error={error}
      helperText={helperText}
      size={size}
      className={classnames(slotClasses.root, WrapperProps?.className)}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
    >
      <Select
        {...rootProps}
        SelectDisplayProps={{
          ...fieldAriaProps,
          id: ariaId,
          className: classnames(slotClasses.select, inputProps?.className),
        }}
        className={classnames(slotClasses.field, className)}
        autoComplete="off"
        displayEmpty={!!placeholder}
        error={Boolean(error)}
        inputProps={inputProps}
        input={<InputBase error={Boolean(error)} />}
        inputRef={fieldRef}
        labelId={labelId}
        value={value ?? inputValue}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={{
          ...rootProps.MenuProps,
          MenuListProps: {
            ...rootProps.MenuProps?.MenuListProps,
            className: classnames(slotClasses.list),
          },
        }}
      >
        {options}
      </Select>
    </FieldWrapper>
  )
}

const SelectField = React.memo(_SelectField) as typeof _SelectField

export default SelectField
