import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface DateFieldClasses {
  root: string
  field: string
  label: string
  input: string
  helperText: string
  errorText: string
  displayField: string
}

export type DateFieldClassKey = keyof DateFieldClasses

export function getDateFieldUtilityClass(slot: string) {
  return generateUtilityClass('DateField', slot)
}

const dateFieldClasses: DateFieldClasses = generateUtilityClasses('DateField', [
  'root',
  'field',
  'label',
  'input',
  'helperText',
  'errorText',
  'displayField',
])

export default dateFieldClasses
