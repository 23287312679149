import { DataProxy } from '@apollo/client'
import { DocumentNode } from 'graphql'

import { CachedListUpdateStrategy, updateCachedList } from '@acre/utils'
import {
  GetCaseDocument,
  GetCaseQuery,
  GetClientDocument,
  GetClientQuery,
  Maybe,
  RenderAndStoreDocumentMutation,
  UpdateDocumentMutation,
  UploadDocumentMutation,
} from '@acre/graphql'

export const updateDocumentCache = async (
  cache: DataProxy,
  uploadDocument: UploadDocumentMutation['uploadDocument'],
  caseIds?: string[],
) => {
  const payloadBuilder = (
    query: DocumentNode,
    id: Maybe<string>,
    newItem: UploadDocumentMutation['uploadDocument'],
    strategy: CachedListUpdateStrategy,
    key: string,
  ) => {
    const payload = {
      readQuery: {
        query,
        variables: { id },
      },
      writeQuery: {
        query,
        variables: { id },
      },
      newItem,
      strategy,
      key,
    }

    return payload
  }

  //
  // if document has been uploaded on the case it will contain case_id
  // and case cache needs to be updated
  //
  // if document has been uploaded on the client it will contain owning_client_ids
  // and client cache needs to be updated
  //
  const caseIdsToUpdate = uploadDocument?.case_ids || caseIds
  if (caseIdsToUpdate) {
    return caseIdsToUpdate.forEach(async (caseId) => {
      const cacheUpdatePayload = payloadBuilder(
        GetCaseDocument,
        caseId,
        uploadDocument,
        CachedListUpdateStrategy.Append,
        'case.details.documents',
      )

      await updateCachedList<UploadDocumentMutation['uploadDocument']>(cache, cacheUpdatePayload)
    })
  }
  if (uploadDocument && uploadDocument.owning_client_ids) {
    return uploadDocument.owning_client_ids.forEach(async (clientId) => {
      const cacheUpdatePayload = payloadBuilder(
        GetClientDocument,
        clientId,
        uploadDocument,
        CachedListUpdateStrategy.Append,
        'client.details.documents',
      )

      await updateCachedList<UploadDocumentMutation['uploadDocument']>(cache, cacheUpdatePayload)
    })
  }
}

export const updateDocumentCacheVerbalDisclosure = async (cache: DataProxy, data: RenderAndStoreDocumentMutation) => {
  const { renderAndStoreDocument } = data
  if (renderAndStoreDocument && renderAndStoreDocument.owning_client_ids) {
    renderAndStoreDocument.owning_client_ids.forEach(async (clientId) => {
      const cacheUpdatePayload = {
        readQuery: {
          query: GetClientDocument,
          variables: { id: clientId },
        },
        writeQuery: {
          query: GetClientDocument,
          variables: { id: clientId },
        },
        newItem: renderAndStoreDocument,
        strategy: CachedListUpdateStrategy.Append,
        key: 'client.details.documents',
      }

      await updateCachedList<RenderAndStoreDocumentMutation['renderAndStoreDocument']>(cache, cacheUpdatePayload)
    })
  }

  if (renderAndStoreDocument && renderAndStoreDocument.rendered_case_ids) {
    const caseId = renderAndStoreDocument.rendered_case_ids[0]
    const cacheUpdatePayload = {
      readQuery: {
        query: GetCaseDocument,
        variables: { id: caseId },
      },
      writeQuery: {
        query: GetCaseDocument,
        variables: { id: caseId },
      },
      newItem: renderAndStoreDocument,
      strategy: CachedListUpdateStrategy.Append,
      key: 'case.details.documents',
    }

    await updateCachedList<RenderAndStoreDocumentMutation['renderAndStoreDocument']>(cache, cacheUpdatePayload)
  }
}

export const updateArchivedDocumentForCaseAndClientCache = async (
  cache: DataProxy,
  updateDocumentMutation: UpdateDocumentMutation['updateDocument'],
  caseId: string,
) => {
  const cachedCase: Maybe<GetCaseQuery> = cache.readQuery({
    query: GetCaseDocument,
    variables: {
      id: caseId,
    },
  })
  const cachedCaseMiDocuments = cachedCase?.case?.details?.miDocuments ?? {}
  const cachedCaseDocuments = cachedCase?.case?.details?.miDocuments?.documents ?? []

  // Update Case document cache
  try {
    await cache.writeQuery({
      query: GetCaseDocument,
      variables: {
        id: caseId,
      },
      data: {
        case: {
          ...cachedCase?.case,
          details: {
            ...cachedCase?.case?.details,
            miDocuments: {
              ...cachedCaseMiDocuments,
              // Update case documents cache to remove archived document
              documents: cachedCaseDocuments?.map((doc) => {
                const { document_id } = updateDocumentMutation!
                const isArchivedDocument = doc.document_id === document_id
                return {
                  ...doc,
                  archived: isArchivedDocument ? true : doc.archived,
                }
              }),
            },
          },
        },
      },
    })
  } catch (e) {
    console.error(`Problem updating case documents cache: ${e}`)
  }

  // Update Client document cache
  try {
    const cachedCaseClients: Maybe<GetClientQuery>[] | undefined = cachedCase?.case?.details?.clients?.map((client) => {
      return cache.readQuery({
        query: GetClientDocument,
        variables: {
          id: client.id,
        },
      })
    })

    if (cachedCaseClients && cachedCaseClients.length > 0) {
      await Promise.all(
        cachedCaseClients.map((client) => {
          return cache.writeQuery({
            query: GetClientDocument,
            variables: {
              id: client?.client.id,
            },
            data: {
              client: {
                ...client?.client,
                details: {
                  ...client?.client.details,
                  // Update case documents cache to remove archived document
                  documents: cachedCaseDocuments?.map((doc) => {
                    const { document_id } = updateDocumentMutation!
                    const isArchivedDocument = doc.document_id === document_id
                    return {
                      ...doc,
                      archived: isArchivedDocument ? true : doc.archived,
                    }
                  }),
                },
              },
            },
          })
        }),
      )
    }
  } catch (e) {
    // Silently fail as the client document cache has not yet being generated
  }
}
