import React from 'react'
import { isEqual, omit } from 'lodash'
import { FormSpy } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { populateMissingFields } from '@acre/utils'
import { Button, BUTTON_PRIMARY, BUTTON_TYPE_SUBMIT, ConfirmationModalSaveChanges, Footer } from '@acre/design-system'

type Props = {
  includeConfirmModal?: boolean
  allowedRoute?: string
  sideNav?: boolean
}

const FormFooter = ({ allowedRoute, includeConfirmModal, sideNav = true }: Props) => (
  <FormSpy
    subscription={{
      values: true,
      initialValues: true,
      touched: true,
      submitting: true,
      hasValidationErrors: true,
    }}
  >
    {({ values, submitting, initialValues }) => {
      // Use lodash's _isEqual because FormSpy doesn't correctly handle
      // pristine or hasValidationErrors across multiple pages
      // preference_capital_repayment_type & preference_repayment_amount are FE only fields, so form should be treated as pristine if they change
      const filteredValues = omit(values, ['preference_capital_repayment_type', 'preference_repayment_amount'])

      const filteredInitialValues = omit(initialValues, [
        'preference_capital_repayment_type',
        'preference_repayment_amount',
      ])

      const pristine = isEqual(
        populateMissingFields(filteredInitialValues, filteredInitialValues), // do this to convert any undefined values into null values
        populateMissingFields(filteredInitialValues, filteredValues),
      )

      return (
        <Footer sideNav={sideNav} pristine={pristine}>
          <Button
            id="SaveChanges"
            buttonStyle={BUTTON_PRIMARY}
            isLoading={submitting}
            disabled={pristine}
            type={BUTTON_TYPE_SUBMIT}
            fullWidth
          >
            <FormattedMessage id="buttons.saveChanges" />
          </Button>
          {includeConfirmModal && allowedRoute && (
            <ConfirmationModalSaveChanges when={(location) => !pristine && !location.pathname.includes(allowedRoute)} />
          )}
        </Footer>
      )
    }}
  </FormSpy>
)

export default FormFooter
