import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface HoverPopOverClasses {
  root: string
  paper: string
  hitArea: string
}

export type HoverPopOverClassKey = keyof HoverPopOverClasses

export function getHoverPopOverUtilityClass(slot: string) {
  return generateUtilityClass('HoverPopOver', slot)
}

const hoverPopOverClasses: HoverPopOverClasses = generateUtilityClasses('HoverPopOver', ['root', 'paper', 'hitArea'])

export default hoverPopOverClasses
