import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import Cookies from 'js-cookie'

import { GetBrokersEulaDocument, GetBrokersEulaQuery, Maybe, QueryBrokersEulaArgs, User } from '@acre/graphql'
import config from '@acre/config'

export const filterBrokerClientRecordPerEnv = (user?: Maybe<User>) => {
  // client record can exist with same email address as brokers so we want to check if it's originated from acre

  const brokerClientRecord = user?.client_record?.find(
    (client) => client?.details?.client_originator === config.CLIENT_ORIGINATOR,
  )

  return brokerClientRecord
}

export const useEula = ({ user }: { user?: Maybe<User> }) => {
  const [doc, setDoc] = useState<Maybe<GetBrokersEulaQuery['brokersEula']>>()
  const [signedEula, setSignedEula] = useState<boolean>(false)
  const brokerClientRecord = filterBrokerClientRecordPerEnv(user)

  const [getBrokersEula, { loading }] = useLazyQuery<GetBrokersEulaQuery, QueryBrokersEulaArgs>(
    GetBrokersEulaDocument,
    {
      onCompleted: ({ brokersEula }) => {
        // if eula doesn't exist, sentry has logged it, we want to continue uninterrupted user flow
        // or if verification count is more than 1 than we know user has already accepted eula in the past
        if (
          !brokerClientRecord?.id ||
          !brokersEula ||
          (brokersEula?.verification_count && brokersEula?.verification_count >= 1)
        ) {
          Cookies.set(`eula-${brokerClientRecord?.id}`, JSON.stringify({ signed: true }), { expires: 30 })
          return setSignedEula(true)
        }
        // otherwise we render eula for acceptance
        if (brokersEula) {
          return setDoc(brokersEula)
        }
      },
    },
  )

  useEffect(() => {
    if (brokerClientRecord?.id) {
      const cookieValue = Cookies.get(`eula-${brokerClientRecord?.id}`)
      const eulaSigned = cookieValue && JSON.parse(cookieValue).signed === true

      if (eulaSigned) {
        return setSignedEula(true)
      } else {
        getBrokersEula({
          variables: {
            brokerClientId: brokerClientRecord?.id,
          },
        })
      }
    }

    // if Client ID doesn't exist, sentry has logged it, we want to continue uninterrupted user flow
    if (user && !brokerClientRecord?.id) {
      return setSignedEula(true)
    }
  }, [user])

  return {
    loading,
    doc,
    signedEula,
    setSignedEula,
  }
}
