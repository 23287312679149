import { differenceInYears, isAfter, isFuture, startOfDay } from 'date-fns'

import { generateUniqueId, getDifferenceInMonths } from '@acre/utils'
import {
  CommissionFrequency,
  Maybe,
  PredictedRevenueEntry,
  ProtectionProductDetails,
  ProtectionProductStatus,
} from '@acre/graphql'

export const getPredictedRevenueFromProtection = (protectionProducts: ProtectionProductDetails[]) => {
  const selectedProtectionProducts = protectionProducts.filter(
    (product) => product.status === ProtectionProductStatus.Selected,
  )
  return selectedProtectionProducts.reduce<PredictedRevenueEntry[]>((acc, product) => {
    const initialCommission = product.commission?.initial_commission
    const { total, commission_period, commission } = getProtectionFee(product)
    if (initialCommission && total) {
      acc.push({
        id: generateUniqueId(),
        value: total,
        fee_type: 'protectionProcFee',
        payer: product.provider_name,
        commission,
        commission_period,
        frequency: product.commission?.commission_frequency,
      })
    }

    return acc
  }, [])
}

export const getProtectionFee = (protectionProduct: ProtectionProductDetails) => {
  const commissionFrequency = protectionProduct.commission?.commission_frequency
  const initialCommission = protectionProduct.commission?.initial_commission
  const initialCommissionEndDate = protectionProduct.commission?.initial_commission_end_date

  if (!initialCommission || isNaN(Number(initialCommission))) {
    return {
      total: null,
      commission: null,
      commission_period: null,
    }
  }

  // Indemnified (one-off)
  if (commissionFrequency === CommissionFrequency.InvalidCommissionFrequency || !commissionFrequency) {
    return {
      total: Number(initialCommission),
      commission: Number(initialCommission),
      commission_period: null,
    }
  }

  // Non-indemnified (monthly/annual)
  if (initialCommissionEndDate) {
    const startDate = getStartDate({ startDate: protectionProduct.start_date, endDate: initialCommissionEndDate })
    if (commissionFrequency === CommissionFrequency.Monthly) {
      const numberOfMonthsUntilEndDate = getDifferenceInMonths(new Date(initialCommissionEndDate), startDate)

      if (numberOfMonthsUntilEndDate > 0) {
        return {
          total: Number(initialCommission) * numberOfMonthsUntilEndDate,
          commission: Number(initialCommission),
          commission_period: numberOfMonthsUntilEndDate,
        }
      }
    }

    if (commissionFrequency === CommissionFrequency.Annual) {
      const numberOfYearsUntilEndDate = differenceInYears(new Date(initialCommissionEndDate), startDate)
      if (numberOfYearsUntilEndDate > 0) {
        return {
          total: Number(initialCommission) * numberOfYearsUntilEndDate,
          commission: Number(initialCommission),
          commission_period: numberOfYearsUntilEndDate,
        }
      }
    }
  }
  return {
    total: null,
    commission: null,
    commission_period: null,
  }
}

const getStartDate = ({ startDate, endDate }: { startDate?: Maybe<string>; endDate: string }) => {
  if (startDate) {
    // If start date and end date is in the future and  start date is before the end date return the start date
    if (
      isFuture(new Date(endDate)) &&
      isFuture(new Date(startDate)) &&
      isAfter(new Date(endDate), new Date(startDate))
    ) {
      return new Date(startDate)
      // If start date is not in the future but end date is and start date is before the end date return today's date
    } else if (isFuture(new Date(endDate)) && isAfter(new Date(endDate), new Date(startDate))) {
      return startOfDay(new Date())
    } else {
      // alternatively return the end date as the start date, which will result in 0 commission
      return new Date(endDate)
    }
  }

  return isFuture(new Date(endDate)) ? startOfDay(new Date()) : new Date(endDate)
}
