import useFieldDisabledContext from './useFieldDisabledContext'

const useFieldDisabledState = (isDisabled?: boolean) => {
  const isDisabledContextValue = useFieldDisabledContext()

  if (isDisabled !== undefined) {
    return isDisabled
  }

  return isDisabledContextValue
}

export default useFieldDisabledState
