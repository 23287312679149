import { Feature, FlagType, useFlag } from '@acre/utils'

const usePreviewMode = () => {
  return {
    allowed: useFlag([FlagType.Feature, Feature.PreviewMode], true),
    active: window.location.hostname.includes('crm-next'),
  }
}

export default usePreviewMode
