import React from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  BUTTON_DANGER_SECONDARY,
  BUTTON_PRIMARY,
  BUTTON_TYPE_SUBMIT,
  COMPACT_LEFT_ALIGNED,
  Variant,
} from '../../utils/constants'
import { ButtonTheme } from '../../utils/types'
import Button from '../Button'
import { ButtonNotDisabled } from '../Button'

export type FooterButtonType = {
  onClick?: (args: any) => any
  isDisabled?: boolean
  isLoading?: boolean
  style?: ButtonTheme
  id?: string
  variant?: Variant
  text?: string
  useButtonNotDisabled?: boolean
  to?: string
  values?: { [key: string]: number | string }
}

type Props = {
  secondaryButton?: FooterButtonType
  primaryButton?: FooterButtonType
  primaryLink?: FooterButtonType
  hideSecondaryButton?: boolean
}

const ModalFooter = ({ secondaryButton, primaryButton, hideSecondaryButton, primaryLink }: Props) => {
  const theme = useTheme()

  return (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <>
        <Box display="flex" width="100%" />
        <Box display="flex">
          {!hideSecondaryButton && (
            <Box mr={theme.spacing(1)}>
              {secondaryButton?.useButtonNotDisabled ? (
                <ButtonNotDisabled
                  id={secondaryButton?.id}
                  buttonStyle={secondaryButton?.style || BUTTON_DANGER_SECONDARY}
                  type={BUTTON_TYPE_SUBMIT}
                  disabled={Boolean(secondaryButton?.isDisabled) || Boolean(primaryButton?.isLoading)}
                  onClick={secondaryButton?.onClick}
                  variant={secondaryButton?.variant || COMPACT_LEFT_ALIGNED}
                >
                  <FormattedMessage id={secondaryButton?.text || 'generic.cancel'} values={secondaryButton.values} />
                </ButtonNotDisabled>
              ) : (
                <Button
                  id={secondaryButton?.id}
                  buttonStyle={secondaryButton?.style || BUTTON_DANGER_SECONDARY}
                  type={BUTTON_TYPE_SUBMIT}
                  disabled={Boolean(secondaryButton?.isDisabled) || Boolean(primaryButton?.isLoading)}
                  onClick={secondaryButton?.onClick}
                  variant={secondaryButton?.variant || COMPACT_LEFT_ALIGNED}
                >
                  <FormattedMessage id={secondaryButton?.text || 'generic.cancel'} values={secondaryButton?.values} />
                </Button>
              )}
            </Box>
          )}

          {!primaryLink &&
            (primaryButton?.useButtonNotDisabled ? (
              <ButtonNotDisabled
                id={primaryButton?.id}
                buttonStyle={primaryButton?.style || BUTTON_PRIMARY}
                type={BUTTON_TYPE_SUBMIT}
                isLoading={primaryButton?.isLoading}
                onClick={primaryButton?.onClick}
                disabled={Boolean(primaryButton?.isDisabled)}
                variant={primaryButton?.variant || COMPACT_LEFT_ALIGNED}
              >
                <FormattedMessage id={primaryButton?.text || 'generic.saveAndClose'} values={primaryButton.values} />
              </ButtonNotDisabled>
            ) : (
              <Button
                id={primaryButton?.id}
                buttonStyle={primaryButton?.style || BUTTON_PRIMARY}
                type={BUTTON_TYPE_SUBMIT}
                isLoading={primaryButton?.isLoading}
                onClick={primaryButton?.onClick}
                disabled={Boolean(primaryButton?.isDisabled)}
                variant={primaryButton?.variant || COMPACT_LEFT_ALIGNED}
              >
                <FormattedMessage id={primaryButton?.text || 'generic.saveAndClose'} values={primaryButton?.values} />
              </Button>
            ))}

          {primaryLink && primaryLink.to && (
            <Link style={{ textDecoration: 'none' }} to={primaryLink.to}>
              <Button
                id={primaryLink?.id}
                buttonStyle={primaryLink?.style || BUTTON_PRIMARY}
                type={BUTTON_TYPE_SUBMIT}
                isLoading={primaryLink?.isLoading}
                disabled={primaryLink?.isDisabled}
                variant={primaryLink?.variant || COMPACT_LEFT_ALIGNED}
              >
                <FormattedMessage id={primaryLink?.text || 'generic.saveAndClose'} />
              </Button>
            </Link>
          )}
        </Box>
      </>
    </Box>
  )
}

export default ModalFooter
