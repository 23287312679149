import {
  formHelperTextClasses,
  InputBase as MuiInputBase,
  outlinedInputClasses,
  selectClasses,
  styled,
} from '@mui/material'

import selectFieldClasses from '../SelectField/selectFieldClasses'
import { TextFieldBaseProps } from '../TextFieldBase/TextFieldBase.types'

const InputBase = styled(MuiInputBase, {
  name: 'TextField',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, styles[props.mode]],
})<Pick<TextFieldBaseProps, 'mode'>>(({ mode = 'editable', theme }) => ({
  flexGrow: 1,

  [`& .${outlinedInputClasses.adornedEnd}`]: {
    paddingRight: 0,
  },

  [`& .${outlinedInputClasses.adornedStart}`]: {
    paddingLeft: 0,
  },

  [`& .${formHelperTextClasses.root}`]: {
    maxWidth: '100%%',
    flexBasis: '100%',
    marginLeft: 0,
    marginRight: 0,
  },

  '& > div, & > div > span': {
    flex: 1,
    borderRadius: theme.display.borderRadius,
  },

  [`& input, & .${selectClasses.select}`]: {
    background: theme.palette.background.paper,
    height: theme.spacing(4),
    lineHeight: theme.spacing(4),
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius,
  },

  [`& .${outlinedInputClasses.notchedOutline}, & .${selectClasses.select}`]: {
    borderColor: theme.colours.baseLight,
  },

  [`& .${selectClasses.select}`]: {
    borderWidth: theme.display.inputBorderWidth,
    borderStyle: 'solid',
    height: theme.spacing(30 / 8),
    paddingLeft: '0.75rem',
  },

  [`
    &:hover > :not(div.${outlinedInputClasses.disabled}, div.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline},
    &:hover:not(div.${selectClasses.disabled}, div.${outlinedInputClasses.error}) > .${selectClasses.select},
    &.Mui-focused:not(div.${outlinedInputClasses.disabled}, div.${outlinedInputClasses.error}) > .${selectClasses.select}
  `]: {
    borderColor: theme.palette.primary.main,
  },

  [`&.${outlinedInputClasses.error} > .${selectClasses.select}`]: {
    borderColor: theme.palette.error.main,
  },

  [`& input.${outlinedInputClasses.disabled}`]: {
    background: '#F5F6FA',

    [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: '#d7dae0',
    },

    ...((mode === 'protected' || mode === 'visible') && {
      background: 'transparent',
      '-webkit-text-fill-color': theme.palette.text.primary,
      color: theme.palette.text.primary,

      [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: 'transparent',
      },
    }),
  },

  [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
  },

  [`& .${selectFieldClasses.placeholder}`]: {
    color: 'grey',
  },
}))

export default InputBase
