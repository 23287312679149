import { styled, Theme } from '@mui/material'

import { Variant } from '@acre/design-system'

export const Div = styled('div')`
  display: flex;
  box-sizing: border-box;
  position: relative; /* Keep children contained */
`

export const Unit = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  min-width: 54px;

  color: ${({ theme }) => theme.colours.base};
  background-color: ${({ theme }) => theme.colours.baseExtraLight};

  font-size: 16px;
  font-style: normal;

  border-style: solid;
  border-color: ${({ theme }) => theme.colours.baseLight};
  border-width: ${({ theme }) => theme.display.inputBorderWidth};

  border-top-right-radius: ${({ theme }) => theme.display.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.display.borderRadius};
  border-left: none;

  pointer-events: none;
  transition: border 0.2s ease-in-out;
  outline: none;

  &.focused {
    border-color: ${({ theme }) => theme.colours.primary};
  }

  &.error {
    border-color: ${({ theme }) => theme.colours.danger};
  }
`

export const Input = styled('input')<{ variant?: Variant }>(
  ({ theme, variant }) => `
  display: flex;
  flex: 1 1 auto;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0px;
  font-size: 16px;
  border-style: solid;
  border-top-left-radius: ${theme.display.borderRadius};
  border-bottom-left-radius: ${theme.display.borderRadius};
  border-color: ${theme.colours.baseLight};
  border-width: ${theme.display.inputBorderWidth};
  outline: none;
  transition: border 0.2s ease-in-out;
  ${(variant === 'compact' || variant === 'compactLeftAligned') && compactStyles(theme)};

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }

  &:focus {
    border-color: ${theme.colours.primary};
  }

  &.error {
    border-color: ${theme.colours.danger};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseLight};

    &::placeholder {
      color: ${theme.colours.baseLight};
    }
  }
  
`,
)

const compactStyles = (theme: Theme) => `
  height: 32px;
  font-size: 14px;
  padding-left: ${theme.spacing(1.5)};
`
