import { styled } from '@mui/material'
import { darken } from 'polished'

import { COMPACT, SMALL, Variant } from '../../utils/constants'

export const TagStyle = styled('span')<{ variant?: Variant; color: string; hasMargin?: boolean; multiline?: boolean }>(
  ({ theme, color, variant, hasMargin, multiline }) => `
  background: ${color};
  display: inline-block;
  font-size: 12px;
  border-radius: ${variant === SMALL ? theme.spacing(4) : theme.display.borderRadius};
  padding: ${variant === COMPACT ? `0 ${theme.spacing(0.5)}` : `2px ${theme.spacing(1)}`};
  color: ${
    (color === theme.colours.new.yellow1 && theme.colours.new.yellow2) || darken(0.6, color)
  }; /* temporary if statement hack (until we migrate all tag colours to use the new colours in the theme) */
  font-weight: ${variant === SMALL ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold};
  line-height: 20px;
  white-space: ${multiline ? 'pre' : 'nowrap'}; 
  ${
    hasMargin
      ? `
          & + span {
            margin-left: ${theme.spacing(2)};
          }
        `
      : ''
  }
  &.clickable {
    cursor: pointer;
  }
`,
)
