import { Box, styled } from '@mui/material'

export const Tags = styled('span')`
  color: ${({ theme }) => theme.colours.warning};
  font-size: ${({ theme }) => theme.typography.pxToRem(10)};
  padding-left: ${({ theme }) => theme.spacing(1.5)};
`

export const ARFloat = styled(Box)`
  float: right;
  font-size: ${({ theme }) => theme.typography.pxToRem(12)} !important;
  top: 0;
`

export const PillContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const ClientDetails = styled('div')`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  & > div {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    margin: 0;
    color: ${({ theme }) => theme.colours.baseMid};
    :active {
      color: ${({ theme }) => theme.colours.baseMid};
      background-color: ${({ theme }) => theme.colours.primary10};
    }
    &:not(:first-of-type) {
      font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    }
    &:not(:last-of-type) {
      padding-bottom: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`

export const SmallText = styled('p')`
  color: ${({ theme }) => theme.colours.baseMid};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`
