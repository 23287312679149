import { ChangeEvent, Fragment, ReactNode } from 'react'

import testHandle from '../../utils/testHandle'
import { FlagReview, InputNote, ReviewDate } from './Shared.styles'

export type NoteBodyItemProps = {
  id: string
  value: string
  onChange: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined
  placeholder?: string
  createdDate?: ReactNode
  disabled?: boolean
}

const NoteBodyItem = ({ id, createdDate = null, value, onChange, disabled, placeholder }: NoteBodyItemProps) => {
  return disabled ? (
    <FlagReview mt={4} data-testid={testHandle(`${id}NoteBodyItem`)}>
      {value.split('\n').map((line, index) => (
        <Fragment key={index}>
          <p>{line}</p>
          <br />
        </Fragment>
      ))}
      <ReviewDate>{createdDate}</ReviewDate>
    </FlagReview>
  ) : (
    <FlagReview mt={4}>
      <InputNote
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={testHandle(`${id}NoteBodyItem`)}
        disabled={disabled}
      />
      {createdDate}
    </FlagReview>
  )
}

export default NoteBodyItem
