import { useCallback, useEffect, useState } from 'react'

import { Client } from '@acre/graphql'
import { fetchProtectedClientFields } from '@acre/graphql'

const fetchDateOfBirth = async (client: Client) => {
  return await fetchProtectedClientFields(client.id, ['date_of_birth'])
}

const zipClientsWithResult = (clients: Client[], result: Client[]) => {
  return clients.map((client, index) => ({
    ...client,
    date_of_birth: result[index].date_of_birth,
  }))
}

/**
 * This is a somewhat niche hook, but almost all protection-related work requires
 * unobfuscated dates as an absolute minimum requirement for making requests.
 *
 * This hook acts as a util for fetching the DOBs for a list of clients, and
 * provides both a callback function for more event driven usage, and an
 * apollo-esque return object as an alternative.
 *
 * Usage:
 *
 * const { clients, loading, error } = useClientsWithDob(clientsWithoutDob)
 *
 * or
 *
 * const { loading, error } = useClientsWithDob(clientsWithoutDob, (clients) => {
 *   doSomethingWithClients(clients)
 * })
 *
 * @param clients A list of clients whose dates of birth are obfuscated
 * @param callback A function to be called upon successful fetching of all obfuscated DOBs
 *
 */
export default (clients: Client[], callback?: (clients: Client[]) => any, skip?: boolean) => {
  const [clientsWithDob, setClientsWithDob] = useState<Client[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchClients = useCallback(
    async (clients: Client[]) => {
      try {
        // Grab the unobfuscated dates and zip them with the existing client data
        const result = await Promise.all(clients.map(fetchDateOfBirth))
        const resultUnwrapped = result.map((client) => client!.details)
        const clientsWithDob = zipClientsWithResult(clients, resultUnwrapped)

        // Call the provided function and update the state so we can return from the hook
        callback && callback(clientsWithDob)
        setClientsWithDob(clientsWithDob)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setError(true)
      }
    },
    [callback],
  )

  useEffect(() => {
    if (!skip) {
      fetchClients(clients)
    } else setLoading(false)
  }, [clients, fetchClients, skip])

  return { clients: clientsWithDob, loading, error }
}
