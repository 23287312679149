import React from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@acre/utils'
import { type MortgageClubFeeCalculated } from '@acre/graphql'
import { COMPACT_LEFT_ALIGNED, Dropdown, DropdownOption } from '@acre/design-system'

import { MortgageClubMessage, Ul } from '../IllustrationModal.styles'

export interface MortgageClubSectionProps {
  options: DropdownOption[]
  disabled: boolean
  mortgageClubProcFees?: MortgageClubFeeCalculated[]
}

const MortgageClubSection = ({ options, disabled, mortgageClubProcFees }: MortgageClubSectionProps) => {
  const formatMessage = useFormatMessage()

  if (options.length) {
    return (
      <Field
        name="mortgageClubCode"
        render={({ input }) => {
          return (
            <Dropdown
              id="SelectMortgageClubCode"
              {...input}
              variant={COMPACT_LEFT_ALIGNED}
              label={formatMessage('cases.products.options.generateIllustration.selectMortgageClub')}
              options={options}
              disabled={disabled}
              // error={!!noAlternativeProcFees || !!procFeeMissing}
              // message={noAlternativeProcFees || procFeeMissing || ''}
            />
          )
        }}
      />
    )
  }

  return (
    <>
      <MortgageClubMessage>
        <FormattedMessage id="cases.products.options.generateIllustration.notAvailableMessage" />
      </MortgageClubMessage>
      <Ul>{mortgageClubProcFees?.map((fee) => <li key={fee.mortgage_club_code}>{fee.mortgage_club_name}</li>)}</Ul>
      <MortgageClubMessage>
        <FormattedMessage id="cases.products.options.generateIllustration.changeOptionsMessage" />
      </MortgageClubMessage>
    </>
  )
}

export default MortgageClubSection
