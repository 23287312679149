import React, { cloneElement, ReactElement } from 'react'
import { Theme } from '@mui/material'

import { flattenChildren } from '@acre/utils'

import testHandle from '../../utils/testHandle'

export type TbodyProps = React.PropsWithChildren<{
  gutterPadding?: keyof Theme['spacers']
  rowPadding?: keyof Theme['spacers']
  fontSize?: number
  highlight?: boolean
  id?: string
}>

const Tbody = ({ gutterPadding, rowPadding, fontSize, children, highlight, id }: TbodyProps) => {
  const flattenedChildren = flattenChildren(children)

  const childrenWithProps = (flattenedChildren || []).map((child) =>
    child
      ? cloneElement(child as ReactElement, {
          gutterPadding,
          rowPadding,
          fontSize,
          highlight,
          ...(child as ReactElement).props,
        })
      : null,
  )

  return (
    <tbody id={id} data-testid={id && testHandle(id)}>
      {childrenWithProps}
    </tbody>
  )
}

export default Tbody
