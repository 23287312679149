import { Theme } from '@mui/material/styles'

export const disabledClassName = (theme: Theme) => `
  &:disabled,
  &.disabled {
    border: 1px none ${theme.colours.baseLight};
    background-color: ${theme.colours.baseExtraLight};
    pointer-events: none;
    color: ${theme.colours.baseMid};
    svg path: {
      stroke: ${theme.colours.baseMid};
      color: ${theme.colours.baseMid};
    }
  }
`
