import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Maybe } from '@acre/graphql'
import { CaseStatus, UpdateCaseStatusRequestUpdateType } from '@acre/graphql'
import { ConfirmationModal } from '@acre/design-system'

import { useUpdateCaseStatus } from '../../../../../utils/hooks/useUpdateCaseStatus'

type Props = {
  caseId: string
  isOpen: boolean
  onClose: () => void
  caseStatus: Maybe<CaseStatus>
}

const SubmitPreSalesCheckVerificationModal = ({ caseId, isOpen, onClose, caseStatus }: Props) => {
  const [updateCaseStatus, { loading }] = useUpdateCaseStatus(caseId)
  const handleSubmit = async () => {
    let update = UpdateCaseStatusRequestUpdateType.PresaleCheck
    if (caseStatus === CaseStatus.PreApplication) {
      update = UpdateCaseStatusRequestUpdateType.ReviewRequired
    }
    const options = {
      variables: { id: caseId, input: { update } },
    }
    await updateCaseStatus(options)
    onClose()
  }

  return (
    <>
      {isOpen && (
        <ConfirmationModal
          id="SubmitCaseForReviewModal"
          handleClose={() => onClose()}
          footer={{
            primaryButton: {
              id: 'ConfirmSubmitCaseForReview',
              isLoading: loading,
              onClick: () => handleSubmit(),
              text: 'caseOverview.buttons.preSalesCheck',
            },
            secondaryButton: {
              id: 'CancelSubmitCaseForReview',
              isLoading: loading,
              onClick: () => onClose(),
            },
          }}
        >
          <FormattedMessage id="caseOverview.modalConfirmations.submitForPreSalesConfirmation" />
        </ConfirmationModal>
      )}
    </>
  )
}

export default SubmitPreSalesCheckVerificationModal
