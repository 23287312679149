import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import {
  GateName,
  GetPermissionResolutionDocument,
  GetPermissionResolutionQuery,
  GetPermissionResolutionQueryVariables,
  ResolvedPermissionResolution,
} from '@acre/graphql'

import { mergeObjectArrays, transformPermissionInputs } from './permission.helpers'
import { PermissionInputWithAssignees } from './permission.types'

export type Props = {
  inputs: PermissionInputWithAssignees[]
  // these exceptions are an object
  // they can be ussed for any kind of exceptions
  exceptions?: { gates: GateName[] }
}

const useUserIdsWithPermissions = ({ inputs }: Props): { loading: boolean; usersThatHavePermissions: string[] } => {
  const { permissionInputs, allGates } = useMemo(() => transformPermissionInputs({ inputs }), [inputs])

  const { data, error, loading } = useQuery<GetPermissionResolutionQuery, GetPermissionResolutionQueryVariables>(
    GetPermissionResolutionDocument,
    {
      variables: { input: permissionInputs },
      skip: !permissionInputs.length,
    },
  )

  const resolvedPermissions = useMemo(() => {
    const { permissionResolution } = data || {}

    // we only receive an array of has_permissions from the api,
    // the order of these are expected to be the same as the inputs.
    // we need to merge the results from the api request and what was
    // sent so that the frontend code can have both the frontend
    // permissions gates as well as the corresponding response together
    let mergedArray: ResolvedPermissionResolution[] = []
    if (permissionResolution && allGates && allGates.length) {
      mergedArray = mergeObjectArrays(permissionResolution, allGates)
    }

    // when using this component in the code, we will most commonly
    // need to check which permission gates have validated permissions
    // the validated permission gates are returned here
    const assigneeIds: string[] = mergedArray
      .reduce<string[]>((acc, element) => {
        let result = acc
        if (element && element.assignee && element.has_permission) {
          result = [...result, element.assignee.id]
        }
        return result
      }, [])
      .filter((a, b, arr) => arr.indexOf(a) === b)

    return assigneeIds
  }, [data])

  return {
    loading,
    usersThatHavePermissions: error ? [] : resolvedPermissions,
  }
}

export default useUserIdsWithPermissions
