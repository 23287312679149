import { Client, ClientVersion, User } from '@acre/graphql'
type GetInitialCharacter = {
  word: string
}

type ClientUser = {
  id: string
  first: string
  last: string
  organisation: { name: string }
  advisor: { first: string; last: string }
}

export const getInitialCharacter = ({ word }: GetInitialCharacter) => {
  if (word) {
    return word.charAt(0).toUpperCase() || ''
  }
  return ''
}

export const getName = (client?: Partial<User> | Partial<Client>) => {
  const { first_name, last_name } = client || {}
  return [first_name, last_name].filter((val) => val).join(' ')
}

export const getNames = (clients: Partial<ClientVersion>[]) => {
  return clients.map((client) => getName(client.details)).join(', ')
}

export const getNameDisambiguation = (client?: ClientUser) => {
  const { first, last } = client || {}
  return [first, last].filter((val) => val).join(' ')
}

export const getAdvisorDisambiguation = (client?: ClientUser) => {
  const { first, last } = client?.advisor || {}
  return [{ first, last }].filter((val) => val)
}
