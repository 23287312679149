import React from 'react'
import { MissingFields } from '@broker-crm-utils'

import { Case, GetClientQuery, MortgageReason, Note } from '@acre/graphql'

export type CaseSubNavContextType = {
  case: {
    id: string
    details: Partial<Case>
    verifications:
      | {
          passed_collection_ids: string[]
          failed_collection_ids: string[]
          failed_verification_ids: string[]
          missing_fields: MissingFields
        }
      | undefined
    notes: Note[]
    noOfGIProducts?: number
    missingFieldsToggle: boolean | undefined
    missingFieldsTotals: {
      caseDetails: {
        [key: string]: number
      }
      factFind: {
        [key: string]: number
      }
      property: {
        [key: string]: number
      }
      mortgage: {
        [key: string]: number
      }
      mortgageLenderProposed: {
        [key: string]: number
      }
      suitability: {
        protectionSRMissingFieldsCount: number
        mortgageSRMIssingFieldsCount: number
      }
      lenderProposed: {
        [key: string]: number
      }
      caseDetailsTotal: number
      preferences: number
      mortgageDetailsTotal: number
      mortgageLenderProposedDetailsTotal: number
      factFindTotal: number
      propertyTotal: number
      propertyMortgageTotal: number
      suitabilityTotal: number
      lenderProposedTotal: number
      incomeMissingFieldsCounts: number
    }
  }
  enableProducts: boolean
  caseSolutionType?: { label: string; value: MortgageReason }
  dualNaturalClients: boolean
  hasNonNaturalClient: boolean
  nonNaturalClient?: GetClientQuery['client']
  naturalClients: GetClientQuery['client'][]
  isLeadCase: boolean
  isProtectionCase: boolean
  isBusinessProtectionCase: boolean
  isHomeInsuranceCase: boolean
  dualFactFindUrl: (url: string, clientType: 'NON_NATURAL' | 'NATURAL') => string
  isSuitabilityMenuOpen: boolean
  setSuitabilityMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isClientProfile: boolean
}

export const CaseSubNavContext = React.createContext<CaseSubNavContextType>(null!)
