import { useMemo } from 'react'
import { Typography, useTheme } from '@mui/material'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import { Circle } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import { testHandle, useFormatMessage } from '@acre/utils'
import { CaseStatus, CaseVersionSummary, useGetCaseVersionsQuery } from '@acre/graphql'
import { STATUS_INTL, STATUS_TAG_COLOUR, Tag } from '@acre/design-system'

import CollapsableSection, { SidebarSection } from '../CollapsableSection'
import { getPathnameSuffix } from './VersionNavigation.helpers'
import { SectionWrapper } from '../ReviewModeDrawer.styles'
import { StyledStep, StyledStepper } from './VersionNavigation.styles'

const messagePrefix = 'cases.reviewMode.versionHistory'

const VersionNavigation = ({ caseId, version }: { caseId: string; version?: string }) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()
  const navigate = useNavigate()
  const location = useLocation()

  const { data } = useGetCaseVersionsQuery({ variables: { id: caseId }, skip: !caseId })

  const activeStep = useMemo(() => (version ? parseFloat(version) - 1 : undefined), [version])
  const caseVersions = useMemo(() => data?.caseVersions?.cases, [data?.caseVersions?.cases])

  const steps = caseVersions?.map((version: CaseVersionSummary) => {
    return {
      version: version.version,
      date_created: `${version.version_created_at}`,
      status: version.details?.status || CaseStatus.InvalidStatus,
    }
  })

  const stepIcon = <Circle size={12} weight="fill" color={theme.colours.new.baseLightColour} />

  return (
    <SectionWrapper data-testid={testHandle('CaseHistoryWrapper')}>
      <CollapsableSection sectionId={SidebarSection.VersionsNav} header={formatMessage(`${messagePrefix}.caseHistory`)}>
        <StyledStepper orientation="vertical" activeStep={activeStep}>
          {steps?.map((step, index) => (
            <StyledStep
              key={`Version${step.version}`}
              expanded={true}
              active={index === activeStep}
              last={index === steps.length - 1}
              onClick={() => {
                const pathnameSuffix = getPathnameSuffix(location.pathname)
                return navigate(`/cases/${caseId}/review-mode/version/${index + 1}/${pathnameSuffix}`)
              }}
            >
              <StepLabel icon={stepIcon}>
                <Tag
                  text={formatMessage(STATUS_INTL[step.status])}
                  colourID={STATUS_TAG_COLOUR[step.status as CaseStatus]}
                />
              </StepLabel>
              <StepContent>
                <Typography fontSize={theme.typography.pxToRem(12)}>
                  <FormattedMessage
                    id={`${messagePrefix}.transitionedOn`}
                    values={{ date: format(new Date(step.date_created), 'dd.MM.yy') }}
                  />
                </Typography>
              </StepContent>
            </StyledStep>
          ))}
        </StyledStepper>
      </CollapsableSection>
    </SectionWrapper>
  )
}

export default VersionNavigation
