import { styled, Theme } from '@mui/material'
import { rem, transparentize } from 'polished'

import { SwitchVariant } from './Switch.helpers'

//
// There are a lot of hard-coded values here. Most of these will change after
// we've discussed with the design team how to handle edge cases like these
// in our library of design tokens
//

const clipBoardSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0E4EFB" viewBox="0 0 256 256">
    <path d="M168,152a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,152Zm-8-40H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm56-64V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V48A16,16,0,0,1,56,32H92.26a47.92,47.92,0,0,1,71.48,0H200A16,16,0,0,1,216,48ZM96,64h64a32,32,0,0,0-64,0ZM200,48H173.25A47.93,47.93,0,0,1,176,64v8a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V64a47.93,47.93,0,0,1,2.75-16H56V216H200Z"></path>
  </svg>
`

const activeCircleStyles = () => `
  &::after {
    width: ${rem('22px')};
  }
`

const getColor = (theme: Theme, variant: SwitchVariant, checked: boolean) => {
  if (variant === SwitchVariant.Large) {
    if (checked) {
      return theme.colours.primary
    } else return theme.colours.baseMid
  }
  return theme.colours.base
}

const getContent = (variant: SwitchVariant) => {
  if (variant === SwitchVariant.Large) {
    return `url('data:image/svg+xml;base64,${btoa(clipBoardSvg)}')`
  } else {
    return `''`
  }
}

export const StyledLabel = styled('label')(
  ({ theme, variant, checked }: { theme: Theme; variant: SwitchVariant; checked: boolean }) => `
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-out;
  font-size: ${variant === SwitchVariant.Large ? '14px' : 'inherit'};
  color: ${getColor(theme, variant, checked)};


  &.disabled {
    color: ${theme.colours.baseMid};
  }

  &:active:not(.disabled) {
    > .custom-checkbox {
      ${activeCircleStyles}
    }
  }
`,
)

export const StyledInput = styled('input')(
  ({ theme, variant }: { theme: Theme; variant: SwitchVariant }) => `
  min-width: ${theme.spacing(variant === SwitchVariant.Large ? 7 : 4)};
  width: ${theme.spacing(variant === SwitchVariant.Large ? 7 : 4)};
  height: ${rem(variant === SwitchVariant.Large ? '32px' : '20px')};
  margin: 0;
  padding: 0;
  appearance: none;
  cursor: pointer;
  margin-right: ${theme.spacing(1)};

  border-radius: ${rem(variant === SwitchVariant.Large ? '16px' : '10px')};
  background-color: ${variant === SwitchVariant.Large ? theme.colours.primary10 : theme.colours.baseMid};
  transition: all 0.1s ease-out;
  position: relative;

  &::after {
    content: ${getContent(variant)};
    width: ${theme.spacing(variant === SwitchVariant.Large ? 3 : 2)};
    height: ${theme.spacing(variant === SwitchVariant.Large ? 3 : 2)};
    border-radius: ${rem(variant === SwitchVariant.Large ? '12px' : '8px')};
    background-color: ${theme.colours.foreground};
    transition: all 0.1s ease-out;
    padding: ${variant === SwitchVariant.Large ? '4px' : 0};
    position: absolute;
    top: ${rem(variant === SwitchVariant.Large ? '4px' : '2px')};
    left: ${rem(variant === SwitchVariant.Large ? '4px' : '2px')};
  }

  &.checked {
    background-color: ${theme.colours.primary};
    &::after {
      position: absolute;
      left: unset;
      top: ${rem(variant === SwitchVariant.Large ? '4px' : '2px')};
      right: ${rem(variant === SwitchVariant.Large ? '4px' : '2px')};
    }
  }

  &:active:not(.disabled) {
    ${activeCircleStyles}
  }
  &.disabled {
    background-color: ${theme.colours.baseLight};
  }
  &.disabled.checked {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.primary)};
  }
`,
)
