import { useCaseContext } from '@broker-crm-contexts'
import { format } from 'date-fns'
import { useLocation, useParams } from 'react-router-dom'

import { Case, CaseStatus, CaseVersion, Maybe } from '@acre/graphql'
import { STATUS_INTL, useFormatMessage } from '@acre/design-system'

import { useGetDocumentDetails } from '../ReviewMode.helpers'
import { DEFAULT_VALUE } from '../Verifications/Verifications.helpers'

export const headerMessagePrefix = 'cases.reviewMode.versionHeader'
export const docHistoryMessagePrefix = 'cases.reviewMode.documentHistory'

export const getLastVersionTransitionTime = (details: Case) => {
  const versions = details?.transition_client_versions
  const lastVersion = versions?.[versions.length - 1]
  return lastVersion?.created_at
}

const setDateTimeForVersionHeader = (caseDetails: Case) => {
  const currentVersionTransitionTime = getLastVersionTransitionTime(caseDetails)

  if (currentVersionTransitionTime) {
    return format(new Date(currentVersionTransitionTime), 'HH:mm • dd.MM.yy')
  } else {
    return DEFAULT_VALUE
  }
}

export const getDocumentStatusKey = (verification_count: Maybe<number>, archived: Maybe<boolean>) => {
  const verificationStatusKey = verification_count && verification_count > 0 ? 'signed' : 'notSigned'
  const documentStatusKey = archived ? 'archived' : verificationStatusKey

  return documentStatusKey
}

export const useGetVersionHeaderContent = (caseVersion: CaseVersion) => {
  const formatMessage = useFormatMessage()
  const caseContext = useCaseContext()
  const { pathname } = useLocation()
  const { documentId } = useParams()

  const DEFAULT_VALUE = '-'
  const isSectionWithVersions = pathname.includes('/review-mode/version')

  // Exclude mortgage grid page for esis docs
  const isDocumentsSection =
    pathname.includes('/review-mode/documents') && !pathname.includes('/review-mode/documents/ESIS/list')

  const { data: documentData } = useGetDocumentDetails({ documentId })

  const { verification_count, date_stored, archived } = documentData?.documentDetails || {}
  const documentStatusKey = getDocumentStatusKey(verification_count, archived)

  const caseStatus = caseContext?.details?.status || CaseStatus.InvalidStatus

  let status = DEFAULT_VALUE
  let dateTime = setDateTimeForVersionHeader(caseContext.details)

  if (caseStatus) {
    status = formatMessage(STATUS_INTL[caseStatus]) || DEFAULT_VALUE
  }

  if (isSectionWithVersions) {
    status = caseVersion?.details?.status ? formatMessage(STATUS_INTL[caseVersion?.details?.status]) : DEFAULT_VALUE

    dateTime = caseVersion?.version_created_at
      ? format(new Date(caseVersion?.version_created_at), 'HH:mm • dd.MM.yy')
      : DEFAULT_VALUE
  }

  if (isDocumentsSection) {
    status = formatMessage(`${docHistoryMessagePrefix}.${documentStatusKey}`)
    dateTime = date_stored ? format(new Date(date_stored), 'HH:mm • dd.MM.yy') : DEFAULT_VALUE
  }

  return { status, dateTime }
}
