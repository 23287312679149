import { Maybe, OrganisationType, User } from '@acre/graphql'

import { userType } from '../../utils/constants'

export const checkIfFirstTimeUser = (user: Maybe<User>) => {
  const isFirstTimeOrg = user?.organisation?.first_time_access?.complete === false
  const isFirstTimeUser = user?.first_time_access?.complete === false

  return {
    isFirstTimeOrg,
    isFirstTimeUser,
    isFirstTimeUsingAcre: isFirstTimeOrg || isFirstTimeUser,
  }
}

export const isAllowedToOnboardOrg = ({ orgType, userRole }: { orgType: OrganisationType; userRole: string }) => {
  if (orgType === OrganisationType.Ar && (userRole === userType.firmManager || userRole === userType.principal)) {
    return true
  } else if (orgType === OrganisationType.Da && userRole === userType.principal) {
    return true
  } else {
    return false
  }
}
