import { ReactElement } from 'react'
import { OperationVariables } from '@apollo/client'
import { MutationFunction } from '@apollo/client'
import { DocumentNode } from 'graphql'

import { Mortgage, MortgageProduct } from '@acre/graphql'

export type UUID = string

export type AddressFindResult = {
  id: string
  summary: string
}

export type Match = {
  params: {
    id: string
    caseId?: string
  }
}

export type Metadata = {
  name: string
  string_value?: string
  bool_value?: boolean
}

export type LocalStorage = 'localStorage'

export type Cookie = 'cookie'

export type Storage = LocalStorage | Cookie

export type HEADER = string

export type CSS = string

export type HTTP_METHODS = 'GET' | 'PATCH' | 'POST'

export type RequestUrl = string

export type ApolloClient = any

export type Body = any

type OnClickCallWithValue = (value?: any) => any
export type OnClickMethod = OnClickCallWithValue

export type HelperTextType = 'error' | 'success' | 'tip'

export type Status = 'true' | 'false'

export type Order = 'none' | 'asc' | 'desc'

export interface DataDrivenState {
  [key: string]: any
}

export type FormType = {
  rows: Array<FormRowType>
}

export type FormRowType = {
  id: string
  fieldName: string
  type: RowTypeName
  label: string
  validator: 'none' | 'dateOver18' | 'single' | 'ageInCorrectRange'
  initialState?: string | {}
  placeholder?: string
  options?: Array<any>
  obfuscated?: boolean
  defaultValue?: string
}

export type RowTypeName = 'text' | 'number' | 'radio' | 'date' | 'checkbox' | 'dropdown' | 'file' | 'multiselect'

export type ClientMutation = MutationFunction<any, OperationVariables>

export type GqlMutation = DocumentNode

export type GqlQuery = DocumentNode

export type ObjectType<T = any> = {
  [key: string]: T
}

export type MarginSizeKeys = '0' | '1' | '2' | '3' | '4'
export type MarginSizeMapping = { [key in MarginSizeKeys]: number | string }

export type HeaderSize = 'large' | 'medium' | 'small'

export type FontSize = 'size42' | 'size26' | 'size16' | 'size14'

export const fontExtraLarge = 42
export const fontLarge = 26
export const fontMedium = 16
export const fontSmall = 14

// Extend when required
// https://developers.alliescomputing.com/postcoder-web-api/address-lookup/international-addresses#global-coverage
export type CountryCode = 'GB'

export type Response = any

export type CardIcon =
  | 'blueBankCard'
  | 'blueFolder'
  | 'greenHouse'
  | 'greyHouse'
  | 'orangeProfile'
  | 'purpleBriefcase'
  | 'greenBriefcase'
  | 'blueMultiStorey'
  | 'aquaBlueShieldPound'
  | 'purpleShieldPound'

export type RatePeriod = Pick<MortgageProduct, 'initial_rate_period' | 'initial_rate_period_months'>

export type PaymentMethod = Pick<Mortgage, 'interest_only_amount' | 'repayment_amount'>

export type FormValue<T> = { value: T; error: string }

export type ElementChildren = ReactElement | ReactElement[]

export type TranslationOptions = {
  label?: string
  first?: string
  second?: string
}

export type Layout = 'row' | 'column'
