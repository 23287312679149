import { styled } from '@mui/material/styles'

export const TaskRowSidebarDraw = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  'div, button': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '14px',
    lineHeight: 1.2,
    textWrap: 'pretty',
    whiteSpace: 'pre-wrap',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colours.primary,
    },
  },
  '&:last-child': {
    marginBottom: 0,
  },
}))
