import { Verification, VerificationCollection } from '@acre/graphql'

//
// The lists of keys below are not exhaustive, and should be
// updated regularly
//

export type ExceptionTaskOrCollectionKey = 'publishMortgageSuitabilityReport' | 'publishProtectionSuitabilityReport'

export type CollectionKey =
  | '4c7119d6-54da-4b8f-9646-6eb501a2fee5'
  | '6485401b-de35-479f-8bba-b3885964c645'
  | '1603231f-4362-4770-81c9-eea2bfa740d2'
  | 'd1067db2-b239-4309-9c69-a35c2ca64945'
  | '2707a2c1-c5bf-47df-83c3-6ac722f30edd'
  | '98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b'
  | 'fc9cae62-29d0-480f-ad8f-c8050d9162da'
  | 'b4ba2510-75ef-4c80-a8c3-6dde09dd7f85'
  | 'c8e370ea-d938-4517-8d8f-a09b2a54da33'
  | '20381c16-daa6-46d9-b7c2-cf1c0928032b'
  | '691d8ef8-8331-11eb-8dcd-0242ac130003'
  | 'e272c0be-f340-4a77-a0ab-221f2de72917'
  | '3ab3666a-19cb-4169-b5bd-49a3d59b5183'
  | 'ed8aa316-c6cd-4e4f-971b-75a549dbc8a2'
  | 'c9369465-3f51-4440-923c-fb024eebee81'
  | '88e78b05-a12c-45ec-b6fe-55d9cee9e5a2'
  | '4e3ac03e-c6a6-4504-849b-b4aee94b7225'
  | 'da46dac2-6863-4833-b065-0a104e87e2b0'
  | '82683f28-6ef3-4510-8f9b-8f903f053b52'
  | 'b0a8fa92-7eea-48dd-b202-51e745857a88'
  | 'eea6416b-0fac-458d-a70a-fa5f7bc41eb5'
  | 'f96f5367-2ee1-4a9c-a40c-5320346779d9'
  | '3bf5e3a6-09e7-44b9-89f6-ddc873a0dfd9'
  | 'afa45dde-afb5-11ea-b3de-0242ac130004'

export type VerificationKey =
  | 'b55a82c8-d7e7-4266-84e2-d15c270de492'
  | '9faabe14-c68f-4fbc-b72c-baa2a4c10009'
  | '0f0d8607-1fce-4bee-87ff-aff9ae7b2a26'
  | '78ab77f6-7c22-11eb-9439-0242ac130002'
  | 'a6d13434-c20b-442e-8a7a-881d01274bbc'
  | 'ff96366b-a234-4001-b177-a328abcfba94'
  | '5674053a-e675-475b-93a7-eac7d0fc98e2'
  | '506b21bf-6666-4e9f-8696-9d788ab135bc'
  | 'd68d625b-08b0-43a9-9a98-209ac5745d9b'
  | '82ebd963-95bf-4e9d-8b96-96e69a8cb91b'
  | 'a8b629e8-80b2-11eb-9439-0242ac130002'
  | 'a82870e8-80b3-11eb-9439-0242ac130002'
  | '73b4345a-a632-4655-b4b0-5bbf18ed6a45'
  | 'f4b56f02-80b2-11eb-9439-0242ac130002'
  | '15f7068f-411c-420a-b6fd-fa269f47de2a'
  | '20b843c1-8259-4ada-b8c4-148a9bf89db5'
  | 'de90ffb3-983d-4cc4-95b7-808aa024ea62'
  | '3764a1fa-8caa-4e48-8bd4-6db9c7903c63'
  | '03ef5cc0-8032-11eb-9439-0242ac130002'
  | '09d4b8bc-8030-11eb-9439-0242ac130002'
  | 'd6b2daf0-802e-11eb-9439-0242ac130002'
  | 'b21c1338-6d80-49cb-9cad-d6382708bb26'
  | '7d268706-343a-4b38-8bc7-28acd5fc3ceb'
  | 'f458e0a5-6dda-4893-9831-efc0eaf41ee3'
  | 'b64e9fc8-3581-47da-9016-542c531cb2c4'
  | 'a44e05d4-64bc-4a51-8fe4-cf1def3c298d'
  | '71d9a2ba-832d-11eb-8dcd-0242ac130003'
  | '8a7b2bb0-2a2f-4a96-b5b5-f5f4b7c99f7d'
  | '91589e44-f3f4-46e9-8c7e-6132d7212d68'
  | '3a135ecf-badb-44f2-bc58-b07e6ba3242f'
  | 'af6831a1-d245-49c1-aae4-a19fe3ef7cb8'
  | '6cf64d8f-859e-46f5-8226-5333292296b2'
  | '59c8fd23-fc18-44dd-bd82-117e7eb9fa3b'
  | '83be9147-91cf-4bce-844c-b6db5d1c4bf3'
  | 'f10faa7d-08d6-4d9b-ae65-2ed52c124a99'
  | '8f854751-4152-43fc-a269-8be4fd5b35d1'
  | '6be4c226-47b2-4a25-af25-49a53cd1c82b'
  | '52affbdf-7076-41a6-a980-db30c11f2f0d'
  | '88527420-3d19-422d-968d-b6e5f0549ed9'
  | '4d4fdb5c-bd61-432d-a18e-1dd17d0fd554'
  | '45488490-5a12-4017-8785-3dbe5aa38bce'
  | '63368740-0a3d-41f6-b114-091ac52b68df'
  | '07b3cc86-eaad-488a-b292-0760be3a4284'
  | 'b1d48c3c-676b-40e7-bf8f-04dbf2bf1af1'
  | 'c8730791-7a36-4dba-8e49-7507e87cf1ba'
  | '538093c9-bdef-464c-ae78-7dba75cb4a81'
  | '576b7485-4ae4-4c62-b290-44f8459a21e0'
  | '3f95dd5e-dc6b-45c0-a182-2dfa262f8575'
  | '5a635f18-2f97-4167-ac62-5709d1fe57f0'
  | '66ac7972-009b-4093-8091-47023fa417d5'
  | 'fae864d0-0336-46f6-889a-5599f10d4ea3'
  | '1256e8d2-3006-4c1a-b568-82e9c0b65c48'
  | 'd25183b2-217d-4332-ae5d-61c784d1b3e2'
  | '07f64c6e-b8b1-47ad-a716-b16b4d7a4b8b'
  | '3192cd8d-9797-44fb-a924-97e557ffbfc5'
  | 'de277f43-a520-4e45-bccf-d88b343b7722'
  | '234cc4fa-9ad2-421f-a300-992565715b27'
  | '6ac78159-4892-46ef-8572-be78ee6912e6'
  | '95b21230-1e1b-4e79-98e1-a1f0e51633f9'
  | 'e189e27d-7918-486d-9ef9-5f7ddb26449d'
  | 'c90746f3-953c-4d86-b9f7-170197a2d622'
  | '47703272-5d30-4294-af39-7941592f0cba'
  | '53044d60-33c9-47b3-b66a-56a82bd280e5'
  | '6d0a10f8-0637-43fe-85f1-5b57386b5dd6'
  | '52db2330-aa7f-4749-a5e3-e859905992f8'
  | 'ee788625-d3e3-4889-849d-e0e2d66af8b7'
  | '8556fd11-5be3-4d4f-858d-28367227a23c'
  | 'fbcd7731-889e-463a-bcfa-a9e07ad0e119'
  | 'eb7d1179-0c21-487c-8055-0a144c6714cb'
  | '0b8da2f6-e337-4c3c-8925-1eb52945b9dd'
  | '8d755443-1385-4116-bc35-8a332769098d'
  | 'c26d31f6-9477-4be5-9aee-8fcacabacbba'
  | 'd3529e3f-9664-4356-849e-788d151b0c5f'
  | 'f3d8f8bf-f272-4d13-8c39-c52a162f336e'
  | 'a13a9143-115c-4c34-bedb-aa8cba05a1f8'
  | '62eadab4-423f-472d-96dd-dc1a6874cc25'
  | 'e972bd4c-e481-4bde-b50d-7df25f3e67fa'
  | 'e836b333-c447-4754-8952-1d884a0651a5'
  | 'ff645601-9a5f-4a83-91c4-a4512e1c10ca'
  | '2a1be837-f901-4968-9cc1-54fd49c39340'
  | '9eb7f388-39a3-40dd-8603-50924218e152'
  | '30f54685-ec36-4ed5-9fa2-0a1c455d2744'
  | 'c58ef454-7c96-4ced-acf1-30506bf7d847'
  | '4b7e6e7b-7cd2-470b-a36e-a7aab5d20763'
  | '6702f721-775a-41fb-b46c-e388a8e7a75a'
  | 'ab676e82-1d31-45c4-b042-24f7a0a239f1'
  | 'e4122491-986c-4250-9a8e-cab42731009c'
  | '6d122d2d-04d6-46fa-95b3-d2d919a8aec3'
  | 'a158b12d-0291-400a-aeb2-3eebd8cf3198'
  | 'dd86c0a9-ba0e-45b0-8afd-3a97c2fd7e35'
  | '78eb8384-9c95-4194-ada1-fe79b2b1bd90'
  | '75b7e4db-4997-4c80-9b9f-f0ced7ad63eb'
  | '6a99cf22-0794-4543-99de-045f3fb60ddb'
  | '605ec4f1-47ed-4739-b62e-a346e012491e'
  | '59ee1255-8579-4207-b361-a92bcca61915'
  | '6e25da8e-b9e3-4ab7-aa56-3afe6b7b9dff'
  | '510c7731-fd74-4ed2-9103-3489cb12aa57'
  | 'fbfcb0ea-78a1-43c8-bc69-c83225cdb550'
  | 'b2692937-b875-4d77-b8a3-30a1bb213d77'
  | '89e5e19e-6048-4d3d-a106-8b85fd4d3c7b'
  | '35f8041f-c427-41a7-b092-8f6311bf6ab6'
  | 'e876254f-4165-4b59-a0f8-d8dca09c9f54'
  | 'd5b1b220-b7af-4558-990a-721e7a9bd75f'
  | 'e14dbffe-3d5b-4378-8571-033c1a92936e'
  | '1a942d77-2bcd-4f20-a7c5-fd57d62a27a1'
  | '5c8b97d0-0ab6-42e8-acf1-18cfea89e143'
  | 'cc59a169-8f91-4209-901b-73dc51d03fb2'
  | 'f572bb29-21a0-4716-9a72-a557c9f2cde5'
  | 'bf522e92-aa1d-43f1-a0fb-4669472fd87d'
  | 'd7dc4595-9006-4efc-bc40-26a12b624577'
  | 'a9cef192-0791-44f3-b34b-5d4e37ecc524'
  | 'ecbc1a9b-9161-42e8-86dc-7fd062a13d02'
  | '2ccd8f67-3491-49c7-bf63-5e1c7c2e49f3'
  | '4a868a1e-a3ae-4c6c-b3ed-52f251e27c46'
  | '939e93dd-0e6f-4880-a6d2-5f753644809b'
  | 'a6afa2b7-42d3-46cc-97c5-fdf8c8907e19'
  | '379fe0a3-dae4-4543-a9b6-b8cd16f9a768'
  | 'edf02f0b-c7e7-4b7a-9d07-dbe1266e6e58'
  | '7fa91a15-6d4d-4a6c-8a6e-8c0f45f2371b'
  | 'c13bd0fa-beee-4634-89c8-4fc136c94ad1'
  | '260631d7-1874-4c65-85d5-c983d920e35a'
  | 'f37c07b2-8f15-4caa-991f-11c91900140e'
  | 'de9651fa-965c-4071-9559-9782bb39d10c'
  | '6edc5db0-896d-11ea-bc55-0242ac130003'
  | '89a91ebd-688a-478a-9dc3-723c677fc56f'
  | 'a5fbd96e-eafc-4c51-8636-2276ad34ceb4'
  | '8b154e7c-0bf7-44c8-b21f-81276d2f2dd1'
  | '14675e56-1ddb-4f01-be81-024f67588de7'
  | 'ae1c1a45-3d10-49a1-8b90-d5e00f8799e8'
  | 'd89629bb-cc54-48e4-858c-8cab432da745'
  | 'ce23e4de-6f37-4af4-a801-7433c3528415'
  | 'e24b93d9-8a84-4ad3-aa24-1992062ac08b'
  | 'bbaa3ca1-3578-41ba-9b8b-ede084d8934c'
  | '6f4cb8a4-b264-43f6-b594-dce2a036e1b6'
  | '3f425e12-7e3a-4982-93a0-b23ef15f1cf2'
  | '03a1f11b-c975-4449-a4b5-a885060a9dab'
  | 'a4d5b1ac-d387-479a-a928-4f0ff5149435'
  | '88f985f0-7b24-481b-be12-0e8c5d164014'
  | '56ceeca7-0b28-4d61-96a9-ed0cc0a67c7c'
  | '5ec29996-5760-11eb-ae93-0242ac130002'
  | 'f091a106-60ba-11eb-ae93-0242ac130002'
  | 'f685c966-60ba-11eb-ae93-0242ac130002'
  | 'e99902a4-60ba-11eb-ae93-0242ac130002'
  | '5c538531-0ae5-41d5-83ff-4d2602455ddd'
  | '4476f4bc-566e-11eb-ae93-0242ac130002'
  | 'afd2ba94-53f2-11eb-ae93-0242ac130002'
  | 'a771746c-53f2-11eb-ae93-0242ac130002'
  | '92005882-53f2-11eb-ae93-0242ac130002'
  | 'b647ea34-53f2-11eb-ae93-0242ac130002'
  | 'be175754-53f2-11eb-ae93-0242ac130002'
  | 'b88d2170-53f1-11eb-ae93-0242ac130002'
  | '91078a5c-5138-11eb-ae93-0242ac130002'
  | '85a17492-51de-11eb-ae93-0242ac130002'
  | '1394181a-5138-11eb-ae93-0242ac130002'
  | 'f117637a-f4ca-4ea5-a346-db6c78ff1e6c'
  | '3c3998fe-80b2-11eb-9439-0242ac130002'
  | '051e39c7-490e-4a66-a5f4-0f13d5af3256'
  | '07014b47-8497-46d9-a0cc-b11c24028a0a'
  | '102bdcfb-4b0d-4002-9550-a045db1792b7'
  | '107869cb-8aa9-48fb-8dfc-e50a710bf693'
  | '10a0b101-dcf5-40e7-9a8c-6c9e5a5c31dd'
  | '10d21169-2cbe-4b33-a75a-6618537dc664'
  | '18c315c4-374f-4f30-94a2-4c821994a5a8'
  | '1c5adeab-a87b-4cc6-891c-2f9765c21e91'
  | '270826fc-f565-4e58-ab5c-35ce210ac103'
  | '2754e797-110f-4aa0-bc3f-9ed3edcb7743'
  | '33158ffc-1705-4804-af31-877437b0a55f'
  | '335ff476-425d-412d-b76c-735968f5fe3d'
  | '3450f777-1900-4cdb-a95f-58cb3fee341f'
  | '37ec1d97-8289-41ef-acd2-433a570e9f18'
  | '382e0668-69cf-414b-a8c0-d9a9b3fedb5b'
  | '392a68a2-5f36-11eb-ae93-0242ac130002'
  | '395cbdc0-6959-4b96-8b1e-fe20c8fd31f5'
  | '39ed369d-15fb-42c0-a179-d1fb3d132a13'
  | '46139a9e-1d82-4dd0-9ddf-376e930a6b5d'
  | '51d3c71a-9665-49cb-87f0-10ef89b09f35'
  | '55b58e24-1c71-467a-95e8-6dfe0a4e2a78'
  | '5977f29a-f164-4476-91d8-c35ff16a2650'
  | '59aa9000-530c-4463-9d5a-f4926cf08a8b'
  | '6a948a3b-e289-4181-88e9-b32d8a72035a'
  | '6cd91878-b1ca-4029-bfd3-89262c5f935d'
  | '76e15ec8-072a-465e-a01c-cb5648affc9e'
  | '7a60dc16-afb4-11ea-b3de-0242ac130004'
  | '7c88c0e8-175a-474e-bcb5-6b09615027e3'
  | '7d38ef29-293b-4b6e-8126-d06251456128'
  | '89956183-356c-463e-964f-f0f380800b73'
  | '8cf5ae8f-289b-4337-a016-402055711a9a'
  | '8f7c3fbc-c2e9-45dc-9635-3357cd573033'
  | '979dfdda-76b0-4740-b1bd-6dfb9f7a29bb'
  | '99df172c-d26c-4e10-926a-de443d30b6a9'
  | '9aecef99-a36f-4efc-b480-8b9d99fdac57'
  | '9dc1f4cd-188b-4d5c-9ea4-dcafba0218c6'
  | '9eda1bab-e695-437b-bb8c-6a1f7c390726'
  | 'ab16aca8-7c2a-11eb-9439-0242ac130002'
  | 'acc1722c-fadc-4e34-b7e3-996b3076eebc'
  | 'b8c850c1-1405-4d3f-8f8d-bd13a528a615'
  | 'c13f1116-e86d-4a90-85dc-d6f68056243b'
  | 'cf430f44-d21e-4041-8ff6-5f3cf5796f7c'
  | 'd4b3b396-b0e0-4fd9-9eec-efadb9fe7785'
  | 'd50644c2-2e57-4c29-bd85-e4bfd97cdaaf'
  | 'd57a4003-709d-4004-bd8b-c59837d912c7'
  | 'd590bb05-0ba8-4543-ba3c-b2e839118424'
  | 'd73ed8c5-0474-46a0-8971-2b53db03604d'
  | 'd7e04033-2d6a-4de5-8fb8-11bc7442eae7'
  | 'd7e04033-2d6a-4de5-8fb8-11bc7442eae7/protection_referral'
  | 'd959961b-bbba-46ca-81c6-f6f447e72041'
  | 'dd18e02a-8b1e-4404-b8c2-5fdde58720b7'
  | 'dd197157-da9e-4135-9b77-085416335c39'
  | 'e0a3f8a6-5a4e-11eb-ae93-0242ac130002'
  | 'fa3e4e1c-77c1-40d1-8026-580b5ca72f4e'
  | 'f403dccd-54b8-4200-a616-a4f3bc077010'
  | 'fd7fa9bb-fefc-46d7-a1ac-4be76db4f779'
  | '712d4f12-6b40-4a22-a825-1c35b01807b9'
  | 'e50a8168-39e6-4efb-9686-b06fcda4a869'
  | '8306e6b6-3854-46b1-91a0-b1b3c84a2952'
  | '64bd4831-dced-4dfd-b949-57b7b96d91b8'
  | '0c103cb9-2a9f-4936-a7ce-86beb616f330'
  | '6350665c-0fcb-4758-995f-739e52e47a18'
  | '76c75968-530e-4126-9f1f-419dc3fad906'
  | '70f31fd2-63f5-43c6-bbf5-d0bd45892d16'
  | 'bad66433-6c2c-4663-baca-358a8d2e09c1'
  | 'a5a61d7b-25cf-4ea8-80de-930e92e77f17'
  | 'c68cbfd4-6e6d-4caa-9146-0eeddc5f1299'
  | '0f12f92a-fe52-4f56-9d8a-818be35ed2fa'
  | '3b652069-7251-4ec7-9ea8-787f579e1e44'
  | 'e50d04aa-0758-4af2-a0e7-35df18970d79'
  | '787c2e5a-ffeb-4cff-b39d-869653fc8187'
  | '7fefa140-28d4-48ff-8c33-1e839e16ef70'
  | '6535f2ac-d7e3-436c-b8de-aa6fbb4588b9'
  | '99735aca-8fce-4c19-8e74-0ff3407e624a'
  | '0ec4d531-826e-4591-8a75-809cfc5ddb7'
  | '3e647cab-40ff-46d3-b9ba-2205d18bb782'
  | 'c2482c8b-6f38-4df8-aedd-a4376a5ec1a0'
  | '4e66a2e1-7af0-4255-b2a7-7224b449c30e'
  | '9c1cda01-77b6-43e8-995a-cc9b52413ebc'
  | 'e0b3e204-c672-4176-b10d-042d28147625'
  | '90f468f9-33dc-4e8f-9118-bb54a347fc2a'
  | '263b4b00-83a9-4491-87e1-fb4dde10d1fa'
  | 'da1fb103-95a5-4503-bd37-4233c67bee2c'
  | '8fead52d-6246-4a1d-9a17-5edfb91cb28a'
  | '3f955f9e-2457-419a-80c4-4a6242e39fe8'
  | '8f6cefda-67dd-4e10-aea4-89ef01478e32'
  | 'df7577cb-3e9a-43f4-8ded-399ddef636c5'
  | 'ed3742bf-b510-4f32-84bc-1bfe2ce5b293'
  | '063cd64f-be75-4997-8414-a4b81f3dcbb3'
  | 'f4787a82-28b8-445b-9eca-fb9195d7026a'
  | 'f7ec2acf-2249-49ee-a116-c7c2624079db'
  | 'df59edf0-66d8-4af6-82f3-2459562e5973'
  | 'fb66f1f8-9472-41ce-9bbd-4656768af686'
  | '5ebf5d84-72cb-4bf8-a8af-4ef75dbffb2c'
  | '33e2aeff-de87-45e4-aa79-e6551edc82f7'
  | 'a1bafe5d-66ec-41f1-a3e1-45089b1d5a48'
  | '54fb2f5e-5ca5-4e83-8102-9e59db2d3e9e'
  | '08f39667-f20d-4a6e-a0fb-3543be78e0f4'
  | '54eb2116-7636-4e7b-a139-47703cc42b29'
  | '23df27b2-fee9-481d-9028-8b358062254d'
  | '2216a94d-c818-446f-9084-88e842602e4c'
  | '444194b7-c458-48dd-987f-35d17bf3ebcb'
  | 'ad7fc981-c28f-4b6b-93c1-b414790bdea3'
  | 'eabfeb16-5736-41a9-9e9e-99bee4d67366'
  | 'a4e4334a-3749-48d2-90fc-651a1658dd4f'
  | '01be36c4-b1ae-475f-8f89-f83196139323'
  | '647935f0-96e0-4d9c-84d0-ce18309fdb94'
  | '46f05799-a5ec-405f-835b-5de2189731d0'
  | '7341e3d5-627a-4e57-a0c3-39b3cd697f22'
  | '37bcbf58-f37e-432d-b3e1-207c0e2190e5'
  | 'd5f469f4-79ef-4341-b4c5-a80e12900fa5'
  | '15e205ba-022a-44c2-b92c-e86c21e00595'
  | 'cb6a4dad-eae4-4d3b-886d-5b9ee5a432e2'
  | '06a14ce7-cf4e-4cda-998a-f5b16bed3cbe'
  | 'b642cda9-4c61-455b-82d2-94818009c6b1'
  | '2f129eff-cb01-4915-a1d2-375b46fe3fce'
  | '8be14420-e7d4-4736-ac21-0bf01c00da6f'
  | '25c29e20-1464-48ad-abe3-1ab928c963c1'
  | 'bd6b7735-0df8-4f3c-9887-d3846c5bec75'
  | '423145aa-39f6-4bb7-be47-79ba6267da87'
  | 'd63c028e-3774-4c08-b64a-58040884bffe'
  | '5d76f44b-519f-4396-9f13-ac169aa9a3b6'
  | '7cc94df7-1f70-4e23-899b-3807dab4d990'
  | '5726802b-92ce-4914-8e15-7c377d7464e4'
  | '55b7fa41-737a-4304-a347-fe6809235a05'
  | 'f86ce4c9-6c7b-46fc-91fc-95d10007770a'
  | '6a06e6de-80f0-4a24-9ef7-7e4e0bb362a3'
  | '133deeee-7073-448b-b59b-96d2f74764cc'
  | '5aef1eb8-a3ac-4de2-8da6-4b557be31afb'
  | 'f8f3113c-de51-424b-832b-537fa1b17bec'
  | '776cc14f-a3e2-472b-9951-85d7f5b8526c'
  | '6116956a-024a-493d-b8b9-75aba601714d'
  | '640acdc7-3d04-4498-b17a-7f7a8a8170a2'
  | '983cad50-00af-4da3-ae98-fce734657518'
  | '64c4e94e-867f-45d7-bc6b-5101b7993ae9'
  | 'a04b8931-39e2-4507-b80e-eb08ce6cc83d'
  | '7260499e-c443-490a-9cd8-513994c774a1'
  | 'd6400178-4613-45e0-b785-3374078c54ff3'
  | 'fbe4cea5-8bca-4b01-aa8c-79a270bb01e3'
  | '254e4ee9-5208-455a-aa20-984381a0ace8'
  | 'ca4bd103-5c26-4481-9b63-df7956aeb40d'
  | 'd2391928-167f-41af-b341-8c94bf6986db'
  | 'd50a93cf-61d9-48db-907b-72e5b8f02c78'
  | 'ecadd111-8a71-4330-8685-cbdefe8ee139'
  | 'a8547e0a-8b80-4381-bf5e-597bf94cbd86'
  | '96b433a3-f60a-4d75-b5d2-5d77ad7ee613'
  | '714566b1-bef4-4011-bba1-e7ee8ec1c257'
  | '69f9e4c1-3203-49af-8176-c1b18d212877'
  | '86a8a9b8-c783-4f73-a452-1a7792ca4eb0'

export interface DecoratedTask {
  alwaysShow?: boolean
  hideWhenComplete?: boolean
  pathname?: string
  message?: string
  action?: Action
}

export interface DecoratedCollection extends DecoratedTask {
  collection: VerificationCollection
}

export interface DecoratedVerification extends DecoratedTask {
  verification: Verification
}

export enum Action {
  Redirect = 'REDIRECT',
  IDD = 'IDD',
  None = 'NONE',
}
