import { User } from '@acre/graphql'

import { createMockCreator } from './createMockCreator'

const defaultMockUsersOrg = {
  id: '18bd58bb-7619-4e5e-a76a-02eddf93ce94',
  organisation_id: '18bd58bb-7619-4e5e-a76a-02eddf93ce94',
  name: 'Acre Software',
  administered_by: null,
  representative_of_org: {
    id: '21988214-38a5-4c7e-a533-24a5720c3791',
    organisation_id: '21988214-38a5-4c7e-a533-24a5720c3791',
    external_id: 'acre.software/0001',
    name: 'Acre Network Ltd [Test]',
    complaints_url: 'https://acresoftware.com/how-to-complain.pdk',
    complaints_phone: '+443450456800',
    complaints_address: {
      address1: 'Empress House',
      address2: 'St Thomas Road',
      address3: null,
      posttown: 'Huddersfield',
      county: null,
      postcode: 'HD1 3LG',
      country: 'GB',
      __typename: 'LutherAddress' as 'LutherAddress',
    },
    primary_address: {
      address1: 'Empress House',
      address2: 'St Thomas Road',
      address3: null,
      posttown: 'Huddersfield',
      county: null,
      postcode: 'HD1 3LG',
      country: 'GB',
      __typename: 'LutherAddress' as 'LutherAddress',
    },
  },
  metadata: [],
  panel_management: {},
}

const defaultMockUser = {
  email_address: 'firm_manager@acre.software',
  first_name: 'Sharon',
  id: '08c1d440-dfed-40c7-a15a-b83bb45c0cf5',
  last_name: 'Blair',
  group_ids: ['test_group_id_1', 'test_group_id_3'],
  organisation_id: '18bd58bb-7619-4e5e-a76a-02eddf93ce94',
  primary_role_id: '90997f32-8118-4aa9-bbf8-10d6f688e999',
  organisation: defaultMockUsersOrg,
  metadata: [],
  client_record: [
    {
      id: '092a6c67-4942-4bb0-94cc-9426ea07aec4',
      details: {
        id: '092a6c67-4942-4bb0-94cc-9426ea07aec4',
        client_originator: '1a1270bc-69f1-4ace-89f8-336828251e6e',
      },
    },
  ],
}

const mutateUser = (mockUser: Partial<User>) => {
  return { ...mockUser, organisation: defaultMockUsersOrg }
}

export const createUserMock = createMockCreator<User>(defaultMockUser, mutateUser)

export const createMockUsersOrg = createMockCreator<User>(defaultMockUsersOrg)
