import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@acre/utils'
import { Button, Radio, RadioGroup } from '@acre/design-system'

import { getCaseOwnersOptions, validate } from './CaseOwnersFilterForm.helpers'
import {
  CaseListCaseOwnersFilterType,
  CaseListCaseOwnersState,
  CaseListOwnersFormFields,
} from './CaseOwnersFilterForm.types'

type Props = {
  loading: boolean
  onSubmit: (formState: CaseListCaseOwnersFilterType) => void
  caseOwnersState: CaseListCaseOwnersState
  initialValues: CaseListCaseOwnersFilterType
}

const CaseOwnersFilterForm = ({ caseOwnersState, loading, onSubmit, initialValues }: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()

  const filterOptions = useMemo(
    () => [
      // Prepend the default 'All' option
      { label: formatMessage('cases.table.filters.all'), value: 'All' },
      ...getCaseOwnersOptions(caseOwnersState.caseOwners),
    ],
    [],
  )

  const initialValuesFallback = { filter_owner_user_id: 'All' }
  const handleSubmit = (state: { filter_owner_user_id: string }) => {
    if (state.filter_owner_user_id === 'All') {
      return onSubmit({ filter_owner_user_id: '' })
    }
    return onSubmit(state)
  }

  return (
    <Box p={theme.spacers.size16}>
      <Form
        initialValues={initialValues.filter_owner_user_id ? initialValues : initialValuesFallback}
        validate={validate}
        onSubmit={(state) => handleSubmit(state)}
        subscription={{ submitting: true, pristine: true }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={theme.spacers.size16}>
              <RadioGroup>
                {filterOptions.map((field, index) => (
                  <Field key={index} name={CaseListOwnersFormFields.OwnerUserId} type="radio" value={field.value}>
                    {({ input }) => <Radio {...input} id={`FilterCaseOwners${index}`} label={field.label} />}
                  </Field>
                ))}
              </RadioGroup>
            </Box>

            <Button id="ApplyCaseOwnerFilters" type="submit" buttonStyle="secondary" isLoading={loading} fullWidth>
              <FormattedMessage id="cases.table.filters.applyFilters" />
            </Button>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default CaseOwnersFilterForm
