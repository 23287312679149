export const addParametersToCacheKey = (parameters: Record<string, unknown>, cacheKey: string) => {
  const paramString = Object.keys(parameters).reduce(
    (acc, key) => `${acc}${acc.length ? ',' : ''}${key}=${parameters[key]}`,
    '',
  )

  if (cacheKey.endsWith(')')) {
    return `${cacheKey.substr(0, cacheKey.length - 1)},${paramString})`
  }

  return `${cacheKey}(${paramString})`
}
