import { FieldReadFunction } from '@apollo/client'

import { formatAsCurrency, ReportsRevenueResponse, ReportsRevenueRow, RevenueTotals } from '@acre/graphql'

const read: FieldReadFunction<RevenueTotals> = (_, { readField, variables }) => {
  const revenueReportResponse = readField<ReportsRevenueResponse>({
    fieldName: 'reportsRevenue',
    args: variables?.body ? { body: variables.body } : {},
  })

  const totals = {} as RevenueTotals['totals']

  revenueReportResponse?.totals.by_year_month?.forEach((total) => {
    if (total.id) {
      totals[total.id] = formatAsCurrency(total?.value || 0)
    }
  })

  const grandTotal = revenueReportResponse?.results?.reduce(
    (acc: number, cur: ReportsRevenueRow) => (cur.value ? acc + cur.value : acc),
    0,
  )

  return { totals: { ...(totals || []), total: formatAsCurrency(grandTotal || 0) } }
}

export default {
  read,
}
