import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface FormSubmitActionClasses {
  root: string
}

export type FormSubmitActionClassKey = keyof FormSubmitActionClasses

export function getFormSubmitActionUtilityClass(slot: string) {
  return generateUtilityClass('FormSubmitAction', slot)
}

const formSubmitActionClasses: FormSubmitActionClasses = generateUtilityClasses('FormSubmitAction', ['root'])

export default formSubmitActionClasses
