import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { isFuture, isToday } from 'date-fns'

import { formatDigits } from '@acre/utils'

import useFormatMessage from '../../hooks/useFormatMessage'
import { Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { isNumberWithinParameters } from '../DateInput/DateInput.helpers'
import { Legend } from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import { TimeSegment } from './TimeInput.helpers'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { TimeFieldset, TimeInputField, TimeInputSection } from './TimeInput.styles'

export type TimeInputProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  id: string
  label?: string
  variant?: Variant
  value?: string
  deadline?: string | undefined
  customValue?: string
}

const TimeInput = ({ onChange, id, label, variant, value, deadline, customValue }: TimeInputProps) => {
  const formatMessage = useFormatMessage()

  const [hours, setHours] = useState<string | null>(null)
  const [minutes, setMinutes] = useState<string | null>(null)

  const minutesInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // If updating an existing note, set the time on mount
    if (value) {
      setHours(value.substring(0, 2))
      setMinutes(value.substring(3, 5))
    }
  }, [])

  // Sends time value in correct format up to parent component
  useEffect(() => {
    if (hours && onChange)
      onChange({
        target: { value: `${formatDigits(hours)}:${formatDigits(minutes)}` },
      } as ChangeEvent<HTMLInputElement>)
  }, [hours, minutes, onChange])

  // If custom value is set from parent, set time to this value
  // If no due time has been set yet, set time to one hour from now if date deadline is today,
  // and set time to 9am if date is later in the future
  useEffect(() => {
    const deadlineAsDate = new Date(deadline as string)
    const dateNow = new Date()
    const blankTimeField = !value || !(hours && minutes)
    if (customValue) {
      setHours(customValue.substring(0, 2))
      setMinutes(customValue.substring(3, 5))
    } else if (blankTimeField && isToday(deadlineAsDate)) {
      const oneHourFromNow = dateNow.getHours() + 1
      const mins = dateNow.getMinutes().toString()
      setHours(oneHourFromNow.toString())
      setMinutes(formatDigits(mins))
    } else if (blankTimeField && isFuture(deadlineAsDate)) {
      setHours('09')
      setMinutes('00')
    }
  }, [deadline])

  const handleTimeInputChange = (event: ChangeEvent<HTMLInputElement>, segment: TimeSegment) => {
    const { value } = event.target
    if (segment === TimeSegment.HOURS && (isNumberWithinParameters(value, 2, 23) || value === '')) {
      setHours(value)
      if (value.length === 2) minutesInput?.current?.focus()
    }
    if (segment === TimeSegment.MINUTES && (isNumberWithinParameters(value, 2, 59) || value === '')) {
      setMinutes(value)
    }
  }

  const TimeSeparator = () => <span style={{ display: 'flex', alignItems: 'center' }}>:</span>

  return (
    <MatomoWrapper onChange={onChange} id={id} eventType="timeInputChange" trackEventTrigger="onBlur">
      <TimeFieldset>
        <LabelAndInputWrapper variant={variant}>
          {label && <Legend text={label} />}
          <TimeInputField variant={variant}>
            <TimeInputSection
              placeholder="hh"
              value={hours || ''}
              onChange={(e) => handleTimeInputChange(e, TimeSegment.HOURS)}
              data-testid={`${testHandle(id)}HH`}
              aria-label={formatMessage('generic.hours')}
            />
            <TimeSeparator />
            <TimeInputSection
              ref={minutesInput}
              placeholder="mm"
              value={minutes || ''}
              onChange={(e) => handleTimeInputChange(e, TimeSegment.MINUTES)}
              data-testid={`${testHandle(id)}MM`}
              aria-label={formatMessage('generic.mins')}
            />
          </TimeInputField>
        </LabelAndInputWrapper>
      </TimeFieldset>
    </MatomoWrapper>
  )
}

export default TimeInput
