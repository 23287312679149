export enum PermissionAssignmentName {
  USER_CREATE_USER = 'user/create_user',
  ACRE_ORGANISATION_CREATE_REG_ORG = 'acre/organisation/create_reg_org',
  ORGANISATION_CREATE_AR_ORG = 'organisation/create_ar_org',
  ORGANISATION_CREATE_SUBDIVISION_ORG = 'organisation/create_subdivision_org',
  ORGANISATION_EDIT_ORG = 'organisation/edit_org',
  ORGANISATION_EDIT_METADATA = 'organisation/edit_metadata',
  ORGANISATION_EDIT_SYSTEM_METADATA = 'organisation/edit_system_metadata',
  ORGANISATION_EDIT_FEES = 'organisation/edit_fees',
  ORGANISATION_REPORTING = 'organisation/reporting',
  PERMISSIONS_VIEW_PERMISSIONS = 'permissions/view_permissions',
  USER_VIEW = 'user/view',
  USER_EDIT_USER = 'user/edit_user',
  USER_EDIT_FEES = 'user/edit_fees',
  USER_EDIT_AUTHENTICATION = 'user/edit_authentication',
  USER_EDIT_USER_METADATA = 'user/edit_user_metadata',
  USER_EDIT_SYSTEM_METADATA = 'user/edit_system_metadata',
  USER_EDIT_ORG_DATA = 'user/edit_org_data',
  ORGANISATION_VIEW = 'organisation/view',
  ORGANISATION_EDIT_PROTECTED = 'organisation/edit_protected',
  ORGANISATION_CREATE_INTRODUCER_ORG = 'organisation/create_introducer_org',
  USER_VIEW_FEES = 'user/view_fees',
  CLIENT_VIEW_CLIENT = 'client/view_client',
  CLIENT_VIEW_PROTECTED = 'client/view_protected',
  CLIENT_EDIT_CLIENT = 'client/edit_client',
  CLIENT_EDIT_CLIENT_RESTRICTED = 'client/edit_client_restricted',
  CASE_CHANGE_OWNER = 'case/change_owner',
  CASE_VIEW_CASE = 'case/view_case',
  CASE_EDIT_CASE = 'case/edit_case',
  CASE_CREATE_ON_BEHALF_OF = 'case/create_on_behalf_of',
  GROUP_VIEW_GROUP = 'group/view_group',
  GROUP_EDIT_GROUP = 'group/edit_group',
  ACRE_ROLE_EDIT_ROLES = 'acre/role/edit_roles',
  ACRE_PERMISSIONS_EDIT_ROLES_PERMISSION_ASSIGNMENTS = 'acre/permissions/edit_roles_permission_assignments',
  PERMISSIONS_EDIT_REGULATED_PERMISSION = 'permissions/edit_regulated_permission',
  PERMISSIONS_EDIT_PERMISSION = 'permissions/edit_permission',
  PERMISSIONS_EDIT_ACRE_PERMISSIONS = 'permissions/edit_acre_permissions',
  CLIENT_VIEW_MORTGAGE = 'client/view_mortgage',
  CLIENT_VIEW_PROTECTION_INSURANCE = 'client/view_protection_insurance',
  REGULATED_MORTGAGE_PROVISIONAL = 'regulated/mortgageprovisional',
  REGULATED_MORTGAGE = 'regulated/mortgage',
  REGULATED_EQUITYRELEASE_PROVISIONAL = 'regulated/equityreleaseprovisional',
  REGULATED_EQUITYRELEASE = 'regulated/equityrelease',
  REGULATED_PROTECTIONINSURANCE_PROVISIONAL = 'regulated/protectioninsuranceadvisedprovisional',
  REGULATED_PROTECTIONINSURANCE = 'regulated/protectioninsuranceadvised',
  REGULATED_GENERALINSURANCE_PROVISIONAL = 'regulated/generalinsuranceprovisional',
  REGULATED_GENERALINSURANCE = 'regulated/generalinsurance',
  REGULATED_COMPLIANCE_VIEWCOMPLIANCEDASHBOARD = 'regulated/viewcompliancedashboard',
  REGULATED_COMPLIANCE_VIEWCASEDETAIL = 'regulated/compliance/viewcasedetail',
  REGULATED_COMPLIANCE_APPROVECASES = 'regulated/compliance/approvecases',
  REGULATED_COMPLIANCE_APPROVEADVICE = 'regulated/compliance/approveadvice',
  REGULATED_COMPLIANCE_APPROVEEIDVOVERRIDE = 'regulated/compliance/approveeidvoverride',
  REGULATED_COMPLIANCE_APPROVEAML = 'regulated/compliance/approveaml',
  REGULATED_COMPLIANCE_ADDCASENOTES = 'regulated/compliance/addcasenotes',
  REGULATED_COMPLIANCE_VIEWCASENOTES = 'regulated/compliance/viewcasenotes',
  REGULATED_DEBTCONSOLIDATION = 'regulated/debtconsolidation',
  FIRM_MANAGETEMPLATES = 'firm/managetemplates',
  ACCOUNTING = 'finance/view_case_ledger',
  DELETE_LEDGER = 'finance/delete_ledger',
  CREATE_CASE_LEDGER = 'finance/create_case_ledger',
  EDIT_CASE_LEDGER = 'finance/edit_case_ledger',
  INVALID_PERMISSION_ASSIGNMENT_NAME = 'permissions/invalid-permission',
  MANAGE_ORGANISATION = 'organisation/manage_organisation',
  ACRE_CASE_IMPORT = 'acre/case_import',
}

export enum GateName {
  VIEW_CLIENT_SECTION = 'VIEW_CLIENT_SECTION',
  VIEW_CASE_SECTION = 'VIEW_CASE_SECTION',
  VIEW_BROKER_DASHBOARD = 'VIEW_BROKER_DASHBOARD',
  VIEW_MI_DASHBOARD = 'VIEW_MI_DASHBOARD',
  EDIT_CLIENT_PROFILE_ALL = 'EDIT_CLIENT_PROFILE_ALL',
  EDIT_USER_STATUS_COMPANY_SETTINGS = 'EDIT_USER_STATUS_COMPANY_SETTINGS',
  EDIT_CLIENT_VERIFY_IDD = 'EDIT_CLIENT_VERIFY_IDD',
  EDIT_CASE_ADD_NEW = 'EDIT_CASE_ADD_NEW',
  EDIT_CLIENT_UPLOAD_DOCUMENT = 'EDIT_CLIENT_UPLOAD_DOCUMENT',
  EDIT_CASE_VERIFY_ACCEPTANCE = 'EDIT_CASE_VERIFY_ACCEPTANCE',
  EDIT_CASE_GENERATE_ILLUSTRATION = 'EDIT_CASE_GENERATE_ILLUSTRATION',
  EDIT_CASE_UPLOAD_DOCUMENT = 'EDIT_CASE_UPLOAD_DOCUMENT',
  EDIT_CASE_SUITABILITY_DETAILS = 'EDIT_CASE_SUITABILITY_DETAILS',
  EDIT_CASE_REQUIREMENTS = 'EDIT_CASE_REQUIREMENTS',
  EDIT_CASE_GENERATE_REASONS_WHY = 'EDIT_CASE_GENERATE_REASONS_WHY',
  EDIT_ORGANISATION_GENERAL_INSURANCE_COMPANY_SETTINGS = 'EDIT_ORGANISATION_GENERAL_INSURANCE_COMPANY_SETTINGS',
  EDIT_ORGANISATION_COMPANY_INFORMATION_COMPANY_SETTINGS = 'EDIT_ORGANISATION_COMPANY_INFORMATION_COMPANY_SETTINGS',
  EDIT_ORGANISATION_MORTGAGE_FEES_COMPANY_SETTINGS = 'EDIT_ORGANISATION_MORTGAGE_FEES_COMPANY_SETTINGS',
  ORGANISATION_CREATE_AR_ORG = 'ORGANISATION_CREATE_AR_ORG',
  VIEW_CASE_COMPLIANCE = 'VIEW_CASE_COMPLIANCE',
  VIEW_CASE_FLAGS = 'VIEW_CASE_FLAGS',
  EDIT_CASE_FLAGS = 'EDIT_CASE_FLAGS',
  CASE_CHANGE_OWNER = 'CASE_CHANGE_OWNER',
  VIEW_CASE_EIDV = 'VIEW_CASE_EIDV',
  EDIT_CASE = 'EDIT_CASE',
  EDIT_CLIENT = 'EDIT_CLIENT',
  EDIT_CLIENT_RESTRICTED = 'EDIT_CLIENT_RESTRICTED',
  VIEW_OVERRIDE_CLIENT_EIDV = 'VIEW_OVERRIDE_CLIENT_EIDV',
  EDIT_GENERAL_INSURANCE = 'EDIT_GENERAL_INSURANCE',
  EDIT_GENERAL_INSURANCE_PROVISIONAL = 'EDIT_GENERAL_INSURANCE_PROVISIONAL',
  ORGANISATION_EDIT_PROTECTED = 'ORGANISATION_EDIT_PROTECTED',
  ORGANISATION_CREATE_INTRODUCER_ORG = 'ORGANISATION_CREATE_INTRODUCER_ORG',
  USER_CREATE_USER = 'USER_CREATE_USER',
  ORGANISATION_EDIT_ORG = 'ORGANISATION_EDIT_ORG',
  ORGANISATION_VIEW = 'ORGANISATION_VIEW',
  ORGANISATION_REPORTING = 'ORGANISATION_REPORTING',
  ORGANISATION_EDIT_METADATA = 'ORGANISATION_EDIT_METADATA',
  ORGANISATION_EDIT_SYSTEM_METADATA = 'ORGANISATION_EDIT_SYSTEM_METADATA',
  ACCOUNTING = 'ACCOUNTING',
  DELETE_LEDGER = 'DELETE_LEDGER',
  CREATE_CASE_LEDGER = 'CREATE_CASE_LEDGER',
  EDIT_CASE_LEDGER = 'EDIT_CASE_LEDGER',
  ACRE_ORGANISATION_CREATE_REG_ORG = 'ACRE_ORGANISATION_CREATE_REG_ORG',
  REGULATED_MORTGAGE_PROVISIONAL = 'REGULATED_MORTGAGE_PROVISIONAL',
  REGULATED_MORTGAGE = 'REGULATED_MORTGAGE',
  REGULATED_EQUITYRELEASE_PROVISIONAL = 'REGULATED_EQUITYRELEASE_PROVISIONAL',
  REGULATED_EQUITYRELEASE = 'REGULATED_EQUITYRELEASE',
  REGULATED_PROTECTIONINSURANCE_PROVISIONAL = 'REGULATED_PROTECTIONINSURANCE_PROVISIONAL',
  REGULATED_PROTECTIONINSURANCE = 'REGULATED_PROTECTIONINSURANCE',
  REGULATED_GENERALINSURANCE_PROVISIONAL = 'REGULATED_GENERALINSURANCE_PROVISIONAL',
  REGULATED_GENERALINSURANCE = 'REGULATED_GENERALINSURANCE',
  REGULATED_DEBTCONSOLIDATION = 'REGULATED_DEBTCONSOLIDATION',
  REGULATED_COMPLIANCE_VIEWCASEDETAIL = 'REGULATED_COMPLIANCE_VIEWCASEDETAIL',
  REGULATED_COMPLIANCE_APPROVECASES = 'REGULATED_COMPLIANCE_APPROVECASES',
  REGULATED_COMPLIANCE_APPROVEAML = 'REGULATED_COMPLIANCE_APPROVEAML',
  REGULATED_COMPLIANCE_APPROVEADVICE = 'REGULATED_COMPLIANCE_APPROVEADVICE',
  REGULATED_COMPLIANCE_APPROVEEIDVOVERRIDE = 'REGULATED_COMPLIANCE_APPROVEEIDVOVERRIDE',
  REGULATED_COMPLIANCE_ADDCASENOTES = 'REGULATED_COMPLIANCE_ADDCASENOTES',
  PERMISSIONS_EDIT_REGULATED_PERMISSION = 'PERMISSIONS_EDIT_REGULATED_PERMISSION',
  REGULATED_COMPLIANCE_VIEWCASENOTES = 'REGULATED_COMPLIANCE_VIEWCASENOTES',
  PERMISSIONS_VIEW_PERMISSIONS = 'PERMISSIONS_VIEW_PERMISSIONS',
  FIRM_MANAGETEMPLATES = 'FIRM_MANAGETEMPLATES',
  USER_EDIT_USER_METADATA = 'USER_EDIT_USER_METADATA',
  GROUP_EDIT_GROUP = 'GROUP_EDIT_GROUP',
  GROUP_VIEW_GROUP = 'GROUP_VIEW_GROUP',
  PERMISSIONS_EDIT_PERMISSION = 'PERMISSIONS_EDIT_PERMISSION',
  USER_EDIT_AUTHENTICATION = 'USER_EDIT_AUTHENTICATION',
  MANAGE_ORGANISATION = 'MANAGE_ORGANISATION',
  USER_EDIT_USER = 'USER_EDIT_USER',
  CLIENT_VIEW_PROTECTED = 'CLIENT_VIEW_PROTECTED',
  ACRE_CASE_IMPORT = 'ACRE_CASE_IMPORT',
}

export enum PermissionScopeType {
  organisation = 'organisation',
  case = 'case',
  client = 'client',
  user = 'user',
}

export type EntityIdsCollection = {
  [PermissionScopeType.organisation]?: string[]
  [PermissionScopeType.user]?: string[]
  [PermissionScopeType.case]?: string[]
  [PermissionScopeType.client]?: string[]
}

export type ResolvedPermissionResolution = {
  gate: GateName
  permission_name: string
  has_permission: boolean
  scope: {
    id: string
    type: string
  }
  assignee?: {
    id: string
  }
}
