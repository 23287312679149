import { camelCase } from 'lodash'

import {
  CanBeAddedToLoan,
  currentMortgageProductFeeTypes,
  MortgageProductFee,
  MortgageProductFeeType,
} from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

export const messagePrefix = 'mortgageProduct.feeTypes'

export const canBeAddedPrefix = 'mortgageProduct.canBeAddedToLoan'

export const parseFn = (value: string) => (value === '' ? null : value)

export const options = [
  CanBeAddedToLoan.MustBeAdded,
  CanBeAddedToLoan.OptionToBeAdded,
  CanBeAddedToLoan.PaySeparately,
].map((value) => ({
  label: `${canBeAddedPrefix}.${camelCase(value)}`,
  value,
}))

export const calculateTotalFees = (fees?: MortgageProductFee[]) =>
  ((fees || []) as MortgageProductFee[])
    .reduce((acc, { amount }) => {
      return amount ? acc + parseFloat(amount) : acc
    }, 0)
    .toString()

export const getFeeTypeOptions = (feeTypes: MortgageProductFeeType[]) =>
  feeTypes.reduce((acc, feeType) => {
    if (
      [
        MortgageProductFeeType.InvalidFeeType,
        MortgageProductFeeType.EarlyRepaymentCharge,
        MortgageProductFeeType.ChapsFee,
        MortgageProductFeeType.HomebuyersFee,
        MortgageProductFeeType.DisbursementFee,
      ].includes(feeType)
    ) {
      return acc
    }
    return [
      ...acc,
      {
        value: feeType,
        label: `${messagePrefix}.${camelCase(feeType)}`,
      },
    ]
  }, [] as DropdownOption[])

export const allFeeTypeOptions = getFeeTypeOptions(Object.values(MortgageProductFeeType))
export const currentProductfeeTypeOptions = getFeeTypeOptions(currentMortgageProductFeeTypes)
