import { useRef } from 'react'
import { Config, createForm, FormApi } from 'final-form'

const useFormApiRef = <FV extends unknown>({ config, skip }: { config: Config<FV, Partial<FV>>; skip?: boolean }) => {
  const formApiRef = useRef<FormApi<FV> | null>(null)

  if (!formApiRef.current && !skip) {
    formApiRef.current = createForm<FV>(config)
  }

  return formApiRef
}

export default useFormApiRef
