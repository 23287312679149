import { Box } from '@mui/material'
import { Field, useFormState } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { getFormMetaErrors, useFormatMessage } from '@acre/utils'
import { Maybe, PremiumFrequency } from '@acre/graphql'
import { CurrencyInput, Dropdown, Radio, RadioGroup, TextInput, Variant } from '@acre/design-system'
import { DateFieldFF } from '@acre/forms-ui'

import {
  CommissionStartsIn,
  CommissionType,
  getRenewalCommissionStartsInOptions,
  messagePrefix,
} from './ProtectionCommissionFields.helpers'
import { Div, FieldContainer } from './ProtectionCommissionFields.styles'

type Props = {
  variant?: Variant
  fieldLayout?: 'horizontal' | 'vertical'
  fieldSize?: 'medium' | 'large'
  premiumFrequency?: Maybe<PremiumFrequency> | undefined
}
const ProtectionCommissionFields = ({ fieldSize, fieldLayout, variant, premiumFrequency }: Props) => {
  const formVals = useFormState().values
  const intl = useIntl()
  const formatMessage = useFormatMessage()
  const renewalCommissionStartsInOptions = getRenewalCommissionStartsInOptions(formatMessage)

  return (
    <Box mb={1}>
      <FieldContainer variant={variant}>
        <Field name="underwritten_premium_amount">
          {({ input, meta }) => (
            <CurrencyInput
              {...input}
              {...getFormMetaErrors({
                meta,
                intl,
              })}
              id="UnderwrittenPremiumAmount"
              label={formatMessage(
                `${
                  premiumFrequency === PremiumFrequency.PremiumFrequencyAnnual
                    ? `${messagePrefix}.underwrittenPremiumAmountAnnually`
                    : `${messagePrefix}.underwrittenPremiumAmountMonthly`
                }`,
              )}
              variant={variant}
            />
          )}
        </Field>
      </FieldContainer>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <FieldContainer variant={variant}>
          <Field name="policy_number">
            {({ input, meta }) => (
              <TextInput
                {...input}
                {...getFormMetaErrors({
                  meta,
                  intl,
                })}
                id="PolicyNumber"
                label={formatMessage(`${messagePrefix}.policyNumber`)}
                variant={variant}
              />
            )}
          </Field>
        </FieldContainer>
        <FieldContainer variant={variant}>
          <DateFieldFF
            name="start_date"
            label={<FormattedMessage id={`${messagePrefix}.policyStartDate`} />}
            layout={fieldLayout === 'horizontal' ? 'row' : 'column'}
            size={fieldSize}
          />
        </FieldContainer>
        <Box my={variant === 'compactLeftAligned' ? 1 : 2} width="100%">
          <RadioGroup id="CommissionType" label={formatMessage(`${messagePrefix}.commissionType`)} variant={variant}>
            <Field name="commission_type" type="radio" value={CommissionType.Indemnified}>
              {({ input }) => (
                <Radio {...input} label={formatMessage(`${messagePrefix}.indemnified`)} variant={variant} />
              )}
            </Field>
            <Field name="commission_type" type="radio" value={CommissionType.NonIndemnified}>
              {({ input }) => (
                <Radio {...input} label={formatMessage(`${messagePrefix}.nonIndemnified`)} variant={variant} />
              )}
            </Field>
          </RadioGroup>
        </Box>

        <FieldContainer variant={variant}>
          <Field name="initial_commission">
            {({ input, meta }) => (
              <CurrencyInput
                {...input}
                {...getFormMetaErrors({
                  meta,
                  intl,
                })}
                id="InitialCommissionAmount"
                label={formatMessage(`${messagePrefix}.initialCommissionAmount`)}
                variant={variant}
              />
            )}
          </Field>
        </FieldContainer>
        {formVals.commission_type === CommissionType.NonIndemnified && (
          <FieldContainer variant={variant}>
            <Field name="number_of_months_received_initial_commission" type="number">
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  {...getFormMetaErrors({
                    includeTouched: false,
                    meta,
                    intl,
                  })}
                  id="NumberOfMonthsReceivedFor"
                  label={formatMessage(`${messagePrefix}.numberOfMonthsReceivedInitialCommission`)}
                  variant={variant}
                />
              )}
            </Field>
          </FieldContainer>
        )}
      </Box>

      <Div />

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <FieldContainer variant={variant}>
          <Field name="renewal_commission">
            {({ input, meta }) => (
              <CurrencyInput
                {...input}
                {...getFormMetaErrors({
                  meta,
                  intl,
                })}
                id="RenewalCommissionAmount"
                label={formatMessage(`${messagePrefix}.renewalCommissionAmount`)}
                variant={variant}
              />
            )}
          </Field>
        </FieldContainer>

        <FieldContainer variant={variant}>
          <Field name="renewal_commission_starts_in">
            {({ input }) => (
              <Dropdown
                {...input}
                id="RenewalCommissionStartsIn"
                options={renewalCommissionStartsInOptions}
                label={formatMessage(`${messagePrefix}.renewalCommissionStartsIn`)}
                defaultValue="HIDE"
                variant={variant}
              />
            )}
          </Field>
        </FieldContainer>
        <FieldContainer variant={variant}>
          {formVals.renewal_commission_starts_in === CommissionStartsIn.SpecificDate ? (
            <DateFieldFF
              name="renewal_commission_start_date"
              label={<FormattedMessage id={`${messagePrefix}.renewalCommissionStartDate`} />}
              layout={fieldLayout === 'horizontal' ? 'row' : 'column'}
              size={fieldSize}
            />
          ) : (
            <Field name="number_of_months_renewal_commission_starts_in" type="number">
              {({ input, meta }) => {
                const label = formatMessage(`${messagePrefix}.numberOfMonthsRenewalCommissionStartsIn`)
                return (
                  <TextInput
                    id="NumberOfMonthsRenewalCommissionStartsIn"
                    {...input}
                    {...getFormMetaErrors({
                      includeTouched: false,
                      meta,
                      intl,
                      messageInsert: { ...meta.error?.values, label },
                    })}
                    label={label}
                    variant={variant}
                  />
                )
              }}
            </Field>
          )}
        </FieldContainer>
      </Box>
    </Box>
  )
}

export default ProtectionCommissionFields
