import { pick } from 'lodash'

import { Maybe, Mortgage, MortgageReason, RelatedUserRelationshipType } from '@acre/graphql'

type MortgageAndMortgageCalculatedValues = 'monthly_payment' | 'revert_monthly_payment'

export const pickMortgageOrMortgageCalculatedValue = (
  calculatedValuesKey: MortgageAndMortgageCalculatedValues,
  mortgage?: Maybe<Mortgage>,
) => {
  if (!mortgage) return null
  // properties on mortgage
  const mortgageObject = pick(mortgage, calculatedValuesKey)
  // properties on caculated_values
  const calculatedValuesObject = pick(mortgage?.calculated_values, calculatedValuesKey)
  // calculated values should be prioritised
  return calculatedValuesObject[calculatedValuesKey] ?? (mortgageObject[calculatedValuesKey] || null)
}

export const pickMortgageOrMortgageCalculatedValues = (
  calculatedValuesKeys: MortgageAndMortgageCalculatedValues[],
  mortgage?: Maybe<Mortgage>,
) => {
  if (!mortgage) return null
  return calculatedValuesKeys.reduce(
    (
      acc: {
        [key in MortgageAndMortgageCalculatedValues]: string | undefined | null
      },
      mortgageKey: MortgageAndMortgageCalculatedValues,
    ) => {
      return {
        ...acc,
        [mortgageKey]: pickMortgageOrMortgageCalculatedValue(mortgageKey, mortgage),
      }
    },
    {} as {
      [key in MortgageAndMortgageCalculatedValues]: string | undefined | null
    },
  )
}

export const PREFERENCE_MAP: { [key in MortgageReason]: string } = {
  [MortgageReason.InvalidMortgageReason]: 'productTypes.noReasonProvided',
  [MortgageReason.ReasonHouseMove]: 'productTypes.houseMove',
  [MortgageReason.ReasonRemortgage]: 'productTypes.remortgage',
  [MortgageReason.ReasonBtl]: 'productTypes.buyToLet',
  [MortgageReason.ReasonBtlRemortgage]: 'productTypes.buyToLetRemortgage',
  [MortgageReason.ReasonFtb]: 'productTypes.firstTimeBuy',
  [MortgageReason.ReasonEquityRelease]: 'productTypes.equityRelease',
  [MortgageReason.ReasonFurtherAdvance]: 'productTypes.furtherAdvance',
  [MortgageReason.ReasonBusinessProtection]: 'productTypes.businessProtection',
  [MortgageReason.ReasonProtection]: 'productTypes.protection',
  [MortgageReason.ReasonCommercial]: 'productTypes.commercial',
  [MortgageReason.ReasonBridging]: 'productTypes.bridging',
  [MortgageReason.ReasonGeneralInsurance]: 'productTypes.homeInsurance',
}

export const RELATION_MAP: { [key in RelatedUserRelationshipType]: string } = {
  [RelatedUserRelationshipType.InvalidRelationshipType]: 'generic.notFound',
  [RelatedUserRelationshipType.Referrer]: 'relationType.referrer',
  [RelatedUserRelationshipType.Administrator]: 'relationType.administrator',
  [RelatedUserRelationshipType.LeadQualifier]: 'relationType.leadQualifier',
  [RelatedUserRelationshipType.CaseManager]: 'relationType.caseManager',
  [RelatedUserRelationshipType.Watcher]: 'relationType.watcher',
  [RelatedUserRelationshipType.Reviewer]: 'relationType.reviewer',
}
