import { useLocalStorage, useUserContext } from '@acre/utils'

export enum SidebarSection {
  VersionsNav = 'versionsNav',
  DocumentsNav = 'documentsNav',
  Tasks = 'tasks',
  PageNotes = 'pageNotes',
}

export const useSidebarDrawerSectionsLocalStorage = (section: SidebarSection) => {
  const user = useUserContext()

  const defaultValues = {
    sectionsOpen: {
      [section]: true,
    },
  }

  const storageKey = `${user?.id}-reviewModeDrawer-${section}`
  const [localValues, updateStorage] = useLocalStorage(storageKey, defaultValues)

  return { localValues, updateLocalStorage: updateStorage }
}
