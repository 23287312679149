import React, { ReactElement, useEffect, useMemo } from 'react'
import { useFormFieldsContext } from '@broker-crm-contexts'

type Props = {
  children: ReactElement[]
  payloadKey: string
}

const CollectFormFields = ({ children, payloadKey }: Props) => {
  // we collect fields to allow for dynamic selections of data
  // this collection of fields can be used if a form contains a
  // mix of data model objects' properties and we would otherwise
  // need to have a fixed object which "sorts" the submitted data

  const { dispatch } = useFormFieldsContext()
  const fieldNames: string[] = []

  // the field names will be stored with a specified key
  // we use the fact that the array reference is stored
  const payload = useMemo(
    () => ({
      payload: { [payloadKey]: fieldNames },
    }),
    [payloadKey],
  )

  useEffect(() => {
    // we use a stack which contains the children of this component, iterates through it,
    // and filters elements with the name attribute into an array of names
    let stack = React.Children.toArray(children) as ReactElement[]
    if (dispatch && children?.length && Array.isArray(stack) && stack.length) {
      while (stack.length > 0) {
        const child = stack.pop()
        // is the children property an array
        const hasMultipleChildren = Array.isArray(child?.props?.children)
        // is the children property an object
        const hasSingleChild =
          child?.props?.hasOwnProperty('children') &&
          child?.props?.children !== undefined &&
          child?.props?.children !== null &&
          typeof child?.props?.children === 'object'

        // if it has the name attribute then select it
        if (child?.props?.name) {
          fieldNames.push(child.props.name)
        }

        // if there is an object as a child then push it
        if (!child?.props?.name && hasSingleChild) {
          stack = [...stack, child?.props?.children]
        }

        // if there is an array as a child then spread it
        if (!child?.props?.name && hasMultipleChildren) {
          stack = [...stack, ...child.props.children]
        }
      }
      // store in a reducer's state
      dispatch && payload && dispatch(payload)
    }
  }, [payloadKey])

  return <>{children}</>
}

export default CollectFormFields
