import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

export const AccordionHeader = styled('h4')(({ theme }) => ({
  color: theme.colours.grey800,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 1.6,
}))

export const AccordionSubHeader = styled('h5')(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 1.6,
}))

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  boxShadow: 'none!important',
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosIcon />} {...props} />
))(({ theme }) => ({
  minHeight: 0,
  padding: 0,
  margin: 0,
  overflow: 'hidden',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '.MuiSvgIcon-root': {
    transform: 'rotate(90deg)',
    height: '16px',
    width: '16px',
  },
  '&:hover': {
    'h4, svg': {
      color: theme.colours.primary,
    },
  },
}))

export const AccordionDetails = styled((props: AccordionDetailsProps) => <MuiAccordionDetails {...props} />)(() => ({
  padding: '24px 0 0 0',
}))
