import React, { createContext, ReactNode, useContext, useMemo, useReducer } from 'react'

export type FormFieldsObjects = {
  [key: string]: string[]
}

export type FormFieldsContextType = {
  state: FormFieldsObjects
  dispatch?: React.Dispatch<{
    payload: FormFieldsObjects
  }>
}

const FormFieldsContext = createContext<FormFieldsContextType>({
  state: {},
})

export default FormFieldsContext
export const FormFieldsConsumer = FormFieldsContext.Consumer

export const useFormFieldsContext = () => useContext(FormFieldsContext)

function reducer(state: FormFieldsObjects, action: { payload: FormFieldsObjects }) {
  return {
    ...state,
    ...action.payload,
  }
}
const initialState = {}

export const FormFieldsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  )

  return <FormFieldsContext.Provider value={value}>{children}</FormFieldsContext.Provider>
}
