import React, { ChangeEvent, FocusEvent } from 'react'
import { Box, useTheme } from '@mui/material'
import classNames from 'classnames'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import testHandle from '../../utils/testHandle'
import { SwitchVariant } from './Switch.helpers'
import { StyledInput, StyledLabel } from './Switch.styles'

export type SwitchProps = {
  id: string
  checked: boolean
  label?: string
  disabled?: boolean
  displayLabel?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  variant?: SwitchVariant
}

const Switch = ({
  id,
  label,
  checked,
  disabled: disabledProp,
  displayLabel = true,
  onChange,
  onFocus,
  onBlur,
  variant = SwitchVariant.Medium,
}: SwitchProps) => {
  const theme = useTheme()
  const disabled = useFieldDisabledState(disabledProp)

  const className = classNames({
    checked,
    disabled,
    ['custom-checkbox']: true,
  })

  return (
    <Box display="flex" alignItems="center">
      <StyledInput
        id={id}
        theme={theme}
        type="checkbox"
        onBlur={onBlur}
        variant={variant}
        checked={checked}
        onFocus={onFocus}
        aria-label={label}
        disabled={disabled}
        onChange={onChange}
        className={className}
        data-testid={testHandle(id)}
        onClick={(e) => e.stopPropagation()}
      />

      {displayLabel ? (
        <StyledLabel
          htmlFor={id}
          theme={theme}
          id={`${id}Label`}
          checked={checked}
          variant={variant}
          className={className}
          data-testid={`${testHandle(id)}Label`}
        >
          {label}
        </StyledLabel>
      ) : null}
    </Box>
  )
}

export default withDisabled(Switch)
