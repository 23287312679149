import { Maybe } from '@acre/graphql'

export const getUserInitials = (first_name: string, last_name: string): string => {
  const firstLetter = first_name[0] || ''
  const secondLetter = last_name[0] || ''
  const initials = `${firstLetter}${secondLetter}`
  return initials
}

export const getUserFullName = (first_name: Maybe<string> = '', last_name: Maybe<string> = ''): string => {
  const fullName = `${first_name} ${last_name}`
  return fullName
}
