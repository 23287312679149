import React, { memo, ReactElement, useState } from 'react'
import { Box } from '@mui/material'

import { ButtonOverflow, ButtonTheme, ColourId } from '@acre/design-system'

type Props = {
  id: string
  isLoading?: boolean
  isDisabled?: boolean
  colourID?: ColourId
  children?: Array<ReactElement>
  buttonText?: string
  showText?: boolean
  style?: ButtonTheme
  overFlowMargin?: boolean
}

const GenericDeleteOverflowButtons = ({
  id,
  isLoading,
  isDisabled,
  colourID,
  children = [],
  showText,
  buttonText,
  style,
  overFlowMargin,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ButtonOverflow
      id={id}
      isOpen={isOpen}
      onClick={(e) => {
        e.stopPropagation()
        setIsOpen(true)
      }}
      onClose={() => setIsOpen(false)}
      isLoading={isLoading}
      disabled={isDisabled}
      variant="default"
      colourID={colourID}
      showText={showText}
      buttonText={buttonText ? buttonText : undefined}
      style={style}
      overflowMargin={overFlowMargin}
    >
      <Box display="flex" flexDirection="column" width="100%">
        {children}
      </Box>
    </ButtonOverflow>
  )
}

export default memo(GenericDeleteOverflowButtons)
