import Cookies from 'js-cookie'

import { STORAGE_COOKIE, STORAGE_LOCAL } from './constants'
import { Storage } from './customTypes'

export const setStorage = async (storageType: Storage, key: string, value: string) => {
  if (storageType === STORAGE_COOKIE) {
    return await Cookies.set(key, value)
  }
  if (storageType === STORAGE_LOCAL) {
    return await localStorage.setItem(key, value)
  }
}

export const getStorage = async (storageType: Storage, key: string) => {
  if (storageType === STORAGE_COOKIE) {
    return await Cookies.get(key)
  }
  if (storageType === STORAGE_LOCAL) {
    return await localStorage.getItem(key)
  }
}

export const removeStorage = async (storageType: Storage, key: string) => {
  if (storageType === STORAGE_COOKIE) {
    return await Cookies.remove(key)
  }
  if (storageType === STORAGE_LOCAL) {
    return await localStorage.removeItem(key)
  }
}
