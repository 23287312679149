import React, { useCallback, useState } from 'react'
import { alpha, Checkbox, CheckboxProps, FormHelperText, lighten, styled, unstable_composeClasses } from '@mui/material'
import { Box } from '@mui/material'
import classnames from 'classnames'

import { Size } from '../../utils/types'
import FeatherIcon, { IconName } from '../FeatherIcon'
import type { ExtendField, FieldOverrideProps, SimpleFieldProps, SimpleFieldWrapperProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import SimpleFieldWrapper from '../SimpleFieldWrapper/SimpleFieldWrapper'
import { getCheckboxFieldUtilityClass } from './checkboxFieldClasses'

export type CheckBoxFieldVariant = 'standard' | 'outlined'

export interface CheckboxFieldProps extends SimpleFieldProps, ExtendField<CheckboxProps> {
  isGrouped?: boolean
  variant?: CheckBoxFieldVariant
}

const CheckboxFieldRoot = styled(SimpleFieldWrapper, {
  name: 'CheckboxField',
  slot: 'Root',
})<SimpleFieldWrapperProps & { checked?: boolean; variant?: CheckBoxFieldVariant }>(({ theme, variant, checked }) => ({
  ...(variant === 'outlined' && {
    border: `1px solid ${theme.colours.base}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    width: '100%',
    marginLeft: 0,
    paddingLeft: theme.spacing(1),

    '&:hover:not(.Mui-disabled)': {
      backgroundColor: lighten(theme.palette.primary.light, 0.5),
    },

    ...(checked && {
      '&.Mui-disabled': {
        backgroundColor: alpha(theme.colours.baseLight, 0.1),
      },

      '&:not(.Mui-disabled)': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.colours.primary, 0.1),
      },
    }),
  }),
}))

const CheckboxFieldCheckbox = styled<typeof Checkbox, keyof Omit<CheckboxProps, 'size'>>(Checkbox, {
  name: 'CheckboxField',
  slot: 'Checkbox',
  overridesResolver: (props, styles) => `${styles.root}${props.size ? styles.size : ''}`,
})<FieldOverrideProps>(({ theme, size }) => ({
  ...(size === 'large' && {
    [`& .${getCheckboxFieldUtilityClass('icon')}`]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.display.borderRadius,

    },

    [`& .${getCheckboxFieldUtilityClass('iconChecked')}`]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.display.borderRadius,
    },
  }),
}))

const CheckboxFieldIcon = styled('span', {
  name: 'CheckboxField',
  slot: 'Icon',
  overridesResolver: (props, styles) => styles.icon,
})(({ theme }) => ({
  transition: 'all 0.1s ease-out',

  background: theme.colours.foreground,
  border: '1px solid',
  borderColor: theme.colours.baseLight,

  width: theme.spacing(2.4),
  height: theme.spacing(2.4),
  borderRadius: '0.3rem',
}))

const CheckboxFieldIconChecked = styled('span', {
  name: 'CheckboxField',
  slot: 'IconChecked',
  overridesResolver: (props, styles) => styles.iconChecked,
})(({ theme }) => ({
  transition: 'all 0.1s ease-out',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  background: theme.colours.primary,
  border: '1px solid',
  borderColor: theme.colours.primary,

  width: theme.spacing(2.4),
  height: theme.spacing(2.4),
  borderRadius: '0.3rem',

  '& svg': {
    color: theme.colours.baseExtraLight,
    stroke: theme.colours.baseExtraLight,
    strokeWidth: theme.spacing(0.5),
  },

  '&.Mui-disabled': {
    backgroundColor: theme.colours.primaryColorDisabled,
    borderColor: theme.colours.primaryColorDisabled,
  },
}))

const useUtilityClasses = (ownerState: Partial<CheckboxFieldProps>) => {
  const slots = {
    root: ['root', ownerState.size || 'medium'],
    checkbox: ['checkbox'],
    label: ['label'],
    icon: ['icon'],
    iconChecked: ['iconChecked'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getCheckboxFieldUtilityClass, ownerState.classes)
}

const _CheckboxField = (props: CheckboxFieldProps) => {
  const {
    className,
    classes,
    label,
    ['data-testid']: dataTestId,
    size = 'medium',
    WrapperProps,
    error,
    helperText,
    isGrouped,
    LabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    variant = 'standard',
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, size })
  const [checked, setChecked] = useState<boolean>(props.checked ?? false)
  const { labelId, errorId, descriptionId, fieldAriaProps } = useAriaProps(props)
  const handleClick = useCallback(() => setChecked((value) => !value), [])

  const Container = isGrouped ? React.Fragment : Box

  const helperTextProps = helperText
    ? {
        ...FormHelperTextProps,
        id: descriptionId,
        className: classnames(slotClasses.helperText, FormHelperTextProps?.className),
      }
    : undefined

  const errorProps = error
    ? {
        ...ErrorTextProps,
        id: errorId,
        role: 'alert',
        className: classnames(slotClasses.errorText, ErrorTextProps?.className),
      }
    : undefined

  return (
    <Container data-testid={dataTestId} className={classnames(slotClasses.root, className)}>
      <CheckboxFieldRoot
        {...WrapperProps}
        error={Boolean(error)}
        label={label}
        size={size}
        variant={variant}
        LabelProps={{ ...LabelProps, id: labelId, className: slotClasses.label }}
        checked={checked}
        control={
          <CheckboxFieldCheckbox
            {...rootProps}
            size={size}
            disableRipple
            icon={<CheckboxFieldIcon role="img" aria-labelledby={labelId} className={slotClasses.icon} />}
            checkedIcon={
              <CheckboxFieldIconChecked role="img" aria-labelledby={labelId} className={slotClasses.iconChecked}>
                <FeatherIcon name={IconName.Check} size={Size.Small} />
              </CheckboxFieldIconChecked>
            }
            inputProps={{
              ...rootProps.inputProps,
              ...fieldAriaProps,
              className: classnames(slotClasses.checkbox, className),
            }}
            onClick={handleClick}
          />
        }
      />

      {helperText &&
        (React.isValidElement(helperText) ? (
          React.cloneElement(helperText as React.ReactElement, helperTextProps)
        ) : (
          <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
        ))}

      {error &&
        (React.isValidElement(error) ? (
          React.cloneElement(error as React.ReactElement, errorProps)
        ) : (
          <FormHelperText {...errorProps} error>
            {error}
          </FormHelperText>
        ))}
    </Container>
  )
}

const CheckboxField = React.memo(_CheckboxField) as typeof _CheckboxField

export default CheckboxField
