import { FieldReadFunction } from '@apollo/client'

import {
  CancellationReasonReport,
  formatAsCurrency,
  ReportColumn,
  ReportsCancellationReasonResponse,
} from '@acre/graphql'

import { CancellationReasonColumns, grandTotal } from './helpers'

const read: FieldReadFunction<CancellationReasonReport[]> = (_, { readField, variables }) => {
  const reportsCancellationReasonResponse = readField<ReportsCancellationReasonResponse>({
    fieldName: 'reportsCancellationReason',
    args: variables?.body ? { body: variables.body } : {},
  })

  const reportsCancellationReasonGroupedResults = reportsCancellationReasonResponse?.grouped_totals?.map(
    (groupResult) => {
      const columnValues = {} as Record<CancellationReasonColumns, ReportColumn>

      const columns = Object.values(CancellationReasonColumns) as CancellationReasonColumns[]

      columns.forEach((column) => {
        const presentValue = groupResult.results?.find(
          (statRef) => readField<CancellationReasonColumns>('cancellation_reason', statRef) === column,
        )

        columnValues[column as CancellationReasonColumns] = {
          value: formatAsCurrency(presentValue?.value || 0),
          count: presentValue && presentValue.count ? presentValue.count : 0,
        }
      })

      const row = {
        ...columnValues,
        id: groupResult.group_id,
        view_by: variables?.body.view_by || '',
        TOTAL: grandTotal(groupResult.results),
      } as CancellationReasonReport

      return row
    },
  )

  return reportsCancellationReasonGroupedResults || []
}

export default {
  read,
}
