import { AdvisorTotalResults, formatAsCurrency, ReportsCancellationTotal } from '@acre/graphql'

export enum CancellationReasonColumns {
  NO_RESPONSE = 'NO_RESPONSE',
  CLIENT_DECLINED_PRODUCT = 'CLIENT_DECLINED_PRODUCT',
  NO_PRODUCT_AVAILABLE = 'NO_PRODUCT_AVAILABLE',
  INCORRECT_CONTACT_DETAILS = 'INCORRECT_CONTACT_DETAILS',
  CLIENT_NO_LONGER_REQUIRES_PRODUCT = 'CLIENT_NO_LONGER_REQUIRES_PRODUCT',
  CLIENT_OBTAINED_PRODUCT_ELSEWHERE = 'CLIENT_OBTAINED_PRODUCT_ELSEWHERE',
  OTHER = 'OTHER',
  TOTAL = 'TOTAL',
}

export enum CaseStatusColumns {
  Lead = 'LEAD',
  PreRecommendation = 'PRE_RECOMMENDATION',
  PreApplication = 'PRE_APPLICATION',
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  AwaitingValuation = 'AWAITING_VALUATION',
  AwaitingOffer = 'AWAITING_OFFER',
  Offered = 'OFFER_RECEIVED',
  Exchange = 'EXCHANGE',
}

export const grandTotal = (totals?: Array<ReportsCancellationTotal | AdvisorTotalResults> | null) => {
  const calculatedTotals =
    totals &&
    totals.reduce(
      (acc, cur) => {
        if (
          cur?.cancellation_reason &&
          CancellationReasonColumns[cur?.cancellation_reason as CancellationReasonColumns]
        ) {
          return {
            value: cur.value ? (acc?.value || 0) + cur.value : acc.value,
            count: cur.count ? (acc?.count || 0) + cur.count : acc.count,
          }
        }
        return acc
      },
      {
        value: 0,
        count: 0,
      },
    )
  return {
    value: formatAsCurrency(calculatedTotals?.value || 0),
    count: calculatedTotals?.count || 0,
  }
}

export const defaultValue = {
  value: formatAsCurrency(0),
  count: 0,
}
