import { ApolloClient, fromPromise, NormalizedCacheObject, Observable } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { ErrorResponse } from '@apollo/client/link/error'
import axios, { AxiosResponse } from 'axios'
import { GraphQLError } from 'graphql'
import Cookies from 'js-cookie'

import envConfig from '@acre/config'

import { messagesAlerts } from '../intl'
import { Locales } from '../types'
import { isForbiddenError } from './links.helpers'

const messages = messagesAlerts[Locales.GB]

const redirectToLogin = async (client: ApolloClient<NormalizedCacheObject>) => {
  await client.clearStore()

  Cookies.set('login_redirect', location.pathname)

  window.location.href = '/login'
}

// Redirects the user to the login screen if they get a 401
const authRedirectErrorLink = (function () {
  // Placeholder for refresh
  let refreshRequest: Observable<AxiosResponse<{}>> | null = null

  return (
    { response, forward, graphQLErrors, networkError, operation }: ErrorResponse,
    client: ApolloClient<NormalizedCacheObject>,
  ) => {
    const errors = graphQLErrors?.length ? graphQLErrors : ([networkError] as const)

    if (errors.length && errors[0] === undefined) return

    const has401Error = (errors as GraphQLError[] | NetworkError[]).some((error) =>
      isForbiddenError(error, networkError ? 'network' : 'graphql'),
    )
    const initialLocation = window.location.pathname
    const isLoginRoute = initialLocation === '/login'
    const isRefreshRoute = initialLocation === '/refresh'

    if (has401Error) {
      // We want to avoid showing the signin prompt if the user
      // lands on either the login page or /
      if (!(isLoginRoute || isRefreshRoute)) {
        // https://github.com/apollographql/apollo-link/issues/646
        // Use `fromPromise` and `flatMap` to ensure it actually waits
        // for the request to finish and retries
        if (refreshRequest === null) {
          refreshRequest = fromPromise(
            axios({
              url: envConfig.REFRESH_URL,
              method: 'POST',
              withCredentials: true,
              validateStatus: () => true, // Always resolve
            }),
          )
        }

        return refreshRequest.flatMap((value) => {
          refreshRequest = null

          if (value.status === 200) {
            return forward(operation)
          } else {
            console.error('Authentication error occurred:', messages.alerts.login)
            const err = new Error(messages.alerts.login)
            redirectToLogin(client)
            return fromPromise(Promise.reject(err))
          }
        })
      }

      if (response) {
        response.errors = []
      }
    }
  }
})()

export default authRedirectErrorLink
