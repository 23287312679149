import { Check, X } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { isBoolean, isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { formatAsYearsAndMonth } from '@acre/utils'
import { formatAsCurrency, formatAsPercentage, LutherAddress, Maybe } from '@acre/graphql'

export const defaultValue = '-'

export const booleanFormatter = (value?: boolean) =>
  isBoolean(value) ? <FormattedMessage id={value ? 'generic.yes' : 'generic.no'} /> : defaultValue

export const currencyFormatter = (value?: Maybe<string>) => (value ? formatAsCurrency(value) : defaultValue)

export const percentageFormatter = (value?: Maybe<string>) => (value ? formatAsPercentage(value) : defaultValue)

export const stringFormatter = (value?: Maybe<string>) => value ?? defaultValue

export const numberFormatter = (value?: Maybe<number>) => (value ? value.toString() : defaultValue)

export const stringArrayJoinFormatter = (array: string[]) => (!isEmpty(array) ? array.join(', ') : defaultValue)

export const emailLinkFormatter = (value?: Maybe<string>) =>
  value ? <a href={`mailto:${value}`}>{value}</a> : defaultValue

export const telephoneLinkFormatter = (value?: Maybe<string>) =>
  value ? <a href={`tel:${value}`}>{value}</a> : defaultValue

export const dateFormatter = (date?: Maybe<string>) => (date ? format(new Date(date), 'dd/MM/yyyy') : defaultValue)

export const monthYearDateFormatter = (date?: Maybe<string>) =>
  date ? format(new Date(date), 'MM/yyyy') : defaultValue

export const monthYearStringFormatter = (months?: Maybe<number>) =>
  months ? formatAsYearsAndMonth(months) : defaultValue

export const mappedStringFormatter = (value?: string, DATA_MAP?: { [key: string]: string }) =>
  value && DATA_MAP && DATA_MAP[value] ? <FormattedMessage id={DATA_MAP[value]} /> : defaultValue

export const addressFormatter = (address?: Maybe<LutherAddress>, link?: string) => {
  if (!address) return defaultValue
  const { address1, posttown, postcode } = address

  if (link) {
    return <a href={link}>{[address1, posttown, postcode].join(', ')}</a>
  } else {
    return [address1, posttown, postcode].join(', ')
  }
}

export const checkOrCrossFormatter = (value: boolean) =>
  value ? <Check size={14} weight="bold" /> : <X size={14} weight="bold" />
