import React, { ReactElement, useMemo } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { Maybe } from '@acre/graphql'

import withDisabled from '../../hoc/withDisabled'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { Size } from '../../utils/types'
import Icon, { IconName } from '../FeatherIcon'
import HelperText from '../HelperText'
import Label from '../Label'
import LoadingSpinner from '../LoadingSpinner'
import { RadioProps } from '../Radio'
import Tooltip from '../Tooltip'
import { RadioGroupTooltipContainer, RadioGroupWrapper, RadioPillSpacer, RadioSpacer } from './RadioGroup.styles'

export type RadioGroupProps = {
  children: ReactElement<RadioProps>[]
  id?: string
  label?: any
  disabled?: boolean
  layout?: 'row' | 'column'
  flexDirection?: string
  name?: string
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  loading?: boolean
  variant?: Variant
  tooltip?: string | JSX.Element
  pill?: boolean
  labelWidth?: Maybe<string>
}

const RadioGroup = ({
  children,
  id,
  label,
  layout,
  flexDirection,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  loading = false,
  variant = 'default',
  tooltip,
  pill = false,
  labelWidth,
}: RadioGroupProps) => {
  const intl = useIntl()

  const theme = useTheme()

  const formatMessage = (id: string, label?: string, less?: string) =>
    intl.formatMessage({ id }, { label, first: label, second: less })

  const direction = layout === 'column' || (children.length > 2 && !flexDirection) ? 'column' : 'row'
  const className = useMemo(() => classNames({ column: direction === 'column' }), [direction])
  const dataTestId = useMemo(() => (id ? testHandle(id) : null), [id])
  return (
    // Box needed because to the external components this needs to look like a single element
    // especially Stack component which stacks input fields on top of each other with spacing
    <Box>
      <RadioGroupWrapper id={id} data-testid={dataTestId} variant={variant} labelWidth={labelWidth}>
        {label && (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
            variant={variant}
          />
        )}
        <Box display="flex" flexDirection={direction} alignItems="center">
          {children.map((child, index) => {
            return pill ? (
              <Box key={index} mt={index === 0 ? undefined : theme.spacers.size8} width={'100%'}>
                <RadioPillSpacer className={className} variant={variant}>
                  {child}
                </RadioPillSpacer>
              </Box>
            ) : (
              <RadioSpacer key={index} className={className} variant={variant}>
                {child}
              </RadioSpacer>
            )
          })}
          {tooltip && (
            <RadioGroupTooltipContainer>
              <Tooltip placement="right" content={tooltip}>
                <Icon name={IconName.HelpCircle} size={Size.Small} />
              </Tooltip>
            </RadioGroupTooltipContainer>
          )}
          {loading && (
            <Box ml={theme.spacers.size8}>
              <LoadingSpinner />
            </Box>
          )}
        </Box>
      </RadioGroupWrapper>
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </Box>
  )
}

export default withDisabled(RadioGroup)
