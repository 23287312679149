import React, { useCallback, useMemo, useState } from 'react'
import { CardRowLine } from '@broker-crm-common'
import { TaskRowSidebarDraw } from '@broker-crm-common/ReviewModeDrawer/Tasks/Tasks.styles'
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types'
import { Location } from 'history'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { Maybe, MortgageReason, ProtectionProductStatus, useGetProtectionProductsQuery } from '@acre/graphql'
import { trackEventFiltered } from '@acre/design-system'

import { TasksLocation } from '../../CaseOverview.helpers'
import { ChevronIcon, validTransitionStatusType } from '../CaseVerifications.helpers'
import ConfirmCaseTransitionModal from '../ConfirmationCaseTransitionModal'
import { TransitionToCompleteStage } from '../TransitionToCompleteTask/CompleteTransitionTask.helpers'
import ConfirmProtectionDetailsFlow from '../TransitionToCompleteTask/ConfirmProtectionDetails/ConfirmProtectionDetailsFlow'
import MortgageDetailsModal from '../TransitionToCompleteTask/MortgageDetailsModal'
import { VerificationTaskButton } from '../../CaseOverview.styles'

type Props = {
  status: validTransitionStatusType
  caseId: string
  preferenceMortgageReason: Maybe<MortgageReason>
  isProtection: boolean
  tasksLocation?: TasksLocation
  location?: Location<unknown>
  onSubmit?: () => any
  trackEvent?: (params: TrackEventParams) => void | undefined
}

const { MORTGAGE_DETAILS, PROTECTION_DETAILS, UPDATE_CASE_STATUS } = TransitionToCompleteStage

const ExchangeTransitionTask = ({
  status,
  caseId,
  isProtection = false,
  tasksLocation,
  location,
  preferenceMortgageReason,
  onSubmit,
  trackEvent,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleClose = () => setIsModalOpen(false)

  const firstStage = isProtection ? PROTECTION_DETAILS : MORTGAGE_DETAILS
  const [transitionStage, setTransitionStage] = useState<TransitionToCompleteStage>(firstStage)

  const variables = { protectionProductDetails: true, filterCaseIds: [caseId] }
  const { data } = useGetProtectionProductsQuery({ variables })

  const selectedProducts = useMemo(
    () => data?.protectionProducts?.filter((product) => product.details.status === ProtectionProductStatus.Selected),
    [data?.protectionProducts],
  )

  const numberOfProducts = selectedProducts?.length || 0

  const transitionFromMortgageDetails = useCallback(
    () => (numberOfProducts > 0 ? setTransitionStage(PROTECTION_DETAILS) : setTransitionStage(UPDATE_CASE_STATUS)),
    [numberOfProducts],
  )

  const transitionFromProtectionDetails = useCallback(() => setTransitionStage(UPDATE_CASE_STATUS), [])

  const handleTransitionToCompleteClick = useCallback(() => {
    setIsModalOpen(true)
    setTransitionStage(firstStage)
    trackEvent && trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
  }, [firstStage, location, tasksLocation, trackEvent])

  const buttonContent = useMemo(() => {
    return (
      <VerificationTaskButton type="button" id={`CaseTransitionToEXCHANGE`}>
        <FormattedMessage id="caseTransitions.confirmExchanged" />
        {ChevronIcon}
      </VerificationTaskButton>
    )
  }, [])

  const renderButton = useMemo(() => {
    if (tasksLocation === TasksLocation.CaseSidebarDrawer) {
      return <TaskRowSidebarDraw onClick={handleTransitionToCompleteClick}>{buttonContent}</TaskRowSidebarDraw>
    } else {
      return (
        <CardRowLine
          style={{ display: 'flex' }}
          onClick={handleTransitionToCompleteClick}
          data-testid={testHandle(`CaseTransitionToEXCHANGE`)}
        >
          {buttonContent}
        </CardRowLine>
      )
    }
  }, [buttonContent, handleTransitionToCompleteClick, tasksLocation])

  return (
    <>
      {renderButton}
      {transitionStage === MORTGAGE_DETAILS && (
        <MortgageDetailsModal
          caseId={caseId}
          onSubmit={transitionFromMortgageDetails}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {transitionStage === PROTECTION_DETAILS && (
        <ConfirmProtectionDetailsFlow
          products={selectedProducts}
          isModalOpen={isModalOpen}
          onSubmit={transitionFromProtectionDetails}
          onClose={handleClose}
        />
      )}
      {transitionStage === UPDATE_CASE_STATUS && (
        <ConfirmCaseTransitionModal
          status={status}
          caseId={caseId}
          preferenceMortgageReason={preferenceMortgageReason}
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

export default ExchangeTransitionTask
