import React, { KeyboardEventHandler, ReactNode, useEffect } from 'react'

export const escape = '27'

export type PanelBarSections = {
  left?: ReactNode
  center?: ReactNode
  right?: ReactNode
}

export type OnClose = (open: boolean) => void

export const useModalEffect = (open: boolean, modalOverlayRef: React.MutableRefObject<any>) => {
  useEffect(() => {
    if (open && modalOverlayRef.current) {
      modalOverlayRef.current.focus()
    }
    document.body.style.overflow = open ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [open])
}

export const getModalClose = (onClose?: OnClose, tracker?: () => void) => () => {
  tracker && tracker()
  onClose && onClose(false)
}

export const getHandleKeyDown =
  (onClose?: OnClose): KeyboardEventHandler =>
  (e) =>
    e.code === escape && onClose && onClose(false)

export const panelDefault = { left: null, center: null, right: null }
