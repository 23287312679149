import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface SimpleFieldWrapperClasses {
  root: string

  sizeMedium: string
  sizeLarge: string

  error: string
}

export type SimpleFieldWrapperClassKey = keyof SimpleFieldWrapperClasses

export function getSimpleFieldWrapperUtilityClass(slot: string) {
  return generateUtilityClass('SimpleFieldWrapper', slot)
}

const simpleFieldWrapperClasses: SimpleFieldWrapperClasses = generateUtilityClasses('SimpleFieldWrapper', [
  'root',
  'sizeMedium',
  'sizeLarge',
  'error',
])

export default simpleFieldWrapperClasses
