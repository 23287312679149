import axios, { AxiosError, AxiosRequestConfig } from 'axios'
// import { GraphQLError } from 'graphql'
import qs from 'qs'

import envConfig from '@acre/config'

import { LutherException } from '../resolvers/util'

interface RequestMeta {
  meta?: { start?: number }
}

type AxiosRequestConfigMeta = AxiosRequestConfig & RequestMeta

interface AxiosResponseMeta<T = any> {
  data: T
  status: number
  statusText: string
  headers: Headers
  config: AxiosRequestConfigMeta
  request?: any
}

interface AxiosResponseDuration extends AxiosResponseMeta {
  duration?: number
}

const defaultParams: AxiosRequestConfig = {
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
}

export interface CreateAxiosInstanceConfig {
  useApiKey?: boolean
  useLutherInterceptor?: boolean
  useCpApiKey?: boolean
}

const createAxiosInstance = (axiosConfig: AxiosRequestConfig, config?: CreateAxiosInstanceConfig) => {
  const instance = axios.create({ ...defaultParams, ...axiosConfig })

  if (config?.useCpApiKey) {
    instance.defaults.headers.common['X-Api-Key'] = envConfig.CLIENT_PORTAL_API_KEY
  }

  if (config?.useApiKey) {
    instance.defaults.headers.common['X-Api-Key'] = envConfig.API_KEY
  }

  instance.interceptors.request.use((config: AxiosRequestConfigMeta) => {
    config.meta = { start: Date.now() }

    return config
  })

  instance.interceptors.response.use(
    (response: AxiosResponseDuration) => {
      if (response.config && response.config.meta && response.config.meta.start) {
        response.duration = Date.now() - response.config.meta.start
      }
      return response
    },
    (err: AxiosError) => {
      if (err.response) {
        const errorResponse = err.response.data
        const url = err.response.config.url!

        if (errorResponse.exception) {
          if (config?.useLutherInterceptor) {
            throw new LutherException(err?.message, err.response?.status, url, errorResponse.exception)
          }

          err.message = errorResponse.exception.description
        }

        if (errorResponse.message) {
          err.message = errorResponse.message
        }
      }

      throw err
    },
  )

  return instance
}

export default createAxiosInstance
