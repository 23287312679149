import React, { useCallback, useContext, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { UserContext } from '@acre/utils'
import { useFormatMessage } from '@acre/utils'
import { AssigneeType, GetUserDocument, GetUserQuery, GetUserQueryVariables, NoteInput, NoteType } from '@acre/graphql'
import { Button, ButtonNotDisabled, GreyText, H2, Modal, Paragraph } from '@acre/design-system'

import { fallBackDate, getModalDescriptionMsgId, useSubmitReminder, validate } from './AddReminderModal.helper'
import ReminderFields, { ReminderLayout } from './ReminderFields'

type Props = {
  modalOpen: boolean
  initialValues: NoteInput
  transition?: () => void
  setModalOpen?: (value: boolean) => void
  createdAt?: string | null
  isEdit?: boolean
  isProtection?: boolean
  isHomeInsurance?: boolean
  layout?: ReminderLayout
  isPartOfTheFlow?: boolean // Modal is part of the complete transition tasks
}

const AddReminderModal = ({
  modalOpen,
  transition,
  setModalOpen,
  initialValues,
  createdAt,
  isEdit = false,
  isProtection = false,
  isHomeInsurance = false,
  layout = 'reminder',
  isPartOfTheFlow = false,
}: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()
  const user = useContext(UserContext)
  const formRef = useRef<HTMLFormElement>(null)
  const { created_by } = initialValues
  const { data: creator } = useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {
    variables: { id: created_by!, useClientApi: false },
    skip: !created_by,
  })

  // Reminders with a body need the layout with additional fields
  const hasBody = initialValues?.body
  const overridenLayout = hasBody ? 'review' : layout

  // Review notes that are prompted upon case completion have a slightly different layout
  const isReminder = layout === 'reminder'
  const showReviewExtras = !isEdit && !isReminder

  const now = new Date()
  const noteDateValue = format(
    initialValues?.created_at ? parseISO(initialValues?.created_at) : now,
    'dd MMM yyyy, h:mm a',
  )

  const status = initialValues && initialValues.status
  const buttonValue = !isEdit ? 'reminders.addReminder' : 'reminders.saveChanges'

  const newReminderHeading = isReminder
    ? formatMessage('reminders.modalHeading')
    : formatMessage('reminders.setAReminder')
  const modalHeading = isEdit ? formatMessage('reminders.modalEditHeading') : newReminderHeading

  const modalDescriptionMsgId = getModalDescriptionMsgId(isHomeInsurance, isProtection)

  const { handleSubmit: handleSubmitNote, loading } = useSubmitReminder({ initialValues, isEdit })

  const handleSubmit = useCallback(
    async (data: NoteInput) => {
      await handleSubmitNote(data)
      if (isPartOfTheFlow) {
        transition && transition()
      } else {
        setModalOpen && setModalOpen(false)
      }
    },
    [handleSubmitNote, isPartOfTheFlow, setModalOpen, transition],
  )

  //if assignee type not passed then default to USER (not GROUP)
  if (!initialValues['assignee_type']) {
    initialValues['assignee_type'] = AssigneeType.User
  }
  //if title not passed then default to Review or Reminder depending  on primary_type
  if (!initialValues['title']) {
    if (initialValues['primary_type'] === NoteType.Reminder) {
      initialValues['title'] = 'Reminder'
    } else if (initialValues['primary_type'] === NoteType.Review) {
      initialValues['title'] = 'Review'
    }
  }

  const handleSkipOrCloseReminderClick = useCallback(() => {
    if (isPartOfTheFlow) {
      transition && transition()
    } else {
      setModalOpen && setModalOpen(false)
    }
  }, [isPartOfTheFlow, setModalOpen, transition])

  return (
    <>
      {user && (
        <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
          {({ handleSubmit, form, submitFailed, valid }) => (
            <form
              onSubmit={(evt) => {
                const submitting = handleSubmit && handleSubmit(evt)
                submitting instanceof Promise &&
                  submitting
                    .then(() => {
                      // @ts-ignore  This method is part of the API, but isn't in the type definitions.
                      form.restart()
                    })
                    .catch((error) => {
                      console.log(error)
                    })
              }}
              ref={formRef}
            >
              <Modal
                open={modalOpen}
                onClose={() => {
                  handleSkipOrCloseReminderClick()
                  // @ts-ignore  This method is part of the API, but isn't in the type definitions.
                  form.restart()
                }}
                header={{
                  left: <H2 styledAs="h4">{modalHeading}</H2>,
                }}
                className="modal-reduced-padding modal-overflow"
                footer={
                  <>
                    <Box width={'100%'}>
                      <Box
                        display="flex"
                        justifyContent={showReviewExtras ? 'flex-end' : 'space-between'}
                        alignItems="center"
                      >
                        {!showReviewExtras && (
                          <Box display="flex" flexDirection="column" alignItems="flex-start">
                            {isEdit && (
                              <GreyText>
                                <FormattedMessage
                                  id="reminders.createdBy"
                                  values={{
                                    firstName: creator?.user?.first_name,
                                    lastName: creator?.user?.last_name,
                                  }}
                                />
                              </GreyText>
                            )}
                            <GreyText>{fallBackDate(noteDateValue)}</GreyText>
                          </Box>
                        )}

                        {showReviewExtras && (
                          <Box mr={theme.spacers.size16}>
                            <Button onClick={handleSkipOrCloseReminderClick} buttonStyle="secondary" disabled={false}>
                              <FormattedMessage id="reminders.skipReminder" />
                            </Button>
                          </Box>
                        )}

                        <ButtonNotDisabled
                          id="SaveReminder"
                          isLoading={loading}
                          buttonStyle="primary"
                          onClick={() => {
                            form.submit()?.then(() => form.reset())
                          }}
                          disabled={!valid}
                        >
                          <FormattedMessage id={buttonValue} />
                        </ButtonNotDisabled>
                      </Box>
                    </Box>
                  </>
                }
              >
                {!isEdit && !isReminder && (
                  <Paragraph>
                    <FormattedMessage id={`reminders.${modalDescriptionMsgId}`} />
                  </Paragraph>
                )}
                <Box mb={theme.spacers.size16}>
                  <ReminderFields
                    submitFailed={submitFailed}
                    layout={overridenLayout}
                    status={status!}
                    createdAt={createdAt}
                    isEdit={isEdit}
                    initialValues={initialValues}
                  />
                </Box>
              </Modal>
            </form>
          )}
        </Form>
      )}
    </>
  )
}

export default AddReminderModal
