import { DropdownOption } from '@acre/design-system'

type InputWithStringLabel = { label?: string }
type InputWithTranslation = { message?: string }

type InputWithLabel = InputWithStringLabel | InputWithTranslation

interface InputTableBuilderInput {
  id: string
  required?: boolean
  value?: string | number
  onChange?: (value: ChangeValue) => any
}

type InputTableBuilderProps = {
  defaultValue?: string
  options?: DropdownOption[]
  type: InputTableBuilderElementType
} & InputTableBuilderInput &
  InputWithLabel

export type Row = { rowId?: string; columns: InputTableBuilderProps[] }

export type ColumnHead = { heading: string; width?: string }

export type TableStructure = {
  columnHeads?: Array<ColumnHead>
  rows: Row[]
}

export type ChangeValue = {
  id: string
  value: string | number
}

export enum InputTableBuilderElementType {
  label = 'label',
  dropdown = 'dropdown',
  text = 'text',
  currency = 'currency',
  remove = 'remove',
  percent = 'percent',
}

export type InputType = {
  id: string
  type: InputTableBuilderElementType
  options?: DropdownOption[]
  required?: boolean
  value?: string | number
  onChange?: (value: ChangeValue) => any
  onRemove?: (values: TableStructure) => any
  label?: string
  message?: string
  defaultValue?: string
}

export const updateRows = (rows: Row[], rowId: number, newColumn: ChangeValue): Row[] => {
  let newRows = [...rows] // Clone array, don’t mutate the original
  newRows[rowId].columns = rows[rowId].columns.map((column) => {
    if (newColumn.id === column.id) {
      return {
        ...column,
        value: newColumn.value,
      }
    }
    return column
  })
  return newRows
}
