import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface FormCancelActionClasses {
  root: string
}

export type FormCancelActionClassKey = keyof FormCancelActionClasses

export function getFormCancelActionUtilityClass(slot: string) {
  return generateUtilityClass('FormCancelAction', slot)
}

const formCancelActionClasses: FormCancelActionClasses = generateUtilityClasses('FormCancelAction', ['root'])

export default formCancelActionClasses
