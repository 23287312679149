import React, { memo, SyntheticEvent } from 'react'

import { testHandle } from '@acre/utils'
import { convertPoundsToPence } from '@acre/graphql'
import { Close, COMPACT_LEFT_ALIGNED, CurrencyInput, Dropdown, TextInput } from '@acre/design-system'

import { PercentageInput } from '../PercentageInput'
import { InputTableBuilderElementType, InputType } from './InputTableBuilder.helpers'
import { RemoveButtonStyle } from './InputTableBuilderElement.styles'

const handleCurrencyBlur = (event: React.FocusEvent<HTMLInputElement> | undefined) => {
  const value = event?.target.value.replace(',', '') || ''
  return convertPoundsToPence(value) || ''
}

const InputTableBuilderElement = ({
  id,
  label,
  message,
  value,
  defaultValue,
  type,
  options,
  onChange,
  onRemove,
}: InputType) => {
  if (type === InputTableBuilderElementType.label) {
    return (
      <p id={id} data-testid={testHandle(id)}>
        {message || label}
      </p>
    )
  }

  if (type === InputTableBuilderElementType.dropdown && onChange && options && options.length > 0) {
    const stringValue = typeof value === 'string' ? value : ''
    const handleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
      e.preventDefault()

      if (e.currentTarget.value && id) {
        onChange({ id, value: e.currentTarget.value })
      }
    }
    if (defaultValue) {
      return (
        <Dropdown
          variant={COMPACT_LEFT_ALIGNED}
          id={id}
          data-testid={id}
          options={options}
          value={stringValue}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      )
    }

    console.error("'defaultValue' not provided for input type 'Dropdown'")
    return null
  }

  if (type === InputTableBuilderElementType.text && onChange && typeof value !== 'number') {
    return (
      <TextInput
        variant={COMPACT_LEFT_ALIGNED}
        id={id}
        type={type}
        value={value}
        onBlur={(evt) => onChange({ id, value: evt.target.value })}
        onChange={() => {}}
      />
    )
  }

  if (type === InputTableBuilderElementType.currency && onChange) {
    return (
      <CurrencyInput
        variant={COMPACT_LEFT_ALIGNED}
        id={id}
        value={Number(value)}
        onBlur={(evt) => onChange({ id, value: handleCurrencyBlur(evt) })}
        onChange={() => {}}
      />
    )
  }

  if (type === InputTableBuilderElementType.remove && onRemove) {
    return (
      <RemoveButtonStyle id={id} type="button" buttonStyle="clear" onClick={onRemove}>
        <Close />
      </RemoveButtonStyle>
    )
  }

  if (type === InputTableBuilderElementType.percent && onChange) {
    return (
      <PercentageInput
        variant={COMPACT_LEFT_ALIGNED}
        id={id}
        value={value ? Number(value) : NaN}
        validateOnBlur={(value) => onChange({ id, value })}
      />
    )
  }

  console.error('Input type not supported. Supported: dropdown | text | currency | percent | remove')

  return null
}

export default memo(InputTableBuilderElement)
