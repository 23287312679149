import React, { cloneElement, isValidElement, ReactNode } from 'react'
import { Theme } from '@mui/material'

import { flattenChildren } from '@acre/utils'

import testHandle from '../../utils/testHandle'
import { StyledTable } from './Table.styles'

export interface TableProps {
  gutterPadding?: keyof Theme['spacers']
  rowPadding?: keyof Theme['spacers']
  fontSize?: number
  highlight?: boolean
  borderBottom?: boolean
  id?: string
  tableLayout?: 'auto' | 'fixed'
  className?: string
  children?: React.ReactNode
}

const Table = ({
  gutterPadding,
  rowPadding,
  fontSize,
  children,
  highlight = false,
  borderBottom,
  id,
  tableLayout = 'auto',
  className,
}: TableProps) => {
  const flattenedChildren = flattenChildren(children)

  const childrenWithProps = (flattenedChildren || []).map((child: ReactNode) =>
    isValidElement(child)
      ? cloneElement(child, {
          gutterPadding,
          rowPadding,
          fontSize,
          highlight,
          ...child.props,
        })
      : null,
  )

  return (
    <StyledTable
      id={id}
      borderBottom={borderBottom}
      tableLayout={tableLayout}
      className={className}
      data-testid={id && testHandle(id)}
    >
      {childrenWithProps}
    </StyledTable>
  )
}

export default Table
