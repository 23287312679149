import { GetClientsQuery, isNonNaturalClient } from '@acre/graphql'

// Regex to split the HTML string by sections that begin with a heading tag and are followed by content
export const headingTagRegex = /<h[1-6][^>]*>.*?<\/h[1-6]>.*?(?=<h[1-6][^>]*>|$)/gis

/**
 * Allows splitting a html string into two equal halves (albeit not perfectly)
 * @param str HTML string
 * @returns the two parts that comprise the single HTML string
 */
export const getHTMLSections = (str?: string | null) => {
  if (!str) {
    return {
      firstPart: '',
      secondPart: '',
    }
  }

  const sections = str.match(headingTagRegex)
  const halfPoint = sections ? Math.floor(sections?.length / 2) : 0
  const divisionIndex = sections ? str.indexOf(sections[halfPoint]) : 0

  return {
    firstPart: str.substring(0, divisionIndex),
    // needs to match the class name in the default template, as upon slicing of the single html string
    // the second part doesn't include the div with this class
    secondPart: `<div class='idd-template'>` + str.substring(divisionIndex) + '</div>',
  }
}

export const getIddClients = (clients: GetClientsQuery['clients']) => {
  const nonNaturalClients = clients.filter((client) => isNonNaturalClient(client.details))
  // If non-natural clients exist then IDD them only, otherwise IDD all clients
  return nonNaturalClients?.length && nonNaturalClients?.length > 0 ? nonNaturalClients : clients
}
