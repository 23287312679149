import { useMemo, useRef } from 'react'
import { MissingFieldsSections } from '@broker-crm-utils'
import { useFormState } from 'react-final-form'

import { useCaseContext } from '../../../contexts/CaseContext'
import { getMissingDirtyFields } from '../../../utils/missingFields/getMissingDirtyFields'

export const useMissingFields = (
  appSection: MissingFieldsSections,
  formSection: string,
  itemId: string = '', // Default to empty and if it is then we return EMPTY_ARRAY
  index?: number | null,
) => {
  const { verifications, missingFieldsToggle } = useCaseContext()
  const { dirtyFields, submitting } = useFormState()

  const missingFieldsRef = useRef<string[] | undefined>()

  const formMissingFields = useMemo(() => {
    if (submitting) {
      return missingFieldsRef.current
    }

    const sectionMissingFields = verifications?.missing_fields ? verifications?.missing_fields[appSection] : {}

    const missingFields =
      sectionMissingFields && sectionMissingFields[itemId] ? sectionMissingFields[itemId][formSection] : undefined

    missingFieldsRef.current = missingFields ? getMissingDirtyFields(dirtyFields, index, missingFields) : undefined

    return missingFieldsRef.current
  }, [submitting, verifications?.missing_fields, appSection, itemId, formSection, dirtyFields, index])

  return {
    isMissing: (field: string) => missingFieldsToggle && formMissingFields && formMissingFields.includes(field),
    isNestedObjectMissing: (obJectId: string) =>
      missingFieldsToggle && formMissingFields && formMissingFields?.some((item) => item?.includes(obJectId)),
  }
}
