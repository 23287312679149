import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { ReportProblemProvider } from '@broker-crm-contexts'
import FullStory from 'react-fullstory'

import { calculateFlags, Feature, Flag, FlagProvider, FlagType, PageScrollHandler, UserProvider } from '@acre/utils'
import { PostBrowserIdDocument, PostBrowserIdMutation, useGetCurrentUserQuery } from '@acre/graphql'
import acreConfig from '@acre/config'
import { FullPageLoadingSpinner, UserTracker } from '@acre/design-system'

import Eula from './pages/Eula'
import { useEula } from './pages/Eula/Eula.helpers'
import Router from './routing/Router'
import { introducerType } from './utils/constants'

const App = () => {
  const { loading: loadingCurrentUser, data: dataCurrentUser } = useGetCurrentUserQuery()

  const [isProblemReported, setIsProblemReported] = useState(false)

  const [checkBrowserId] = useMutation<PostBrowserIdMutation>(PostBrowserIdDocument)

  const currentUser = dataCurrentUser ? dataCurrentUser.currentUser : null
  const organisationData = currentUser?.organisation

  useEffect(() => {
    if (
      currentUser?.role_name === introducerType.introducer ||
      currentUser?.role_name === introducerType.introducerManager
    ) {
      return window.location.assign(acreConfig.INTRODUCER_PORTAL_URL)
    }
  }, [currentUser])

  useEffect(() => {
    checkBrowserId()
  }, [checkBrowserId])

  const { loading, signedEula, doc } = useEula({
    user: currentUser,
  })

  const calculatedFlags = calculateFlags(organisationData, currentUser)

  if (loadingCurrentUser || loading) {
    return <FullPageLoadingSpinner />
  }

  return (
    <PageScrollHandler>
      <FlagProvider value={calculatedFlags}>
        <UserProvider value={currentUser}>
          <UserTracker user={currentUser}>
            {doc?.data_base64 && !signedEula && <Eula />}
            <Flag keyPath={[FlagType.Feature, Feature.Fullstory]} defaultValue={false}>
              <FullStory org={acreConfig.FULLSTORY_ORG_ID} />
            </Flag>
            <ReportProblemProvider value={{ isProblemReported, setIsProblemReported }}>
              <Router />
            </ReportProblemProvider>
          </UserTracker>
        </UserProvider>
      </FlagProvider>
    </PageScrollHandler>
  )
}

export default App
