import React, { useCallback } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { NumberFormatValues, SourceInfo } from 'react-number-format/types/types'

interface CustomProps {
  id?: string
  name?: string
  onChange: (event: { target: { name?: string; value: string } }) => void
}

export interface NumberFormatProps extends Omit<NumericFormatProps, 'onChange'>, CustomProps {}

const NumberFormat = React.forwardRef<typeof NumericFormat, NumberFormatProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props

  const onValueChange = useCallback(
    (values: NumberFormatValues, sourceInfo: SourceInfo) => {
      const event = sourceInfo.event
      onChange({ ...event, target: { ...event?.target, name: props.name, value: values.value } })
    },
    [onChange, props.name],
  )

  return <NumericFormat {...other} getInputRef={ref} onValueChange={onValueChange} thousandSeparator />
})

export default NumberFormat
