import { createTour } from '@acre/design-system'

const PreviewModeTour = createTour({
  Button: {
    selector: '#preview-mode-tour-button',
    content: {
      title: "You're in preview mode",
      body: "This means you're using the newset version of Acre. Click this icon to go back to the standard mode.",
    },
  },
})

export const PreviewModeTourSteps = [PreviewModeTour.Button]

export default PreviewModeTour
