import React, { ReactElement, useCallback } from 'react'
import { FormSubscription } from 'final-form'
import { useForm, useFormState } from 'react-final-form'

import { ToPartial } from '@acre/utils'

import Button, { ButtonNewestProps as ButtonProps } from '../ButtonNewest'

export interface FormSubmitAction extends ToPartial<ButtonProps, 'onClick' | 'children'> {
  disabledStateKeys?: (keyof FormSubscription)[]
  text?: string | ReactElement
}

const FormSubmitAction = ({ text, children, disabled, ...props }: FormSubmitAction) => {
  const { submit } = useForm()
  const { valid } = useFormState({ subscription: { valid: true } })

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      // prevent double submit
      e.preventDefault()

      // Should this go before or after onClick?
      submit()

      if (typeof props.onClick === 'function') {
        props.onClick(e)
      }
    },
    [props, submit],
  )

  return (
    <Button disabled={Boolean(disabled) || !valid} size="large" {...props} onClick={handleClick}>
      {text || children}
    </Button>
  )
}

export default FormSubmitAction
