import { ReactElement } from 'react'
import { MockedResponse } from '@apollo/client/testing'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { enGB } from 'date-fns/locale'
import { IntlProvider } from 'react-intl'

import { ErrorProvider, FlagProvider, flagsAllEnabled, flattenMessages, getMessages, Locales } from '@acre/utils'
import { Alert, muiTheme, ThemeProvider } from '@acre/design-system'
import { render } from '@acre/test'

import messages from '../../intl/messagesBrokerCrm'
import MockedProvider from './MockedProvider'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

export default (tree: ReactElement, mocks?: MockedResponse<Record<string, any>>[]) => {
  const children = mocks ? <MockedProvider mocks={mocks}>{tree}</MockedProvider> : tree

  return render(
    <FlagProvider value={flagsAllEnabled}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
          <ThemeProvider theme={muiTheme}>
            <ErrorProvider alertSlot={Alert} alertId="testErrorToast">
              {children}
            </ErrorProvider>
          </ThemeProvider>
        </IntlProvider>
      </LocalizationProvider>
    </FlagProvider>,
  )
}
