import React, { FC, useMemo } from 'react'
import { camelCase } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { formatAsCurrency, Maybe, MortgageProductFee } from '@acre/graphql'
import { Table, Tbody, Td, Th, Thead, Tr } from '@acre/design-system'

import { calculateTotalFees } from '../../GenericMortgageCard/MortgageProductFees/MortgageProductFees.helpers'

type Props = {
  mortgageProductFees?: Maybe<MortgageProductFee[]>
}

const ProductFeesTable: FC<Props> = ({ mortgageProductFees }) => {
  const fees = useMemo(() => mortgageProductFees?.filter((fee) => fee.amount && fee.fee_type), [mortgageProductFees])

  const total = calculateTotalFees(fees)

  if (!fees) {
    return null
  }

  return (
    <div data-testid={testHandle('ProductFeesTableContainer')}>
      <Table gutterPadding="size32" rowPadding="size8" borderBottom>
        <Thead fontSize={12}>
          <Tr styled>
            <Th>
              <FormattedMessage id="mortgageProduct.feeTypes.type" />
            </Th>
            <Th>
              <FormattedMessage id="mortgageProduct.feeTypes.amount" />
            </Th>
          </Tr>
        </Thead>
        <Tbody fontSize={14}>
          {fees.map(({ amount, fee_type }) => (
            <Tr key={`${fee_type} ${amount}`}>
              <Td>
                <FormattedMessage id={`mortgageProduct.feeTypes.${camelCase(fee_type!)}`} />
              </Td>
              <Td>{amount && formatAsCurrency(amount)}</Td>
            </Tr>
          ))}
          <Tr styled>
            <Td>
              <strong>
                <FormattedMessage id="mortgageProduct.feeTypes.total" />
              </strong>
            </Td>
            <Td>{total && <strong>{formatAsCurrency(total)}</strong>}</Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  )
}

export default ProductFeesTable
