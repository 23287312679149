import React, { cloneElement, isValidElement, ReactNode } from 'react'
import { isFragment } from 'react-is'

/* Returns React children into an array, flattening fragments. */
// https://github.com/grrowl/react-keyed-flatten-children
export default function flattenChildren(
  children: ReactNode,
  depth: number = 0,
  keys: (string | number)[] = [],
): ReactNode[] {
  return React.Children.toArray(children).reduce((acc: ReactNode[], node, nodeIndex) => {
    if (isFragment(node)) {
      acc.push.apply(
        acc,
        flattenChildren((node as JSX.Element).props.children, depth + 1, keys.concat(node.key || nodeIndex)),
      )
    } else {
      if (isValidElement(node)) {
        acc.push(
          cloneElement(node, {
            key: keys.concat(String(node.key)).join('.'),
          }),
        )
      } else if (typeof node === 'string' || typeof node === 'number') {
        acc.push(node)
      }
    }
    return acc
  }, [])
}
