import { createContext, useContext } from 'react'

import { ClientVersion, GateName, GetClientQuery, Maybe } from '@acre/graphql'

export type ClientContextType = Maybe<(GetClientQuery['client'] | ClientVersion) & { permissions?: GateName[] }>

const ClientContext = createContext<ClientContextType>(null)

const useClientContext = () => useContext<ClientContextType>(ClientContext)

ClientContext.displayName = 'ClientContext'
const ClientConsumer = ClientContext.Consumer
const ClientProvider = ClientContext.Provider

export { ClientContext, ClientConsumer, ClientProvider, useClientContext }
