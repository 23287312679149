import React, { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ButtonNotDisabled } from '@acre/design-system'

import InitialDisclosureCheckboxes from './InitialDisclosureCheckboxes'
import { ConfirmationMessage } from './InitialDisclosure.styles'

const InitialDisclosureFooter = ({
  clientIds,
  clientIdsAccept,
  loading,
  handleSubmit,
  setClientIdsAccept,
}: {
  clientIds: string[]
  clientIdsAccept: string[]
  loading: boolean
  handleSubmit: () => void
  setClientIdsAccept: Dispatch<SetStateAction<string[]>>
}) => {
  const theme = useTheme()

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" flexDirection="column">
        <Box mb={theme.spacers.size8} mr={theme.spacers.size8}>
          <ConfirmationMessage>
            <FormattedMessage id="cases.initialDisclosure.confirmationCopy" />
          </ConfirmationMessage>
        </Box>
        <InitialDisclosureCheckboxes
          clientIds={clientIds}
          checkedClientIds={clientIdsAccept}
          setCheckedClientIds={setClientIdsAccept}
        />
      </Box>
      <ButtonNotDisabled onClick={handleSubmit} id="ConfirmVerbalIdd" isLoading={loading}>
        <FormattedMessage id="generic.confirmAndContinue" />
      </ButtonNotDisabled>
    </Box>
  )
}

export default InitialDisclosureFooter
