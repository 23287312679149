import { GetCaseOwnersQuery, Maybe } from '@acre/graphql'

export enum CaseListOwnersFormFields {
  OwnerUserId = 'filter_owner_user_id',
}

export type CaseListCaseOwnersFilterType = {
  [CaseListOwnersFormFields.OwnerUserId]: Maybe<string>
}

export type CaseListCaseOwnersState = {
  caseOwners: GetCaseOwnersQuery['caseOwners']
  form: CaseListCaseOwnersFilterType
}
