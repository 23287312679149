import { createContext, useContext, useState } from 'react'
import { MissingFields } from '@broker-crm-utils'

import { CaseVersion, GateName } from '@acre/graphql'

export type CaseContextType = Pick<CaseVersion, 'details' | 'id' | 'created_at' | 'version' | 'version_created_at'> & {
  verifications?: {
    passed_collection_ids: string[]
    failed_collection_ids: string[]
    failed_verification_ids: string[]
    missing_fields: MissingFields
  }
  permissions?: GateName[]
  missingFieldsToggle?: boolean
  setMissingFieldsToggle?: (value: boolean) => void
}

/**
 * @function useSessionState
 * Like useState, but uses sessionStorage instead
 * @param storageKey The key to use for session storage
 * @param defaultValue The value to set by default
 */
export function useSessionState<T>(storageKey: string, defaultValue: T): [T, (value: T) => void] {
  const sessionStorageValue = window.sessionStorage.getItem(storageKey)
  const parsedSessionValue = sessionStorageValue ? JSON.parse(sessionStorageValue) : { value: defaultValue }

  const [internalState, setInternalState] = useState<{ value: T }>(parsedSessionValue)

  const setState = (value: T) => {
    setInternalState({ value })
    window.sessionStorage.setItem(storageKey, JSON.stringify({ value }))
  }

  return [internalState.value, setState]
}

// @ts-ignore - Empty context type
const CaseContext = createContext<CaseContextType>({})
const CaseContextConsumer = CaseContext.Consumer
const CaseContextProvider = CaseContext.Provider
CaseContext.displayName = 'CaseContext'

const useCaseContext = () => useContext<CaseContextType>(CaseContext)

export { CaseContext, CaseContextConsumer, CaseContextProvider, useCaseContext }
