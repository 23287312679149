import { CaseStatus } from '@acre/graphql'

import { ColourId } from '../../utils/types'

export const STATUS_TAG_COLOUR: { [index in CaseStatus]: ColourId } = {
  INVALID_STATUS: ColourId.LightRed,
  PRE_RECOMMENDATION: ColourId.LightBlue,
  POST_APPLICATION: ColourId.LightGreen,
  PRE_APPLICATION: ColourId.LightOrange,
  NOT_PROCEEDING: ColourId.BaseLightColor,
  COMPLETE: ColourId.Green,
  REVIEW: ColourId.LightRed,
  IMPORTED_COMPLETE: ColourId.Green,
  IMPORTING: ColourId.LightRed,
  POST_RECOMMENDATION_REVIEW: ColourId.LightRed,
  LEAD: ColourId.Yellow,
  APPLICATION_SUBMITTED: ColourId.LightGreen,
  AWAITING_VALUATION: ColourId.LightOrange,
  AWAITING_OFFER: ColourId.LightRed,
  OFFER_RECEIVED: ColourId.Green,
  EXCHANGE: ColourId.Green,
}

export const statusPrefix = 'cases.status'

export const STATUS_INTL: { [key in CaseStatus]: string } = {
  INVALID_STATUS: `${statusPrefix}.invalid`,
  PRE_RECOMMENDATION: `${statusPrefix}.preRecommendation`,
  POST_APPLICATION: `${statusPrefix}.postApplication`,
  PRE_APPLICATION: `${statusPrefix}.preApplication`,
  NOT_PROCEEDING: `${statusPrefix}.notProceeding`,
  COMPLETE: `${statusPrefix}.complete`,
  REVIEW: `${statusPrefix}.review`,
  IMPORTED_COMPLETE: `${statusPrefix}.importedComplete`,
  IMPORTING: `${statusPrefix}.importing`,
  POST_RECOMMENDATION_REVIEW: `${statusPrefix}.postRecommendationReview`,
  LEAD: `${statusPrefix}.lead`,
  APPLICATION_SUBMITTED: `${statusPrefix}.applicationSubmitted`,
  AWAITING_VALUATION: `${statusPrefix}.awaitingValuation`,
  AWAITING_OFFER: `${statusPrefix}.awaitingOffer`,
  OFFER_RECEIVED: `${statusPrefix}.offered`,
  EXCHANGE: `${statusPrefix}.exchange`,
}
