import { styled } from '@mui/material'

import { Variant } from '../../utils/constants'
export type Align = 'left' | 'center' | 'right'

export const CardListItem = styled('div')<{
  smallFont?: boolean
  border?: boolean
  variant?: Variant
  fontSize?: number
  extraPadding?: boolean
  flexDirection?: string
}>(
  ({ theme, smallFont, border, variant, fontSize, extraPadding, flexDirection }) => `
  padding: ${
    variant === 'compact' || variant === 'small' || variant === 'compactLeftAligned' ? '0' : `${theme.spacing(1)} 0`
  };
  display: flex;
  flex-direction: ${flexDirection ? flexDirection : 'row'};
  justify-content: ${variant === 'default' ? 'space-between' : ''};
  width: ${variant === 'small' ? '75%' : '100%'};

  border-top: ${border && `1px solid ${theme.colours.baseLight}`};

  line-height: 1.6;
  font-size: ${fontSize ? `${fontSize}px` : smallFont ? '14px' : '16px'};
  padding-bottom: ${extraPadding && theme.spacing(0.5)};
`,
)

export const CardListItemKey = styled('div')<{ align?: Align; width?: string; variant?: Variant; boldLabel: boolean }>(
  ({ theme, width, variant, boldLabel }) => `
  text-align: left;
  flex-shrink: 0; /* prevents list item value growing into the key item space */
  padding-right: ${theme.spacing(1)};
  width: ${width ? width : undefined};
  flex-grow:  ${!width ? '1' : undefined};
  font-weight: ${boldLabel ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular};
  ${variant === 'compactLeftAligned' ? 'width: 33%;' : ''};
`,
)

export const CardListItemValue = styled('div')<{
  align?: Align
  boldValues?: boolean
  variant?: Variant
  paddingLeft?: string
}>(
  ({ theme, align, variant, boldValues, paddingLeft }) => `
  display: flex;
  flex-direction: column;
  ${align === 'left' && variant !== 'compactLeftAligned' ? 'width: 50%;' : ''};
  text-align: ${align ? align : 'right'};
  padding-left: ${paddingLeft ? paddingLeft : theme.spacing(1)};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  hyphens: none;
  font-weight: ${boldValues ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular};
  width: ${variant === 'compactLeftAligned' && '100%'};
`,
)

export const GreyText = styled('p')(
  ({ theme }) => `
  color: ${theme.colours.baseMid};
`,
)
