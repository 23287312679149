import React, { ReactNode } from 'react'
import { styled } from '@mui/material'

import testHandle from '../../utils/testHandle'

const StyledError = styled('p')(
  ({ theme }) => `
  color: ${theme.colours.danger};
  margin-top: 0.1rem;
  margin-bottom: 0;
  font-size: 14px;
  a,
  a:hover {
    color: ${theme.colours.danger};
  }
`,
)
export type ErrorMessageProps = {
  id?: string
  error: ReactNode | string
}

const ErrorMessage = ({ id, error }: ErrorMessageProps) => {
  const dataTestId = id ? `${testHandle(id)}-error` : null
  return <StyledError data-testid={dataTestId}>{error}</StyledError>
}

export default ErrorMessage
