import { memoize } from 'lodash'

import {
  GateName,
  Maybe,
  PermissionAssignmentName,
  PermissionResolutionAssigneeInput,
  PermissionResolutionInput,
  PermissionScopeType,
  ResolvedPermissionResolution,
} from '@acre/graphql'

import {
  GeneratePermissionResolutionInputProps,
  PermissionBuilderProps,
  PermissionInput,
  PermissionInputWithAssignees,
  TransformedPermissionInputs,
} from './permission.types'

// certain gates can be conditionally excluded from permission resolution requests
export const excludeInputsBasedOnGates = ({
  gateExceptions,
  inputs,
}: {
  gateExceptions: GateName[]
  inputs: PermissionInput[] | PermissionInputWithAssignees[]
}) => {
  return [...inputs].reduce((acc: PermissionInput[], input: PermissionInput) => {
    let newInput = { ...input }

    if (Array.isArray(newInput.gates)) {
      newInput = {
        ...newInput,
        // remove the gates which are excepted
        gates: newInput.gates.filter((gate) => !gateExceptions.includes(gate)) || [],
      }
    }
    // if no gates are left then do not preserve the input
    if (!newInput.gates.length) {
      return [...acc]
    }
    return [...acc, newInput]
  }, [])
}

const PermissionAssignmentNamesWithoutScope = [
  PermissionAssignmentName.ORGANISATION_CREATE_INTRODUCER_ORG,
  PermissionAssignmentName.REGULATED_MORTGAGE_PROVISIONAL,
  PermissionAssignmentName.REGULATED_MORTGAGE,
  PermissionAssignmentName.REGULATED_EQUITYRELEASE_PROVISIONAL,
  PermissionAssignmentName.REGULATED_EQUITYRELEASE,
  PermissionAssignmentName.REGULATED_PROTECTIONINSURANCE_PROVISIONAL,
  PermissionAssignmentName.REGULATED_PROTECTIONINSURANCE,
  PermissionAssignmentName.REGULATED_GENERALINSURANCE_PROVISIONAL,
  PermissionAssignmentName.REGULATED_GENERALINSURANCE,
  PermissionAssignmentName.ACRE_ORGANISATION_CREATE_REG_ORG,
]

/**
 * Function to create all possible permutations of permission and assignees
 * @param permissionResolutionInputs permission resolutions which can include the source (but not necessarily)
 * @param assignees (assignees that you would like to check permissions for)
 * @returns permissions resolutions for every assignee specified
 */
const getPermissionsInputsForEveryAssignee = (
  permissionResolutionInputs: PermissionResolutionInput[],
  assignees?: PermissionResolutionAssigneeInput[],
) => {
  if (assignees && assignees.length) {
    return assignees.reduce((acc, assignee) => {
      const assigneesPermissionResolution = permissionResolutionInputs.map((resolution) => ({
        ...resolution,
        assignee,
      }))

      return [...acc, ...assigneesPermissionResolution]
    }, [] as PermissionResolutionInput[])
  }

  return permissionResolutionInputs
}

export const generatePermissionResolutionInput = ({
  entityIdsCollection = {},
  permissionName,
  assignees,
}: GeneratePermissionResolutionInputProps): PermissionResolutionInput[] => {
  if (!permissionName) return []
  let permissionResolutionInput: PermissionResolutionInput[] = []

  const entityIdsCollectionKeys = Object.keys(entityIdsCollection) as PermissionScopeType[]

  if (entityIdsCollectionKeys && entityIdsCollectionKeys.length) {
    permissionResolutionInput = entityIdsCollectionKeys.reduce((acc, key) => {
      let permissionResolutionInputs: PermissionResolutionInput[] = acc
      const scopesIds = entityIdsCollection[key]
      if (scopesIds && scopesIds.length) {
        const permissionResolutions = scopesIds.reduce((acc, scopeId) => {
          let permissionInput = acc
          if (scopeId) {
            permissionInput = [
              ...permissionInput,
              {
                permission_name: permissionName,
                scope: {
                  id: scopeId,
                  type: key,
                },
              },
            ]
          }
          return permissionInput
        }, [] as PermissionResolutionInput[])

        permissionResolutionInputs = [...permissionResolutionInputs, ...permissionResolutions]
      }

      permissionResolutionInputs = getPermissionsInputsForEveryAssignee(permissionResolutionInputs, assignees)

      return [...permissionResolutionInputs]
    }, [] as PermissionResolutionInput[])
    // For introducer org, there is no scope
  } else if (permissionName && PermissionAssignmentNamesWithoutScope.includes(permissionName)) {
    permissionResolutionInput = getPermissionsInputsForEveryAssignee(
      [
        {
          permission_name: permissionName,
          scope: {},
        },
      ],
      assignees,
    )
  }

  return permissionResolutionInput
}

export const arraysOfArrayHaveSameDimensions = (arrayToCheck: any[][]) => {
  if (arrayToCheck.length <= 1 || !arrayToCheck.every((element) => element.length > 0)) {
    return false
  }

  const sum = arrayToCheck.reduce((acc, el) => Number(acc) + Number(el.length), 0)
  return arrayToCheck.length && Number.isInteger(Number(sum) / Number(arrayToCheck.length))
}

// this function should merge arrays fo the same length
export const mergeObjectArrays = (...args: object[][]): ResolvedPermissionResolution[] => {
  const merged: ResolvedPermissionResolution[] = []
  const unmerged = args
  const canMergeArrays = arraysOfArrayHaveSameDimensions(unmerged)

  while (unmerged.length && canMergeArrays) {
    const toMerge = unmerged.shift()
    // unmerged is expected to be an array of arrays
    // we check that the shifted element is an array
    if (Array.isArray(toMerge)) {
      for (let i = 0; i < toMerge.length; i++) {
        const element = toMerge[i]
        if (element && typeof element === 'object' && element.constructor === Object) {
          merged[i] = {
            ...merged[i],
            ...element,
          }
        }
      }
    }
  }
  return merged
}

// transformPermissionInputs iteratively creates an array of permission resolution requests
// and creates an array of frontend gates to be combined with the responses and provided as render props
export const transformPermissionInputs = ({
  inputs,
}: {
  inputs: PermissionInputWithAssignees[]
}): TransformedPermissionInputs => {
  let allGates: { gate: GateName }[] = []
  const resolved = (inputs || []).reduce((acc: PermissionResolutionInput[], input: PermissionInputWithAssignees) => {
    const { entityIdsCollection, gates, assignees } = input
    const resolvedPermissions: PermissionResolutionInput[] = gates.reduce(
      (acc: PermissionResolutionInput[], gate: GateName) => {
        let builtPermissions: PermissionResolutionInput[] = acc
        const permission = permissionBuilder({
          gate,
          entityIdsCollection,
          assignees,
        })

        if (permission && permission.length) {
          // collect gates to be checked against permissions input
          // and returned
          allGates = [...allGates, ...Array(permission.length).fill({ gate })]
          builtPermissions = [...builtPermissions, ...permission]
        }
        return builtPermissions
      },
      [],
    )

    return [...acc, ...resolvedPermissions]
  }, [])
  return {
    permissionInputs: resolved,
    allGates,
  }
}

// permissionBuilder first translates frontend gate names to backend permission names
// it then generates permission resolution inputs for the permission_resolution_requests
// post request
export const permissionBuilder = memoize(
  ({ gate, entityIdsCollection, assignees }: PermissionBuilderProps): PermissionResolutionInput[] => {
    let permissionName: Maybe<PermissionAssignmentName> = null
    let result: PermissionResolutionInput[] = []
    switch (gate) {
      case GateName.VIEW_MI_DASHBOARD: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_VIEWCOMPLIANCEDASHBOARD
        break
      }
      case GateName.VIEW_BROKER_DASHBOARD:
      case GateName.VIEW_CASE_SECTION: {
        permissionName = PermissionAssignmentName.CASE_VIEW_CASE
        break
      }
      case GateName.VIEW_CLIENT_SECTION: {
        permissionName = PermissionAssignmentName.CLIENT_VIEW_CLIENT
        break
      }
      case GateName.CLIENT_VIEW_PROTECTED: {
        permissionName = PermissionAssignmentName.CLIENT_VIEW_PROTECTED
        break
      }
      case GateName.VIEW_CASE_FLAGS:
      case GateName.REGULATED_COMPLIANCE_VIEWCASENOTES: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_VIEWCASENOTES
        break
      }
      case GateName.VIEW_OVERRIDE_CLIENT_EIDV: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_APPROVEEIDVOVERRIDE
        break
      }
      case GateName.VIEW_CASE_COMPLIANCE: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_VIEWCASEDETAIL
        break
      }
      case GateName.EDIT_CASE: {
        permissionName = PermissionAssignmentName.CASE_EDIT_CASE
        break
      }
      case GateName.EDIT_CLIENT: {
        permissionName = PermissionAssignmentName.CLIENT_EDIT_CLIENT
        break
      }
      case GateName.EDIT_CLIENT_RESTRICTED: {
        permissionName = PermissionAssignmentName.CLIENT_EDIT_CLIENT_RESTRICTED
        break
      }
      case GateName.REGULATED_COMPLIANCE_APPROVEAML: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_APPROVEAML
        break
      }
      case GateName.REGULATED_COMPLIANCE_APPROVEADVICE: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_APPROVEADVICE
        break
      }
      case GateName.EDIT_USER_STATUS_COMPANY_SETTINGS: {
        permissionName = PermissionAssignmentName.USER_EDIT_USER
        break
      }
      case GateName.USER_EDIT_USER: {
        permissionName = PermissionAssignmentName.USER_EDIT_USER
        break
      }
      case GateName.ORGANISATION_CREATE_AR_ORG: {
        permissionName = PermissionAssignmentName.ORGANISATION_CREATE_AR_ORG
        break
      }
      case GateName.EDIT_ORGANISATION_MORTGAGE_FEES_COMPANY_SETTINGS: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_FEES
        break
      }
      case GateName.EDIT_ORGANISATION_COMPANY_INFORMATION_COMPANY_SETTINGS: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_ORG
        break
      }
      case GateName.EDIT_ORGANISATION_GENERAL_INSURANCE_COMPANY_SETTINGS: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_METADATA
        break
      }

      case GateName.ORGANISATION_EDIT_SYSTEM_METADATA: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_SYSTEM_METADATA
        break
      }

      case GateName.EDIT_GENERAL_INSURANCE: {
        permissionName = PermissionAssignmentName.REGULATED_GENERALINSURANCE
        break
      }
      case GateName.EDIT_GENERAL_INSURANCE_PROVISIONAL: {
        permissionName = PermissionAssignmentName.REGULATED_GENERALINSURANCE_PROVISIONAL
        break
      }
      case GateName.ORGANISATION_CREATE_INTRODUCER_ORG: {
        permissionName = PermissionAssignmentName.ORGANISATION_CREATE_INTRODUCER_ORG
        break
      }
      case GateName.USER_CREATE_USER: {
        permissionName = PermissionAssignmentName.USER_CREATE_USER
        break
      }
      case GateName.ORGANISATION_EDIT_ORG: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_ORG
        break
      }
      case GateName.CASE_CHANGE_OWNER: {
        permissionName = PermissionAssignmentName.CASE_CHANGE_OWNER
        break
      }
      case GateName.ORGANISATION_VIEW: {
        permissionName = PermissionAssignmentName.ORGANISATION_VIEW
        break
      }
      case GateName.ORGANISATION_REPORTING: {
        permissionName = PermissionAssignmentName.ORGANISATION_REPORTING
        break
      }
      case GateName.ORGANISATION_EDIT_METADATA: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_METADATA
        break
      }
      case GateName.ACCOUNTING: {
        permissionName = PermissionAssignmentName.ACCOUNTING
        break
      }
      case GateName.DELETE_LEDGER: {
        permissionName = PermissionAssignmentName.DELETE_LEDGER
        break
      }
      case GateName.CREATE_CASE_LEDGER: {
        permissionName = PermissionAssignmentName.CREATE_CASE_LEDGER
        break
      }
      case GateName.ORGANISATION_EDIT_PROTECTED: {
        permissionName = PermissionAssignmentName.ORGANISATION_EDIT_PROTECTED
        break
      }
      case GateName.EDIT_CASE_LEDGER: {
        permissionName = PermissionAssignmentName.EDIT_CASE_LEDGER
        break
      }
      case GateName.ACRE_ORGANISATION_CREATE_REG_ORG: {
        permissionName = PermissionAssignmentName.ACRE_ORGANISATION_CREATE_REG_ORG
        break
      }
      case GateName.REGULATED_MORTGAGE_PROVISIONAL: {
        permissionName = PermissionAssignmentName.REGULATED_MORTGAGE_PROVISIONAL
        break
      }
      case GateName.REGULATED_MORTGAGE: {
        permissionName = PermissionAssignmentName.REGULATED_MORTGAGE
        break
      }
      case GateName.REGULATED_EQUITYRELEASE_PROVISIONAL: {
        permissionName = PermissionAssignmentName.REGULATED_EQUITYRELEASE_PROVISIONAL
        break
      }
      case GateName.REGULATED_EQUITYRELEASE: {
        permissionName = PermissionAssignmentName.REGULATED_EQUITYRELEASE
        break
      }
      case GateName.REGULATED_PROTECTIONINSURANCE_PROVISIONAL: {
        permissionName = PermissionAssignmentName.REGULATED_PROTECTIONINSURANCE_PROVISIONAL
        break
      }
      case GateName.REGULATED_PROTECTIONINSURANCE: {
        permissionName = PermissionAssignmentName.REGULATED_PROTECTIONINSURANCE
        break
      }
      case GateName.REGULATED_GENERALINSURANCE_PROVISIONAL: {
        permissionName = PermissionAssignmentName.REGULATED_GENERALINSURANCE_PROVISIONAL
        break
      }
      case GateName.REGULATED_GENERALINSURANCE: {
        permissionName = PermissionAssignmentName.REGULATED_GENERALINSURANCE
        break
      }
      case GateName.REGULATED_DEBTCONSOLIDATION: {
        permissionName = PermissionAssignmentName.REGULATED_DEBTCONSOLIDATION
        break
      }
      case GateName.REGULATED_COMPLIANCE_VIEWCASEDETAIL: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_VIEWCASEDETAIL
        break
      }
      case GateName.REGULATED_COMPLIANCE_APPROVECASES: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_APPROVECASES
        break
      }
      case GateName.REGULATED_COMPLIANCE_ADDCASENOTES: {
        permissionName = PermissionAssignmentName.REGULATED_COMPLIANCE_ADDCASENOTES
        break
      }
      case GateName.PERMISSIONS_EDIT_REGULATED_PERMISSION: {
        permissionName = PermissionAssignmentName.PERMISSIONS_EDIT_REGULATED_PERMISSION
        break
      }
      case GateName.PERMISSIONS_VIEW_PERMISSIONS: {
        permissionName = PermissionAssignmentName.PERMISSIONS_VIEW_PERMISSIONS
        break
      }
      case GateName.FIRM_MANAGETEMPLATES: {
        permissionName = PermissionAssignmentName.FIRM_MANAGETEMPLATES
        break
      }
      case GateName.USER_EDIT_USER_METADATA: {
        permissionName = PermissionAssignmentName.USER_EDIT_USER_METADATA
        break
      }
      case GateName.GROUP_EDIT_GROUP: {
        permissionName = PermissionAssignmentName.GROUP_EDIT_GROUP
        break
      }
      case GateName.GROUP_VIEW_GROUP: {
        permissionName = PermissionAssignmentName.GROUP_VIEW_GROUP
        break
      }
      case GateName.PERMISSIONS_EDIT_PERMISSION: {
        permissionName = PermissionAssignmentName.PERMISSIONS_EDIT_PERMISSION
        break
      }
      case GateName.MANAGE_ORGANISATION: {
        permissionName = PermissionAssignmentName.MANAGE_ORGANISATION
        break
      }
      case GateName.USER_EDIT_AUTHENTICATION: {
        permissionName = PermissionAssignmentName.USER_EDIT_AUTHENTICATION
        break
      }
      case GateName.ACRE_CASE_IMPORT: {
        permissionName = PermissionAssignmentName.ACRE_CASE_IMPORT
        break
      }
      default: {
        permissionName = null
        break
      }
    }
    if (permissionName) {
      result = generatePermissionResolutionInput({
        entityIdsCollection,
        permissionName,
        assignees,
      })
    }
    return result
  },
  ({ gate, entityIdsCollection, assignees }) => JSON.stringify({ gate, entityIdsCollection, assignees }),
)
