import { Dependant, DependantRelationship } from '@acre/graphql'

const dependant: Dependant = {
  __typename: 'Dependant',
  relationship: DependantRelationship.Child,
  id: 'TEST_ID',
  internalHash: null,
  name: null,
  date_of_birth: null,
  dependency_end_date: null,
  relationship_other: null,
  title: null,
}

export default dependant
