export enum MissingFieldsSections {
  FACT_FIND = 'fact_find',
  CASE_DETAILS = 'case_details',
  MORTGAGE_DETAILS = 'mortgage_details',
  PROPERTY_DETAILS = 'property_details',
  SUITABILITY = 'suitability',
  PROTECTION_SUITABILITY_PER_PRODUCT = 'protection_suitability_per_product',
}

export type MissingFieldsWithItemIds = {
  [itemId: string]: {
    [category: string]: string[]
  }
}

export type MissingFields = {
  [MissingFieldsSections.FACT_FIND]: MissingFieldsWithItemIds
  [MissingFieldsSections.CASE_DETAILS]: MissingFieldsWithItemIds
  [MissingFieldsSections.MORTGAGE_DETAILS]: MissingFieldsWithItemIds
  [MissingFieldsSections.PROPERTY_DETAILS]: MissingFieldsWithItemIds
  [MissingFieldsSections.SUITABILITY]: MissingFieldsWithItemIds
  [MissingFieldsSections.PROTECTION_SUITABILITY_PER_PRODUCT]: MissingFieldsWithItemIds
}
