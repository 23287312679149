import { ReactElement, ReactNode } from 'react'
import { omit } from 'lodash'

export const omitProperties = <T extends Object>(obj: T, properties: string | string[]) => {
  return omit(obj, properties)
}

//
// Used for the field list item rendering
//

//
// Types
//

export type ValueOf<T> = T[keyof T]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface Item<T> {
  message: string
}

export interface UnformattedItem<T> extends Item<T> {
  value: ReactNode
}

export interface SingleKeyItem<T> extends Item<T> {
  key: keyof T
  // TODO FEAT-110 Revisit types for this formatterR
  // formatter?: (value: ValueOf<T>) => ReactNode (More strict typing doesn't seem to work?)
  // Using 'any' because the union of string, number, boolean is incompatible with any single primitive
  formatter?: (value: any) => ReactNode
}

export interface MultiKeyItem<T> extends Item<T> {
  keys: string[]
  formatter?: (values: Partial<T>) => ReactNode
}

//
// Typeguards
//
export function isSingleKeyItem<T>(item: Item<T>): item is SingleKeyItem<T> {
  return (item as SingleKeyItem<T>).key !== undefined
}

export function isMultiKeyItem<T>(item: Item<T>): item is MultiKeyItem<T> {
  return (item as MultiKeyItem<T>).keys !== undefined
}

export function isUnformattedItem<T>(item: Item<T>): item is UnformattedItem<T> {
  return (item as UnformattedItem<T>).value !== undefined
}

//
// Formatters
//

export function formatValues<T>(method: (value: T) => ReactNode, fallback: ReactElement, value?: T): ReactNode {
  return value !== null && value !== undefined ? method(value) : fallback
}

export function formatValue<T>(
  method: (value: ValueOf<T>) => ReactNode,
  fallback: ReactElement,
  value?: ValueOf<T> | null,
): ReactNode {
  return value !== null && value !== undefined ? method(value) : fallback
}
