import { AxiosError } from 'axios'
import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import { CdmGetClientRequest, CdmGetClientResponse, CdmGetClientVersionResponse } from '../service/luther/model'
import { createBatchLoaderFn, createBatchLoaderFnWithReducer, getFirstUsedKey } from '../utils/dataloader'

const fetchClientsBatchFn = createBatchLoaderFnWithReducer<CdmGetClientRequest, CdmGetClientResponse>('/client', {
  reduceKeys: ['client_ids'],
  defaultParams: {
    client_details: true,
  },
})

const fetchClientAtVersionBatchFn = createBatchLoaderFn<
  { client_id: string; version: number },
  CdmGetClientVersionResponse
>(({ version, client_id }) => `/client/${client_id}/version/${version}`, {
  forwardParams: false,
})

export const createClientLoader = () => {
  return new DataLoader(
    async (params: CdmGetClientRequest[]) => {
      const { protectedParams, unprotectedParams } = splitProtectedParams(params)

      // We don't want to batch the batch calls that have show_protected query param together with the
      // ones that do not. The API was designed so that whenever show_protected is passed in it will only
      // return the fields specified in show_protected array and none of the others
      const protectedResponse = protectedParams.length > 0 ? await fetchClientsBatchFn(protectedParams) : null
      const unprotectedResponse = unprotectedParams.length > 0 ? await fetchClientsBatchFn(unprotectedParams) : null

      const batchKey = getFirstUsedKey(params[0], ['client_ids'])

      return params.map((param) => {
        const keys = param[batchKey]

        // NOTE: Is this a bug somewhere? It seems like the keys are not always an array
        const key = Array.isArray(keys) ? keys[0] : keys

        const response = hasProtectedParams(param) ? protectedResponse : unprotectedResponse
        const client = response?.clients?.find((client) => client.client_id === key)

        return (
          client ||
          new GraphQLError(`No client found for query: ${JSON.stringify(param)}`, {
            extensions: { code: 'NOT_FOUND', payload: param, status: 404, expection: response?.exception },
          })
        )
      })
    },
    {
      maxBatchSize: 200,
      cacheKeyFn: JSON.stringify,
    },
  )
}

export const createClientVersionLoader = () =>
  new DataLoader(
    async (params: { client_id: string; version: number }[]) => {
      const responses = await fetchClientAtVersionBatchFn(params)

      return params.map((param) => {
        const response = responses.find(
          (response) => (response as CdmGetClientVersionResponse).client?.client_id === param.client_id,
        ) as CdmGetClientVersionResponse
        const error = responses.find((response) => (response as AxiosError).isAxiosError) as AxiosError

        return (
          response?.client ||
          new GraphQLError(
            error?.message || error.response?.statusText || `No client found for query: ${JSON.stringify(param)}`,
            {
              extensions: {
                code: 'NOT_FOUND',
                payload: param,
                status: error.response?.status || 404,
                expection: error.response || response?.exception,
              },
            },
          )
        )
      })
    },
    {
      cacheKeyFn: JSON.stringify,
      maxBatchSize: 200,
    },
  )

export const clientLoader = createClientLoader()
export const clientVersionLoader = createClientVersionLoader()

// Helpers

/**
 * Identifies if the query params contain a show_protected param
 * @param param client query params
 */
const hasProtectedParams = (param: CdmGetClientRequest) => {
  return Object.keys(param).includes('show_protected')
}

/**
 * Splits up params array into two arrays of protected and unprotectedParams
 * because we never wanna batch calls that have show_protected query param together with the
 * ones that do not. The API was designed so that whenever show_protected is passed in
 * it will only return the fields specified in show_protected array and none of the others
 * @param params Params that we can use to batch calls to the client api
 */
const splitProtectedParams = (params: CdmGetClientRequest[]) => {
  return params.reduce(
    (acc, param) => {
      if (hasProtectedParams(param)) {
        acc.protectedParams.push(param)
      } else {
        acc.unprotectedParams.push(param)
      }
      return acc
    },
    {
      protectedParams: [] as CdmGetClientRequest[],
      unprotectedParams: [] as CdmGetClientRequest[],
    },
  )
}
