import { TypePolicy } from '@apollo/client'

import mortgageStatus from './mortgageStatus'

export default {
  keyFields: ['document_id'],
  fields: {
    mortgageStatus,
  },
} as TypePolicy
