import React from 'react'
import { Navigate } from 'react-router-dom'

export interface RouteBlockerProps {
  redirectTo?: string
  shouldRender?: boolean
  fallback?: React.ReactNode | React.FC
  children: React.ReactNode
  loading?: boolean
  loadingComponent?: React.ReactNode
}

function RouteBlocker({
  redirectTo,
  loading,
  shouldRender = true,
  fallback,
  loadingComponent,
  children,
}: RouteBlockerProps) {
  if (!shouldRender) {
    if (fallback) {
      return <>{fallback}</>
    }

    return redirectTo ? <Navigate to={redirectTo} replace /> : null
  }

  return <>{loading ? loadingComponent || null : children}</>
}

export default React.memo(RouteBlocker)
