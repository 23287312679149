import React, { Key, useCallback } from 'react'
import type { ArrayPath,FieldValues } from 'react-hook-form'

import type { FieldArraySchema } from '../../types/schema'
import FieldArray, { FieldArrayRenderFn } from '../fieldArray/FieldArray'
import SchemaFormComponent from './SchemaFormComponent'

export interface SchemaFieldArrayComponentProps<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
> extends FieldArraySchema<TFieldValues> {
  name: TName
}

const _SchemaFieldArrayComponent = <TFieldValues extends FieldValues = FieldValues>(
  props: SchemaFieldArrayComponentProps<TFieldValues>,
) => {
  const renderFieldArray = useCallback<FieldArrayRenderFn<TFieldValues>>(
    ({ name, index }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return props.parts.map(({ name: partName, components, ...component }) => (
        <SchemaFormComponent<TFieldValues, ArrayPath<TFieldValues>>
          key={partName as Key}
          {...component}
          name={`${name}.${index}.${String(partName)}` as ArrayPath<TFieldValues>}
        />
      ))
    },
    [props.parts],
  )

  return <FieldArray<TFieldValues> {...props} render={renderFieldArray} />
}

const SchemaFieldArrayComponent = React.memo(_SchemaFieldArrayComponent) as typeof _SchemaFieldArrayComponent

export default SchemaFieldArrayComponent
