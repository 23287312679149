import React from 'react'
import classNames from 'classnames'
import type { ComponentProps } from 'react'

import { testHandle } from '@acre/utils'

import { getColourHex } from '../../utils/colourHex'
import { Variant } from '../../utils/constants'
import { Colour, ColourId } from '../../utils/types'
import { TagStyle } from './Tag.styles'

export interface TagProps extends Omit<ComponentProps<typeof TagStyle>, 'color'> {
  text: string | React.ReactElement
  colourID?: ColourId
  onClick?: () => void
  id?: string
  hasMargin?: boolean
  variant?: Variant
  colour?: Colour
  multiline?: boolean
}

const Tag = ({
  id,
  colourID = ColourId.LightRed,
  colour,
  text,
  onClick,
  hasMargin = true,
  variant,
  multiline = false,
  ...rest
}: TagProps) => {
  const colourHex = colourID ? getColourHex(colourID) : colour
  const dataTestId = id ? testHandle(id) : null

  return (
    <TagStyle
      id={id}
      data-testid={dataTestId}
      onClick={onClick}
      className={classNames({ clickable: Boolean(onClick) })}
      hasMargin={hasMargin}
      variant={variant}
      color={colourHex || ''}
      multiline={multiline}
      {...rest}
    >
      {text}
    </TagStyle>
  )
}

export default Tag
