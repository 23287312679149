import { addMonths, differenceInMonths, format } from 'date-fns'
import createDecorator from 'final-form-calculate'

import { Commission, CommissionFrequency, ProtectionProductDetails } from '@acre/graphql'
export const messagePrefix = 'protection.common'
import { Decorator } from 'final-form'
import * as yup from 'yup'

import {
  ERROR_COMMISSION_RENEWAL_START_DATE,
  ERROR_DATE_GENERIC,
  ERROR_NUMBER_OF_MONTHS_RENEWAL_COMMISSION_STARTS_IN,
  ERROR_REQUIRED,
  isValidDate,
  unwrapErrors,
} from '@acre/utils'

export enum CommissionType {
  Indemnified = 'indemnified',
  NonIndemnified = 'nonIndemnified',
}

export enum CommissionStartsIn {
  SpecificDate = 'specificDate',
  Months = 'months',
}

export const getRenewalCommissionStartsInOptions = (formatMessage: (value: string) => string) => [
  {
    label: formatMessage(`${messagePrefix}.specificDate`),
    value: CommissionStartsIn.SpecificDate,
  },
  {
    label: formatMessage(`${messagePrefix}.months`),
    value: CommissionStartsIn.Months,
  },
]

// Preformats the fields from the api for fields in the form
export const getProtectionProductInitialValues = (
  details: ProtectionProductDetails,
  isBusinessProtectionCase?: boolean,
) => ({
  commission_description: details.commission?.description,
  start_date: details.start_date,
  underwritten_premium_amount: isBusinessProtectionCase
    ? details.recommendation_premium_amount
    : details.underwritten_premium_amount,
  policy_number: details.policy_number,
  commission_type: details.commission?.initial_commission_end_date
    ? CommissionType.NonIndemnified
    : CommissionType.Indemnified,
  initial_commission: details.commission?.initial_commission,
  number_of_months_received_initial_commission: details.commission?.initial_commission_end_date
    ? // + 1 because differenceInMonths results in one less month than what's been entered
      differenceInMonths(
        new Date(details.commission?.initial_commission_end_date).setHours(0, 0, 0, 0),
        new Date().setHours(0, 0, 0, 0),
      ).toString()
    : null,
  renewal_commission: details.commission?.renewal_commission,
  renewal_commission_starts_in: CommissionStartsIn.SpecificDate,
  renewal_commission_start_date: details.commission?.renewal_commission_start_date,
  number_of_months_renewal_commission_starts_in: null as null | string,
})

export type protectionProductFormType = ReturnType<typeof getProtectionProductInitialValues>

// Preformats the fields from form for fields to be sent to the api
export const convertProtectionProductValuesForApi = (values: protectionProductFormType) => {
  let renewal_commission_start_date: string | null = null
  if (values.renewal_commission_start_date) {
    renewal_commission_start_date = format(new Date(values.renewal_commission_start_date), 'yyyy-MM-dd')
  } else if (values.number_of_months_renewal_commission_starts_in) {
    renewal_commission_start_date = format(
      addMonths(new Date().setHours(0, 0, 0, 0), parseInt(values.number_of_months_renewal_commission_starts_in)),
      'yyyy-MM-dd',
    )
  }

  const isNonIndemnifiedCommission =
    values.commission_type === CommissionType.NonIndemnified && values.number_of_months_received_initial_commission

  return {
    policy_number: values.policy_number,
    start_date: values.start_date,
    underwritten_premium_amount: values.underwritten_premium_amount,
    commission: {
      initial_commission: values.initial_commission,
      // commission frequency is monthly by default
      renewal_commission_frequency: CommissionFrequency.Monthly,
      initial_commission_end_date: isNonIndemnifiedCommission
        ? format(
            addMonths(new Date().setHours(0, 0, 0, 0), parseInt(values.number_of_months_received_initial_commission!)),
            'yyyy-MM-dd',
          )
        : null,
      commission_frequency: isNonIndemnifiedCommission ? CommissionFrequency.Monthly : null,
      renewal_commission: values.renewal_commission,
      renewal_commission_start_date,
    },
  }
}

export const protectionProductDecorator = createDecorator(
  //Resets number_of_months_received_initial_commission when commission_type changes
  {
    field: 'commission_type',
    updates: {
      number_of_months_received_initial_commission: (commissionType, allValues) => {
        const formVals = allValues as protectionProductFormType
        return commissionType === CommissionType.Indemnified
          ? null
          : formVals?.number_of_months_received_initial_commission
      },
    },
  },
  {
    //Resets renewal_commission_start_date or number_of_months_renewal_commission_starts_in when renewal_commission_starts_in changes
    field: 'renewal_commission_starts_in',
    updates: {
      renewal_commission_start_date: (startsIn, allValues) => {
        const formVals = allValues as protectionProductFormType
        return startsIn === CommissionStartsIn.Months ? null : formVals.renewal_commission_start_date
      },
      number_of_months_renewal_commission_starts_in: (startsIn, allValues) => {
        const formVals = allValues as protectionProductFormType
        return startsIn === CommissionStartsIn.SpecificDate
          ? null
          : formVals.number_of_months_renewal_commission_starts_in
      },
    },
  },
) as Decorator<protectionProductFormType, protectionProductFormType>

// Schema validation for the protection product form
export const protectionCommissionSchema = yup.object<ReturnType<typeof getProtectionProductInitialValues>>().shape(
  {
    number_of_months_received_initial_commission: yup
      .string()
      .nullable()
      .when('commission_type', {
        is: (commission_type: CommissionType) => commission_type === CommissionType.NonIndemnified,
        then: (schema) => schema.required(ERROR_REQUIRED),
      }),
    start_date: yup
      .string()
      .typeError(ERROR_DATE_GENERIC)
      .nullable()
      .notRequired()
      .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value)),
    commission_type: yup.string(),
    renewal_commission_starts_in: yup.string(),
    number_of_months_renewal_commission_starts_in: yup
      .string()
      .nullable()
      .notRequired()
      .when(['renewal_commission', 'renewal_commission_starts_in'], {
        is: (renewal_commission: Commission, renewal_commission_starts_in: CommissionStartsIn) =>
          Boolean(renewal_commission) && renewal_commission_starts_in === CommissionStartsIn.Months,
        then: (schema) => schema.required(ERROR_REQUIRED),
      })
      .when(['number_of_months_received_initial_commission', 'renewal_commission_starts_in'], {
        is: (number_of_months_received_initial_commission: number, renewal_commission_starts_in: CommissionStartsIn) =>
          number_of_months_received_initial_commission && renewal_commission_starts_in === CommissionStartsIn.Months,
        then: (schema) =>
          schema.test(
            'isValid',
            ERROR_COMMISSION_RENEWAL_START_DATE,
            function (this, number_of_months_renewal_commission_starts_in) {
              if (!number_of_months_renewal_commission_starts_in) {
                return true
              }

              const numberOfMonthsReveceivedInitialCommission = this.parent.number_of_months_received_initial_commission

              return parseInt(number_of_months_renewal_commission_starts_in) >
                parseInt(numberOfMonthsReveceivedInitialCommission)
                ? true
                : this.createError({
                    message: ERROR_NUMBER_OF_MONTHS_RENEWAL_COMMISSION_STARTS_IN,
                    params: { values: { n: numberOfMonthsReveceivedInitialCommission } },
                  })
            },
          ),
      }),
    renewal_commission_start_date: yup
      .string()
      .nullable()
      .notRequired()
      .when(['renewal_commission', 'renewal_commission_starts_in'], {
        is: (renewal_commission: Commission, renewal_commission_starts_in: CommissionStartsIn) =>
          Boolean(renewal_commission) && renewal_commission_starts_in === CommissionStartsIn.SpecificDate,
        then: (schema) => schema.required(ERROR_REQUIRED),
      })
      .when(['number_of_months_received_initial_commission', 'renewal_commission_starts_in'], {
        is: (number_of_months_received_initial_commission: number, renewal_commission_starts_in: CommissionStartsIn) =>
          number_of_months_received_initial_commission &&
          renewal_commission_starts_in === CommissionStartsIn.SpecificDate,
        then: (schema) =>
          schema.test('isValid', ERROR_COMMISSION_RENEWAL_START_DATE, function (this, renewal_commission_start_date) {
            if (!renewal_commission_start_date) {
              return true
            }

            const initialCommissionEndDate = addMonths(
              new Date(),
              parseInt(this.parent.number_of_months_received_initial_commission),
            )

            const renewalCommissionStartDate = new Date(renewal_commission_start_date)

            return initialCommissionEndDate.setHours(0, 0, 0, 0) < renewalCommissionStartDate.setHours(0, 0, 0, 0)
              ? true
              : this.createError({
                  message: ERROR_COMMISSION_RENEWAL_START_DATE,
                  params: { values: { initialCommissionEndDate: format(initialCommissionEndDate, 'dd/MM/yyy') } },
                })
          }),
      }),
    renewal_commission: yup
      .string()
      .nullable()
      .when(['renewal_commission_start_date', 'number_of_months_renewal_commission_starts_in'], {
        is: (
          renewal_commission_start_date: string,
          number_of_months_renewal_commission_starts_in: CommissionStartsIn,
        ) => renewal_commission_start_date || number_of_months_renewal_commission_starts_in,
        then: (schema) => schema.required(ERROR_REQUIRED),
      }),
  },
  [
    ['number_of_months_received_initial_commission', 'commission_type'],
    ['number_of_months_renewal_commission_starts_in', 'number_of_months_received_initial_commission'],
    ['number_of_months_renewal_commission_starts_in', 'renewal_commission_starts_in'],
    ['number_of_months_renewal_commission_starts_in', 'renewal_commission'],
    ['renewal_commission_start_date', 'number_of_months_received_initial_commission'],
    ['renewal_commission_start_date', 'renewal_commission_starts_in'],
    ['renewal_commission_start_date', 'renewal_commission'],
  ],
)

export const validate = unwrapErrors(protectionCommissionSchema)
