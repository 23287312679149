import { ReactElement } from 'react'
import { MockedProvider,MockedProvider as MockedProviderHooks } from '@apollo/client/testing'
import { CaseContextProvider, PermissionProvider } from '@broker-crm-contexts'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { render } from '@testing-library/react'
import { enGB } from 'date-fns/locale'
import { InitialEntry } from 'history'
import { IntlProvider } from 'react-intl'
import { MemoryRouter } from 'react-router-dom'

import { FlagProvider, flagsAllEnabled, flattenMessages, getMessages, Locales } from '@acre/utils'
import { muiTheme, ThemeProvider } from '@acre/design-system'

import messages from '../../intl/messagesBrokerCrm'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

export default (testCaseContext: any, component: ReactElement, initialEntries?: InitialEntry[]) => {
  return render(
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
        <ThemeProvider theme={muiTheme}>
          <MockedProvider mocks={[]} addTypename={false}>
            <MockedProviderHooks mocks={[]} addTypename={false}>
              <MemoryRouter initialEntries={initialEntries ?? undefined}>
                <FlagProvider value={flagsAllEnabled}>
                  <PermissionProvider value={{ gates: [] }}>
                    <CaseContextProvider value={{ ...testCaseContext }}>{component}</CaseContextProvider>
                  </PermissionProvider>
                </FlagProvider>
              </MemoryRouter>
            </MockedProviderHooks>
          </MockedProvider>
        </ThemeProvider>
      </IntlProvider>
      ,
    </LocalizationProvider>,
  )
}
