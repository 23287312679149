import { userType } from '@broker-crm-utils'

import {
  isBusinessProtection,
  isGeneralProtection,
  isHomeInsurance,
  shouldShowProtectionReferral,
  shouldShowSuitabilityReport,
} from '@acre/utils'
import {
  CaseVersion,
  ClientVersion,
  GetCaseQuery,
  GetClientQuery,
  GetUserQuery,
  isNaturalClient as isNaturalClientHelper,
  Organisation,
  OrganisationType,
  User,
} from '@acre/graphql'

import { checkIfFirstTimeUser, isAllowedToOnboardOrg } from '../pages/Onboarding/Onboarding.helpers'

export const isHomeInsuranceCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  isHomeInsurance(caseValue.details.preference_mortgage_reason)

export const isBusinessProtectionCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  isBusinessProtection(caseValue.details.preference_mortgage_reason)

export const isNotBusinessProtectionCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  !isBusinessProtection(caseValue.details.preference_mortgage_reason)

export const isProtectionReferralRequired = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  shouldShowProtectionReferral(caseValue.details.preference_mortgage_reason)

export const isProtectionCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  isGeneralProtection(caseValue.details.preference_mortgage_reason)

export const hasProtectionAdvice = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  shouldShowSuitabilityReport(caseValue.details.protection?.has_client_accepted_protection_advice)

export const isNotProtectionCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  !isGeneralProtection(caseValue.details.preference_mortgage_reason)

export const isNaturalClient = (clientValue: ClientVersion | GetClientQuery['client']) =>
  isNaturalClientHelper(clientValue.details)

export const isNonNaturalClient = (clientValue: ClientVersion | GetClientQuery['client']) =>
  !isNaturalClientHelper(clientValue.details)

export const isNaturalClientOnCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  isNaturalClientHelper(caseValue.details.clients[0].details)

export const isNonNaturalClientOnCase = (caseValue: CaseVersion | GetCaseQuery['case']) =>
  !isNaturalClientHelper(caseValue.details.clients[0].details)

export const isDAOrg = (orgValue: Organisation) => orgValue.type === OrganisationType.Da

export const shouldOnboardUser = (user: User | GetUserQuery['user']) => {
  const isPrincipal = user?.primary_role_id == userType.principal

  const { isFirstTimeOrg, isFirstTimeUser } = checkIfFirstTimeUser(user)

  if ((!isPrincipal && isFirstTimeOrg) || isFirstTimeUser) {
    return true
  } else {
    return false
  }
}

export const shouldOnboardOrg = (user: User | GetUserQuery['user']) => {
  const allowedToOnboardOrg =
    user?.organisation?.type &&
    user?.primary_role_id &&
    isAllowedToOnboardOrg({
      orgType: user.organisation.type,
      userRole: user.primary_role_id,
    })

  const isFirstTimeOrg = (user as User)?.organisation?.first_time_access?.complete === false

  if (allowedToOnboardOrg && isFirstTimeOrg) {
    return true
  }
  return false
}
