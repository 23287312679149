import React from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'
import { useFormState } from 'react-hook-form'

import { ButtonNewest as Button, ButtonNewestProps as ButtonProps } from '@acre/design-system'
import { useFormApi, useFormContext } from '@acre/forms'

import { getFormSubmitActionUtilityClass } from './formSubmitActionClasses'

export interface FormSubmitActionProps extends ButtonProps {}

const FormSubmitActionRoot = styled(Button, {
  name: 'FormSubmitAction',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const useUtilityClasses = (ownerState: Partial<FormSubmitActionProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getFormSubmitActionUtilityClass, ownerState.classes)
}

const _FormSubmitAction = (props: FormSubmitActionProps) => {
  const { className, classes, disabled, size = 'large', ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes })

  const { control } = useFormContext()

  const { submitMode } = useFormApi()

  const { isValid, isSubmitting } = useFormState({ control })

  return (
    <FormSubmitActionRoot
      {...rootProps}
      disabled={Boolean(disabled) || (submitMode === 'block' && !isValid)}
      loading={isSubmitting}
      type="submit"
      size={size}
      className={classnames(slotClasses.root, className)}
    />
  )
}

const FormSubmitAction = React.memo(_FormSubmitAction) as typeof _FormSubmitAction

export default FormSubmitAction
