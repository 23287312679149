import React, { ChangeEvent, FocusEvent, ReactElement, useMemo } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import Label from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import { FormControlWrapper, LabelAndInputWrapper } from '../../styles/form-control.styles'
import {
  CustomCheckbox,
  RoundCheckbox,
  StyledCheckboxInput,
  StyledCheckboxWrapper,
  StyledInput,
} from './Checkbox.styles'
import CheckIcon from '../../assets/check.svg?react'
import RoundCheckIcon from '../../assets/roundCheck.svg?react'

export type CheckboxProps = {
  id: string
  checked?: boolean
  disabled?: boolean
  label?: string | ReactElement
  subLabel?: string | ReactElement
  ariaLabel?: string
  round?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  readOnly?: boolean
  variant?: Variant
  shouldStopPropagation?: boolean
}

const Checkbox = ({
  id,
  label,
  subLabel,
  checked,
  ariaLabel,
  disabled: disabledProp,
  onChange,
  onFocus,
  onBlur,
  round = false,
  readOnly = false,
  variant = 'compactRightAligned',
  shouldStopPropagation = false,
}: CheckboxProps) => {
  const disabled = useFieldDisabledState(disabledProp)

  const className = useMemo(
    () =>
      classNames({
        checked,
        disabled,
        ['custom-checkbox']: true,
      }),
    [checked, disabled],
  )

  const CheckboxComponent = round ? RoundCheckbox : CustomCheckbox
  const IconComponent = round ? RoundCheckIcon : CheckIcon

  return (
    <FormControlWrapper data-testid={`${id}Wrapper`} className={className}>
      <LabelAndInputWrapper variant={variant}>
        {label && <Label htmlFor={id} isDisabled={disabled} text={label} variant={variant} />}
        <StyledCheckboxInput data-testid={testHandle(id)}>
          <MatomoWrapper onChange={onChange} id={id} eventType={'checkboxChange'}>
            <StyledCheckboxWrapper key={id} htmlFor={id} className={className}>
              <StyledInput
                id={id}
                data-testid={testHandle(`${id}Input`)}
                type="checkbox"
                aria-label={(label as string) || ariaLabel}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={(e) => e.stopPropagation()}
                readOnly={readOnly}
              />
              <CheckboxComponent onClick={(e) => shouldStopPropagation && e.stopPropagation()} className={className}>
                <IconComponent role="checkbox" />
              </CheckboxComponent>
              {subLabel && (
                <Box pl={2}>
                  <Label htmlFor={id} text={subLabel} />
                </Box>
              )}
            </StyledCheckboxWrapper>
          </MatomoWrapper>
        </StyledCheckboxInput>
      </LabelAndInputWrapper>
    </FormControlWrapper>
  )
}

export default withDisabled(Checkbox)
