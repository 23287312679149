export const getValuesFromMonths = (inMonths: number) => {
  const months = Number(inMonths) % 12
  const years = (Number(inMonths) - months) / 12

  return [years, months]
}

export const getInitialValues = (inValue: number) => {
  return getValuesFromMonths(inValue)
}

type GetValuesArgs = {
  values: { months?: number; years?: number }
  hideMonths?: boolean
}

export const getMonthsFromValues = ({ values, hideMonths }: GetValuesArgs) => {
  const years = Number(values.years ?? 0)
  const months = Number(values.months ?? 0)

  return hideMonths ? years : years * 12 + months
}

export const getChangeValue = (args: GetValuesArgs) => {
  return getMonthsFromValues(args)
}
