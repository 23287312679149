import React, { ReactElement } from 'react'
import { MockedProvider, MockedProvider as MockedProviderHooks } from '@apollo/client/testing'
import { CaseContextProvider, ClientProvider, PermissionProvider } from '@broker-crm-contexts'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { render } from '@testing-library/react'
import { enGB } from 'date-fns/locale'
import { IntlProvider } from 'react-intl'
import { MemoryRouter } from 'react-router-dom'

import { FlagProvider, flagsAllEnabled, flattenMessages, getMessages, Locales } from '@acre/utils'
import { muiTheme, ThemeProvider } from '@acre/design-system'

import messages from '../../intl/messagesBrokerCrm'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

/**
 * @deprecated Use renderWithCommon instead
 */
export default (
  testCaseContext: any,
  testClientContext: any,
  component: ReactElement,
  mocks?: any,
  useRouter?: boolean,
) => {
  const Router = useRouter !== false ? MemoryRouter : React.Fragment

  return render(
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
        <ThemeProvider theme={muiTheme}>
          <MockedProvider mocks={[]} addTypename={false}>
            <MockedProviderHooks mocks={mocks || []} addTypename={false}>
              <Router>
                <FlagProvider value={flagsAllEnabled}>
                  <PermissionProvider value={{ gates: [] }}>
                    <CaseContextProvider value={{ ...testCaseContext }}>
                      <ClientProvider value={{ ...testClientContext }}>{component}</ClientProvider>
                    </CaseContextProvider>
                  </PermissionProvider>
                </FlagProvider>
              </Router>
            </MockedProviderHooks>
          </MockedProvider>
        </ThemeProvider>
      </IntlProvider>
    </LocalizationProvider>,
  )
}
