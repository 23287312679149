import { styled, Theme } from '@mui/material'
import { rem } from 'polished'
import { Styles } from 'react-select/src/styles'

import { Variant } from '../../utils/constants'

export const DefaultArrow = styled('span')<{ direction: string }>(
  ({ theme, direction }) => `
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  border: solid ${theme.colours.base};
  border-width: 0 2px 2px 0;

  padding: ${theme.spacing(0.5)};
  margin-top: ${direction === 'up' ? `-${rem(3)}` : `-${rem(7)}`};

  transform: ${direction === 'up' ? 'rotate(225deg)' : 'rotate(45deg)'};
  cursor: pointer;
`,
)

export const DropdownMultiSearchSelect = styled('div')`
  * {
    padding: 0;
  }
`

export const Circle = styled('span')(
  ({ theme }) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  aspect-ratio: 1;
  margin-right: ${theme.spacing(1)};
  display: block;
  border-radius: 100%;

  transition: background-color 0.1s ease-in-out;
  border-color: ${theme.colours.baseLight};
  border-style: solid;
  border-width: 2px;
  box-shadow: none;

  &.disabled {
    border-color: ${theme.colours.baseLight};
    background-color: ${theme.colours.baseExtraLight};
    box-shadow: inset 0px 0px 0px 3px ${theme.colours.baseExtraLight};
  }

  &.checked {
    border-color: ${theme.colours.primary};
    background-color: ${theme.colours.primary};
    box-shadow: inset 0px 0px 0px 3px ${theme.colours.foreground};
  }

  &.checked.disabled {
    color: ${theme.colours.baseLight};
    border-color: ${theme.colours.baseLight};
    background-color: ${theme.colours.baseLight};
  }
`,
)

export const selectStyles: (
  theme: Theme,
  variant: Variant | undefined,
  heightCap?: boolean,
  showError?: boolean,
  menuHeight?: string | undefined,
  multiDisabledShowOptions?: boolean,
) => Partial<{}> = (
  theme: Theme,
  variant: Variant | undefined,
  heightCap?: boolean,
  showError?: boolean,
  menuHeight?: string | undefined,
  multiDisabledShowOptions?: boolean,
) => ({
  input: (styles: Styles<any, any>) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
  container: (styles: Styles<any, any>) => ({
    ...styles,
    borderWidth: theme.display.inputBorderWidth,
    outlineWidth: theme.display.inputBorderWidth,
    '&:hover': {
      borderColor: theme.colours.primary,
    },
    '&:focus': {
      borderColor: theme.colours.primary,
      outlineColor: theme.colours.primary,
    },
    fontFamily: theme.typography.fontFamily,
  }),
  control: (
    styles: Styles<any, any>,
    { isDisabled, hasValue, isSearchable }: { isDisabled: boolean; hasValue: boolean; isSearchable: boolean },
  ) => ({
    ...styles,
    minHeight: theme.spacing(6),
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(2)}`,
    borderRadius: theme.display.borderRadius,
    boxShadow: 'none',
    borderColor: theme.colours.baseLight,
    borderWidth: theme.display.inputBorderWidth,
    outlineWidth: theme.display.inputBorderWidth,
    ...(isDisabled
      ? {
          color: theme.colours.baseExtraLight,
          backgroundColor: theme.colours.baseExtraLight,
        }
      : {}),
    ...(hasValue
      ? {
          color: theme.colours.baseLight,
          borderColor: theme.colours.baseLight,
        }
      : {}),
    '&:hover': {
      ...(isSearchable ? { cursor: 'text' } : { cursor: 'pointer' }),
    },
    '&:focus-within': {
      ...(isSearchable ? { cursor: 'text' } : { cursor: 'pointer' }),
      borderColor: theme.colours.primary,
      outlineColor: theme.colours.primary,
    },
    ...(showError
      ? {
          borderColor: theme.colours.danger,
          outlineColor: theme.colours.danger,
        }
      : {}),
    ...((variant === 'compact' ||
      variant === 'compactLeftAligned' ||
      variant === 'compactTopAligned' ||
      variant === 'compactLeftNarrow') &&
      compactStyles(theme)),
    ...(heightCap
      ? {
          maxHeight: theme.spacing(40), // Set the max height
          overflowY: 'scroll', //Enable vertical scrolling
          '&::-webkit-scrollbar': {
            display: 'none', //Hide scrollbar for Webkit-based browsers
          },
          '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
          'scrollbar-width': 'none', // Hide scrollbar for Firefox
        }
      : {}),
  }),
  valueContainer: (styles: Styles<any, any>) => ({
    ...styles,
    padding: 0,
  }),
  singleValue: (styles: Styles<any, any>, { hasValue }: { hasValue: boolean }) => ({
    ...styles,
    ...(hasValue
      ? {
          color: theme.colours.primary,
          borderColor: theme.colours.primary,
        }
      : {}),
    display: 'none',
    '&:first-of-type': {
      display: 'block',
    },
  }),
  dropdownIndicator: (styles: Styles<any, any>, { hasValue }: { hasValue: boolean }) => ({
    ...styles,
    color: theme.colours.baseMid,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      transform:
        (variant === 'compact' ||
          variant === 'compactLeftAligned' ||
          variant === 'compactTopAligned' ||
          variant === 'compactLeftNarrow') &&
        'scale(calc(2/3))',
      ...(hasValue
        ? {
            stroke: theme.colours.base,
          }
        : {}),
    },
  }),
  indicatorsContainer: (styles: Styles<any, any>) => ({
    ...styles,
    border: 'none',
    color: theme.colours.baseMid,
    borderColor: theme.colours.baseLight,
    paddingRight: theme.spacing(1),
    '& > div': {
      padding: 0,
    },
  }),
  indicatorContainer: (styles: Styles<any, any>) => ({
    ...styles,
    border: 'none',
    color: theme.colours.baseMid,
    borderColor: theme.colours.baseLight,
    paddingRight: theme.spacing(1),
    '& > div': {
      padding: 0,
    },
  }),
  clearIndicator: (styles: Styles<any, any>) => ({
    ...styles,
    color: theme.colours.baseMid,
  }),
  indicatorSeparator: () => ({
    border: 'none',
  }),
  menu: (styles: Styles<any, any>) => ({
    ...styles,
    borderRadius: theme.display.borderRadius,
    zIndex: theme.zIndex.modal,
    minWidth: theme.wrappers.small,
    '& button:disabled': {
      backgroundColor: theme.colours.transparent,
    },
  }),
  groupHeading: (styles: Styles<any, any>) => ({
    ...styles,
    '& > div': {
      padding: '0',
      '& div': {
        margin: 0,
        padding: 0,
      },
    },
  }),
  menuList: () => ({
    padding: '0',
    borderRadius: '0',
    maxHeight: menuHeight ?? '60vh',
    overflow: 'auto',
  }),
  option: (
    styles: Styles<any, any>,
    { isFocused, isSelected, isDisabled }: { isFocused: boolean; isSelected: boolean; isDisabled: boolean },
  ) => {
    const shared = {
      backgroundColor: isDisabled ? theme.colours.transparent : theme.colours.primary10,
      color: isDisabled ? theme.colours.baseLight : theme.colours.primary,
      cursor: isDisabled || multiDisabledShowOptions ? 'not-allowed' : 'pointer',
    }
    return {
      ...styles,
      ...(isSelected
        ? {
            color: theme.colours.primary,
            backgroundColor: theme.colours.transparent,
          }
        : {}),
      ...(isFocused ? shared : {}),
      '&:hover': shared,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '8px 16px',
      fontSize: '14px',
    }
  },
  multiValue: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    ...(!isDisabled
      ? {
          backgroundColor: theme.colours.pattensBlue,
        }
      : {
          backgroundColor: theme.colours.baseLight,
          color: theme.colours.new.grey2,
        }),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.display.borderRadius,
  }),
  multiValueLabel: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    ...(!isDisabled
      ? {
          color: theme.colours.cobalt,
          paddingRight: theme.spacing(0.5),
        }
      : {
          paddingRight: theme.spacing(0.5),
          color: theme.colours.new.grey2,
        }),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: '14px',
  }),
  multiValueRemove: (styles: Styles<any, any>) => ({
    ...styles,
    paddingLeft: 0,
    color: theme.colours.baseMid,
    fontSize: '14px',
    '& > svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.colours.foreground,
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    margin: 0,
    padding: 0,
    ...(isDisabled
      ? {
          color: theme.colours.baseMid,
        }
      : {}),
  }),
})

const compactStyles = (theme: Theme) => ({
  minHeight: theme.spacing(4),
  fontSize: '14px',
  padding: 0,
  paddingLeft: theme.spacing(1.5),
})
