import React, { useCallback } from 'react'
import { FieldValues,Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { YearMonthField, YearMonthFieldProps } from '@acre/design-system'
import { FieldBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'

export interface YearMonthFieldRHFProps<T extends FieldValues = FieldValues>
  extends FieldBaseProps,
    YearMonthFieldProps {
  name: Path<T>
}

function _YearMonthFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: YearMonthFieldRHFProps<T>,
) {
  const { name, shouldUnregister = true, ...other } = props
  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController<T>({ name, control, shouldUnregister })
  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const handleChange = useCallback<Required<YearMonthFieldRHFProps<T>>['onChange']>(
    (event) => {
      field.onChange({ ...event, target: { ...event.target, value: event.target.valueAsNumber } })
    },
    [field],
  )

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  return (
    <YearMonthField
      {...other}
      onChange={handleChange}
      value={field.value ?? 0}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      aria-required={!fieldSchema?.spec?.optional}
      WrapperProps={{ required: !fieldSchema?.spec?.optional }}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
    />
  )
}

const YearMonthFieldRHF = React.memo(_YearMonthFieldRHF) as typeof _YearMonthFieldRHF

export default YearMonthFieldRHF
