import { ChangeEvent } from 'react'
import { fieldParserFn } from '@broker-crm-common'
import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@acre/utils'
import { ButtonNewest, TextAreaNew } from '@acre/design-system'

import { nl2br } from '../../../../pages/Cases/CaseDetails/Suitability/Suitability.helpers'
import { messagePrefix } from '../PageNotes.helpers'
import { TextContainer, TextUnderlay } from './AddNewPageNotes.styles'

type Props = {
  noteBody: string
  addingNote: boolean
  handleNoteChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  handleAddNote: () => void
}

const AddNewPageNotesContent = ({ handleNoteChange, handleAddNote, addingNote, noteBody }: Props) => {
  const formatMessage = useFormatMessage()

  return (
    <>
      <Field name="pageNotes" parse={fieldParserFn}>
        {({ input }) => (
          <TextContainer>
            <TextUnderlay dangerouslySetInnerHTML={{ __html: nl2br(noteBody) + '<br>' }} />
            <TextAreaNew
              id="AddNewPageNotes"
              {...input}
              value={noteBody}
              onChange={handleNoteChange}
              placeholder={formatMessage(`${messagePrefix}.typeANote`)}
              name="AddNewPageNotes"
              ariaLabel="AddNewPageNotes"
            />
          </TextContainer>
        )}
      </Field>
      <Box py={1}>
        <ButtonNewest
          id="AddNewPageNotesButton"
          variant="primary"
          size="large"
          fullWidth
          onClick={handleAddNote}
          loading={addingNote}
          disabled={isEmpty(noteBody)}
        >
          <FormattedMessage id={`${messagePrefix}.saveNoteToPage`} />
        </ButtonNewest>
      </Box>
    </>
  )
}

export default AddNewPageNotesContent
