import React, { useContext, useEffect } from 'react'
import { setContext, setUser } from '@sentry/react'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'

import UserContext from '../context/UserContext'
import RouteBlocker, { RouteBlockerProps } from './RouteBlocker'

interface PrivateRouteProps extends Omit<RouteBlockerProps, 'shouldRender'> {}

const RequireAuth = ({ redirectTo = '/login', ...props }: PrivateRouteProps) => {
  const user = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {
    if (user) {
      setContext('user', {
        id: user.id,
        email: user.email_address,
      })

      setUser({
        id: user.id,
        email: user.email_address!,
      })

      setContext('organisation', {
        id: user.organisation_id,
        name: user.organisation?.name,
      })
    }
  }, [location.pathname, user])

  const shouldRender = !!user

  if (!shouldRender) {
    Cookies.set('login_redirect', location.pathname)
  }

  return <RouteBlocker {...props} redirectTo={redirectTo} shouldRender={shouldRender} />
}

export default React.memo(RequireAuth)
