import { styled } from '@mui/material'

import { FooterProps } from './Footer.types'

export const StyledFooter = styled('footer')<FooterProps>(({ theme, sideNav }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: sideNav ? theme.mixins.sidebar.width : '100%',
  background: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
  ...(sideNav && { borderRight: `1px solid ${theme.palette.secondary.light}` }),
}))

export const FooterContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
`
export const Hidden = styled('span')`
  visibility: hidden;
`
