import * as yup from 'yup'

import {
  ERROR_BEFORE_LABEL,
  ERROR_DATE_GENERIC,
  ERROR_FUTURE,
  ERROR_REQUIRED,
  isInTheFuture,
  isValidDate,
  unwrapErrors,
  validateIsBefore,
} from '@acre/utils'
import { ClientInput } from '@acre/graphql'

export type UpdateClientsAddressFormValues = {
  selectedClients?: ClientInput[]
  move_in_date?: string
}

export const schema = yup.object<Pick<UpdateClientsAddressFormValues, 'move_in_date'>>().shape(
  {
    move_in_date: yup
      .string()
      .typeError(ERROR_DATE_GENERIC)
      .required(ERROR_REQUIRED)
      .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value))
      .test('isInThePast', ERROR_FUTURE, (value) => !isInTheFuture(value))
      .when('move_out_date', {
        is: (move_out_date: string) => isValidDate(move_out_date),
        then: (schema) =>
          schema.test('Compare dates', ERROR_BEFORE_LABEL, function (this: yup.TestContext, move_in_date: string) {
            const move_out_date: string = this.resolve(yup.ref('move_out_date'))
            return validateIsBefore(move_in_date, move_out_date)
          }),
      }),
  },
  [['move_in_date', 'move_out_date']],
)

export const validateUpdateClientAddressModal = unwrapErrors(schema)
