import { styled } from '@mui/material'

export const Title = styled('div')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const TableWrapper = styled('div')`
  & table {
    & th {
      padding-top: ${({ theme }) => theme.spacing(1)};
      padding-bottom: ${({ theme }) => theme.spacing(1)};
      color: ${({ theme }) => theme.colours.base};
      font-weight: 400;
    }

    & th:not(:last-of-type) {
      border-right: ${({ theme }) => `1px solid ${theme.colours.baseLight}`};
    }

    & tr {
      & td {
        min-width: 100px;
        * {
          font-weight: ${({ theme }) => theme.typography.fontWeightBold};
        }
      }
    }
  }
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacers.size8};
  justify-content: flex-end;
  align-items: center;
`

export const ButtonsWrapperVertical = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers.size8};
  justify-content: flex-start;
  align-items: flex-end;
  button {
    width: 100% !important;
  }
  & > div {
    width: 100% !important;
  }
`
