import React, { useCallback, useContext } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Location } from 'history'
import { useLocation } from 'react-router-dom'

import { isGeneralProtection, isHomeInsurance, UserContext } from '@acre/utils'
import { CaseStatus, Maybe, MortgageReason, UpdateCaseStatusRequestUpdateType } from '@acre/graphql'

import { TasksLocation } from '../CaseOverview.helpers'
import { useGeneralInsuranceProducts, validTransitionStatusType } from './CaseVerifications.helpers'
import { StatusTransitionTask } from './StatusTransitionTask'
import CompleteTransitionTask from './TransitionToCompleteTask/CompleteTransitionTask'
import ExchangeTransitionTask from './TransitionToExchangeTask/ExchangeTransitionTask'

const CaseTransitions = ({
  caseId,
  caseStatus,
  preferenceMortgageReason,
  tasksLocation,
  onCompleteTransitionTaskSubmit,
}: {
  caseId: string
  caseStatus?: Maybe<CaseStatus>
  preferenceMortgageReason: Maybe<MortgageReason> | undefined
  tasksLocation?: TasksLocation
  onCompleteTransitionTaskSubmit?: () => void
}) => {
  const userContext = useContext(UserContext)

  const shouldUseExchangeStatus = Boolean(userContext?.organisation?.process_settings?.use_exchange_case_status)
  const { markProductsComplete } = useGeneralInsuranceProducts(caseId)

  const isProtectionCase = isGeneralProtection(preferenceMortgageReason)
  const isHomeInsuranceCase = isHomeInsurance(preferenceMortgageReason)

  const { trackEvent } = useMatomo()
  let location = useLocation() as Location

  const handleSubmit = useCallback(() => {
    markProductsComplete()
  }, [markProductsComplete])

  const setNormalTransitions = (shouldUseExchangeStatus: boolean) => {
    const initialTransitions = [
      { from: CaseStatus.PreApplication, to: UpdateCaseStatusRequestUpdateType.ApplicationSubmitted },
      {
        from: CaseStatus.ApplicationSubmitted,
        to: UpdateCaseStatusRequestUpdateType.AwaitingValuation,
      },
      {
        from: CaseStatus.AwaitingValuation,
        to: UpdateCaseStatusRequestUpdateType.AwaitingOffer,
      },
    ]

    const finalTransitions = [
      {
        from: CaseStatus.AwaitingOffer,
        to: UpdateCaseStatusRequestUpdateType.Offered,
      },
      shouldUseExchangeStatus
        ? {
            from: CaseStatus.OfferReceived,
            to: UpdateCaseStatusRequestUpdateType.Exchange,
          }
        : {
            from: CaseStatus.OfferReceived,
            to: CaseStatus.Complete,
            onSubmit: () => handleSubmit(),
          },
      {
        from: CaseStatus.Exchange,
        to: UpdateCaseStatusRequestUpdateType.Complete,
        onSubmit: () => handleSubmit(),
      },
    ]

    return [...initialTransitions, ...finalTransitions] as {
      from: CaseStatus
      to: validTransitionStatusType
      onSubmit?: () => Promise<void>
    }[]
  }

  const normalTransitions = setNormalTransitions(shouldUseExchangeStatus)

  const protectionCaseTransitions = [
    { from: CaseStatus.PreApplication, to: UpdateCaseStatusRequestUpdateType.ApplicationSubmitted },
    {
      from: CaseStatus.ApplicationSubmitted,
      to: UpdateCaseStatusRequestUpdateType.Complete,
    },
  ] as typeof normalTransitions

  const giCaseTransitions = [
    { from: CaseStatus.PreRecommendation, to: UpdateCaseStatusRequestUpdateType.Recommendation },
    { from: CaseStatus.PreApplication, to: UpdateCaseStatusRequestUpdateType.ApplicationSubmitted },
    {
      from: CaseStatus.ApplicationSubmitted,
      to: UpdateCaseStatusRequestUpdateType.Complete,
    },
  ] as typeof normalTransitions

  let transitions = normalTransitions
  if (isProtectionCase) transitions = protectionCaseTransitions
  if (isHomeInsuranceCase) transitions = giCaseTransitions

  for (let transition of transitions) {
    const { from, to, onSubmit } = transition
    if (caseStatus === from) {
      // To Exchange is a custom transition
      // We don't currently need to confirm product details for GI case
      if (!isHomeInsuranceCase && to === UpdateCaseStatusRequestUpdateType.Exchange && shouldUseExchangeStatus) {
        return (
          <ExchangeTransitionTask
            status={to}
            caseId={caseId}
            onSubmit={onSubmit}
            preferenceMortgageReason={preferenceMortgageReason}
            isProtection={isProtectionCase}
            tasksLocation={tasksLocation}
            trackEvent={trackEvent}
            location={location}
          />
        )
      }

      // To Complete is a custom transition
      // We don't currently need to confirm fees and product details for GI case
      if (!isHomeInsuranceCase && to === UpdateCaseStatusRequestUpdateType.Complete) {
        return (
          <CompleteTransitionTask
            caseId={caseId}
            onSubmit={async () => {
              onSubmit && (await onSubmit())
              onCompleteTransitionTaskSubmit && onCompleteTransitionTaskSubmit()
            }}
            isProtection={isProtectionCase}
            tasksLocation={tasksLocation}
            trackEvent={trackEvent}
            location={location}
          />
        )
      }

      return (
        <StatusTransitionTask
          status={to}
          caseId={caseId}
          onSubmit={onSubmit}
          tasksLocation={tasksLocation}
          trackEvent={trackEvent}
          location={location}
          preferenceMortgageReason={preferenceMortgageReason}
        />
      )
    }
  }
  return null
}

export default CaseTransitions
