import React, { PropsWithChildren } from 'react'
import { Field } from 'react-final-form'

export type ConditionProps<T> = PropsWithChildren<{
  when: string
  is: T[]
}>

const Condition = <T extends string | boolean | number | Date>({ children, when, is }: ConditionProps<T>) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (is.includes(value) ? children : null)}
  </Field>
)

export default Condition
