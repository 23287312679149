import React, { Fragment, HTMLProps, memo, MouseEvent as ReactMouseEvent, PropsWithChildren, ReactElement } from 'react'
import { Box, useTheme } from '@mui/material'
import { TippyProps } from '@tippyjs/react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'

import { getColourHex } from '../../utils/colourHex'
import { getTextColourHex } from '../../utils/textColourHex'
import { ColourId } from '../../utils/types'
import { H2 } from '../Header'
import MissingTip from '../MissingTip'
import { UnsetSectionDivider } from '../SectionDivider'
import { ClickableCard, ReceivedMessageCard, SentMessageCard, StyledCard } from './Card.styles'

export type CardProps = PropsWithChildren<{
  id?: string
  border?: boolean
  shadow?: boolean
  mobile?: boolean
  red?: boolean
  padding?: string | number
  fullHeight?: boolean
  maxHeight?: string
  minHeight?: string
  isMissing?: boolean
  isIncomplete?: boolean
  externalClassName?: string
  clickable?: boolean
  isMessage?: boolean
  isSent?: boolean
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
  backgroundColour?: string
  heading?: string | ReactElement
  headingId?: string
  subHeading?: string
  subHeadingClassName?: string
  colourID?: ColourId
  disabled?: boolean
  textColour?: ColourId
  style?: HTMLProps<'div'>['style']
  borderRadius?: string
  boxShadow?: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onBlur?: () => void
  onFocus?: () => void
}>

const Card = ({
  id,
  border = true,
  shadow = true,
  mobile = false,
  red = false,
  fullHeight = false,
  maxHeight,
  minHeight,
  padding,
  isIncomplete = false,
  children,
  externalClassName,
  clickable = false,
  isMessage = false,
  isSent = false,
  backgroundColour,
  onClick,
  heading,
  headingId,
  subHeading,
  subHeadingClassName,
  colourID,
  disabled,
  textColour,
  style,
  borderRadius,
  boxShadow,
  onMouseOver,
  onMouseLeave,
  onFocus,
  onBlur,
}: CardProps) => {
  const theme = useTheme()
  const dataTestId = id ? testHandle(id) : null
  const colourHex = colourID ? getColourHex(colourID) : undefined
  const textColourHex = colourID ? getTextColourHex(colourID) : getTextColourHex(textColour!)
  const isDefaultColour = colourID === ColourId.AcreBlue
  const className = classNames({
    border,
    shadow,
    mobile,
    red: isIncomplete || red,
    fullHeight,
    maxHeight: !!maxHeight,
    minHeight: !!minHeight,
    cardParent: true,
    disabled,
    ...(externalClassName && { [externalClassName]: true }),
  })

  const Wrapper = isIncomplete ? MissingTip : Fragment
  const wrapperProps = isIncomplete
    ? {
        placement: 'left',
        flipBehavior: ['top', 'bottom', 'right', 'left'],
        content: 'Incomplete',
      }
    : {}

  // Check if the clicked element is below a button in the hierachy, or is a button itself
  const hasButtonParent = (element: HTMLElement) => {
    let el = element
    const parents = [element]

    while (el && !el.classList.contains('cardParent')) {
      parents.push(el.parentElement!)
      el = el.parentElement!
    }

    return parents.some((el) => el?.tagName === 'BUTTON')
  }

  // Handle click on the card, ensuring that we aren't clicking a button inside the card
  const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Keep the event so we can pass it on
    evt.persist()

    const target = evt.target as HTMLElement

    if (target && !hasButtonParent(target) && onClick) {
      onClick(evt)
    }
  }

  const clickableProps = clickable ? { role: 'button', tabIndex: 0, onClick: handleClick } : null

  const CardComponent = clickable ? ClickableCard : StyledCard
  const MessageCard = isSent ? SentMessageCard : ReceivedMessageCard
  const Card = isMessage ? MessageCard : CardComponent

  return (
    <Wrapper {...(wrapperProps as TippyProps)}>
      <Card
        id={id}
        className={className}
        data-testid={dataTestId}
        color={colourHex}
        textColour={textColourHex}
        isDefaultColour={isDefaultColour}
        padding={padding}
        minHeight={minHeight}
        {...clickableProps}
        backgroundColour={backgroundColour}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        style={style}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
      >
        {heading && (
          <Box mb={subHeading ? theme.spacers.size8 : theme.spacers.size24}>
            <H2 styledAs="h5" data-testid={headingId ? testHandle(headingId) : undefined}>
              {typeof heading === 'string' ? <FormattedMessage id={heading} /> : heading}
            </H2>
          </Box>
        )}
        {subHeading && (
          <Box component="p" mt={theme.spacers.size4} mb={theme.spacers.size24} className={`${subHeadingClassName}`}>
            <FormattedMessage id={subHeading} />
          </Box>
        )}
        {(heading || subHeading) && (
          <Box mb={theme.spacers.size32} mt={theme.spacers.size24}>
            <UnsetSectionDivider margin="size32" />
          </Box>
        )}
        {children}
      </Card>
    </Wrapper>
  )
}

export default memo(Card)
