import { styled } from '@mui/material'
import { darken, transparentize } from 'polished'

import { disabledClassName } from '../../styles/disabled.styles'

export type CardStyleProps = {
  padding?: string | number
  clickable?: boolean
  maxHeight?: string
  minHeight?: string
  backgroundColour?: string
  heading?: string
  subHeading?: string
  fontWeight?: string
  color?: string
  isDefaultColour?: boolean
  textColour?: string
  borderRadius?: string
  boxShadow?: string
}

export const StyledCard = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColour' &&
    prop !== 'borderRadius' &&
    prop !== 'color' &&
    prop !== 'textColour' &&
    prop !== 'isDefaultColour' &&
    prop !== 'minHeight' &&
    prop !== 'boxShadow',
})<CardStyleProps>(
  ({
    theme,
    padding,
    heading,
    subHeading,
    backgroundColour,
    isDefaultColour,
    color,
    textColour,
    maxHeight,
    minHeight,
    boxShadow,
    borderRadius = theme.display.borderRadius,
  }) => `
  padding: ${padding || theme.spacers.size32};
  padding-top: ${heading || (subHeading && theme.spacers.size24)};
  border-radius: ${borderRadius};
  background-color: ${backgroundColour ? backgroundColour : theme.colours.foreground};
  width: 100%;
  ${
    (isDefaultColour &&
      color &&
      `background: ${transparentize(theme.opacity.op95, color)};
       color: ${color};
       border: 1px solid ${color};`) ||
    (!isDefaultColour &&
      color &&
      `background: ${transparentize(theme.opacity.op60, color)};
       color: ${textColour || darken(theme.opacity.op60, color)};
       border: 1px solid ${textColour || darken(theme.opacity.op60, color)};`) ||
    (textColour && `color: ${textColour};`)
  }
  &.blackBackground {
    background-color: ${theme.colours.black};
  }

  &.border {
    ${
      (isDefaultColour && color && `border: 1px solid ${color}`) ||
      (!isDefaultColour && color && `border: 1px solid ${textColour || darken(theme.opacity.op60, color)};`) ||
      `border: 1px solid ${theme.colours.baseLight}`
    }
  }

  &.red {
    border: 1px solid ${theme.colours.danger};
  }

  &.shadow {
    box-shadow: ${color ? '' : boxShadow || theme.boxShadow};
  }

  &.mobile {
    padding: ${theme.spacers.size16};
    &.shadow {
      box-shadow: ${theme.newClientBoxShadow};
    }
  }

  &.fullHeight {
    height: 100%;
  }

  &.relativePosition {
    position: relative;
  }

  &.maxHeight {
    max-height: ${maxHeight};
    overflow-y: auto;
  }

  &.minHeight {
    min-height: ${minHeight};
  }

  p.bold {
    font-weight: ${theme.typography.fontWeightBold};
  }
  ${disabledClassName(theme)}
`,
)

export const ClickableCard = styled(StyledCard)(
  ({ color, isDefaultColour, theme }) => `
  cursor: pointer;
  border: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    ${isDefaultColour && color && `background: ${transparentize(theme.opacity.op80, color)}`};
    ${!isDefaultColour && color && `background: ${transparentize(theme.opacity.op60, color)}`};
    border-color: ${color ? '' : theme.colours.hover};
    outline: none;
  }

  &:hover {
    ${isDefaultColour && color && `background: ${transparentize(theme.opacity.op90, color)}`};
    ${!isDefaultColour && color && `background: ${transparentize(theme.opacity.op40, color)}`};
  }

  &:active {
    ${isDefaultColour && color && `background: ${transparentize(theme.opacity.op80, color)}`};
    ${!isDefaultColour && color && `background: ${transparentize(theme.opacity.op20, color)}`};
    ${!color && `background: ${theme.colours.primary10}`};
  }
`,
)

export const ReceivedMessageCard = styled(StyledCard)(
  ({ theme, color, isDefaultColour, textColour }) => `
  /* minicard */
  font-size: 14px;

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  /* baseWhite */

  /* baseLightColor */

  border: 1px solid #d7dae0;
  /* cardShadow */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 16px 16px 16px;

  /* Inside auto layout */

  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;

  ${
    isDefaultColour &&
    color &&
    `
        background: ${transparentize(theme.opacity.op95, color)};
        color: ${color};
        border: 1px solid ${color};`
  };
  ${
    !isDefaultColour &&
    color &&
    `
        background: ${transparentize(theme.opacity.op60, color)};
        color: ${textColour || darken(theme.opacity.op60, color)};
        border: 1px solid ${textColour || darken(theme.opacity.op60, color)};
        `
  };
  ${
    !color &&
    textColour &&
    `
    color: ${textColour};
    `
  };

`,
)

export const SentMessageCard = styled(StyledCard)(
  ({ theme, color, isDefaultColour, textColour }) => `
  /* minicard */
  font-size: 14px;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  /* cardShadow */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px 0px 16px 16px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;

  ${
    isDefaultColour &&
    color &&
    `
      background: ${transparentize(theme.opacity.op95, color)};
      color: ${color};
      border: 1px solid ${color};
  `
  };
  ${
    !isDefaultColour &&
    color &&
    `
      background: ${transparentize(theme.opacity.op60, color)};
      color: ${textColour || darken(theme.opacity.op60, color)};
      border: 1px solid ${textColour || darken(theme.opacity.op60, color)};
  `
  };
  ${!color && textColour && `color: ${textColour}`};
`,
)
