import { styled } from '@mui/material'

export const ExternalLink = styled('a')<{ disabled?: boolean }>(
  ({ theme }) => `
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  margin-left: ${theme.spacers.size12};
  margin-right: ${theme.spacers.size12};

  text-decoration: none;
  color: ${theme.colours.baseMid};
  transition: all 0.15s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transition: all 0.15s ease-in-out;
    background-color: ${theme.colours.foreground};
  }

  &.selected {
    color: ${theme.colours.primary};
    &::after {
      background-color: ${theme.colours.primary};
    }
  }

  &:hover {
    text-decoration: none;
    &::after {
      background-color: ${theme.colours.primary};
    }
  }
`,
)
