import { FeeType } from '@acre/graphql'

export const messagePrefix = 'caseFeeTypes'

export const getMortgageFeeTypeLabel = (feeType: FeeType) => {
  switch (feeType) {
    case FeeType.PreadviceFixed:
      return `${messagePrefix}.preMortgageAdvice`
    case FeeType.PrerecommendationFixed:
      return `${messagePrefix}.preMortgageRecommendation`
    case FeeType.PrerecommendationPercent:
      return `${messagePrefix}.preMortgageRecommendationPercent`
    case FeeType.PreapplicationFixed:
      return `${messagePrefix}.preMortgageApplication`
    case FeeType.PreapplicationPercent:
      return `${messagePrefix}.preMortgageApplicationPercent`
    case FeeType.OfferFixed:
      return `${messagePrefix}.offer`
    case FeeType.OfferPercent:
      return `${messagePrefix}.offerPercent`
    case FeeType.LegalCompletionFixed:
      return `${messagePrefix}.legalCompletion`
    case FeeType.LegalCompletionPercent:
      return `${messagePrefix}.legalCompletionPercent`
    case FeeType.LegalExchangeFixed:
      return `${messagePrefix}.legalExchangeFixed`
    case FeeType.LegalExchangePercent:
      return `${messagePrefix}.legalExchangePercent`
    default:
      return `${messagePrefix}.invalidFeeType`
  }
}
