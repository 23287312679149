import React from 'react'
import { useTheme } from '@mui/material'
import * as Sentry from '@sentry/react'

import acreConfig from '@acre/config'

import Button from '../Button'
import Card from '../Card'
import { H2 } from '../Header'
import Nav from '../Nav'
import { CardContainer, Code, Container, Logo, P, RedCode } from './PrettyErrorBoundary.styles'
import { AcreSquareLogo } from '../../assets'

export type PrettyErrorBoundaryProps = React.PropsWithChildren<{}>

const PrettyErrorBoundary = ({ children }: PrettyErrorBoundaryProps) => {
  const theme = useTheme()

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        // Make sure to fully reload the page when the dynamically imported module import fails
        // (this is needed since when some files change and the assest's name in prod also change
        // (since it contains a hash that depends on the changes))
        if (error.message.includes('Failed to fetch dynamically imported module')) {
          location.reload()
        }

        return (
          <React.Fragment>
            <Nav
              offset={theme.display.navHeightSmall.toString()}
              left={
                <Logo>
                  <AcreSquareLogo />
                </Logo>
              }
            />
            <Container>
              <CardContainer>
                <Card>
                  <H2>You have encountered an error</H2>
                  <P>
                    Unfortunately, something went wrong. We&apos;re really sorry this happened and we&apos;ve notified
                    our technical people so they can fix it as soon as possible.
                  </P>
                  {acreConfig.SENTRY_ENVIRONMENT != 'crm.myac.re' && (
                    <>
                      <P>NB The following will not appear in production:</P>
                      <RedCode style={{ padding: '10px', color: 'red', marginTop: '10px' }}>{error.toString()}</RedCode>
                      <Code>{componentStack}</Code>
                    </>
                  )}
                  <Button
                    onClick={() => {
                      resetError()
                    }}
                  >
                    Reload page
                  </Button>
                </Card>
              </CardContainer>
            </Container>
          </React.Fragment>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default PrettyErrorBoundary
