import { styled, Theme } from '@mui/material'
import { transparentize } from 'polished'

import { Variant } from '../../utils/constants'

export const Circle = styled('span')<{ variant?: Variant; hoverColour?: string }>(
  ({ theme, hoverColour }) => `
  width: 16px;
  height: 16px;
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};

  display: block;
  border-radius: 100%;

  transition: background-color 0.1s ease-in-out;
  border-color: ${theme.colours.baseLight};
  border-style: solid;
  border-width: 2px;
  box-shadow: none;

  &.disabled {
    border-color: ${theme.colours.baseLight};
    background-color: ${theme.colours.baseExtraLight};
    box-shadow: inset 0px 0px 0px 2px ${theme.colours.baseExtraLight};
  }

  &.checked {
    border-color: ${hoverColour ? hoverColour : theme.colours.primary};
    background-color: ${theme.colours.new.grey6} !important;
    box-shadow: inset 0px 0px 0px 2px ${theme.colours.foreground};

    
  }

  &.checked.disabled {
    color: ${theme.colours.baseLight};
    border-color: ${theme.colours.baseLight};
    background-color: ${theme.colours.baseLight};
  }
`,
)

export const Input = styled('input')<{ variant?: Variant }>(
  ({ theme }) => `
  position: absolute;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  background: none;


  &:disabled {
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }
`,
)

const optionLabelCompactStyles = (theme: Theme) => `
  font-size: 14px;
  height: ${theme.display.inputHeightShort};
`

export const OptionLabel = styled('label', {
  name: 'RadioOption',
  slot: 'label',
})<{ variant?: Variant; borderradius?: string; hovercolour?: string }>(
  ({ theme, variant, borderradius, hovercolour }) => `
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  cursor: pointer;

  border-radius: ${borderradius ? borderradius : null};

  background: ${theme.colours.foreground};
  border-color: ${theme.colours.baseLight};
  border-width: ${theme.display.inputBorderWidth};
  color: ${theme.colours.base};
  font-size: 16px;
  border-style: solid;
  transition: background-color 0.1s ease-in-out;

  ${
    (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
    optionLabelCompactStyles(theme)
  }

  &.disabled {
    color: ${theme.colours.baseMid};
    background-color: ${theme.colours.baseExtraLight};
  }

  &.checked {
    z-index: 1;
    border-color: ${hovercolour ? `${hovercolour} !important` : theme.colours.primary};
    background-color: ${
      hovercolour
        ? `${transparentize(theme.opacity.op90, hovercolour)} !important`
        : transparentize(theme.opacity.op90, theme.colours.primary)
    };
  }

  &:hover {
    z-index: 1;
    border-color: ${theme.colours.primary};
    &.disabled {
      color: ${theme.colours.baseMid};
      border-color: ${theme.colours.baseLight};
      background-color: ${theme.colours.baseExtraLight};
    }
  }

  &.checked.disabled {
    z-index: 2;
    color: ${theme.colours.baseMid};
    border-color: ${theme.colours.baseLight};
    background-color: ${theme.colours.baseExtraLight};
  }

  &.error:not(.disabled) {
    border-color: ${theme.colours.danger};
  }
`,
)

export const RadioLabel = styled('div')`
  max-width: 80%;
  margin-top: 12px;
  margin-bottom: 12px;
`
