import { AnySchema, ValidationError } from 'yup'

//
// For using yup with react-final-form
//
// yup throws real errors when validating state. Because of this, we need to surround
// catch, and extract and return each error message individually
//
// https://github.com/final-form/react-final-form/issues/78
//

// export type YupSchema = MixedSchema | BooleanSchema | StringSchema | NumberSchema | DateSchema

// This should be used where an individual react-final-form Field is validated
export default (schema: AnySchema) => {
  return (values: any) => {
    try {
      // Disable 'abortEarly' so that we catch *all* the errors, not just the first
      schema.validateSync(values, { abortEarly: false, context: values })
    } catch (err) {
      if (err instanceof ValidationError) {
        return err.inner[0]
      }
    }
    return null
  }
}
