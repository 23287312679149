import React, { FC, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import { Client, Maybe } from '@acre/graphql'
import { H6 } from '@acre/design-system'

const InfoListStyles = styled('dl')`
  font-size: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  dt,
  dd {
    padding: ${({ theme }) => theme.spacing(0.5)} 0;
    margin: 0;

    display: flex;
  }

  dd {
    justify-content: flex-end;
    text-align: right;
    flex: 1 0 60%;
    padding-right: ${({ theme }) => theme.spacing(0.5)};
    border-radius: 0 ${({ theme }) => theme.display.borderRadius} ${({ theme }) => theme.display.borderRadius} 0;
  }

  dt {
    flex: 1 0 40%;
    padding-left: ${({ theme }) => theme.spacing(0.5)};
    border-radius: ${({ theme }) => theme.display.borderRadius} 0 0 ${({ theme }) => theme.display.borderRadius};
  }

  dt:nth-of-type(4n + 3),
  dt:nth-of-type(4n + 3) + dd {
    background: ${({ theme }) => theme.colours.baseExtraLight} 0;
  }
`

export type InfoListProps = {
  heading: string | (MessageDescriptor & { values?: Record<string, string | number> })
  items: {
    label: string
    value?: Maybe<string>
    translation?: boolean
  }[]
}

const InfoList: FC<InfoListProps> = ({ heading, items }) => {
  const headingProps = typeof heading === 'string' ? { id: heading } : heading

  return (
    <>
      <H6>
        <FormattedMessage {...headingProps} />
      </H6>
      <InfoListStyles>
        {items.map(({ label, value, translation }) => (
          <Fragment key={`${label}${value}`}>
            <FormattedMessage tagName="dt" id={label} />
            <dd>{translation && value ? <FormattedMessage id={value} /> : value || '-'}</dd>
          </Fragment>
        ))}
      </InfoListStyles>
    </>
  )
}

export { InfoList }

export const formatName = (client: Client) => {
  const { first_name, middle_name, last_name } = client
  return [first_name, middle_name, last_name].filter((val) => val).join(' ')
}
