import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Fade, Paper, PaperProps, Popper, PopperProps, styled, unstable_composeClasses } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import classnames from 'classnames'

import { getHitAreaData, getHoverTrapStyles } from './HoverPopOver.helpers'
import { getHoverPopOverUtilityClass } from './hoverPopOverClasses'

export interface HoverPopOverProps extends Omit<PopperProps, 'open' | 'childen'> {
  children: React.ReactNode
  PaperProps?: PaperProps
  offsetX?: number
  offsetY?: number
  delay?: number
  interactive?: boolean
  onClose?: () => void
}

const HoverPopOverRoot = styled(Popper, {
  name: 'HoverPopOver',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})({
  zIndex: 1300,
})

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
    paper: ['paper'],
    hitArea: ['hitArea'],
  }

  return unstable_composeClasses(slots, getHoverPopOverUtilityClass)
}

let prevAnchorEl: PopperProps['anchorEl']

const modifiers: PopperProps['modifiers'] = [
  {
    name: 'flip',
    enabled: true,
    options: {
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      rootBoundary: 'document',
      padding: 8,
    },
  },
]

const PopOverPaper = styled(Paper, {
  name: 'HoverPopOver',
  slot: 'Paper',
  overridesResolver: (props, styles) => styles.paper,
})``

const PopOverHitAreaSVG = styled('svg', {
  name: 'HoverPopOver',
  slot: 'HitArea',
  overridesResolver: (props, styles) => styles.hitArea,
})({
  position: 'absolute',
  zIndex: 1300,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  path: {
    pointerEvents: 'auto',
  },
})

const _HoverPopOver = (props: HoverPopOverProps) => {
  const {
    className,
    id: idProp,
    PaperProps,
    onClose,
    interactive = false,
    offsetX,
    offsetY,
    children,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses()

  const targetRef = React.useRef<HTMLDivElement | null>(null)
  const hitAreaRef = React.useRef<SVGPathElement | null>(null)

  if (interactive && !onClose) {
    console.error('PopOver: interactive prop requires close prop to be set.')
  }

  prevAnchorEl = props.anchorEl

  const open = Boolean(props.anchorEl)

  const id = open ? idProp : undefined

  const handleClose = useCallback(() => {
    const anchorDidChangeAndIsStillSet = prevAnchorEl && props.anchorEl && prevAnchorEl !== props.anchorEl
    !anchorDidChangeAndIsStillSet && onClose?.()
  }, [onClose, props.anchorEl])

  const hoverTrapStyles = useMemo(
    () => getHoverTrapStyles({ offsetX, offsetY, placement: rootProps.placement }),
    [offsetX, offsetY, rootProps.placement],
  )

  const addCloseHandleOnTriggerReturn = useCallback(() => {
    const anchorEl = props.anchorEl as HTMLElement

    anchorEl.addEventListener('mouseleave', handleClose)
  }, [handleClose, props.anchorEl])

  const handleHitAreaMouseEnter = useCallback(() => {
    if (props.anchorEl && interactive && onClose) {
      const anchorEl = props.anchorEl as HTMLElement
      anchorEl.removeEventListener('mouseleave', handleClose)
    }
  }, [handleClose, interactive, onClose, props.anchorEl])

  const handleHitAreaMouseLeave = useCallback(() => {
    setTimeout(() => {
      if (!isInsidePopper.current) {
        handleClose()
      }
    }, 0)
  }, [handleClose])

  useEffect(() => {
    const anchorEl = props.anchorEl as HTMLElement
    const hitAreaEl = hitAreaRef.current

    if (anchorEl && onClose) {
      anchorEl.addEventListener('mouseenter', addCloseHandleOnTriggerReturn)
      anchorEl.addEventListener('mouseleave', handleClose)

      if (interactive) {
        hitAreaEl?.addEventListener('mouseenter', addCloseHandleOnTriggerReturn)
        hitAreaEl?.addEventListener('mouseleave', handleHitAreaMouseLeave)
      }

      return () => {
        anchorEl.removeEventListener('mouseleave', handleClose)
        anchorEl.removeEventListener('mouseenter', addCloseHandleOnTriggerReturn)

        if (interactive) {
          hitAreaEl?.removeEventListener('mouseenter', addCloseHandleOnTriggerReturn)
          hitAreaEl?.removeEventListener('mouseleave', handleHitAreaMouseLeave)
        }
      }
    }
  })

  useEffect(() => {
    if (interactive) {
      if (open) {
        setTimeout(() => {
          if (hitAreaRef.current && targetRef.current) {
            const hitAreaData = getHitAreaData({
              placement: props.placement,
              anchorEl: props.anchorEl,
              targetEl: targetRef.current,
            })

            if (hitAreaData) {
              hitAreaRef.current.setAttribute('d', hitAreaData)
            }
          }
        })
      } else {
        hitAreaRef.current?.setAttribute('d', '')
      }
    }
  }, [interactive, open, props.anchorEl, props.placement])

  useResizeObserver(window.document.body, () => {
    if (interactive) {
      if (open) {
        setTimeout(() => {
          if (hitAreaRef.current && targetRef.current) {
            const hitAreaData = getHitAreaData({
              placement: props.placement,
              anchorEl: props.anchorEl,
              targetEl: targetRef.current,
            })

            if (hitAreaData) {
              hitAreaRef.current.setAttribute('d', hitAreaData)
            }
          }
        })
      } else {
        hitAreaRef.current?.setAttribute('d', '')
      }
    }
  })

  const isInsidePopper = useRef(false)

  const handlePopperMouseEnter = useCallback(() => {
    isInsidePopper.current = true

    if (props.anchorEl && interactive && onClose) {
      const anchorEl = props.anchorEl as HTMLElement
      anchorEl.removeEventListener('mouseleave', handleClose)
    }
  }, [handleClose, interactive, onClose, props.anchorEl])

  const handlePopperMouseLeave = useCallback(() => {
    isInsidePopper.current = false

    if (interactive && onClose) {
      handleClose()
    }
  }, [handleClose, interactive, onClose])

  return (
    <>
      {interactive && (
        <PopOverHitAreaSVG className={slotClasses.hitArea}>
          <path
            ref={hitAreaRef}
            fill="transparent"
            onMouseEnter={handleHitAreaMouseEnter}
            onMouseLeave={handleHitAreaMouseLeave}
          />
        </PopOverHitAreaSVG>
      )}
      <HoverPopOverRoot
        className={classnames(slotClasses.root, className)}
        transition
        id={id}
        open={open}
        placement="bottom"
        modifiers={modifiers}
        {...rootProps}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div
              ref={targetRef}
              style={hoverTrapStyles}
              onMouseEnter={handlePopperMouseEnter}
              onMouseLeave={handlePopperMouseLeave}
            >
              <PopOverPaper
                elevation={2}
                {...PaperProps}
                className={classnames(slotClasses.paper, PaperProps?.className)}
              >
                {children}
              </PopOverPaper>
            </div>
          </Fade>
        )}
      </HoverPopOverRoot>
    </>
  )
}

const HoverPopOver = React.memo(_HoverPopOver) as typeof _HoverPopOver

export default HoverPopOver
