import { styled } from '@mui/material'

const Divider = styled('div')(
  ({ theme }) => `
  height: 1px;
  background-color: ${theme.colours.baseLight};
`,
)

export default Divider
