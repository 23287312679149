import { styled, Theme } from '@mui/material'

export const CardHeader = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled('div')`
  &.align-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const CardTitle = styled('h2')`
  font-size: 16px;
  padding: ${({ theme }) => theme.spacers.size8} 0;
`

export const CardGridWrapper = styled('div')`
  padding-top: ${({ theme }) => theme.spacers.size32};
  padding-bottom: ${({ theme }) => theme.spacers.size32};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 1.5rem;
`

export const SectionDivider = styled('hr')`
  border: 0;
  height: 0;
  border-top: 1px solid ${({ theme }) => theme.colours.baseLight};
`

export const SectionHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const SectionHeading = styled('h3')`
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
`

export const ShowHide = styled('a')<{ fontSize?: 14 | 16 }>`
  color: ${({ theme }) => theme.colours.primary};
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  font-size: ${({ theme, fontSize = 'size16' }) => theme.typography.pxToRem(fontSize as number)};
  &:hover {
    cursor: pointer;
  }
`

export const MultiLineDataValues = styled('div')`
  text-align: left;
  & + div {
    padding-top: 0.5rem;
  }
`

export const IconBackground = styled('span')<{ colour: keyof Theme['colours'] }>`
  border-radius: ${({ theme }) => theme.display.borderRadius};
  & svg {
    display: block;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacers.size48};
  height: ${({ theme }) => theme.spacers.size48};
  background-color: ${({ theme, colour }) => theme.colours[colour]};
`

export const ClickableDiv = styled('div')`
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: absolute;
`

export const ClickableDivWrapper = styled('div')`
  position: relative;
`
