import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface TextFieldClasses {
  root: string
  field: string
  label: string
  input: string
  helperText: string
  errorText: string
  displayField: string
}

export type TextFieldClassKey = keyof TextFieldClasses

export function getTextFieldUtilityClass(slot: string) {
  return generateUtilityClass('TextField', slot)
}

const textFieldClasses: TextFieldClasses = generateUtilityClasses('TextField', [
  'root',
  'field',
  'label',
  'input',
  'helperText',
  'errorText',
  'displayField',
])

export default textFieldClasses
