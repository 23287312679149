import { IntlShape } from 'react-intl'

import { generateUniqueId } from '@acre/utils'
import { FeeType, MortgageClubFeeCalculated, NewFee } from '@acre/graphql'
import { DropdownOption, translateOptions } from '@acre/design-system'

import { InputTableBuilderElementType, Row, TableStructure } from '../../InputTableBuilder'

export const messagePrefix = 'cases.products.options.generateIllustration'

type ProductFeeFieldType = {
  fieldType: InputTableBuilderElementType
}

type SelectOption = DropdownOption
export type CaseFeeSelectOption = SelectOption & ProductFeeFieldType

// List of fee options for populating <select>
// element <option>s in InputTableBuilder component
export const caseFeeOptions: CaseFeeSelectOption[] = [
  {
    value: FeeType.PreadviceFixed,
    label: `${messagePrefix}.preMortgageAdvice`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.PrerecommendationFixed,
    label: `${messagePrefix}.preMortgageRecommendation`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.PrerecommendationPercent,
    label: `${messagePrefix}.preMortgageRecommendationPercent`,
    fieldType: InputTableBuilderElementType.percent,
  },
  {
    value: FeeType.PreapplicationFixed,
    label: `${messagePrefix}.preMortgageApplication`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.PreapplicationPercent,
    label: `${messagePrefix}.preMortgageApplicationPercent`,
    fieldType: InputTableBuilderElementType.percent,
  },
  {
    value: FeeType.OfferFixed,
    label: `${messagePrefix}.offer`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.OfferPercent,
    label: `${messagePrefix}.offerPercent`,
    fieldType: InputTableBuilderElementType.percent,
  },
  {
    value: FeeType.LegalCompletionFixed,
    label: `${messagePrefix}.legalCompletion`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.LegalCompletionPercent,
    label: `${messagePrefix}.legalCompletionPercent`,
    fieldType: InputTableBuilderElementType.percent,
  },
  {
    value: FeeType.LegalExchangeFixed,
    label: `${messagePrefix}.legalExchangeFixed`,
    fieldType: InputTableBuilderElementType.currency,
  },
  {
    value: FeeType.LegalExchangePercent,
    label: `${messagePrefix}.legalExchangePercent`,
    fieldType: InputTableBuilderElementType.percent,
  },
]

// Based on the selected option value, we wish to render
// the correct input field component, which is either the
// <Currency /> or the <PercentageInput />
export const updateRowFieldTypes = (values: TableStructure): Row[] => {
  return values.rows.map((row) => {
    const selectedOption: FeeType = (row.columns[0].value as FeeType) || FeeType.InvalidFeeType
    const selectedOptionFieldType: CaseFeeSelectOption = caseFeeOptions.find(
      (option) => option.value === selectedOption,
    ) || {
      label: '',
      fieldType: InputTableBuilderElementType.text,
      value: FeeType.InvalidFeeType,
    }
    return {
      rowId: generateUniqueId(),
      columns: [
        row.columns[0],
        {
          ...row.columns[1],
          type: selectedOptionFieldType.fieldType,
        },
        row.columns[2],
      ],
    }
  })
}

// Takes the fees from a case and converts it to a
// structure passed into InputTableBuilder component
export const convertFeesToRows = (intl: IntlShape, caseFees: NewFee[], caseFeeOptions: CaseFeeSelectOption[]): Row[] =>
  caseFees.map((fee) => {
    // Pull in the correct CaseFeeSelectOption type
    // from caseFeeOptions to render the correct
    // form control display

    const selectedOption: Pick<CaseFeeSelectOption, 'fieldType' | 'value'> = caseFeeOptions.find(
      (option) => option.value === fee.fee_type,
    ) || {
      fieldType: InputTableBuilderElementType.text,
      value: FeeType.InvalidFeeType,
    }
    return {
      rowId: generateUniqueId(),
      columns: [
        {
          id: 'ProductFeeType',
          options: translateOptions(intl, caseFeeOptions),
          required: true,
          type: InputTableBuilderElementType.dropdown,
          defaultValue: FeeType.InvalidFeeType,
          value: selectedOption.value,
        },
        {
          id: 'ProductFeeAmount',
          required: true,
          type: selectedOption.fieldType,
          value: Number(fee.value) || '',
        },
        {
          id: 'RemoveButton',
          label: 'Remove button',
          type: InputTableBuilderElementType.remove,
        },
      ],
    }
  })

// Creates the structure for a new InputTableBuilder row
export const generateRow = (intl: IntlShape): Row => ({
  rowId: generateUniqueId(),
  columns: [
    {
      id: 'ProductFeeType',
      type: InputTableBuilderElementType.dropdown,
      options: translateOptions(intl, caseFeeOptions),
      required: true,
      defaultValue: FeeType.InvalidFeeType,
      value: FeeType.InvalidFeeType,
    },
    {
      id: 'ProductFeeAmount',
      type: InputTableBuilderElementType.text,
      required: true,
      value: '',
    },
    {
      id: 'RemoveButton',
      label: 'Remove button',
      type: InputTableBuilderElementType.remove,
    },
  ],
})

export const createMortgageClubOptions = (
  mortgageClubProcFees: MortgageClubFeeCalculated[] | null | undefined,
  preferredClubs?: string[],
): DropdownOption[] => {
  const filterByPreferredClubs = preferredClubs && preferredClubs.length > 0

  return (
    mortgageClubProcFees
      ?.filter(({ mortgage_club_name, mortgage_club_code, proc_fee }) => {
        if (filterByPreferredClubs) {
          return (
            mortgage_club_name &&
            mortgage_club_code &&
            (proc_fee === 0 || proc_fee) &&
            preferredClubs?.includes(mortgage_club_code)
          )
        }

        return mortgage_club_name && mortgage_club_code && proc_fee
      })
      .map(({ mortgage_club_name, mortgage_club_code, proc_fee }) => {
        return {
          // mortgage_club_name and proc_fee are not falsy,
          // because falsy values have been filtered above
          label: `${mortgage_club_name!} - £${proc_fee! / 100}`,
          value: mortgage_club_code!,
        }
      }) || []
  )
}

export const getFormattedValues = (values: TableStructure) => {
  const formattedValues: NewFee[] = values.rows.map((row: Row) => {
    const FEE_TYPE = 0
    const FEE_VALUE = 1
    const rowValue = row.columns[FEE_VALUE].value?.toString() || 0
    return {
      fee_type: row.columns[FEE_TYPE].value as FeeType,
      value: rowValue ? rowValue : '0',
    }
  })

  return formattedValues
}

export const removeTypeNameFromFees = (fees: NewFee[]) =>
  fees.map((fee) => {
    return { fee_type: fee.fee_type, value: fee.value }
  })
