import { styled } from '@mui/material'

export type TextProps = {
  textAlign?: string
  fontColor?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: string
  fontFamily?: string
  className?: string
  textDecoration?: string
  userSelect?: string
  whiteSpace?: string
  wordBreak?: string
  maxWidth?: string
  width?: string
  margin?: string
}

// if you wish to use this component as a different type of tag, you can do so using the 'as' prop i.e.
// <Text as="span">This will be rendered as a span tag</Text> or <Text as="p">This will be rendered as a p tag</Text>
const Text = styled('div')<TextProps>(
  ({
    theme,
    fontColor,
    fontSize,
    lineHeight,
    fontWeight,
    fontFamily,
    textAlign,
    maxWidth,
    whiteSpace,
    textDecoration,
    userSelect,
    wordBreak,
    width,
    margin,
  }) => `
  color: ${fontColor || theme.colours.base};
  font-size: ${`${fontSize}px` || '14px'};
  line-height: ${lineHeight || '1.6'};
  font-weight: ${fontWeight || theme.typography.fontWeightRegular};
  font-family: ${fontFamily || theme.typography.fontFamily};
  text-align: ${textAlign};
  max-width: ${maxWidth};
  white-space: ${whiteSpace};
  text-decoration: ${textDecoration};
  user-select: ${userSelect};
  word-break: ${wordBreak};
  width: ${width};
  margin: ${margin};

  &.overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`,
)

/* TODO: refactor this into oblivion */

export const GreyText = styled('span')<{
  fontSize?: number
}>(
  ({ theme, fontSize }) => `
  color: ${theme.colours.baseMid};
  font-size: ${`${fontSize}px` || '16px'};
`,
)

export const BoldText = styled('span')`
  font-weight: bold;
`

export default Text
