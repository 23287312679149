import { useState } from 'react'

import { useFormatMessage, useUserContext } from '@acre/utils'
import { Maybe, requesters } from '@acre/graphql'
import config from '@acre/config'
import { ErrorMessage, GlobalWarning, theme } from '@acre/design-system'

import { clearCookies, extractHostAndPortFromUrl } from './SupportUserWarning.helpers'

const { API1_URL, CLIENT_ORIGINATOR } = config

const loginAsSupportUser = async () => {
  const response = await requesters.default.get('/authentication/support/return', {
    baseURL: `${API1_URL}/acre`,
    withCredentials: true,
  })
  return response
}

const url = window.location.href
const extractedHostAndPort = extractHostAndPortFromUrl(url)

export const SupportUserWarning = () => {
  const formatMessage = useFormatMessage()
  const user = useUserContext()

  const supportRoles = ['Acre Support Read Only', 'Acre Support Modification']
  const includesSupportRole = supportRoles.includes(user?.role_name!)
  const reviewModeEnabled = location.pathname.includes('review-mode')

  const [error, setError] = useState<Maybe<Error>>(null)

  const handleLogin = () => {
    loginAsSupportUser()
      .then(() => {
        clearCookies()
        location.replace(`https://${extractedHostAndPort}/company/${CLIENT_ORIGINATOR}/information`)
      })
      .catch((err) => {
        setError(err)
      })
  }

  if (error) {
    return <ErrorMessage error={<>{`Error! ${error.message}`}</>} />
  }

  if (includesSupportRole && !reviewModeEnabled) {
    return (
      <GlobalWarning
        colour={theme.colours.new.white}
        background={theme.colours.primary}
        messageText={formatMessage('globalWarnings.supportMessage', {
          supportRole: user?.role_name || '',
          supportedOrg: user?.organisation?.name || '',
        })}
        buttonText={formatMessage('globalWarnings.switchBack')}
        buttonAction={handleLogin}
      />
    )
  }
}
