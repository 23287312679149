import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@mui/material'
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import Select, { components, Props } from 'react-select'
import { ControlProps } from 'react-select/src/components/Control'
import { IndicatorProps } from 'react-select/src/components/indicators'
import { MenuListComponentProps, MenuProps } from 'react-select/src/components/Menu'
import { MultiValueProps } from 'react-select/src/components/MultiValue'

import { useClickOutsideListener } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { BUTTON_SECONDARY, HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { DropdownOption } from '../../utils/types'
import Button from '../Button'
import DateInput from '../DateInput'
import Icon, { IconName } from '../FeatherIcon'
import HelperText from '../HelperText'
import Label from '../Label'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { Circle } from '../Radio/Radio.styles'
import { selectStyles } from './DateRangeSelector.styles'

export type DateRangeSelectorProps = {
  id: string
  label?: string
  value?: string
  onChange: (value: string | undefined) => any
  onApplyFilter?: () => void
  error?: boolean
  disabled?: boolean
  message?: string
  placeholder?: string
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
}

const DateRangeSelector = ({
  id,
  label,
  value,
  onChange,
  onApplyFilter,
  error,
  disabled: disabledProp,
  message,
  placeholder,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant,
}: DateRangeSelectorProps) => {
  const theme = useTheme()
  const selectRef = useRef<Select>(null)

  const intl = useIntl()

  const formatMessage = useCallback(
    (id: string, label?: string, less?: string) => intl.formatMessage({ id }, { label, first: label, second: less }),
    [intl],
  )

  const disabled = useFieldDisabledState(disabledProp)

  const start_date = useCallback(
    () => (value?.split(',')[0] && isValid(parseISO(value?.split(',')[0])) ? value?.split(',')[0] : undefined),
    [value],
  )

  const end_date = useCallback(
    () => (value?.split(',')[1] && isValid(parseISO(value?.split(',')[1])) ? value?.split(',')[1] : undefined),
    [value],
  )

  let Options: DropdownOption[] = useMemo(() => {
    const now = new Date()

    return [
      {
        label: formatMessage('dateRangeSelector.options.today'),
        value: `${format(now, 'yyyy-MM-dd')},${format(now, 'yyyy-MM-dd')}`,
      },
      {
        label: formatMessage('dateRangeSelector.options.thisWeek'),
        value: `${format(startOfWeek(now, { weekStartsOn: 1 }), 'yyyy-MM-dd')},${format(
          endOfWeek(now, { weekStartsOn: 1 }),
          'yyyy-MM-dd',
        )}`,
      },
      {
        label: formatMessage('dateRangeSelector.options.thisMonth'),
        value: `${format(startOfMonth(now), 'yyyy-MM-dd')},${format(endOfMonth(now), 'yyyy-MM-dd')}`,
      },
      {
        label: formatMessage('dateRangeSelector.options.thisYear'),
        value: `${format(startOfYear(now), 'yyyy-MM-dd')},${format(endOfYear(now), 'yyyy-MM-dd')}`,
      },
      {
        label: formatMessage('dateRangeSelector.options.dateRange'),
        value: `,`,
      },
    ]
  }, [formatMessage])

  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(
    value ? Options.find((x) => x.value === value) || Options[4] : undefined,
  )
  const [startDate, setStartDate] = useState<string | undefined>(start_date())
  const [endDate, setEndDate] = useState<string | undefined>(end_date())
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutsideListener(containerRef, () => setMenuIsOpen(false), menuIsOpen)

  useEffect(() => {
    startDate && endDate && onChange(`${startDate},${endDate}`)
  }, [startDate, endDate, onChange])

  useEffect(() => {
    !value && selectRef?.current?.select.clearValue()
    setSelectedOption(value ? Options.find((x) => x.value === value) || Options[4] : undefined)
    setStartDate(start_date())
    setEndDate(end_date())
  }, [Options, end_date, start_date, value])

  const handleChange = useCallback<Required<Props>['onChange']>(
    (...args) => {
      if (args[1].action === 'clear') {
        setSelectedOption(undefined)
        onChange(undefined)
        onApplyFilter && onApplyFilter()
        setMenuIsOpen(false)
      } else if (args[1] && args[1].action === 'select-option' && args[0]) {
        setSelectedOption(args[0])
        onChange(args[0].value)
        args[0].label === Options[4].label && setStartDate(undefined)
        args[0].label === Options[4].label && setEndDate(undefined)
      }
    },
    [Options, onApplyFilter, onChange],
  )

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    isValid(parseISO(e.target.value)) && e.target.value != startDate && setStartDate(e.target.value)
  }

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    isValid(parseISO(e.target.value)) && e.target.value != endDate && setEndDate(e.target.value)
  }

  // collect styles which have theme applied to it
  const styles = selectStyles(theme, variant)

  // A component to replace Control
  const Control = ({ children, ...props }: ControlProps<any, any>) => {
    return (
      <div
        aria-hidden="true"
        onClick={() => {
          setMenuIsOpen(!menuIsOpen)
        }}
      >
        <components.Control {...props}>{children}</components.Control>
      </div>
    )
  }

  const Menu = (props: MenuProps<any, any>) => {
    return (
      <components.Menu {...props}>
        <>
          {props.children}
          <Box mx={theme.spacers.size16} my={theme.spacers.size8}>
            <DateInput
              label={formatMessage('dateRangeSelector.from')}
              id="StartDate"
              variant="compactLeftAligned"
              disabled={selectedOption?.label !== Options[4].label}
              value={startDate}
              onChange={handleStartDateChange}
              onClick={(e) => {
                e.stopPropagation()
                e.currentTarget.focus()
              }}
            />
          </Box>
          <Box mx={theme.spacers.size16} my={theme.spacers.size8}>
            <DateInput
              label={formatMessage('dateRangeSelector.to')}
              id="EndDate"
              variant="compactLeftAligned"
              disabled={selectedOption?.label !== Options[4].label}
              value={endDate}
              onChange={handleEndDateChange}
              onClick={(e) => {
                e.stopPropagation()
                e.currentTarget.focus()
              }}
            />
          </Box>
          <Box mx={theme.spacers.size16} mt={theme.spacers.size8} mb={theme.spacers.size16}>
            <Button
              fullWidth
              disabled={!props.hasValue || !startDate || !endDate}
              buttonStyle={BUTTON_SECONDARY}
              variant="compact"
              onClick={() => {
                onChange(`${startDate},${endDate}`)
                onApplyFilter && onApplyFilter()
                setMenuIsOpen(false)
              }}
            >
              <FormattedMessage id="dateRangeSelector.applyFilter" />
            </Button>
          </Box>
        </>
      </components.Menu>
    )
  }
  // A component to replace valueContainer
  const SingleValue = (props: any) => {
    let text =
      selectedOption?.label === Options[4].label && startDate && endDate
        ? `${format(parseISO(startDate), 'dd/MM/yyyy')} to ${format(parseISO(endDate), 'dd/MM/yyyy')}`
        : selectedOption?.label
    return <components.SingleValue {...props}>{text}</components.SingleValue>
  }

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <Box mr={theme.spacers.size8}>
          <Circle className={props.isSelected ? 'checked' : undefined} variant={variant} />
        </Box>
        {props.children}
      </components.Option>
    )
  }

  // A component to replace the menu list, this was done to add a test id
  const MenuList = (props: MenuListComponentProps<any, any>) => {
    return (
      <components.MenuList {...props}>
        <div data-testid={testHandle(`${id}MenuList`)}>{props.children}</div>
      </components.MenuList>
    )
  }

  // a component to replace the dropdown icon with a custom caret in the select component
  const DropdownIndicator = (props: IndicatorProps<any, any>) => (
    <components.DropdownIndicator {...props}>
      <Icon name={IconName.ChevronDown} />
    </components.DropdownIndicator>
  )

  // remove button icon
  const MultiValueRemove = (props: MultiValueProps<any>) =>
    disabled ? null : (
      <components.MultiValueRemove {...props}>
        <Icon name={IconName.X} />
      </components.MultiValueRemove>
    )

  return (
    <div data-testid={testHandle(id)} ref={containerRef}>
      <LabelAndInputWrapper variant={variant}>
        {label ? (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
            variant={variant}
            customMarginBottom={theme.spacers.size4}
          />
        ) : null}
        <Select
          ref={selectRef}
          menuIsOpen={menuIsOpen}
          styles={styles}
          openMenuOnClick={true}
          isClearable={true}
          closeMenuOnSelect={false}
          backspaceRemovesValue={false}
          tabSelectsValue={false}
          isDisabled={disabled}
          isSearchable={false}
          isMulti={false}
          options={Options}
          onChange={handleChange}
          value={selectedOption}
          placeholder={placeholder}
          hideSelectedOptions={false}
          components={{
            Control,
            SingleValue,
            Option,
            DropdownIndicator,
            Menu,
            MenuList,
            MultiValueRemove,
          }}
        />
      </LabelAndInputWrapper>

      {message && <HelperText id={id} message={message || ''} textType={error ? HELPER_TYPE_ERROR : ''} />}
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </div>
  )
}

export default withDisabled(DateRangeSelector)
