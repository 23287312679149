import { styled } from '@mui/material'

export const RecommendationMessage = styled('p')`
  color: ${({ theme }) => theme.colours.baseMid};
  text-align: left;
  font-size: 12px;
  line-height: 1.6;
`

export const FormSectionHeader = styled('h2')`
  font-size: 16px;
`

export const MortgageClubMessage = styled('p')`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Ul = styled('ul')`
  list-style-type: disc;
  margin-left: 24px;
  margin-bottom: 16px;
  li {
    font-size: 14px;
    line-height: 1.6;
  }
`
