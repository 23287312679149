import { styled } from '@mui/material'
import { rem } from 'polished'

import { Variant } from '../../utils/constants'
import NumberFormat, { NumberFormatProps } from './NumberFormat'

export const StyledInput = styled(NumberFormat)<{ variant: Variant } & NumberFormatProps>(
  ({ theme, variant }) => `

  transition: all 0.1s ease-in-out;

  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  background-color: ${theme.colours.foreground};
  border-radius: ${theme.display.borderRadius};
  border: 1px solid ${theme.colours.baseLight};
  box-sizing: border-box;
  font-size: 16px;
  padding: ${rem(11)} 1rem;
  color: ${theme.colours.base};
  line-height: ${rem(26)};
  width: 100%;
  min-width: 0;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colours.baseMid};
  }

  /* For IE this declaration needs to be separate */
  ::-ms-input-placeholder {
    color: ${theme.colours.baseMid};
  }

  &:focus,
  &:active:not(:disabled):not(.error) {
    border-color: ${theme.colours.primary};
    outline: none;
  }

  &:disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-width: 0;
  height: ${theme.display.inputHeight};
  order: 2;
  min-width: 0;

  &:focus ~ label {
    border-color: ${theme.colours.primary};
  }

  ${
    variant.includes('compact') &&
    `
  height: ${theme.spacers.size32};
  font-size: 14px;
  padding-left: ${theme.spacers.size12};
`
  }
`,
)
