import isEmpty from 'lodash/isEmpty'

import { useFormatMessage } from '@acre/utils'
import { Maybe, TemplateType, User } from '@acre/graphql'
import config from '@acre/config'

export const getClientInvitePayloads = (
  formatMessage: ReturnType<typeof useFormatMessage>,
  selectedClients?: string[],
  caseId?: Maybe<string>,
  user?: Maybe<User>,
) => {
  if (isEmpty(selectedClients) || !caseId || !user) return

  const clientPortalIddPayload = {
    template_name: TemplateType.ClientPortalIdd,
    client_ids: selectedClients,
    case_ids: [caseId],
    organisation_ids: [user.organisation_id],
    store_document: true,
  }

  const organisationPrivacyPayload = {
    template_name: TemplateType.OrganisationPrivacyNotice,
    client_ids: selectedClients,
    organisation_ids: [user.organisation_id],
    store_document: true,
  }

  const creditReportReferralPayload = {
    template_name: TemplateType.Idd,
    client_ids: selectedClients,
    organisation_id: config.ACRE_SUPPORT_ORG_ID,
  }

  const emailPayloads = selectedClients?.map((clientId) => {
    return {
      template: {
        template_name: TemplateType.WelcomeEmail,
        client_ids: [clientId],
        organisation_ids: [user.organisation_id],
        user_ids: [user.id],
        case_ids: [caseId],
      },
      subject: formatMessage('caseOverview.clientInvitation.emailSubjectLine', {
        orgName: user.organisation?.name,
      }),
      client_recipient_ids: [clientId],
    }
  })

  return {
    emailPayloads,
    clientPortalIddPayload,
    organisationPrivacyPayload,
    creditReportReferralPayload,
  }
}
