import { Maybe } from '@acre/graphql'

export const messagePrefix = 'cases.reviewMode.verifications'

export const DEFAULT_VALUE = '-'

export const capitaliseFirstLetterOfSentence = (string: Maybe<string>) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
