import React, { ReactNode } from 'react'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'

export type NavProps = {
  children?: ReactNode
  offset?: string
  right?: ReactNode
  left?: ReactNode
  childNav?: boolean
}

const Nav = ({ left, right, children }: NavProps) => {
  return (
    // +2 on drawer, since the global warnings is + 1
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
      <Toolbar
        disableGutters
        variant="dense"
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          color: theme.colours.base,
        })}
      >
        <Typography
          variant="h6"
          component="div"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="stretch"
          height="100%"
          display="flex"
          sx={(theme) => ({
            '& a': {
              height: theme.spacing(6),
            },
          })}
        >
          {left}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {children}
        </Box>
        <Typography
          variant="h6"
          component="div"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          height="100%"
          display="flex"
        >
          {right}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Nav
