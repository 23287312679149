import { createContext, useContext } from 'react'

type ReportProblemType = {
  isProblemReported: boolean
  setIsProblemReported: React.Dispatch<React.SetStateAction<boolean>>
}
export const ReportProblemContext = createContext([] as unknown as ReportProblemType)

export const ReportProblemConsumer = ReportProblemContext.Consumer
export const ReportProblemProvider = ReportProblemContext.Provider

export const useReportProblemContext = () => useContext(ReportProblemContext)
