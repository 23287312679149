import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface DisplayFieldClasses {
  root: string
  field: string
  label: string
  value: string
  controls: string
  helperText: string
  errorText: string

  // size
  small: string
  medium: string
  large: string
}

export type DisplayFieldClassKey = keyof DisplayFieldClasses

export function getDisplayFieldUtilityClass(slot: string) {
  return generateUtilityClass('DisplayField', slot)
}

const displayFieldClasses: DisplayFieldClasses = generateUtilityClasses('DisplayField', [
  'root',
  'field',
  'label',
  'value',
  'controls',
  'helperText',
  'errorText',

  // size
  'small',
  'medium',
  'large',
])

export default displayFieldClasses
