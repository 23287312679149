import React, { createContext, PropsWithChildren, useCallback, useState } from 'react'
import { cloneDeep, get, isNil, set } from 'lodash'

import { FieldStatus } from '../common/hooks/useProtectedField.types'

export type ProtectedFieldState = {
  status?: number
  unprotectedValue?: any
  initialState?: any
  clientId?: string
}

type ProtectedFieldActions = {
  protectedFieldMetaState: { [key: string]: ProtectedFieldState }
  setProtectedFieldMetaState: (action: { fieldName: string; payload: ProtectedFieldState }) => void
  cleanProtectedFieldValues?: <T extends object>({
    values,
    fieldsToExclude,
    ignoreField,
  }: {
    values: T
    fieldsToExclude: string[]
    ignoreField: string
  }) => T
  resetMetaState?: () => void
  tearDownMetaState?: () => void
}

export const ProtectedFieldContext = createContext<ProtectedFieldActions>({
  protectedFieldMetaState: {},
  setProtectedFieldMetaState: () => {},
})

const initialState = {}

export type ProtectedFieldsProviderProps = PropsWithChildren<{}>

export const ProtectedFieldsProvider = ({ children }: ProtectedFieldsProviderProps) => {
  const [protectedFieldMetaState, _setProtectedFieldMetaState] = useState<{
    [key: string]: ProtectedFieldState
  }>(initialState)
  let newState = {}
  const setProtectedFieldMetaState = (action: { fieldName: string; payload: ProtectedFieldState }) => {
    _setProtectedFieldMetaState((state) => {
      newState = {
        ...state,
        [action.fieldName]: {
          ...(state[action.fieldName] ? state[action.fieldName] : {}),
          ...action.payload,
        },
      }
      return newState
    })
  }

  const resetMetaState = () => {
    Object.keys(protectedFieldMetaState).forEach((fieldName) => {
      if (protectedFieldMetaState[fieldName].status === FieldStatus.Edited) {
        setProtectedFieldMetaState({
          fieldName,
          payload: {
            status: undefined,
            unprotectedValue: undefined,
          },
        })
      }
    })
  }

  //removing any stored meta values when a form is exited or closed
  const tearDownMetaState = () => {
    Object.keys(protectedFieldMetaState).forEach((fieldName) => {
      setProtectedFieldMetaState({
        fieldName,
        payload: {
          status: undefined,
          unprotectedValue: undefined,
          initialState: undefined,
        },
      })
    })
  }

  const cleanProtectedFieldValues = useCallback(
    function <T extends object>({
      values,
      fieldsToExclude,
      ignoreField,
    }: {
      values: T
      fieldsToExclude: string[]
      ignoreField: string
    }) {
      // the fields which are not edited should be removed
      // if they are not, obfuscated data will be submitted
      try {
        Object.keys(protectedFieldMetaState).forEach((field) => {
          if (protectedFieldMetaState[field]?.status !== FieldStatus.Edited && !field.includes(ignoreField)) {
            values = set<T>(cloneDeep(values), field, undefined)
          }
        })

        if (!Object.keys(protectedFieldMetaState).length) {
          for (const field of fieldsToExclude) {
            if (!isNil(get(values, field))) {
              values = set<T>(cloneDeep(values), field, undefined)
            }
          }
        }
      } catch (e) {
        console.error(e)
      }

      return values
    },
    [protectedFieldMetaState],
  )

  const value = {
    protectedFieldMetaState,
    setProtectedFieldMetaState,
    cleanProtectedFieldValues,
    resetMetaState,
    tearDownMetaState,
  }

  return <ProtectedFieldContext.Provider value={value}>{children}</ProtectedFieldContext.Provider>
}
