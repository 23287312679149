import { MessageDescriptor, useIntl } from 'react-intl'

const useFormatMessage = () => {
  const { formatMessage } = useIntl()

  return (id: MessageDescriptor['id'], values?: Record<string, string | number | boolean | null | undefined | Date>) =>
    formatMessage({ id }, values)
}

export default useFormatMessage
