import { styled, Theme } from '@mui/material'
import { darken } from 'polished'

export const InitialsWrapper = styled('span')<{ size: keyof Theme['spacers'] }>(
  ({ theme, color, size }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color};
  color: ${darken(0.6, color!)};
  width: ${theme.spacers[size]};
  min-width: ${theme.spacers[size]};
  height: ${theme.spacers[size]};
  font-size: ${size === 'size48' ? '26px' : '14px'};
  font-weight: ${theme.typography.fontWeightBold};
  border-radius: 50%;
`,
)
