import type { FieldError } from 'react-hook-form'

import { FieldProps } from '@acre/design-system'

type GetCommonTranslatedPropsOptions = {
  parseText?: (text: string) => string
  error?: FieldError
}

const getCommonTranslatedProps = <Props extends FieldProps>(props: Props, opts?: GetCommonTranslatedPropsOptions) => {
  const { parseText, error } = opts || {}

  const label = parseText && typeof props.label === 'string' ? parseText(props.label) : props.label
  const helperText = parseText && typeof props.helperText === 'string' ? parseText(props.helperText) : props.helperText

  const passedError = error?.message || props.error
  const errorMessage = parseText && typeof passedError === 'string' ? parseText(passedError) : passedError

  return { label, helperText, error: errorMessage }
}

export default getCommonTranslatedProps
