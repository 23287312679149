import * as yup from 'yup'

import { ERROR_REQUIRED, unwrapErrors } from '@acre/utils'
import { FeeType, Maybe, NewFee } from '@acre/graphql'

export const messagePrefix = 'caseTransitions.toComplete'

export const getTotal = (fees: Maybe<number>[]) => {
  return fees.reduce((acc: number, fee) => {
    if (fee) return fee + acc
    return acc
  }, 0)
}

export const getFeeValue = (feeType: FeeType, value: string, mortgageAmount?: Maybe<string>) => {
  if (feeType.includes('PERCENT')) {
    if (!mortgageAmount) return null
    // percentages are represented by 5dp on the BE
    // but in the FE mapping we map it to percent numbers i.e. 1% = 1
    const percentage = parseInt(value) / 100
    return percentage * parseInt(mortgageAmount)
  }
  return parseInt(value)
}

export const getFeeTypeTotal = (feeTypes: FeeType[], fees: NewFee[], mortgageAmount?: Maybe<string>) => {
  let total: number = 0

  // Go through each feeType and calculate the fee value
  feeTypes.forEach((feeType) => {
    const fee = fees.find((f) => f.fee_type === feeType)
    if (fee && fee.value) {
      const feeValue = getFeeValue(fee.fee_type, fee.value, mortgageAmount)
      if (feeValue) {
        total += feeValue
      }
    }
  })

  // Return total or null based on if any fees were added
  return total || null
}

const getPercentage = (num: number, per: number) => {
  return (num / 100) * per
}

export const validate = (initialValues: { mortgage_amount?: string | null; mortgage_start_date: string | null }) => {
  const feesSchema = yup.object().shape({
    mortgage_amount: yup
      .number()
      .nullable()
      .required(ERROR_REQUIRED)
      .test('is new value increased or decreased by more than 10%', `${messagePrefix}.amendedAmountError`, (value) => {
        if (initialValues?.mortgage_amount) {
          const initialMortgageAmount = parseInt(initialValues.mortgage_amount)
          const tenPercentOfInitialMortgageAmount = getPercentage(initialMortgageAmount, 10)
          const tooHight = initialMortgageAmount + tenPercentOfInitialMortgageAmount
          const tooLow = initialMortgageAmount - tenPercentOfInitialMortgageAmount

          if (value > tooHight) {
            return false
          }
          if (value < tooLow) {
            return false
          }
          return true
        }
        return true
      }),
  })
  return unwrapErrors(feesSchema)
}

export enum TransitionToCompleteStage {
  MORTGAGE_DETAILS,
  PROTECTION_DETAILS,
  ADD_REMINDER,
  UPDATE_CASE_STATUS,
}
