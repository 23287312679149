import 'tippy.js/dist/tippy.css'
import { css,styled } from '@mui/material'
import { GlobalStyles } from '@mui/material'

const sharedStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const OuterContainer = styled('span')`
  ${sharedStyles}
`

export const InnerContainer = styled('span')`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`

export const MissingOuterContainer = styled('span')`
  ${sharedStyles}
`

export const MissingInnerContainer = styled('span')`
  ${sharedStyles}
  width: 100%;
`

export const CustomTippyStyles = ({ wide }: { wide: boolean }) => (
  <GlobalStyles
    styles={(theme) => `
    .tippy-box[data-theme~='acre'] {
      background-color: ${theme.colours.foreground};
      border: 1px solid ${theme.colours.baseLight};
      color: ${theme.colours.base};
      box-shadow: ${theme.boxShadow};
      text-align: left;
      max-width: ${wide ? '500px' : '320px'};
      border-radius: ${theme.display.borderRadius};
      padding: ${theme.spacing(1.5)};
    }

    .tippy-box[data-theme~='missing'] {
      background-color: ${theme.colours.foreground};
      border: 1px solid ${theme.colours.danger};
      color: ${theme.colours.danger};
      box-shadow: ${theme.boxShadow};
      text-align: left;
      max-width: ${wide ? '500px' : '320px'};
      border-radius: ${theme.display.borderRadius};
      padding: ${theme.spacing(1.5)};
      z-index: 1;
    }

    .tippy-arrow {
      display:none;
    }
`}
  />
)
