import React, { ComponentProps, useCallback } from 'react'
import { Button } from '@mui/material'
import type { FieldValues, SubmitHandler } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { FormSchemaProvider } from '../hooks/useFormSchema'
import useParseSchema from '../hooks/useParseSchema'
import type { ComponentMapBase, FormSchema } from '../types/schema'
import Form, { FormProps } from './Form'
import SchemaFormComponents from './schema/SchemaFormComponents'
import isValueDefined from './util/isValueDefined'

export type SchemaFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TSchema extends FormSchema = FormSchema,
> = Omit<
  FormProps<TSchema['validationSchema'], TSchema['visibilitySchema'], TFieldValues, TComponentMap>,
  'defaultValues' | 'validationSchema' | 'visibilitySchema'
> &
  Partial<Omit<ComponentProps<TForm>, 'onSubmit' | 'defaultValues' | 'validationSchema' | 'visibilitySchema'>> & {
    onSubmit: SubmitHandler<TFieldValues>
    schema: TSchema
    layout?: React.ComponentType
    FormComponent?: TForm
  }

function _SchemaForm<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TSchema extends FormSchema = FormSchema<AnyObjectSchema, AnyObjectSchema, TComponentMap, TFieldValues>,
>({
  schema,
  layout: Layout,
  FormComponent,
  onSubmit,
  ...props
}: SchemaFormProps<TFieldValues, TComponentMap, TForm, TSchema>) {
  const { formProps, blocks, blockIndex } = useParseSchema<
    AnyObjectSchema,
    AnyObjectSchema,
    TFieldValues,
    TComponentMap
  >(schema)

  const Cmp = FormComponent ?? Form

  const handleSubmit = useCallback<SubmitHandler<TFieldValues>>(
    (values, event) => {
      let _values = { ...values } as Record<keyof TFieldValues, unknown>

      if (schema.transforms) {
        _values = Object.entries(_values).reduce((acc, [key, value]: [keyof TFieldValues, unknown]) => {
          const fieldType = blockIndex[key].type

          if (isValueDefined(value) && schema.transforms && fieldType in schema.transforms) {
            acc[key] = schema.transforms[fieldType]?.(value, 'out')
          } else {
            acc[key] = value
          }

          return acc
        }, _values)
      }

      return onSubmit(_values as TFieldValues, event)
    },
    [schema.transforms, onSubmit, blockIndex],
  )

  return (
    <FormSchemaProvider value={{ schema, blockIndex }}>
      <Cmp {...props} {...formProps} onSubmit={handleSubmit}>
        {Layout ? <Layout /> : <SchemaFormComponents components={blocks} />}
        {!FormComponent && (
          <Button size="large" type="submit">
            Submit
          </Button>
        )}
      </Cmp>
    </FormSchemaProvider>
  )
}

const SchemaForm = React.memo(_SchemaForm) as typeof _SchemaForm

export default SchemaForm
