import React, { ReactElement } from 'react'
import { MockedProvider,MockedProvider as MockedProviderHooks } from '@apollo/client/testing'
import { CaseContextProvider, ClientProvider, PermissionProvider } from '@broker-crm-contexts'
import { render } from '@testing-library/react'
import { IntlProvider } from 'react-intl'
import { MemoryRouter } from 'react-router-dom'

import { FlagProvider, flagsAllEnabled, flattenMessages, getMessages, Locales } from '@acre/utils'
import { muiTheme, ThemeProvider } from '@acre/design-system'

import messages from '../intl/messagesBrokerCrm'

const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

export default (testCaseContext: any, testClientContext: any, component: ReactElement) => {
  return render(
    <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
      <ThemeProvider theme={muiTheme}>
        <MockedProvider mocks={[]} addTypename={false}>
          <MockedProviderHooks mocks={[]} addTypename={false}>
            <MemoryRouter>
              <FlagProvider value={flagsAllEnabled}>
                <PermissionProvider value={{ gates: [] }}>
                  <CaseContextProvider value={{ ...testCaseContext }}>
                    <ClientProvider value={{ ...testClientContext }}>{component}</ClientProvider>
                  </CaseContextProvider>
                </PermissionProvider>
              </FlagProvider>
            </MemoryRouter>
          </MockedProviderHooks>
        </MockedProvider>
      </ThemeProvider>
    </IntlProvider>,
  )
}
