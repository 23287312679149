import { DocumentType, MortgageReason, Notification, NotificationEventType } from '@acre/graphql'

export enum NotificationEventTargetType {
  CLIENT = 'CLIENT',
  BROKER = 'BROKER',
}

export enum NotificationEventSourceType {
  CLIENT = 'CLIENT',
  USER = 'USER',
}

export enum NotificationOperation {
  DELETE = 'DELETE',
  DELETE_ALL = 'DELETE_ALL',
  GET_ALL = 'GET_ALL',
  INVALID_OPERATION = 'INVALID_OPERATION',
}

type NotificationSource = {
  sourceType?: NotificationEventSourceType
  id?: string
  firstName?: string
  lastName?: string
}

export interface NotificationSubEvent {
  id: string
  targetId: string
  targetType: NotificationEventTargetType
  createdAt: string
  source: NotificationSource
}

/**
 * Notification with __typename to be written into the cache
 */
export type NotificationWithTypename = Notification & { __typename: 'Notification' }

/**
 * A collection of client, user, and case IDs to resolve
 */
export type IdCollection = { client: string[]; user: string[]; case: string[] }

/*##############################################
  START Individual Notification Types
 ##############################################*/

export interface DocumentUploadNotificationMessage extends NotificationSubEvent {
  type: NotificationEventType.NewDocument
  message: string
  newDocumentNotification: {
    caseId: string
    caseType: MortgageReason
    clientIds: string[]
    uploadedAt: string
    documentType: DocumentType
  }
}

export interface CaseAllocationNotificationMessage extends NotificationSubEvent {
  type: NotificationEventType.CaseAllocation
  caseAllocationNotification: {
    caseType: MortgageReason
    caseFor: string
    allocatedAt: string
  }
}

export interface NewSecureMessageNotification extends NotificationSubEvent {
  type: NotificationEventType.NewSecureMessage
  message: string
  newSecureMessageNotification: {
    caseId: string
    recipientFirstName: string
    recipientLastName: string
    senderFirstName: string
    senderLastName: string
  }
}

/* Union of all possible notification message types */
export type NotificationMessage = DocumentUploadNotificationMessage | NewSecureMessageNotification

/*##############################################
  END Individual Notification Types
 ##############################################*/

/**
 * The format that we recieve notifications from the backend in
 * and/or send back notifications for an operation
 */
export interface NotificationEnvelopeBase {
  targetId: string
  operation: NotificationOperation
}

interface NotificationEnvelopeDelete extends NotificationEnvelopeBase {
  operation: NotificationOperation.DELETE
  notifications: Pick<NotificationSubEvent, 'id'>[]
}

interface NotificationEnvelopeDeleteAll extends NotificationEnvelopeBase {
  operation: NotificationOperation.DELETE_ALL
}

interface NotificationEnvelopeGetAll extends NotificationEnvelopeBase {
  operation: NotificationOperation.GET_ALL
}

interface NotificationEnvelopeRecieve extends NotificationEnvelopeBase {
  operation: NotificationOperation.INVALID_OPERATION
  notifications: NotificationMessage[]
}

type NotificationEnvelopeTypes = {
  [NotificationOperation.DELETE]: NotificationEnvelopeDelete
  [NotificationOperation.DELETE_ALL]: NotificationEnvelopeDeleteAll
  [NotificationOperation.INVALID_OPERATION]: NotificationEnvelopeRecieve
  [NotificationOperation.GET_ALL]: NotificationEnvelopeGetAll
}

export type NotificationEnvelope<Operation extends keyof NotificationEnvelopeTypes> =
  NotificationEnvelopeTypes[Operation]
