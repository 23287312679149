import React, { ComponentType, forwardRef, PropsWithRef } from 'react'

import useFieldDisabledContext from '../../hooks/useFieldDisabledContext'

export type WithDisabledProps<OuterProps = unknown> = OuterProps & {
  disabled?: boolean
}

function withDisabled<P extends WithDisabledProps = WithDisabledProps>(WrappedComponent: ComponentType<P>) {
  const Component = forwardRef<ComponentType<P>, P>(function Component(props, ref) {
    let isDisabled = false

    const disabledContext = useFieldDisabledContext()

    // whenever there are scenario specific conditions for elements to be disabled,
    // we need to combine that scenario condition and the useFieldDisabledContext context value,
    // this is because the withDisabled HOC wrapping components, exclusively prioritises any disabled prop
    // passed in to a component
    if (props && props.disabled !== undefined) {
      isDisabled = props.disabled
    } else {
      isDisabled = disabledContext
    }

    return <WrappedComponent {...props} disabled={isDisabled} ref={ref} />
  }) as ComponentType<PropsWithRef<P>>

  Component.displayName = `withDisabled(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return Component
}

export default withDisabled
