import { matchSorter } from 'match-sorter'

import { DropdownOption } from '../../utils/types'

// returning sorted list
// function solo responsible for sorting the list, no default error messages
export const searchAndSortDropDown = (searchPrompt: string = '', options: DropdownOption[]): DropdownOption[] => {
  if (typeof searchPrompt !== 'string' || !searchPrompt) {
    return []
  }
  const sortedList: DropdownOption[] = matchSorter(options, searchPrompt, {
    keys: ['label'],
    threshold: matchSorter.rankings.CONTAINS,
  })
  return sortedList
}

export const searchAndSort = (searchPrompt: string = '', options: string[]): string[] => {
  if (typeof searchPrompt !== 'string' || !searchPrompt) {
    return []
  }
  const sortedList = matchSorter(options, searchPrompt, {
    threshold: matchSorter.rankings.CONTAINS,
  }).reduce((searchList: string[], item) => {
    searchList.push(item)
    return searchList
  }, [])
  // returning sorted list
  return sortedList.length > 0 ? sortedList : []
}
