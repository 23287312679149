import { useMutation } from '@apollo/client'
import { format, formatISO, formatRFC3339, isSameDay, parseISO } from 'date-fns'
import { omit } from 'lodash'
import * as yup from 'yup'

import {
  ERROR_DATE_GENERIC,
  ERROR_PAST,
  ERROR_REQUIRED,
  isInTheFuture,
  isValidDate,
  unwrapErrors,
  useFormatMessage,
  useUserContext,
} from '@acre/utils'
import {
  AddNoteDocument,
  AddNoteMutation,
  AddNoteMutationVariables,
  AssigneeType,
  GetGroupsQuery,
  GetNotesDocument,
  Maybe,
  NoteInput,
  NoteStatus,
  NoteType,
  omitTypename,
  UpdateNoteDocument,
  UpdateNoteMutation,
  UpdateNoteMutationVariables,
  useGetGroupsLazyQuery,
  useGetUserLazyQuery,
  useGetUserQuery,
  User,
} from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

import { useCaseContext } from '../../../contexts'
import { getOrgIdForCaseNotes } from '../../Cases/CaseDetails/CaseNotes/CaseNote.helpers'

export const formatAdviserName = (user: User) => {
  const firstName = user?.first_name ? user?.first_name : ''
  const lastName = user?.last_name ? user?.last_name : ''

  return `${firstName} ${lastName}`
}

export const defaultDropDownOption = (intlMessage: string, formatMessage: ReturnType<typeof useFormatMessage>) => {
  return {
    label: `${formatMessage(intlMessage)}`,
    value: '',
  } as DropdownOption
}

export const userDropDownOptions = (
  formatMessage: ReturnType<typeof useFormatMessage>,
  loading: boolean,
  users?: Maybe<User[]>,
) => {
  if (loading) return []
  return users
    ? users.map(
        (user) =>
          ({
            label: formatAdviserName(user),
            value: user.id,
          }) as DropdownOption,
      )
    : [defaultDropDownOption('caseNew.noAdvisors', formatMessage)]
}

export const getGroupDropdownOptions = (
  formatMessage: ReturnType<typeof useFormatMessage>,
  loading: boolean,
  groups?: GetGroupsQuery['getGroups'],
) => {
  if (loading) return []
  return groups
    ? groups
        .map(
          ({ group_id, group_name, accept_reminders, disabled }) =>
            ({
              label: group_name!,
              value: group_id,
              isDisabled: disabled || !accept_reminders,
            }) as DropdownOption,
        )
        ?.filter((option) => !option.isDisabled)
    : [defaultDropDownOption('reminders.noGroups', formatMessage)]
}

export const fallBackDate = (noteDateValue: string) =>
  noteDateValue === 'Invalid date' ? format(new Date(), 'dd MMM yyyy, h:mm a') : noteDateValue

export const reminderSchema = yup.object<Partial<NoteInput>>().shape({
  title: yup.string().required(ERROR_REQUIRED).min(1, ERROR_REQUIRED),
  primary_type: yup.string().required(ERROR_REQUIRED),
  deadline: yup
    .string()
    .typeError(ERROR_DATE_GENERIC)
    .required(ERROR_REQUIRED)
    .test('isInThePast', ERROR_PAST, (value) => isSameDay(new Date(value), new Date()) || isInTheFuture(value))
    .test('isValid', ERROR_DATE_GENERIC, (value) => Boolean(value) && isValidDate(value)),
  assignee_id: yup.string().required(ERROR_REQUIRED),
})

export const validate = unwrapErrors(reminderSchema)

const formatDeadline = (deadline: Maybe<string> | undefined, dueTime: Maybe<string> | undefined) => {
  if (!deadline) return null
  const deadlineAsDate = new Date(formatISO(parseISO(deadline)))
  if (!dueTime) return formatRFC3339(deadlineAsDate)
  const hours = Number(dueTime.substring(0, 2))
  const mins = Number(dueTime.substring(3, 5))
  deadlineAsDate.setHours(hours)
  deadlineAsDate.setMinutes(mins)
  return formatRFC3339(deadlineAsDate)
}

export const useSubmitReminder = ({ initialValues, isEdit }: { initialValues: NoteInput; isEdit: boolean }) => {
  const { id: case_id, details } = useCaseContext()
  const user = useUserContext()
  const representative_of = user?.organisation?.representative_of

  const caseOwnerId = details?.owner_user_id

  const { data: caseOwner } = useGetUserQuery({
    variables: {
      id: caseOwnerId as string,
      useClientApi: false,
    },
    skip: Boolean(user?.id === caseOwnerId) || !caseOwnerId,
  })

  const orgIdForCaseNotes = getOrgIdForCaseNotes(user, caseOwner?.user, details)

  const updateOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetNotesDocument,
        variables: {
          params: {
            organisation_id: orgIdForCaseNotes!,
            filter_case_id: case_id,
          },
        },
        skip: !orgIdForCaseNotes,
      },
    ],
  }
  const [addNote, { loading: loadingAdd }] = useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, {
    ...updateOptions,
  })

  const [updateNote, { loading: loadingUpdate }] = useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    {
      ...updateOptions,
    },
  )

  const [getUser] = useGetUserLazyQuery()

  const [getGroups] = useGetGroupsLazyQuery()

  const handleSubmit = async (data: NoteInput) => {
    const formattedDeadline = formatDeadline(data.deadline, data.dueTime)
    const readStatus = user?.id === data.assignee_id ? NoteStatus.Read : NoteStatus.Unread
    const orgIDs =
      orgIdForCaseNotes === representative_of ? [orgIdForCaseNotes] : [orgIdForCaseNotes, representative_of]

    if (user?.id !== data.assignee_id && data.assignee_id) {
      if (data.assignee_type === AssigneeType.Group) {
        const assignedGroupOrg = await getGroups({ variables: { ids: [data.assignee_id], fetchUsers: false } })
        const assignedGroupOrgId = assignedGroupOrg.data?.getGroups?.find(
          (group) => group.group_id === data.assignee_id,
        )?.organisation_id
        if (assignedGroupOrgId && !orgIDs.includes(assignedGroupOrgId)) {
          orgIDs.push(assignedGroupOrgId)
        }
      } else if (data.assignee_type === AssigneeType.User) {
        const assignedUserOrg = await getUser({ variables: { id: data.assignee_id, useClientApi: false } })
        const assignedUserOrgId = assignedUserOrg.data?.user?.organisation_id
        if (assignedUserOrgId && !orgIDs.includes(assignedUserOrgId)) {
          orgIDs.push(assignedUserOrgId)
        }
      }
    }

    const notesData = {
      ...omitTypename(initialValues),
      // If case id is specified then use that, if not use the case_id of the case you're currently on
      case_id: data.case_id || case_id,
      body: data.body,
      // unless primary_type is REVIEW, set to REMINDER
      primary_type: data.primary_type === NoteType.Review ? NoteType.Review : NoteType.Reminder,
      assignee_id: data.assignee_id ? data.assignee_id : '',
      assignee_type: data.assignee_type,
      deadline: formattedDeadline,
      title: data.title,
      status: readStatus,
      template_id: data.template_id || null,
    }
    if (!isEdit) {
      // add case_id, created_at and organisation_id  during creation
      await addNote({
        variables: {
          input: { organisation_ids: orgIDs, ...notesData },
        },
      })
    } else if (initialValues?.note_id) {
      await updateNote({ variables: { note_id: initialValues?.note_id, input: omit(notesData, 'dueTime') } })
    }
  }
  return { handleSubmit, loading: loadingAdd || loadingUpdate }
}

export const getModalDescriptionMsgId = (isHomeInsuranceCase: boolean, isProtectionCase: boolean) => {
  if (isHomeInsuranceCase) return 'wouldYouLikeToSetAReminderGI'
  if (isProtectionCase) return 'wouldYouLikeToSetAReminderProtection'
  return 'wouldYouLikeToSetAReminder'
}
