import theme from '../theme'
import { ColourId } from '../utils/types'

type ColourLookup = Partial<Record<ColourId, string>>

const colourLookup: ColourLookup = {
  [ColourId.Yellow]: theme.colours.new.yellow2,
  [ColourId.AcreBlue]: theme.colours.primary,
  [ColourId.PattensBlue]: theme.colours.cobalt,
  [ColourId.BaseMid]: theme.colours.baseMid,
  [ColourId.LightRed]: theme.colours.danger,
  [ColourId.White]: theme.colours.foreground,
  [ColourId.Black]: theme.colours.base,
  [ColourId.DarkYellow]: theme.colours.darkYellow,
}

export const getTextColourHex = (colourId: ColourId) => colourLookup[colourId] || undefined
