import { styled } from '@mui/material'

import { Variant } from '../../utils/constants'

export const ButtonOverflowWrapper = styled('div')<{ showLeftMargin?: boolean }>(
  ({ showLeftMargin }) => `
  display: inline-block;
  margin-left: ${showLeftMargin ? '20px' : 'unset'};
`,
)

export const StyledText = styled('div')<{ variant?: Variant; fontColor?: string; fontSize?: string }>(
  ({ fontColor, fontSize }) => `
  color: ${fontColor ? fontColor : undefined};
  display: block;
  font-size: ${fontSize ? fontSize : undefined};
`,
)
