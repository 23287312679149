import React from 'react'
import { styled } from '@mui/material'

import TableFooterInfoItem, { TableFooterInfoItemProps } from '../TableFooterInfoItem'

const TableFooterInfoBarStyles = styled('div')`
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 12px;

  & > * {
    margin-right: ${({ theme }) => theme.spacers.size24};
  }
`
export type TableFooterInfoBarProps = { data: TableFooterInfoItemProps[] }

const TableFooterInfoBar = ({ data }: TableFooterInfoBarProps) => {
  const items = data.map((item) => <TableFooterInfoItem key={item.title} title={item.title} value={item.value} />)

  return <TableFooterInfoBarStyles>{items}</TableFooterInfoBarStyles>
}

export default TableFooterInfoBar
