import {
  Maybe,
  Mortgage,
  MortgageReason,
  MortgageStatus,
  useGetCaseByVersionQuery,
  useGetCaseVerificationsQuery,
  useGetCaseVersionsQuery,
  useGetDocumentDetailsQuery,
} from '@acre/graphql'

export const useGetCaseVersionsListData = (caseId: Maybe<string>) => {
  const { data: caseVersionsData, loading: caseVersionsLoading } = useGetCaseVersionsQuery({
    variables: { id: caseId! },
    skip: !caseId,
  })
  return { caseVersionsData, caseVersionsLoading }
}

export const useGetCaseVersionData = (caseId: Maybe<string>, version: Maybe<string>) => {
  const { data: caseVersionData, loading: caseVersionLoading } = useGetCaseByVersionQuery({
    variables: { id: caseId!, version: parseInt(version!) },
    skip: !caseId || !version || version === 'undefined',
    fetchPolicy: 'no-cache',
  })

  return { caseVersionData, caseVersionLoading }
}

export const explanationsFields = [
  { fieldName: 'suitability_report_recommendation_introduction', alwaysShow: true },
  { fieldName: 'recommendation_reason_for_equity_disparity', alwaysShow: false },
  { fieldName: 'preference_details', alwaysShow: true },
  { fieldName: 'recommendation_reason_strategy_for_btl_void_period_coverage', alwaysShow: false },
  { fieldName: 'recommendation_reason_for_affordability', alwaysShow: true },
  { fieldName: 'recommendation_term_reason', alwaysShow: true },
  { fieldName: 'recommendation_reason_term_into_retirement', alwaysShow: false },
  { fieldName: 'recommendation_reason_for_recommendation_term_increase', alwaysShow: false },
  { fieldName: 'recommendation_reason_for_recommendation', alwaysShow: true },
  { fieldName: 'recommendation_reason_for_remortgage_cost_increase', alwaysShow: false },
  { fieldName: 'recommendation_reason_additional_borrowing', alwaysShow: false },
  { fieldName: 'reason_for_further_advance', alwaysShow: false },
  { fieldName: 'recommendation_reason_retirement_interest_only', alwaysShow: false },
  { fieldName: 'debt_consolidation_reason_for_consolidated_debts', alwaysShow: false },
  { fieldName: 'recommendation_reason_help_to_buy_loan_repayment_strategy', alwaysShow: false },
  { fieldName: 'reason_for_recommendation_product_type', alwaysShow: false },
  { fieldName: 'reason_for_recommendation_initial_term', alwaysShow: false },
]

export const isCaseProtectionOrGI = (mortgageReason: Maybe<MortgageReason>) => {
  if (
    mortgageReason &&
    [
      MortgageReason.ReasonProtection,
      MortgageReason.ReasonBusinessProtection,
      MortgageReason.ReasonGeneralInsurance,
    ].includes(mortgageReason)
  ) {
    return true
  } else {
    return false
  }
}

export const snakeCaseToCapitalised = (fieldName: string) => {
  return fieldName
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
    .replace(/^[a-z]/, (letter) => letter.toUpperCase())
}

export const caseHasSelectedMortgage = (caseMortgages: Maybe<Mortgage[]>) => {
  return caseMortgages?.some((mortgage) => mortgage.status === MortgageStatus.StatusSelected)
}

export const useGetPassedVerifications = ({ caseId }: { caseId: string }) => {
  const { data, loading } = useGetCaseVerificationsQuery({
    variables: { id: caseId, show_pass: true },
    fetchPolicy: 'cache-first',
    skip: !caseId,
  })
  return { data, loading }
}

export const useGetDocumentDetails = ({ documentId }: { documentId: Maybe<string> }) => {
  const { data, loading } = useGetDocumentDetailsQuery({
    variables: { docId: documentId!, params: { document_details: true } },
    fetchPolicy: 'cache-first',
    skip: !documentId || documentId === 'undefined' || documentId === 'null',
  })
  return { data, loading }
}
