import { format, formatDistanceToNowStrict, parse } from 'date-fns'

import { Maybe } from '@acre/graphql'

import { emptyVal } from '../constants'

// In datefns, if you do format(new Date(string)), it will not take into account daylight savings
// Therefore we use this function to get the correct format string
const getFormatString = (date: string) => {
  if (date.length <= 10) {
    if (/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(date)) {
      return 'dd-MM-yyyy'
    }

    if (/^[0-9]{4}-[0-9]{2}$/.test(date)) {
      return 'yyyy-MM'
    }

    if (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(date)) {
      return 'dd/MM/yyyy'
    }

    return 'yyyy-MM-dd'
  }

  return "yyyy-MM-dd'T'HH:mm:ss'Z'"
}

export const formatDate = (date: string) => {
  if (date.includes('*')) {
    return date.split('-').reverse().join('/')
  }

  return format(parse(date, getFormatString(date), new Date()), 'dd/MM/yyyy')
}

export const formatDateTime = (date: string) => {
  if (date.includes('*')) {
    return formatDate(date)
  }

  return format(parse(date, getFormatString(date), new Date()), 'dd/MM/yyyy h:mm aaa')
}

export const formatDateWithDash = (date: string) => {
  if (date.includes('*')) {
    return date.split('-').reverse().join('-')
  }

  return format(parse(date, getFormatString(date), new Date()), 'dd-MM-yyyy')
}

export const momentToApiDate = (date: Date): string => (date ? format(new Date(date), 'yyyy-MM-dd') : date)

export const documentUploadDate = (date_stored?: Maybe<string>) => {
  return date_stored ? format(new Date(date_stored), 'yyyy/MM/dd HH:mm') : emptyVal
}

export const docStoredTime = (date_stored?: Maybe<string>) => {
  return date_stored ? formatDistanceToNowStrict(new Date(date_stored)) : emptyVal
}

export const fullMonthAndYear = (date: string) => {
  return format(new Date(date), 'MMMM yyyy')
}
