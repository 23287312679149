import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'

export interface StepContentProps {
  title?: string
  body: string | ReactElement
}

const StepContent = ({ title, body }: StepContentProps) => (
  <>
    {title && (
      <Typography variant="h5" component="h1" mb={1}>
        {title}
      </Typography>
    )}
    <Typography variant="body1" mb={2}>
      {body}
    </Typography>
  </>
)

export default StepContent
