import { useCallback, useState } from 'react'
import { useUpdateCaseStatus } from '@broker-crm-utils'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Maybe, MortgageReason } from '@acre/graphql'
import { Button, BUTTON_PRIMARY, BUTTON_SECONDARY, Modal } from '@acre/design-system'

import { getTaskTextByStatus, validTransitionStatusType } from './CaseVerifications.helpers'

type Props = {
  isModalOpen: boolean
  status: validTransitionStatusType
  caseId: string
  preferenceMortgageReason: Maybe<MortgageReason>
  handleClose: () => void
  onSubmit?: () => any
}

const ConfirmCaseTransitionModal = ({
  status,
  caseId,
  preferenceMortgageReason,
  isModalOpen,
  handleClose,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateCaseStatus] = useUpdateCaseStatus(caseId)

  const { modalMessage } = getTaskTextByStatus(status, preferenceMortgageReason)

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    await updateCaseStatus({
      variables: {
        id: caseId,
        input: {
          update: status,
        },
      },
    })

    onSubmit && (await onSubmit())
    setIsLoading(false)
    handleClose()
  }, [caseId, handleClose, onSubmit, status, updateCaseStatus])

  return (
    <Modal
      id="ConfirmCaseTransitionModal"
      open={isModalOpen}
      onClose={handleClose}
      showHeading={false}
      footer={
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Button id="Cancel" buttonStyle={BUTTON_SECONDARY} onClick={handleClose}>
            <FormattedMessage id="generic.cancel" />
          </Button>
          <Box ml={2}>
            <Button
              data-testid={`ConfirmCaseTransitionTo${status}`}
              id={`ConfirmCaseTransitionTo${status}`}
              buttonStyle={BUTTON_PRIMARY}
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              <FormattedMessage id="generic.confirm" />
            </Button>
          </Box>
        </Box>
      }
    >
      <FormattedMessage id={modalMessage} />
    </Modal>
  )
}

export default ConfirmCaseTransitionModal
