import { isEmpty, omitBy } from 'lodash'

import configs from '@acre/config'

import {
  QueryGetRevenueReportArgs,
  QueryReportsCancellationReasonArgs,
  QueryReportsCaseWaterfallArgs,
  QueryReportsCaseWaterfallIntroducerArgs,
  QueryReportsCurrentPipelineArgs,
  QueryReportsPredictedRevenueArgs,
  ReportsCancellationReasonResponse,
  ReportsCaseWaterfallIntroducerResponse,
  ReportsCaseWaterfallResponse,
  ReportsCurrentPipelineResponse,
  ReportsDiscoveryResponse,
  ReportsFilterOptionsResponse,
  ReportsRevenueResponse,
} from '../generated/resolvers'
import {
  addInternalHash,
  QueryReportsCommissionsArgs,
  QueryReportsFilterOptionsArgs,
  ReportsCommissionsResponse,
} from '../main'
import request from '../requesters/default'

const { API1_URL } = configs

export const currentPipeline = async (params: QueryReportsCurrentPipelineArgs) => {
  const response = await request.get<ReportsCurrentPipelineResponse>('/mi/firm/current_pipeline', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const cancellationReason = async (params: QueryReportsCancellationReasonArgs) => {
  const response = await request.get<ReportsCancellationReasonResponse>('/mi/firm/cancellation_report', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const caseWaterfall = async (params: QueryReportsCaseWaterfallArgs) => {
  const response = await request.get<ReportsCaseWaterfallResponse>('/mi/firm/waterfall', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const caseWaterfallIntroducer = async (params: QueryReportsCaseWaterfallIntroducerArgs) => {
  const response = await request.get<ReportsCaseWaterfallIntroducerResponse>('/mi/firm/introducer_waterfall', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const revenue = async (params: QueryGetRevenueReportArgs) => {
  const response = await request.get<ReportsRevenueResponse>('/mi/firm/revenue', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const tier = async (params: QueryReportsCommissionsArgs) => {
  const response = await request.get<ReportsCommissionsResponse>('/mi/firm/tier', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })

  const responseWithIds = { summaries: addInternalHash(response?.data.summaries) || [] }

  return responseWithIds
}

export const predictedRevenue = async (params: QueryReportsPredictedRevenueArgs) => {
  const response = await request.get<ReportsCurrentPipelineResponse>('/mi/firm/predicted_revenue', {
    baseURL: `${API1_URL}/acre`,
    params: omitBy(params.body, isEmpty),
  })
  return response.data
}

export const reportsDiscovery = async () => {
  const response = await request.get<ReportsDiscoveryResponse>(`/mi/report/discovery`, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

// CSV Download
export const downloadReport = async (
  id: string,
  filename: string,
  endpoint: string,
  params?: Record<string, unknown>,
  fileType: 'xlsx' | 'csv' = 'csv',
) => {
  // Separate columns from other params
  const { columns, ...otherParams } = params || {}

  // Query parameters to be sent in URL
  const queryParams = { id, ...otherParams }

  // Prepare the JSON body with columns if available
  const bodyParams = columns ? { columns } : {}

  return await request
    .post(endpoint, bodyParams, {
      params: queryParams,
      baseURL: API1_URL,
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${filename}.${fileType}`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up by removing the link
      window.URL.revokeObjectURL(url) // Free up memory by revoking the object URL
    })
    .catch((error) => {
      console.error('Error downloading the report:', error)
      throw error
    })
}

export const reportsFilterOptions = async (params: QueryReportsFilterOptionsArgs) => {
  const response = await request.get<ReportsFilterOptionsResponse>('/mi/report/filters', {
    baseURL: `${API1_URL}/acre`,
    params: params.body,
  })
  return response.data
}
