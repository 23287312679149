import { useState } from 'react'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ButtonNewest } from '@acre/design-system'

const RecommendProductTooltip = () => {
  const theme = useTheme()
  const messagePrefix = 'mortgageProduct.recommendProductTooltip'
  const strong = (c: React.ReactNode) => <strong>{c}</strong>
  const FormattedMessageWithStrong = ({ id }: { id: string }) => <FormattedMessage id={id} values={{ strong }} />
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const tooltipContent = (
    <Box display="flex" flexDirection="column" gap={1}>
      <h3>
        <FormattedMessage id={`${messagePrefix}.title`} />
      </h3>
      <Typography fontSize={theme.typography.pxToRem(12)}>
        <FormattedMessage id={`${messagePrefix}.firstPara`} />
      </Typography>
      <Typography fontSize={theme.typography.pxToRem(12)}>
        <FormattedMessageWithStrong id={`${messagePrefix}.secondPara`} />
      </Typography>
      <h4>
        <FormattedMessage id={`${messagePrefix}.or`} />
      </h4>
      <Typography fontSize={theme.typography.pxToRem(12)}>
        <FormattedMessage id={`${messagePrefix}.thirdPara`} />
      </Typography>
    </Box>
  )

  const seeReasonsButton = (
    <ButtonNewest
      variant="link"
      onClickCapture={() => setTooltipOpen(true)}
      sx={{ color: theme.colours.danger, fontWeight: theme.typography.fontWeightBold }}
    >
      <FormattedMessage id={`${messagePrefix}.seeReasons`} />
    </ButtonNewest>
  )

  return (
    <>
      <Typography fontSize={theme.typography.pxToRem(14)} color={theme.colours.danger}>
        <FormattedMessage id={`${messagePrefix}.warning`} />
      </Typography>
      <Typography fontSize={theme.typography.pxToRem(14)}>
        {tooltipOpen ? (
          <Tooltip title={tooltipContent} placement="bottom-start">
            {seeReasonsButton}
          </Tooltip>
        ) : (
          seeReasonsButton
        )}
      </Typography>
    </>
  )
}

export default RecommendProductTooltip
