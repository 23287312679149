const indexBy = <T extends Record<string, unknown>, K extends keyof T>(key: K, list: readonly T[]) =>
  list.reduce(
    (acc, curr) => {
      acc[curr[key] as unknown as K] = curr

      return acc
    },
    {} as { [K in keyof T]: T },
  )

export default indexBy
