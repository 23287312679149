import { styled } from '@mui/material'

export const StyledCheckbox = styled('span')(
  ({ theme }) => `
  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }

  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  margin: 0;
  margin-right: ${theme.spacing(1)};
  border: 1px solid;

  border-color: ${theme.colours.baseLight};
  border-radius: ${theme.display.borderRadius};
  background: ${theme.colours.foreground};

  transition: all 0.1s ease-out;

  &.checked {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: ${theme.colours.primary};
    background-color: ${theme.colours.primary};
    & svg {
      color: ${theme.colours.baseExtraLight};
      stroke: ${theme.colours.baseExtraLight};
      stroke-width: 4px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: ${theme.colours.baseExtraLight};
    & svg {
      stroke: ${theme.colours.baseExtraLight};
      color: ${theme.colours.baseExtraLight};
      stroke: ${theme.colours.baseExtraLight};
    }
  }

  &.checked.disabled {
    background-color: ${theme.colours.primaryColorDisabled};
    border-color: ${theme.colours.primaryColorDisabled};
  }
`,
)

export const CheckboxLabel = styled('label')<{ disabled?: boolean }>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  color: ${theme.colours.base};
  font-size: 14px;
  line-height: 1.6;
  cursor: pointer;
  &:hover > ${StyledCheckbox} {
    cursor: pointer;
    border-color: ${theme.colours.primary};
  }

  &.disabled {
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
    &:hover {
      /* We want to style the checkbox when the label is hovered */
      & > ${StyledCheckbox} {
        cursor: not-allowed;
        border-color: ${theme.colours.baseLight};
      }
    }
  }

  &.checked.disabled {
    &:hover {
      /* We want to style the checkbox when the label is hovered */
      & > ${StyledCheckbox} {
        border-color: ${theme.colours.primaryColorDisabled};
      }
    }
  }
`,
)
