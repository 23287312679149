import React from 'react'
import { styled, Typography, typographyClasses, unstable_composeClasses } from '@mui/material'
import classNames from 'classnames'

import { FieldProps, FieldWrapperProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper from '../FieldWrapper'
import displayFieldClasses, { getDisplayFieldUtilityClass } from './displayFieldClasses'

export interface DisplayFieldProps extends Omit<FieldProps, 'label'>, FieldWrapperProps {
  label: React.ReactNode
  value?: string
  valueClassName?: string
  controls?: React.ReactNode
  loading?: boolean
}

const DisplayFieldRoot = styled(FieldWrapper, {
  name: 'DisplayField',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, styles[props.variant], styles[props.size]],
  shouldForwardProp: (prop) => prop !== 'controls' && prop !== 'loading',
})<DisplayFieldProps>(({ controls, disabled, size, theme, layout }) => ({
  [`& .${displayFieldClasses.controls}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  ...(disabled && {
    [`& .${typographyClasses.root}`]: {
      color: theme.colours.baseMid,
    },
  }),

  [`& .${displayFieldClasses.value}`]: {
    flexGrow: 1,
    height: theme.spacing(4),
    lineHeight: theme.spacing(4),

    ...(layout === 'column' && {
      ...(size === 'large' && {
        height: '3rem',
        fontSize: theme.typography.pxToRem(16),
        lineHeight: '3rem',
      }),
    }),
  },

  ...(layout === 'row' &&
    controls === undefined && {
      height: theme.spacing(4),
    }),
}))

const useUtilityClasses = (ownerState: Partial<DisplayFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    value: ['value'],
    controls: ['controls'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getDisplayFieldUtilityClass, ownerState.classes)
}

const DisplayField = (props: DisplayFieldProps) => {
  const {
    classes,
    className,
    valueClassName,
    value = '',
    controls,
    name,
    layout = 'row',
    size = 'medium',
    loading,
    helperText,
    error,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, size, layout })

  const { fieldAriaProps } = useAriaProps(props)

  const valueProps = {
    ...fieldAriaProps,
    id: name,
    component: 'span',
    'aria-disabled': true,
    role: 'textbox',
    'aria-busy': loading,
    className: classNames(slotClasses.value, valueClassName),
  }

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  return (
    <DisplayFieldRoot
      {...rootProps}
      name={name}
      controls={controls}
      layout={layout}
      size={size}
      helperText={helperText}
      error={error}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
      className={classNames(slotClasses.root, slotClasses.field, className)}
    >
      {controls ? (
        <div className={classNames(slotClasses.controls)}>
          <Typography {...valueProps}>{loading ? 'Loading...' : value}</Typography>
          {controls}
        </div>
      ) : (
        <Typography {...valueProps}>{loading ? 'Loading...' : value}</Typography>
      )}
    </DisplayFieldRoot>
  )
}

export default DisplayField
