import React, { useMemo, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import { RouteBlocker, RouteBlockerProps, useUserContext } from '@acre/utils'

import { shouldOnboardOrg, shouldOnboardUser } from './routing.helpers'

export interface RequireOnboardingProps extends Omit<RouteBlockerProps, 'children' | 'shouldRender'> {
  userPath: string
  organisationPath: string
  fallback?: React.ReactNode
}

const RequireOnboarding = ({ userPath, organisationPath, ...props }: RequireOnboardingProps) => {
  const user = useUserContext() || undefined

  const redirect = useRef<string>('/')

  const shouldRender = useMemo(() => {
    if (shouldOnboardOrg(user)) {
      redirect.current = organisationPath
      return true
    } else if (shouldOnboardUser(user)) {
      redirect.current = userPath
      return true
    } else {
      return false
    }
  }, [organisationPath, user, userPath])

  return (
    <RouteBlocker {...props} shouldRender={shouldRender}>
      <Navigate replace to={redirect.current} />
    </RouteBlocker>
  )
}

export default React.memo(RequireOnboarding)
