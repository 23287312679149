import { Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { ItemCardData } from '../../utils/types'
import GreyText from '../GreyText'
import { Align, CardListItem, CardListItemKey, CardListItemValue } from './FieldListItem.styles'

export interface FieldListItem extends ItemCardData {
  defaultValue?: string
  smallFont?: boolean
  boldValues?: boolean
  boldLabel?: boolean
  leftWidth?: string
  align?: Align
  border?: boolean
  variant?: Variant
  fontSize?: number
  extraPadding?: boolean
  flexDirection?: string
  paddingLeft?: string
  greyKeyText?: boolean
}

const FieldListItem = ({
  id,
  value,
  label,
  defaultValue,
  boldValues = false,
  boldLabel = false,
  leftWidth,
  smallFont = false,
  align,
  border = true,
  variant = 'default',
  fontSize,
  extraPadding,
  flexDirection = 'row',
  paddingLeft,
  greyKeyText,
}: FieldListItem) => {
  const dataTestId = id ? testHandle(id) : testHandle('ItemCardListItem')
  const fieldValue = value || <GreyText>{defaultValue}</GreyText>
  return (
    <CardListItem
      data-testid={dataTestId}
      smallFont={smallFont}
      border={border}
      key={id}
      id={id}
      variant={variant}
      fontSize={fontSize}
      extraPadding={extraPadding}
      flexDirection={flexDirection}
    >
      {label && (
        <CardListItemKey
          data-testid={testHandle('CardListItemKey')}
          width={leftWidth}
          align={align}
          boldLabel={boldLabel}
          variant={variant}
        >
          {greyKeyText ? <GreyText>{label}</GreyText> : label}
        </CardListItemKey>
      )}
      <CardListItemValue
        data-testid={testHandle('CardListItemValue')}
        data-pii
        align={align}
        boldValues={boldValues}
        variant={variant}
        paddingLeft={paddingLeft}
      >
        {fieldValue}
      </CardListItemValue>
    </CardListItem>
  )
}

export default FieldListItem
