import { ErrorResponse } from '@apollo/client/link/error'
import { captureException } from '@sentry/react'
import { GraphQLError, GraphQLFormattedError } from 'graphql'
import { get, isNil } from 'lodash'

import { ErrorProvider } from '../errorHandler'
import { isForbiddenError, statusCodes } from './links.helpers'

const graphqlErrorLink = ({ response, graphQLErrors, operation }: ErrorResponse) => {
  if (graphQLErrors?.length && !graphQLErrors.some((error) => isForbiddenError(error, 'graphql'))) {
    //// SHOW ERRORS TO DEVS ////
    // TODO: handle whole array
    const graphQLError = graphQLErrors[0]
    const error = graphQLError
    const statusCode = graphQLError.extensions?.status
    //@ts-ignore
    if (!statusCodes.includes(statusCode as number) && isNil(window.Cypress)) {
      captureException(error, {
        contexts: {
          graphql: {
            operation: operation.operationName,
            variables: JSON.stringify(operation.variables, null, 2),
          },
        },
      })
    }

    console.error(operation)
    console.error(graphQLErrors)

    //// SHOW ERRORS TO USERS ////

    ErrorProvider.showErrors(graphQLErrors as Readonly<GraphQLError>[])

    //// MARK ERRORS AS HANDLED ////

    if (response?.errors && response?.data) {
      // If we have data, we can remove the errors that are related to the data.
      // This allows us to still show the user the data that we have, even if some of the data is missing.

      response.errors = response.errors.reduce((acc, curr) => {
        if (!get(response.data, curr.path!.slice(0, -1))) {
          acc.push(curr)
        }
        return acc
      }, [] as GraphQLFormattedError[])
    }
  }
}

export default graphqlErrorLink
