import React, { useLayoutEffect, useRef, useState } from 'react'
import { Typography, TypographyProps } from '@mui/material'

import Button from '../ButtonNewest'
import { ButtonWrapper, TextBox } from './ShowHideTextBox.styles'

export interface ShowHideTextBoxProps {
  children?: React.ReactNode
  textBoxMaxHeight: number
  showMoreText: string
  showLessText: string
  TypographyProps?: TypographyProps<'p'>
}

const ShowHideTextBox = ({
  children,
  textBoxMaxHeight,
  showMoreText,
  showLessText,
  TypographyProps,
}: ShowHideTextBoxProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [currentHeight, setCurrentHeight] = useState<null | string>(null)
  const [showAll, setShowAll] = useState(false)
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState<boolean>(false)

  // Set current height based on whether show all text state is true or false
  useLayoutEffect(() => {
    if (!showAll && ref.current && ref!.current!.clientHeight > textBoxMaxHeight) {
      setCurrentHeight(`${textBoxMaxHeight}px`)
    } else {
      setCurrentHeight(null)
    }
  }, [showAll, textBoxMaxHeight])

  // Set display show more/less button depending on whether the height of the text
  // exceeds the max height
  useLayoutEffect(() => {
    if (ref.current && ref!.current!.clientHeight > textBoxMaxHeight) {
      setDisplayShowMoreButton(true)
    } else {
      setDisplayShowMoreButton(false)
    }
  }, [textBoxMaxHeight])

  return (
    <>
      <TextBox ref={ref} maxHeight={currentHeight}>
        <Typography component="span" {...TypographyProps}>
          {children || null}
        </Typography>
      </TextBox>
      {displayShowMoreButton && (
        <ButtonWrapper>
          <Button variant="link" onClick={() => setShowAll(!showAll)}>
            {showAll ? showLessText : showMoreText}
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}

export default ShowHideTextBox
