import { styled } from '@mui/material'

import { Colour, NewColour, PairedColour, Size } from '../../utils/types'

export const StyledIcon = styled('div')<{
  badge?: boolean
  colour?: PairedColour | Colour
  size?: Size
  hover?: boolean
  hoverColour?: Colour
  filled?: boolean
  disabled?: boolean
}>(
  ({ theme, badge, size, colour, filled, hover, hoverColour, disabled }) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: ${
    (size === 'small' && theme.spacing(2)) ||
    (badge && size === 'default' && theme.spacing(6)) ||
    (badge && size === 'medium' && theme.spacing(4)) ||
    theme.spacing(3)
  };

  height: ${
    (size === 'small' && theme.spacing(2)) ||
    (badge && size === 'medium' && theme.spacing(4)) ||
    (badge && size === 'default' && theme.spacing(6)) ||
    theme.spacing(3)
  };

  color: ${colour ? theme.colours.new[`${colour}${disabled ? '3' : '2'}` as NewColour] : 'none'};
  border-radius: ${theme.display.borderRadius};
  background-color: ${badge && colour ? theme.colours.new[`${colour}1` as NewColour] : 'none'};
  color: ${colour ? theme.colours.new[`${colour}2` as NewColour] : 'none'};

  & > svg {
    fill: ${!!filled && !!colour ? theme.colours.new[`${colour}2` as NewColour] : 'none'};
    width: ${
      (size === 'small' && badge && theme.spacing(1.5)) ||
      (size === 'small' && theme.spacing(2)) ||
      (size === 'medium' && badge && theme.spacing(2)) ||
      theme.spacing(3)
    };
    stroke-width: ${size === 'default' ? 2 : 1.5};
    & > * {
      color: inherit !important;
      stroke: inherit !important;
      fill: inherit !important;
      vector-effect: non-scaling-stroke;
    }
  }

  &:hover {
    color: ${hover && hoverColour ? theme.colours.new[`${hoverColour}2` as NewColour] : 'none'};
  }
`,
)
