import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface CheckboxFieldClasses {
  root: string
  checkbox: string
  label: string
  icon: string
  iconChecked: string
}

export type CheckboxFieldClassKey = keyof CheckboxFieldClasses

export function getCheckboxFieldUtilityClass(slot: string) {
  return generateUtilityClass('CheckboxField', slot)
}

const checkboxFieldClasses: CheckboxFieldClasses = generateUtilityClasses('CheckboxField', [
  'root',
  'checkbox',
  'label',
  'icon',
  'iconChecked',
])

export default checkboxFieldClasses
