import { FieldReadFunction } from '@apollo/client'
import { isNil } from 'lodash'

import { GetPermissionsListQuery, PermissionAllowList } from '@acre/graphql'

/**
 *  to display permissions
 *  we need to check BE response from /permissions aka full list of permissions available
 *  then check the response from permissions assignments for that user
 *  if permission exist in user permission list and does not have disabled: true then should be defaulted as granted
 */

/**
 *  to edit permissions
 *  if permission has already been given(ie disabled exist regardless it's value) the then FE makes PATCH to enable/disable the permission
 *  if the permission has never been assigned to the user, then FE to do a POST to create a new permission
 */

const read: FieldReadFunction<PermissionAllowList[]> = (_, { readField, args }) => {
  const permission = readField<GetPermissionsListQuery['permission']>({
    fieldName: 'permission',
    args: { show_allowlist: args?.show_allowlist },
  })

  const id = args?.userId ? { userId: args.userId } : args?.groupId ? { groupId: args.groupId } : {}

  const permissionAssignments = readField<GetPermissionsListQuery['permissionAssignments']>({
    fieldName: 'permissionAssignments',
    args: id,
  })

  const permissionsAllowList = permission?.permissions
    .map((perm) => {
      const assignedPermission = permissionAssignments?.permission_assignments.findLast((permissionAssignmentRef) => {
        const permissionAssignmentOrgId = readField<string>('organisation_id', permissionAssignmentRef)
        const permissionAssignmentUserId = readField<string>('user_id', permissionAssignmentRef)
        const permissionAssignmentUserScopeId = readField<string>('user_scope_id', permissionAssignmentRef)
        const permissionAssignmentId = readField<string>('permission_id', permissionAssignmentRef)
        const permScopes = readField<string>('scopes', perm)

        if (
          args?.userScope &&
          permScopes?.includes('user_scope_id') &&
          permissionAssignmentUserId === args?.userId &&
          permissionAssignmentUserScopeId === args?.userId
        ) {
          return permissionAssignmentId === perm.permission_id
        } else if (
          !args?.userScope &&
          (!permScopes?.includes('organisation_id') ||
            (permScopes?.includes('organisation_id') && permissionAssignmentOrgId === args?.orgId))
        ) {
          return permissionAssignmentId === perm.permission_id
        } else {
          return false
        }
      })

      if (assignedPermission) {
        //permission assignment id will be used for patching the right permission
        const assignedId = readField<boolean>('permission_assignment_id', assignedPermission)
        const mergedPermission = { ...perm, permission_assignment_id: assignedId }

        // import to keep disabled value boolean for editing logic
        const isDisabled = readField<boolean>('disabled', assignedPermission)
        if (isNil(isDisabled)) {
          return { ...mergedPermission, disabled: false }
        }
        return { ...mergedPermission, disabled: isDisabled }
      }
      return { ...perm, disabled: null, permission_assignment_id: null }
    })
    .sort((a, b) => a.permission_name?.localeCompare(b.permission_name))

  return permissionsAllowList as PermissionAllowList[]
}
export default {
  read,
}
