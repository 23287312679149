import objectDifference from './objectDifference'

// FRON-1068 When updating certain fields, the broker needs to provide a
// 'reason' as to why they've made a change. We haven't designed for this
// circumstance as of yet, so for now we will just provide a placeholder

export const FIELDS_REQUIRE_REASON = [
  'preference_interest_only_repayment_strategy',
  'preference_upper_limit',
  'preference_fixed_rate',
  'preference_early_year_discount',
  'preference_no_early_repayment_charge',
  'preference_no_tie_in',
  'preference_fee_to_loan',
  'preference_vary_repayment_amount',
  'preference_payment_holiday',
  'preference_link_finances',
  'preference_details',
  'preference_target_property',
  'preference_target_deposit',
  'preference_target_property_value',
  'preference_term',
  'preference_repayment_percentage',
  'preference_cashback',
  'preference_mortgage_class',
  'preference_free_conveyancing',
  'preference_free_valuations',
  'preference_initial_period_months',
  'preference_completion_speed_important',
  'preference_overpayment',
]

export const isReasonNeeded = <T extends { [key: string]: any }>(initialState: T, state: T) => {
  // Changed fields
  const diff = objectDifference(state, initialState)
  const isFieldChanged = Object.keys(diff).some((field) => FIELDS_REQUIRE_REASON.includes(field))

  return isFieldChanged
}
