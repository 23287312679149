import { generateUniqueId } from '@acre/utils'
import { Mortgage, MortgageStatus, PredictedRevenueEntry } from '@acre/graphql'

export const getPredictedRevenueFromMortgage = (mortgages?: Partial<Mortgage>[]): PredictedRevenueEntry | null => {
  const selectedMortgages = getSelectedMortgages(mortgages)
  const { procFee, lenderNames } = sumUpMortgagesProcFees(selectedMortgages) || {}
  if (procFee) {
    return {
      id: generateUniqueId(),
      fee_type: 'mortgageProcFee',
      value: procFee,
      payer: lenderNames?.join(', '),
      frequency: null,
      commission: null,
      commission_period: null,
    }
  }
  return null
}

export const getSelectedMortgages = (mortgages?: Partial<Mortgage>[]) =>
  mortgages?.filter(({ status }) => status === MortgageStatus.StatusSelected)

export const sumUpMortgagesProcFees = (mortgages?: Partial<Mortgage>[]) => {
  return mortgages?.reduce(
    (acc, mortgage) => {
      const netProcfee = Number(mortgage?.calculated_values?.calculated_net_proc_fee)
      if (netProcfee && !isNaN(netProcfee)) {
        acc.procFee += netProcfee

        if (mortgage.mortgage_product?.lender_name) {
          acc.lenderNames.push(mortgage.mortgage_product?.lender_name)
        }
      }
      return acc
    },
    {
      procFee: 0,
      lenderNames: [],
    } as {
      procFee: number
      lenderNames: string[]
    },
  )
}
