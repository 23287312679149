import { format, isValid, parseISO } from 'date-fns'

import { LedgerRecurringPaymentsStatus, LedgerStatus, LedgerType } from '@acre/graphql'
import { ColourId } from '@acre/design-system'

export const formatDate = (date: string | null | undefined) => {
  return date && isValid(parseISO(date)) ? format(parseISO(date), 'dd/MM/yyyy') : ''
}

export const getTagColor = (status: LedgerStatus | LedgerRecurringPaymentsStatus | null | undefined) => {
  switch (status) {
    case LedgerStatus.Settled:
    case LedgerRecurringPaymentsStatus.InForce:
    case LedgerRecurringPaymentsStatus.Live:
      return ColourId.Green
    case LedgerStatus.Due:
    case LedgerStatus.PartiallyPaid:
    case LedgerRecurringPaymentsStatus.Overdue:
      return ColourId.Yellow
    case LedgerStatus.PartiallyWrittenOff:
    case LedgerStatus.WrittenOff:
    case LedgerRecurringPaymentsStatus.Close:
    case LedgerRecurringPaymentsStatus.Cancelled:
      return ColourId.Grey
    default:
      return ColourId.Grey
  }
}

const ledgerPaymentTypesPrefix = 'accounting.ledgerPaymentTypes'

export const LEDGER_PAYMENT_TYPE_MAP: { [key in LedgerType]: string } = Object.entries(LedgerType).reduce(
  (acc, [key, value]) => {
    acc[value as LedgerType] = `${ledgerPaymentTypesPrefix}.${key}`
    return acc
  },
  {} as { [key in LedgerType]: string },
)
