import { useMemo } from 'react'
import { userType } from '@broker-crm-utils'
import { useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useFormatMessage, useUserContext } from '@acre/utils'
import { getMetadata, getMetadataBoolean } from '@acre/utils'
import { GeneralInsuranceProvider, useIsGeneralInsuranceAuthValidQuery, UserMetadata } from '@acre/graphql'
import { FeatherIcon, GlobalWarning, IconName, Size } from '@acre/design-system'

const advisorRoles = [userType.advisor, userType.principal]

export const UinsureCreds = () => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()
  const user = useUserContext()
  const navigate = useNavigate()

  const typedMetaData: UserMetadata[] = user?.metadata || []
  const username = getMetadata(typedMetaData, 'uinsure_username')
  const password = getMetadata(typedMetaData, 'uinsure_password')
  const override = getMetadataBoolean(typedMetaData, 'gi_auto_refer_disable_for_user')
  // We may want to skip/hide banners if not all the relevant fields have been filled in
  const skip = !username || !password
  const { data, loading } = useIsGeneralInsuranceAuthValidQuery({
    variables: {
      input: {
        encrypted_username: username!,
        encrypted_password: password!,
        provider: GeneralInsuranceProvider.Uinsure,
      },
    },
    skip,
  })

  // we useMemo here as we want only one copy of this
  const memoisedComponent = useMemo(
    () => (
      <GlobalWarning
        colour={theme.colours.danger}
        background={theme.colours.new.red1}
        messageText={formatMessage('globalWarnings.giAutoRefer')}
        buttonText={formatMessage('globalWarnings.fixThis')}
        buttonAction={() => {
          navigate(`/user/${user?.id}/settings/credentials`)
        }}
        icon={<FeatherIcon name={IconName.AlertTriangle} size={Size.Medium} />}
      />
    ),
    [formatMessage, navigate, theme, user?.id],
  )

  const canAdvise = advisorRoles.includes(user?.primary_role_id || '')
  const autoReferItem = user?.organisation?.metadata?.find((item) => item.name === 'gi_auto_refer') || false
  const hasAutoRefer = autoReferItem && autoReferItem.bool_value && !override
  const reviewModeEnabled = location.pathname.includes('review-mode')

  // if the user org has auto refer and the creds are not valid and the user can advise, we show the warning
  if (!user || !hasAutoRefer || loading || data?.isGeneralInsuranceAuthValid || !canAdvise || reviewModeEnabled) return

  return memoisedComponent
}
