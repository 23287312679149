import React, { ChangeEvent, ComponentProps, FocusEvent, ReactNode, useMemo } from 'react'
import { styled } from '@mui/material'
import { Box } from '@mui/material'
import classNames from 'classnames'

import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import LoadingSpinner from '../LoadingSpinner'
import { Circle, Input, OptionLabel, RadioLabel } from './Radio.styles'

export type RadioProps = {
  name: string
  label: string | ReactNode
  value: string
  id?: string
  checked?: boolean
  disabled?: boolean
  readOnly?: boolean
  loading?: boolean
  error?: boolean
  variant?: Variant
  className?: string
  borderradius?: string
  hovercolour?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

export interface RadioCircleProps extends ComponentProps<typeof Circle> {
  disabled?: boolean
  checked?: boolean
}

const RadioCircle = styled(Circle, {
  name: 'Radio',
  slot: 'Circle',
  overridesResolver: (props, styles) => [styles.circle, props.checked && styles.circleChecked],
  shouldForwardProp: () => true,
})<RadioCircleProps>(({ theme, checked, disabled }) => ({
  width: '16px',
  height: '16px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),

  display: 'block',
  borderRadius: ' 100%',

  transition: 'background-color 0.1s ease-in-out',
  borderColor: theme.colours.baseLight,
  borderStyle: 'solid',
  borderWidth: '2px',
  boxShadow: 'none',

  ...(disabled && {
    borderColor: theme.colours.baseLight,
    backgroundColor: theme.colours.baseExtraLight,
    boxShadow: `inset 0px 0px 0px 2px ${theme.colours.baseExtraLight}`,
  }),

  ...(checked && {
    borderColor: theme.colours.primary,
    backgroundColor: theme.colours.new.grey6,
    boxShadow: `inset 0px 0px 0px 2px ${theme.colours.foreground}`,
  }),

  ...(disabled &&
    checked && {
      color: theme.colours.baseLight,
      borderColor: theme.colours.baseLight,
      backgroundColor: theme.colours.baseLight,
    }),
}))

const RadioBase = ({
  id,
  name,
  label,
  value,
  checked,
  disabled: disabledProp,
  readOnly = false,
  loading,
  error,
  className,
  onChange,
  onFocus,
  onBlur,
  variant = 'default',
  borderradius,
  hovercolour,
}: RadioProps) => {
  const disabled = useFieldDisabledState(disabledProp)

  const _className = useMemo(() => classNames({ disabled, checked, error }), [disabled, checked, error])

  const dataTestId = useMemo(() => (id ? testHandle(id) : null), [id])
  return (
    <OptionLabel
      className={`${_className} ${className}`}
      variant={variant}
      borderradius={borderradius}
      hovercolour={hovercolour}
    >
      <Input
        id={id}
        data-testid={dataTestId}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        variant={variant}
        readOnly={readOnly}
      />

      {loading ? (
        <Box display="flex" my="6px" alignItems="center" width="100%">
          <LoadingSpinner inverted />
        </Box>
      ) : (
        <>
          <RadioCircle variant={variant} checked={checked} disabled={disabled} />
          <RadioLabel>{label}</RadioLabel>
        </>
      )}
    </OptionLabel>
  )
}

const Radio = styled(RadioBase, {
  name: 'Radio',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, props.checked && styles.checked],
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'hovercolour',
})<RadioProps>({})

export default Radio
