import { useCaseContext } from '@broker-crm-contexts'
import { useLocation } from 'react-router-dom'

import { useFormatMessage, useUserContext } from '@acre/utils'
import { useGetUserQuery } from '@acre/graphql'

import {
  getOrgIdForCaseNotes,
  getOrgIdsForAddingNote,
} from '../../../pages/Cases/CaseDetails/CaseNotes/CaseNote.helpers'
import CollapsableSection, { SidebarSection } from '../CollapsableSection'
import AddNewPageNotes from './AddNewPageNotes/AddNewPageNotes'
import ExistingPageNotes from './ExistingPageNotes/ExistingPageNotes'
import { getFactFindSection, isProtectionSourcingPage, isSourcingPage, messagePrefix } from './PageNotes.helpers'
import { SectionWrapper } from '../ReviewModeDrawer.styles'

const PageNotes = () => {
  const formatMessage = useFormatMessage()
  const { pathname } = useLocation()

  // Get org Id for case notes
  const user = useUserContext()
  const { details: caseDetails } = useCaseContext()
  const caseOwnerId = caseDetails.owner_user_id

  const { data: caseOwner } = useGetUserQuery({
    variables: {
      id: caseOwnerId!,
      useClientApi: false,
    },
    skip: Boolean(user?.id === caseOwnerId) || !caseOwnerId,
  })

  const orgIdForCaseNotes = getOrgIdForCaseNotes(user, caseOwner?.user, caseDetails)
  const orgIdsForAddingNote = getOrgIdsForAddingNote(user, caseDetails, orgIdForCaseNotes)

  const shouldRenderPageNotes =
    pathname.includes('/fact-find/') ||
    pathname.includes('/properties/edit/') ||
    isProtectionSourcingPage(pathname) ||
    isSourcingPage(pathname)

  const factFindSection = getFactFindSection(pathname)

  if (!shouldRenderPageNotes) return

  return (
    <SectionWrapper>
      <CollapsableSection sectionId={SidebarSection.PageNotes} header={formatMessage(`${messagePrefix}.pageNotes`)}>
        <>
          <AddNewPageNotes
            orgIds={orgIdsForAddingNote}
            orgIdForCaseNotes={orgIdForCaseNotes}
            factFindSection={factFindSection}
          />
          <ExistingPageNotes orgIdForCaseNotes={orgIdForCaseNotes} factFindSection={factFindSection} />
        </>
      </CollapsableSection>
    </SectionWrapper>
  )
}

export default PageNotes
