import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Field, useForm, useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'

import { getFormMetaErrors, unwrapErrorFieldSchema, useFormatMessage } from '@acre/utils'
import {
  Checkbox,
  Condition,
  CurrencyInput,
  DateInput,
  DateRangeSelector,
  Dropdown,
  DropdownOption,
  FnCondition,
  MonthsInput,
  MultiSearchSelect,
  PercentageInput,
  PhoneInput,
  Radio,
  RadioGroup,
  SearchSelector,
  TextAreaNew,
  TextInput,
  withDisabled,
  YearMonthInput,
} from '@acre/design-system'

import { YesNoRadioGroup } from '../../common/YesNoRadioGroup'
import { useMissingFields } from '../../pages/Cases/CaseDetails/useMissingFields'
import {
  CHECKBOX_COMPONENT,
  concatenateSplitString,
  CURRENCY_INPUT_COMPONENT,
  DATE_INPUT_COMPONENT,
  DATE_RANGE_SELECTOR,
  DROPDOWN_SELECTOR_COMPONENT,
  fieldParserFn,
  InputBuilderProps,
  MULTI_SEARCH_SELECT,
  PERCENTAGE_INPUT_COMPONENT,
  PHONE_COMPONENT,
  RADIO_SELECTOR_COMPONENT,
  SEARCH_SELECTOR,
  TERM_COMPONENT,
  TEXT_AREA_COMPONENT,
  TEXT_INPUT_COMPONENT,
  YEAR_MONTH_INPUT_COMPONENT,
  YES_NO_RADIO_SELECTOR_COMPONENT,
} from './InputBuilder.helpers'

const InputBuilder = withDisabled((props: InputBuilderProps) => {
  const intl = useIntl()
  const formatMessage = useFormatMessage()
  const { values } = useFormState()
  const form = useForm()
  let runMissingFields: boolean

  const {
    fieldName,
    id,
    label = '',
    variant = 'default',
    defaultValue,
    initialValue,
    fieldPrefix = '',
    disabled = false,
    showErrorsWithoutTouched = false,
    appSection,
    formSection = '',
    itemId = '',
    index = 0,
    errorMessageInsert,
    validation,
    fieldValidation,
    rows,
    missingFieldName,
    extra,
  } = props

  const fieldId = index ? `${id}${index}` : id

  let isMissing: { isMissing: (field: string, id?: string) => boolean | undefined }

  if (appSection !== undefined && formSection !== '' && itemId !== '') {
    runMissingFields = true
    isMissing = useMissingFields(appSection!, formSection, itemId, index)
  } else {
    runMissingFields = false
  }

  const formattedFieldName = fieldPrefix ? `${fieldPrefix}.${fieldName}` : fieldName

  const parseTextFn = (value: string) => {
    if (value === '') {
      return null
    }
    if (value && props.componentType === TEXT_INPUT_COMPONENT && props.inputType === 'number') {
      return Number(value)
    }
    return value
  }

  const validationCondition = validation && validation.condition
  const validationRelatedField = validation && validation.relatedField
  let elementToRender: React.ReactNode
  switch (props.componentType) {
    case TEXT_INPUT_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          parse={parseTextFn}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <TextInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  type={props.inputType}
                  label={label}
                  variant={variant}
                  disabled={disabled}
                  hasValue={props.hasValue}
                  iconName={props.iconName}
                  isMissing={
                    runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                  }
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case TEXT_AREA_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          parse={fieldParserFn}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <TextAreaNew
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  rows={rows}
                  label={label}
                  variant={variant}
                  isMissing={
                    runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                  }
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case CURRENCY_INPUT_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          parse={fieldParserFn}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <CurrencyInput
                {...input}
                {...getFormMetaErrors({
                  intl,
                  meta,
                  messageInsert: errorMessageInsert || { label },
                  includeTouched: !showErrorsWithoutTouched,
                })}
                id={fieldId}
                label={label}
                variant={variant}
                disabled={disabled}
                extra={typeof extra === 'function' ? extra() : ''}
                isMissing={
                  !props.hasFailedVerification
                    ? runMissingFields && input.value === ''
                      ? isMissing.isMissing(missingFieldName || fieldName)
                      : false
                    : props.hasFailedVerification && input.value === ''
                      ? props.hasFailedVerification
                      : false
                }
                tooltip={props.tooltip}
                secondaryText={props.secondaryText}
              />
            )
          }}
        </Field>
      )
      break
    case PERCENTAGE_INPUT_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          parse={fieldParserFn}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            const isFieldMissing = (value: any) => {
              if (runMissingFields && value === '') {
                return isMissing.isMissing(missingFieldName || fieldName)
              } else {
                return false
              }
            }

            return (
              <>
                <PercentageInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  label={label}
                  variant={variant}
                  isMissing={isFieldMissing(input.value)}
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case DROPDOWN_SELECTOR_COMPONENT: {
      let { options, optionFilterField, secondaryText } = props

      if (options) {
        if (optionFilterField && optionFilterField != '') {
          const formVal = form.getState().values[fieldPrefix.slice(0, -1)]?.[optionFilterField]
          if (formVal) {
            options = options.filter((value) =>
              value.valid?.reduce((acc, curr) => (curr == formVal ? true : acc), false),
            )
          }
        }
        elementToRender = (
          <Field
            name={formattedFieldName}
            parse={fieldParserFn}
            validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
          >
            {({ input, meta }) => {
              return (
                <>
                  <Dropdown
                    {...input}
                    {...getFormMetaErrors({
                      intl,
                      meta,
                      messageInsert: errorMessageInsert || { label },
                      includeTouched: !showErrorsWithoutTouched,
                    })}
                    id={fieldId}
                    label={label}
                    options={options ? options : []}
                    defaultValue={defaultValue}
                    variant={variant}
                    disabled={disabled}
                    isMissing={
                      runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                    }
                    secondaryText={secondaryText}
                  />
                  {typeof extra === 'function' ? extra() : ''}
                </>
              )
            }}
          </Field>
        )
      }
      break
    }
    case TERM_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          parse={fieldParserFn}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <MonthsInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  label={label}
                  onlyYears={props.onlyYears}
                  variant={variant}
                  tags={[formatMessage(`generic.years`), formatMessage(`generic.months`)]}
                  isMissing={
                    runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                  }
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case YES_NO_RADIO_SELECTOR_COMPONENT: {
      const { opposite, tooltip } = props

      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <YesNoRadioGroup
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                    isRadioGroup: true,
                  })}
                  id={fieldId}
                  label={label}
                  variant={variant}
                  disabled={disabled}
                  opposite={opposite}
                  isMissing={
                    runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                  }
                  tooltip={tooltip}
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    }
    case RADIO_SELECTOR_COMPONENT: {
      const { options, layout, customMissingField } = props

      if (options) {
        elementToRender = (
          <>
            <RadioGroup
              id={id}
              label={label}
              layout={layout}
              variant={variant}
              disabled={disabled}
              isMissing={customMissingField && !values[fieldName]}
            >
              {options.map((option) => (
                <Field
                  key={option.value}
                  type="radio"
                  name={formattedFieldName}
                  value={option.value}
                  validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
                >
                  {({ input }) => (
                    <Radio
                      disabled={disabled}
                      {...input}
                      id={`${id}${concatenateSplitString(option.label)}`}
                      label={option.label}
                      variant={variant}
                    />
                  )}
                </Field>
              ))}
            </RadioGroup>
            {typeof extra === 'function' ? extra() : ''}
          </>
        )
      }
      break
    }
    case DATE_INPUT_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <DateInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  label={label}
                  variant={variant}
                  disabled={disabled}
                  isMissing={
                    runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)
                  }
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case YEAR_MONTH_INPUT_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <YearMonthInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  label={label}
                  variant={variant}
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case SEARCH_SELECTOR: {
      const {
        isSearchable = false,
        useTagsOnMulti = false,
        isMulti = true,
        data = [],
        showSelectAllOptions = true,
        displayMultiOptionsOnDisabled = false,
        heightCap,
      } = props

      elementToRender = (
        <Field name={formattedFieldName}>
          {({ input, meta }) => {
            return (
              <SearchSelector
                {...input}
                {...getFormMetaErrors({ intl, meta, messageInsert: errorMessageInsert || { label } })}
                id={fieldId}
                label={label}
                variant={variant}
                data={data as DropdownOption[]}
                disabled={disabled}
                isSearchable={isSearchable}
                useTagsOnMulti={useTagsOnMulti}
                heightCap={heightCap}
                isMulti={isMulti}
                isMissing={runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)}
                showSelectAllOptions={showSelectAllOptions}
                displayMultiOptionsOnDisabled={displayMultiOptionsOnDisabled}
              />
            )
          }}
        </Field>
      )
      break
    }
    case CHECKBOX_COMPONENT:
      elementToRender = (
        <Field initialValue={initialValue} name={formattedFieldName} type="checkbox">
          {({ input }) => {
            return <Checkbox id={fieldId} label={label} disabled={disabled} {...input} />
          }}
        </Field>
      )
      break
    case DATE_RANGE_SELECTOR:
      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <DateRangeSelector
                {...input}
                {...getFormMetaErrors({ intl, meta, messageInsert: errorMessageInsert || { label } })}
                id={fieldId}
                label={label}
                variant={variant}
                disabled={disabled}
                onApplyFilter={props.onApplyFilter}
                isMissing={runMissingFields && input.value === '' && isMissing.isMissing(missingFieldName || fieldName)}
              />
            )
          }}
        </Field>
      )
      break
    case PHONE_COMPONENT:
      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <>
                <PhoneInput
                  {...input}
                  {...getFormMetaErrors({
                    intl,
                    meta,
                    messageInsert: errorMessageInsert || { label },
                    includeTouched: !showErrorsWithoutTouched,
                  })}
                  id={fieldId}
                  label={label}
                  variant={variant}
                  disabled={disabled}
                  hasValue={props.hasValue}
                  iconName={props.iconName}
                  isMissing={runMissingFields && input.value === '' && isMissing.isMissing(fieldName)}
                />
                {typeof extra === 'function' ? extra() : ''}
              </>
            )
          }}
        </Field>
      )
      break
    case MULTI_SEARCH_SELECT: {
      const { isSearchable = false, data = [], mappingFn } = props

      elementToRender = (
        <Field
          name={formattedFieldName}
          validate={fieldValidation ? unwrapErrorFieldSchema(fieldValidation) : undefined}
        >
          {({ input, meta }) => {
            return (
              <MultiSearchSelect
                {...input}
                {...getFormMetaErrors({ meta, intl, includeTouched: false })}
                id={fieldId}
                label={label}
                variant={variant}
                disabled={disabled}
                data={data}
                isMissing={runMissingFields && input.value === '' && isMissing.isMissing(fieldName)}
                selectedValues={input.value}
                isSearchable={isSearchable}
                openMenuOnClick={true}
                mappingFn={mappingFn}
              />
            )
          }}
        </Field>
      )
      break
    }
    default:
      null
  }

  if (validation && validation.fncondition) {
    return (
      <FnCondition fncondition={validation.fncondition} fieldPrefix={fieldPrefix}>
        <Box mb={2}>{elementToRender}</Box>
      </FnCondition>
    )
  }

  if (validationRelatedField && validationCondition) {
    return (
      <Condition
        when={fieldPrefix ? `${fieldPrefix}.${validationRelatedField}` : validationRelatedField}
        is={validationCondition}
      >
        <Box mb={2}>{elementToRender}</Box>
      </Condition>
    )
  }

  return <Box mb={2}>{elementToRender}</Box>
})
export default memo(InputBuilder)
