import React, { ComponentPropsWithoutRef } from 'react'
import { Theme } from '@mui/material'

import { TableVariant } from '../../utils/constants'
import { StyledTh, VerticalAlign } from './Table.styles'

type ThProps = React.PropsWithChildren<{
  padded?: boolean
  isHidden?: boolean
  width?: string
  align?: 'left' | 'right' | 'center'
  padLeftForInputTable?: boolean
  gutterPadding?: keyof Theme['spacers']
  verticalAlign?: VerticalAlign
  paddingBottom?: boolean
  rowPadding?: keyof Theme['spacers']
  cellPadding?: keyof Theme['spacers']
  variant?: TableVariant
  colSpan?: number
  color?: keyof Theme['colours']
  fontSize?: number
}> &
  ComponentPropsWithoutRef<'th'>

const Th = ({
  children,
  color,
  isHidden = false,
  width = 'auto',
  align = 'left',
  gutterPadding,
  verticalAlign,
  rowPadding,
  variant,
  colSpan = 1,
  paddingBottom = true,
  fontSize,
  cellPadding,
  ...rest
}: ThProps) => {
  return (
    <StyledTh
      width={width}
      align={align}
      gutterPadding={gutterPadding}
      verticalAlign={verticalAlign}
      paddingBottom={paddingBottom}
      cellPadding={cellPadding}
      rowPadding={rowPadding}
      variant={variant}
      colSpan={colSpan}
      color={color}
      fontSize={fontSize}
      {...rest}
    >
      <div>{children !== null && !isHidden && children}</div>
    </StyledTh>
  )
}

export default Th
