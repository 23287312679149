import { FC, useMemo } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { isBefore, parse } from 'date-fns'
import { omit } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { PREFERENCE_MAP } from '@acre/utils'
import {
  formatAsCurrency,
  formatAsPercentage,
  MortgageReason,
  MortgageStatus,
  RateTiersType,
  useGetUserQuery,
} from '@acre/graphql'
import { H2, MasonryCell, MasonryColumn, MasonryLayout, Modal } from '@acre/design-system'

import { formatAddress } from '../../../Introducers/IntroducersTable.helpers'
import ChaseInfoClientInfo from './ChaseInfoClientInfo'
import { InfoList, InfoListProps } from './ChaseInfoModal.helpers'

type Props = {
  onClose: () => void
  open: boolean
}

const ChaseInfoModal: FC<Props> = ({ open, onClose }) => {
  const data = useCaseContext()

  const selectedMortgage = useMemo(
    () => data.details.mortgages?.find((mortgage) => mortgage.status === MortgageStatus.StatusSelected),
    [data.details.mortgages],
  )

  const currentMortgage = useMemo(
    () => data.details.mortgages?.find((mortgage) => mortgage.status === MortgageStatus.StatusCurrent),
    [data.details.mortgages],
  )

  const mortgageRate = useMemo(() => {
    const rateTiers = selectedMortgage?.mortgage_product?.rate_tiers || []

    const rateTier = rateTiers.reduce((rate, tier) => {
      if (tier.period_fixed_end_date) {
        const endDate = parse(tier.period_fixed_end_date, 'yyyy-mm-dd', new Date())
        const currentRateEndDate = rate.period_fixed_end_date
          ? parse(rate.period_fixed_end_date, 'yyyy-mm-dd', new Date())
          : null

        if (!currentRateEndDate || isBefore(endDate, currentRateEndDate)) {
          return tier
        }
      }

      return rate
    }, {} as RateTiersType)

    return rateTier.rate
  }, [selectedMortgage?.mortgage_product?.rate_tiers])

  const securitySection: InfoListProps = useMemo(() => {
    const property = [data.details.new_property, data.details.remortgage_property].find(
      (property) => property?.id === data.details.preference_target_property,
    )

    return {
      heading: 'cases.chaseInfo.security',
      items: [
        {
          label: 'cases.chaseInfo.address',
          value: formatAddress(omit(property?.details.address, '__typename')),
        },
        {
          label: 'cases.chaseInfo.valuation',
          value: formatAsCurrency(property?.details.valuation),
        },
      ],
    }
  }, [data])

  const showAdditionalBorrowing =
    data?.details?.preference_mortgage_reason &&
    [MortgageReason.ReasonRemortgage, MortgageReason.ReasonBtlRemortgage, MortgageReason.ReasonHouseMove].includes(
      data?.details?.preference_mortgage_reason,
    )

  const totalAdditionalBorrowing = data.details.additional_loans?.reduce((total, loan) => {
    if (loan.loan_amount && !isNaN(Number(loan.loan_amount)) && Number(loan.loan_amount)) {
      return total + Number(loan.loan_amount)
    }
    return total
  }, 0)

  const recommendedMortgageSection: InfoListProps = useMemo(() => {
    const mortgageDetails = [
      {
        label: 'cases.chaseInfo.loan',
        value: formatAsCurrency(data.details.preference_loan_amount),
      },

      {
        label: 'cases.chaseInfo.rate',
        value: formatAsPercentage(mortgageRate),
      },
      {
        label: 'cases.chaseInfo.applicationType',
        value: data.details.preference_mortgage_reason && PREFERENCE_MAP[data.details.preference_mortgage_reason],
        translation: true,
      },
      {
        label: 'cases.chaseInfo.applicationReference',
        value: selectedMortgage?.provider_application_reference,
      },
      {
        label: 'cases.chaseInfo.mortgageAccount',
        value: selectedMortgage?.mortgage_account_number,
      },
    ]

    if (showAdditionalBorrowing) {
      mortgageDetails.splice(1, 0, {
        label: 'cases.chaseInfo.additionalBorrowing',
        value: formatAsCurrency(totalAdditionalBorrowing),
      })
      return {
        heading: 'cases.chaseInfo.recommendedMortgage',
        items: mortgageDetails,
      }
    } else {
      return {
        heading: 'cases.chaseInfo.recommendedMortgage',
        items: mortgageDetails,
      }
    }
  }, [data.details, mortgageRate, selectedMortgage, showAdditionalBorrowing, totalAdditionalBorrowing])

  const currentMortgageSection: InfoListProps = useMemo(() => {
    return {
      heading: 'cases.chaseInfo.currentMortgage',
      items: [
        {
          label: 'cases.chaseInfo.mortgageAccount',
          value: currentMortgage?.mortgage_account_number,
        },
      ],
    }
  }, [currentMortgage])

  const { data: leadCaseOwner } = useGetUserQuery({
    variables: {
      id: data.details.owner_user_id!,
      useClientApi: false,
    },
    skip: !data.details.owner_user_id,
  })

  const brokerSection: InfoListProps = useMemo(() => {
    return {
      heading: 'cases.chaseInfo.brokerHeading',
      items: [
        {
          label: 'cases.chaseInfo.name',
          value: `${leadCaseOwner?.user?.first_name} ${leadCaseOwner?.user?.last_name}`,
        },
        {
          label: 'cases.chaseInfo.fcaNumber',
          value: data.details.owner?.organisation?.fca_reg_no?.toString(),
        },
        {
          label: 'cases.chaseInfo.firmName',
          value: data.details.owner?.organisation?.name,
        },
        {
          label: 'cases.chaseInfo.firmAddress',
          value: formatAddress(omit(data.details.owner?.organisation?.primary_address, '__typename')),
        },
      ],
    }
  }, [
    data.details.owner?.organisation?.fca_reg_no,
    data.details.owner?.organisation?.name,
    data.details.owner?.organisation?.primary_address,
    leadCaseOwner?.user?.first_name,
    leadCaseOwner?.user?.last_name,
  ])

  return (
    <Modal
      padding={false}
      size="Large"
      open={open}
      onClose={onClose}
      header={{
        left: (
          <H2 styledAs="h5">
            <FormattedMessage id="cases.chaseInfo.heading" />
          </H2>
        ),
      }}
    >
      <MasonryLayout>
        <MasonryColumn>
          <MasonryCell>
            <InfoList {...securitySection} />
            <InfoList {...recommendedMortgageSection} />
            {currentMortgage && <InfoList {...currentMortgageSection} />}
          </MasonryCell>
        </MasonryColumn>
        <MasonryColumn>
          <MasonryCell>
            {data?.details?.clients.map((client, index) => {
              const heading = { id: 'cases.chaseInfo.clientHeading', values: { index: index + 1 } }
              return <ChaseInfoClientInfo key={JSON.stringify(heading)} heading={heading} client={client} />
            })}
            <InfoList {...brokerSection} />
          </MasonryCell>
        </MasonryColumn>
      </MasonryLayout>
    </Modal>
  )
}

export default ChaseInfoModal
