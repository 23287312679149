import React, { useCallback } from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import TextField, { TextFieldProps } from '../TextField'
import { getNumberFieldUtilityClass } from './numberFieldClasses'

export interface NumberFieldProps extends TextFieldProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const NumberFieldRoot = styled(TextField, {
  name: 'NumberField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const useUtilityClasses = (ownerState: Partial<NumberFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    input: ['input'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getNumberFieldUtilityClass, ownerState.classes)
}

const _NumberField = (props: NumberFieldProps) => {
  const { className, classes, ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes, layout: rootProps.layout, size: rootProps.size })

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, props)

  const numberInputOnWheelPreventChange = useCallback((e: React.WheelEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement
    // Prevent the input value change
    el.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      el.focus()
    }, 0)
  }, [])

  return (
    <NumberFieldRoot
      {...rootProps}
      className={classnames(slotClasses.root, className)}
      inputProps={{
        ...rootProps.inputProps,
        type: 'number',
        pattern: '[0-9]*',
        inputMode: 'numeric',
        className: slotClasses.input,
        onWheel: numberInputOnWheelPreventChange,
      }}
      InputProps={{
        ...rootProps.InputProps,
        className: classnames(slotClasses.field, rootProps.InputProps?.className),
      }}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
    />
  )
}

const NumberField = React.memo(_NumberField) as typeof _NumberField

export default NumberField
