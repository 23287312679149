import React, { ChangeEvent, FocusEvent, KeyboardEvent, useState } from 'react'
import classNames from 'classnames'
import { FieldInputProps } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { allowedInputNumberKeys, testHandle } from '@acre/utils'
import { HELPER_TYPE_ERROR, HelperText, Label, Variant } from '@acre/design-system'

import { checkPercentageInputError } from './Percentage.helpers'
import { Div, Input, Unit } from './PercentageInput.styles'

type Props = {
  id: string
  value?: number
  label?: string
  error?: string
  placeholder?: string
  disabled?: boolean
  onChange?: (value: number) => void
  validateOnBlur?: (value: number) => void
  fieldInputProps?: FieldInputProps<string | number | undefined, HTMLInputElement>
  variant?: Variant
}

const PercentageInput = ({
  id,
  value = NaN,
  label,
  error = '',
  placeholder,
  disabled,
  onChange,
  validateOnBlur,
  fieldInputProps,
  variant,
}: Props) => {
  const [amount, setAmount] = useState<number>(value)
  const [focused, setFocused] = useState<boolean>(false)
  const [hasValidationError, setHasValidationError] = useState<string>(checkPercentageInputError(value))

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    const value = event.currentTarget.valueAsNumber
    setHasValidationError(checkPercentageInputError(value))
    setAmount(value)
    onChange && onChange(value)
    fieldInputProps && fieldInputProps.onChange(value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (disabled) return
    const value = event.currentTarget.valueAsNumber
    setFocused(false)
    validateOnBlur && validateOnBlur(value)
    fieldInputProps && fieldInputProps.onBlur(event)
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    fieldInputProps && fieldInputProps.onFocus(event)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    const hasAllowedChars = e.metaKey || allowedInputNumberKeys.indexOf(e.key) > -1

    if (!hasAllowedChars) e.preventDefault()
  }

  return (
    <div>
      {label && <Label htmlFor={id} isDisabled={disabled} text={label} />}
      <Div>
        <Input
          id={id}
          data-testid={testHandle(id)}
          type="number"
          value={isNaN(amount) ? '' : amount}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames({ error })}
          onKeyDown={handleKeyDown}
          variant={variant}
        />
        <Unit className={classNames({ focused, error })}>&#37;</Unit>
      </Div>

      {(error || hasValidationError) && (
        <HelperText
          id={`Helper${id}`}
          message={hasValidationError ? <FormattedMessage id={hasValidationError} /> : error}
          textType={HELPER_TYPE_ERROR}
        />
      )}
    </div>
  )
}

export default PercentageInput
