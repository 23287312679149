import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface YearMonthFieldClasses {
  root: string
  fields: string
  label: string
  months: string
  years: string
  helperText: string
  errorText: string
}

export type YearMonthFieldClassKey = keyof YearMonthFieldClasses

export function getYearMonthFieldUtilityClass(slot: string) {
  return generateUtilityClass('YearMonthField', slot)
}

const yearMonthFieldClasses: YearMonthFieldClasses = generateUtilityClasses('YearMonthField', [
  'root',
  'fields',
  'label',
  'months',
  'years',
  'helperText',
  'errorText',
])

export default yearMonthFieldClasses
