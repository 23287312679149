import React, { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { useUserContext } from '@acre/utils'
import {
  GetCaseClientsDocument,
  GetCaseDocument,
  GetCaseVerificationsDocument,
  RenderAndStoreIddDocumentDocument,
  RenderAndStoreIddDocumentMutation,
  RenderAndStoreIddDocumentMutationVariables,
} from '@acre/graphql'
import { H2, Modal } from '@acre/design-system'

import InitialDisclosureDocument from './InitialDisclosureDocument'
import InitialDisclosureFooter from './InitialDisclosureFooter'

type Props = {
  isOpen: boolean
  onClose: () => void
  caseId: string | undefined
  clientIds: string[]
  isLeadCase?: boolean
}

const InitialDisclosureModal = ({ isOpen, onClose, caseId, clientIds, isLeadCase }: Props) => {
  const theme = useTheme()
  const user = useUserContext()
  const { organisation_id } = user || { organisation_id: null }

  const organisationIds = useMemo(() => (organisation_id ? [organisation_id] : []), [organisation_id])

  const urlLocation = useLocation()
  const isCaseCreationFlow = urlLocation.pathname === '/cases/new'
  const [clientIdsAccept, setClientIdsAccept] = useState<string[]>([])

  const refetchQueries = isLeadCase
    ? [
        { query: GetCaseDocument, variables: { id: caseId } },
        { query: GetCaseClientsDocument, variables: { id: caseId } },
      ]
    : [
        { query: GetCaseDocument, variables: { id: caseId } },
        { query: GetCaseVerificationsDocument, variables: { id: caseId } },
      ]

  const [renderAndStoreIddDocument, { loading }] = useMutation<
    RenderAndStoreIddDocumentMutation,
    RenderAndStoreIddDocumentMutationVariables
  >(RenderAndStoreIddDocumentDocument, {
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const handleSubmit = useCallback(async () => {
    // Verify the initial disclosure documents for each client

    const baseInput = {
      organisation_ids: organisationIds,
      client_ids: clientIds,
      case_ids: [caseId!],
    }
    const documentPayload = {
      variables: {
        input: {
          ...baseInput,
        },
        clientIdsAccept: clientIdsAccept,
        caseId: caseId,
      },
    }

    await renderAndStoreIddDocument(documentPayload)

    onClose()
  }, [caseId, clientIds, clientIdsAccept, onClose, organisationIds, renderAndStoreIddDocument])

  const handleOnClose = () => {
    // on case creation flow we need to generate idds regardless if it has been accepted or not
    if (isCaseCreationFlow) {
      handleSubmit()
    }
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleOnClose}
      size="Large"
      header={{
        left: (
          <H2 styledAs="h5">
            <FormattedMessage id="cases.initialDisclosure.heading" />
          </H2>
        ),
      }}
      footer={
        <InitialDisclosureFooter
          handleSubmit={handleSubmit}
          setClientIdsAccept={setClientIdsAccept}
          clientIdsAccept={clientIdsAccept}
          clientIds={clientIds}
          loading={loading}
        />
      }
    >
      {/* Remove default modal margin of modal body */}
      <Box m={`-${theme.spacers.size48}`}>
        <InitialDisclosureDocument {...{ caseId, clientIds, organisationIds }} />
      </Box>
    </Modal>
  )
}

export default InitialDisclosureModal
