import { MortgageReason } from '@acre/graphql'

export type ProductType = Array<{
  label: string
  value: MortgageReason
  isDisabled?: boolean
}>

export const ProductTypes: ProductType = [
  {
    label: 'productTypes.firstTimeBuy',
    value: MortgageReason.ReasonFtb,
  },
  {
    label: 'productTypes.houseMove',
    value: MortgageReason.ReasonHouseMove,
  },
  {
    label: 'productTypes.remortgage',
    value: MortgageReason.ReasonRemortgage,
  },
  {
    label: 'productTypes.buyToLetRemortgage',
    value: MortgageReason.ReasonBtlRemortgage,
  },
  {
    label: 'productTypes.buyToLet',
    value: MortgageReason.ReasonBtl,
  },
  {
    label: 'productTypes.protection',
    value: MortgageReason.ReasonProtection,
  },
  {
    label: 'productTypes.businessProtection',
    value: MortgageReason.ReasonBusinessProtection,
  },
  {
    label: 'productTypes.equityRelease',
    value: MortgageReason.ReasonEquityRelease,
  },
  {
    label: 'productTypes.bridging',
    value: MortgageReason.ReasonBridging,
  },
  {
    label: 'productTypes.commercial',
    value: MortgageReason.ReasonCommercial,
  },
  {
    label: 'productTypes.homeInsurance',
    value: MortgageReason.ReasonGeneralInsurance,
  },
]
