import React, { ReactNode } from 'react'
import { Box } from '@mui/material'

import testHandle from '../../utils/testHandle'

export type NoteFooterProps = {
  id: string
  left?: ReactNode
  center?: ReactNode
  right?: ReactNode
}

const NoteFooter = ({ id, left, center, right }: NoteFooterProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" data-testid={testHandle(`${id}NoteFooter`)}>
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      {left}
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center">
      {center}
    </Box>
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {right}
    </Box>
  </Box>
)

export default NoteFooter
