import React, { ChangeEvent, FocusEvent, ReactNode } from 'react'
import { unstable_composeClasses } from '@mui/material'
import classNames from 'classnames'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import { Maybe } from '@acre/graphql'

import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import HelperText from '../HelperText'
import Label from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import { getTextAreaUtilityClass } from './textAreaClasses'
import { LabelAndInputWrapper, StyledTextArea, TextAreaWrapper } from './TextArea.styles'

export type TextAreaNewProps = {
  id?: string
  testId?: string
  rows?: number
  label?: ReactNode
  labelCentered?: boolean
  labelWidth?: Maybe<string>
  name?: string
  value?: string | number | null
  error?: boolean
  message?: ReactNode
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void
  onKeyDown?: () => void
  isMissing?: boolean
  className?: string
  variant?: Variant
  secondaryText?: ReactNode
}

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
    wrapper: ['wrapper'],
  }

  return unstable_composeClasses(slots, getTextAreaUtilityClass, undefined)
}

const TextAreaNew = ({
  id,
  testId,
  rows,
  label,
  labelCentered = false,
  labelWidth,
  value,
  error,
  message,
  name,
  disabled: disabledProp,
  ariaLabel,
  placeholder,
  onBlur,
  onChange,
  onFocus,
  isMissing,
  className,
  onKeyDown,
  variant = 'default',
  secondaryText,
}: TextAreaNewProps) => {
  const slotClasses = useUtilityClasses()

  const intl = useIntl()
  const wrapperClassName = classNames({ error })
  const testingHandle = testHandle(testId || id)
  const formatMessage = (id: string): string => intl.formatMessage({ id })

  const disabled = useFieldDisabledState(disabledProp)

  // Need to ensure that <br> are converted to \n before rendering the value
  const parseValue = (value: string | number | null | undefined): string | undefined => {
    if (value === null || value === undefined) {
      return
    }
    return value.toString().replace(/<br>/g, '\n')
  }

  return (
    <MatomoWrapper onChange={onChange} id={id} eventType="textAreaChange" trackEventTrigger="onBlur">
      <TextAreaWrapper
        data-testid={`${testingHandle}Wrapper`}
        className={classnames(slotClasses.wrapper, wrapperClassName)}
      >
        <LabelAndInputWrapper variant={variant} labelCentered={labelCentered} labelWidth={labelWidth}>
          {label && (
            <Label htmlFor={id} text={label} isMissing={isMissing} variant={variant} secondaryText={secondaryText} />
          )}
          <StyledTextArea
            id={id}
            rows={rows}
            value={parseValue(value)}
            name={name}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            aria-label={ariaLabel}
            placeholder={placeholder}
            data-testid={testingHandle}
            className={classnames(slotClasses.root, className)}
            onKeyDown={onKeyDown}
            variant={variant}
          />
        </LabelAndInputWrapper>

        {message && (
          <HelperText
            id={`${testId || id}Helper`}
            message={message || ''}
            textType={error ? HELPER_TYPE_ERROR : ''}
            variant={variant}
          />
        )}
        {isMissing && (
          <HelperText
            id={`${id}Helper`}
            message={formatMessage('errors.missingFieldRequired')}
            textType={HELPER_TYPE_ERROR}
            variant={variant}
          />
        )}
      </TextAreaWrapper>
    </MatomoWrapper>
  )
}

export default TextAreaNew
