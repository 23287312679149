import { useParams } from 'react-router-dom'

import { useGetCaseQuery } from '@acre/graphql'

import { UseRouteDataHookProps } from './types'

const useRouteForCase = (
  { fetchPolicy = 'cache-first', skip = false, warn = true }: UseRouteDataHookProps = {
    fetchPolicy: 'cache-first',
    skip: false,
    warn: true,
  },
) => {
  const params = useParams<{ caseId?: string }>()

  const { data, loading, error } = useGetCaseQuery({
    variables: {
      id: params.caseId as string,
    },
    fetchPolicy,
    skip: skip || !params.caseId,
  })

  if (warn && !params.caseId) {
    console.warn('useRouteForCase: caseId was not found in route. This is possibly a bug.')
  }

  return { data: data?.case, loading, error }
}

export default useRouteForCase
