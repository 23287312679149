import React from 'react'
import { styled, Theme } from '@mui/material'
import { transparentize } from 'polished'

import { GREYED_OUT, TableVariant } from '../../utils/constants'

// Tables don't play very nicely with box-sizing, padding, margins, etc. so the
//
// following CSS might be somewhat confusing. It's been commented where appropriate
// but most of the styles for cells are kept here, so that shared CSS attributes
// can be managed from one place.

export type VerticalAlign = 'top' | 'middle'

type TrProps = {
  fontSize?: number
  fontWeight?: 'normal' | 'bold'
  rowPadding?: keyof Theme['spacers']
  gutterPadding?: keyof Theme['spacers']
  cellPadding?: keyof Theme['spacers']
  colour?: 'grey' | 'blue'
  variant?: TableVariant
  borderTop?: boolean
}

type CellProps = TrProps & {
  width?: string
  align?: 'left' | 'right' | 'justify' | 'center'
  verticallyAlign?: boolean
  paddingLeft?: boolean | keyof Theme['spacers']
  paddingRight?: boolean | keyof Theme['spacers']
  colSpan?: number
  fontSize?: number
}

type ThProps = {
  verticalAlign?: VerticalAlign
  paddingBottom?: boolean
  color?: keyof Theme['colours']
}

type TableProps = {
  borderBottom?: boolean
  tableLayout?: 'auto' | 'fixed'
}

//
// Shared styles
//
const customRowPadding = (theme: Theme, rowPadding?: keyof Theme['spacers']) => `
  padding-top: ${theme.spacers[rowPadding || 'size16']};
  padding-bottom: ${theme.spacers[rowPadding || 'size16']};
`

const cellContentStyles = () => `
  height: 100%;
  display: block;
`

const cellStyles = (theme: Theme, cellPadding?: keyof Theme['spacers'], gutterPadding?: keyof Theme['spacers']) => `
  /* Reset all padding */
  padding: 0;
  /* The first and last cells in any row must be padded manually, because rows and tables don't accept padding */

  /* Each cell should also pad slightly from its neighbour */
  padding-left: ${cellPadding ? theme.spacers[cellPadding] : theme.spacers.size16};
  padding-right: ${cellPadding ? theme.spacers[cellPadding] : theme.spacers.size16};

  &:first-of-type {
    padding-left: ${gutterPadding ? theme.spacers[gutterPadding] : '0'};
    padding-right: ${cellPadding ? theme.spacers[cellPadding] : theme.spacers.size16};
  }

  &:last-of-type {
    padding-right: ${gutterPadding ? theme.spacers[gutterPadding] : '0'};
    padding-left: ${cellPadding ? theme.spacers[cellPadding] : theme.spacers.size16};
  }

  &:only-child {
    padding-right: ${gutterPadding ? theme.spacers[gutterPadding] : theme.spacers.size16};
    padding-left: ${gutterPadding ? theme.spacers[gutterPadding] : theme.spacers.size16};
  }

  /* We need to add padding to a cell's content rather than the cell itself, so that the cell's clickable area has no deadzones*/
  & > * {
    ${cellContentStyles()}
  }
`

//
// Styled components
//
export const StyledTable = styled('table')<TableProps>(
  ({ theme, tableLayout, borderBottom }) => `
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  table-layout: ${tableLayout};

  .error {
    margin-left: 0;
  }

  ${borderBottom ? `border-bottom: 1px solid ${theme.colours.baseLight};` : `border-bottom: none;`}
`,
)

export const StyledThead = styled('thead')<{ borderBottom: boolean; borderTop: boolean; divided: boolean }>(
  ({ theme, borderBottom, borderTop, divided }) => `
  border-bottom: ${borderBottom && `1px solid ${theme.colours.baseLight}`};
  border-top: ${borderTop && `1px solid ${theme.colours.baseLight}`};

  ${
    divided &&
    `
    & tr {
     border-top: 1px solid ${theme.colours.baseLight};
    }
    & th {
      border-right: 1px solid ${theme.colours.baseLight};
    }
    & th:last-of-type {
      border-right: none;
    }
  `
  }
`,
)

export const StyledThNoProps = styled('th')<CellProps & ThProps>(
  ({ theme, width, align, verticalAlign, paddingBottom, rowPadding, fontSize, color, cellPadding, gutterPadding }) => `
  ${cellStyles(theme, cellPadding, gutterPadding)}

  font-weight: ${theme.typography.fontWeightRegular};
  font-size: ${fontSize ? fontSize : 14}px;
  line-height: 1.6;
  color: ${theme.colours[color || 'baseMid']};
  width: ${width};

  text-align: ${align};

  ${verticalAlign ? `vertical-align: ${verticalAlign};` : ''};
  ${paddingBottom ? `padding-bottom: ${theme.spacers[rowPadding || 'size16']};` : 'padding-bottom: 0;'};
  ${customRowPadding(theme, rowPadding)};
`,
)

export const StyledTh = (props: React.PropsWithChildren<CellProps & ThProps>) => (
  <StyledThNoProps {...props} colSpan={props?.colSpan || 1}>
    {props.children}
  </StyledThNoProps>
)

const greyedOutStyles = (theme: Theme) => `
  background-color: ${theme.colours.background};
  color: ${theme.colours.baseLight};
  & > td > * {
    color: ${theme.colours.baseMid};
  }

  & > td > a > svg path {
    stroke: ${theme.colours.baseMid};
  }
`

export const StyledTr = styled('tr')<TrProps>(
  ({ theme, onClick, fontSize, fontWeight, variant, colour, borderTop }) => `
  transition: all 0.1s ease-out;
  cursor: ${onClick ? 'pointer' : 'default'};

  & + tr {
    ${borderTop ? `border-top: none;` : `border-top: 1px solid ${theme.colours.baseLight};`}
  }

  font-size: ${fontSize ? fontSize : 16}px;
  line-height: 1.6;
  font-weight: ${fontWeight};

  & > * {
    font-size: ${fontSize ? fontSize : 16}px;
    line-height: 1.6;
  }

  ${variant === GREYED_OUT && greyedOutStyles}
  color: ${(colour === 'grey' && theme.colours.base) || (colour === 'blue' && theme.colours.primary)};
  &.styled {
    background-color: ${theme.colours.background};
    color: ${theme.colours.base};
    border-top: ${`1px solid ${theme.colours.baseLight}`};
  }

  &.highlight {
    &:hover {
      background-color: ${
        (colour === 'grey' && theme.colours.baseExtraLight) ||
        (colour === 'blue' && transparentize(theme.opacity.op90, theme.colours.primary))
      };
      color: ${(colour === 'grey' && theme.colours.base) || (colour === 'blue' && theme.colours.primary)};
      cursor: pointer;
    }
  }

  &.selected {
    background-color: ${
      (colour === 'grey' && theme.colours.baseExtraLight) ||
      (colour === 'blue' && transparentize(theme.opacity.op90, theme.colours.primary))
    };

    color: ${(colour === 'grey' && theme.colours.base) || (colour === 'blue' && theme.colours.primary)};
  }
`,
)

export const StyledTd = styled('td')<CellProps>(
  ({ theme, verticallyAlign, fontSize, cellPadding, gutterPadding, rowPadding }) => `
  ${cellStyles(theme, cellPadding, gutterPadding)}
  ${customRowPadding(theme, rowPadding)};
  ${verticallyAlign && 'vertical-align: top'};
  font-size: ${fontSize ? fontSize : 16}px;
  line-height: 1.6;

  & > * {
    font-size: ${fontSize ? fontSize : 16}px;
    line-height: 1.6;
  }
  &.incompleteBanner {
    border-left: ${`${theme.spacers.size4} solid ${theme.colours.danger}`};
  }

  a {
    color: ${theme.colours.base};
    transition: color 0.1s ease-out;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`,
)

const centerProps = `
  display: flex;
  justify-content: center;
`

export const TdContent = styled('div')<CellProps>(
  ({ theme, width, align, paddingLeft }) => `
  width: ${width};
  ${align === 'center' && centerProps}
  ${
    (!paddingLeft && 'padding-left: 0px !important') ||
    (typeof paddingLeft === 'string' && `padding-left: ${theme.spacers[paddingLeft]} !important`)
  };
`,
)

export const TdCircle = styled('div')<{
  selected?: boolean
}>(
  ({ theme, selected }) => `
  width: ${theme.spacers.size24};
  min-width: ${theme.spacers.size24};
  height: ${theme.spacers.size24};
  flex-shrink: 0;

  display: block;
  border-radius: 100%;

  border-style: solid;
  border-width: 2px;
  box-shadow: inset 0px 0px 0px 3px ${selected ? theme.colours.primary10 : 'none'};
  border-color: ${selected ? theme.colours.primary : theme.colours.baseLight};
  /* Using just a transparent colour as the background for unselected circles, so we see the underlying colour of the table */
  background-color: ${selected ? theme.colours.primary : '#fff0'};
`,
)
