import { styled, Theme } from '@mui/material'

import { MEDIUM, SHORT, Variant } from '../../utils/constants'
import { Height } from '../../utils/types'
import { commonControlStyles } from '../../styles/form-control.styles'

const compactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
  font-size: 14px;
  padding-left: ${theme.spacing(1.5)};
  padding-right: ${theme.spacing(1.5)};
`

export const StyledInput = styled('input')<{
  variant?: Variant
  height?: Height
  fontSize?: number
}>(
  ({ theme, height, fontSize, variant }) => `
  ${commonControlStyles(theme)}
  
  height: ${
    height === SHORT
      ? theme.display.inputHeightShort
      : height === MEDIUM
        ? theme.display.inputHeightMedium
        : theme.display.inputHeight
  };
  
  font-size: ${fontSize ? fontSize : '16'}px;
  position: relative;

  // These variant styles need to remain at bottom of styles so that compact styles can override above styles
  ${
    (variant === 'compact' || variant === 'compactLeftAligned' || variant === 'compactTopAligned') &&
    compactStyles(theme)
  };
`,
)

export const StyledText = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  min-width: ${theme.spacing(1)};
  min-height: ${theme.spacing(1)};
`,
)
