import { isEqual, isObject, transform } from 'lodash'

const objectDifference = (object: any, base: any) => {
  function changes(object: any, base: any): {} {
    return transform(object, (result: any, value: any, key: any) => {
      if (!isEqual(value, base[key])) {
        result[key] = isEqual(value, {}) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export default objectDifference
