import { useMemo, useState } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { Box, Tooltip, useTheme } from '@mui/material'

import { useFormatMessage } from '@acre/utils'
import { GateName } from '@acre/graphql'
import { ButtonNewest, LoadingSpinner } from '@acre/design-system'

type Props = {
  value: string
  loadData: () => Promise<string>
}

const ProtectedReadOnly = ({ value, loadData }: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()

  const [currentValue, setCurrentValue] = useState<string>(value)
  const [loading, setLoading] = useState<boolean>(false)

  const caseContext = useCaseContext()

  // client/view_protected permission will always be true if client/edit_client is true,
  // this is known behaviour in the BE
  const canViewProtectedFields = useMemo(
    () => caseContext?.permissions?.includes(GateName.CLIENT_VIEW_PROTECTED),
    [caseContext?.permissions],
  )

  const hasObfuscatedValue = useMemo(() => currentValue.includes('**'), [currentValue])

  const handleClick = async () => {
    if (hasObfuscatedValue) {
      setLoading(true)
      const unobfuscatedValue = await loadData()
      setCurrentValue(unobfuscatedValue)
      setLoading(false)
    } else {
      setCurrentValue(value)
    }
  }

  if (loading)
    return (
      <Box display="flex" justifyContent="end">
        <LoadingSpinner variant="compact" noMargin />
      </Box>
    )

  return (
    <Box display="flex" justifyContent="end">
      <p>{currentValue}</p>
      <Tooltip
        title={formatMessage('cases.reviewMode.protectedFieldDisabledTooltip')}
        placement="top"
        data-testid="protectedFieldDisabledTooltip"
        disableHoverListener={canViewProtectedFields}
        disableFocusListener={canViewProtectedFields}
        disableTouchListener={canViewProtectedFields}
      >
        <span>
          <ButtonNewest
            variant="link"
            onClick={handleClick}
            disabled={!canViewProtectedFields}
            sx={{
              ml: theme.spacing(1),
              fontSize: theme.typography.pxToRem(12),
            }}
          >
            {hasObfuscatedValue ? formatMessage('generic.show') : formatMessage('generic.hide')}
          </ButtonNewest>
        </span>
      </Tooltip>
    </Box>
  )
}

export default ProtectedReadOnly
