import { Typography, useTheme } from '@mui/material'
import { ChartBar, ListChecks } from '@phosphor-icons/react'
import { FormattedMessage } from 'react-intl'

import { DashboardsNavLink, NavLinkIconStyles, NavLinkWrapper } from '@acre/design-system'

type Props = {
  to: string
  id: string
  pathname: string
  iconColour: string
  onClick?: () => void
}

const DashboardsNavPopoverItem = ({ to, id, pathname, iconColour, onClick }: Props) => {
  const theme = useTheme()

  const isPerformanceDashboard = to.includes('/dashboard/performance/')
  const prefix = isPerformanceDashboard ? 'nav.performanceDashboard' : 'nav.complianceDashboard'

  const splitedTo = to.split('/')
  const dashboardPageLocation = splitedTo[splitedTo.length - 2]

  return (
    <DashboardsNavLink
      to={to}
      id={id}
      isActive={(location) => location.startsWith(to)}
      onClick={onClick}
      alwaysRedirect
    >
      <NavLinkIconStyles sx={{ backgroundColor: theme.colours.new[`${iconColour}1`] }}>
        {isPerformanceDashboard ? (
          <ChartBar size={20} color={theme.colours.new[`${iconColour}2`]} />
        ) : (
          <ListChecks size={20} color={theme.colours.new[`${iconColour}2`]} />
        )}
      </NavLinkIconStyles>
      <NavLinkWrapper>
        <Typography
          fontWeight={theme.typography.fontWeightBold}
          lineHeight={1.5}
          color={pathname.startsWith(to) ? theme.colours.primary : theme.colours.base}
          component="span"
        >
          <FormattedMessage id={`${prefix}.${dashboardPageLocation}`} />
        </Typography>
        <Typography
          fontSize={theme.typography.pxToRem(12)}
          color={theme.colours.base}
          lineHeight={1.5}
          component="span"
        >
          <FormattedMessage id={`${prefix}.${dashboardPageLocation}Subheading`} />
        </Typography>
      </NavLinkWrapper>
    </DashboardsNavLink>
  )
}

export default DashboardsNavPopoverItem
