import React, { ReactNode, useCallback } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import debounce from 'debounce-promise'
import { OptionTypeBase, ValueType } from 'react-select'
import AsyncSelect, { Props as AsyncProps } from 'react-select/async'
import { SelectComponents } from 'react-select/src/components'

import { testHandle } from '@acre/utils'

import { Variant } from '../../utils/constants'
import { ControlContainer, MenuListLoadingSpinner } from './SearchComponents'
import { asyncSelectStyles } from './AsyncSearch.styles'
import { MagnifyingGlass } from '../../assets'

export type AsyncSearchProps<T extends OptionTypeBase> = {
  onClick: (value: any) => void
  executeSearch: (inputValue: string) => Promise<T[]>
  placeholder?: string
  disabled?: boolean
  components?: Partial<SelectComponents<T, any>>
  noOptionsMessage?: string
  error?: string
  customStyles?: (theme: Theme) => Partial<{}>
  onFocus?: () => void
  onBlur?: () => void
  defaultValue?: OptionTypeBase[]
  topOptions?: ReactNode
  variant?: Variant
} & Omit<AsyncProps<OptionTypeBase, any>, 'theme'>

const DropdownIndicator = () => <MagnifyingGlass />

const AsyncSearch = ({
  disabled,
  placeholder,
  components,
  noOptionsMessage,
  error = '',
  customStyles,
  onClick,
  onBlur,
  onFocus,
  executeSearch,
  defaultValue,
  topOptions,
  variant,
  value,
  ...rest
}: AsyncSearchProps<OptionTypeBase>) => {
  const theme = useTheme()
  let rejectOutstanding: (reason: string) => void | undefined
  const reject = () => {
    rejectOutstanding && rejectOutstanding('replaced')
  }
  const inputLoadOptions = useCallback(
    debounce(
      (input: string, cb: (options: OptionTypeBase[]) => void) =>
        new Promise<OptionTypeBase[]>(function (resolve, r) {
          reject()
          rejectOutstanding = r
          executeSearch(input).then((results) => resolve(results))
        })
          .then((results) => cb(results))
          .catch(console.warn),
      400,
    ),
    [executeSearch],
  )

  const handleChange = useCallback(
    (newValue: ValueType<OptionTypeBase, any>) => {
      onClick(newValue as OptionTypeBase)
    },
    [onClick],
  )

  // collect styles which have theme applied to it
  const styles = {
    ...asyncSelectStyles(theme, variant, error),
    ...(customStyles ? customStyles(theme) : {}),
  }

  const loadingSpinner = () => {
    return (
      <>
        {topOptions}
        <MenuListLoadingSpinner />
      </>
    )
  }

  return (
    <AsyncSelect
      inputId={rest.id}
      error={error}
      loadOptions={inputLoadOptions}
      styles={styles}
      isSearchable
      isClearable
      cacheOptions={false}
      defaultOptions={defaultValue}
      blurInputOnSelect={true}
      tabSelectsValue={false}
      // we do this because otherwise it's possible to race
      // against the apollo cache which always gets overwritten
      // by older results
      onKeyDown={reject}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || null}
      isDisabled={disabled}
      placeholder={<div data-testid={testHandle(`${rest.id}-placeholder`)}>{placeholder}</div>}
      noOptionsMessage={() => noOptionsMessage || null}
      components={{
        DropdownIndicator,
        LoadingMessage: loadingSpinner,
        LoadingIndicator: null,
        Control: ControlContainer,
        ...components,
      }}
      {...rest}
    />
  )
}

export default AsyncSearch

// TODO: FRON-2149
// Removing this test since it is not longer needed and creating a ticket
// to make a new more reasonable test.
