import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { matchPath, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'

import { testHandle } from '@acre/utils'
import { ArrowDown } from '@acre/design-system'
import { FeatherIcon, IconName, Size } from '@acre/design-system'

import {
  SideMenuArrow,
  SideMenuItem as MenuItem,
  SideMenuLink,
  SideMenuMissingFieldsCount,
  SideMenuNotificationsCount,
  SideMenuNotLink,
} from './SideMenu.styles'

export interface SideMenuItemProps extends NavLinkProps {
  label: string
  to: string
  children?: ReactNode
  disabled?: boolean
  openPath?: string | string[]
  missingFieldsCount?: number
  showMissingFields?: boolean
  showNotifications?: boolean
  forceSelected?: boolean
  notificationsCount?: number
  isHeading?: boolean
  id?: string
  as?: React.ElementType
  iconName?: IconName
  enableNavigate?: boolean
  enableAutoCollapse?: boolean
}

const SideMenuItem = ({
  label,
  to,
  children,
  disabled,
  openPath,
  missingFieldsCount,
  showMissingFields,
  showNotifications,
  forceSelected,
  notificationsCount,
  isHeading = false,
  id,
  as,
  iconName,
  enableNavigate = false,
  enableAutoCollapse = false,
  ...props
}: SideMenuItemProps) => {
  // Whether the accordion for a submenu is open or not
  // If there is a route match (isDefaultOpen) then open by default
  // so the active item is not hidden
  const [isOpen, setIsOpen] = useState(false)

  // Checks if the current URL path matches the openPath prop e.g. /fact-find/
  const location = useLocation()
  const theme = useTheme()

  const navigate = useNavigate()
  const hasRunOnce = useRef(false)

  const handleClick = useCallback(() => {
    if (enableNavigate && !isOpen) {
      navigate(`${to}`)
    }

    setIsOpen(!isOpen)
  }, [enableNavigate, isOpen, navigate, to])

  useEffect(() => {
    if (!enableAutoCollapse) {
      if (hasRunOnce.current) return
      hasRunOnce.current = true
    }

    const isDefaultOpen = openPath
      ? ([] as string[]).concat(openPath).some((path) => matchPath(path, location.pathname))
      : false

    setIsOpen(isDefaultOpen)
  }, [enableAutoCollapse, location, openPath])

  if (!children || isHeading) {
    return (
      <MenuItem>
        <SideMenuLink
          className={forceSelected ? 'active' : ''}
          as={as}
          disabled={disabled}
          // The styled component underneath is a <Link /> which passes props
          // through to the DOM node which doesn't accept booleans (hence the ternary use with numbers below)
          // https://github.com/styled-components/styled-components/issues/1198
          heading={isHeading ? 1 : 0}
          data-testid={id ? testHandle(id) : null}
          to={!disabled ? to : '#'}
          {...props}
        >
          {iconName && (
            <Box display="flex" mr={theme.spacers.size8} alignItems="center" justifyContent="center">
              <FeatherIcon name={iconName} size={Size.Small} />
            </Box>
          )}
          <Box display="flex" width={'100%'} alignItems="center" justifyContent="space-between">
            <Box>{label}</Box>
            {showNotifications && (
              <SideMenuNotificationsCount>
                <span>{notificationsCount}</span>
              </SideMenuNotificationsCount>
            )}
          </Box>
          {showMissingFields && (
            <Box display="flex" justifyContent="center" height="14px">
              {missingFieldsCount || <FeatherIcon name={IconName.CheckSquare} size={Size.Small} />}
            </Box>
          )}
        </SideMenuLink>
        {children}
      </MenuItem>
    )
  }

  return (
    <>
      <MenuItem>
        <SideMenuNotLink
          data-testid={id ? testHandle(id) : null}
          as={disabled ? 'button' : as}
          disabled={disabled}
          onClick={handleClick}
          className={isOpen ? 'selected' : ''}
        >
          {iconName && (
            <Box display="flex" mr={theme.spacers.size8} alignItems="center" justifyContent="center">
              <FeatherIcon name={iconName} size={Size.Small} />
            </Box>
          )}
          {label} {showNotifications && <SideMenuNotificationsCount>{notificationsCount}</SideMenuNotificationsCount>}
          {showMissingFields && (
            <SideMenuMissingFieldsCount>
              {missingFieldsCount || <FeatherIcon name={IconName.CheckSquare} size={Size.Small} />}
            </SideMenuMissingFieldsCount>
          )}
          {!disabled && (
            <SideMenuArrow open={isOpen}>
              <ArrowDown />
            </SideMenuArrow>
          )}
        </SideMenuNotLink>
        {isOpen && children}
      </MenuItem>
    </>
  )
}

export default React.memo(SideMenuItem)
