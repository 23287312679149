import { styled, Theme } from '@mui/material'
import { Styles } from 'react-select'

import { Variant } from '../../utils/constants'

// styled('div') has a bug in the library at current version
// hence using styled('div')
export const SearchLoadingSpinner = styled('div')(({ theme }) => ({
  '@keyframes donut-spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  border: '4px solid rgba(0, 0, 0, 0.1)',
  borderLeftColor: theme.colours.primary,
  borderRadius: '50%',
  width: 30,
  height: 30,
  animation: 'donut-spin 1.2s linear infinite',
  display: 'block',
}))

export const ClearButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  color: 'theme.colours.baseMid',
  marginRight: 16,
  fontSize: 14,
  '&:hover': {
    color: theme.colours.primary,
  },
}))

export const CloseModalButton = styled('div')(({ theme }) => ({
  marginRight: theme.spacers.size8,
  cursor: 'pointer',
  '& > div > svg': {
    width: 14,
  },
  '&:hover': {
    color: theme.colours.primary,
    '& svg path': {
      color: theme.colours.primary,
      stroke: theme.colours.primary,
    },
  },
}))

export const asyncSelectStyles: (theme: Theme, variant?: Variant, error?: string) => Partial<{}> = (
  theme,
  variant,
  error,
) => ({
  container: (styles: Styles<any, any>) => ({
    ...styles,
    borderWidth: theme.display.inputBorderWidth,
    outlineWidth: theme.display.inputBorderWidth,
    borderRadius: theme.display.borderRadius,
    fontFamily: theme.typography.fontFamily,
  }),
  control: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    ...(isDisabled
      ? {
          backgroundColor: theme.colours.baseExtraLight,
        }
      : {}),
    minHeight: theme.spacers.size32,
    padding: theme.spacers.size8,
    borderRadius: theme.display.borderRadius,
    boxShadow: 'none',
    borderColor: error ? theme.colours.danger : theme.colours.baseLight,
    borderWidth: theme.display.inputBorderWidth,
    outlineWidth: theme.display.inputBorderWidth,
    '&:hover': {
      cursor: 'text',
    },
    '&:focus-within': {
      cursor: 'text',
      borderColor: theme.colours.primary,
      outlineColor: theme.colours.primary,
    },
    ...(['compactLeftAligned', 'compact'].includes(variant as string) && compactControlStyles(theme)),
  }),
  valueContainer: (styles: Styles<any, any>) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
  input: (styles: Styles<any, any>) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  indicatorsContainer: (styles: Styles<any, any>) => ({
    ...styles,
    border: 'none',
    borderColor: theme.colours.baseLight,
    padding: 0,
    paddingRight: theme.spacers.size8,
    ...(['compactLeftAligned', 'compact'].includes(variant as string) && {
      svg: {
        transform: 'scale(calc(2/3))',
      },
    }),
  }),
  indicatorSeparator: () => ({
    border: 'none',
  }),
  menu: (styles: Styles<any, any>) => ({
    ...styles,
    overflow: 'auto',
    borderRadius: theme.display.borderRadius,
    width: '28rem',
    zIndex: 9,
    maxHeight: '40vh',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      webkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  }),
  menuList: () => ({
    padding: 0,
    borderRadius: 0,
    overflowY: 'auto',
  }),
  menuPortal: (styles: Styles<any, any>) => ({
    ...styles,
    zIndex: 9,
  }),
  option: (styles: Styles<any, any>, { isFocused }: { isFocused: boolean }) => {
    const shared = {
      backgroundColor: theme.colours.primary10,
      color: theme.colours.primary,
      cursor: 'pointer',
    }
    return {
      ...styles,
      ...(isFocused
        ? shared
        : {
            backgroundColor: theme.colours.foreground,
          }),
      '.details *': isFocused ? shared : {},
      padding: theme.spacers.size16,
      minHeight: theme.spacers.size48,
      '&:hover, :hover .details *': shared,
      '& em': {
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightBold,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      ...(['compactLeftAligned', 'compact'].includes(variant as string) && compactOptionStyles(theme)),
    }
  },
  placeholder: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    margin: 0,
    padding: 0,
    ...(isDisabled
      ? {
          color: theme.colours.baseMid,
        }
      : {}),
  }),
  singleValue: (styles: Styles<any, any>, { isDisabled }: { isDisabled: boolean }) => ({
    ...styles,
    margin: 0,
    padding: 0,
    ...(isDisabled
      ? {
          color: theme.colours.baseMid,
        }
      : {}),
  }),
})

export const modalStyle: (theme: Theme) => Partial<{}> = (theme: Theme) => ({
  menu: (styles: Styles<any, any>) => ({
    ...styles,
    position: 'absolute',
    borderRadius: theme.display.borderRadius,
    zIndex: 9,
    width: '100%',
    maxHeight: '40vh',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      webkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  }),
})

export const globalSelectStyles: (theme: Theme) => Partial<{}> = (theme: Theme) => ({
  control: (styles: Styles<any, any>) => ({
    ...styles,
    minHeight: theme.spacers.size32,
    paddingTop: theme.spacers.size4,
    paddingBottom: theme.spacers.size4,
    paddingRight: theme.spacers.size16,
    paddingLeft: theme.spacers.size16,
    borderRadius: theme.display.borderRadius,
    boxShadow: 'none',
    borderColor: theme.colours.foreground,
    borderWidth: theme.display.inputBorderWidth,
    outlineWidth: theme.display.inputBorderWidth,
    '&:hover': {
      cursor: 'text',
      borderColor: theme.colours.baseLight,
    },
    '&:focus-within': {
      cursor: 'text',
      borderColor: theme.colours.primary,
      outlineColor: theme.colours.primary,
    },
    '&.error': {
      borderColor: theme.colours.danger,
    },
    backgroundColor: theme.colours.baseExtraLight,
    '& > div': {
      flexWrap: 'nowrap',
    },
  }),
  placeholder: (styles: Styles<any, any>) => ({
    ...styles,
    color: theme.colours.baseMid,
    margin: 0,
  }),
  indicatorsContainer: (styles: Styles<any, any>) => ({
    ...styles,
    border: 'none',
    borderColor: theme.colours.baseLight,
    padding: 0,
    svg: {
      transform: 'scale(0.8)',
    },
  }),
  menuPortal: (styles: Styles<any, any>) => ({
    ...styles,
    zIndex: 9999,
  }),
  menu: (styles: Styles<any, any>) => ({
    ...styles,
    width: '135%',
    maxHeight: '80vh',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      webkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  }),
})

export const GlobalSearchWrapper = styled('div')`
  top: 0.5rem;
  right: 0.5rem;
  width: 10rem;

  @media (min-width: ${({ theme }) => theme.wrappers.extraLarge}) {
    width: 20rem;
  }
`
const compactControlStyles = (theme: Theme) => ({
  minHeight: theme.spacers.size32,
  height: theme.spacers.size32,
  fontSize: 14,
  padding: 0,
  paddingLeft: theme.spacers.size8,
})

const compactOptionStyles = (theme: Theme) => ({
  paddingTop: theme.spacers.size8,
  paddingBottom: theme.spacers.size8,
})
