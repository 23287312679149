import { v4 as generateUniqueId } from 'uuid'

import {
  Mortgage,
  MortgageAndCase,
  MortgageAndProduct,
  MortgageAndProductInput,
  MortgageInput,
  MortgageProductActualInput,
} from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import { CdmCreateAcreMortgageProductResponse } from '../service/luther/model'

export const addMortgageToProduct = async (input: MortgageAndProductInput) => {
  const response = await request.post<MortgageAndProduct>(`/mortgage/${input.mortgage_id}/product`, {
    ...input,
    source_response: input.source_response && JSON.parse(input.source_response),
  })

  return response.data
}

export const createMortgageProduct = async (input: MortgageProductActualInput) => {
  const response = await request.post<CdmCreateAcreMortgageProductResponse>(
    '/mortgage_product',
    // FRON-955 Product code is required by Luther. If product code is not provided, we need to generate a
    // unique ID (which is a temporary workaround until this issue is resolved)
    {
      product_details: {
        ...input,
        product_code: input.product_code || generateUniqueId(),
      },
    },
  )
  return response.data
}

export const updateMortgageProductHelper = async (
  mortgageId: string,
  mortgageProductId: string,
  mortgageProductInput: MortgageProductActualInput,
  mortgageInput?: MortgageInput | null,
  mortgageProductIdToDelete?: string,
) => {
  let mortgage: Mortgage = {
    ...(mortgageInput || {}),
    id: mortgageId,
    mortgage_id: mortgageId,
  }

  await deleteMortgageProduct(mortgageId, mortgageProductIdToDelete ?? mortgageProductId)

  const mortgageProduct = await createMortgageProduct(mortgageProductInput)

  if (mortgageProduct) {
    // Link mortgage and product
    const addMortgageToProductInput: MortgageAndProductInput = {
      mortgage_id: mortgageId,
      product_id: mortgageProduct.product_id,
    }
    await addMortgageToProduct(addMortgageToProductInput)

    mortgage = {
      ...mortgage,
      mortgage_product_id: mortgageProduct.product_id,
      mortgage_product: {
        ...mortgageProduct,
        id: mortgageProduct.product_id,
      },
    }
  }

  mortgageLoader.clearAll()

  return mortgage
}

export const deleteMortgageProduct = async (mortgageId: string, productId: string) => {
  const response = await request.delete<MortgageAndCase>(`/mortgage/${mortgageId}/product/${productId}`)
  return response.data
}
