import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { ConfirmationModal } from '@acre/design-system'

export interface IllustrationConfirmationModalProps {
  open: boolean
  onSubmit: () => Promise<void> | void
  onClose: () => Promise<void> | void
}

const _IllustrationConfirmationModal = ({ open, onSubmit, onClose }: IllustrationConfirmationModalProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  return (
    <ConfirmationModal
      isVisible={open}
      handleClose={onClose}
      footer={{
        primaryButton: {
          id: 'ConfirmEsisFeeChange',
          onClick: async () => {
            setSubmitting(true)
            await onSubmit()
            setSubmitting(false)
            onClose()
          },
          isLoading: submitting,
          text: 'generic.continue',
        },
        secondaryButton: {
          id: 'CancelEsisFeeChange',
          onClick: onClose,
        },
      }}
    >
      <FormattedMessage id="cases.products.options.generateIllustration.changeFeesConfirmation" />
    </ConfirmationModal>
  )
}

const IllustrationConfirmationModal = React.memo(_IllustrationConfirmationModal)

export default IllustrationConfirmationModal
