import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'
import { omit } from 'lodash'

import { GetClientMiDocumentsQueryVariables } from '@acre/graphql'

const keyArgs: KeyArgsFunction = (params?: Pick<GetClientMiDocumentsQueryVariables, 'filters'> | null) => {
  return JSON.stringify(params && params.filters ? omit(params.filters, ['bookmark', 'page_size']) : {})
}

export default {
  keyArgs,
}
