import { useCallback } from 'react'
import { useReportProblemContext } from '@broker-crm-contexts'
import useRouteForCase from '@broker-crm-utils/hooks/useRouteCase'
import { Box } from '@mui/material'
import { FormApi } from 'final-form'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { useFormatMessage, useUserContext } from '@acre/utils'
import { useCreateReportProblemMutation, ZendeskReportProblemInput } from '@acre/graphql'
import {
  BUTTON_DANGER_SECONDARY,
  Checkbox,
  GreyText,
  H2,
  Modal,
  ModalFooter,
  TextAreaNew,
  TextInput,
} from '@acre/design-system'

import {
  buildReportTemplate,
  captureAndReturnScreenshot,
  extractCaseUUID,
  ProblemReportForm,
  SERVICE_REQUEST_TYPE,
} from './ReportProblem.helpers'

const ReportProblem = () => {
  const { isProblemReported, setIsProblemReported } = useReportProblemContext()
  const currentUser = useUserContext()
  const currentRoute = useLocation()
  const currentCaseId = extractCaseUUID(currentRoute.pathname)

  const { data: caseData } = useRouteForCase({
    warn: false,
    caseId: currentCaseId,
    // Prevents org ID being passed as case ID in reports route
    skip: currentRoute.pathname.includes('reports'),
  })

  const [submitProblemReport, { loading }] = useCreateReportProblemMutation()
  const getFormattedMessage = useFormatMessage()

  const closeModal = useCallback(() => {
    setIsProblemReported(false)
  }, [setIsProblemReported])

  const resetFormAndCloseModal = useCallback(
    (form: FormApi<ProblemReportForm, Partial<ProblemReportForm>>) => {
      closeModal()
      form.reset()
    },
    [closeModal],
  )

  const handleSubmit = useCallback(
    async (values: ProblemReportForm, form: FormApi<ProblemReportForm, Partial<ProblemReportForm>>) => {
      const report = buildReportTemplate(currentRoute.pathname, values, currentUser, caseData)
      const requesterName = `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`
      const subject = `[${currentUser?.organisation?.name}] ${values?.title || ''}`

      const problemReport: ZendeskReportProblemInput = {
        requester: { name: requesterName, email: currentUser?.email_address || '' },
        subject,
        comment: {
          body: report,
          type: SERVICE_REQUEST_TYPE,
        },
        url: 'https://' + window.location.hostname + currentRoute.pathname,
        org_id: currentUser?.organisation_id,
      }
      if (values.screenshot) {
        const screenshotBase64 = await captureAndReturnScreenshot()
        problemReport.screenshot = screenshotBase64
      }

      const { data } = await submitProblemReport({
        variables: {
          input: problemReport,
        },
      })

      const isSubmitSuccessful = data?.createReportProblem

      if (isSubmitSuccessful) {
        resetFormAndCloseModal(form)
      } else if (values.screenshot) {
        // Show the modal back if it was hidden and the request failed
        const modalElement = document.getElementById('ReportProblemModal')
        if (modalElement) {
          modalElement.style.display = 'flex'
        }
      }
      return
    },
    [caseData, currentRoute.pathname, currentUser, resetFormAndCloseModal, submitProblemReport],
  )

  return (
    <Form onSubmit={(values, form) => handleSubmit(values, form)} initialValues={{}}>
      {({ handleSubmit, form, hasValidationErrors, pristine }) => (
        <form onSubmit={handleSubmit} id="ReportForm">
          <Modal
            id="ReportProblemModal"
            open={isProblemReported}
            onClose={() => resetFormAndCloseModal(form)}
            header={{
              left: (
                <Box flexDirection="column" alignItems="baseline">
                  <H2 styledAs="h5">
                    <FormattedMessage id="problemReport.title" />
                  </H2>
                  <GreyText fontSize={12}>
                    <FormattedMessage id="problemReport.describeProblem" />
                  </GreyText>
                </Box>
              ),
            }}
            footer={
              <ModalFooter
                secondaryButton={{
                  id: 'CancelReport',
                  onClick: () => resetFormAndCloseModal(form),
                  style: BUTTON_DANGER_SECONDARY,
                  isDisabled: loading,
                  text: 'generic.cancel',
                }}
                primaryButton={{
                  id: 'SubmitReport',
                  onClick: handleSubmit,
                  isLoading: loading,
                  isDisabled: loading || hasValidationErrors || pristine,
                  text: 'generic.submit',
                }}
              />
            }
          >
            <Box mb={2}>
              <Field name="title">
                {({ input }) => <TextInput {...input} label={getFormattedMessage('problemReport.title')} id="Title" />}
              </Field>
            </Box>
            <Box mb={2}>
              <Field name="situation">
                {({ input }) => (
                  <TextAreaNew
                    {...input}
                    label={getFormattedMessage('problemReport.situation')}
                    rows={3}
                    id="Situation"
                  />
                )}
              </Field>
            </Box>
            <Box mb={2}>
              <Field name="expectation">
                {({ input }) => (
                  <TextAreaNew
                    {...input}
                    label={getFormattedMessage('problemReport.expectation')}
                    rows={3}
                    id="Expectation"
                  />
                )}
              </Field>
            </Box>
            <Box mb={2}>
              <Field name="problem">
                {({ input }) => (
                  <TextAreaNew {...input} label={getFormattedMessage('problemReport.problem')} rows={3} id="Problem" />
                )}
              </Field>
            </Box>
            <Field name="screenshot" type="checkbox">
              {({ input }) => (
                <Checkbox {...input} label={getFormattedMessage('problemReport.screenshot')} id="Screenshot" />
              )}
            </Field>
          </Modal>
        </form>
      )}
    </Form>
  )
}

export default ReportProblem
