import { styled, Theme } from '@mui/material'

import { Variant } from '../../utils/constants'

export const TimeFieldset = styled('fieldset')`
  padding: 0;
  margin: 0;
  border: 0;
`

export const TimeInputSection = styled('input')(
  ({ theme }) => `
  font-size: 16px;
  color: ${theme.colours.base};
  border: none;
  text-align: center;
  width: 100%;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  min-width: 0;
  &:disabled {
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }
`,
)

export const TimeInputField = styled('div')<{
  variant?: Variant
}>(
  ({ theme, variant }) => `
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: ${theme.display.inputHeight};
  border-style: solid;
  border-width: ${theme.display.inputBorderWidth};
  border-radius: ${theme.display.borderRadius};
  border-color: ${theme.colours.baseLight};
  transition: border-color 0.2s ease-in-out;

  ${TimeInputSection}:focus & {
    border-color: ${theme.colours.primary};
  }

  &:focus-within {
    border-color: ${theme.colours.primary};
  }

  &.disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
  }

  ${(variant === 'compact' || variant === 'compactLeftAligned') && timeCompactStyles(theme)};
`,
)
const timeCompactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
  & > * {
    font-size: 14px;
  }
`
