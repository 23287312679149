import React, { ChangeEvent, FocusEvent, ReactElement, useMemo } from 'react'
import classNames from 'classnames'

import { testHandle } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import { Size } from '../../utils'
import Icon, { IconName } from '../FeatherIcon'
import { CheckboxLabel, StyledCheckbox } from './Checkbox.styles'

export type CheckboxNewProps = {
  id: string
  label: string | ReactElement
  checked?: boolean
  disabled?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  readOnly?: boolean
}

const CheckboxNew = ({ label, ...props }: CheckboxNewProps) => {
  const { id, checked, disabled } = props

  const className = useMemo(
    () =>
      classNames({
        checked,
        disabled,
      }),
    [checked, disabled],
  )

  return (
    <CheckboxLabel htmlFor={id} className={className}>
      <StyledCheckbox className={className}>
        <input data-testid={testHandle(id)} type="checkbox" aria-label={label as string} {...props} />
        {checked && <Icon name={IconName.Check} size={Size.Small} />}
      </StyledCheckbox>
      {label}
    </CheckboxLabel>
  )
}

export default withDisabled(CheckboxNew)
