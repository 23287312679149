import { PropsWithChildren } from 'react'
import { NavLinkProps, useLocation } from 'react-router-dom'

import testHandle from '../../utils/testHandle'
import { StyledDashboardsNavLink } from './DashboardsNavLink.styles'

export type DashboardsNavLinkProps = PropsWithChildren<{
  to: string
  id: string
  disabled?: boolean
  danger?: boolean
  alwaysRedirect?: boolean
  isActive?: (location: string) => boolean
}> &
  NavLinkProps

const DashboardsNavLink = ({
  id,
  to,
  children,
  danger,
  alwaysRedirect,
  isActive: customIsActive,
  ...rest
}: DashboardsNavLinkProps) => {
  const defaultIsActive = (match: string) => match !== null
  const isActiveCheck = customIsActive ?? defaultIsActive
  const location = useLocation()

  return (
    <StyledDashboardsNavLink
      id={id}
      to={to}
      data-testid={testHandle(id)}
      danger={danger}
      className={isActiveCheck(location?.pathname) ? 'active' : ''}
      {...rest}
      // If the nav page is already active, do not trigger redirect as this can trigger side effects e.g. unsaved change modal
      {...(isActiveCheck(location.pathname) && !alwaysRedirect ? { onClick: (e) => e.preventDefault() } : {})}
    >
      {children}
    </StyledDashboardsNavLink>
  )
}

export default DashboardsNavLink
