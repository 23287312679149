import React, { useCallback } from 'react'
import { DialogActions, DialogContent, styled } from '@mui/material'
import { Form, FormProps, FormRenderProps } from 'react-final-form'

import Dialog, { DialogProps } from '../Dialog'

export interface DialogFormProps<FV extends Record<string, unknown> = Record<string, unknown>>
  extends Omit<DialogProps, 'title' | 'onSubmit' | 'variant'> {
  variant?: 'form' | 'table'
  actions: React.ReactElement
  disableCloseOnSubmit?: boolean
  FormProps?: Omit<FormProps<FV>, 'onSubmit'>
  loading?: boolean
  title?: React.ReactNode | ((formProps: Omit<FormRenderProps<FV>, 'onSubmit'>) => React.ReactNode)
  onSubmit: FormProps<FV>['onSubmit']
  onCancel?: FormProps<FV>['onCancel']
  render?: (formProps: Omit<FormRenderProps<FV>, 'onSubmit'>) => React.ReactNode
}

const StyledDialog = styled(Dialog, {
  name: 'DialogForm',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const StyledDialogContent = styled(DialogContent, {
  name: 'DialogFormContent',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(2),
  },
  '& > *:last-of-type': {
    marginBottom: 0,
  },
}))

const StyledForm = styled('form', {
  name: 'DialogForm',
  slot: 'Form',
  overridesResolver: (props, styles) => styles.form,
})({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
})

function DialogForm<FV extends Record<string, unknown> = Record<string, unknown>>({
  children,
  render,
  actions,
  disableCloseOnSubmit = false,
  title,
  FormProps,
  onSubmit,
  onCancel,
  ...dialogProps
}: DialogFormProps<FV>) {
  const handleClose = useCallback(
    () =>
      (...props: Parameters<NonNullable<DialogFormProps<FV>['onClose']>>) => {
        dialogProps.onClose && dialogProps?.onClose?.(...props)
      },
    [dialogProps],
  )

  const handleSubmit = useCallback<(fn: DialogFormProps<FV>['onSubmit']) => FormProps<FV>['onSubmit']>(
    (submitHandler) => async (values, form, callback) => {
      await submitHandler(values, form, callback)

      if (!disableCloseOnSubmit && typeof dialogProps.onClose === 'function') {
        handleClose()({}, 'escapeKeyDown')
      }
    },
    [dialogProps.onClose, disableCloseOnSubmit, handleClose],
  )

  const handleOnClose = useCallback(
    (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        if (typeof handleClose === 'function') {
          handleClose()({}, reason)
        }
        onCancel && onCancel()
      }
    },
    [handleClose, onCancel],
  )

  return (
    <Form<FV>
      subscription={{ pristine: true, initialValues: true, values: true, valid: true }}
      {...FormProps}
      onSubmit={handleSubmit(onSubmit)}
      render={(props) => (
        <StyledDialog
          maxWidth="xs"
          fullWidth
          title={typeof title === 'function' ? title(props) : title}
          {...dialogProps}
          onClose={(event, reason) => {
            handleOnClose(event, reason)
            if (reason !== 'backdropClick') {
              props.form.restart()
            }
          }}
        >
          <StyledForm onSubmit={props.handleSubmit}>
            <StyledDialogContent>{render ? render(props) : children}</StyledDialogContent>
            <DialogActions>{actions}</DialogActions>
          </StyledForm>
        </StyledDialog>
      )}
    />
  )
}

DialogForm.defaultProps = {
  variant: 'form',
}

export default DialogForm
