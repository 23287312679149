import React, { Children, memo, ReactElement, useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import {
  Button,
  BUTTON_POPOVER,
  ButtonOverflow,
  ButtonTheme,
  ColourId,
  COMPACT_LEFT_ALIGNED,
  IconTypes,
  Tooltip,
  Variant,
} from '@acre/design-system'

type Props = React.PropsWithChildren<{
  id: string
  isLoading?: boolean
  isDisabled?: boolean
  colourID?: ColourId
  onDelete?: () => void
  onView?: () => void
  onEdit?: () => void
  onCancel?: () => void
  onClose?: () => void
  onReverse?: () => void
  buttonText?: string
  icon?: IconTypes
  style?: ButtonTheme
  overFlowMargin?: boolean
  variant?: Variant
  customIcon?: ReactElement
  fontColor?: string
  fontSize?: string
  deleteText?: string
  showLeftMargin?: boolean
  closeOnClick?: boolean
  maxSize?: boolean
  shouldDisableDelete?: boolean
  tooltipContent?: React.ReactElement<any, string>
  customMinWidth?: string
}>
type ChildType = React.ReactElement<{ onClick: () => Promise<void> | void }>

const GenericOverflowButtons = ({
  id,
  isLoading,
  isDisabled,
  colourID,
  onDelete,
  onCancel,
  onView,
  onEdit,
  onReverse,
  icon,
  closeOnClick = true,
  children,
  customIcon,
  buttonText,
  style,
  overFlowMargin,
  fontColor,
  variant = 'compact',
  deleteText,
  fontSize,
  showLeftMargin = false,
  maxSize,
  shouldDisableDelete = false,
  tooltipContent,
  customMinWidth,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  // If a button has an onClick handler (or if it doesn't but closeOnClick prop is set to true), we should close the button (i.e. run handleClose)
  // on click of the button by passing handleClose to the onClick action of each child
  const mappedChildren =
    children &&
    Children.map(children, (child) => {
      const childCopy = child as ChildType

      if (childCopy.props.onClick) {
        const onClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
          e.stopPropagation()
          await childCopy.props.onClick()
          handleClose()
        }
        return React.cloneElement<{ onClick: React.MouseEventHandler<HTMLButtonElement> }>(childCopy, { onClick })
      }

      return closeOnClick ? React.cloneElement(childCopy, { onClick: handleClose }) : child
    })

  const renderDeleteButton = useCallback(
    (onDelete: (() => void) | undefined) => {
      if (onDelete) {
        const button = (
          <Button
            id={`${id}Delete`}
            fullWidth
            variant={COMPACT_LEFT_ALIGNED}
            buttonStyle="popOverDanger"
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
              onDelete()
            }}
            disabled={shouldDisableDelete}
          >
            {deleteText || <FormattedMessage id="generic.delete" />}
          </Button>
        )

        if (tooltipContent) {
          return (
            <Tooltip content={tooltipContent} trigger="mouseenter">
              {button}
            </Tooltip>
          )
        } else return button
      }
    },
    [deleteText, handleClose, id, shouldDisableDelete, tooltipContent],
  )

  return (
    <ButtonOverflow
      id={id}
      maxSize={maxSize}
      isOpen={isOpen}
      onClick={(e) => {
        e.stopPropagation()
        setIsOpen(true)
      }}
      icon={icon}
      customIcon={customIcon}
      onClose={(e) => {
        e.stopPropagation()
        handleClose()
      }}
      isLoading={isLoading}
      disabled={isDisabled}
      variant={variant}
      colourID={colourID}
      overflowMargin={overFlowMargin}
      showText={buttonText ? true : false}
      buttonText={buttonText}
      style={style}
      fontColor={fontColor}
      fontSize={fontSize}
      showLeftMargin={showLeftMargin}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        minWidth={customMinWidth || 'auto'}
        style={{ overflow: 'hidden' }}
      >
        {onView && (
          <Button
            id={`${id}View`}
            fullWidth
            variant={COMPACT_LEFT_ALIGNED}
            buttonStyle={BUTTON_POPOVER}
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
              onView()
            }}
          >
            <FormattedMessage id="generic.view" />
          </Button>
        )}
        {onEdit && (
          <Button
            id={`${id}Edit`}
            fullWidth
            variant="compactLeftAligned"
            buttonStyle="popOver"
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
              onEdit()
            }}
          >
            <FormattedMessage id="generic.edit" />
          </Button>
        )}
        {onCancel && (
          <Button
            id={`${id}Cancel`}
            fullWidth
            variant="compactLeftAligned"
            buttonStyle="popOver"
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
              onCancel()
            }}
          >
            <FormattedMessage id="generic.cancel" />
          </Button>
        )}
        {mappedChildren}
        {onReverse && (
          <Button
            id={`${id}Reverse`}
            fullWidth
            variant="compactLeftAligned"
            buttonStyle="popOverDanger"
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
              onReverse()
            }}
          >
            {deleteText || <FormattedMessage id="generic.reInState" />}
          </Button>
        )}
        {onDelete && renderDeleteButton(onDelete)}
      </Box>
    </ButtonOverflow>
  )
}

export default memo(GenericOverflowButtons)
