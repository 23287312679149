import React from 'react'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { Divider, H2, UnsetMarginWrapper } from '@acre/design-system'

const TasksCardHeader = ({ heading }: { heading: string }) => {
  return (
    <>
      <H2 styledAs="h5" data-testid={testHandle('CaseVerificationsHeader')}>
        <FormattedMessage id={heading} />
      </H2>
      <Box component="p" mt={0.5} mb={4} fontSize={14}>
        <FormattedMessage id={'caseOverview.verifications.tasksDescription'} />
      </Box>
      <UnsetMarginWrapper margin="size32">
        <Divider />
      </UnsetMarginWrapper>
    </>
  )
}

export default TasksCardHeader
