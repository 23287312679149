import { CaseStatus, Maybe, MortgageReason, PropertyLocation, ProtectionProductDetails } from '@acre/graphql'

export const isCaseInReview = (status?: Maybe<CaseStatus>) => {
  if (!status) {
    return false
  }
  return [CaseStatus.Review, CaseStatus.PostRecommendationReview].includes(status)
}

export const isGeneralProtection = (mortgageReason?: Maybe<MortgageReason>) => {
  if (!mortgageReason) return false

  return [MortgageReason.ReasonProtection, MortgageReason.ReasonBusinessProtection].includes(mortgageReason)
}

export const isGeneralInsurance = (mortgageReason?: Maybe<MortgageReason>) => {
  if (!mortgageReason) return false

  return [MortgageReason.ReasonGeneralInsurance].includes(mortgageReason)
}

export const shouldShowSuitabilityReport = (has_client_accepted_protection_advice?: Maybe<boolean>) => {
  if (!has_client_accepted_protection_advice) {
    return false
  }
  return has_client_accepted_protection_advice
}

export const isBusinessProtection = (mortgageReason?: Maybe<MortgageReason>) => {
  if (!mortgageReason) return false

  return MortgageReason.ReasonBusinessProtection === mortgageReason
}

export const isHomeInsurance = (mortgageReason?: Maybe<MortgageReason>) => {
  if (!mortgageReason) return false

  return MortgageReason.ReasonGeneralInsurance === mortgageReason
}

export const isManualProtection = (productsDetails?: ProtectionProductDetails[]): boolean => {
  if (productsDetails && productsDetails[0].source_response) {
    const source_response = JSON.parse(productsDetails[0].source_response)
    if (source_response.solutions.length === 0) {
      return true
    }
  }
  return false
}

export const shouldShowProtectionReferral = (mortgageReason?: Maybe<MortgageReason>) => {
  if (!mortgageReason) {
    return false
  }

  const mortgageReasonsToShowProtectionReferral = [
    MortgageReason.ReasonFtb,
    MortgageReason.ReasonHouseMove,
    MortgageReason.ReasonRemortgage,
    MortgageReason.ReasonFurtherAdvance,
    MortgageReason.ReasonBtl,
    MortgageReason.ReasonBtlRemortgage,
  ]

  return mortgageReasonsToShowProtectionReferral.includes(mortgageReason)
}

export const isInEnglandAndWales = (propertyLocation: string | PropertyLocation) => {
  return (
    [PropertyLocation.EnglandWales, PropertyLocation.England, PropertyLocation.Wales].includes(
      propertyLocation as PropertyLocation,
    ) || propertyLocation === 'GB'
  )
}
