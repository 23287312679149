import { format, formatDistanceToNow, formatRelative, getQuarter, isBefore, isValid } from 'date-fns'

const formatDateAndTime = (date: string | Date) => {
  const datePassed = new Date(date)
  const futureDate = isBefore(new Date(), datePassed)

  if (isValid(datePassed) && !futureDate) {
    const fromNow = formatDistanceToNow(new Date(date), { addSuffix: true, includeSeconds: true })

    if (fromNow.includes('minute') || fromNow.includes('seconds') || fromNow.includes('minutes')) {
      return fromNow
    }
    if (fromNow.includes('hours') || fromNow.includes('hour')) {
      return formatRelative(new Date(), new Date(date)).replace(/^\w/, (c) => c.toLowerCase())
    } else {
      return format(new Date(date), 'dd MMM yyyy, h:mm a')
    }
  } else {
    return 'Invalid date'
  }
}

const calculateLastFourQuarters = (date: Date) => {
  const currentQuarter = getQuarter(date)
  const currentYear = date.getFullYear()

  switch (currentQuarter) {
    case 1:
      return [`${currentYear - 1} Q4`, `${currentYear - 1} Q3`, `${currentYear - 1} Q2`, `${currentYear - 1} Q1`]
    case 2:
      return [`${currentYear} Q1`, `${currentYear - 1} Q4`, `${currentYear - 1} Q3`, `${currentYear - 1} Q2`]
    case 3:
      return [`${currentYear} Q2`, `${currentYear} Q1`, `${currentYear - 1} Q4`, `${currentYear - 1} Q3`]
    case 4:
      return [`${currentYear} Q3`, `${currentYear} Q2`, `${currentYear} Q1`, `${currentYear - 1} Q4`]
    default:
      return null
  }
}

const getLastFourQuarters = () => {
  return calculateLastFourQuarters(new Date())
}

const formatAsYearsAndMonth = (months: number) => {
  return `${Math.floor(months / 12)} Years ${months % 12} Months`
}

// This function prepends a 0 if it's a single digit to keep the time format of hh:mm
const formatDigits = (digit: string | null) => {
  if (digit && digit.length === 1) return `0${digit}`
  return digit
}

export { formatDateAndTime, getLastFourQuarters, calculateLastFourQuarters, formatAsYearsAndMonth, formatDigits }
