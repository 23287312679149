import { sharedHrStyles } from '@broker-crm-common'
import { styled } from '@mui/material'
import { transparentize } from 'polished'

import { Button } from '@acre/design-system'

export const OverviewSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.display.borderRadius,
  border: `1px solid ${theme.colours.baseLight}`,
  overflow: 'visible',
}))

export const FlagRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const VerificationTaskButton = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0;
  text-align: left;
  width: 100%;
  line-height: 1.6;
  color: ${({ theme }) => theme.colours.base};
  &:hover {
    color: ${({ theme }) => theme.colours.primary};
  }
`

export const VerificationComplete = styled('div')(({ theme }) => ({
  position: 'relative',
  color: theme.colours.success,
  marginLeft: `-${theme.spacing(4)}`,
  marginRight: `-${theme.spacing(4)}`,

  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  lineHeight: theme.typography.body1.lineHeight,

  '&::after': {
    ...sharedHrStyles({ theme }),
    bottom: 0,
  },
}))

export const VerificationMessage = styled('div')`
  display: flex;
  flex: 3;
  font-size: 14px;
  padding-right: ${({ theme }) => theme.spacing(1)};
  & > span:first-letter {
    text-transform: capitalize;
  }
`

export const DocumentDownload = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colours.base};
  text-decoration: none;

  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  border-radius: ${({ theme }) => theme.display.borderRadius};
  transition: all 0.1s ease-out;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => theme.colours.primaryPaleColor};
  }

  &:active {
    background-color: ${({ theme }) => transparentize(theme.opacity.op80, theme.colours.primary)};
  }
`

export const LeadCaseAssignButton = styled(Button)`
  color: ${({ theme }) => theme.colours.primary};

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: ${({ theme }) => theme.colours.black};
  }

  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

export const LeadSummaryListButton = styled(Button)`
  color: ${({ theme }) => theme.colours.primary};

  &:hover {
    text-decoration: underline;
  }
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`
