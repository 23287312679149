import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface MenuItemLinkClasses {
  root: string
}

export type MenuItemLinkClassKey = keyof MenuItemLinkClasses

export function getMenuItemLinkUtilityClass(slot: string) {
  return generateUtilityClass('MenuItemLink', slot)
}

const menuItemLinkClasses: MenuItemLinkClasses = generateUtilityClasses('MenuItemLink', ['root'])

export default menuItemLinkClasses
