import { ComponentPropsWithoutRef } from 'react'
import { styled } from '@mui/material'

// This style is being left in this file as it is used in quite a few places
// But it is now deprecated and should no longer be used--use Text component below, which is more versatile
const Paragraph = styled('p')`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacers.size16};
`
export type ParagraphProps = ComponentPropsWithoutRef<'p'>
export default Paragraph
