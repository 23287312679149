import { useEffect, useMemo } from 'react'
import usePreviewMode from '@broker-crm-common/hooks/usePreviewMode'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useLocation } from 'react-router-dom'

import { useFormatMessage } from '@acre/utils'
import { FeatherIcon, GlobalWarning, IconName, Size, theme, trackEventFiltered } from '@acre/design-system'

import { ServiceMessage } from './ServiceMessageBanner'
import { ServiceBannerColour } from './ServiceMessageBanner.helpers'

type Props = {
  serviceMessage: ServiceMessage
}

const ServiceMessageAlert = ({ serviceMessage }: Props) => {
  const location = useLocation()
  const formatMessage = useFormatMessage()
  const { active: previewModeEnabled } = usePreviewMode()
  const { trackEvent } = useMatomo()

  const { previewOnly, message, actionLink, colour } = serviceMessage

  const handleClick = (link: string) => {
    window.open(link, '_blank')
    trackEventFiltered(trackEvent, location, 'serviceBannerActionClick', `redirect-${link}`)
  }

  const hidePreviewMessage = useMemo(() => !previewModeEnabled && previewOnly, [previewModeEnabled, previewOnly])

  useEffect(() => {
    if (!hidePreviewMessage) {
      trackEventFiltered(trackEvent, location, 'serviceBannerView', `viewed-${message}`)
    }
  }, [hidePreviewMessage, location, message, trackEvent])

  if (hidePreviewMessage) return

  return (
    <GlobalWarning
      colour={colour === ServiceBannerColour.Green ? theme.colours.new.mint2 : theme.colours.new.yellow2}
      background={colour === ServiceBannerColour.Green ? theme.colours.new.mint1 : theme.colours.new.yellow1}
      messageText={message}
      buttonText={formatMessage('globalWarnings.moreInformation')}
      buttonAction={actionLink ? () => handleClick(actionLink!) : undefined}
      icon={<FeatherIcon name={IconName.AlertTriangle} size={Size.Medium} />}
    />
  )
}

export default ServiceMessageAlert
