import config from '@acre/config'

const { LOGOUT_URL } = config

export const extractHostAndPortFromUrl = (url: string) => {
  const regex = /^https:\/\/([^/]+)\/?/
  const match = url.match(regex)
  return match ? match[1] : LOGOUT_URL
}

export const clearCookies = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=;'
  }
}
