import { DataProxy } from '@apollo/client'
import { DocumentNode } from 'graphql'

/**
 * Retrieve a deeply nested value from an object
 * @param obj An object to retrieve a nested value from
 * @param path An array of strings which form the the keys e.g. ['a', 'b'] to retrieve obj.a.b
 */
export const getNestedValue: any = (obj: { [key: string]: any }, path: string[]) => {
  let base = obj

  while (path.length) {
    if (base[path[0]]) {
      base = base[path[0]]
      path = path.slice(1)
    } else {
      return null
    }
  }

  return base
}

/**
 * Create a nested object with a value at the end of the given path
 * @param path An array of strings to form the object
 * @param value The value to nest inside the object
 */
export const makeNestedValue = (path: string[], value: any) => {
  let base = { [path.slice(-1)[0]]: value }

  path
    .slice(0, -1)
    .reverse()
    .forEach((key) => {
      base = { [key]: base }
    })

  return base
}

/**
 * Set a nested value inside an object
 * @param obj An object within which you want to set a nested value
 * @param path The path in the object you wish to set the value at, as an array of strings
 * @param value The value to set
 */
export function setNested(obj: { [key: string]: any }, path: string[], value: any): object {
  return Object.keys(obj).reduce((acc, key: string) => {
    if (key === path[0] && path.length > 1) {
      return { ...acc, [key]: setNested(obj[key], path.slice(1), value) }
    } else if (key === path[0] && path.length === 1) {
      return { ...acc, [key]: value }
    }
    return { ...acc, [key]: obj[key] }
  }, {})
}

type UpdateCacheItemParams<ItemType> = {
  query: DocumentNode
  variables: { [key: string]: any }
  data: ItemType
  key: string
}

const updateCachedItem = async function updateCachedItem<ItemType>(
  cache: DataProxy,
  { query, variables, data, key }: UpdateCacheItemParams<ItemType>,
): Promise<ItemType> {
  let structuredData = null

  if (key.split('.').length > 1) {
    let currentValue: object | null = null

    try {
      currentValue = cache.readQuery({ query, variables })
    } catch (e) {
      // Data was not in the cache yet
      currentValue = makeNestedValue(key.split('.'), null)
    }
    structuredData = setNested(currentValue!, key.split('.'), data)
  } else {
    structuredData = makeNestedValue(key.split('.'), data)
  }

  await cache.writeQuery({
    query,
    variables,
    data: structuredData,
  })

  return data
}

export enum CachedListUpdateStrategy {
  Prepend = 'PREPEND',
  Append = 'APPEND',
}

type QueryWithVariables = {
  query: DocumentNode
  variables: { [key: string]: any }
}

type UpdateCachedListParams<ItemType> = {
  readQuery: QueryWithVariables
  writeQuery: QueryWithVariables
  newItem: ItemType
  strategy: CachedListUpdateStrategy
  key: string
}

const updateCachedList = async function updateCachedList<ItemType>(
  cache: DataProxy,
  { readQuery, writeQuery, newItem, strategy, key }: UpdateCachedListParams<ItemType>,
): Promise<void> {
  let cachedData: { [key: string]: ItemType[] } | null = null

  try {
    cachedData = cache.readQuery(readQuery)
  } catch (err) {
    // There is no cached data
    cachedData = makeNestedValue(key.split('.'), [])
  }

  if (cachedData) {
    const keyValue = getNestedValue(cachedData, key.split('.'))
    let updatedList: ItemType[] = Array.isArray(keyValue) ? [...keyValue] : []

    if (strategy === CachedListUpdateStrategy.Append) {
      updatedList.push(newItem)
    } else if (strategy === CachedListUpdateStrategy.Prepend) {
      updatedList.unshift(newItem)
    }

    const writeQueryData = setNested(cachedData, key.split('.'), updatedList)

    await cache.writeQuery({
      query: writeQuery.query,
      variables: writeQuery.variables,
      data: writeQueryData,
    })
  }
}

export { updateCachedItem, updateCachedList }
