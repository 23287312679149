import React, { ReactNode } from 'react'

import { Div } from './PopOverItem.styles'

export type PopOverItemProps = {
  children?: ReactNode
}

const PopOverItem = ({ children = [] }: PopOverItemProps) => <Div data-tag="popover-item">{children}</Div>

export default PopOverItem
