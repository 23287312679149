const groupBy = <I extends Record<string, unknown>, K extends keyof I>(key: K, list: I[]) => {
  return list.reduce(
    (acc, curr) => {
      const index = curr[key] as K

      acc[index] = acc[index] || []

      acc[index].push(curr)

      return acc
    },
    {} as { [K in keyof I]: I[] },
  )
}

export default groupBy
