import { styled, Theme } from '@mui/material'

// Used when you have a two column layout but the left or right column is empty and you
// just have data in one of the columns. The data will expand to fit both columns on smaller screen.
export const HalfWidthTwoColumnCell = styled('div')(
  ({ theme }) => `
  background-color: ${theme.colours.foreground};
  padding: ${theme.spacing(4)};
  width: 50%;
  border-radius: ${theme.display.borderRadius};
  @media (max-width: ${theme.wrappers.largeBreakpoint}px) {
    width: 100%;
  }
`,
)

export const TwoColumnCell = styled('div')(
  ({ theme }) => `
  background-color: ${theme.colours.foreground};
  padding: ${theme.spacing(4)};
  border-radius: ${theme.display.borderRadius};
`,
)

const insideCardWithFooterStyles = (theme: Theme) => `
  border-top-left-radius: ${theme.display.borderRadius};
  border-top-right-radius: ${theme.display.borderRadius};

  & > ${TwoColumnCell}:last-of-type {
    border-top-right-radius: ${theme.display.borderRadius};
  }

  & > ${TwoColumnCell}:first-of-type {
    border-top-left-radius: ${theme.display.borderRadius};
  }

  @media (max-width: ${theme.wrappers.largeBreakpoint}px) {
    & > ${TwoColumnCell}:first-of-type {
      border-top-left-radius: ${theme.display.borderRadius};
      border-top-right-radius: ${theme.display.borderRadius};
    }

    & > ${TwoColumnCell}:last-of-type {
      border-top-right-radius: initial;
    }
  }
`

const insideCardStyles = (theme: Theme) => `
  border-radius: ${theme.display.borderRadius};

  & > ${TwoColumnCell}:last-of-type {
    border-top-right-radius: ${theme.display.borderRadius};
    border-bottom-right-radius: ${theme.display.borderRadius};
  }

  & > ${TwoColumnCell}:first-of-type {
    border-top-left-radius: ${theme.display.borderRadius};
    border-bottom-left-radius: ${theme.display.borderRadius};
  }

  @media (max-width: ${theme.wrappers.largeBreakpoint}px) {
    & > ${TwoColumnCell}:first-of-type {
      border-top-left-radius: ${theme.display.borderRadius};
      border-top-right-radius: ${theme.display.borderRadius};
      border-bottom-right-radius: initial;
      border-bottom-left-radius: initial;
    }

    & > ${TwoColumnCell}:last-of-type {
      border-top-right-radius: initial;
      border-top-left-radius: initial;
      border-bottom-left-radius: ${theme.display.borderRadius};
      border-bottom-right-radius: ${theme.display.borderRadius};
    }
  }
`

type TwoColumnLayoutProps = { insideCard?: boolean; insideCardWithFooter?: boolean }

export const TwoColumnLayout = styled('div')<TwoColumnLayoutProps>(
  ({ theme, insideCard, insideCardWithFooter }) => `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;

  @media (max-width: ${theme.wrappers.largeBreakpoint}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 1px;
  }

  ${insideCardWithFooter && insideCardWithFooterStyles}
  ${insideCard && insideCardStyles}

    & > div:not(:last-of-type) {
    padding-bottom: ${theme.spacing(4)};
  }

  & > div:not(:first-of-type) {
    padding-top: ${theme.spacing(4)};
  }
`,
)

export default TwoColumnLayout
