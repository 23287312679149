import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Icon, IconTypes, Tooltip } from '@acre/design-system'

import { StyledLabel } from './LabelWithTooltip.styles'

type Props = {
  labelKey: string
  tooltipKey: string
  spaceBelow?: boolean
}

const LabelWithTooltip = ({ labelKey, tooltipKey, spaceBelow }: Props) => {
  const theme = useTheme()

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <StyledLabel>
        <FormattedMessage id={labelKey} />
      </StyledLabel>

      <div style={{ marginBottom: spaceBelow ? theme.spacers.size8 : 'auto' }}>
        <Tooltip placement="right" content={<FormattedMessage id={tooltipKey} />}>
          <Icon name={IconTypes.Tooltip} />
        </Tooltip>
      </div>
    </Box>
  )
}

export default LabelWithTooltip
