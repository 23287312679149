import React, { ChangeEvent, FocusEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { isNil } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import theme from '../../theme'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import { preventNonNumericChars } from '../../utils/helpers'
import testHandle from '../../utils/testHandle'
import HelperText from '../HelperText'
import Label from '../Label'
import { trackEventFiltered } from '../UserTracker'
import { FormControlWrapper, LabelAndInputWrapper, StyledInputAddonRight } from '../../styles/form-control.styles'
import { StyledInput } from './MonthsInput.styles'

export type MonthsInputProps = {
  value?: number | null
  id?: string
  label?: string
  error?: boolean
  message?: ReactNode
  name?: string
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  tags?: string[]
  onlyMonths?: boolean
  onlyYears?: boolean
  onChange: (e: number | null) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  isMissing?: boolean
  variant?: Variant
}

const MonthsInput = ({
  id,
  label,
  error,
  message,
  name,
  disabled: disabledProp,
  ariaLabel,
  placeholder,
  value,
  tags,
  onlyMonths = false,
  onlyYears = false,
  onChange,
  onFocus,
  onBlur,
  isMissing,
  variant = 'default',
}: MonthsInputProps) => {
  const intl = useIntl()
  const className = classNames({ error })
  const componentId = id || 'MonthsInputComponent'

  const disabled = useFieldDisabledState(disabledProp)

  const [years, setYears] = useState<number | null>(null)
  const [months, setMonths] = useState<number | null>(null)
  const formatMessage = (id: string): string => intl.formatMessage({ id })

  const { trackEvent } = useMatomo()

  const location = useLocation()

  useEffect(() => {
    // adds values if exists
    if (value && !onlyYears) {
      const initialMonths = Number(value) % 12
      const initialYears = (Number(value) - initialMonths) / 12

      setYears(initialYears)
      setMonths(initialMonths)
    }

    // removes values on delete
    // if (!value && value !== 0 && !onlyYears) {
    //   setMonths(null)
    //   setYears(null)
    // }
  }, [value, onlyYears])

  const handleYearsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.target.value ? setYears(parseInt(e.target.value)) : setYears(null)
  }, [])

  const handleMonthsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.target.value ? setMonths(parseInt(e.target.value)) : setMonths(null)
  }, [])

  // We want the calculations to happen onBlur after changing the input
  const handleYearsBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (years !== null) {
        const yearsMultiplier = onlyYears ? 1 : 12
        onChange((years || 0) * yearsMultiplier + (months || 0))
      } else {
        if (months) setYears(0)
        onChange(months)
      }
      onBlur && onBlur(e)
      trackEventFiltered(trackEvent, location, 'YearsChange', id)
    },
    [months, onBlur, onChange, onlyYears, years, id, location, trackEvent],
  )

  const handleMonthsBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (months !== null) {
        onChange((years || 0) * 12 + (months || 0))
      } else {
        if (years) setMonths(0)
        onChange(years && years * 12)
      }
      onBlur && onBlur(e)
      trackEventFiltered(trackEvent, location, 'MonthsChange', id)
    },
    [months, onBlur, onChange, years, id, location, trackEvent],
  )

  const handleMonthsFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (months === 0) {
        setMonths(null)
      }
      trackEventFiltered(trackEvent, location, 'MonthsFocus', id)

      onFocus && onFocus(e)
    },
    [id, location, months, onFocus, trackEvent],
  )

  const handleYearsFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (years === 0) {
        setYears(null)
      }

      onFocus && onFocus(e)
    },
    [onFocus, years],
  )

  return (
    <>
      <FormControlWrapper id={componentId} data-testid={testHandle(componentId)} className={className}>
        <LabelAndInputWrapper variant={variant}>
          {label && (
            <Label
              isMissing={isNil(value) || (value.toString() === '' && isMissing)}
              htmlFor="YearsInput"
              text={label}
            />
          )}
          <Box display="flex">
            {!onlyMonths && (
              <Box
                display="flex"
                mr={
                  variant === 'compact' || variant === 'compactLeftAligned' ? theme.spacers.size8 : theme.spacers.size32
                }
                width="100%"
              >
                <StyledInput
                  id={id || 'YearsInput'}
                  name={name}
                  disabled={disabled}
                  type="number"
                  value={years === null ? '' : years}
                  onBlur={handleYearsBlur}
                  onFocus={handleYearsFocus}
                  onChange={handleYearsChange}
                  variant={variant}
                  aria-label={ariaLabel}
                  placeholder={placeholder}
                  data-testid={testHandle('YearsInput')}
                  className={className}
                  onKeyDown={preventNonNumericChars}
                  autoComplete="off"
                />
                <StyledInputAddonRight
                  variant={variant}
                  htmlFor="YearsInput"
                  width={variant === 'compact' || variant === 'compactLeftAligned' ? theme.spacers.size64 : undefined}
                >
                  {tags ? tags[0] : formatMessage('generic.years')}
                </StyledInputAddonRight>
              </Box>
            )}
            {!onlyYears && (
              <Box display="flex" width="100%">
                <StyledInput
                  id="MonthsInput"
                  name={name}
                  disabled={disabled}
                  type="number"
                  value={months === null ? '' : months}
                  onBlur={handleMonthsBlur}
                  onFocus={handleMonthsFocus}
                  onChange={handleMonthsChange}
                  aria-label={ariaLabel}
                  placeholder={placeholder}
                  data-testid={testHandle('MonthsInput')}
                  className={className}
                  onKeyDown={preventNonNumericChars}
                  autoComplete="off"
                  variant={variant}
                />
                <StyledInputAddonRight
                  variant={variant}
                  htmlFor="MonthsInput"
                  width={variant === 'compact' || variant === 'compactLeftAligned' ? theme.spacers.size64 : undefined}
                >
                  {tags ? tags[1] : formatMessage('generic.months')}
                </StyledInputAddonRight>
              </Box>
            )}
          </Box>
        </LabelAndInputWrapper>

        {message && (
          <HelperText
            id={`${id}Helper`}
            variant={variant}
            message={message || ''}
            textType={error ? HELPER_TYPE_ERROR : ''}
          />
        )}
      </FormControlWrapper>
      {isMissing && value?.toString() === '' && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </>
  )
}

export default withDisabled(MonthsInput)
