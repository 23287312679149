import React, { useState } from 'react'
import {
  ButtonProps,
  IconButtonProps,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItemProps,
  MenuProps,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { testHandle } from '@acre/utils'

import FeatherIcon from '../components/FeatherIcon/FeatherIcon'
import { IconName } from '../components/FeatherIcon/FeatherIcon.types'
import MenuItem from '../components/MenuItem/MenuItem'
import MenuItemLink from '../components/MenuItemLink/MenuItemLink'
import { Colour, Size } from '../utils/types'

export type UseButtonMenuProps = {
  ariaId?: string
  menu: (MenuItemProps & {
    testId?: string
    icon?: React.ReactNode
    to?: string
    externalLink?: boolean
    openInNewTab?: boolean
    danger?: boolean
    onClose?: () => void
  })[]
  MenuComponent?: React.ComponentType<MenuProps>
  size?: 'medium' | 'large'
}

const sequence =
  <FN extends (...args: any[]) => any>(...fns: FN[]) =>
  (...args: Parameters<FN>) =>
    fns.reduce((acc, fn) => fn(...args), undefined as ReturnType<FN>)

const useButtonMenu = ({ ariaId: ariaIdProp, menu, MenuComponent = MuiMenu, size = 'medium' }: UseButtonMenuProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const ariaId = ariaIdProp || 'basic-menu'

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const buttonProps = {
    'aria-controls': open ? ariaId : undefined,
    'aria-haspopup': 'true',
    'aria-expanded': open ? 'true' : undefined,
    onClick: handleClick,
  } satisfies Partial<ButtonProps | IconButtonProps>

  const navigate = useNavigate()

  const customStyles = { padding: theme.spacing(size === 'large' ? 2 : 1), lineHeight: '1.2' }

  const Menu = (props: Omit<MenuProps, 'id' | 'anchorEl' | 'open' | 'onClose' | 'MenuListProps' | 'children'> & {}) => (
    <MenuComponent
      MenuListProps={{
        'aria-labelledby': ariaIdProp,
      }}
      {...props}
      id={`${ariaId}-menu`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {menu.map(({ onClick, onClose: handleCloseTab, icon, children, ...item }, index) => {
        let _children = children

        if (icon || handleCloseTab) {
          _children = (
            <>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{children}</ListItemText>
              {handleCloseTab && (
                <ListItemButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClose()
                    handleCloseTab()
                  }}
                >
                  <FeatherIcon name={IconName.X} colour={Colour.Red} size={Size.Small} />
                </ListItemButton>
              )}
            </>
          )
        }

        if (item.to) {
          const handleLinkClick = () => {
            navigate(item.to as string)
          }

          if (item.externalLink) {
            return (
              <MenuItemLink
                key={item.id}
                sx={{ padding: 0 }}
                {...item}
                data-testid={item.testId || (item.id ? testHandle(item.id) : undefined)}
              >
                <Link
                  href={item.to}
                  target={item.openInNewTab ? '_blank' : ''}
                  style={{
                    ...customStyles,
                    textDecoration: 'none',
                    color: 'inherit',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {_children}
                </Link>
              </MenuItemLink>
            )
          }

          return (
            <MenuItemLink
              sx={customStyles}
              {...item}
              onClick={
                onClick ? sequence(onClick, handleClose, handleLinkClick) : sequence(handleClose, handleLinkClick)
              }
              key={index}
              data-testid={testHandle(item.id!)}
            >
              {_children}
            </MenuItemLink>
          )
        }

        return (
          <MenuItem
            {...item}
            data-testid={testHandle(item.id!)}
            onClick={onClick ? sequence(onClick, handleClose) : handleClose}
            key={index}
            sx={customStyles}
          >
            {_children}
          </MenuItem>
        )
      })}
    </MenuComponent>
  )

  return { buttonProps, Menu: React.memo(Menu) }
}

export default useButtonMenu
