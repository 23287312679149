import React, { ReactElement } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { Card, Table, Tbody, Td, Th, Thead, Tr } from '@acre/design-system'

import { RecommendProductTooltip } from './RecommendProductTooltip'
import { ButtonsWrapper, ButtonsWrapperVertical, TableWrapper, Title } from './TableCard.styles'

type Props = {
  tableHeaders: string[]
  id?: string
  mainImage?: string
  mainIcon?: ReactElement
  buttons?: ReactElement
  title?: string
  subTitle?: string | null
  tableData?: any[]
  hideTable?: boolean
  hideHeader?: boolean
  dataInPlaceOfTable?: ReactElement | null
  extraData?: ReactElement | null
  isRecommendDisabled?: boolean
  onCardHeaderClick?: (e: MouseEvent) => void
}

const TableCard = ({
  id,
  mainIcon,
  mainImage,
  title,
  subTitle,
  buttons,
  tableHeaders,
  tableData,
  hideTable,
  dataInPlaceOfTable,
  isRecommendDisabled,
  extraData,
  onCardHeaderClick,
  hideHeader = false,
}: Props) => {
  const theme = useTheme()
  const mediaQueryLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const mediaQueryMedium = useMediaQuery('(min-width: 1100px')

  return (
    <Card border={false} padding="0" id={id || 'TableCard'} data-testid={testHandle(id || 'TableCard')}>
      {!hideHeader && (
        <Box
          display="flex"
          justifyContent="space-between"
          py={2}
          px={4}
          borderBottom={`1px solid ${theme.colours.baseLight}`}
          data-testid={testHandle(`${id}Header` || 'TableCardHeader')}
          onClick={onCardHeaderClick as React.MouseEventHandler<HTMLDivElement> | undefined}
          style={{ gap: theme.spacing(2), cursor: onCardHeaderClick && 'pointer' }}
        >
          <Box display="flex" alignItems="center">
            {mediaQueryLarge && mainImage && <img src={mainImage} alt={title} />}
            {mainIcon && mainIcon}
            <Box ml={(!mainImage || !mediaQueryLarge) && !mainIcon ? 0 : 2}>
              <Box mb={0.5}>
                <Title data-testid={testHandle(`${id}Title` || 'TableCardTitle')}>{title}</Title>
              </Box>
              <Box data-testid={testHandle(`${id}SubTitle` || 'TableCardSubTitle')}>{subTitle}</Box>
              {isRecommendDisabled && (
                <Box mt={0.5}>
                  <RecommendProductTooltip />
                </Box>
              )}
            </Box>
          </Box>
          {mediaQueryMedium ? (
            <ButtonsWrapper>{buttons}</ButtonsWrapper>
          ) : (
            <ButtonsWrapperVertical>{buttons}</ButtonsWrapperVertical>
          )}
        </Box>
      )}
      {!hideTable ? (
        <>
          <TableWrapper>
            <Table
              gutterPadding="size32"
              rowPadding={extraData ? 'size8' : 'size16'}
              highlight={false}
              id={`${id || 'Table'}Table`}
              borderBottom={Boolean(extraData)}
            >
              {mediaQueryMedium ? (
                <>
                  <Thead fontSize={12}>
                    <Tr>
                      {tableHeaders.map((header, index) => (
                        <Th verticalAlign="top" key={index}>
                          <FormattedMessage id={header} />
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody id={`${id}TableBody` || 'TableCardTableBody'} fontSize={14}>
                    <Tr>
                      {tableData
                        ? tableData.map((row, index) => (
                            <Td id={`${id || 'TableCard'}Cell${index}`} key={index}>
                              {row || '-'}
                            </Td>
                          ))
                        : tableHeaders?.map((_, index) => (
                            <Td id={`${id || 'TableCard'}Cell${index}`} key={index}>
                              -
                            </Td>
                          ))}
                    </Tr>
                  </Tbody>
                </>
              ) : (
                <Tbody id={`${id}TableBody` || 'TableCardTableBody'} fontSize={14}>
                  {tableData
                    ? tableData.map((row, index) => (
                        <Tr key={`tr-${index}`}>
                          <Th key={`th-${index}`} style={{ paddingLeft: theme.spacing(4) }}>
                            <FormattedMessage id={tableHeaders[index]} />
                          </Th>
                          <Td id={`${id || 'TableCard'}Cell${index}`} key={index}>
                            {row || '-'}
                          </Td>
                        </Tr>
                      ))
                    : tableHeaders?.map((_, index) => (
                        <Tr key={`tr-u-${index}`}>
                          <Td id={`${id || 'TableCard'}Cell${index}`} key={index}>
                            -
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              )}
            </Table>
          </TableWrapper>
          {extraData && (
            <Box px={4} pt={2}>
              {extraData}
            </Box>
          )}
        </>
      ) : (
        <>{dataInPlaceOfTable}</>
      )}
    </Card>
  )
}

export default TableCard
