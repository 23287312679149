export const getPathnameSuffix = (pathname: string) => {
  const contentBetweenSlashes = /[^/]+(?=\/$)/
  const match = pathname.match(contentBetweenSlashes)

  if (match) {
    return match[0]
  } else {
    const contentAfterFinalSlash = /[^/]+$/
    const matchAfterFinalSlash = pathname.match(contentAfterFinalSlash)

    return matchAfterFinalSlash ? matchAfterFinalSlash[0] : null
  }
}
