import { SidebarSection, useSidebarDrawerSectionsLocalStorage } from './CollapsableSection.helpers'
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  AccordionSubHeader,
  AccordionSummary,
} from './CollapsableSection.styles'

type Props = {
  children: JSX.Element
  sectionId: SidebarSection
  header: string
  subHeader?: string
}

const CollapsableSection = ({ children, header, subHeader, sectionId }: Props) => {
  const { localValues, updateLocalStorage } = useSidebarDrawerSectionsLocalStorage(sectionId)

  return (
    <Accordion
      defaultExpanded={true}
      expanded={Boolean(localValues.sectionsOpen[sectionId])}
      onChange={(_, expanded) => updateLocalStorage({ sectionsOpen: { [sectionId]: expanded } })}
    >
      <AccordionSummary>
        <AccordionHeader>{header}</AccordionHeader>
      </AccordionSummary>
      {subHeader && <AccordionSubHeader>{subHeader}</AccordionSubHeader>}
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default CollapsableSection
