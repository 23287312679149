import { styled } from '@mui/material'

import { Button } from '@acre/design-system'

export const InputTableBuilderTd = styled('div')`
  height: 100%;
  width: 100%;
`

export const RemoveButtonStyle = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > svg {
    width: 16px;
  }
`
