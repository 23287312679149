import { styled, Theme } from '@mui/material'

import { Variant } from '../../utils/constants'

const downArrowIcon =
  "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3535 3.64642L14.7678 5.06064L8.06065 11.7677L1.35355 5.06064L2.76776 3.64642L8.06065 8.93932L13.3535 3.64642Z' fill='%23333333'/%3E%3C/svg%3E%0A"

const compactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
  font-size: 14px;
  padding-left: ${theme.spacing(1.5)};
  background-position: right 10px top 50%;
`

export const Select = styled('select')<{
  isLoading: boolean
  variant: Variant
  placeholderColour: keyof Theme['colours']
}>(
  ({ theme, isLoading, variant, placeholderColour }) => `
  position: relative;
  color: ${theme.colours.base};
  border-radius: ${theme.display.borderRadius};

  background-color: ${theme.colours.foreground};
  background-image: ${isLoading ? 'none' : `url("${downArrowIcon}")`};
  background-position: right 14px top 50%;
  background-repeat: no-repeat;

  outline: none;
  width: 100%;
  border: none;
  font-family: ${theme.typography.fontFamily};
  font-size: 16px;
  box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.baseLight} inset;

  display: flex;
  align-items: center;
  height: ${theme.display.inputHeight};
  padding-left: ${theme.spacers.size16};
  padding-right: ${theme.spacers.size32};
  transition: all 0.1s ease-in-out;

  -webkit-appearance: none;
  -moz-appearance: none;

  box-sizing: border-box;

  &::-ms-expand {
    display: none;
  }

  &.empty {
    color: ${theme.colours[placeholderColour]};

    option {
      color: ${theme.colours.base};
    }
  }

  &.error {
    box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.danger} inset;
  }

  &.success {
    box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.successColor}
      inset;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.primary} inset;
  }

  &.disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
    box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.baseLight} inset;
  }

  &:disabled {
    background-color: ${theme.colours.baseExtraLight};
    color: ${theme.colours.baseMid};
    cursor: not-allowed;
    box-shadow: 0 0 0 ${theme.display.inputBorderWidth} ${theme.colours.baseLight} inset;
  }

  ${variant.includes('compact') && compactStyles(theme)}
`,
)

export const SpinnerWrapper = styled('span')(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(1)};
  z-index: ${theme.display.overflowZIndex};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`,
)
