import React, { useMemo } from 'react'
import { usePermissionsContext } from '@broker-crm-contexts'
import { Box, useTheme } from '@mui/material'
import { isWithinInterval, max, parseISO, subMonths } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { components } from 'react-select'
import { OptionProps } from 'react-select/src/components/Option'

import { getInitialCharacter, PREFERENCE_MAP } from '@acre/utils'
import {
  CaseStatus,
  GateName,
  GetCaseQuery,
  GetOrganisationQuery,
  GetUserQuery,
  MortgageReason,
  SearchResultItem,
} from '@acre/graphql'
import { CaseStatusTag, ColourId, FeatherIcon, IconName, Tag, UserAvatar } from '@acre/design-system'

import { randomColourIdGenerator } from './SearchComponents.helpers'
import { ARFloat, ClientDetails, PillContainer, SmallText, Tags } from './SearchComponents.styles'

export const CaseOption = (props: OptionProps<[GetOrganisationQuery & GetUserQuery & GetCaseQuery], true>) => {
  const theme = useTheme()

  const data = props?.data

  if (data?.case && data?.user && data?.organisation) {
    const userName = `${data.user.first_name || ''} ${data.user.last_name || ''}`

    const organisationName = data.organisation.name || ''

    const initial = data.user?.first_name ? getInitialCharacter({ word: data.user?.first_name }) : ''

    const reason = data.case?.details?.mortgage_reason as MortgageReason
    const mortgageReason = reason ? PREFERENCE_MAP[reason] : ''

    return (
      <components.Option {...props}>
        <Box my={theme.spacing(1)} mr={theme.spacing(1)}>
          <UserAvatar initials={initial} size="size32" />
        </Box>
        <ClientDetails className="details">
          <Box>
            {userName} - {organisationName}
          </Box>
          <Box
            style={{
              fontSize: theme.typography.pxToRem(14),
            }}
          >
            {mortgageReason ? <FormattedMessage id={mortgageReason} /> : null}
          </Box>
        </ClientDetails>
      </components.Option>
    )
  }
  return (
    <components.Option {...props}>
      <FormattedMessage id="generic.nothingToShow" />
    </components.Option>
  )
}

const STATUS_INTL = {
  [MortgageReason.ReasonFtb]: 'firstTimeBuy',
  [MortgageReason.ReasonHouseMove]: 'houseMove',
  [MortgageReason.ReasonRemortgage]: 'remortgage',
  [MortgageReason.ReasonBtl]: 'buyToLet',
  [MortgageReason.ReasonBtlRemortgage]: 'buyToLetRemortgage',
  [MortgageReason.ReasonEquityRelease]: 'equityRelease',
  [MortgageReason.ReasonFurtherAdvance]: 'furtherAdvance',
  [MortgageReason.ReasonBusinessProtection]: 'businessProtection',
  [MortgageReason.ReasonProtection]: 'protection',
  [MortgageReason.InvalidMortgageReason]: 'noReasonProvided',
  [MortgageReason.ReasonCommercial]: 'commercial',
  [MortgageReason.ReasonBridging]: 'bridging',
  [MortgageReason.ReasonGeneralInsurance]: 'homeInsurance',
}

const STATUS_TAG_COLOUR = {
  [MortgageReason.ReasonFtb]: ColourId.LightBlue,
  [MortgageReason.ReasonHouseMove]: ColourId.LightGreen,
  [MortgageReason.ReasonRemortgage]: ColourId.LightPurple,
  [MortgageReason.ReasonBtl]: ColourId.SkyBlue,
  [MortgageReason.ReasonBtlRemortgage]: ColourId.LightPurple,
  [MortgageReason.ReasonEquityRelease]: ColourId.LightOrange,
  [MortgageReason.ReasonFurtherAdvance]: ColourId.LightOrange,
  [MortgageReason.ReasonBusinessProtection]: ColourId.LightOrange,
  [MortgageReason.ReasonProtection]: ColourId.LightRed,
  [MortgageReason.InvalidMortgageReason]: ColourId.LightOrange,
  [MortgageReason.ReasonCommercial]: ColourId.LightOrange,
  [MortgageReason.ReasonBridging]: ColourId.LightOrange,
  [MortgageReason.ReasonGeneralInsurance]: ColourId.LightOrange,
}

const CaseReasonTag = ({ reason }: { reason: MortgageReason }) => {
  const { formatMessage } = useIntl()
  return (
    <Tag
      id="CaseOverviewCaseType"
      text={formatMessage({ id: `productTypes.${STATUS_INTL[reason]}` })}
      hasMargin={false}
      colourID={STATUS_TAG_COLOUR[reason]}
    />
  )
}

export const reduceClientCases = (client?: SearchResultItem) =>
  client?.Cases?.reduce(
    ([activeCases, completedCases, deadCases], caseObj) => {
      switch (caseObj?.case_status) {
        case CaseStatus.Complete:
          completedCases++
          break
        case CaseStatus.NotProceeding:
          deadCases++
          break
        case CaseStatus.InvalidStatus:
          break
        default:
          activeCases++
          break
      }
      return [activeCases, completedCases, deadCases]
    },
    [0, 0, 0],
  ) || [0, 0, 0]

const ClientActiveOption = (props: OptionProps<any, false>) => {
  const theme = useTheme()
  const permissions = usePermissionsContext()
  const hasEditClientRestrictedPermission = Boolean(permissions?.gates?.includes(GateName.EDIT_CLIENT_RESTRICTED))
  const client = props.data as SearchResultItem

  const [tags, pills, name, identifierItems, regFirm] = useMemo(() => {
    const modifiedDates = client.modified_at ? [parseISO(client.modified_at)] : []
    const createdDates = client.created_at ? [parseISO(client.created_at)] : []
    client.Cases?.forEach((caseObj) => {
      caseObj?.modified_at && modifiedDates.push(parseISO(caseObj.modified_at))
      caseObj?.created_at && createdDates.push(parseISO(caseObj.created_at))
    })

    const [activeCases, completedCases, deadCases] = reduceClientCases(client)

    const lastModified = max(modifiedDates)
    const created = max(createdDates)

    const threeMonthsAgo = subMonths(new Date(), 3)
    const relevancyInterval = {
      start: threeMonthsAgo,
      end: new Date(),
    }
    const modifiedRecently = isWithinInterval(lastModified, relevancyInterval)
    const createdRecently = isWithinInterval(created, relevancyInterval)
    const activeCasesPills =
      activeCases == 1
        ? [
            client.Cases?.[0]?.case_reason ? (
              <CaseReasonTag reason={client.Cases?.[0]?.case_reason} key={`reason_tag_${client.Cases?.[0]?.id}`} />
            ) : null,
            client.Cases?.[0]?.case_status ? (
              <CaseStatusTag status={client.Cases?.[0]?.case_status} key={`status_tag_${client.Cases?.[0]?.id}`} />
            ) : null,
          ]
        : activeCases > 1
          ? [<Tag colourID={ColourId.LightRed} text={`${activeCases} active cases`} key={`cases_tag_${client.id}`} />]
          : []

    let completedCasesText = null
    if (completedCases > 0 && activeCases == 0) {
      completedCasesText = <Tag colourID={ColourId.Green} text="Completed" />
    }
    const dead =
      deadCases > 0 && activeCases == 0 && completedCases == 0
        ? [
            client.Cases?.[0]?.case_reason ? (
              <CaseReasonTag reason={client.Cases?.[0]?.case_reason} key={`reason_tag_${client.Cases?.[0]?.id}`} />
            ) : null,
            <CaseStatusTag status={client.Cases?.[0]?.case_status} key={`status_tag_${client.Cases?.[0]?.id}`} />,
          ]
        : []
    const name = client.organisation_name ? client.organisation_name : `${client.first_name} ${client.last_name}`
    const identifierItems = []
    if (client.email && !hasEditClientRestrictedPermission) identifierItems.push(client.email)
    if (client.postcode) identifierItems.push(client.postcode)
    if (client.Properties?.length && client.Properties?.[0]?.postcode !== client.postcode)
      identifierItems.push(client.Properties?.[0]?.postcode)
    let regFirm = ''
    if (client.regulating_org_id && client.owning_org_id !== client.regulating_org_id && client.owning_org_name) {
      regFirm = client.owning_org_name
    }

    return [
      `${modifiedRecently && !createdRecently ? 'Changed' : ''}${createdRecently ? 'New' : ''}`,
      [...activeCasesPills, completedCasesText, ...dead].filter((e) => e != null),
      name,
      identifierItems,
      regFirm,
    ]
  }, [client, hasEditClientRestrictedPermission])
  return (
    <Box
      {...props.innerProps}
      style={{
        width: '100%',
        borderBottom: `1px solid ${theme.colours.grey}`,
        display: 'flex',
        flexDirection: 'row',
        color: props.isFocused ? theme.colours.baseMid : theme.colours.base,
        backgroundColor: props.isFocused ? theme.colours.primary10 : undefined,
        minHeight: '80px',
      }}
      p={1}
      pt={3}
    >
      <Box mr={1}>
        <UserAvatar
          initials={name
            .split(/\s/)
            .map((x) => x.charAt(0))
            .join('')
            .toUpperCase()
            .substring(0, 2)}
          colourId={randomColourIdGenerator(name)}
          size="size32"
        />
      </Box>
      <Box style={{ flex: 1 }}>
        <ClientDetails className="details">
          <ARFloat>{regFirm}</ARFloat>
          <Box style={{ fontSize: theme.typography.pxToRem(14) }}>
            {name} <Tags>{tags}</Tags>
          </Box>
          <Box style={{ fontSize: theme.typography.pxToRem(12) }}>{identifierItems.join(' | ')}</Box>
          <PillContainer>{pills}</PillContainer>
        </ClientDetails>
      </Box>
    </Box>
  )
}

export const ClientOption = (props: OptionProps<any, false>) => {
  const theme = useTheme()
  if (props?.data?.newClientOption) {
    return (
      <components.Option {...props} key="CreateClient">
        <Box m={theme.spacing(1)}>
          <FeatherIcon name={IconName.UserPlus} />
        </Box>
        <Box>
          <FormattedMessage id="search.createANewClient" />
        </Box>
      </components.Option>
    )
  }
  if (props?.data?.more) {
    return (
      <components.Option {...props}>
        <SmallText>And {props?.data?.more} more results</SmallText>
      </components.Option>
    )
  }
  if (!props?.data) {
    return (
      <components.Option {...props}>
        <FormattedMessage id="generic.nothingToShow" />
      </components.Option>
    )
  }
  return <ClientActiveOption {...props}>{props.children}</ClientActiveOption>
}
