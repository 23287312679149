import { ReactNode } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Stack, useTheme } from '@mui/material'

import { testHandle } from '@acre/utils'

import { caseSidebarDrawWidth } from '../../theme/theme.mui'
import { iconStyle, useSidebarDrawerOpenLocalStorage } from './CaseSidebarDrawer.helpers'
import { Drawer, StyledIconButton } from './CaseSidebarDrawer.styles'

type Props = {
  children: ReactNode
  open: boolean
  toggleDrawerOpen: () => void
}

const CaseSidebarDrawer = ({ children, open, toggleDrawerOpen }: Props) => {
  const theme = useTheme()

  const { sideBarOpenLocalStorage, updateLocalStorage } = useSidebarDrawerOpenLocalStorage(open, toggleDrawerOpen)

  return (
    <Drawer variant="permanent" open={sideBarOpenLocalStorage} anchor="right">
      {/* Box to take up space of nav bar */}
      <Box minHeight={theme.spacing(6)} />
      <Box position="relative">
        <StyledIconButton
          onClick={() => {
            updateLocalStorage(!sideBarOpenLocalStorage)
            toggleDrawerOpen()
          }}
          data-testid={testHandle('SidebarButton')}
        >
          {sideBarOpenLocalStorage ? <ChevronRightIcon sx={iconStyle} /> : <ChevronLeftIcon sx={iconStyle} />}
        </StyledIconButton>
      </Box>
      <Stack pt={1} width={caseSidebarDrawWidth} sx={{ overflowY: 'scroll' }}>
        {children}
      </Stack>
    </Drawer>
  )
}

export default CaseSidebarDrawer
