import { useMemo } from 'react'
import { userType } from '@broker-crm-utils/constants'

import { Maybe, User } from '@acre/graphql'

import { checkIfFirstTimeUser } from '../pages/Onboarding/Onboarding.helpers'

const useShouldOnboard = (user: Maybe<User>) =>
  useMemo(() => {
    if (!user) {
      return false
    }

    const { isFirstTimeUsingAcre } = checkIfFirstTimeUser(user)

    return (
      isFirstTimeUsingAcre &&
      ![userType.readOnlySupport, userType.modifySupport].includes(user?.primary_role_id as string)
    )
  }, [user])

export default useShouldOnboard
