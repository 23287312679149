import { MutableRefObject, useEffect, useRef } from 'react'

const useClickOutsideListener = (
  element: MutableRefObject<any>,
  handler: (() => void) | ((e: MouseEvent) => void),
  shouldRun: boolean = true,
) => {
  const savedHandler: MutableRefObject<any> = useRef()

  const checkElementContains = (e: MouseEvent) => {
    // Check both the ref and the 'current' property, as the ref
    // may persist when the current prop does not
    if (!element || !element.current) return

    const containsTarget = element.current.contains(e.target)
    if (!containsTarget) handler(e)
  }

  // Save the handler, and only update if it is changed
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = (e: MouseEvent) => checkElementContains(e)
    // Only create the handler if we actually want to listen at this time
    if (shouldRun) {
      window.addEventListener('mousedown', eventListener)
      return () => window.removeEventListener('mousedown', eventListener)
    } else window.removeEventListener('mousedown', eventListener)
    return () => {}
    // If the element ref changes or we decide to
    // start/stop listening, re-run this effect
  }, [element, shouldRun])
}

export default useClickOutsideListener
