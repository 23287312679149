import { NetworkError } from '@apollo/client/errors'
import { AxiosError } from 'axios'
import { GraphQLError, GraphQLFormattedError } from 'graphql'

import { LutherException } from '@acre/graphql'

export const isForbiddenError = <
  T extends 'graphql' | 'network',
  E extends T extends 'graphql' ? GraphQLFormattedError : NetworkError,
>(
  err: E,
  type: T,
) => {
  if (type === ('graphql' as const)) {
    const { extensions, originalError } = err as GraphQLError

    if (extensions.status === 401 || (originalError as LutherException)?.statusCode === 401) {
      return true
    }

    if (originalError && Object.prototype.hasOwnProperty.call(originalError, 'response')) {
      const { response } = originalError as AxiosError

      return response && response.status === 401
    } else if ((originalError as LutherException)?.statusCode === 401) {
      return true
    } else if (originalError && originalError.name === 'LutherException') {
      const { statusCode } = originalError as LutherException

      return statusCode === 401
    }
  }
  return false
}

export const statusCodes = [400, 401, 403]
