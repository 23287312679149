import React from 'react'
import { useQuery } from '@apollo/client'
import { useCaseContext } from '@broker-crm-contexts'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'

import {
  getClientName,
  GetClientsDocument,
  GetClientsQuery,
  GetClientsQueryVariables,
  isNaturalClient,
  isNonNaturalClient,
} from '@acre/graphql'
import { Checkbox } from '@acre/design-system'

import { getIddClients } from './InitialDisclosure.helpers'
type Props = {
  clientIds: string[]
  checkedClientIds: string[]
  setCheckedClientIds: React.Dispatch<React.SetStateAction<string[]>>
}

const InitialDisclosureCheckboxes = ({ clientIds, checkedClientIds, setCheckedClientIds }: Props) => {
  const theme = useTheme()
  const { details } = useCaseContext()

  const { data } = useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, {
    variables: {
      ids: clientIds,
    },
  })

  // get all nonNaturalClientIds from the clientIds passed in
  const nonNaturalClientIds = data?.clients.reduce((acc, client) => {
    return isNonNaturalClient(client.details) ? [...acc, client.details.id] : acc
  }, [] as string[])

  // if on case, get all natural clients on that case (as not all clientIds will be passed into this component)
  const naturalClientIds =
    details?.clients?.filter((client) => isNaturalClient(client.details)).map(({ id }) => id) || []

  const toggle = (id: string) => {
    const isNonNatural = nonNaturalClientIds?.includes(id)

    if (checkedClientIds.includes(id)) {
      // Remove all ids from checked ids, if non-natural client has been de-selected
      if (isNonNatural) {
        setCheckedClientIds([])
      } else {
        setCheckedClientIds(checkedClientIds.filter((clientId) => clientId !== id))
      }
    } else {
      if (isNonNatural) {
        setCheckedClientIds([...naturalClientIds, ...clientIds])
        // Acccept all client idds, if non-natural client has been checked off
      } else {
        setCheckedClientIds([...checkedClientIds, id])
      }
    }
  }

  const clients = data?.clients && getIddClients(data.clients)

  return (
    <Box display="flex">
      {clients?.map((client, index) => {
        const { id, details } = client
        const name = getClientName({ client: details })

        return (
          <Box key={id} mr={theme.spacers.size16}>
            <Checkbox
              id={`Client${index}`}
              label={name}
              checked={checkedClientIds.includes(id)}
              onChange={() => toggle(id)}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default InitialDisclosureCheckboxes
