import { useEffect, useMemo, useState } from 'react'
import usePreviewMode from '@broker-crm-common/hooks/usePreviewMode'
import { Box } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Feature, FlagType, useFlag, useFormatMessage } from '@acre/utils'
import { Maybe, useGetCaseVersionsLazyQuery } from '@acre/graphql'
import { Switch, SwitchVariant } from '@acre/design-system'

import DocumentNavigation from './DocumentNavigation'
import CaseInfo from './Info/CaseInfo'
import PageNotes from './PageNotes'
import RelatedCases from './RelatedCases'
import Tasks from './Tasks'
import VersionNavigation from './VersionNavigation'
import { SectionWrapper } from './ReviewModeDrawer.styles'

const ReviewModeDrawer = () => {
  const navigate = useNavigate()
  const formatMessage = useFormatMessage()
  const location = useLocation()

  const caseSidebarDrawerFlag = useFlag([FlagType.Feature, Feature.CaseSidebarDrawer], false)

  const { active: previewModeEnabled } = usePreviewMode()

  const { caseId, version } = useParams<{ caseId: string; version?: string }>()

  const [reviewModeOn, setReviewModeOn] = useState(false)

  useEffect(() => {
    if (!location.pathname.includes(`cases/${caseId}/review-mode`)) {
      setReviewModeOn(false)
    } else {
      setReviewModeOn(true)
    }
  }, [caseId, location.pathname])

  const displayVersionNav = useMemo(
    () => location.pathname.includes(`cases/${caseId}/review-mode/version`),
    [caseId, location.pathname],
  )

  // Exclude mortgage grid page for esis docs
  const displayDocNav = useMemo(
    () =>
      location.pathname.includes(`cases/${caseId}/review-mode/documents/`) &&
      !location.pathname.includes(`cases/${caseId}/review-mode/documents/ESIS/list`),
    [caseId, location.pathname],
  )

  const displayToggle = useMemo(
    () => previewModeEnabled || caseSidebarDrawerFlag,
    [caseSidebarDrawerFlag, previewModeEnabled],
  )

  const [getCaseVersions] = useGetCaseVersionsLazyQuery()

  const onSwitch = async (caseId: string, version?: Maybe<string>) => {
    if (reviewModeOn) {
      setReviewModeOn(false)
      return navigate(`/cases/${caseId}/overview`)
    }

    if (version) {
      setReviewModeOn(true)
      return navigate(`/cases/${caseId}/review-mode/version/${version}/fact-find`)
    }

    const { data } = await getCaseVersions({ variables: { id: caseId! } })

    if (data?.caseVersions?.cases?.length) {
      setReviewModeOn(true)
      return navigate(`/cases/${caseId}/review-mode/version/${data?.caseVersions?.cases?.length}/fact-find`)
    }
  }

  return (
    <>
      <SectionWrapper>
        <Box mb={2}>
          <CaseInfo />
        </Box>
        {displayToggle && (
          <Switch
            variant={SwitchVariant.Large}
            id="ToggleReviewMode"
            checked={reviewModeOn}
            disabled={false}
            onChange={() => caseId && onSwitch(caseId, version)}
            label={formatMessage(`cases.reviewMode.${reviewModeOn ? `reviewModeOn` : `reviewModeOff`}`)}
          />
        )}
      </SectionWrapper>
      {displayVersionNav && <VersionNavigation caseId={caseId!} version={version} />}
      {displayDocNav && <DocumentNavigation caseId={caseId!} />}
      {!reviewModeOn && (
        <>
          <PageNotes />
          <Tasks />
        </>
      )}
      <RelatedCases />
    </>
  )
}

export default ReviewModeDrawer
