import { styled } from '@mui/material'

export const DocumentContainerStyle = styled('div')`
  color: ${({ theme }) => theme.colours.base};

  & h1,
  h2 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 12px !important;
    margin-bottom: ${({ theme }) => theme.spacers.size4};
  }

  & p {
    font-size: 12px !important;
    line-height: 1.6;
    margin-bottom: ${({ theme }) => theme.spacers.size8};
  }

  & li {
    font-size: 12px !important;
    margin-bottom: ${({ theme }) => theme.spacers.size4} !important;
  }
`

export const ConfirmationMessage = styled('p')`
  color: ${({ theme }) => theme.colours.base};
  font-size: 14px;
  text-align: left;
`
