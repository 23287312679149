import React, { createContext } from 'react'

import type { FormComponentSchema, FormSchema } from '../types/schema'

export const FormSchemaContext = createContext<{
  schema: FormSchema | undefined
  blockIndex: Record<string, FormComponentSchema> | undefined
}>({ schema: undefined, blockIndex: undefined })

export const FormSchemaProvider = FormSchemaContext.Provider

const useFormSchema = () => React.useContext(FormSchemaContext)

export default useFormSchema
