import { styled, Theme } from '@mui/material'
import { rem } from 'polished'

import { Variant } from '@acre/design-system'

export const SearchWrapper = styled('div')(
  ({ theme }) => `
  position: relative; /* Keep absolute children contained */
  z-index: ${theme.display.overflowZIndex + 1};
  font-size: 14px;
`,
)

export const RowWrapper = styled('div')<{ variant?: Variant }>(
  ({ theme, variant }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  & button {
    margin-left: ${theme.spacing(2)};
  }
  /* 
    Annoying edge case:
    - the first > div is the flex box item, 
    - the second row div is the wrapper inside the component we're trying to target
   */
  & > div > div {
    flex-grow: 1;
  }
  ${variant === 'compact' && rowWrapperCompactStyles(theme)};
  ${variant === 'compactLeftAligned' && rowWrapperNewCompactStyles(theme)};
`,
)

const rowWrapperCompactStyles = (theme: Theme) => `
  align-items: center;
  flex-direction: row;
  & label {
    width: 33%;
    min-width: 33%;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    margin-top: -${theme.spacing(1.5)};
    margin-bottom: -${theme.spacing(1.5)};
    transform: translateX(-${theme.spacing(1)});
  }
  & button {
    margin-left: ${theme.spacing(1)};
  }
`

const rowWrapperNewCompactStyles = (theme: Theme) => `
  align-items: center;
  flex-direction: row;
  & label {
    width: 30%;
    min-width: 30%;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
  }
  & button {
    margin-left: ${theme.spacing(1)};
  }
`

export const InputWrapper = styled('div')<{ variant?: Variant }>(
  ({ theme, variant }) => `
  display: flex;
  div {
    width: 100%;
    margin-right: ${theme.display.ms1};
    + div {
      margin-right: 0;
    }
  }
  margin-top: ${theme.spacing(2)};

  ${(variant === 'compact' || variant === 'compactLeftAligned') && inputWrapperCompactStyles(theme)}
`,
)

export const SingleSelectorContainer = styled('div')(
  ({ theme }) => `
  margin-top: ${rem(theme.display.spacingIncrement * 4)};
`,
)

const inputWrapperCompactStyles = (theme: Theme) => `
  flex-direction: column;
  margin-left: 0px;

  div {
    margin-right: 0;
    & + div {
      margin-top: ${theme.spacing(2)};
      margin-right: 0;
    }
  }
`

export const SearchResultButton = styled('button')(
  ({ theme }) => `
  width: 100%;
  text-align: left;
  padding: ${theme.spacing(2)};
  background: transparent;
  border: none;
  outline: none;
  margin-left: 0px !important;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  &:hover {
    background-color: ${theme.colours.primary10};
    .address-first-line {
      color: ${theme.colours.primary};
    }
  }
`,
)

export const Results = styled('ul')<{ isVisible: boolean; variant: Variant }>(
  ({ theme, variant, isVisible }) => `
  margin-top: ${variant === 'compactLeftAligned' ? theme.spacing(1) : 'auto'};
  position: absolute;
  top: ${theme.spacers.size32};
  width: 100%;
  background-color: white;
  list-style: none;
  padding: 0;
  box-shadow: ${theme.boxShadow};
  border-radius: ${theme.display.borderRadius};
  overflow: auto;
  max-height: 300px;
  border: 1px solid ${theme.colours.baseLight};
  display: ${isVisible ? 'block' : 'none'};
`,
)

export const NoResultLi = styled('li')(
  ({ theme }) => `
  padding: ${theme.display.ms1};
  color: ${theme.colours.baseMid};
`,
)

export const GreyText = styled('span')(
  ({ theme }) => `
  color: ${theme.colours.baseMid};
  white-space: nowrap;
`,
)

export const BlueText = styled('span')(
  ({ theme }) => `
  color: ${theme.colours.primary};
`,
)
