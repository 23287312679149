import React from 'react'
import { styled } from '@mui/material'

const TableFooterInfoItemStyles = styled('div')``
export type TableFooterInfoItemProps = { title: string; value: string | number }

const TableFooterInfoItem = ({ title, value }: TableFooterInfoItemProps) => (
  <TableFooterInfoItemStyles>{`${title}: ${value}`}</TableFooterInfoItemStyles>
)

export default TableFooterInfoItem
