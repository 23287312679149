import { CaseStatus, VerificationCollection } from '@acre/graphql'

// when collection Gate 3.7 has passed and case is in pre-recommendation we should show a task to publish SR
export const isGateThreePointSevenExceptionCollection = (
  passed: boolean,
  name: string | null | undefined,
  caseStatus: CaseStatus | null | undefined,
): boolean => {
  if (name?.includes('Gate 3.7.1') && passed && caseStatus === CaseStatus.PreRecommendation) {
    return true
  } else if (name?.includes('Gate 3.7.2') && passed) {
    return true
  } else {
    return false
  }
}

export type OrderedVerifications = {
  passed_collection_ids: string[]
  failed_collection_ids: string[]
  failed_verification_ids: string[]
  passed_verification_ids: string[]
}

export const orderVerifications = (verifications: VerificationCollection[]) => {
  const orderedVerifications: OrderedVerifications = verifications?.reduce(
    (acc, collection) => {
      const { passed_collection_ids, failed_collection_ids, failed_verification_ids, passed_verification_ids } = acc

      if (collection.passed) {
        return {
          ...acc,
          passed_collection_ids: [...passed_collection_ids, collection.collection_id],
          passed_verification_ids: [
            ...passed_verification_ids!,
            ...(collection.verifications || []).reduce((acc, verification) => {
              if (verification.passed) {
                return [...acc, verification.verification_id]
              }
              return acc
            }, [] as string[]),
          ] as string[],
        }
      }

      return {
        ...acc,
        failed_collection_ids: [...failed_collection_ids, collection.collection_id],

        failed_verification_ids: [
          ...failed_verification_ids!,
          ...(collection.verifications || []).reduce((acc, verification) => {
            if (!verification.passed) {
              return [...acc, verification.verification_id]
            }
            return acc
          }, [] as string[]),
        ] as string[],

        passed_verification_ids: [
          ...passed_verification_ids!,
          ...(collection.verifications || []).reduce((acc, verification) => {
            if (verification.passed) {
              return [...acc, verification.verification_id]
            }
            return acc
          }, [] as string[]),
        ] as string[],
      }
    },
    {
      passed_collection_ids: [],
      failed_collection_ids: [],
      failed_verification_ids: [],
      passed_verification_ids: [],
    } as OrderedVerifications,
  )

  return orderedVerifications
}
