import React from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { Location } from 'history'
import { isEmpty, omit } from 'lodash'
import { useFormState } from 'react-final-form'
import { useLocation } from 'react-router-dom'

import { differenceInObjects, ExactObject, isObjectEmpty, useFormatMessage } from '@acre/utils'
import { ConfirmationNavigationModal } from '@acre/design-system'

export type CustomFormPromptProps = {
  toPrefix?: string
  pathnameOverride?: string
}

const CustomFormPrompt = ({ toPrefix, pathnameOverride }: CustomFormPromptProps) => {
  const { id } = useCaseContext()
  const { values, initialValues, hasValidationErrors, errors } = useFormState()
  const currentLocation = useLocation()
  const formatMessage = useFormatMessage()

  const message: (location: Location) => string = (location: Location<any>) => {
    const hasUpdatedValues = (newState: ExactObject, initialState: ExactObject) => {
      const updatedStateSections = omit(
        differenceInObjects(newState, initialState),
        'introducer_org_id',
        'unsecured_commitments',
        'sharedBudget',
      )

      if (!isEmpty(updatedStateSections)) {
        const hasValues =
          !isObjectEmpty(updatedStateSections) ||
          Object.keys(updatedStateSections).some((key) => initialValues[key] && !values[key])
        return hasValues
      }
      return false
    }

    // These variables are primarily used to ensure that the unsaved changes modal is shown in the fact find
    // when switching between clients

    const currentPathname = currentLocation.pathname
    const newPathname = location.pathname

    const currentLocationEndOfSlug = currentPathname.split('/')[currentLocation.pathname.split('/').length - 1]
    const newLocationEndOfSlug = newPathname.split('/')[location.pathname.split('/').length - 1]

    const isInReviewMode = location.pathname.includes(`cases/${id}/review-mode/version`)

    const slugChanging = currentLocationEndOfSlug !== newLocationEndOfSlug

    const currentPathnameIncludesDestinationPrefix = toPrefix && location.pathname.includes(toPrefix)

    const currentPathnameIncludesPathnameOverride = pathnameOverride && location.pathname.includes(pathnameOverride)

    // Do not render the modal if the URL hasn't changed
    if (currentPathname === newPathname) {
      return ''
    }

    if (
      currentPathnameIncludesDestinationPrefix ||
      (slugChanging && currentPathnameIncludesPathnameOverride && !isInReviewMode)
    ) {
      if (hasValidationErrors) {
        console.error(errors)
        return formatMessage('generic.unresolvedErrors')
      }
      return ''
    } else {
      return hasUpdatedValues(values, initialValues) ? formatMessage('generic.unsavedChanges') : ''
    }
  }

  return (
    <ConfirmationNavigationModal message={message}>
      {({ displayMessage }) => displayMessage}
    </ConfirmationNavigationModal>
  )
}

export default CustomFormPrompt
