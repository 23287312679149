import { CaseContextType } from '@broker-crm-contexts'

import { CaseStatus, ClientRelationship, ClientVersion, isNaturalClient } from '@acre/graphql'

export const getCompanyDirectors = (caseDetails: CaseContextType['details']) => {
  return getDirectors(caseDetails.clients)
}

export const getDirectors = (clients: ClientVersion[]) => {
  const nonNaturalClients = clients.filter((client) => !isNaturalClient(client.details))

  return nonNaturalClients.reduce((clientIds: string[], company) => {
    const relationships = company.details.relationships

    if (!relationships) return clientIds

    const directors = relationships
      .filter((relationship) =>
        [ClientRelationship.DirectorTrustee, ClientRelationship.DirectorTrustee, ClientRelationship.Psc].includes(
          relationship.relationship_type as ClientRelationship,
        ),
      )
      .map((director) => director.relationship_reference!)

    return [...clientIds, ...directors]
  }, [])
}

export const progresedCaseStatus = [
  CaseStatus.PostRecommendationReview,
  CaseStatus.PostApplication,
  CaseStatus.NotProceeding,
  CaseStatus.ImportedComplete,
  CaseStatus.Importing,
  CaseStatus.ApplicationSubmitted,
  CaseStatus.AwaitingValuation,
  CaseStatus.AwaitingOffer,
  CaseStatus.OfferReceived,
  CaseStatus.Exchange,
  CaseStatus.Complete,
]

export const hiddenSections = [
  'outgoings',
  'mortgage',
  'mortgageNew',
  'properties',
  'conveyancing',
  'protection',
  'impersonation',
  'employment',
]
