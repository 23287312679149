import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface GreyTextClasses {
  root: string
}

export type GreyTextClassKey = keyof GreyTextClasses

export function getGreyTextUtilityClass(slot: string) {
  return generateUtilityClass('GreyText', slot)
}

const greyTextClasses: GreyTextClasses = generateUtilityClasses('GreyText', ['root'])

export default greyTextClasses
