import { styled, Theme } from '@mui/material'
import { transparentize } from 'polished'

import { Variant } from '../../utils/constants'
import { disabledClassName } from '../../styles/disabled.styles'

const compactStyles = ({ theme }: { theme: Theme }) => `
  height: ${theme.display.inputHeightShort};
  font-size: 14px;
  padding-right: ${theme.spacing(1.5)};
  padding-left: ${theme.spacing(1.5)};
`

const newCompactStyles = ({ theme }: { theme: Theme }) => `
  height: ${theme.display.inputHeightMedium};
  font-size: 14px;
`

export const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'textColour' && prop !== 'color' && prop !== 'isLoading',
})<{
  isLoading: boolean
  variant?: Variant
  height?: string
  width?: string
}>(
  ({ theme, isLoading, variant, height, width }) => `
  transition: opacity 0.1s ease-out, border-color 0.1s ease-out, background-color 0.1s ease-out;
  border-radius: ${theme.display.borderRadius};
  padding-right: ${theme.spacers.size16};
  padding-left: ${theme.spacers.size16};
  height: ${height || theme.display.inputHeight};
  width: ${width || 'auto'};
  font-size: 16px;

  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colours.primary};

  white-space: nowrap;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.left {
    margin-left: auto;
  }

  &.fullWidth {
    width: 100%;
  }

  &:disabled {
    cursor: ${isLoading ? 'progress' : 'not-allowed'};
  }

  &.active svg {
    stroke: ${theme.colours.primary};
  }

  ${disabledClassName(theme)}
  ${variant == 'compact' && compactStyles({ theme })};
  ${variant == 'compactLeftAligned' && newCompactStyles({ theme })};
`,
)

export const ButtonPrimary = styled(StyledButton)(
  ({ theme }) => `
  color: white;
  background-color: ${theme.colours.primary};

  & svg path {
    color: white;
    stroke: white;
  }

  &:hover {
    border-color: ${theme.colours.hover};
    background-color: ${theme.colours.hover};
  }

  &:active {
    border-color: ${theme.colours.pressed};
    background-color: ${theme.colours.pressed};
  }

  &:disabled {
    color: white;
    background-color: ${theme.colours.new.blue2};
    border: 1px solid ${theme.colours.new.blue2};
    opacity: 0.2;
  }
`,
)

// @TODO RD-703
export const ButtonSecondary = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.primary};
  background-color: white;
  border: 1px solid  ${transparentize(theme.opacity.op50, theme.colours.primary)};

  & svg path {
    color: ${theme.colours.primary};
    stroke: ${theme.colours.primary};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op95, theme.colours.primary)};
    border: 1px solid  ${theme.colours.primary};
    text-decoration: none;
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op95, theme.colours.primary)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    border: 1px solid ${theme.colours.baseExtraLight};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)

export const ButtonSecondarySmall = styled(ButtonSecondary, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(
  ({ theme }) => `
  height: ${theme.spacing(4)};
  font-size: 14px;
  padding-right: ${theme.spacing(1)};
  padding-left: ${theme.spacing(1)};
`,
)

export const ButtonColoured = styled(StyledButton, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'textColour',
})<{ textColour?: string; color?: string }>(
  ({ theme, textColour, color }) => `
  color: ${textColour || theme.colours.primary};
  background-color: ${color || 'white'};
  border: 1px solid ${textColour || theme.colours.primary};

  & svg path {
    color: ${textColour || theme.colours.primary};
    stroke: ${textColour || theme.colours.primary};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, color ? color : theme.colours.primary)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, color ? color : theme.colours.primary)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    border: 1px solid ${theme.colours.baseExtraLight};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)

export const ButtonTag = styled(ButtonSecondary)(
  ({ theme }) => `
  height: ${theme.spacing(3)};
  font-size: 14px;
`,
)

export const ButtonDanger = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.danger};
  background-color: white;
  border: none;

  & svg path {
    color: ${theme.colours.danger};
    stroke: ${theme.colours.danger};
    fill: ${theme.colours.danger};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.danger)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.danger)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)

export const ToggleButton = styled(ButtonSecondary)(
  ({ theme }) => `
  width: 107px;
  font-size: 16px;
  color: ${theme.colours.primary};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  border-color: ${theme.colours.baseLight};

  &:focus {
    outline: none;
  }

  @media (max-width: ${theme.wrappers.mediumBreakpoint}px) {
    width: 30%;
  }
`,
)

// @TODO RD-703
export const ButtonClear = styled(StyledButton, {
  shouldForwardProp: (prop) => prop !== 'textColour' && prop !== 'height' && prop !== 'width',
})<{ textColour?: string; height?: string; width?: string }>(
  ({ theme, textColour, height, width }) => `
  font-weight: ${theme.typography.fontWeightRegular};
  position: relative;
  border: none;
  background-color: transparent;
  height: ${height ? height : 'unset'};
  width: ${width ? width : 'unset'};
  outline: none;
  color: inherit;
  padding-left: 0px;
  padding-right: 0px;
  &:hover {
    color: ${textColour === theme.colours.baseMid ? theme.colours.primary : undefined};
  }
  &:disabled {
    background-color: transparent;
  }
  @media (max-width: ${theme.wrappers.mediumBreakpoint}px) {
    width: auto;
  }
  `,
)

export const ButtonClearSmall = styled(ButtonClear)(
  ({ theme }) => `
  ${newCompactStyles({ theme })}
  `,
)

export const ButtonLink = styled(ButtonClear)(
  ({ theme }) => `
    color: ${theme.colours.primary};
`,
)

export const ButtonLinkSmall = styled(ButtonLink)`
  font-size: 14px;
`

const overflowCompactStyles = (theme: Theme) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
`

const overflowCompactLeftAlignedStyles = (theme: Theme) => `
  width: ${theme.spacing(5)};
  height: ${theme.spacing(5)};
`

const overflowSmallStyles = (theme: Theme) => `
  height: ${theme.spacing(3)};
  width: ${theme.spacing(3)};
  font-size: 12px;
`

export const ButtonOverflow = styled(StyledButton, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'textColour' && prop !== 'color',
})<{ variant?: Variant; textColour?: string; color?: string }>(
  ({ theme, variant, textColour, color }) => `
  padding: unset;
  width: ${theme.display.inputHeight};
  height: ${theme.display.inputHeight};

  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  svg path {
      fill:  ${textColour};
  }
  &:hover {
    color: ${theme.colours.primary};
    background-color: ${
      color === theme.colours.primary
        ? transparentize(theme.opacity.op90, theme.colours.primary)
        : color
          ? transparentize(0.4, color)
          : transparentize(theme.opacity.op90, theme.colours.primary)
    };
  }

  &.open,
  :active {
    outline: none;
    color: ${theme.colours.primary};
    background-color: ${
      color === theme.colours.primary
        ? transparentize(theme.opacity.op80, theme.colours.primary)
        : color
          ? transparentize(0.2, color)
          : transparentize(theme.opacity.op80, theme.colours.primary)
    };
  }

  &.alignLeft {
    justify-content: flex-start;
    padding-left: 1rem;
  }

  @media (max-width: ${theme.wrappers.mediumBreakpoint}px) {
    width: auto;
  }

  ${
    (variant === 'compact' && overflowCompactStyles(theme)) ||
    (variant === 'small' && overflowSmallStyles(theme)) ||
    (variant === 'compactLeftAligned' && overflowCompactLeftAlignedStyles(theme))
  };
`,
)

export const ButtonDashed = styled(ButtonSecondary, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: Variant }>(
  ({ theme, variant }) => `
  border: 1px dashed ${theme.colours.primary};

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.primary)};
  }

  ${(variant === 'compact' || variant === 'compactLeftAligned') && dashedCompactStyles(theme)};
`,
)

const dashedCompactStyles = (theme: Theme) => `
  height: ${theme.spacing(4)};
`

export const ButtonPopOver = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.base};
  background-color: ${theme.colours.foreground};
  border-radius: 0;
  border: none;

  /* Revert height, so that the button's height expands according to content */
  height: revert;
  padding: ${theme.spacing(2)};

  justify-content: flex-start;

  &:hover,
  &:active,
  &:disabled {
    color: white;
  }

  &:hover {
    background-color: ${theme.colours.primary10};
    color: ${theme.colours.primary};
  }

  &:focus {
    color: ${theme.colours.foreground};
    background-color: ${theme.colours.pressed};
  }

  &:disabled {
    color: ${theme.colours.baseLight};
    background-color: white;
  }
`,
)

export const ButtonPopOverDanger = styled(StyledButton)(
  ({ theme }) => `
  background-color: ${theme.colours.foreground};
  border-radius: 0;

  /* Revert height, so that the button's height expands according to content */
  height: revert;
  padding: ${theme.spacing(2)};

  justify-content: flex-start;

  color: ${theme.colours.danger};
  background-color: white;
  border: none;

  & svg path {
    color: ${theme.colours.danger};
    stroke: ${theme.colours.danger};
    fill: ${theme.colours.danger};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.danger)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.danger)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)

// Only show red when hovering over the button
export const ButtonPopOverDangerOnHover = styled(StyledButton)(
  ({ theme }) => `
  background-color: ${theme.colours.foreground};
  border-radius: 0;
  color: ${theme.colours.baseMid};
  padding: unset;
  height: ${theme.display.inputHeight};
  justify-content: flex-start;

  background-color: white;
  border: none;

  & svg path {
    color: ${theme.colours.danger};
    stroke: ${theme.colours.danger};
    fill: ${theme.colours.danger};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.danger)};
    color: ${theme.colours.danger};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.danger)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)

export const ButtonHidden = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.primary};
  background-color: white;
  border: 1px solid ${theme.colours.primary};

  & svg path {
    color: ${theme.colours.primary};
    stroke: ${theme.colours.primary};
    fill: ${theme.colours.primary};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.primary)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    border: 1px solid ${theme.colours.baseExtraLight};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }

  &:hidden {
    display: none;
  }
`,
)

export const ButtonPlus = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.primary};
  background-color: ${transparentize(theme.opacity.op95, theme.colours.primary)};
  border: 1px solid ${theme.colours.primary};

  & svg path {
    color: ${theme.colours.primary};
    stroke: ${theme.colours.primary};
    fill: ${theme.colours.primary};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.primary)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:hidden {
    display: none;
  }
`,
)

export const ButtonDropdownMenu = styled(StyledButton)(
  ({ theme }) => `
  background-color: white;
  border-color: ${theme.colours.baseLight};
  &:hover {
    border-color: ${theme.colours.primary};
  }
  color: ${theme.colours.baseMid};
`,
)

export const ButtonDangerSecondary = styled(StyledButton)(
  ({ theme }) => `
  color: ${theme.colours.danger};
  background-color: white;
  border: 1px solid ${theme.colours.danger};
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colours.danger};

  & svg path {
    color: ${theme.colours.danger};
    stroke: ${theme.colours.danger};
    fill: ${theme.colours.danger};
  }

  &:hover {
    background-color: ${transparentize(theme.opacity.op90, theme.colours.danger)};
  }

  &:active {
    background-color: ${transparentize(theme.opacity.op80, theme.colours.danger)};
  }

  &.loading {
    background-color: ${theme.colours.baseMid};
  }

  &:disabled {
    color: ${theme.colours.baseMid};
    border: 1px solid ${theme.colours.baseExtraLight};
    background-color: ${theme.colours.baseExtraLight};
    &:hover {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseExtraLight};
      &.loading {
        background-color: ${theme.colours.baseExtraLight};
      }
    }
  }
`,
)
