import { FieldReadFunction } from '@apollo/client'
import { chain } from 'lodash'

import { formatAsCurrency, ReportsRevenueResponse, ReportsRevenueTotalsItem, RevenueReport } from '@acre/graphql'

import indexBy from '../../../utils/array/indexBy'

const read: FieldReadFunction<RevenueReport> = (_, { readField, variables }) => {
  const revenueReportResponse = readField<ReportsRevenueResponse>({
    fieldName: 'reportsRevenue',
    args: variables?.body ? { body: variables.body } : {},
  })

  const totalsByGroupId = revenueReportResponse?.totals.by_group_id
    ? indexBy('id', revenueReportResponse?.totals.by_group_id)
    : {}

  const getRevenueReportGroupedResults = chain(revenueReportResponse?.results)
    .groupBy('group_id')
    .map(
      (group, group_id) =>
        group.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.year_month]: formatAsCurrency(cur.value),
          }),
          {
            id: group_id,
            view_by: variables?.body.view_by || '',
            total: formatAsCurrency(totalsByGroupId[group_id as keyof ReportsRevenueTotalsItem]?.value || 0),
          },
        ) as {
          id: string
          total: string
          [name: string]: unknown
        },
    )
    .value()

  return { revenues: getRevenueReportGroupedResults || [] }
}

export default {
  read,
}
