import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Icon, IconTypes } from '@acre/design-system'

const GenericError = () => {
  const theme = useTheme()

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Icon name={IconTypes.BadgeWarning} />
      <Box ml={theme.spacers.size16}>
        <FormattedMessage id="errors.generic" />
      </Box>
    </Box>
  )
}

export default GenericError
