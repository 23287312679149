import { PropertyLocation, PropertyType, PropertyVersion } from '@acre/graphql'

const property: PropertyVersion = {
  __typename: 'PropertyVersion',
  id: 'TEST_ID',
  property_id: 'TEST_ID',
  created_at: null,
  version: 1,
  version_created_at: null,
  details: {
    __typename: 'Property',
    id: 'TEST_ID',
    property_id: 'TEST_ID',
    acre_property_id: 'TEST_ID',
    address: null,
    attributes: null,
    btl_has_lived: null,
    btl_is_operating_a_business: null,
    btl_will_lived: null,
    builder_registration_scheme: null,
    builder: null,
    case_status: null,
    clear_floor_between_retail: null,
    cost_of_works: null,
    current_energy_rating: null,
    flying_freehold_percentage: null,
    gross_development_value: null,
    help_to_buy_loan_amount: null,
    intend_to_let_or_run_business_description: null,
    intend_to_let_or_run_business: null,
    is_house_of_multiple_occupancy: null,
    is_multi_unit_freehold_block: null,
    is_new_build: null,
    is_private_sale: null,
    is_property_ex_local_authority: null,
    is_property_furnished: null,
    is_property_habitable: null,
    is_shared_ownership: null,
    is_unencumbered: null,
    is_work_planned_on_property: null,
    leasehold_service_charge: null,
    location: PropertyLocation.England,
    monthly_management_expenses: null,
    monthly_rental_income: null,
    mortgages: null,
    mortgagesCp: null,
    number_of_bedrooms: null,
    number_of_bathrooms: null,
    number_of_lettable_rooms: null,
    occupants: null,
    price: null,
    property_builder_name: null,
    property_location: PropertyLocation.England,
    property_price: null,
    property_tenure: null,
    property_type: PropertyType.House,
    property_use: null,
    property_valuation: null,
    purchasing_as_sitting_tennant: null,
    registered_owners_details: null,
    registered_owners: null,
    right_to_buy_valuation: null,
    roof_type: null,
    shared_equity_repayment_details: null,
    shared_ownership_monthly_rent: null,
    shared_ownership_scheme_provider: null,
    shared_ownership_share: null,
    shared_ownership_type: null,
    specified_rebuild_cost: null,
    sub_type: null,
    target_registered_owners_details: null,
    tenure: null,
    transition_mortgage_versions: null,
    type: PropertyType.House,
    use: null,
    valuation_date: null,
    valuation: null,
    wall_type: null,
    work_planned_on_property: null,
    year_built: null,
    year_property_built: null,
  },
}

export default property
