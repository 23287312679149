import { useMemo } from 'react'

import { GateName, ResolvedPermissionResolution, useGetPermissionResolutionQuery } from '@acre/graphql'

import { excludeInputsBasedOnGates, mergeObjectArrays, transformPermissionInputs } from './permission.helpers'
import { PermissionInput } from './permission.types'

export type Props = {
  inputs: PermissionInput[]
  // these exceptions are an object
  // they can be ussed for any kind of exceptions
  exceptions?: { gates: GateName[] }
}

const usePermissionResolution = ({ inputs, exceptions }: Props): { loading: boolean; gates: GateName[] } => {
  let inputsToTansform = inputs
  // if there are exceptions for gates present, we need to exclude those gates from inputs
  // and potentially inputs which contain those gates completely
  if (exceptions && exceptions.gates) {
    inputsToTansform = excludeInputsBasedOnGates({
      inputs,
      gateExceptions: exceptions.gates,
    })
  }
  const { permissionInputs, allGates } = transformPermissionInputs({ inputs: inputsToTansform })

  const { data, error, loading } = useGetPermissionResolutionQuery({
    variables: { input: permissionInputs },
    skip: !permissionInputs.length,
  })

  const resolvedPermissions = useMemo(() => {
    if (loading || error || !data?.permissionResolution) {
      return []
    }

    const { permissionResolution } = data || {}

    // we only receive an array of has_permissions from the api,
    // the order of these are expected to be the same as the inputs.
    // we need to merge the results from the api request and what was
    // sent so that the frontend code can have both the frontend
    // permissions gates as well as the corresponding response together
    let mergedArray: ResolvedPermissionResolution[] = []
    if (permissionResolution && allGates && allGates.length) {
      mergedArray = mergeObjectArrays(permissionResolution, allGates)
    }

    // when using this component in the code, we will most commonly
    // need to check which permission gates have validated permissions
    // the validated permission gates are returned here
    const gates: GateName[] = mergedArray
      .reduce((acc, element) => {
        let result = acc
        if (element && element.gate && element.has_permission) {
          result = [...result, element.gate]
        }
        return result
      }, [] as GateName[])
      .filter((a, b, arr) => arr.indexOf(a) === b)

    return gates
  }, [allGates, data, error, loading])

  return {
    loading,
    gates: error ? [] : resolvedPermissions,
  }
}

export default usePermissionResolution
