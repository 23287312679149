import { omit, pick } from 'lodash'

export const getKeyArgs =
  <T extends Record<string, unknown>>({
    keys,
    mode = 'omit',
    argsKey,
  }: {
    keys?: (keyof T)[]
    mode?: 'omit' | 'pick'
    argsKey?: string
  }) =>
  (args: Record<string, T> | null) => {
    if (!args) {
      return JSON.stringify({})
    }

    const resolvedArgs = argsKey ? args[argsKey] : args

    const fn = mode === 'omit' ? omit : pick

    const filteredKeys = keys?.length ? fn(resolvedArgs, keys) : resolvedArgs

    return JSON.stringify(filteredKeys)
  }
