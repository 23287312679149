import { styled } from '@mui/material'

export const TextContainer = styled('div')(({ theme }) => ({
  '& textarea': {
    position: 'relative',
    height: theme.spacing(15),
    width: '100%',
    margin: 'auto',
    lineHeight: 1.6,
    fontSize: theme.typography.pxToRem(14),
  },
}))

export const TextUnderlay = styled('div')({
  display: 'none',
})
