import React from 'react'
import { MainNav, PermissionResolution } from '@broker-crm-common'
import useShouldOnboard from '@broker-crm-hooks/useShouldOnboard'
import { helmetData } from '@broker-crm-utils/helmetData'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { getUserOrganisationIds } from '@acre/utils'
import { GateName, PermissionScopeType, useGetCurrentUserQuery } from '@acre/graphql'
import { AppLayout, PageTracker } from '@acre/design-system'

const { ACCOUNTING } = GateName

const AppRoutes = () => {
  const { data } = useGetCurrentUserQuery()

  const user = data?.currentUser

  const shouldOnboard = useShouldOnboard(user)
  const location = useLocation()

  if (shouldOnboard && !location.pathname.includes('welcome')) {
    return <Navigate to="welcome" replace />
  }

  // const location = useLocation()
  // @TODO FRON-522 use history
  const isLoginPage = location.pathname === '/login'
  const className = classNames('router', {
    'router--navigation-spacer': !isLoginPage,
  })

  let title = window.location.pathname.split('/').pop()?.replace(/-/g, ' ') || 'unknown'
  title = title.substring(0, 1).toUpperCase() + title.substring(1, 999)

  return (
    <PageTracker helmetData={helmetData}>
      <PermissionResolution
        inputs={[
          {
            gates: [ACCOUNTING],
            entityIdsCollection: {
              [PermissionScopeType.organisation]: user ? [getUserOrganisationIds({ user }).userOrganisationId] : [],
            },
          },
        ]}
      >
        {() => (
          <div className={className}>
            <Helmet titleTemplate="Acre Broker CRM | %s" helmetData={helmetData}>
              <title>{title}</title>
            </Helmet>
            <AppLayout>
              {!shouldOnboard && <MainNav />}
              <Outlet />
            </AppLayout>
          </div>
        )}
      </PermissionResolution>
    </PageTracker>
  )
}

export default AppRoutes
