import React from 'react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

import {
  formatValue,
  formatValues,
  isMultiKeyItem,
  isSingleKeyItem,
  isUnformattedItem,
  Item,
  ValueOf,
} from '@acre/utils'
import { FieldListItem, GreyText } from '@acre/design-system'

type Props<T> = {
  item: Item<T>
  data: T
}

function FormattedFieldListItem<T extends object>({ item, data }: Props<T>) {
  const fallback = (
    <GreyText>
      <FormattedMessage id="generic.noInformation" />
    </GreyText>
  )

  const { message } = item
  let valueFormatted = null

  // Handle case where we only ask for a single field
  if (isSingleKeyItem(item)) {
    const { key, formatter } = item
    const value = get(data, key) as ValueOf<T>

    const formatterInstance = formatter || ((value: ValueOf<T>) => <>{value}</>)
    valueFormatted = formatValue(formatterInstance, fallback, value)
  }

  // Handle case where we only ask for multiple fields from the same object
  else if (isMultiKeyItem(item)) {
    const { keys, formatter } = item
    const values = keys.reduce((acc, fieldname) => {
      const key = fieldname.split('.').slice(-1)[0]
      const value = get(data, fieldname)
      return { ...acc, [key]: value }
    }, {} as T)

    const formatterInstance = formatter || ((value: T) => <>{value}</>)
    valueFormatted = formatValues(formatterInstance, fallback, values)
  }

  // Handle case where we want a completely unformatted item
  else if (isUnformattedItem(item)) {
    const { value } = item
    valueFormatted = value
  }

  return <FieldListItem label={<FormattedMessage id={message} />} value={valueFormatted} align="left" smallFont />
}

export default FormattedFieldListItem
