import React from 'react'

import StepContent from './StepContent'
import type { TourStep, TourStepContentConfig, TourStepInput } from './types'

export default function createTour<Steps extends Record<string, TourStepInput>>(steps: Steps) {
  return Object.entries(steps).reduce(
    (acc, [key, value]: [keyof Steps, TourStepInput]) => {
      acc[key] = { ...value, selectorToHTML: () => value.selector.replace(/[#.]/, '') }

      if ((value.content as TourStepContentConfig).title) {
        acc[key].content = <StepContent {...(value.content as TourStepContentConfig)} />
      }

      return acc
    },
    {} as Record<keyof Steps, TourStep>,
  )
}
