import { FieldMergeFunction } from '@apollo/client'

import { NewFee } from '@acre/graphql'

const merge: FieldMergeFunction<NewFee[]> = (existing, incoming) => {
  return incoming
}

export default {
  merge,
}
