import React from 'react'
// import { FormHelperText } from '@mui/material'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { CheckboxField, CheckboxFieldProps } from '@acre/design-system'
import { FieldBaseProps, useFieldValidationSchema, useFormContext } from '@acre/forms'

// import ErrorMessageDisplay, { RenderErrorMessageProps } from '../../common/ErrorMessageDisplay'
// import { ErrorMessage } from '@acre/forms'

export interface CheckboxFieldRHFProps<T extends FieldValues = FieldValues>
  extends Omit<CheckboxFieldProps, 'value' | 'onChange' | 'onBlur' | 'inputRef' | 'name'>,
    FieldBaseProps {
  name: Path<T>
}

function _CheckboxFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>({
  name,
  label,
  shouldUnregister = true,
  ...props
}: CheckboxFieldRHFProps<T>) {
  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const errorId = error ? `${name}-error` : ''

  // const renderError = useCallback(
  //   ({ message, messages }: RenderErrorMessageProps) =>
  //     messages ? (
  //       <FormHelperText id={errorId} component="ul" sx={{ ml: '14px' }} error>
  //         <ErrorMessageDisplay message={message} messages={messages} />
  //       </FormHelperText>
  //     ) : null,
  //   [errorId],
  // )

  return (
    <CheckboxField
      {...props}
      WrapperProps={{ error: Boolean(error) }}
      label={label}
      inputProps={{
        'aria-required': !fieldSchema?.spec?.optional,
        'aria-errormessage': errorId,
      }}
      onChange={field.onChange}
      checked={field.value ?? false}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
      // error={<ErrorMessage name={name} render={renderError} />}
      error={error?.message}
    />
  )
}

const CheckboxFieldRHF = React.memo(_CheckboxFieldRHF) as typeof _CheckboxFieldRHF

export default CheckboxFieldRHF
