import React, { ReactElement } from 'react'

import testHandle from '../../utils/testHandle'
import { Container, Div } from './PopOver.styles'

export type PopOverProps = {
  id?: string
  isVisible?: boolean
  children?: ReactElement | Array<ReactElement | false | null> | string | null | false
  maxSize?: boolean
  overflowYVisible?: boolean
  align?: 'left' | 'right'
  overflowMargin?: boolean
}

const PopOver = ({
  id,
  children = [],
  isVisible = true,
  align = 'right',
  maxSize = true,
  overflowYVisible = false,
  overflowMargin,
}: PopOverProps) => {
  const dataTestId = id ? testHandle(id) : null

  return (
    <Div data-testid={dataTestId} isVisible={isVisible}>
      <Container maxSize={maxSize} align={align} overflowYVisible={overflowYVisible} overflowMargin={overflowMargin}>
        {children}
      </Container>
    </Div>
  )
}

export default PopOver
