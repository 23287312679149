import React, { createContext, ReactNode, useEffect, useState } from 'react'
import debounce from 'debounce-promise'

type PageScrollHandlerProps = {
  children: ReactNode
}

type PageScrollContextType = {
  scrollValue: number
  unwatchScroll: () => void
  watchScroll: () => void
}

export const PageScrollContext = createContext<PageScrollContextType>({
  scrollValue: 0,
  unwatchScroll: () => null,
  watchScroll: () => null,
})

export const PageScrollHandler = ({ children }: PageScrollHandlerProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const scrollHandler = debounce(() => {
    setScrollPosition(window.scrollY)
  }, 100)

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  })

  return (
    <PageScrollContext.Provider
      value={{
        scrollValue: scrollPosition,
        unwatchScroll: () => window.removeEventListener('scroll', scrollHandler),
        watchScroll: () => window.addEventListener('scroll', scrollHandler),
      }}
    >
      {children}
    </PageScrollContext.Provider>
  )
}
