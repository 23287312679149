import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'

import { RenderDocumentDocument, RenderDocumentQuery, TemplateType } from '@acre/graphql'
import { CenteredLoadingSpinner, TwoColumnCell, TwoColumnLayout } from '@acre/design-system'

import { getHTMLSections } from './InitialDisclosure.helpers'
import { DocumentContainerStyle } from './InitialDisclosure.styles'

type Props = {
  clientIds: string[]
  caseId: string | undefined
  organisationIds: string[]
}

const CaseInitialDisclosureDocument = ({ clientIds, caseId, organisationIds }: Props) => {
  const theme = useTheme()

  const { loading, data } = useQuery<RenderDocumentQuery>(RenderDocumentDocument, {
    fetchPolicy: 'network-only', // Ensure we  always get the latest version of the document
    variables: {
      client_ids: clientIds,
      organisation_ids: organisationIds,
      template_name: TemplateType.VerbalIdd,
      store_document: false,
      case_ids: [caseId],
    },
  })

  const originalData = data?.renderedDocument?.data_base64 ? atob(data?.renderedDocument?.data_base64) : null

  return (
    <TwoColumnLayout>
      <TwoColumnCell>
        {loading ? (
          <Box my={theme.spacers.size24}>
            <CenteredLoadingSpinner />
          </Box>
        ) : (
          <DocumentContainerStyle
            dangerouslySetInnerHTML={{
              __html: getHTMLSections(originalData).firstPart,
            }}
          />
        )}
      </TwoColumnCell>
      <TwoColumnCell>
        {loading ? (
          <Box my={theme.spacers.size24}>
            <CenteredLoadingSpinner />
          </Box>
        ) : (
          <DocumentContainerStyle
            dangerouslySetInnerHTML={{
              __html: getHTMLSections(originalData).secondPart,
            }}
          />
        )}
      </TwoColumnCell>
    </TwoColumnLayout>
  )
}

export default CaseInitialDisclosureDocument
