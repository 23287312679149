import React, { PropsWithChildren } from 'react'
import { get } from 'lodash'
import { useFormState } from 'react-final-form'

export type FnConditionProps = PropsWithChildren<{
  fieldPrefix?: string
  fncondition: (arg: any) => boolean
}>

const FnCondition = ({ fncondition, children, fieldPrefix = '' }: FnConditionProps) => {
  const { values } = useFormState()
  const fieldPrefixWithoutDot = fieldPrefix || ''
  const nestedValues = fieldPrefixWithoutDot ? get(values, fieldPrefixWithoutDot) : values

  // Sometimes when component is unmounting then nestedValues is undefined,
  // and then fncondition often throws errors
  if (nestedValues) {
    return <>{fncondition(nestedValues) ? children : null}</>
  }

  return null
}

export default FnCondition
