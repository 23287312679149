import { get } from 'lodash'
import { useParams } from 'react-router-dom'

import { EncryptFn, getMetadata, getMetadataIndex } from '@acre/utils'
import { GeneralInsuranceProvider, Maybe, ProtectionProvider, useGetUserQuery, UserMetadata } from '@acre/graphql'
import { Colour, IconName } from '@acre/design-system'

//
// Types
//

export type CredentialsPair = {
  username?: Maybe<string>
  password?: Maybe<string>
}

export type UserCredentials = {
  uinsure?: CredentialsPair
  ipipeline?: CredentialsPair
  paymentshield?: CredentialsPair
}

export type MetadataMapping = {
  formKey: string
  metadataKey: string
  encryptionMethod?: EncryptFn
}

export const getCredsFromMetadata = (
  metadata: UserMetadata[],
  provider: GeneralInsuranceProvider | ProtectionProvider,
  plainText: boolean,
): CredentialsPair => {
  switch (provider) {
    case GeneralInsuranceProvider.Paymentshield:
      return {
        username: getMetadata(metadata, `paymentshield_seller_id${plainText ? '_plaintext' : ''}`),
        password: getMetadata(metadata, 'paymentshield_passkey'),
      }
    case GeneralInsuranceProvider.Uinsure:
      return {
        username: getMetadata(metadata, `uinsure_username${plainText ? '_plaintext' : ''}`),
        password: getMetadata(metadata, 'uinsure_password'),
      }
    case ProtectionProvider.Ipipeline:
      return {
        username: getMetadata(metadata, `protection.IPUsername${plainText ? '_plaintext' : ''}`),
        password: getMetadata(metadata, 'protection.IPPassword'),
      }
    default:
      return {
        username: null,
        password: null,
      }
  }
}

// only check if cred exists doesn't check if it is valid
export const areCredentialsProvided = (cred: CredentialsPair) => {
  return Boolean(cred.username && cred.password)
}

export const useCredsFromMetadata = (
  provider: GeneralInsuranceProvider | ProtectionProvider,
  plainText: boolean = false,
) => {
  const params = useParams<{ id?: string }>()
  const userId = params.id
  const { data } = useGetUserQuery({
    variables: {
      id: userId!,
      useClientApi: false,
    },
    fetchPolicy: 'cache-first',
    skip: !userId,
  })

  const typedMetaData: UserMetadata[] = data?.user?.metadata || []

  return getCredsFromMetadata(typedMetaData, provider, plainText)
}

/**
 * Build the initial values for the form
 * @param metadata
 * @returns user credentials
 */
export const buildInitialValues = (metadata: UserMetadata[] | null) => {
  if (!metadata) return {}

  // Santander
  const santanderBrokerCode = getMetadata(metadata, 'lender.santander.brokercode')

  return {
    ipipeline: getCredsFromMetadata(metadata, ProtectionProvider.Ipipeline, true),
    uinsure: getCredsFromMetadata(metadata, GeneralInsuranceProvider.Uinsure, true),
    paymentshield: getCredsFromMetadata(metadata, GeneralInsuranceProvider.Paymentshield, true),
    santander: {
      brokercode: santanderBrokerCode,
    },
  }
}

//
// Managing metadata
//
export const mergeCurValsIntoMetadata = (
  initialValues: UserCredentials,
  currentValues: UserCredentials,
  metaData: UserMetadata[],
  metadataMappings: MetadataMapping[],
) => {
  //
  // Metadata is quite fragile, so we need to be sure that we only edit/add
  // items that are relevant. This means that:
  //
  // - if a field is pristine we don't want to re-encrypt it
  // - we need to ensure we don't overwrite/delete existing unrelated items
  //
  return metadataMappings.reduce(async (nmd, mapping) => {
    const previous = await nmd
    const initial: string = get(initialValues, mapping.formKey)
    const current: string = get(currentValues, mapping.formKey)

    // Do nothing if values match
    if (initial === current) {
      return previous
    }

    // Or if the corresponding metadata item already exists, overwrite it
    if (getMetadataIndex(metaData, mapping.metadataKey) > -1) {
      return await overwriteMetadata(previous, mapping, current)
    }

    // If metadata item doesn't exist, create it instead
    return await createMetadata(previous, mapping, current)

    // Accumulator values return promises, so initial value needs to be passed as a promise
  }, Promise.resolve(metaData))
}

// Warning: This method has side effects, because it edits an item within 'metadata'
export const overwriteMetadata = async (metadata: UserMetadata[], mapping: MetadataMapping, value: string) => {
  const newMetadata = [...metadata]
  const { metadataKey, encryptionMethod } = mapping
  const index = getMetadataIndex(metadata!, metadataKey)
  const newItem = { ...metadata![index] }

  // The value may or may not need to be encrypted
  let newValue
  if (encryptionMethod) newValue = await encryptionMethod(value || '')
  else newValue = value

  newItem.string_value = newValue
  newMetadata.splice(index, 1, newItem)
  return newMetadata
}

// Warning: This method has side effects, because it adds an item to 'metadata'
export const createMetadata = async (metadata: UserMetadata[], mapping: MetadataMapping, value: string) => {
  const newMetadata = [...metadata]
  const { metadataKey, encryptionMethod } = mapping
  const newItem = { name: metadataKey, user_editable: true, string_value: '' }

  // The value may or may not need to be encrypted
  let newValue
  if (encryptionMethod) newValue = await encryptionMethod(value || '')
  else newValue = value

  newItem.string_value = newValue
  newMetadata.push(newItem)
  return newMetadata
}

export type CredentialsStatusType = 'valid' | 'check' | 'invalid' | 'save' | 'error'

export const CREDENTIALS_BANNER_VALID: CredentialsStatusType = 'valid'
export const CREDENTIALS_BANNER_CHECK: CredentialsStatusType = 'check'
export const CREDENTIALS_BANNER_INVALID: CredentialsStatusType = 'invalid'
export const CREDENTIALS_BANNER_SAVE_PROMPT: CredentialsStatusType = 'save'
export const CREDENTIALS_BANNER_ERROR: CredentialsStatusType = 'error'

export const bannerPrefix = 'userSettings.credentials.banners.'

export const CredentialsBannerMessage: { [key in CredentialsStatusType]: string } = {
  [CREDENTIALS_BANNER_VALID]: `${bannerPrefix}credentialsValid`,
  [CREDENTIALS_BANNER_CHECK]: `${bannerPrefix}checkCreds`,
  [CREDENTIALS_BANNER_INVALID]: `${bannerPrefix}unableToValidate`,
  [CREDENTIALS_BANNER_SAVE_PROMPT]: `${bannerPrefix}newCreditsValid`,
  [CREDENTIALS_BANNER_ERROR]: `${bannerPrefix}providerUnavailable`,
}

export const CredentialsBannerColour: { [key in CredentialsStatusType]: Colour } = {
  [CREDENTIALS_BANNER_VALID]: Colour.Mint,
  [CREDENTIALS_BANNER_CHECK]: Colour.Navy,
  [CREDENTIALS_BANNER_INVALID]: Colour.Yellow,
  [CREDENTIALS_BANNER_SAVE_PROMPT]: Colour.Mint,
  [CREDENTIALS_BANNER_ERROR]: Colour.Red,
}

export const CredentialsBannerIcon: { [key in CredentialsStatusType]: IconName } = {
  [CREDENTIALS_BANNER_VALID]: IconName.CheckCircle,
  [CREDENTIALS_BANNER_CHECK]: IconName.Info,
  [CREDENTIALS_BANNER_INVALID]: IconName.AlertCircle,
  [CREDENTIALS_BANNER_SAVE_PROMPT]: IconName.Info,
  [CREDENTIALS_BANNER_ERROR]: IconName.AlertCircle,
}
