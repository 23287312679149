export const origoOptions = [
  { value: 'AAB00001', label: 'Abattoir Inspector' },
  { value: 'AAC02588', label: 'Abattoir Worker' },
  { value: 'AAD03000', label: 'Abrasive Wheels Worker' },
  { value: 'AAB00010', label: 'Account Executive' },
  { value: 'AAB00011', label: 'Accountant' },
  { value: 'AAD03001', label: 'Accounts Administrator/Assistant' },
  { value: 'AAB00013', label: 'Acidifier Operator' },
  { value: 'AAD03002', label: 'Acrobat' },
  { value: 'AAB00020', label: 'Actor/Actress (no stunt work)' },
  { value: 'AAB00021', label: 'Actuary' },
  { value: 'AAB00022', label: 'Acupuncturist' },
  { value: 'AAC02590', label: 'Administration Manager' },
  { value: 'AAD03003', label: 'Administrator - office' },
  { value: 'AAB00023', label: 'Advertising Manager' },
  { value: 'AAC02591', label: 'Advertising executive' },
  { value: 'AAB00024', label: "Aerial Erector (40' up)" },
  { value: 'AAB00025', label: "Aerial Erector (up to 40')" },
  { value: 'AAB00026', label: 'Aerial Photography' },
  { value: 'AAC02592', label: 'Aerobics Instructor' },
  { value: 'AAB00027', label: 'Agent' },
  { value: 'AAB00028', label: 'Agricultural Engineer' },
  { value: 'AAB00030', label: 'Agricultural Worker' },
  { value: 'AAB00031', label: 'Agronomist' },
  { value: 'AAD03004', label: 'Air Compressor Operator' },
  { value: 'AAB00036', label: 'Air Frame Service Fitter' },
  { value: 'AAB00037', label: 'Air Pump Attendant -Coastal etc' },
  { value: 'AAB00038', label: 'Air Pump Attendant -Deep Sea' },
  { value: 'AAB00039', label: 'Air Traffic Control Assistant' },
  { value: 'AAB00041', label: 'Air Traffic Controller' },
  { value: 'AAC02593', label: 'Air Traffic Planner' },
  { value: 'AAB00042', label: 'Aircraft Electronics Service Fitter' },
  { value: 'AAB00043', label: 'Aircraft Engine Service Fitter' },
  { value: 'AAB00044', label: 'Aircraft Finisher' },
  { value: 'AAB00045', label: 'Aircraft Inspector' },
  { value: 'AAB00046', label: 'Aircraft Instrument Mechanic' },
  { value: 'AAB00047', label: 'Aircraft Joiner' },
  { value: 'AAB00048', label: 'Aircraft Maintenance Technician' },
  { value: 'AAB00049', label: 'Aircraft Marshaller' },
  { value: 'AAB00050', label: 'Aircraft Refueller' },
  { value: 'AAB00051', label: 'Aircrew (including Flight Engineer)' },
  { value: 'AAB00052', label: 'Airline Cabin Staff' },
  { value: 'AAB00053', label: 'Airline Pilots' },
  { value: 'AAB00054', label: 'Airport Manager' },
  { value: 'AAB00055', label: 'Airport Superintendent' },
  { value: 'AAD03392', label: 'Alternative Therapist/Complimentary Therapist' },
  { value: 'AAC02594', label: 'Ambassador' },
  { value: 'AAB00057', label: 'Ambulance Driver' },
  { value: 'AAB00058', label: 'Ambulanceman (No driving)' },
  { value: 'AAB00119', label: 'Amusement Arcade Worker' },
  { value: 'AAD03005', label: 'Amusement Park Worker' },
  { value: 'AAB00060', label: 'Anaesthetist' },
  { value: 'AAD03006', label: 'Analyst - Business' },
  { value: 'AAD03007', label: 'Analyst - City' },
  { value: 'AAD03008', label: 'Analyst - Investment' },
  { value: 'AAD03009', label: 'Analyst - Systems' },
  { value: 'AAD03010', label: 'Analyst- Other' },
  { value: 'AAC02596', label: 'Analytical Chemist' },
  { value: 'AAB00062', label: 'Ancient Monuments Inspector' },
  { value: 'AAB00063', label: 'Animal Nursing Auxiliary' },
  { value: 'AAB00064', label: 'Animal Trainer/Keeper' },
  { value: 'AAB00065', label: 'Animator' },
  { value: 'AAB00066', label: 'Annealer' },
  { value: 'AAB00067', label: 'Announcer - Radio & TV - Entertainment' },
  { value: 'AAB00068', label: 'Announcer - Station Personnel - Railways' },
  { value: 'AAB00069', label: 'Anodiser' },
  { value: 'AAB00070', label: 'Antique Dealer' },
  { value: 'AAB00071', label: 'Antique Restorer' },
  { value: 'AAB00073', label: 'Arc Welder' },
  { value: 'AAB00074', label: 'Archaeologist' },
  { value: 'AAC02597', label: 'Archaeologist (other countries)' },
  { value: 'AAB00075', label: 'Architect' },
  { value: 'AAC02598', label: 'Architect (office)' },
  { value: 'AAB00076', label: 'Archivist' },
  { value: 'AAD03014', label: 'Armed Forces - Army - SAS' },
  { value: 'AAD03011', label: 'Armed Forces - Army - aircrew' },
  { value: 'AAD03012', label: 'Armed Forces - Army - bomb disposal' },
  { value: 'AAD03013', label: 'Armed Forces - Army - no bomb disposal' },
  { value: 'AAD03015', label: 'Armed Forces - Full time reservist - no special duties' },
  { value: 'AAD03016', label: 'Armed Forces - Full time reservist - special duties' },
  { value: 'AAD03019', label: 'Armed Forces - Navy - SBS' },
  { value: 'AAD03017', label: 'Armed Forces - Navy - aircrew' },
  { value: 'AAD3390,', label: 'rmed Forces - Navy - diving' },
  { value: 'AAD03018', label: 'Armed Forces - Navy - no diving' },
  { value: 'AAD03389', label: 'Armed Forces - RAF - aircrew' },
  { value: 'AAD03020', label: 'Armed Forces - RAF - no flying' },
  { value: 'AAB00079', label: 'Aromatherapist' },
  { value: 'AAB00080', label: 'Arranger' },
  { value: 'AAB00081', label: 'Art Director' },
  { value: 'AAB00082', label: 'Art Gallery Attendant' },
  { value: 'AAB00083', label: 'Art Gallery Curator' },
  { value: 'AAB00084', label: 'Art Gallery Guide' },
  { value: 'AAC02599', label: 'Art Gallery Manager - Commercial' },
  { value: 'AAB00085', label: 'Artexer' },
  { value: 'AAB00086', label: 'Artist - Freelance Painter' },
  { value: 'AAB00087', label: 'Artist Commercial' },
  { value: 'AAB00088', label: "Artist's Model" },
  { value: 'AAD03021', label: 'Asbestos Inspector' },
  { value: 'AAB00089', label: 'Asbestos Worker' },
  { value: 'AAB00095', label: 'Asphalter' },
  { value: 'AAB00108', label: 'Assembler - Woodworking Industry' },
  { value: 'AAB00109', label: 'Assembly Inspector' },
  { value: 'AAC02600', label: 'Assessor (claims/insurance)' },
  { value: 'AAB00110', label: 'Assistant Cameraman' },
  { value: 'AAB00111', label: 'Assistant Director' },
  { value: 'AAB00112', label: 'Assistant Editor' },
  { value: 'AAB00113', label: 'Assistant Superintendent' },
  { value: 'AAB00114', label: 'Assistant Tool Pusher' },
  { value: 'AAB00115', label: 'Associate Producer' },
  { value: 'AAC02601', label: 'Assumed Non-Hazardous (for quotation only)' },
  { value: 'AAB00116', label: 'Astrologer' },
  { value: 'AAB00117', label: 'Astronomer' },
  { value: 'AAC02602', label: 'Atomic Energy Worker' },
  { value: 'AAB00120', label: 'Attendant - Bingo - Entertainment' },
  { value: 'AAB00121', label: 'Attendant - Fairground etc - Entertainment' },
  { value: 'AAD03022', label: 'Au Pair' },
  { value: 'AAB00122', label: 'Auctioneer' },
  { value: 'AAB00123', label: 'Audiometrician' },
  { value: 'AAB00124', label: 'Auditor' },
  { value: 'AAB00125', label: 'Author' },
  { value: 'AAB00126', label: 'Autoclave Operator' },
  { value: 'AAB00127', label: 'Autolysis Man' },
  { value: 'AAB00128', label: 'Automatic Train Attendant' },
  { value: 'AAD03023', label: 'Auxiliary Nurse' },
  { value: 'BAB00130', label: 'Baggage Handler' },
  { value: 'BAD03025', label: 'Baggage Manager' },
  { value: 'BAB00133', label: 'Baggage Master' },
  { value: 'BAB00134', label: 'Baggage Porter' },
  { value: 'BAD03026', label: 'Bailiff' },
  { value: 'BAC02606', label: 'Baker' },
  { value: 'BAB00135', label: 'Bakery Equipment Operator' },
  { value: 'BAC02607', label: 'Bakery Shop Manager' },
  { value: 'BAB00137', label: 'Baler' },
  { value: 'BAB00138', label: 'Band Leader' },
  { value: 'BAB00139', label: 'Band Mill Sawyer' },
  { value: 'BAB00141', label: 'Bank Staff' },
  { value: 'BAD03027', label: 'Banksman' },
  { value: 'BAB00152', label: "Banksman's Assistant" },
  { value: 'BAC02609', label: 'Bar Manager/Proprietor' },
  { value: 'BAB00153', label: 'Bar Steward' },
  { value: 'BAC02610', label: 'Barber' },
  { value: 'BAC02611', label: 'Barber - Shop Manager/Proprietor' },
  { value: 'BAB00154', label: 'Bargeman - Merchant Marine' },
  { value: 'BAB00155', label: 'Bargeman - Quarrying' },
  { value: 'BAB00156', label: 'Bargemaster' },
  { value: 'BAB00157', label: 'Barley Roaster' },
  { value: 'BAB00158', label: 'Barmaid' },
  { value: 'BAB00159', label: 'Barman' },
  { value: 'BAB00160', label: 'Barrelman' },
  { value: 'BAC02612', label: 'Barrister' },
  { value: 'BAB00161', label: 'Barrister, Advocate' },
  { value: 'BAB00163', label: 'Batman' },
  { value: 'BAB00164', label: 'Battery Assembler' },
  { value: 'BAB00165', label: 'Battery Repairer' },
  { value: 'BAB00167', label: 'Beautician' },
  { value: 'BAC02613', label: 'Beautician Shop Manager/Proprietor' },
  { value: 'BAC02614', label: 'Bed & Breakfast Proprietor' },
  { value: 'BAB00168', label: 'Beekeeper, Apiarist' },
  { value: 'BAB00169', label: 'Belt Maker' },
  { value: 'BAB00170', label: 'Belt Patrol Man' },
  { value: 'BAB00171', label: 'Bench Hand - Production Fitting - Metal Manufacture' },
  { value: 'BAB00172', label: 'Bench Hand - Rubber Industry - Natural' },
  { value: 'BAB00173', label: 'Berthing Superintendent' },
  { value: 'BAC02615', label: 'Betting Shop Manager (on course)' },
  { value: 'BAC02616', label: 'Betting Shop Manager (shop based)' },
  { value: 'BAB00174', label: 'Bill Poster/Sticker' },
  { value: 'BAD03029', label: 'Bin Man' },
  { value: 'BAB00176', label: 'Bindery Assistant' },
  { value: 'BAB00177', label: 'Binding Machine Attendant' },
  { value: 'BAD03030', label: 'Bingo Hall Manager' },
  { value: 'BAC02618', label: 'Biochemist' },
  { value: 'BAC02619', label: 'Biochemist - Lecturing and research' },
  { value: 'BAC02620', label: 'Biological scientist' },
  { value: 'BAB00178', label: 'Biologist (No travel/ underwater)' },
  { value: 'BAB00179', label: 'Biologist (Overseas travel)' },
  { value: 'BAB00180', label: 'Biologist (Underwater work)' },
  { value: 'BAB00181', label: 'Biscuit Baker' },
  { value: 'BAB00182', label: 'Blacksmith' },
  { value: 'BAB00183', label: 'Blancher' },
  { value: 'BAD03031', label: 'Blaster - quarry' },
  { value: 'BAB00184', label: 'Bleacher - Paper & Board Manufacture' },
  { value: 'BAB00185', label: 'Bleacher - Textile & Clothing Industry' },
  { value: 'BAB00186', label: 'Blender' },
  { value: 'BAB00187', label: 'Block Cutter' },
  { value: 'BAB00188', label: 'Boarding School Matron' },
  { value: 'BAB00189', label: 'Boat Builder' },
  { value: 'BAB00190', label: 'Boatswain - Fishing Industry' },
  { value: 'BAB00191', label: 'Boatswain - Merchant Marine' },
  { value: 'BAB00192', label: "Boatswain's Mate" },
  { value: 'BAB00193', label: 'Bodyguard' },
  { value: 'BAB00194', label: 'Boiler - Confectionery etc - Food & Drink' },
  { value: 'BAB00195', label: 'Boiler - Fruit & Veg. - Food & Drink' },
  { value: 'BAB00196', label: 'Boiler - Meat, Fish etc - Food & Drink' },
  { value: 'BAB00197', label: 'Boiler Cleaner' },
  { value: 'BAB00198', label: 'Boiler Operator - Electrical Supply' },
  { value: 'BAB00199', label: 'Boiler Operator - Water Supply Industry' },
  { value: 'BAB00200', label: 'Boiler Operator/Fireman' },
  { value: 'BAB00201', label: 'Bomb Disposal - Elsewhere' },
  { value: 'BAB00202', label: 'Bomb Disposal - Mainland Britain' },
  { value: 'BAB00203', label: 'Book Illustrator' },
  { value: 'BAD03032', label: 'Book Seller' },
  { value: 'BAB00204', label: 'Book-Keeper' },
  { value: 'BAB00205', label: 'Bookbinder' },
  { value: 'BAB00206', label: 'Bookmaker - On course' },
  { value: 'BAB00207', label: 'Bookmaker - Shop Manager' },
  { value: 'BAB00208', label: 'Bookmaker - Shop Owner' },
  { value: 'BAB00209', label: 'Bookmaker - Shop Staff' },
  { value: 'BAB00210', label: 'Boom Operator' },
  { value: 'BAB00212', label: 'Borer - Mining' },
  { value: 'BAB00211', label: 'Borer - Tunnelling' },
  { value: 'BAB00213', label: 'Borstal Matron' },
  { value: 'BAB00214', label: 'Bosun (Third Hand)' },
  { value: 'BAB00215', label: 'Botanist (No overseas field work)' },
  { value: 'BAB00216', label: 'Bottle Washer (hand or machine)' },
  { value: 'BAB00217', label: 'Bottling Machine Attendant' },
  { value: 'BAB00218', label: 'Box Maker' },
  { value: 'BAD03033', label: 'Box Office Cashier' },
  { value: 'BAD03034', label: 'Box Office Clerk' },
  { value: 'BAB00224', label: 'Box Office Manager' },
  { value: 'BAB00226', label: 'Brakesman' },
  { value: 'BAB00227', label: 'Brazer' },
  { value: 'BAB00228', label: 'Bread Baker' },
  { value: 'BAB00229', label: 'Bread Roundsman' },
  { value: 'BAD03035', label: 'Breakdown Recovery Man' },
  { value: 'BAB00230', label: 'Brewer' },
  { value: 'BAB00231', label: 'Brewery Manager' },
  { value: 'BAB00232', label: 'Bricklayer' },
  { value: 'BAB00233', label: 'Bridge Man' },
  { value: 'BAD03036', label: 'Briner' },
  { value: 'BAD03037', label: 'Broker - Insurance IFA' },
  { value: 'BAD03038', label: 'Broker - Insurance Non IFA' },
  { value: 'BAD03039', label: 'Broker - Money/investments' },
  { value: 'BAD03388', label: 'Broker - Oil' },
  { value: 'BAD03040', label: 'Broker - Other' },
  { value: 'BAB00236', label: 'Bronzer' },
  { value: 'BAB00237', label: 'Broom/Brush Maker' },
  { value: 'BAB00238', label: 'Buffet Car Attendant' },
  { value: 'BAC02621', label: 'Builder' },
  { value: 'BAB00239', label: 'Building Inspector' },
  { value: 'BAC02623', label: 'Building Site Agent - Building and construction' },
  { value: 'BAD03041', label: 'Building Society worker' },
  { value: 'BAB00241', label: 'Building Surveyor' },
  { value: 'BAB00243', label: 'Bulldozer Driver' },
  { value: 'BAD03042', label: 'Bunker Control man' },
  { value: 'BAC02625', label: 'Burglar Alarm Fitter' },
  { value: 'BAB00250', label: 'Bus Conductor (No driving)' },
  { value: 'BAB00251', label: 'Bus Driver' },
  { value: 'BAB00252', label: 'Bus Inspector' },
  { value: 'BAB00253', label: 'Business Consultant' },
  { value: 'BAB00255', label: 'Butcher' },
  { value: 'BAC02627', label: 'Butcher Shop Proprietor' },
  { value: 'BAB00256', label: 'Butler' },
  { value: 'BAB00257', label: 'Butter Blender' },
  { value: 'BAB00258', label: 'Butter Maker' },
  { value: 'BAC02628', label: 'Buyer - retail' },
  { value: 'BAD03044', label: 'Buyer - stocks and shares' },
  { value: 'CAD03055', label: 'CC TV Installer/Maintenance - 40 ft and over' },
  { value: 'CAD03056', label: 'CC TV Installer/Maintenance - under 40 ft' },
  { value: 'CAB00259', label: 'Cabin Boy' },
  { value: 'CAB00260', label: 'Cabinet Maker' },
  { value: 'CAB00261', label: 'Cable Former' },
  { value: 'CAB00262', label: 'Cable Hand' },
  { value: 'CAB00263', label: 'Cable Jointer' },
  { value: 'CAB00264', label: 'Cable Laying Diver' },
  { value: 'CAB00265', label: 'Cable Tester' },
  { value: 'CAB00266', label: 'Cafe Cashier' },
  { value: 'CAC02629', label: 'Cafe Manager' },
  { value: 'CAB00267', label: 'Cafe Proprietor (Licensed)' },
  { value: 'CAB00268', label: 'Cafe Proprietor (Unlicensed)' },
  { value: 'CAD03046', label: 'Café Worker' },
  { value: 'CAB00270', label: 'Calibrator' },
  { value: 'CAD03047', label: 'Call Centre Manager' },
  { value: 'CAD03048', label: 'Call Centre Worker' },
  { value: 'CAB00271', label: 'Caller' },
  { value: 'CAC02630', label: 'Calligrapher' },
  { value: 'CAC02631', label: 'Camera Repair Technician' },
  { value: 'CAD03049', label: 'Cameraman Outside Work' },
  { value: 'CAB00273', label: 'Cameraman Studio' },
  { value: 'CAD03050', label: 'Cameraman War or Disaster reporting' },
  { value: 'CAB00275', label: 'Candle Maker' },
  { value: 'CAB00276', label: 'Canine Beautician' },
  { value: 'CAC02632', label: 'Canine Behaviourist' },
  { value: 'CAB00277', label: 'Canning Machine Attendant' },
  { value: 'CAB00278', label: 'Canteen Assistant' },
  { value: 'CAB00279', label: 'Canteen Manager' },
  { value: 'CAB00280', label: 'Canvasser' },
  { value: 'CAB00282', label: 'Captain - Merchant Marine' },
  { value: 'CAB00281', label: 'Captain - Oil & Natural Gas Industries' },
  { value: 'CAB00284', label: 'Car Delivery Driver' },
  { value: 'CAC02633', label: 'Car Hire Company Proprietor (admin. and driving)' },
  { value: 'CAB00285', label: 'Car Lasher' },
  { value: 'CAB00286', label: 'Car Park Attendant' },
  { value: 'CAC02634', label: 'Car Rental Company Manager' },
  { value: 'CAD03051', label: 'Car Rental Company Worker' },
  { value: 'CAB00287', label: 'Car Salesman (S/E or commission)' },
  { value: 'CAB00288', label: 'Car Salesman (Salaried)' },
  { value: 'CAB00289', label: 'Car Valeter' },
  { value: 'CAB00306', label: 'Car Wash Attendant' },
  { value: 'CAD03052', label: 'Caravan Site Manager' },
  { value: 'CAD03053', label: 'Caravan Site Staff' },
  { value: 'CAB00290', label: 'Carbon Printer' },
  { value: 'CAB00291', label: 'Carbonation Man' },
  { value: 'CAB00292', label: 'Carboniser' },
  { value: 'CAB00293', label: 'Care Assistant' },
  { value: 'CAC02635', label: 'Care Worker - Residential' },
  { value: 'CAC02636', label: 'Careers Advisor' },
  { value: 'CAB00294', label: 'Caretaker, Janitor' },
  { value: 'CAB00295', label: 'Cargo Clerk' },
  { value: 'CAB00296', label: 'Cargo Superintendent' },
  { value: 'CAB00299', label: 'Carpenter & Joiner' },
  { value: 'CAB00297', label: 'Carpenter - Construction Industry' },
  { value: 'CAB00298', label: 'Carpenter - Film Industry - Entertainment' },
  { value: 'CAC02637', label: 'Carpet Cleaner' },
  { value: 'CAC02638', label: 'Carpet Company director (office based admin. only)' },
  { value: 'CAC02639', label: 'Carpet Designer' },
  { value: 'CAB00300', label: 'Carpet Fitter' },
  { value: 'CAC02640', label: 'Carpet Salesman' },
  { value: 'CAC02642', label: 'Carpet Shop Assistant' },
  { value: 'CAC02643', label: 'Carpet Shop Manager (admin.)' },
  { value: 'CAC02644', label: 'Carpet Shop Owner (no manual duties)' },
  { value: 'CAB00302', label: 'Carriage Cleaner' },
  { value: 'CAB00301', label: 'Carriage Examiner' },
  { value: 'CAB00303', label: 'Cartographer' },
  { value: 'CAB00304', label: 'Cartoonist' },
  { value: 'CAB00305', label: 'Cartridge Filler' },
  { value: 'CAC02645', label: 'Cashier - Bank, Building Society' },
  { value: 'CAC02646', label: 'Cashier - Shop, cafe, supermarket, bingo' },
  { value: 'CAB00309', label: 'Casino Cashier' },
  { value: 'CAB00310', label: 'Caster' },
  { value: 'CAB00311', label: 'Casting Director' },
  { value: 'CAB00312', label: 'Casting Machine Operator' },
  { value: 'CAC02647', label: 'Caterer - offshore/at sea' },
  { value: 'CAC02648', label: 'Catering Assistant' },
  { value: 'CAC02649', label: 'Catering Manager' },
  { value: 'CAB00317', label: 'Cathead Man' },
  { value: 'CAD03054', label: 'Caulker' },
  { value: 'CAB00323', label: 'Ceiling Fixer' },
  { value: 'CAB00324', label: 'Cell Tester' },
  { value: 'CAB00325', label: 'Cementer' },
  { value: 'CAD03057', label: 'Cemetery Worker' },
  { value: 'CAB00326', label: 'Ceramicist' },
  { value: 'CAB00328', label: 'Chain Maker' },
  { value: 'CAB00329', label: 'Chair Maker' },
  { value: 'CAC02653', label: 'Chambermaid - Housekeeper' },
  { value: 'CAB00330', label: 'Charge Nurse' },
  { value: 'CAD03058', label: 'Charity Worker - Admin Only' },
  { value: 'CAD03390', label: 'Charity Worker - UK Work' },
  { value: 'CAD03391', label: 'Charity Worker - Overseas Work' },
  { value: 'CAC02654', label: 'Chartered Engineer (some site duties)' },
  { value: 'CAC02655', label: 'Chartered Engineered (admin. only)' },
  { value: 'CAC02656', label: 'Chartered Surveyor (admin only)' },
  { value: 'CAC02657', label: 'Chartered Surveyor (some site duties)' },
  { value: 'CAB00332', label: 'Chassis Builder' },
  { value: 'CAB00333', label: 'Chauffeur' },
  { value: 'CAB00336', label: 'Chef' },
  { value: 'CAB00344', label: 'Chemical Plumber' },
  { value: 'CAB00345', label: "Chemical Plumber's Mate" },
  { value: 'CAD03059', label: 'Chemical engineer - UK' },
  { value: 'CAB00340', label: 'Chemical engineer - offshore' },
  { value: 'CAD03060', label: 'Chemist - industrial' },
  { value: 'CAD03061', label: 'Chemist - retail' },
  { value: 'CAC02658', label: 'Child Protection Co-ordinator' },
  { value: 'CAD03062', label: 'Child Support Agency (CSA) worker' },
  { value: 'CAC02659', label: 'Child Welfare Officer' },
  { value: 'CAB00368', label: 'Childminder' },
  { value: 'CAB00370', label: "Children's Inspector" },
  { value: 'CAB00371', label: "Children's Matron" },
  { value: 'CAC02660', label: "Children's Nursery Proprietor" },
  { value: 'CAC02661', label: "Children's Play-group Leader" },
  { value: 'CAB00372', label: 'Chimney Sweep' },
  { value: 'CAC02662', label: 'Chip Shop Owner' },
  { value: 'CAD03063', label: 'Chip Shop Worker' },
  { value: 'CAB00373', label: 'Chip/Money Changer' },
  { value: 'CAB00374', label: 'Chipper & Painter' },
  { value: 'CAB00375', label: 'Chipper (hand)' },
  { value: 'CAB00376', label: 'Chipping Driver' },
  { value: 'CAB00377', label: 'Chiropodist' },
  { value: 'CAB00378', label: 'Chiropracter' },
  { value: 'CAC02664', label: 'Church Organist' },
  { value: 'CAD03064', label: 'Cinema Projectionist' },
  { value: 'CAB00383', label: 'Circus Hand' },
  { value: 'CAD03065', label: 'Circus Manager' },
  { value: 'CAD03066', label: 'Civil Engineer' },
  { value: 'CAD03067', label: 'Civil Servant' },
  { value: 'CAB00390', label: 'Claims Adjuster' },
  { value: 'CAB00391', label: 'Claims Assessor' },
  { value: 'CAD03068', label: 'Cleaner - commercial premises' },
  { value: 'CAC02666', label: 'Cleaner - domestic premises' },
  { value: 'CAD03069', label: 'Cleaner - industrial' },
  { value: 'CAB00405', label: 'Clergy' },
  { value: 'CAB00406', label: 'Clerical Staff' },
  { value: 'CAB00407', label: 'Clerical Worker' },
  { value: 'CAB00409', label: 'Clerk of Works' },
  { value: 'CAB00412', label: 'Cloakroom Attendant - Club/Nightclub - Entertainment' },
  { value: 'CAB00413', label: 'Cloakroom Attendant - Theatre, Ballet etc - Entertainment' },
  { value: 'CAB00414', label: 'Clock & Watch Assembler' },
  { value: 'CAB00415', label: 'Clock/Watch Maker' },
  { value: 'CAB00416', label: 'Clock/Watch Repairer' },
  { value: 'CAB00417', label: 'Cloth Cutter' },
  { value: 'CAC02671', label: 'Clothing Designer' },
  { value: 'CAB00418', label: 'Clown' },
  { value: 'CAB00419', label: 'Club Manager' },
  { value: 'CAB00420', label: 'Club Proprietor' },
  { value: 'CAB00421', label: 'Club Steward' },
  { value: 'CAD03070', label: 'Coach - Sports' },
  { value: 'CAB00427', label: 'Coach Driver' },
  { value: 'CAB00428', label: 'Coach Painter' },
  { value: 'CAB00431', label: 'Coal Cutter Mover' },
  { value: 'CAB00432', label: 'Coal Cutterman' },
  { value: 'CAB00433', label: 'Coal Dry Cleaning Plant Operator' },
  { value: 'CAB00434', label: 'Coal Face Workers' },
  { value: 'CAB00435', label: 'Coal Melter' },
  { value: 'CAB00436', label: 'Coal Merchant - admin only' },
  { value: 'CAB00437', label: 'Coal Merchant - some delivery' },
  { value: 'CAB00438', label: 'Coal Trimmer' },
  { value: 'CAB00439', label: 'Coal Washery Operator' },
  { value: 'CAB00440', label: 'Coal Yard Foreman' },
  { value: 'CAB00441', label: 'Coal Yard Man' },
  { value: 'CAB00442', label: 'Coastguard (Office based)' },
  { value: 'CAB00443', label: 'Coastguard (Otherwise)' },
  { value: 'CAB00444', label: 'Coffin Maker' },
  { value: 'CAB00445', label: 'Coil Former' },
  { value: 'CAB00446', label: 'Coil Winder' },
  { value: 'CAB00447', label: 'College Lecturer' },
  { value: 'CAB00449', label: 'Colour Calculator' },
  { value: 'CAB00450', label: 'Colour Matcher' },
  { value: 'CAB00451', label: 'Colour Mixer' },
  { value: 'CAB00452', label: 'Columnist' },
  { value: 'CAB00453', label: 'Comedian' },
  { value: 'CAB00454', label: 'Commentator - no overseas travel etc' },
  { value: 'CAB00455', label: 'Commentator - otherwise' },
  { value: 'CAB00456', label: 'Commercial Diving' },
  { value: 'CAC02674', label: 'Commercial Manager (office sales)' },
  { value: 'CAB00457', label: 'Commercial Pilots' },
  { value: 'CAC02675', label: 'Commercial Traveller' },
  { value: 'CAB00458', label: 'Commissionaire' },
  { value: 'CAD03071', label: 'Community Development Worker' },
  { value: 'CAB00460', label: 'Community Nurse' },
  { value: 'CAC02678', label: 'Company Director (admin. duties only)' },
  { value: 'CAC02679', label: 'Company Secretary' },
  { value: 'CAB00462', label: 'Compass Adjuster' },
  { value: 'CAC02680', label: 'Compliance Manager' },
  { value: 'CAB00463', label: 'Composer' },
  { value: 'CAB00464', label: 'Compositor' },
  { value: 'CAB00465', label: 'Compounder' },
  { value: 'CAC02681', label: 'Computer Analyst' },
  { value: 'CAC02682', label: 'Computer Company Technical Support Manager' },
  { value: 'CAB00466', label: 'Computer Operator' },
  { value: 'CAC02683', label: 'Computer Programmer' },
  { value: 'CAB00467', label: 'Computer Programmer/Analyst' },
  { value: 'CAC02684', label: 'Computer Salesman (office based)' },
  { value: 'CAC02685', label: 'Computer Software Manager' },
  { value: 'CAC02686', label: 'Computer Software Salesman (includes travelling)' },
  { value: 'CAC02687', label: 'Computer Systems Installer' },
  { value: 'CAC02688', label: 'Computer Wirer' },
  { value: 'CAC02689', label: 'Computer Workshop Technical Engineer' },
  { value: 'CAC02690', label: 'Concert Promoter' },
  { value: 'CAB00468', label: "Concrete Erector - 40' up" },
  { value: 'CAB00469', label: "Concrete Erector - up to 40'" },
  { value: 'CAB00470', label: 'Concrete Finisher' },
  { value: 'CAB00471', label: 'Concrete Paving Driver' },
  { value: 'CAB00472', label: 'Concrete Shutterer' },
  { value: 'CAB00473', label: 'Concreter' },
  { value: 'CAB00474', label: 'Conductor - Music Industry - Entertainment' },
  { value: 'CAB00475', label: 'Conductor - Train Crew - Railways' },
  { value: 'CAC02691', label: 'Confectioner' },
  { value: 'CAC02692', label: 'Conference Organising Assistant' },
  { value: 'CAC02693', label: 'Conference Organising Manager' },
  { value: 'CAB00476', label: 'Conjurer' },
  { value: 'CAB00478', label: 'Construction Work' },
  { value: 'CAB00480', label: 'Contact Lens Technician' },
  { value: 'CAB00481', label: 'Continuity Clerk' },
  { value: 'CAB00482', label: 'Control Engineer' },
  { value: 'CAD03072', label: 'Control Room Operator' },
  { value: 'CAD03073', label: 'Conveyancer' },
  { value: 'CAD03074', label: 'Conveyer Operator' },
  { value: 'CAB00508', label: 'Cook' },
  { value: 'CAB00514', label: 'Cooper' },
  { value: 'CAB00515', label: 'Coppersmith' },
  { value: 'CAB00516', label: 'Copyholder' },
  { value: 'CAB00517', label: 'Copyholder (Newspapers etc)' },
  { value: 'CAB00518', label: 'Copytaster' },
  { value: 'CAC02695', label: 'Copywriter' },
  { value: 'CAB00519', label: 'Core Borer' },
  { value: 'CAB00520', label: 'Core Builder' },
  { value: 'CAB00521', label: 'Coremaker' },
  { value: 'CAB00522', label: 'Coroner' },
  { value: 'CAB00524', label: 'Correspondent - no overseas travel etc - Journalism' },
  { value: 'CAB00523', label: 'Correspondent - no overseas travel etc - Radio & TV - Entertainment' },
  { value: 'CAB00526', label: 'Correspondent - otherwise - Journalism' },
  { value: 'CAB00525', label: 'Correspondent - otherwise - Radio & TV - Entertainment' },
  { value: 'CAB00527', label: 'Costermonger' },
  { value: 'CAB00528', label: 'Costume Designer' },
  { value: 'CAC02696', label: 'Costumier' },
  { value: 'CAB00529', label: 'Counsellor' },
  { value: 'CAB00530', label: 'Counter Staff - Dry Cleaning' },
  { value: 'CAB00531', label: 'Counter Staff - Laundry' },
  { value: 'CAB00532', label: 'Counter Staff - Post Office/Telecommunications' },
  { value: 'CAC02697', label: 'Couriers' },
  { value: 'CAB00533', label: 'Court Bailiff' },
  { value: 'CAB00534', label: 'Court Usher' },
  { value: 'CAB00535', label: 'Crab Fisherman' },
  { value: 'CAB00542', label: 'Crane Erector' },
  { value: 'CAD03075', label: 'Crane Operator' },
  { value: 'CAD03076', label: 'Crane Slinger' },
  { value: 'CAC02698', label: 'Credit Agent' },
  { value: 'CAC02699', label: 'Credit Controller' },
  { value: 'CAB00559', label: 'Critic' },
  { value: 'CAD03077', label: 'Crop Sprayer - on ground' },
  { value: 'CAD03078', label: 'Crop Sprayer - pilot' },
  { value: 'CAB00562', label: 'Crossing Keeper' },
  { value: 'CAB00563', label: 'Croupier' },
  { value: 'CAB00566', label: 'Crushing Worker' },
  { value: 'CAC02700', label: 'Curator (museum)' },
  { value: 'CAB00567', label: 'Curator - Zoo' },
  { value: 'CAB00568', label: 'Curer' },
  { value: 'CAC02701', label: 'Customer Care Officer' },
  { value: 'CAD03079', label: 'Customer Service Staff' },
  { value: 'CAD03080', label: 'Customs and Excise' },
  { value: 'CAD03081', label: 'Cutter' },
  { value: 'CAD03082', label: 'Cutting Machine Operator' },
  { value: 'DAC02702', label: 'Dairyman' },
  { value: 'DAB00582', label: 'Dancer' },
  { value: 'DAC02703', label: 'Dancing Teacher' },
  { value: 'DAD03084', label: 'Data Controller' },
  { value: 'DAD03085', label: 'Data Inputter' },
  { value: 'DAD03086', label: 'Dealer - money/shares/investment' },
  { value: 'DAB00585', label: 'Debt Collector' },
  { value: 'DAB00586', label: 'Deck Chair Attendant' },
  { value: 'DAB00587', label: 'Deck Hand' },
  { value: 'DAB00589', label: 'Deck Officer' },
  { value: 'DAC02706', label: 'Decorator' },
  { value: 'DAB00593', label: 'Delivery Driver' },
  { value: 'DAB00594', label: 'Demolition Worker-no explosives' },
  { value: 'DAB00595', label: 'Demolition Worker-using explosives' },
  { value: 'DAD03087', label: 'Demonstrator' },
  { value: 'DAB00598', label: 'Dental Assistant' },
  { value: 'DAC02707', label: 'Dental Auxiliary' },
  { value: 'DAB00599', label: 'Dental Consultant' },
  { value: 'DAB00600', label: 'Dental Hygienist' },
  { value: 'DAB00601', label: 'Dental Nurse' },
  { value: 'DAB00602', label: 'Dental Practitioner' },
  { value: 'DAD03088', label: 'Dental Receptionist' },
  { value: 'DAB00603', label: 'Dental Technician' },
  { value: 'DAB00604', label: 'Dental Therapist' },
  { value: 'DAB00605', label: 'Dentist' },
  { value: 'DAD03089', label: 'Department Store Manager' },
  { value: 'DAD03090', label: 'Department Store worker' },
  { value: 'DAC02708', label: 'Dermatologist' },
  { value: 'DAD03091', label: 'Design cutter' },
  { value: 'DAB00612', label: 'Designer' },
  { value: 'DAB00616', label: 'Detention Centre Warden' },
  { value: 'DAD03092', label: 'Diamond workers' },
  { value: 'DAB00626', label: 'Die Cutter' },
  { value: 'DAB00627', label: 'Die Setter' },
  { value: 'DAB00629', label: 'Diesel Locomotive Fitter' },
  { value: 'DAC02709', label: 'Dietician' },
  { value: 'DAB00631', label: 'Dining Car Attendant' },
  { value: 'DAD03093', label: 'Dinner Lady' },
  { value: 'DAD03375', label: 'Director - Company - admin only' },
  { value: 'DAD03376', label: 'Director - Company - other' },
  { value: 'DAD03377', label: 'Director - Managing - admin only' },
  { value: 'DAD03378', label: 'Director - Managing - other' },
  { value: 'DAD03379', label: 'Director - Musical' },
  { value: 'DAD03380', label: 'Director - Other' },
  { value: 'DAD03381', label: 'Director - Sales - management only' },
  { value: 'DAD03386', label: 'Director - Sales - some selling' },
  { value: 'FAC02771', label: 'Director - TV and film' },
  { value: 'DAC02710', label: 'Director & Medical Consultant' },
  { value: 'DAB00637', label: 'Disc Jockey' },
  { value: 'DAB00638', label: 'Disinfecting Officer' },
  { value: 'DAB00639', label: 'Disinfestor' },
  { value: 'DAC02711', label: 'Dispatch Rider' },
  { value: 'DAB00640', label: 'Dispatcher' },
  { value: 'DAC02712', label: 'Distiller' },
  { value: 'DAB00641', label: 'Distillery Manager' },
  { value: 'DAB00642', label: 'District Nurse' },
  { value: 'DAC02713', label: 'Diver' },
  { value: 'DAC02714', label: 'Diver (North Sea)' },
  { value: 'DAB00643', label: "Diver's Linesman -Coastal etc" },
  { value: 'DAB00644', label: "Diver's Linesman -Deep Sea" },
  { value: 'DAB00647', label: 'Dock Foreman' },
  { value: 'DAB00648', label: 'Dock Master' },
  { value: 'DAB00649', label: 'Dock Superintendent' },
  { value: 'DAB00650', label: 'Docker' },
  { value: 'DAB00651', label: 'Doctor - Health' },
  { value: 'DAB00652', label: 'Doctor - Merchant Marine' },
  { value: 'DAC02715', label: 'Dog Catcher' },
  { value: 'DAB00655', label: 'Dogger' },
  { value: 'DAB00656', label: 'Domestic Electrician' },
  { value: 'DAB00657', label: 'Domestic Premises Cleaner' },
  { value: 'DAB00658', label: 'Domestic Supervisor (Hospital)' },
  { value: 'DAC02716', label: 'Domestic Tiler' },
  { value: 'DAB00659', label: 'Donkeyman' },
  { value: 'DAB00660', label: 'Door to Door Salesman' },
  { value: 'DAD03094', label: 'Doorman' },
  { value: 'DAC02717', label: 'Double Glazing - Installer/fitter' },
  { value: 'DAC02719', label: 'Double Glazing Surveyor' },
  { value: 'DAD03095', label: 'Drainage Layer/Clearer' },
  { value: 'DAC02720', label: 'Draper' },
  { value: 'DAB00665', label: 'Draughtsman' },
  { value: 'DAB00666', label: 'Drawer (Bar, Plate, Rod, etc)' },
  { value: 'DAB00667', label: 'Drayman' },
  { value: 'DAB00668', label: 'Dredger Driver' },
  { value: 'DAB00669', label: 'Dredgerman' },
  { value: 'DAB00672', label: 'Dresser' },
  { value: 'DAB00673', label: 'Dressmaker' },
  { value: 'DAD03096', label: 'Drier' },
  { value: 'DAD03097', label: 'Driller - offshore' },
  { value: 'DAD03098', label: 'Driller - onshore' },
  { value: 'DAD03101', label: 'Driver - HGV' },
  { value: 'DAD03103', label: 'Driver - PSV' },
  { value: 'DAD03099', label: 'Driver - construction' },
  { value: 'DAD03100', label: 'Driver - delivery' },
  { value: 'DAD03102', label: 'Driver - industrial plant' },
  { value: 'DAC02724', label: 'Driver - refuse' },
  { value: 'DAC02725', label: 'Driver - tractor' },
  { value: 'DAB00699', label: 'Driving Examiner' },
  { value: 'DAB00700', label: 'Driving Instructor' },
  { value: 'DAB00701', label: 'Drop Ball Operator' },
  { value: 'DAB00702', label: 'Dry Cleaning Machine Operator' },
  { value: 'DAB00703', label: 'Dry Salter' },
  { value: 'DAB00708', label: 'Dustman/Refuse Collector' },
  { value: 'DAB00710', label: 'Dyer' },
  { value: 'EAD03104', label: 'Ecological Consultant Outside UK' },
  { value: 'EAD03105', label: 'Ecological Consultant UK' },
  { value: 'EAB00712', label: 'Economist' },
  { value: 'EAD03106', label: 'Editor' },
  { value: 'EAD03107', label: 'Editorial Assistant' },
  { value: 'EAD03108', label: 'Education Assistant' },
  { value: 'EAC02729', label: 'Educational Advisor' },
  { value: 'EAB00715', label: 'Effluent Inspector' },
  { value: 'EAB00716', label: 'Electric Logger' },
  { value: 'EAB00717', label: 'Electrical Contractor' },
  { value: 'EAD03109', label: 'Electrical Engineer' },
  { value: 'EAB00722', label: 'Electrical Fitter' },
  { value: 'EAB00724', label: 'Electrical Wireman' },
  { value: 'EAD03110', label: 'Electrician - offshore' },
  { value: 'EAD03111', label: 'Electrician UK based - domestic' },
  { value: 'EAD03112', label: 'Electrician UK based - industrial' },
  { value: 'EAB00736', label: 'Electronics Fitter' },
  { value: 'EAB00737', label: 'Electronics Installer' },
  { value: 'EAB00738', label: 'Electronics Mechanic' },
  { value: 'EAB00739', label: 'Electronics Repairer' },
  { value: 'EAB00740', label: 'Electronics Service Mechanic' },
  { value: 'EAB00741', label: 'Electronics Wireman' },
  { value: 'EAB00742', label: 'Electroplater' },
  { value: 'EAB00743', label: 'Electrotyper' },
  { value: 'EAB00744', label: 'Embalmer' },
  { value: 'EAD03113', label: 'Embassy Employee' },
  { value: 'EAB00745', label: 'Embroiderer' },
  { value: 'EAC02740', label: 'Employment Agency Owner (admin. only)' },
  { value: 'EAD03114', label: 'Employment Agency worker' },
  { value: 'EAB00746', label: 'Enameller' },
  { value: 'EAB00747', label: 'Engine Driver' },
  { value: 'EAB00748', label: 'Engine Tester' },
  { value: 'EAD03115', label: 'Engineer - admin and site visits only' },
  { value: 'EAD03116', label: 'Engineer - admin only' },
  { value: 'EAD03117', label: 'Engineer - heavy manual' },
  { value: 'EAD03118', label: 'Engineer - light manual' },
  { value: 'EAC02742', label: 'Engineer - offshore' },
  { value: 'EAD03120', label: 'Engineer - sales' },
  { value: 'EAD03121', label: 'Engineer - works at heights over 40 ft' },
  { value: 'EAB00762', label: 'Engineering Fitter' },
  { value: 'EAB00764', label: 'Engineering Technician' },
  { value: 'EAD03122', label: 'Engraver' },
  { value: 'EAB00770', label: 'Enrolled Nurse' },
  { value: 'EAC02746', label: 'Entertainer - Entertainment industry' },
  { value: 'EAC02747', label: 'Entertainment Agent - Entertainment industry' },
  { value: 'EAC02748', label: 'Entertainment Manager - Entertainment industry' },
  { value: 'EAB00771', label: 'Entertainments Officer' },
  { value: 'EAC02749', label: 'Environmental Health Officer' },
  { value: 'EAB00772', label: 'Equestrian Artiste' },
  { value: 'EAB00773', label: 'Equestrianism - Riding Instructor' },
  { value: 'EAB00774', label: 'Equestrianism - Show Jumping' },
  { value: 'EAB00775', label: 'Equipment Cleaner' },
  { value: 'EAB00776', label: 'Erector - Aircraft/Aerospace' },
  { value: 'EAB00777', label: 'Erector - Production Fitting - Metal Manufacture' },
  { value: 'EAB00778', label: 'Escapologist' },
  { value: 'EAB00779', label: 'Estate Agent' },
  { value: 'EAC02750', label: 'Estate Manager - all aspects (no manual work)' },
  { value: 'EAB00780', label: 'Estate Ranger' },
  { value: 'EAB00781', label: 'Estimator' },
  { value: 'EAC02751', label: 'Estimator (mainly office duties)' },
  { value: 'EAB00785', label: 'Etcher (creative)' },
  { value: 'EAB00783', label: 'Etcher - Pottery Industry' },
  { value: 'EAB00782', label: 'Etcher - Precious Metals, Engraving etc - Metal Manufacture' },
  { value: 'EAB00784', label: 'Etcher - Printing Industry' },
  { value: 'EAD03124', label: 'Examiner - process' },
  { value: 'EAB00795', label: 'Excavator Driver' },
  { value: 'EAD03125', label: 'Exhaust Fitter' },
  { value: 'EAB00796', label: 'Exhausterman' },
  { value: 'EAC02752', label: 'Exhibition Foreman' },
  { value: 'EAC02753', label: 'Exhibition Space Sales Manager' },
  { value: 'EAB00797', label: 'Exhibition Stand Fitter' },
  { value: 'EAB00798', label: 'Explosives Inspector' },
  { value: 'EAC02754', label: 'Export Agent' },
  { value: 'EAB00800', label: 'Extruder' },
  { value: 'FAD03126', label: 'Fabric Designer' },
  { value: 'FAD03127', label: 'Fabricator - welder/fitter' },
  { value: 'FAD03128', label: 'Facilities Assistant' },
  { value: 'FAB00803', label: 'Facilities Procurement Officer' },
  { value: 'FAB00806', label: 'Factory Inspector' },
  { value: 'FAC02755', label: 'Factory (worker)' },
  { value: 'FAC02756', label: 'Factory Manager (mainly admin.)' },
  { value: 'FAC02757', label: 'Fairground Worker' },
  { value: 'FAC02760', label: 'Farm Manager (manual duties)' },
  { value: 'FAC02761', label: 'Farm Manager (no manual duties)' },
  { value: 'FAC02762', label: 'Farm Owner (manual duties)' },
  { value: 'FAC02763', label: 'Farm Owner (no manual duties)' },
  { value: 'FAC02764', label: 'Farm Worker/Labourer' },
  { value: 'FAB00811', label: 'Farrier' },
  { value: 'FAB00812', label: 'Fashion Model' },
  { value: 'FAB00813', label: 'Fashion Photographer' },
  { value: 'FAD03129', label: 'Fashion Stylist' },
  { value: 'FAD03130', label: 'Fast Food Restaurant Assistant' },
  { value: 'FAC02767', label: 'Fast Food Restaurant Manager (admin. only)' },
  { value: 'FAB00814', label: 'Fat Extractor Man' },
  { value: 'FAC02768', label: 'Fencing Contractor' },
  { value: 'FAB00816', label: 'Ferryman' },
  { value: 'FAB00821', label: 'Fight Arranger' },
  { value: 'FAB00823', label: 'Film Developer' },
  { value: 'FAB00824', label: 'Film Joiner' },
  { value: 'FAB00825', label: 'Film Processor' },
  { value: 'FAB00827', label: 'Filmsetting Machine Operator' },
  { value: 'FAC02774', label: 'Financial Adviser' },
  { value: 'FAD03131', label: 'Financial Planner/Paraplanner' },
  { value: 'FAD03132', label: 'Finisher - toys and textiles' },
  { value: 'FAB00832', label: 'Fire Eater' },
  { value: 'FAB00833', label: 'Fire Prevention Officer' },
  { value: 'FAB00835', label: 'Firefighter - Fire Service' },
  { value: 'FAB00843', label: 'Fish Farmer' },
  { value: 'FAB00848', label: 'Fish Preparer' },
  { value: 'FAD03133', label: 'Fish and chip shop owner' },
  { value: 'FAD03134', label: 'Fish and chip shop worker' },
  { value: 'FAB00849', label: 'Fisherman' },
  { value: 'FAB00850', label: 'Fishery Officer/Warden' },
  { value: 'FAC02779', label: 'Fishmonger' },
  { value: 'FAC02782', label: 'Fitness instructor' },
  { value: 'FAB00852', label: 'Fitter - Motor Vehicle & Cycle Industry' },
  { value: 'FAB00855', label: 'Fitter-Assembler' },
  { value: 'FAB00858', label: 'Fixer Mason' },
  { value: 'FAB00864', label: 'Flame cutter - 40 ft +' },
  { value: 'FAB00865', label: 'Flame cutter - under 40 ft' },
  { value: 'FAD03135', label: 'Flight Attendant' },
  { value: 'FAB00867', label: 'Flight Dispatcher' },
  { value: 'FAD03138', label: 'Flight Operations Manager' },
  { value: 'FAB00869', label: 'Flight Planner' },
  { value: 'FAB00870', label: 'Floor Layer' },
  { value: 'FAB00871', label: 'Floor Manager' },
  { value: 'FAB00872', label: 'Floor Tiler' },
  { value: 'FAB00873', label: 'Floorman' },
  { value: 'FAC02785', label: 'Floorman - Oil Rig Industry' },
  { value: 'FAC02786', label: 'Florist' },
  { value: 'FAB00874', label: 'Flour Confectioner' },
  { value: 'FAB00876', label: 'Food Technologist' },
  { value: 'FAC02787', label: 'Football Manager - Professional players' },
  { value: 'FAC02788', label: 'Forecourt Attendant' },
  { value: 'FAD03139', label: 'Foreman - heavy manual' },
  { value: 'FAD03140', label: 'Foreman - light manual' },
  { value: 'FAD03141', label: 'Foreman - no manual' },
  { value: 'FAD03142', label: 'Foreman - offshore' },
  { value: 'FAD03143', label: 'Foreman - other' },
  { value: 'FAB00937', label: 'Forest Ranger' },
  { value: 'FAB00938', label: 'Forest Worker' },
  { value: 'FAB00940', label: 'Forestry Officer' },
  { value: 'FAB00941', label: 'Forge Hammerman' },
  { value: 'FAB00942', label: 'Forge Pressman' },
  { value: 'FAB00943', label: 'Forger' },
  { value: 'FAB00944', label: 'Fork Lift Truck Driver' },
  { value: 'FAB00945', label: 'Fortune Teller' },
  { value: 'FAD03136', label: 'Foster Parent' },
  { value: 'FAB00946', label: 'Frame Finisher' },
  { value: 'FAB00947', label: 'Freezer Operator' },
  { value: 'FAB00948', label: 'Freight Clerk' },
  { value: 'FAB00949', label: 'Freight Manager - Airport' },
  { value: 'FAB00950', label: 'Freight Manager - Docks' },
  { value: 'FAB00951', label: 'French Polisher' },
  { value: 'FAC02793', label: 'Fruitier' },
  { value: 'FAB00952', label: 'Fuel Technologist' },
  { value: 'FAB00953', label: 'Funeral Director' },
  { value: 'FAB00954', label: "Funeral Director's Assistant" },
  { value: 'FAB00955', label: 'Furnace Control Room Operator' },
  { value: 'FAB00956', label: 'Furnace Operator - Cemetery, Crematorium' },
  { value: 'FAD03137', label: 'Furnace Operator - Other' },
  { value: 'FAB00961', label: 'Furniture Designer' },
  { value: 'FAC02794', label: 'Furniture Maker' },
  { value: 'FAB00962', label: 'Furniture Remover' },
  { value: 'FAC02795', label: 'Furniture Restorer' },
  { value: 'FAC02796', label: 'Furniture Retailer' },
  { value: 'GAD03146', label: 'GP - general practitioner - Doctor' },
  { value: 'GAD03150', label: 'Gallery Assistant' },
  { value: 'GAC02797', label: 'Gallery Owner (admin. only)' },
  { value: 'GAC02798', label: 'Gallery Owner (including manual work)' },
  { value: 'GAD03155', label: 'Galley Hand' },
  { value: 'GAB00965', label: 'Galvaniser' },
  { value: 'GAB00966', label: 'Gamekeeper' },
  { value: 'GAB00968', label: 'Ganger' },
  { value: 'GAD03156', label: 'Gantry Crane Driver' },
  { value: 'GAC02800', label: 'Garage - Mechanic' },
  { value: 'GAC02801', label: 'Garage - Petrol Pump Attendant' },
  { value: 'GAC02802', label: 'Garage Proprietor - admin only' },
  { value: 'GAC02803', label: 'Garage Repair Shop Supervisor (including manual duties)' },
  { value: 'GAC02804', label: 'Gardener' },
  { value: 'GAB00971', label: 'Gas Appliance Mechanic' },
  { value: 'GAB00972', label: 'Gas Compressor Operator' },
  { value: 'GAB00976', label: 'Gas Fitter' },
  { value: 'GAB00979', label: 'Gas Meter Tester' },
  { value: 'GAB00984', label: 'Gem Cutter' },
  { value: 'GAB00985', label: 'Gem Polisher' },
  { value: 'GAB00986', label: 'Gem Setter' },
  { value: 'GAB00987', label: 'Geologist - Mining' },
  { value: 'GAB00988', label: 'Geologist - Oil & Natural Gas Industries' },
  { value: 'GAB00989', label: 'Geologist - no aerial/offshore etc' },
  { value: 'GAB00990', label: 'Geophysicist - Mining' },
  { value: 'GAB00991', label: 'Geophysicist - Oil & Natural Gas Industries' },
  { value: 'GAD03145', label: 'Glamour Model' },
  { value: 'GAC02806', label: 'Glass Blower' },
  { value: 'GAD03151', label: 'Glass Worker' },
  { value: 'GAB00996', label: 'Glazer' },
  { value: 'GAD03152', label: 'Glazier' },
  { value: 'GAB01000', label: 'Gold Beater' },
  { value: 'GAC02807', label: 'Goldsmith' },
  { value: 'GAB01001', label: 'Golf - Caddie' },
  { value: 'GAB01003', label: 'Governor' },
  { value: 'GAB01004', label: 'Grader' },
  { value: 'GAC02808', label: 'Grain Merchant (office based)' },
  { value: 'GAC02809', label: 'Graphic Designer' },
  { value: 'GAB01007', label: 'Grave Digger' },
  { value: 'GAD03154', label: 'Greaser' },
  { value: 'GAB01010', label: 'Greenkeeper' },
  { value: 'GAD03144', label: 'Grinder' },
  { value: 'GAC02813', label: 'Grocer' },
  { value: 'GAB01019', label: 'Groom' },
  { value: 'GAB01020', label: 'Ground Equipment Service Mechanic' },
  { value: 'GAB01021', label: 'Ground Hostess/Steward' },
  { value: 'GAB01022', label: 'Ground Movement Controller' },
  { value: 'GAB01023', label: 'Groundsman' },
  { value: 'GAD03147', label: 'Groundworker' },
  { value: 'GAD03148', label: 'Guard - railway' },
  { value: 'GAD03149', label: 'Guard - security' },
  { value: 'GAC02815', label: 'Guest House Proprietor' },
  { value: 'GAC02816', label: 'Guest House Proprietor (admin. only)' },
  { value: 'GAB01025', label: 'Guillotine Operator' },
  { value: 'GAB01026', label: 'Gummer' },
  { value: 'GAD03153', label: 'Gunsmith' },
  { value: 'HAC02817', label: 'Haberdasher' },
  { value: 'HAB01028', label: 'Hairdresser - Mobile' },
  { value: 'HAB01029', label: 'Hairdresser - Salon' },
  { value: 'HAC02818', label: 'Hairdresser Shop Manager - admin only' },
  { value: 'HAC02819', label: 'Hairdresser Shop Proprietor' },
  { value: 'HAD03164', label: 'Hammerman' },
  { value: 'HAB01037', label: 'Handyman' },
  { value: 'HAB01038', label: 'Harbour Master' },
  { value: 'HAC02820', label: 'Harbour Pilot' },
  { value: 'HAD03157', label: 'Hardware Shop Retailer' },
  { value: 'HAB01041', label: 'Harness Maker' },
  { value: 'HAB01043', label: 'Hat Maker' },
  { value: 'HAB01044', label: 'Hatchery Worker' },
  { value: 'HAC02822', label: 'Haulage Contractor' },
  { value: 'HAB01046', label: 'Haulier (no driving)' },
  { value: 'HAB01047', label: 'Head Gardener' },
  { value: 'HAB01048', label: 'Head Groundsman' },
  { value: 'HAB01049', label: 'Head Keeper - Zoo' },
  { value: 'HAB01050', label: 'Head Roustabout' },
  { value: 'HAC02823', label: 'Headteacher' },
  { value: 'HAC02824', label: 'Health & Safety Officer' },
  { value: 'HAC02827', label: 'Health Counsellor' },
  { value: 'HAB01053', label: 'Health Radiation Monitor' },
  { value: 'HAC02828', label: 'Health Visitor' },
  { value: 'HAC02825', label: 'Health and Fitness Club Manager (admin. only)' },
  { value: 'HAC02826', label: 'Health and Fitness Club Trainer' },
  { value: 'HAC02829', label: 'Heating and Ventilating Fitter' },
  { value: 'HAC02830', label: 'Heavy Goods Driver (no loading) UK only' },
  { value: 'HAB01064', label: 'Heavy Goods Vehicle Driver' },
  { value: 'HAC02831', label: 'Helicopter Engineer' },
  { value: 'HAC02832', label: 'Helicopter Pilot - Oil Rig Industry' },
  { value: 'HAC02833', label: 'Helicopter Pilot - Onshore' },
  { value: 'HAB01066', label: 'Historic Building Guide' },
  { value: 'HAD03162', label: 'Hod Carrier - construction' },
  { value: 'HAB01067', label: 'Hoist Driver' },
  { value: 'HAD03163', label: 'Hoist Operator' },
  { value: 'HAD03165', label: 'Holiday Representative' },
  { value: 'HAC02834', label: 'Home Help' },
  { value: 'HAC02835', label: 'Homeless Centre Manager (admin. only)' },
  { value: 'HAC02836', label: 'Homeopath' },
  { value: 'HAB01076', label: 'Horse Breeder' },
  { value: 'HAB01077', label: 'Horse Racing - Flat Jockey' },
  { value: 'HAB01078', label: 'Horse Racing - National Hunt' },
  { value: 'HAC02837', label: 'Horticulturist' },
  { value: 'HAB01082', label: 'Hospital Matron' },
  { value: 'HAB01084', label: 'Hospital Porter - Health' },
  { value: 'HAB01085', label: 'Hospital Storeman' },
  { value: 'HAB01086', label: 'Hospital Ward Orderly' },
  { value: 'HAB01087', label: 'Hostel Matron' },
  { value: 'HAB01088', label: 'Hostel Warden' },
  { value: 'HAB01089', label: 'Hostess' },
  { value: 'HAD03166', label: 'Hotel Concierge' },
  { value: 'HAB01090', label: 'Hotel Detective' },
  { value: 'HAB01091', label: 'Hotel Doorman' },
  { value: 'HAB01092', label: 'Hotel Maid' },
  { value: 'HAC02838', label: 'Hotel Manager (office based)' },
  { value: 'HAB01094', label: 'Hotel Porter' },
  { value: 'HAB01095', label: 'Hotel Proprietor' },
  { value: 'HAB01096', label: 'Hotel Receptionist' },
  { value: 'HAB01097', label: 'House Maid' },
  { value: 'HAB01098', label: 'Housekeeper' },
  { value: 'HAB01099', label: 'Housewife/House-Husband' },
  { value: 'HAC02839', label: 'Housing Association Development Manager (inc. site visits)' },
  { value: 'HAB01100', label: 'Housing Inspector' },
  { value: 'HAC02840', label: 'Housing Manager' },
  { value: 'HAD03158', label: 'Human Resources Advisor' },
  { value: 'HAD03159', label: 'Human Resources Analyst' },
  { value: 'HAD03161', label: 'Human Resources Assistant' },
  { value: 'HAD03386', label: 'Human Resources Consultant' },
  { value: 'HAD03387', label: 'Human Resources Officer' },
  { value: 'HAD03160', label: 'Hydro-Extractor Operator' },
  { value: 'HAC02841', label: 'Hydrographic Engineer/Surveyor' },
  { value: 'HAC02842', label: 'Hygienist' },
  { value: 'HAB01104', label: 'Hypnotherapist' },
  { value: 'HAB01105', label: 'Hypnotist' },
  { value: 'IAD03170', label: 'IT Analyst' },
  { value: 'IAD03172', label: 'IT Manager - admin only' },
  { value: 'IAD03174', label: 'IT Programmer' },
  { value: 'IAD03176', label: 'IT Technician' },
  { value: 'IAB01106', label: 'Ice Cream Van Driver' },
  { value: 'IAB01110', label: 'Illusionist' },
  { value: 'IAC02846', label: 'Illustrator' },
  { value: 'IAD03175', label: 'Immigration Officer - admin only' },
  { value: 'IAD03177', label: 'Immigration Officer - otherwise' },
  { value: 'IAB01111', label: 'Impersonator' },
  { value: 'IAB01112', label: 'Importer' },
  { value: 'IAB01114', label: 'Incinerator Operator' },
  { value: 'IAD03171', label: 'Independent Financial Adviser - IFA' },
  { value: 'IAD03173', label: 'Industrial Chemist' },
  { value: 'IAC02847', label: 'Industrial Designer' },
  { value: 'IAC02848', label: 'Industrial Relations Officer' },
  { value: 'IAC02849', label: 'Industrial Trainer' },
  { value: 'IAB01118', label: 'Inseminator' },
  { value: 'IAD03178', label: 'Inspector (not police)' },
  { value: 'IAD03179', label: 'Instructor - aviation, diving, etc' },
  { value: 'IAD03180', label: 'Instructor - no special risks' },
  { value: 'IAC02851', label: 'Instrument Maker' },
  { value: 'IAC02852', label: 'Instrument Repairer' },
  { value: 'IAD03167', label: 'Insulator - asbestos work inc' },
  { value: 'IAD03168', label: 'Insulator - no asbestos work' },
  { value: 'IAB01154', label: 'Insurance Agent' },
  { value: 'IAB01155', label: 'Insurance Assessor' },
  { value: 'IAB01156', label: 'Insurance Broker' },
  { value: 'IAB01157', label: 'Insurance Inspector' },
  { value: 'IAC02853', label: 'Interior Designer' },
  { value: 'IAD03169', label: 'Internal Auditor' },
  { value: 'IAB01158', label: 'Interpreter' },
  { value: 'IAC02854', label: 'Investment Analyst' },
  { value: 'IAB01163', label: 'Ironer' },
  { value: 'JAC02855', label: 'Janitor' },
  { value: 'JAB01167', label: 'Jetty Hand' },
  { value: 'JAC02856', label: 'Jeweller' },
  { value: 'JAB01168', label: 'Jewellery Enameller' },
  { value: 'JAB01169', label: 'Jewellery Making & Repair' },
  { value: 'JAB01170', label: 'Jewellery Mounter' },
  { value: 'JAB01173', label: 'Joiner - Construction Industry' },
  { value: 'JAB01174', label: 'Joiner - Ship Building, Ship Repair & Marine Engineering' },
  { value: 'JAB01175', label: 'Jointer' },
  { value: 'JAB01176', label: 'Journalist - no overseas travel etc' },
  { value: 'JAB01177', label: 'Journalist - otherwise' },
  { value: 'JAB01178', label: 'Judge' },
  { value: 'JAB01179', label: "Judge's Clerk" },
  { value: 'JAB01180', label: 'Juggler' },
  { value: 'JAD03181', label: 'Justice of the Peace' },
  { value: 'KAD03182', label: 'Kebab Van Vendor' },
  { value: 'KAB01181', label: 'Keeper - Zoo' },
  { value: 'KAC02857', label: 'Kennel Hand' },
  { value: 'KAB01184', label: 'Kerb Layer' },
  { value: 'KAD03183', label: 'Key Cutter' },
  { value: 'KAB01185', label: 'Keyboard Operator (type setting)' },
  { value: 'KAB01186', label: 'Kiln Attendant' },
  { value: 'KAB01187', label: 'Kiln Operator' },
  { value: 'KAB01192', label: 'Kitchen Porter' },
  { value: 'KAC02858', label: 'Kitchen Staff' },
  { value: 'KAB01194', label: 'Knife Thrower' },
  { value: 'KAB01195', label: 'Knitter' },
  { value: 'LAB01196', label: 'Labeller' },
  { value: 'LAC02859', label: 'Laboratory Manager (supervisory and some testing)' },
  { value: 'LAC02860', label: 'Laboratory Technician' },
  { value: 'LAD03184', label: 'Labourer' },
  { value: 'LAB01246', label: 'Lagger' },
  { value: 'LAB01249', label: 'Laminator' },
  { value: 'LAB01251', label: 'Land Agent' },
  { value: 'LAB01252', label: 'Land Drainage Worker' },
  { value: 'LAB01253', label: 'Land Surveyor' },
  { value: 'LAD03189', label: 'Landlord (Property -inc manual work)' },
  { value: 'LAC02864', label: 'Landlord (Property -no manual work )' },
  { value: 'LAB01254', label: 'Landscape Gardener' },
  { value: 'LAB01255', label: 'Landscape Painter' },
  { value: 'LAD03191', label: 'Lathe Operator' },
  { value: 'LAB01259', label: 'Launderette Assistant' },
  { value: 'LAB01261', label: 'Laundryman' },
  { value: 'LAB01262', label: 'Lavatory Attendant' },
  { value: 'LAC02868', label: 'Lawyer' },
  { value: 'LAB01265', label: 'Leading Fireman' },
  { value: 'LAB01268', label: 'Leather Technologist' },
  { value: 'LAD03195', label: 'Leather worker' },
  { value: 'LAB01269', label: 'Lecturer' },
  { value: 'LAB01271', label: 'Left Luggage Attendant' },
  { value: 'LAC02869', label: 'Legal Adviser' },
  { value: 'LAC02870', label: 'Legal Executive' },
  { value: 'LAD03185', label: 'Legal Practice Manager' },
  { value: 'LAD03187', label: 'Legal Secretary' },
  { value: 'LAC02872', label: 'Letting Agent - Holiday homes' },
  { value: 'LAB01275', label: 'Librarian' },
  { value: 'LAC02874', label: 'Library Assistant' },
  { value: 'LAD03190', label: 'Life Coach' },
  { value: 'LAB01276', label: 'Lifeboatman (enrolled crew)' },
  { value: 'LAC02875', label: 'Lifeboatman - Crew' },
  { value: 'LAB01277', label: 'Lifeguard' },
  { value: 'LAB01278', label: 'Lift Attendant' },
  { value: 'LAC02876', label: 'Lift Engineer' },
  { value: 'LAC02877', label: 'Lift Erector' },
  { value: 'LAB01280', label: 'Light Goods Vehicle Driver' },
  { value: 'LAD03188', label: 'Lighterman' },
  { value: 'LAB01284', label: 'Lighting Manager' },
  { value: 'LAB01285', label: 'Lighting Technician' },
  { value: 'LAD03192', label: 'Linesman' },
  { value: 'LAB01289', label: "Linesman's Mate" },
  { value: 'LAC02878', label: 'Liquidator' },
  { value: 'LAB01293', label: 'Literary Agent' },
  { value: 'LAC02879', label: 'Lithographer' },
  { value: 'LAB01294', label: 'Lithographic Assistant' },
  { value: 'LAB01295', label: 'Lithographic Plate Grainer' },
  { value: 'LAB01296', label: 'Lithographic Plate Preparer' },
  { value: 'LAD03193', label: 'Loader' },
  { value: 'LAD03194', label: 'Loader Operator' },
  { value: 'LAB01306', label: 'Lobster Fisherman' },
  { value: 'LAB01307', label: 'Local Government Officer' },
  { value: 'LAC02880', label: 'Local Newspaper Editor' },
  { value: 'LAB01309', label: 'Lock Keeper' },
  { value: 'LAB01311', label: 'Locksmith' },
  { value: 'LAB01312', label: 'Locomotive Driver' },
  { value: 'LAB01313', label: 'Locomotive Guard' },
  { value: 'SAB02293', label: 'Lollipop Man/Lady' },
  { value: 'LAB01315', label: 'Loss Adjuster' },
  { value: 'LAB01317', label: 'Lumberjack' },
  { value: 'MAB01318', label: 'Machine Attendant' },
  { value: 'MAB01319', label: 'Machine Maintenance Worker' },
  { value: 'MAD03224', label: 'Machine Operator - processing' },
  { value: 'MAB01358', label: 'Machine Tool Setter-Operator' },
  { value: 'MAB01363', label: 'Machinery Electrician' },
  { value: 'MAD03201', label: 'Machinist' },
  { value: 'MAD03204', label: 'Magazine Editor' },
  { value: 'MAD03207', label: 'Magazine Illustrator' },
  { value: 'MAD03211', label: 'Magazine Writer' },
  { value: 'MAB01367', label: 'Magician' },
  { value: 'MAC02881', label: 'Magistrate - Stipendiary' },
  { value: 'MAC02882', label: 'Mail Sorter' },
  { value: 'MAD03198', label: 'Maintenance Fitter' },
  { value: 'MAD03202', label: 'Maintenance Manager' },
  { value: 'MAB01377', label: 'Maintenance Repairer' },
  { value: 'MAD03216', label: 'Maintenance Technician' },
  { value: 'MAD03221', label: "Maitre d'Hotel" },
  { value: 'MAB01390', label: 'Make-up Artist' },
  { value: 'MAB01393', label: 'Malt Roaster' },
  { value: 'MAB01394', label: 'Maltster' },
  { value: 'MAB01395', label: 'Management Consultant - Usually' },
  { value: 'MAD03222', label: 'Manager - Offshore' },
  { value: 'MAD03228', label: 'Manager - Retail' },
  { value: 'MAD03230', label: 'Manager - Sales' },
  { value: 'MAD03208', label: 'Manager - admin only' },
  { value: 'MAD03212', label: 'Manager - heavy manual work' },
  { value: 'MAD03217', label: 'Manager - light manual work' },
  { value: 'MAD03225', label: 'Manager - other' },
  { value: 'MAB01436', label: 'Manager (off site)' },
  { value: 'MAD03235', label: 'Managing Director - Other' },
  { value: 'MAD03236', label: 'Managing Director - Retail' },
  { value: 'MAD03237', label: 'Managing Director - Sales Management' },
  { value: 'MAD03238', label: 'Managing Director - Selling' },
  { value: 'MAD03232', label: 'Managing Director - admin/office based only' },
  { value: 'MAD03233', label: 'Managing Director - heavy manual duties' },
  { value: 'MAD03234', label: 'Managing Director - light manual duties' },
  { value: 'MAD03239', label: 'Manhole Maker' },
  { value: 'MAB01451', label: 'Manicurist' },
  { value: 'MAD03197', label: 'Marine Biologist' },
  { value: 'MAB01453', label: 'Marine Engineer' },
  { value: 'MAB01454', label: 'Marine Installation Fitter' },
  { value: 'MAB01456', label: 'Marine Surveyor' },
  { value: 'MAB01459', label: 'Market Gardener' },
  { value: 'MAB01460', label: 'Market Porter - Usually' },
  { value: 'MAB01461', label: 'Market Research Analyst' },
  { value: 'MAB01462', label: 'Market Research Interviewer' },
  { value: 'MAC02886', label: 'Market Researcher (office based)' },
  { value: 'MAC02887', label: 'Market Researcher - Street research' },
  { value: 'MAD03226', label: 'Market or Street Trader' },
  { value: 'MAC02885', label: 'Market or Street Traders Assistant' },
  { value: 'MAC02888', label: 'Marketing Consultant - International' },
  { value: 'MAC02889', label: 'Marketing Manager' },
  { value: 'MAC02890', label: 'Marketing Research Manager (office based)' },
  { value: 'MAD03196', label: 'Marriage Guidance Counsellor' },
  { value: 'MAB01464', label: 'Martial Arts Instructor' },
  { value: 'MAB01465', label: 'Mason' },
  { value: 'MAB01466', label: 'Mason Bricklayer' },
  { value: 'MAB01467', label: "Mason's Labourer" },
  { value: 'MAB01468', label: 'Masseur' },
  { value: 'MAB01469', label: 'Masseuse' },
  { value: 'MAB01476', label: 'Mate, Second Hand' },
  { value: 'MAC02891', label: 'Mathematician' },
  { value: 'MAB01478', label: 'Mattress Maker' },
  { value: 'MAB01482', label: 'Meat Cutter' },
  { value: 'MAB01483', label: 'Meat Inspector' },
  { value: 'MAB01484', label: 'Meat Trimmer' },
  { value: 'MAD03205', label: 'Mechanic' },
  { value: 'MAD03209', label: 'Mechanic - Oil Rig' },
  { value: 'MAD03213', label: 'Mechanical Engineer' },
  { value: 'MAB01496', label: 'Medical Practitioner' },
  { value: 'MAD03214', label: 'Medical Receptionist' },
  { value: 'MAD03218', label: 'Medical Secretary' },
  { value: 'MAB01497', label: 'Medium' },
  { value: 'MAB01499', label: 'Member of Parliament, Politician' },
  { value: 'MAC02896', label: 'Messenger - Motorcycle' },
  { value: 'MAC02897', label: 'Messenger - Not motorcycle' },
  { value: 'MAB01503', label: 'Metallographer' },
  { value: 'MAB01504', label: 'Metallurgist' },
  { value: 'MAB01505', label: 'Meteorologist' },
  { value: 'MAD03210', label: 'Meter Collector' },
  { value: 'MAD03215', label: 'Meter Fixer/Tester' },
  { value: 'MAD03219', label: 'Meter Reader' },
  { value: 'MAB01515', label: 'Microfilm Operator' },
  { value: 'MAC02901', label: 'Midwife' },
  { value: 'MAB01516', label: 'Milk Roundsman' },
  { value: 'MAB01517', label: 'Milker' },
  { value: 'MAD03220', label: 'Miller' },
  { value: 'MAD03223', label: 'Milliner' },
  { value: 'MAC02902', label: 'Miner' },
  { value: 'MAD03199', label: 'Mineralogist' },
  { value: 'MAC02903', label: 'Mini Cab Driver' },
  { value: 'MAB01529', label: 'Mining Engineer' },
  { value: 'MAB01530', label: 'Mining Officer' },
  { value: 'MAB01531', label: 'Mining Surveyor' },
  { value: 'MAC02904', label: 'Minister of Religion' },
  { value: 'MAB01532', label: 'Missionary' },
  { value: 'MAD03200', label: 'Mixer - processing' },
  { value: 'MAD03203', label: 'Mobile Crane Driver' },
  { value: 'MAC02905', label: 'Model Maker' },
  { value: 'MAB01546', label: 'Money Broker' },
  { value: 'MAB01548', label: 'Mortuary Attendant' },
  { value: 'MAC02906', label: 'Motor Bike Instructor' },
  { value: 'MAB01551', label: 'Motor Cycle Courier' },
  { value: 'MAC02907', label: 'Motor Cycle Messenger' },
  { value: 'MAC02908', label: 'Motor Fleet Manager' },
  { value: 'MAC02909', label: 'Motor Service Manager - admin only' },
  { value: 'MAD03227', label: 'Motorman' },
  { value: 'MAD03229', label: 'Mould Maker' },
  { value: 'MAD03231', label: 'Moulder' },
  { value: 'MAB01564', label: 'Mud Engineer' },
  { value: 'MAB01565', label: 'Mud Logger' },
  { value: 'MAB01566', label: 'Mud Man' },
  { value: 'MAB01568', label: 'Museum Attendant' },
  { value: 'MAB01569', label: 'Museum Curator' },
  { value: 'MAB01570', label: 'Museum Guide' },
  { value: 'MAB01571', label: 'Music Teacher (Private)' },
  { value: 'MAB01573', label: 'Musical Instrument Maker' },
  { value: 'MAB01574', label: 'Musical Instrument Repairer' },
  { value: 'MAD03206', label: 'Musician - Professional' },
  { value: 'NAD03241', label: 'NHS Manager' },
  { value: 'NAD03243', label: 'Nail Technician/Beautician' },
  { value: 'NAC01811', label: 'Nanny' },
  { value: 'NAD03240', label: 'Nature Reserve Worker/Warden' },
  { value: 'NAB01575', label: 'Navigator' },
  { value: 'NAB01578', label: 'News Photographer - no overseas travel etc' },
  { value: 'NAB01579', label: 'News Photographer - otherwise' },
  { value: 'NAC01812', label: 'Newsagent (not delivering papers)' },
  { value: 'NAC01814', label: 'Newspaper Reporter - Freelance' },
  { value: 'NAB01580', label: 'Newsreader' },
  { value: 'NAB01581', label: 'Newsvendor' },
  { value: 'NAD03242', label: 'Night Watchman' },
  { value: 'NAB01587', label: 'Nuclear Engineer' },
  { value: 'NAD03244', label: 'Nuclear Plant Attendant' },
  { value: 'NAB01591', label: 'Nuclear Scientist' },
  { value: 'NAB01592', label: 'Nurse' },
  { value: 'NAB01597', label: 'Nurse - Midwife' },
  { value: 'NAD03245', label: 'Nurse - Sister' },
  { value: 'NAB01598', label: 'Nurse - Teaching duties only' },
  { value: 'NAD03246', label: 'Nursery School Assistant' },
  { value: 'NAB01599', label: 'Nurseryman' },
  { value: 'NAB01600', label: 'Nursing Auxiliary' },
  { value: 'NAC01817', label: 'Nursing Home Proprietor (admin. only)' },
  { value: 'OAC01818', label: 'Obstetrician' },
  { value: 'OAB01601', label: 'Occupational Therapist' },
  { value: 'OAD03247', label: 'Oceanographer' },
  { value: 'OAD03251', label: 'Off-Licence Employee' },
  { value: 'OAB01602', label: 'Off-Licence Manager' },
  { value: 'OAB01604', label: 'Office Clerk' },
  { value: 'OAB01605', label: 'Office Fitter' },
  { value: 'OAB01606', label: 'Office Messenger' },
  { value: 'OAB01608', label: 'Office Receptionist' },
  { value: 'OAD03248', label: 'Oiler and Greaser' },
  { value: 'OAB01612', label: "Old People's Home Matron" },
  { value: 'OAB01613', label: "Old People's Home Warden" },
  { value: 'OAC08123', label: 'Operations Manager - Light engineering company' },
  { value: 'OAB01616', label: 'Operations Officer' },
  { value: 'OAB01617', label: 'Optical Instrument Fitter' },
  { value: 'OAB01618', label: 'Optical Instrument Maker' },
  { value: 'OAB01619', label: 'Optical Instrument Mechanic' },
  { value: 'OAB01620', label: 'Optical Instrument Repairer' },
  { value: 'OAB01621', label: 'Optical Printer' },
  { value: 'OAB01622', label: 'Optician' },
  { value: 'OAC01826', label: 'Opticians Assistant' },
  { value: 'OAB01625', label: 'Orchestrator' },
  { value: 'OAB01628', label: 'Orthodontic Technician' },
  { value: 'OAB01629', label: 'Orthodontist' },
  { value: 'OAC01827', label: 'Orthopaedic Surgeon' },
  { value: 'OAB01630', label: 'Orthoptist' },
  { value: 'OAB01631', label: 'Osteopath' },
  { value: 'OAD03382', label: 'Other - Occupation not listed' },
  { value: 'OAD03249', label: 'Outdoor Pursuits Centre Instructor' },
  { value: 'OAD03250', label: 'Ovensman' },
  { value: 'OAB01637', label: 'Overhead Crane Driver' },
  { value: 'OAB01638', label: 'Overhead Linesman' },
  { value: 'OAB01639', label: "Overhead Linesman's Mate" },
  { value: 'OAB01643', label: 'Oyster Fisherman' },
  { value: 'PAD03258', label: 'P E Teacher' },
  { value: 'PAC01857', label: 'PR Executive' },
  { value: 'PAB01644', label: 'Packaging Machine Attendant' },
  { value: 'PAD03261', label: 'Packer' },
  { value: 'PAC01828', label: 'Painter' },
  { value: 'PAB01666', label: 'Painter & Decorator (Interior)' },
  { value: 'PAB01668', label: "Painter (Exterior) - 40' up" },
  { value: 'PAB01669', label: "Painter (Exterior) - up to 40'" },
  { value: 'PAB01665', label: 'Painter - Woodworking Industry' },
  { value: 'PAD03272', label: 'Panel Beater' },
  { value: 'PAB01673', label: 'Paper Maker (hand)' },
  { value: 'PAB01674', label: 'Paper Merchant' },
  { value: 'PAB01676', label: 'Paramedic (Driver)' },
  { value: 'PAB01677', label: 'Paramedic (No driving)' },
  { value: 'PAB01678', label: 'Park Keeper' },
  { value: 'PAB01679', label: 'Park Ranger' },
  { value: 'PAB01680', label: 'Parks Superintendent' },
  { value: 'PAB01681', label: 'Parliamentary Agent' },
  { value: 'PAC01832', label: 'Party Organiser' },
  { value: 'PAB01682', label: 'Passenger Officer' },
  { value: 'PAB01685', label: 'Pasteuriser' },
  { value: 'PAB01687', label: 'Patent Agent' },
  { value: 'PAB01688', label: 'Pathologist' },
  { value: 'PAB01691', label: 'Pattern Maker - Metal, Plastic etc' },
  { value: 'PAB01692', label: 'Pavior' },
  { value: 'PAC01834', label: 'Pawnbroker' },
  { value: 'PAB01693', label: 'Pedicurist' },
  { value: 'PAD03259', label: 'Personal Assistant (PA)' },
  { value: 'PAB01696', label: 'Pest Control Manager' },
  { value: 'PAB01697', label: 'Pest Control Operator' },
  { value: 'PAB01699', label: 'Petrol Pump Attendant' },
  { value: 'PAB01701', label: 'Pharmacist' },
  { value: 'PAB01702', label: 'Pharmacologist' },
  { value: 'PAB01703', label: 'Pharmacy Assistant' },
  { value: 'PAC01844', label: 'Phlebotomist' },
  { value: 'PAD03383', label: 'Photocopier Engineer' },
  { value: 'PAB01704', label: 'Photocopying Machine Operator' },
  { value: 'PAC01845', label: 'Photographer' },
  { value: 'PAC01846', label: 'Photographer - Aerial' },
  { value: 'PAB01705', label: 'Photographic Finisher' },
  { value: 'PAB01706', label: 'Photographic Model' },
  { value: 'PAB01707', label: 'Photographic Mounter' },
  { value: 'PAB01708', label: 'Physician' },
  { value: 'PAB01709', label: 'Physicist' },
  { value: 'PAB01710', label: 'Physiotherapist' },
  { value: 'PAB01711', label: 'Piano/Organ Tuner' },
  { value: 'PAB01712', label: 'Pickler' },
  { value: 'PAC01848', label: 'Picture Framer' },
  { value: 'PAB01713', label: 'Pier Master' },
  { value: 'PAB01714', label: 'Pile Driver' },
  { value: 'PAB01715', label: 'Pilot' },
  { value: 'PAD03263', label: 'Pipe Fitter' },
  { value: 'PAD03264', label: 'Pipe Jointer' },
  { value: 'PAD03268', label: 'Pipe Layer' },
  { value: 'PAB01729', label: 'Pipe/Powerline Survey Work' },
  { value: 'PAB01731', label: 'Pitch Melter' },
  { value: 'PAD03253', label: 'Planning Engineer' },
  { value: 'PAB01735', label: 'Planning Inspector' },
  { value: 'PAB01736', label: 'Plant Attendant' },
  { value: 'PAC01849', label: 'Plant Hire Manager (some manual work)' },
  { value: 'PAC01850', label: 'Plant Hire Owner (some manual work)' },
  { value: 'PAC01851', label: 'Plant Hire Proprietor (admin. only)' },
  { value: 'PAD03254', label: 'Plant Operator' },
  { value: 'PAB01747', label: 'Plasterer' },
  { value: 'PAB01748', label: 'Plastic Coating Operator' },
  { value: 'PAB01752', label: 'Plate Cutter' },
  { value: 'PAB01753', label: 'Plate Moulder' },
  { value: 'PAB01754', label: 'Plate Separator' },
  { value: 'PAB01755', label: 'Platelayer' },
  { value: 'PAB01756', label: 'Plateman' },
  { value: 'PAB01760', label: 'Plater (including Boiler)' },
  { value: 'PAB01757', label: 'Plater - Aircraft/Aerospace' },
  { value: 'PAB01758', label: 'Plater - Motor Vehicle & Cycle Industry' },
  { value: 'PAB01759', label: 'Plater - Ship Building, Ship Repair & Marine Engineering' },
  { value: 'PAD03252', label: 'Playschool/Group Worker/Leader' },
  { value: 'PAB01761', label: 'Playwright' },
  { value: 'PAB01762', label: 'Plumber - Construction/Industrial' },
  { value: 'PAC01852', label: 'Plumber - Domestic' },
  { value: 'PAB01765', label: 'Pneumatic Drill Operator' },
  { value: 'PAB01766', label: 'Poet' },
  { value: 'PAB01767', label: 'Pointsman' },
  { value: 'PAB01768', label: 'Police' },
  { value: 'PAB01773', label: 'Police Frogman' },
  { value: 'PAD03260', label: 'Politician' },
  { value: 'PAB01777', label: 'Pollution Inspector' },
  { value: 'PAB01780', label: 'Pop Musician' },
  { value: 'PAB01781', label: 'Pop Singer' },
  { value: 'PAB01782', label: 'Port Control Signalman' },
  { value: 'PAB01783', label: 'Port Health Inspector' },
  { value: 'PAB01785', label: 'Porter - Meat, Fish etc - Food & Drink' },
  { value: 'PAB01786', label: 'Porter - Station Personnel - Railways' },
  { value: 'PAB01787', label: 'Portrait Painter' },
  { value: 'PAB01788', label: 'Portrait Photographer' },
  { value: 'PAC01853', label: 'Postal Courier Driver' },
  { value: 'PAC01854', label: 'Postal Courier Manager' },
  { value: 'PAB01790', label: 'Postman' },
  { value: 'PAB01789', label: 'Postman (no driving)' },
  { value: 'PAB01791', label: 'Pot Fisherman' },
  { value: 'PAB01792', label: 'Potman' },
  { value: 'PAB01793', label: 'Potter' },
  { value: 'PAB01794', label: 'Poultry Dresser' },
  { value: 'PAB01795', label: 'Poultry Plucker' },
  { value: 'PAB01797', label: 'Poultryman' },
  { value: 'PAB01798', label: 'Power Loader Man' },
  { value: 'PAB01799', label: 'Power Loader Operator' },
  { value: 'PAB01800', label: 'Power Station Charge Engineer' },
  { value: 'PAB01801', label: 'Power Station Manager' },
  { value: 'PAB01802', label: 'Power Station Superintendent' },
  { value: 'PAC01858', label: 'Practice Manager' },
  { value: 'PAB01806', label: 'Precision Instrument Fitter' },
  { value: 'PAB01807', label: 'Precision Instrument Maker' },
  { value: 'PAB01808', label: 'Precision Instrument Repairer' },
  { value: 'PAB01809', label: 'Preparer' },
  { value: 'PAB01810', label: 'Press Cutter' },
  { value: 'PAB01811', label: 'Press Officer' },
  { value: 'PAD03255', label: 'Press Operator - processing' },
  { value: 'PAB01814', label: 'Press Tool Setter' },
  { value: 'PAB01817', label: 'Presser - Fruit & Veg. - Food & Drink' },
  { value: 'PAB01818', label: 'Presser - Laundry' },
  { value: 'PAB01819', label: 'Priest' },
  { value: 'PAB01821', label: 'Printer' },
  { value: 'PAC01860', label: 'Printing Director (purely admin.)' },
  { value: 'PAB01826', label: 'Prison Officer' },
  { value: 'PAB01827', label: 'Private Detective' },
  { value: 'PAB01831', label: 'Probation Officer' },
  { value: 'PAD03256', label: 'Process worker' },
  { value: 'PAD03257', label: 'Producer - TV/film/theatre' },
  { value: 'PAB01868', label: 'Production Manager' },
  { value: 'PAC01865', label: 'Professional Sportsperson' },
  { value: 'PAC01866', label: 'Project Co-ordinator' },
  { value: 'PAD03266', label: 'Project Manager/Programme Manager' },
  { value: 'PAB01899', label: 'Projectionist' },
  { value: 'PAB01900', label: 'Prompter' },
  { value: 'PAB01901', label: 'Proofer' },
  { value: 'PAC01867', label: 'Property & Estate Manager' },
  { value: 'PAD03271', label: 'Property Developer(no manual work)' },
  { value: 'PAB01902', label: 'Property Manager' },
  { value: 'PAB01905', label: 'Proprietor' },
  { value: 'PAB01906', label: 'Psychiatrist' },
  { value: 'PAB01907', label: 'Psychologist' },
  { value: 'PAC01870', label: 'Psychotherapist' },
  { value: 'PAC01871', label: 'Public Hall Bookings Office Manager' },
  { value: 'PAB01909', label: 'Public Health Inspector' },
  { value: 'PAB01910', label: 'Public House Manager (salaried)' },
  { value: 'PAB01911', label: 'Public Lighting Fitter-Erector' },
  { value: 'PAB01913', label: 'Public Relations Officer' },
  { value: 'PAB01915', label: 'Publican' },
  { value: 'PAB01916', label: 'Publisher' },
  { value: 'PAD03267', label: 'Pumpman' },
  { value: 'PAB01923', label: 'Puppeteer' },
  { value: 'PAC01872', label: 'Purchasing Officer/Manager (not retail)' },
  { value: 'PAB01924', label: 'Purifier Man' },
  { value: 'PAB01925', label: 'Purser' },
  { value: 'PAB01927', label: 'Pusherman' },
  { value: 'QAD03273', label: 'Quality Control Engineer' },
  { value: 'QAD03274', label: 'Quality Control Inspector' },
  { value: 'QAB01930', label: 'Quantity Surveyor' },
  { value: 'QAB01931', label: 'Quarry Manager' },
  { value: 'QAC01874', label: 'Quarryman' },
  { value: 'QAB01933', label: 'Quartermaster' },
  { value: 'QAB01934', label: 'Queen’s Counsel' },
  { value: 'RAB02043', label: 'RSPCA Inspector' },
  { value: 'RAB01935', label: 'Rabbi' },
  { value: 'RAD03278', label: 'Racetrack Steward' },
  { value: 'RAB01936', label: 'Radar Controller/Operator' },
  { value: 'RAB01937', label: 'Radar Observer' },
  { value: 'RAB01942', label: 'Radio Station Manager' },
  { value: 'RAC01877', label: 'Radio and TV Repairer' },
  { value: 'RAB01943', label: 'Radio/Radar Operator' },
  { value: 'RAC01875', label: 'Radio/TV Announcer/Presenter' },
  { value: 'RAC01876', label: 'Radio/TV Director/Producer' },
  { value: 'RAB01947', label: 'Radiographer - Health' },
  { value: 'RAB01948', label: 'Radiologist' },
  { value: 'RAD03281', label: 'Radiotherapist' },
  { value: 'RAB01950', label: 'Rag & Bone Dealer' },
  { value: 'RAB01959', label: 'Receptionist' },
  { value: 'RAC01881', label: 'Record Producer - Entertainment Industry' },
  { value: 'RAB01960', label: 'Recording Engineer' },
  { value: 'RAD03275', label: 'Recruitment Consultant' },
  { value: 'RAB01961', label: 'Rector' },
  { value: 'RAB01963', label: 'Reflexologist' },
  { value: 'RAC01882', label: 'Refuse Collector' },
  { value: 'RAB01964', label: 'Registrar' },
  { value: 'RAC01883', label: 'Reinsurance Broker' },
  { value: 'RAD03277', label: 'Relationship Manager' },
  { value: 'RAB01967', label: 'Rent Collector' },
  { value: 'RAB01973', label: 'Reporter/Writer - no overseas travel etc' },
  { value: 'RAB01974', label: 'Reporter/Writer - otherwise' },
  { value: 'RAB01975', label: 'Rescue Diver' },
  { value: 'RAC01885', label: 'Research Chemist (Managerial)' },
  { value: 'RAC01886', label: 'Research Information Officer (Office based)' },
  { value: 'RAC01887', label: 'Research Projects Manager (Deals with hazardous substances)' },
  { value: 'RAB01976', label: 'Research Survey Clerk' },
  { value: 'RAB01978', label: 'Researcher - Journalism' },
  { value: 'RAB01977', label: 'Researcher - Radio & TV - Entertainment' },
  { value: 'RAB01979', label: 'Reservations Clerk' },
  { value: 'RAB01980', label: 'Reservoir Attendant' },
  { value: 'RAC01888', label: 'Residential Care Worker' },
  { value: 'RAC01890', label: 'Residential Home Proprietor (Admin. only)' },
  { value: 'RAC01891', label: 'Residential Home Proprietor (full involvement in caring)' },
  { value: 'RAC01892', label: 'Restaurant Proprietor - no cooking' },
  { value: 'RAB01983', label: 'Restorer (Paintings)' },
  { value: 'RAB01984', label: 'Restorer (Stone, Brickwork)' },
  { value: 'RAC01894', label: 'Retail Shop Manager' },
  { value: 'RAC01895', label: 'Retail Shop Manager - admin only' },
  { value: 'RAC01896', label: 'Retired' },
  { value: 'RAC01897', label: 'Riding Instructor' },
  { value: 'RAB01985', label: 'Rig Electrician' },
  { value: 'RAB01986', label: 'Rig Maintenance Diver' },
  { value: 'RAB01987', label: 'Rig Mechanic' },
  { value: 'RAB01988', label: 'Rig Medic' },
  { value: 'RAB01989', label: 'Rigger - Docks' },
  { value: 'RAB01990', label: 'Rigger - Film Industry - Entertainment' },
  { value: 'RAB01991', label: 'Rigger - Gas Supply Industry' },
  { value: 'RAB01994', label: 'Rigger - Industrial/Plant Machinery' },
  { value: 'RAB01992', label: 'Rigger - Oil & Natural Gas Industries' },
  { value: 'RAB01993', label: 'Rigger - Ship Building, Ship Repair & Marine Engineering' },
  { value: 'RAB01995', label: 'Ripper' },
  { value: 'RAB01996', label: 'River Inspector' },
  { value: 'RAD03279', label: 'Riveter' },
  { value: 'RAB02008', label: "Road Crew Member - 'Roadie'" },
  { value: 'RAC01900', label: 'Road Manager - Rock band' },
  { value: 'RAB02010', label: 'Road Marker' },
  { value: 'RAD03384', label: 'Road Patrolman' },
  { value: 'RAB02013', label: 'Road Safety Officer' },
  { value: 'RAB02015', label: 'Road Sweeper (hand)' },
  { value: 'RAC01902', label: 'Road Sweeper - Mechanical' },
  { value: 'RAB02016', label: 'Road Tester - Garage Trade' },
  { value: 'RAB02017', label: 'Road Tester - Motor Vehicle & Cycle Industry' },
  { value: 'RAD03280', label: 'Road Worker/Labourer' },
  { value: 'RAB02019', label: 'Roaster' },
  { value: 'RAB02020', label: 'Rodent Destroyer' },
  { value: 'RAB02022', label: 'Roller Blind Maker' },
  { value: 'RAD03276', label: 'Rollerman' },
  { value: 'RAB02032', label: "Roofer - 40' up" },
  { value: 'RAB02033', label: "Roofer - up to 40'" },
  { value: 'RAC01903', label: 'Roofing Inspector (Mostly office based - some estimating)' },
  { value: 'RAB02035', label: 'Rope Maker' },
  { value: 'RAB02037', label: 'Roughneck' },
  { value: 'RAB02039', label: 'Roustabout' },
  { value: 'RAC01905', label: 'Roustabout Pusher - Oil Rig Industry' },
  { value: 'RAC01906', label: 'Rubber & Plastics Worker' },
  { value: 'RAB02044', label: 'Rubber Technologist' },
  { value: 'SAB02047', label: 'Saddler' },
  { value: 'SAD03299', label: 'Safety Inspector' },
  { value: 'SAB02048', label: 'Safety Officer' },
  { value: 'SAC01907', label: 'Safety Officer - Oil Rig Industry' },
  { value: 'SAD03300', label: 'Sailing Instructor' },
  { value: 'SAD03305', label: 'Salary Administrator' },
  { value: 'SAC01908', label: 'Sales & Marketing Manager' },
  { value: 'SAD03306', label: 'Sales Assistant - retail' },
  { value: 'SAD03312', label: 'Sales Executive' },
  { value: 'SAD03317', label: 'Sales Manager' },
  { value: 'SAD03319', label: 'Sales Support Administrator' },
  { value: 'SAB02050', label: 'Salter' },
  { value: 'SAB02051', label: 'Salvage Diver' },
  { value: 'SAB02052', label: 'Salvage Man' },
  { value: 'SAD03283', label: 'Sandblaster' },
  { value: 'SAC01910', label: 'Satellite Aerial Fixer (domestic only)' },
  { value: 'SAB02056', label: 'Saturation Tank Attendant' },
  { value: 'SAD03296', label: 'Saw Doctor or Repairer or Sharpener' },
  { value: 'SAB02064', label: 'Scaffolder' },
  { value: 'SAD03291', label: 'Scaffolder Offshore Oil or Gas' },
  { value: 'SAB02068', label: 'Scene Shifter' },
  { value: 'SAB02069', label: 'Scenery Painter' },
  { value: 'SAD03313', label: 'School Assistant' },
  { value: 'SAC01912', label: 'School Bursar' },
  { value: 'SAC01913', label: 'School Inspector' },
  { value: 'SAB02074', label: 'Scrap Breaker' },
  { value: 'SAB02076', label: 'Scrap Dealer' },
  { value: 'SAB02079', label: 'Screen Printer' },
  { value: 'SAB02081', label: 'Screener - Quarrying' },
  { value: 'SAB02082', label: 'Screenmaker' },
  { value: 'SAB02084', label: 'Screwman' },
  { value: 'SAB02085', label: 'Script Writer' },
  { value: 'SAB02087', label: 'Sculptor' },
  { value: 'SAB02088', label: 'Seaman' },
  { value: 'SAD03307', label: 'Secretary' },
  { value: 'SAD03314', label: 'Security Consultant' },
  { value: 'SAB02093', label: 'Security Guard' },
  { value: 'SAD03285', label: 'Security Manager' },
  { value: 'SAB02096', label: 'Seismologist' },
  { value: 'SAD03301', label: 'Set Designer' },
  { value: 'SAB02106', label: 'Sewage Works Attendant' },
  { value: 'SAB02107', label: 'Sewage Works Manager' },
  { value: 'SAB02108', label: 'Sewerman' },
  { value: 'SAC01919', label: 'Sewing Machine Mechanic' },
  { value: 'SAD03315', label: 'Sewing Machinist' },
  { value: 'SAB02113', label: 'Shaftsman' },
  { value: 'SAB02114', label: 'Sheep Shearer' },
  { value: 'SAB02115', label: 'Sheet Fixer' },
  { value: 'SAD03294', label: 'Sheet Metal Worker' },
  { value: 'SAC01920', label: 'Shelf Filler' },
  { value: 'SAB02119', label: 'Shepherd' },
  { value: 'SAB02121', label: "Ship's Broker" },
  { value: 'SAB02123', label: 'Shipping Clerk' },
  { value: 'SAB02127', label: 'Shoe Maker' },
  { value: 'SAB02128', label: 'Shoe Repairer' },
  { value: 'SAB02129', label: 'Shop Assistant' },
  { value: 'SAB02133', label: 'Shop Fitter' },
  { value: 'SAD03286', label: 'Shotblaster' },
  { value: 'SAD03292', label: 'Shotfirer' },
  { value: 'SAB02145', label: 'Shredding Machine Operator' },
  { value: 'SAB02146', label: 'Shunter - Marshalling/Goods Yard - Railways' },
  { value: 'SAB02147', label: 'Shunter - Mining' },
  { value: 'SAD03302', label: 'Sieve Operator - food' },
  { value: 'SAD03308', label: 'Sieve Operator - quarry' },
  { value: 'SAD03316', label: 'Sifter - food' },
  { value: 'SAD03318', label: 'Sifter - quarry' },
  { value: 'SAB02159', label: "Sign Writer (40' up)" },
  { value: 'SAB02160', label: 'Sign Writer (no work at heights)' },
  { value: 'SAB02163', label: 'Signalman' },
  { value: 'SAD03287', label: 'Siloman' },
  { value: 'SAB02167', label: 'Siloman - docks' },
  { value: 'SAB02169', label: 'Siloman - quarry' },
  { value: 'SAC01922', label: 'Silversmith' },
  { value: 'SAB02171', label: 'Sister (Hospital)' },
  { value: 'SAB02172', label: 'Site Agent' },
  { value: 'SAB02176', label: 'Skiing - Snow - Prof Instructor' },
  { value: 'SAB02177', label: 'Skipper' },
  { value: 'SAB02181', label: 'Slate Cutter' },
  { value: 'SAB02182', label: 'Slate Dresser' },
  { value: 'SAB02183', label: 'Slate Splitter' },
  { value: 'SAB02184', label: "Slater - 40' up" },
  { value: 'SAB02185', label: "Slater - up to 40'" },
  { value: 'SAB02186', label: 'Slaughterer' },
  { value: 'SAB02187', label: 'Slaughterhouse Manager' },
  { value: 'SAB02188', label: 'Sleeping Car Attendant' },
  { value: 'SAB02189', label: 'Sleeve Designer' },
  { value: 'SAB02190', label: 'Smeller' },
  { value: 'SAB02191', label: 'Smith - Gold, Silver etc' },
  { value: 'SAB02192', label: 'Smoker' },
  { value: 'SAB02197', label: 'Social Worker' },
  { value: 'SAB02198', label: 'Sociologist' },
  { value: 'SAB02199', label: 'Solderer' },
  { value: 'SAB02200', label: 'Solicitor' },
  { value: 'SAB02201', label: 'Song Writer' },
  { value: 'SAB02205', label: 'Sorter (scrap metal)' },
  { value: 'SAB02202', label: 'Sorter - Dry Cleaning' },
  { value: 'SAB02203', label: 'Sorter - Laundry' },
  { value: 'SAB02204', label: 'Sorter - Post Office/Telecommunications' },
  { value: 'SAD03295', label: 'Sound Balancer' },
  { value: 'SAD03297', label: 'Sound Mixer' },
  { value: 'SAD03303', label: 'Sound Recordist' },
  { value: 'SAB02212', label: 'Sound Technician' },
  { value: 'SAB02214', label: 'Special Air Service (SAS)' },
  { value: 'SAB02215', label: 'Special Boat Service (SBS)' },
  { value: 'SAB02217', label: 'Special Effects Technician' },
  { value: 'SAB02218', label: 'Speech Therapist' },
  { value: 'SAB02219', label: 'Spiderman' },
  { value: 'SAB02220', label: 'Spinner' },
  { value: 'SAB02224', label: 'Sports Equipment Maker' },
  { value: 'SAB02225', label: 'Sports Equipment Repairer' },
  { value: 'SAB02226', label: 'Spot-Welder' },
  { value: 'SAD03298', label: 'Spray Painter' },
  { value: 'SAB02235', label: 'Stablehand' },
  { value: 'SAB02236', label: 'Staff Nurse' },
  { value: 'SAB02237', label: 'Stage Doorkeeper' },
  { value: 'SAB02238', label: 'Stage Hand' },
  { value: 'SAD03309', label: 'Stage Manager' },
  { value: 'SAC01928', label: 'Stage Technician' },
  { value: 'SAB02242', label: 'Stamper (identification markings)' },
  { value: 'SAD03284', label: 'Station Manager' },
  { value: 'SAD03288', label: 'Station Officer - Fire' },
  { value: 'SAC01929', label: 'Stationer' },
  { value: 'SAB02253', label: 'Statistician' },
  { value: 'SAB02254', label: "Steel Erector - 40' up" },
  { value: 'SAB02255', label: "Steel Erector - up to 40'" },
  { value: 'SAB02258', label: 'Steeplejack' },
  { value: 'SAB02263', label: 'Stenographer' },
  { value: 'SAD03289', label: 'Steriliser' },
  { value: 'SAB02267', label: 'Stevedore' },
  { value: 'SAB02269', label: 'Stitcher' },
  { value: 'SAB02270', label: 'Stockbroker' },
  { value: 'SAB02272', label: 'Stockroom Storeman' },
  { value: 'SAB02273', label: 'Stocktaker' },
  { value: 'SAD03290', label: 'Stone Breaker' },
  { value: 'SAD03293', label: 'Stone/Slate Polisher' },
  { value: 'SAB02284', label: 'Stonemason' },
  { value: 'SAB02285', label: 'Store Detective' },
  { value: 'SAB02286', label: 'Storekeeper' },
  { value: 'SAD03304', label: 'Storeman' },
  { value: 'SAD03310', label: 'Structural Engineer' },
  { value: 'SAB02298', label: 'Student' },
  { value: 'SAB02301', label: 'Stunt Man' },
  { value: 'SAB02305', label: 'Submariner' },
  { value: 'SAB02306', label: 'Sugar Beet Cutter/Slicer' },
  { value: 'SAB02307', label: 'Sugar Boiler' },
  { value: 'SAB02310', label: 'Supermarket Cashier' },
  { value: 'SAD03282', label: 'Supermarket Manager' },
  { value: 'SAB02317', label: 'Surgeon' },
  { value: 'SAB02318', label: 'Surgery Nurse' },
  { value: 'SAB02319', label: 'Surgery Receptionist' },
  { value: 'SAB02320', label: 'Surgical Appliance Maker' },
  { value: 'SAC01936', label: 'Surgical Shoe Maker' },
  { value: 'SAB02322', label: 'Surveyor - Oil & Natural Gas Industries (Exploration & Production)' },
  { value: 'SAB02323', label: 'Surveyor - Ship Building, Ship Repair & Marine Engineering' },
  { value: 'SAC01937', label: 'Swimming Instructor' },
  { value: 'SAB02325', label: 'Swimming Pool Attendant' },
  { value: 'SAB02326', label: 'Switchboard Operator' },
  { value: 'SAB02327', label: 'Sword Swallower' },
  { value: 'SAD03311', label: 'Systems Programmer' },
  { value: 'TAD03338', label: 'Tailor' },
  { value: 'TAD03340', label: 'Takeaway Food Shop Assistant' },
  { value: 'TAD03341', label: 'Takeaway Food Shop Manager - no serving' },
  { value: 'TAB02333', label: 'Tamperman' },
  { value: 'TAB02334', label: 'Tank Room Attendant' },
  { value: 'TAD03327', label: 'Tanker Driver' },
  { value: 'TAD03330', label: 'Tanker Filler' },
  { value: 'TAD03332', label: 'Tanner' },
  { value: 'TAB02338', label: 'Tarmac Layer - Construction Industry' },
  { value: 'TAB02339', label: 'Tarmac Layer - Road Maintenance & Construction' },
  { value: 'TAD03339', label: 'Tattoo Artist' },
  { value: 'TAB02340', label: 'Tax Consultant' },
  { value: 'TAB02341', label: 'Tax Inspector' },
  { value: 'TAC01941', label: 'Taxi Business Administrator' },
  { value: 'TAC01943', label: 'Taxi Business Proprietor (no driving)' },
  { value: 'TAB02342', label: 'Taxi Driver' },
  { value: 'TAB02343', label: 'Taxidermist' },
  { value: 'TAD03325', label: 'Teacher' },
  { value: 'TAC01944', label: 'Teaching Assistant' },
  { value: 'TAD03328', label: 'Technician - admin and site visits' },
  { value: 'TAD03331', label: 'Technician - admin only' },
  { value: 'TAD03333', label: 'Technician - heavy manual' },
  { value: 'TAD03335', label: 'Technician - light manual' },
  { value: 'TAB02360', label: 'Technician - other' },
  { value: 'TAC01945', label: 'Telecommunication Technical Officer' },
  { value: 'TAB02365', label: 'Telegraphist' },
  { value: 'TAC01946', label: 'Telephone Customer Advisor' },
  { value: 'TAB02366', label: 'Telephone Exchange Superintendent' },
  { value: 'TAB02367', label: 'Telephone Fitter' },
  { value: 'TAB02368', label: 'Telephone Operator' },
  { value: 'TAB02369', label: 'Telephone Repairer' },
  { value: 'TAB02370', label: 'Telephone Supervisor' },
  { value: 'TAC01947', label: 'Telephone Systems Sales Director' },
  { value: 'TAB02371', label: 'Telephonist' },
  { value: 'TAD03323', label: 'Telesales Caller' },
  { value: 'TAD03324', label: 'Telesales Manager' },
  { value: 'TAC01948', label: 'Television Engineer' },
  { value: 'TAD03336', label: 'Test Engineer' },
  { value: 'TAB02380', label: 'Test Pilots' },
  { value: 'TAD03321', label: 'Tester' },
  { value: 'TAC01949', label: 'Textile Worker' },
  { value: 'TAB02393', label: 'Thatcher' },
  { value: 'TAC01950', label: 'Theatre Sound Engineer' },
  { value: 'TAB02398', label: 'Ticket Inspector' },
  { value: 'TAB02400', label: "Tiler - 40' up" },
  { value: 'TAB02401', label: "Tiler - up to 40'" },
  { value: 'TAB02402', label: 'Timber Merchant (no manual work)' },
  { value: 'TAB02405', label: 'Timberman' },
  { value: 'TAC01951', label: 'Tobacconist' },
  { value: 'TAB02412', label: 'Tool Fitter' },
  { value: 'TAB02413', label: 'Tool Maker' },
  { value: 'TAB02414', label: 'Tool Pusher' },
  { value: 'TAB02417', label: 'Tour Guide' },
  { value: 'TAB02418', label: 'Tour Manager' },
  { value: 'TAB02419', label: 'Tower Crane Driver' },
  { value: 'TAB02420', label: 'Town Planner' },
  { value: 'TAB02421', label: 'Toxicologist' },
  { value: 'TAB02426', label: 'Trackman' },
  { value: 'TAD03334', label: 'Trade Union Official (full time)' },
  { value: 'TAB02427', label: 'Traffic Warden' },
  { value: 'TAB02428', label: 'Train Crew Inspector' },
  { value: 'TAB02429', label: 'Train Crew Supervisor' },
  { value: 'TAC01953', label: 'Train Driver' },
  { value: 'TAD03326', label: 'Trainer - education/office based' },
  { value: 'TAD03329', label: 'Tram Driver/Conductor' },
  { value: 'TAB02432', label: 'Translator' },
  { value: 'TAC01954', label: 'Transport Company Operations Manager (Office based)' },
  { value: 'TAC01955', label: 'Transport Manager' },
  { value: 'TAC01956', label: 'Travel Agent (office based)' },
  { value: 'TAB02434', label: 'Travel Courier' },
  { value: 'TAB02435', label: 'Trawlerman' },
  { value: 'TAB02436', label: 'Tree Feller' },
  { value: 'TAB02437', label: 'Tree Surgeon' },
  { value: 'TAB02438', label: 'Trenchman' },
  { value: 'TAB02443', label: 'Tugman' },
  { value: 'TAB02449', label: 'Tunneller - no explosives etc' },
  { value: 'TAB02450', label: 'Tunneller - using explosives etc' },
  { value: 'TAC01957', label: 'Turf Accountant (on course)' },
  { value: 'TAC01958', label: 'Turf Accountant (shop)' },
  { value: 'TAD03337', label: 'Turf Cutter/Layer' },
  { value: 'TAB02451', label: 'Turner - Machining, Shaping etc - Metal Manufacture' },
  { value: 'TAB02452', label: 'Turner - Pottery Industry' },
  { value: 'TAB02453', label: 'Turnstile Operator' },
  { value: 'TAB02454', label: 'Tutor (salaried)' },
  { value: 'TAB02455', label: 'Tutor (self-employed)' },
  { value: 'TAB02457', label: 'Type Caster' },
  { value: 'TAC01959', label: 'Typesetter' },
  { value: 'TAB02459', label: 'Typograph Operator' },
  { value: 'TAB02460', label: 'Typographical Designer' },
  { value: 'TAD03322', label: 'Tyre/Exhaust Fitter' },
  { value: 'UAD03343', label: 'Umpire' },
  { value: 'UAD03344', label: 'Under Secretary' },
  { value: 'UAB02463', label: 'Undertaker' },
  { value: 'UAB02464', label: "Undertaker's Director’s Assistant" },
  { value: 'UAB02465', label: 'Underwriter' },
  { value: 'UAC01960', label: 'Unemployed' },
  { value: 'UAD03342', label: 'University lecturer' },
  { value: 'UAC01961', label: 'Unknown' },
  { value: 'UAB02467', label: 'Upholsterer' },
  { value: 'UAB02469', label: 'Usher/Usherette' },
  { value: 'VAD03348', label: 'Vacuum Plant Operator' },
  { value: 'VAB02470', label: 'Valet/Valeter' },
  { value: 'VAB02471', label: 'Valuer' },
  { value: 'VAD03345', label: 'Valveman' },
  { value: 'VAB02475', label: 'Van driver' },
  { value: 'VAB02476', label: 'Varnisher' },
  { value: 'VAB02477', label: 'Vatman' },
  { value: 'VAB02478', label: 'Vehicle Body Builder' },
  { value: 'VAB02479', label: 'Vehicle Body Fitter' },
  { value: 'VAC01962', label: 'Vending Machine Engineer' },
  { value: 'VAB02480', label: 'Venetian Blind Maker' },
  { value: 'VAB02481', label: 'Ventriloquist' },
  { value: 'VAD03346', label: 'Venture Capitalist' },
  { value: 'VAB02482', label: 'Verger' },
  { value: 'VAB02483', label: 'Veterinarian' },
  { value: 'VAB02485', label: 'Veterinary Assistant' },
  { value: 'VAB02492', label: 'Vicar' },
  { value: 'VAC01963', label: 'Video Conference Engineer' },
  { value: 'VAB02493', label: 'Video Recorder Operator' },
  { value: 'VAD03347', label: 'Vintner' },
  { value: 'VAB02495', label: 'Vision Mixer' },
  { value: 'VAB02496', label: 'Vocational Training Instructor' },
  { value: 'WAD03356', label: 'Wages Clerk' },
  { value: 'WAB02499', label: 'Wages Inspector' },
  { value: 'WAB02503', label: 'Waiter, Waitress' },
  { value: 'WAB02506', label: 'Wallpaper Printer' },
  { value: 'WAB02507', label: 'Warden' },
  { value: 'WAB02508', label: 'Wardrobe Mistress' },
  { value: 'WAB02510', label: 'Warehouse Manager' },
  { value: 'WAB02513', label: 'Warehouseman' },
  { value: 'WAD03352', label: 'Washer' },
  { value: 'WAD03353', label: 'Waste Disposal/Recycling Operative' },
  { value: 'WAB02518', label: 'Wasteman, Salvage Man' },
  { value: 'WAC01964', label: 'Watch & Clock Maker' },
  { value: 'WAC01965', label: 'Watch & Clock Repairer' },
  { value: 'WAB02519', label: 'Watchman (inland waterways)' },
  { value: 'WAB02520', label: 'Watchstander' },
  { value: 'WAB02521', label: 'Water Bailiff' },
  { value: 'WAB02522', label: 'Water Infusion Man' },
  { value: 'WAD03351', label: 'Water Meter Reader/Fitter/Tester' },
  { value: 'WAB02524', label: 'Water Skiing - Prof. Instructor' },
  { value: 'WAD03354', label: 'Water Treatment Plant Operator' },
  { value: 'WAD03355', label: 'Waterworks Manager' },
  { value: 'WAD03357', label: 'Weaver' },
  { value: 'WAD03359', label: 'Website/Webpage Designer' },
  { value: 'WAB02529', label: 'Wedding Photographer' },
  { value: 'WAB02530', label: 'Weighbridge Clerk' },
  { value: 'WAD03349', label: 'Weighbridgeman' },
  { value: 'WAB02538', label: 'Weights & Measures Inspector' },
  { value: 'WAB02541', label: "Welder - 40' up" },
  { value: 'WAB02542', label: "Welder - up to 40'" },
  { value: 'WAB02546', label: 'Welfare Officer' },
  { value: 'WAB02547', label: 'Welfare Worker' },
  { value: 'WAB02556', label: 'Wicker Worker' },
  { value: 'WAB02558', label: 'Wig Maker' },
  { value: 'WAB02561', label: 'Winch Operator' },
  { value: 'WAB02562', label: 'Winchman' },
  { value: 'WAB02564', label: "Window Cleaner (40' up)" },
  { value: 'WAB02565', label: "Window Cleaner (up to 40')" },
  { value: 'WAB02566', label: 'Window Dresser' },
  { value: 'WAD03358', label: 'Window/Conservatory Fitter' },
  { value: 'WAD03360', label: 'Windscreen Fitter/Repairer' },
  { value: 'WAB02567', label: 'Wire Winder' },
  { value: 'WAB02570', label: 'Wood Carver' },
  { value: 'WAD03350', label: 'Wood Worker' },
  { value: 'WAB02574', label: 'Woodcutter' },
  { value: 'WAB02575', label: 'Woodman' },
  { value: 'WAC01966', label: 'Working Partner' },
  { value: 'WAB02581', label: 'Wrapping Machine Attendant' },
  { value: 'WAB02583', label: 'Writer' },
  { value: 'XAD03362', label: 'X-ray Technician - Radiologist' },
  { value: 'YAD03363', label: 'Yacht & Boat Builder' },
  { value: 'YAD03365', label: 'Yard Assistant' },
  { value: 'YAB02585', label: 'Yard Cleaner' },
  { value: 'YAD03364', label: 'Yarn Worker' },
  { value: 'YAD03366', label: 'Yoga Teacher' },
  { value: 'YAC01967', label: 'Youth Hostel Assistant and Cook' },
  { value: 'YAC01968', label: 'Youth Hostel Manager (some manual work)' },
  { value: 'YAB02586', label: 'Youth Leader (Full time)' },
  { value: 'YAD03367', label: 'Youth Worker (full time)' },
  { value: 'ZAD03370', label: 'Zoo Curator' },
  { value: 'ZAD03385', label: 'Zoo Director' },
  { value: 'ZAD03368', label: 'Zoo Keeper' },
  { value: 'ZAD03369', label: 'Zoo Keeper - large zoos' },
  { value: 'ZAD03371', label: 'Zoo Keeper - small zoos' },
  { value: 'ZAD03372', label: 'Zoological Research Associate' },
  { value: 'ZAD03373', label: 'Zoological Researcher' },
  { value: 'ZAD03374', label: 'Zoologist' },
  { value: 'ZAB02587', label: 'Zoologist (no overseas field work)' },
]
