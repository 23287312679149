import { useMemo } from 'react'
import { get } from 'lodash'

import { useGetClientProtectedFieldsLazyQuery } from '@acre/graphql'

type Fetchers =
  | 'fetchPreviousName'
  | 'fetchFirstName'
  | 'fetchLastName'
  | 'fetchDob'
  | 'fetchSortCode'
  | 'fetchAccountNumber'

const clientProtectedFields = new Map<Fetchers, { field: string; flags: Record<string, true> }>([
  [
    'fetchPreviousName',
    {
      field: 'maiden_or_previous_name',
      flags: {
        maiden_or_previous_name: true,
      },
    },
  ],
  [
    'fetchFirstName',
    {
      field: 'previous_names[0].previous_first_name',
      flags: {
        first_name: true,
      },
    },
  ],
  [
    'fetchLastName',
    {
      field: 'previous_names[0].previous_surname',
      flags: {
        last_name: true,
      },
    },
  ],
  [
    'fetchDob',
    {
      field: 'date_of_birth',
      flags: {
        date_of_birth: true,
      },
    },
  ],
  [
    'fetchSortCode',
    {
      field: 'accounts[0].sort_code',
      flags: {
        accounts: true,
        sort_code: true,
      },
    },
  ],
  [
    'fetchAccountNumber',
    {
      field: 'accounts[0].number',
      flags: {
        accounts: true,
        number: true,
      },
    },
  ],
])

const useGetClientProtectedFields = (id: string) => {
  const [getClientProtectedFields] = useGetClientProtectedFieldsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  return useMemo(() => {
    return [...clientProtectedFields.keys()].reduce(
      (acc, key) => {
        const entry = clientProtectedFields.get(key)

        if (!entry) {
          return acc
        }

        return {
          ...acc,
          [key]: async () => {
            const result = await getClientProtectedFields({
              variables: {
                id,
                protectedFields: [entry.field],
                ...entry.flags,
              },
            })

            return get(result.data?.clientProtectedFields.details, entry.field)
          },
        }
      },
      {} as Record<Fetchers, () => Promise<string>>,
    )
  }, [getClientProtectedFields, id])
}

export default useGetClientProtectedFields
