import React from 'react'
import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  styled,
  unstable_composeClasses,
} from '@mui/material'
import classnames from 'classnames'

import menuItemClasses, { getMenuItemUtilityClass } from './menuItemClasses'

export interface MenuItemProps extends MuiMenuItemProps {
  danger?: boolean
}

const MenuItemRoot = styled(MuiMenuItem, {
  name: 'MenuItem',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, props.danger && styles.danger],
  shouldForwardProp: (prop) => prop !== 'danger',
})<MenuItemProps>(({ theme }) => ({
  [`&.${menuItemClasses.danger}`]: {
    color: theme.colours.danger,
  },
}))

const useUtilityClasses = (ownerState: Partial<MenuItemProps>) => {
  const slots = {
    root: ['root', ownerState.danger && 'danger'],
  }

  return unstable_composeClasses(slots, getMenuItemUtilityClass, ownerState.classes)
}

const MenuItem = (props: MenuItemProps) => {
  const { className, classes, danger = false, ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes, danger })

  return <MenuItemRoot {...rootProps} danger={danger} className={classnames(slotClasses.root, className)} />
}

export default React.memo(MenuItem) as typeof MenuItem
