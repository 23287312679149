import React from 'react'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { testHandle } from '@acre/utils'
import { Button, BUTTON_PRIMARY, BUTTON_TYPE_SUBMIT, COMPACT_LEFT_ALIGNED } from '@acre/design-system'

import { messagePrefix } from './Fees/Fees.helpers'
import { RecommendationMessage } from './IllustrationModal.styles'

interface Props {
  isRecommendationAndEsis: boolean
  submitting: boolean
  downloadButtonDisabled: boolean
  submitMessage: string
}

function IllustrationModalFooter({
  isRecommendationAndEsis,
  submitting,
  downloadButtonDisabled,
  submitMessage,
}: Props) {
  return (
    <Box display="flex" width="100%" justifyContent={isRecommendationAndEsis ? 'space-between' : 'flex-end'}>
      {isRecommendationAndEsis ? (
        <Box ml={2}>
          <RecommendationMessage data-testid={testHandle('withoutEsisID')}>
            <FormattedMessage id="cases.products.options.generateIllustration.withoutEsisID1" />
            <br />
            <FormattedMessage id="cases.products.options.generateIllustration.withoutEsisID2" />
          </RecommendationMessage>
        </Box>
      ) : null}
      <Button
        id="DownloadIllustration"
        buttonStyle={BUTTON_PRIMARY}
        type={BUTTON_TYPE_SUBMIT}
        isLoading={submitting}
        disabled={downloadButtonDisabled}
        variant={COMPACT_LEFT_ALIGNED}
      >
        <FormattedMessage id={`${messagePrefix}.${submitMessage}`} />
      </Button>
    </Box>
  )
}

export default IllustrationModalFooter
