import React, { useCallback, useMemo } from 'react'
import usePreviewMode from '@broker-crm-common/hooks/usePreviewMode'
import { IconButton, Tooltip } from '@mui/material'
import { ReactGrandTourStep } from 'react-grand-tour'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { useUserContext } from '@acre/utils'
import { useUpdateUserMutation } from '@acre/graphql'
import { GrandTour, Icon, IconTypes } from '@acre/design-system'

import PreviewModeTour, { PreviewModeTourSteps } from '../../tours/PreviewModeTour'

const PreviewModeButton = () => {
  const { pathname } = useLocation()
  const { active: previewModeEnabled } = usePreviewMode()
  const user = useUserContext()

  const handleDisablePreviewModeClick = useCallback(() => {
    const env = window.location.hostname.includes('myac.re') ? 'prod' : 'staging'
    const hostname = `crm.${env === 'prod' ? 'myac.re' : 'acreplatforms.co.uk'}`

    window.location.href = `https://${hostname}${pathname}`
  }, [pathname])

  const [updateUser] = useUpdateUserMutation()

  const handleClose = useCallback(() => {
    const existingFeatures = user?.first_time_access?.features?.length
      ? user.first_time_access?.features.filter((feature) => feature.feature !== 'PREVIEW')
      : []

    if (user?.id) {
      updateUser({
        variables: {
          userId: user?.id,
          input: {
            first_time_access: {
              features: [
                ...existingFeatures.map((feature) => ({ feature: feature.feature, complete: feature.complete })),
                {
                  feature: 'PREVIEW',
                  complete: true,
                },
              ],
            },
          },
        },
      })
    }

    return true
  }, [user, updateUser])

  const openTour = useMemo(
    () => !user?.first_time_access?.features?.find((feature) => feature.feature === 'PREVIEW')?.complete,
    [user?.first_time_access?.features],
  )

  return previewModeEnabled ? (
    <>
      <Tooltip title={<FormattedMessage id="nav.previewMode.tooltip" />} placement="bottom-end">
        <IconButton id={PreviewModeTour.Button.selectorToHTML()} disableRipple onClick={handleDisablePreviewModeClick}>
          <Icon key="previewModeIcon" name={IconTypes.Flask} />
        </IconButton>
      </Tooltip>
      <GrandTour
        hidePreviousStepButton
        hideStepButtons
        steps={PreviewModeTourSteps as ReactGrandTourStep[]}
        open={openTour}
        onClose={handleClose}
      />
    </>
  ) : null
}

export default React.memo(PreviewModeButton)
