import { useMemo, useState } from 'react'
import { CardRow } from '@broker-crm-common'
import { TaskRowSidebarDraw } from '@broker-crm-common/ReviewModeDrawer/Tasks/Tasks.styles'
import { useCaseContext } from '@broker-crm-contexts'
import { isGateThreePointSevenExceptionCollection } from '@broker-crm-utils'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useLocation, useNavigate } from 'react-router-dom'

import { Mortgage, MortgageStatus, useGetClientsLazyQuery, VerificationCollection as Collection } from '@acre/graphql'
import { GetClientsQuery } from '@acre/graphql'
import { Icon, IconTypes, trackEventFiltered } from '@acre/design-system'

import { getCompanyDirectors } from '../../CaseFactFind/CaseFactFind.helpers'
import { TasksLocation } from '../CaseOverview.helpers'
import { addSpaceBetween } from '../CaseSummary.helpers'
import { Gate } from './CaseVerifications.fixtures'
import {
  addOptionalLabel,
  buildAction,
  buildCollectionAction,
  ChevronIcon,
  decorateCollection,
  decorateVerification,
  documentRequirementCollectionIds,
  getVerificationClientDisplayName,
  MAX_VERIFICATIONS,
} from './CaseVerifications.helpers'
import { VerificationComplete, VerificationMessage } from '../CaseOverview.styles'

type Props = {
  collection: Collection
  triggerInitialDisclosure: (clientIds: string[]) => void
  showAllVerifications?: boolean
  tasksLocation?: TasksLocation
  detailSuitabilityReportTasks?: boolean
}

const VerificationCollection = ({
  collection,
  triggerInitialDisclosure,
  showAllVerifications,
  tasksLocation,
  detailSuitabilityReportTasks,
}: Props) => {
  const { trackEvent } = useMatomo()
  const navigate = useNavigate()
  const location = useLocation()

  const { id: caseId, details } = useCaseContext()

  const naturalClients = useMemo(
    () => details.clients.filter((client) => client.details.is_natural_person !== false),
    [details.clients],
  )

  const [directors, setDirectors] = useState<GetClientsQuery['clients']>([])

  const [getClients, { loading }] = useGetClientsLazyQuery({
    onCompleted: (data) => {
      const clientDetails = data.clients
      setDirectors(clientDetails)
    },
  })

  const isCaseSidebarDrawer = useMemo(() => tasksLocation === TasksLocation.CaseSidebarDrawer, [tasksLocation])

  // Getting clientId for verification without clientId provided (bank details verification)
  const firstClientIdOnCase = useMemo(() => details.clients?.[0].id, [details])

  // Check if the clients are non natural (handled in getCompanyDirectors call)
  // and fetch the directors client IDs
  const companyDirectorIds = useMemo(() => getCompanyDirectors(details), [details])

  const currentMortgageId = useMemo(
    () => details?.mortgages?.find((mortgage: Mortgage) => mortgage.status === MortgageStatus.StatusCurrent)?.id,
    [details?.mortgages],
  )

  const lenderProposedMortgageId = useMemo(
    () => details?.mortgages?.find((mortgage: Mortgage) => mortgage.lender_proposed)?.id,
    [details?.mortgages],
  )

  // Load the directors client instance if directors exists on the non natural clients
  useMemo(() => {
    if (companyDirectorIds.length > 0) {
      getClients({ variables: { ids: companyDirectorIds } })
    }
  }, [companyDirectorIds, getClients])

  // Collections
  const decoratedCollection = useMemo(() => {
    const decoratedCollection = decorateCollection(
      collection,
      details.status,
      currentMortgageId,
      lenderProposedMortgageId,
    )
    return decoratedCollection
  }, [collection, currentMortgageId, details.status, lenderProposedMortgageId])

  const { message, hideWhenComplete } = decoratedCollection
  const { collection_id, verifications, name, passed, outstanding_optional } = collection

  let decoratedVerifications = useMemo(
    () =>
      (verifications || []).map((verification) =>
        decorateVerification(
          verification,
          collection_id,
          currentMortgageId,
          lenderProposedMortgageId,
          firstClientIdOnCase,
        ),
      ),
    [collection_id, currentMortgageId, firstClientIdOnCase, lenderProposedMortgageId, verifications],
  )

  decoratedVerifications = useMemo(() => {
    return decoratedVerifications.map((props) => {
      const { verification, pathname } = props
      const isClientVerification = pathname?.includes(':client_id')

      // If it's a client verificaiton and
      // there is only one client on the case
      // and this verification has no client id
      // we assign the client_id to the verification, in order to populate the redirect url
      if (isClientVerification && naturalClients.length === 1 && !verification.client_id) {
        return {
          ...props,
          verification: {
            ...verification,
            client_id: !isEmpty(naturalClients) ? `${naturalClients[0].id}` : `${details.clients[0].id}`,
          },
        }
      }
      return props
    })
  }, [decoratedVerifications, details.clients, naturalClients])

  const verificationsAlwaysShow = decoratedVerifications.filter(({ alwaysShow }) => alwaysShow)

  // If there are too many verifications within the collection, then
  // group them and show only the top-level task (as well as the
  // always-show verifications within)
  //
  // If there aren't many verifications within the collection, then
  // just list them all individually and hide the collection-level task

  // Do not show individual verifications for gates 3.7
  // These are gates that include SR fields
  const isGateThreePointSeven = name?.includes('Gate 3.7')

  // Do not show individual verifications for Gate 3.6.2 if it includes more than one failed verification
  const groupProtectionSourcingCollection =
    name?.includes('Gate 3.6.2') &&
    verifications &&
    verifications.filter((ver) => !isEmpty(ver.failed_fields)).length > 1

  const isExceptionCollection = isGateThreePointSevenExceptionCollection(!!passed, name, details.status)

  // Always show all verifications inside document requirement collections
  const isDocumentRequirementCollection = useMemo(
    () => documentRequirementCollectionIds.includes(collection_id as Gate),
    [collection_id],
  )

  const showCollectionTask = useMemo(() => {
    if (isGateThreePointSeven && detailSuitabilityReportTasks) {
      return false
    } else if (isGateThreePointSeven || groupProtectionSourcingCollection) {
      return true
    } else if (!isDocumentRequirementCollection && verifications?.length && verifications.length > MAX_VERIFICATIONS) {
      return true
    } else return false
  }, [
    detailSuitabilityReportTasks,
    groupProtectionSourcingCollection,
    isDocumentRequirementCollection,
    isGateThreePointSeven,
    verifications?.length,
  ])

  const isIddCollection = collection.collection_id === '4795ece2-4d5c-45ff-969c-ddff800f9ce8'

  const verificationsToDisplay = showCollectionTask ? verificationsAlwaysShow : decoratedVerifications

  // - If all the verifications in a collection have passed, we want to display
  //   a green item with a check icon that isn't clickable.
  //
  // - Else if we have verifications remaining, then we want to defer to the logic
  //   above wherein we only show the collection if there are more than three items
  //
  // - There are collections which are exceptions to the above logic,
  //   (i.e. one is when we have a pre-recommendation case and the collection at
  //   gate 3.7 has passed, which should still display the collection as a task)
  let collectionElement = null

  if (passed && !isExceptionCollection && !hideWhenComplete && !loading && !isGateThreePointSeven) {
    const rowContent = (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <VerificationMessage>{message ? message : name || ''}</VerificationMessage>
        <Icon name={IconTypes.BadgeSuccess} />
      </Box>
    )
    collectionElement = <VerificationComplete>{rowContent}</VerificationComplete>
  } else if (showCollectionTask && !loading && !isIddCollection) {
    const rowContent = (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <VerificationMessage>
          {message
            ? // Only outstanding_optional is relevant for grouped verifications
              addOptionalLabel(outstanding_optional || false, message)
            : addOptionalLabel(outstanding_optional || false, name || '')}
        </VerificationMessage>
        {ChevronIcon}
      </Box>
    )

    const handleClick = () => {
      trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
      buildCollectionAction(decoratedCollection, caseId, details.clients?.[0].id, navigate)
    }

    if (isCaseSidebarDrawer) {
      collectionElement = <TaskRowSidebarDraw onClick={handleClick}>{rowContent}</TaskRowSidebarDraw>
    } else {
      collectionElement = <CardRow onClick={handleClick}>{rowContent}</CardRow>
    }
  }

  const iddCollectionMeta = useMemo(() => {
    if (isIddCollection && decoratedVerifications.length > 0) {
      return {
        message: decoratedVerifications[0]!.message,
        clientIds: decoratedVerifications.map((verification) => verification.verification.client_id),
      }
    }
    return null
  }, [decoratedVerifications, isIddCollection])

  const moreThanOneVerificationsInsideCollection = !isEmpty(iddCollectionMeta?.clientIds)

  const renderVerificationsForMultipleCollections = useMemo(() => {
    /* Verification-level tasks */
    /* If idd collection and more than one verificaiton inside it show it as a single task */

    const handleClick = () => {
      trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
      triggerInitialDisclosure(iddCollectionMeta!.clientIds as string[])
    }

    const rowContent = (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <VerificationMessage>
          <span>{iddCollectionMeta?.message}</span>
        </VerificationMessage>
        {ChevronIcon}
      </Box>
    )

    return isCaseSidebarDrawer ? (
      <TaskRowSidebarDraw onClick={handleClick}>{rowContent}</TaskRowSidebarDraw>
    ) : (
      <CardRow onClick={handleClick}>{rowContent}</CardRow>
    )
  }, [iddCollectionMeta, isCaseSidebarDrawer, triggerInitialDisclosure, trackEvent, location, tasksLocation])

  const renderRestOfVerifications = useMemo(() => {
    // only show one task per collection if collection list is long
    return (showAllVerifications ? verificationsToDisplay : verificationsToDisplay.slice(0, 1)).map(
      (decoratedVerification, index) => {
        const { message, verification } = decoratedVerification
        const { explanation, optional } = verification

        const text = message ? message : explanation || ''

        const displayName = getVerificationClientDisplayName(decoratedVerification, details.clients, directors, loading)

        const rowContent = (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <VerificationMessage>
              <span>{addOptionalLabel(optional || false, addSpaceBetween(text, displayName))}</span>
            </VerificationMessage>
            {ChevronIcon}
          </Box>
        )

        const handleClick = () => {
          trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
          buildAction(decoratedVerification, caseId, firstClientIdOnCase, triggerInitialDisclosure, navigate)
        }

        return isCaseSidebarDrawer ? (
          <TaskRowSidebarDraw key={index} onClick={handleClick}>
            {rowContent}
          </TaskRowSidebarDraw>
        ) : (
          <CardRow key={index} onClick={handleClick}>
            {rowContent}
          </CardRow>
        )
      },
    )
  }, [
    showAllVerifications,
    verificationsToDisplay,
    details.clients,
    directors,
    loading,
    isCaseSidebarDrawer,
    trackEvent,
    location,
    tasksLocation,
    caseId,
    firstClientIdOnCase,
    triggerInitialDisclosure,
    navigate,
  ])

  return (
    <>
      {/* Collection-level task */}
      {collectionElement}
      {moreThanOneVerificationsInsideCollection ? renderVerificationsForMultipleCollections : renderRestOfVerifications}
    </>
  )
}

export default VerificationCollection
