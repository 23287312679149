import { CSSObject, styled, Theme } from '@mui/material/styles'
import { NavLink, NavLinkProps } from 'react-router-dom'

export type StyledOptionsNavLinkProps = { danger?: boolean }

const OptionsNavLinkCss = ({ theme, danger }: { theme: Theme; danger?: boolean }) =>
  ({
    position: 'relative',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: danger ? theme.colours.danger : theme.colours.baseMid,

    marginLeft: theme.spacers.size12,
    marginRight: theme.spacers.size12,

    textDecoration: 'none',
    transition: 'all 0.15s ease-in-out',

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      transition: 'all 0.15s ease-in-out',
      backgroundColor: 'transparent',
    },
    '&.active': {
      color: danger ? theme.colours.danger : theme.colours.primary,
      '&::after': {
        backgroundColor: danger ? theme.colours.danger : theme.colours.primary,
      },
    },

    '&:hover': {
      textDecoration: 'none',

      '&::after': {
        backgroundColor: danger ? theme.colours.danger : theme.colours.primary,
      },
    },

    '&:disabled': {
      color: theme.colours.baseLight,
      fontSize: '14px',
      pointerEvent: 'none',
      cursor: 'unset',
      padding: 0,

      '&::after': {
        display: 'none',
      },
    },
  }) as CSSObject

export const StyledOptionsNavButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'danger',
})<StyledOptionsNavLinkProps>(OptionsNavLinkCss)

export const StyledOptionsNavLink = styled(NavLink, { shouldForwardProp: (prop) => prop !== 'danger' })<
  NavLinkProps & StyledOptionsNavLinkProps
>(OptionsNavLinkCss)
