import { format, isValid } from 'date-fns'

import { CaseDetailsFlagFlagType } from '@acre/graphql'

export const AMLFlags = [
  CaseDetailsFlagFlagType.FlagFinancialCrime,
  CaseDetailsFlagFlagType.FlagPep,
  CaseDetailsFlagFlagType.FlagNationality,
  CaseDetailsFlagFlagType.FlagAml,
]

export const canMarkNoFurtherReviewFlags = [
  CaseDetailsFlagFlagType.FlagFinancialCrime,
  CaseDetailsFlagFlagType.FlagPep,
  CaseDetailsFlagFlagType.FlagVulnerable,
  CaseDetailsFlagFlagType.FlagNationality,
  CaseDetailsFlagFlagType.FlagHighCommission,
  CaseDetailsFlagFlagType.FlagAml,
  CaseDetailsFlagFlagType.FlagRightToBuy,
  CaseDetailsFlagFlagType.FlagLendingIntoRetirement,
  CaseDetailsFlagFlagType.FlagRedemptionPenalty,
  CaseDetailsFlagFlagType.FlagEquityRelease,
  CaseDetailsFlagFlagType.FlagResidentialInterestOnly,
  CaseDetailsFlagFlagType.FlagSecondJob,
  CaseDetailsFlagFlagType.FlagGiftedDeposit,
]

export const canMarkNoFurtherReview = (flag: CaseDetailsFlagFlagType) => {
  return canMarkNoFurtherReviewFlags.includes(flag)
}

export const havePermissionForFlag = (
  flag: CaseDetailsFlagFlagType,
  canApproveAML: boolean,
  canApproveAdvice: boolean,
) => {
  if (AMLFlags.includes(flag)) {
    return canApproveAML
  } else {
    return canApproveAdvice
  }
}

export const createCaseFlagTimeStamp = (value: string) => {
  const momentDate = new Date(value)
  const day = isValid(momentDate) ? format(momentDate, 'd') : ''
  const month = isValid(momentDate) ? format(momentDate, 'MMM') : ''
  const year = isValid(momentDate) ? format(momentDate, 'yyyy') : ''
  const time = isValid(momentDate) ? format(momentDate, 'h:mma') : ''
  return {
    day,
    month,
    year,
    time,
  }
}
