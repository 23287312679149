import { differenceInYears, getMonth, getYear, parseISO } from 'date-fns'

const permittedDateInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '', null, undefined]

export const yearsAgo = (value: string) => differenceInYears(new Date(), new Date(value))

export const cleanValue = (value: string) => {
  const cleanedInput = value
    .split('')
    .filter((char) => permittedDateInput.includes(char))
    .join('')
  if (!isNaN(Number(cleanedInput))) {
    return cleanedInput
  } else {
    return ''
  }
}

export const isValidDay = (cleanedValue: string) => {
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 2 || !isNaN(cleaned) || !cleanedValue
}

export const isValidMonth = (cleanedValue: string) => {
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 2 || !isNaN(cleaned) || !cleanedValue
}

export const isValidYear = (cleanedValue: string) => {
  const cleaned = Number(cleanedValue)
  return cleanedValue.length < 4 || !isNaN(cleaned) || !cleanedValue
}

export const createDate = (day: string, month: string, year: string) => parseISO(`${year}-${month}-${day}`)

export const getDifferenceInMonths = (start: Date, end: Date) => {
  const startValue = getYear(start) * 12 + getMonth(start)
  const endValue = getYear(end) * 12 + getMonth(end)

  return Math.abs(endValue - startValue)
}
