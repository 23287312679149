import { useCallback, useState } from 'react'
import { FetchResult } from '@apollo/client'
import { usePermissionsContext } from '@broker-crm-contexts'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { get } from 'lodash'
import { Field, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage } from 'react-intl'

import { testHandle, useFormatMessage } from '@acre/utils'
import {
  CaseDetailsFlag,
  CaseDetailsFlagFlagType,
  CaseVersion,
  GateName,
  Maybe,
  useCreateCaseFlagReviewMutation,
} from '@acre/graphql'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_SUBMIT,
  Card,
  Checkbox,
  GreyText,
  H2,
  NoteBodyItem,
  NoteFooter,
  Tag,
  UnsetSectionDivider,
} from '@acre/design-system'

import { flagToMessage, messagePrefixNote } from '../CaseFlag.helpers'
import { canMarkNoFurtherReviewFlags, createCaseFlagTimeStamp, havePermissionForFlag } from '../CaseFlagNotes.helpers'

type Props = {
  id: string
  name: string
  caseData: CaseVersion
  flag: CaseDetailsFlag
  onClickClearFlag: (handleSendReviewClick: () => Promise<FetchResult<any> | undefined>) => void
  disabled?: boolean
}

const newReview = { reviewer_notes: '', skip_future_reviews: false }

const CaseFlagNote = ({ id, name, caseData, flag, onClickClearFlag }: Props) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()
  const form = useForm()

  const permissions = usePermissionsContext()
  const haveAddCaseNotePermission = Boolean(permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_ADDCASENOTES))

  const hasEditAMLCaseFlagsPermission = Boolean(permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_APPROVEAML))
  const hasEditAdviceCaseFlagsPermission = Boolean(
    permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_APPROVEADVICE),
  )

  const [showSubmit, setShowSubmit] = useState<boolean>(false)
  const [clearFlag, setClearFlag] = useState<boolean>(true)

  const [unsavedReviewIndex, setUnsavedReviewIndex] = useState<Maybe<number>>(null)
  const reviewNotesFieldName = unsavedReviewIndex ? `${name}.reviews[${unsavedReviewIndex - 1}].reviewer_notes` : ''

  const [createCaseFlagReview, { loading: createCaseFlagReviewLoading }] = useCreateCaseFlagReviewMutation()

  const handleAddClick = useCallback(() => {
    form.mutators.push(`${name}.reviews`, newReview)
    setShowSubmit(true)
    const reviewsLength = get(form.getState().values, `${name}.reviews`).length
    setUnsavedReviewIndex(reviewsLength)
  }, [form, name])

  const handleSendReviewClick = useCallback(async () => {
    const reviewNotes = form.getFieldState(reviewNotesFieldName)?.value
    // If clear flag is true make sure the confirmation modal appears,
    // otherwise add the review notes without setting the flag to be true
    if (clearFlag) {
      onClickClearFlag(handleSendReviewClick)
      setUnsavedReviewIndex(null)
      setShowSubmit(false)
      return
    }

    if (
      !reviewNotes ||
      !reviewNotes.trim() ||
      unsavedReviewIndex === null ||
      unsavedReviewIndex === undefined ||
      !(unsavedReviewIndex >= 0)
    ) {
      return
    }

    const payload = {
      case_id: caseData.id,
      flag_id: flag.flag_id,
      reviewed_case_version: 0,
      reviewer_notes: reviewNotes,
      review_passed: clearFlag,
    }

    const res = await createCaseFlagReview({
      variables: {
        input: payload,
      },
    })

    if (!res) return

    setUnsavedReviewIndex(null)
    setShowSubmit(false)

    return res
  }, [
    caseData.id,
    clearFlag,
    createCaseFlagReview,
    flag.flag_id,
    form,
    onClickClearFlag,
    reviewNotesFieldName,
    unsavedReviewIndex,
  ])

  return (
    <Card id={`${id}Card`} padding={theme.spacing(4)} border={false}>
      <Field name={name}>
        {({ input }) => {
          const flagType = input.value.flag_type as CaseDetailsFlagFlagType
          const flagTypeMessageId = flagToMessage(flagType)
          const flagName = flagTypeMessageId
            ? formatMessage(flagTypeMessageId.toLowerCase())
            : CaseDetailsFlagFlagType.InvalidFlagType
          const haveApprovePermission = havePermissionForFlag(
            flagType,
            hasEditAMLCaseFlagsPermission,
            hasEditAdviceCaseFlagsPermission,
          )
          const canMarkNoFurtherReview = canMarkNoFurtherReviewFlags.includes(flagType)

          return (
            <>
              <Box mb={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Box display="flex" alignItems="center">
                      <H2 styledAs="h3">
                        <FormattedMessage id="cases.flags.note.heading" />
                      </H2>
                      <Box ml={1.5}>
                        <Tag text={flagName} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <UnsetSectionDivider margin="size32" />

              {/* Inputs */}
              <FieldArray name={`${name}.reviews`}>
                {({ fields }) =>
                  (fields || []).map((name, index) => {
                    const key = fields.value[index].id
                    const disabled =
                      !(Boolean(index >= Number(fields.length || 0) - 1) && showSubmit) || !haveApprovePermission
                    const review = fields.value[index]

                    return (
                      <Field key={key} name={`${name}.reviewer_notes`}>
                        {({ input }) => {
                          return (
                            <>
                              <NoteBodyItem
                                {...input}
                                id={`${index}`}
                                placeholder={formatMessage('generic.typeHere')}
                                createdDate={
                                  <Field name={`${name}.created_at`}>
                                    {({ input: { value } }) =>
                                      value ? (
                                        <Box mb={theme.spacers.size4}>
                                          <GreyText>
                                            <FormattedMessage
                                              id="cases.flags.note.reviewCreatedAt"
                                              values={createCaseFlagTimeStamp(value)}
                                            />
                                          </GreyText>
                                        </Box>
                                      ) : (
                                        ''
                                      )
                                    }
                                  </Field>
                                }
                                disabled={disabled}
                              />
                              {disabled && (
                                <Box mb={0.5}>
                                  <GreyText>
                                    {review.review_passed ? (
                                      <FormattedMessage id="cases.flags.note.caseFlagPassedYES" />
                                    ) : (
                                      <FormattedMessage id="cases.flags.note.caseFlagPassedNO" />
                                    )}
                                  </GreyText>
                                </Box>
                              )}
                            </>
                          )
                        }}
                      </Field>
                    )
                  })
                }
              </FieldArray>
              {/* Footer */}
              <NoteFooter
                id={id}
                left={
                  <Box mt={1.5}>
                    {showSubmit ? (
                      <>
                        <Box mb={1.5}>
                          <Box display="flex">
                            <Checkbox
                              id={`${id}ClearFlagCheckbox`}
                              label={formatMessage(`${messagePrefixNote}.allowCaseToProceed`)}
                              checked={clearFlag}
                              disabled={!haveApprovePermission}
                              onChange={() => {
                                setClearFlag(!clearFlag)
                              }}
                            />
                            {clearFlag && canMarkNoFurtherReview && (
                              <Box ml={2}>
                                <Field
                                  name={`${name}.reviews[${unsavedReviewIndex! - 1}].skip_future_reviews`}
                                  type="checkbox"
                                >
                                  {({ input }) => (
                                    <Checkbox
                                      id={`${id}NoFurtherReviewCheckbox`}
                                      label={formatMessage(`${messagePrefixNote}.noFurtherReviewRequired`)}
                                      disabled={!haveApprovePermission}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Button
                          data-testid={testHandle(`${id}SaveReview`)}
                          id={`${id}SaveReview`}
                          type={BUTTON_TYPE_SUBMIT}
                          buttonStyle={BUTTON_SECONDARY}
                          onClick={handleSendReviewClick}
                          disabled={!(haveAddCaseNotePermission || haveApprovePermission)}
                          isLoading={createCaseFlagReviewLoading}
                        >
                          <FormattedMessage id={`${messagePrefixNote}.saveReview`} />
                        </Button>
                      </>
                    ) : (
                      <Button
                        data-testid={testHandle(`${id}WriteAnotherReview`)}
                        id={`${id}WriteAnotherReview`}
                        type={BUTTON_TYPE_BUTTON}
                        buttonStyle={BUTTON_SECONDARY}
                        onClick={handleAddClick}
                        disabled={
                          !havePermissionForFlag(
                            flagType,
                            hasEditAMLCaseFlagsPermission,
                            hasEditAdviceCaseFlagsPermission,
                          )
                        }
                      >
                        + <FormattedMessage id={`${messagePrefixNote}.writeAnotherReview`} />
                      </Button>
                    )}
                  </Box>
                }
              />
            </>
          )
        }}
      </Field>
    </Card>
  )
}

export default CaseFlagNote
