import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface NumberFieldClasses {
  root: string
  field: string
  label: string
  input: string
  helperText: string
  errorText: string
}

export type NumberFieldClassKey = keyof NumberFieldClasses

export function getNumberFieldUtilityClass(slot: string) {
  return generateUtilityClass('NumberField', slot)
}

const numberFieldClasses: NumberFieldClasses = generateUtilityClasses('NumberField', [
  'root',
  'field',
  'label',
  'input',
  'helperText',
  'errorText',
])

export default numberFieldClasses
