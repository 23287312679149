import React, { useCallback, useMemo, useState } from 'react'
import { CardRow } from '@broker-crm-common'
import { TaskRowSidebarDraw } from '@broker-crm-common/ReviewModeDrawer/Tasks/Tasks.styles'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box } from '@mui/material'
import { Location } from 'history'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { CaseStatus, Maybe } from '@acre/graphql'
import { trackEventFiltered } from '@acre/design-system'

import { TasksLocation } from '../CaseOverview.helpers'
import { ChevronIcon } from './CaseVerifications.helpers'
import SubmitPreSalesCheckVerificationModal from './SubmitPreSalesCheckVerificationModal'
import { VerificationMessage } from '../CaseOverview.styles'

type SubmitForCheckingProps = {
  caseId: string
  caseStatus: Maybe<CaseStatus>
  tasksLocation?: TasksLocation
}

const SubmitForCaseCheckButton = ({ caseId, caseStatus, tasksLocation }: SubmitForCheckingProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { trackEvent } = useMatomo()
  let location = useLocation() as Location

  const rowContent = useMemo(() => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <VerificationMessage>
          <FormattedMessage id="caseOverview.verifications.submitForPreSales" />
        </VerificationMessage>
        {ChevronIcon}
      </Box>
    )
  }, [])

  const handleClick = useCallback(() => {
    setModalOpen(true)
    trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
  }, [location, tasksLocation, trackEvent])

  const renderRowContent = useCallback(() => {
    if (tasksLocation === TasksLocation.CaseSidebarDrawer) {
      return <TaskRowSidebarDraw onClick={handleClick}>{rowContent}</TaskRowSidebarDraw>
    } else {
      return <CardRow onClick={handleClick}>{rowContent}</CardRow>
    }
  }, [handleClick, rowContent, tasksLocation])

  return (
    <>
      {renderRowContent()}
      <SubmitPreSalesCheckVerificationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        caseId={caseId}
        caseStatus={caseStatus}
      />
    </>
  )
}

export default SubmitForCaseCheckButton
