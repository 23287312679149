import { ReactElement, ReactNode } from 'react'

export enum Size {
  Default = 'default',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Colour {
  Brown = 'brown',
  Mint = 'mint',
  Navy = 'navy',
  Grey = 'grey',
  Grey2 = 'grey2',
  Blue = 'blue',
  Olive = 'olive',
  Orange = 'orange',
  Peach = 'peach',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  Turquoise = 'turquoise',
  Violet = 'violet',
  Yellow = 'yellow',
  Black = 'black',
  White = 'white',
}

export enum NewColour {
  Amber = 'amber',
  Peach2 = 'peach2',
  Blue1 = 'blue1',
  Blue2 = 'blue2',
  Blue3 = 'blue3',
  Brown1 = 'brown1',
  Brown2 = 'brown2',
  Green = 'green',
  Grey1 = 'grey1',
  Grey2 = 'grey2',
  Grey3 = 'grey3',
  Grey4 = 'grey4',
  Mint1 = 'mint1',
  Mint2 = 'mint2',
  Navy1 = 'navy1',
  Navy2 = 'navy2',
  Olive1 = 'olive1',
  Olive2 = 'olive2',
  Orange1 = 'orange1',
  Orange2 = 'orange2',
  Pink1 = 'pink1',
  Pink2 = 'pink2',
  Purple1 = 'purple1',
  Purple2 = 'purple2',
  Red1 = 'red1',
  Red2 = 'red2',
  Turquoise1 = 'turquoise1',
  Turquoise2 = 'turquoise2',
  Violet1 = 'violet1',
  Violet2 = 'violet2',
  Yellow1 = 'yellow1',
  Yellow2 = 'yellow2',
  Transparent = 'transparent',
  White = 'white',
}

export enum PairedColour {
  Brown = 'brown',
  Blue = 'blue',
  Mint = 'mint',
  Navy = 'navy',
  Olive = 'olive',
  Orange = 'orange',
  Peach = 'peach',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  Turquoise = 'turquoise',
  Violet = 'violet',
  Yellow = 'yellow',
  Grey = 'grey',
  White = 'white',
}

export type Height = 'short' | 'medium' | 'high'

export type ModalSize = 'medium' | 'large' | 'Large' | 'extraLarge' | 'fullScreen' | 'extraExtraLarge'

export enum ColourId {
  LightRed = 1,
  LightPurple,
  LightBlue,
  BaseLightColor,
  LightGreen,
  LightOrange,
  Orange,
  BabyPink,
  Pink,
  Purple,
  LighterPurple,
  SkyBlue,
  Green,
  OryGreen,
  Periwinkle,
  Yellow,
  Grey,
  AcreBlue,
  PattensBlue,
  Cobalt,
  BaseMid,
  White,
  Black,
  DarkYellow,
  Indigo,
  OryPurple,
  Red,
  Turquoise,
  DarkBlue,
}

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'secondarySmall'
  | 'clear'
  | 'clearSmall'
  | 'link'
  | 'linkSmall'
  | 'toggle'
  | 'overflow'
  | 'dashed'
  | 'danger'
  | 'dangerSecondary'
  | 'popOver'
  | 'popOverDanger'
  | 'tag'
  | 'dropdownMenu'
  | 'popOverDangerOnHover'
  | 'coloured'

export type ButtonType = 'button' | 'submit'

export interface ItemCardData {
  value: string | number | null | ReactElement[] | ReactNode
  label?: string | ReactElement
  leftWidth?: string
  id?: string
}

export type DropdownOption<T = string> = {
  label: string
  value: T
  count?: number
  isDisabled?: boolean
  disabled?: boolean
  group?: string
  id?: string
}
