import { TypePolicies } from '@apollo/client'

import document_categories from './document_categories'
import miDocuments from './miDocuments'

export default {
  fields: {
    document_categories,
    miDocuments,
  },
} as TypePolicies['Client']
