import React, { Children, cloneElement, isValidElement, PropsWithChildren, ReactNode } from 'react'
import { Theme } from '@mui/material'

import { StyledThead } from './Table.styles'

export type TheadProps = PropsWithChildren<{
  gutterPadding?: keyof Theme['spacers']
  borderBottom?: boolean
  borderTop?: boolean
  rowPadding?: boolean
  fontSize?: number
  divided?: boolean
}>

const Thead = ({
  gutterPadding,
  children,
  borderBottom = true,
  borderTop = false,
  rowPadding,
  fontSize,
  divided = false,
}: TheadProps) => {
  const childrenWithProps = Children.map<ReactNode, ReactNode>(children || [], (child) =>
    isValidElement(child)
      ? cloneElement(child, {
          gutterPadding,
          rowPadding,
          fontSize,
          ...child.props,
        })
      : null,
  )

  return (
    <StyledThead borderBottom={borderBottom} borderTop={borderTop} divided={divided}>
      {childrenWithProps}
    </StyledThead>
  )
}

export default Thead
