import { FeeType, GetFeesForCaseQuery, Maybe, Mortgage, NewFee, omitTypename, ProtectionProduct } from '@acre/graphql'

import { getFeeTypeTotal } from '../CompleteTransitionTask.helpers'

export const messagePrefix = 'caseTransitions.toComplete'

export const isInitialCommissionOneOff = (product: ProtectionProduct) => {
  return !product.details.commission?.initial_commission_end_date
}

export const getProductsWithCommissionFeesOnly = (products: ProtectionProduct[]) =>
  products.filter((product) => Boolean(product.details.commission?.initial_commission))

export const getInitialCommissionLabel = (totalNumberOfProducts: number, isOneOffCommission: boolean) => {
  const secondaryPrefix = isOneOffCommission ? 'indemnifiedCommission' : 'nonIndemnifiedCommission'
  return totalNumberOfProducts > 1 ? `${messagePrefix}.${secondaryPrefix}X` : `${messagePrefix}.${secondaryPrefix}`
}

export const getMortgageFeesWithLabels = (
  formatMessage: (id: string, values?: Record<string, string | number>) => string,
  selectedMortgage?: Maybe<Mortgage>,
  feesData?: GetFeesForCaseQuery,
) => {
  const { calculated_values, mortgage_amount } = selectedMortgage || {}
  const { fees: rawFees } = feesData?.case.details || {}
  const fees = rawFees ? (omitTypename(rawFees) as NewFee[]) : null

  // Get all the relevant fees

  const procFee = calculated_values?.calculated_net_proc_fee
    ? parseInt(calculated_values.calculated_net_proc_fee)
    : null

  const casePreAdviceFee = fees?.find((fee) => fee?.fee_type === FeeType.PreadviceFixed)
  const preAdviceFee = casePreAdviceFee?.value ? parseInt(casePreAdviceFee.value) : null

  const preRecommendationFee = fees
    ? getFeeTypeTotal([FeeType.PrerecommendationFixed, FeeType.PrerecommendationPercent], fees, mortgage_amount)
    : null

  const preApplicationFee = fees
    ? getFeeTypeTotal([FeeType.PreapplicationFixed, FeeType.PreapplicationPercent], fees, mortgage_amount)
    : null

  const offerFee = fees ? getFeeTypeTotal([FeeType.OfferFixed, FeeType.OfferPercent], fees, mortgage_amount) : null

  const legalFee = fees
    ? getFeeTypeTotal(
        [
          FeeType.LegalCompletionFixed,
          FeeType.LegalCompletionPercent,
          FeeType.LegalExchangeFixed,
          FeeType.LegalExchangePercent,
        ],
        fees,
        mortgage_amount,
      )
    : null

  const feesWithLabels = [
    {
      feeType: formatMessage(`${messagePrefix}.procFee`),
      feeAmount: procFee,
    },
    { feeType: formatMessage(`${messagePrefix}.preAdviceFee`), feeAmount: preAdviceFee },
    { feeType: formatMessage(`${messagePrefix}.preRecommendationFee`), feeAmount: preRecommendationFee },
    { feeType: formatMessage(`${messagePrefix}.preApplicationFee`), feeAmount: preApplicationFee },
    { feeType: formatMessage(`${messagePrefix}.offerFee`), feeAmount: offerFee },
    { feeType: formatMessage(`${messagePrefix}.legalFee`), feeAmount: legalFee },
  ]

  //only return fees that have fee amounts associated with them
  return feesWithLabels.filter(({ feeAmount }) => Boolean(feeAmount))
}

export const getProtectionFeesWithLabels = (
  products: ProtectionProduct[],
  formatMessage: (id: string, values?: Record<string, string | number> | undefined) => string,
) => {
  const productsWithInitialCommission = getProductsWithCommissionFeesOnly(products)
  const numberOfProtectionProductsWithInitialCommission = productsWithInitialCommission.length

  return productsWithInitialCommission?.map((product, idx) => {
    const isOneOffCommission = isInitialCommissionOneOff(product)
    const commission = product.details.commission?.initial_commission
    return {
      feeType: formatMessage(
        getInitialCommissionLabel(numberOfProtectionProductsWithInitialCommission, isOneOffCommission),
        // there can be multiple protection commissions (which will have the same name)
        // therefore to differentiate between them we append the name with the 1-based index
        {
          x: idx + 1,
        },
      ),
      feeAmount: commission ? parseInt(commission) : null,
    }
  })
}
