import { MouseEvent as ReactMouseEvent } from 'react'
import { Theme } from '@mui/material'
import { transparentize } from 'polished'

import { Maybe } from '@acre/graphql'

import { ColourCardTypes } from '..'

export const defaultValue = '-'

export const setIsClickable = (
  disabled: boolean,
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void,
) => {
  if (disabled) {
    return false
  } else if (onClick) {
    return true
  }
  return false
}

// Check if the clicked element is below a button in the hierachy, or is a button itself
export const hasButtonParent = (element: HTMLElement) => {
  let el = element
  const parents = [element]

  while (el && !el.classList.contains('cardParent')) {
    parents.push(el.parentElement!)
    el = el.parentElement!
  }

  return parents.some((el) => el?.tagName === 'BUTTON')
}

export const setColour = (colourVariant: Maybe<ColourCardTypes>, theme: Theme) => {
  if (colourVariant === ColourCardTypes.Warning) {
    return theme.colours.new.yellow2
  } else if (colourVariant === ColourCardTypes.Danger) {
    return theme.colours.danger
  }
  return theme.colours.new.blue1
}

export const setCardBackground = (colourVariant: Maybe<ColourCardTypes>, theme: Theme) => {
  let opacity = theme.opacity.op90
  let colour = theme.colours.foreground

  if (colourVariant === ColourCardTypes.Warning) {
    opacity = theme.opacity.op60
    colour = theme.colours.new.yellow1
  } else if (colourVariant === ColourCardTypes.Danger) {
    opacity = theme.opacity.op60
    colour = theme.colours.new.red1
  }
  return transparentize(opacity, colour)
}

export const setHoverBackground = (colourVariant: Maybe<ColourCardTypes>, theme: Theme) => {
  let opacity = theme.opacity.op90
  let colour = theme.colours.new.blue1

  if (colourVariant === ColourCardTypes.Warning) {
    opacity = theme.opacity.op40
    colour = theme.colours.new.yellow1
  } else if (colourVariant === ColourCardTypes.Danger) {
    opacity = theme.opacity.op40
    colour = theme.colours.new.red1
  }
  return transparentize(opacity, colour)
}

export const setRowBackground = (colourVariant: Maybe<ColourCardTypes>, theme: Theme) => {
  let opacity = theme.opacity.op90
  let colour = theme.colours.new.blue1

  if (colourVariant === ColourCardTypes.Warning) {
    opacity = theme.opacity.op60
    colour = theme.colours.new.yellow1
  } else if (colourVariant === ColourCardTypes.Danger) {
    opacity = theme.opacity.op60
    colour = theme.colours.new.red1
  }
  return transparentize(opacity, colour)
}
