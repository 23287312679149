import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { CardRowLine } from '@broker-crm-common'
import { TaskRowSidebarDraw } from '@broker-crm-common/ReviewModeDrawer/Tasks/Tasks.styles'
import { useCaseContext } from '@broker-crm-contexts'
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types'
import { Location } from 'history'
import { FormattedMessage, useIntl } from 'react-intl'

import { isGeneralProtection, isHomeInsurance, testHandle, useUserContext } from '@acre/utils'
import {
  Case,
  GetProtectionProductsDocument,
  GetProtectionProductsQuery,
  ProtectionProductStatus,
  useGeneralInsuranceProductsQuery,
  useGetOrganisationQuery,
  User,
} from '@acre/graphql'
import { trackEventFiltered } from '@acre/design-system'

import AddReminderModal from '../../../../../Reminders/AddReminder/AddReminderModal'
import { setInitialReminderValues, TasksLocation } from '../../CaseOverview.helpers'
import { ChevronIcon, getCaseSelectedMortgage, getEarliestGIProductEndDate } from '../CaseVerifications.helpers'
import { messagePrefix, TransitionToCompleteStage } from './CompleteTransitionTask.helpers'
import ConfirmFeesModal from './ConfirmFeesModal/ConfirmFeesModal'
import ConfirmProtectionDetailsFlow from './ConfirmProtectionDetails/ConfirmProtectionDetailsFlow'
import MortgageDetailsModal from './MortgageDetailsModal'
import { VerificationTaskButton } from '../../CaseOverview.styles'

type Props = {
  caseId: string
  onSubmit?: () => any
  isProtection: boolean
  tasksLocation?: TasksLocation
  trackEvent?: (params: TrackEventParams) => void | undefined
  location?: Location<unknown>
}

const { MORTGAGE_DETAILS, UPDATE_CASE_STATUS, PROTECTION_DETAILS, ADD_REMINDER } = TransitionToCompleteStage

const CompleteTransitionTask = ({
  caseId,
  onSubmit,
  isProtection = false,
  tasksLocation,
  trackEvent,
  location,
}: Props) => {
  const caseVersion = useCaseContext()
  const user = useUserContext()
  const intl = useIntl()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const firstStage = isProtection ? PROTECTION_DETAILS : MORTGAGE_DETAILS
  const [transitionStage, setTransitionStage] = useState<TransitionToCompleteStage>(firstStage)

  const variables = { protectionProductDetails: true, filterCaseIds: [caseId] }
  const { data } = useQuery<GetProtectionProductsQuery>(GetProtectionProductsDocument, { variables })

  const selectedProducts = useMemo(
    () => data?.protectionProducts?.filter((product) => product.details.status === ProtectionProductStatus.Selected),
    [data?.protectionProducts],
  )

  const numberOfProducts = selectedProducts?.length || 0

  const transitionFromMortgageDetails = useCallback(() => {
    numberOfProducts > 0 ? setTransitionStage(PROTECTION_DETAILS) : setTransitionStage(ADD_REMINDER)
  }, [numberOfProducts])

  const transitionFromProtectionDetails = useCallback(() => setTransitionStage(ADD_REMINDER), [])
  const transitionFromReminderModal = useCallback(() => setTransitionStage(UPDATE_CASE_STATUS), [])

  const handleClose = useCallback(() => setIsModalOpen(false), [])
  const handleSubmit = useCallback(async () => {
    onSubmit && (await onSubmit())
    handleClose()
  }, [handleClose, onSubmit])

  const handleTransitionToCompleteClick = useCallback(() => {
    setIsModalOpen(true)
    setTransitionStage(firstStage)
    trackEvent && trackEventFiltered(trackEvent, location, 'taskClick', `location-${tasksLocation}`)
  }, [firstStage, location, tasksLocation, trackEvent])

  const buttonContent = useMemo(() => {
    return (
      <VerificationTaskButton type="button" id={`CaseTransitionToCOMPLETE`}>
        <FormattedMessage id={`${messagePrefix}.completeCase`} />
        {ChevronIcon}
      </VerificationTaskButton>
    )
  }, [])

  const renderButton = useMemo(() => {
    if (tasksLocation === TasksLocation.CaseSidebarDrawer) {
      return <TaskRowSidebarDraw onClick={handleTransitionToCompleteClick}>{buttonContent}</TaskRowSidebarDraw>
    } else {
      return (
        <CardRowLine
          style={{ display: 'flex' }}
          onClick={handleTransitionToCompleteClick}
          data-testid={testHandle(`CaseTransitionToCOMPLETE`)}
        >
          {buttonContent}
        </CardRowLine>
      )
    }
  }, [buttonContent, handleTransitionToCompleteClick, tasksLocation])

  const mortgage = caseVersion.details && getCaseSelectedMortgage(caseVersion.details as Case)
  const isHomeInsuranceCase = isHomeInsurance(caseVersion.details?.preference_mortgage_reason)
  const isProtectionCase = isGeneralProtection(caseVersion.details?.preference_mortgage_reason)

  const { data: lenderData } = useGetOrganisationQuery({
    variables: { id: mortgage?.mortgage_product?.lender_reference! },
    skip: !mortgage?.mortgage_product?.lender_reference,
  })
  const lenderName = (lenderData?.organisation && lenderData?.organisation.name) || ''

  const { data: giProductsOnCaseData } = useGeneralInsuranceProductsQuery({
    variables: {
      input: {
        filter_case_ids: [caseVersion.id],
      },
    },
    skip: !isHomeInsuranceCase,
  })
  const earliestGIProductEndDate = getEarliestGIProductEndDate(
    giProductsOnCaseData?.generalInsuranceProducts?.general_insurance_products || [],
  )

  const initialValues = setInitialReminderValues(
    caseVersion.details as Case,
    user as User,
    intl,
    lenderName,
    earliestGIProductEndDate,
  )

  return (
    <>
      {renderButton}
      {transitionStage === MORTGAGE_DETAILS && (
        <MortgageDetailsModal
          caseId={caseId}
          onSubmit={transitionFromMortgageDetails}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {transitionStage === PROTECTION_DETAILS && (
        <ConfirmProtectionDetailsFlow
          products={selectedProducts}
          isModalOpen={isModalOpen}
          onSubmit={transitionFromProtectionDetails}
          onClose={handleClose}
        />
      )}

      {transitionStage === ADD_REMINDER && (
        <AddReminderModal
          initialValues={initialValues}
          modalOpen={isModalOpen}
          transition={transitionFromReminderModal}
          isPartOfTheFlow={true}
          layout="review"
          isProtection={isProtectionCase}
          isHomeInsurance={isHomeInsuranceCase}
        />
      )}

      {/* include isModalOpen in condition, as otherwise the state of the component doesn't reset on closing modal */}
      {transitionStage === UPDATE_CASE_STATUS && (
        <ConfirmFeesModal
          protectionProducts={selectedProducts}
          caseId={caseId}
          onSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default React.memo(CompleteTransitionTask)
