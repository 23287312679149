import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { BUTTON_DANGER, BUTTON_SECONDARY } from '../../utils/constants'
import { ModalSize } from '../../utils/types'
import Modal from '../Modal'
import { PanelBarSections } from '../Modal/Modal.helpers'
import ModalFooter, { FooterButtonType } from '../ModalFooter'

export type ConfirmationModalProps = {
  handleClose: () => void
  footer: {
    secondaryButton?: FooterButtonType
    primaryButton: FooterButtonType
  }
  header?: PanelBarSections
  size?: ModalSize
  centered?: boolean
  id?: string
  children?: ReactNode
  isVisible?: boolean
}

const ConfirmationModal = ({
  size = 'medium',
  centered = false,
  children,
  footer,
  header,
  handleClose,
  id,
  isVisible,
}: ConfirmationModalProps) => {
  const Footer = (
    <ModalFooter
      secondaryButton={{
        isDisabled: footer?.secondaryButton?.isDisabled,
        onClick: handleClose,
        id: footer?.secondaryButton?.id || 'ConfirmationModalCancel',
        text: footer?.secondaryButton?.text || 'generic.cancel',
        style: footer?.secondaryButton?.style || BUTTON_SECONDARY,
      }}
      primaryButton={{
        isDisabled: footer?.primaryButton?.isDisabled,
        onClick: footer?.primaryButton?.onClick,
        isLoading: footer?.primaryButton?.isLoading,
        id: footer?.primaryButton?.id || 'ConfirmationModalSubmit',
        text: footer?.primaryButton?.text || 'generic.leaveWithoutSaving',
        style: footer?.primaryButton?.style || BUTTON_DANGER,
      }}
    />
  )

  return (
    <Modal
      size={size}
      centered={centered}
      open={isVisible ?? true}
      onClose={handleClose}
      showHeading={false}
      header={{
        left: header?.left,
      }}
      footer={Footer}
      id={id || 'ConfirmationModal'}
    >
      <>{children || <FormattedMessage id="generic.unsavedChanges" />}</>
    </Modal>
  )
}

export default ConfirmationModal
