import React from 'react'

import TextInput, { TextInputProps } from '../TextInput'

export type PhoneInputProps = Omit<TextInputProps, 'type'>

const PhoneInput = (props: PhoneInputProps) => {
  return <TextInput {...props} type="tel" />
}

export default PhoneInput
