import { useMutation } from '@apollo/client'

import { ErrorProvider } from '@acre/utils'
import {
  AddNoteDocument,
  AddNoteMutation,
  Case,
  CommunicationType,
  GetNotesDocument,
  Maybe,
  Note,
  NoteInput,
  NoteType,
  UpdateNoteDocument,
  UpdateNoteMutation,
  User,
} from '@acre/graphql'
import { IconName, PairedColour } from '@acre/design-system'

const noteTitlesPrefix = 'cases.notes.noteTitles'

export enum FENoteType {
  General = 'General',
  Calendar = 'Calendar',
  Sms = 'Sms',
  Email = 'Email',
  Call = 'Call',
  Messaging = 'Messaging',
}

export type Recipient = {
  id: string
  name: string
  email: string
}

export const getNoteTypeDisplayDetails = (noteType?: Maybe<FENoteType>) => {
  switch (noteType) {
    case FENoteType.General:
      return { iconName: IconName.Edit, iconColour: PairedColour.Purple, title: `${noteTitlesPrefix}.generalNote` }
    case FENoteType.Calendar:
      return { iconName: IconName.Calendar, iconColour: PairedColour.Turquoise, title: `${noteTitlesPrefix}.calendar` }
    case FENoteType.Sms:
      return { iconName: IconName.Smartphone, iconColour: PairedColour.Violet, title: `${noteTitlesPrefix}.sms` }
    case FENoteType.Email:
      return { iconName: IconName.Mail, iconColour: PairedColour.Yellow, title: `${noteTitlesPrefix}.email` }
    case FENoteType.Call:
      return { iconName: IconName.Phone, iconColour: PairedColour.Mint, title: `${noteTitlesPrefix}.call` }
    case FENoteType.Messaging:
      return {
        iconName: IconName.MessageCircle,
        iconColour: PairedColour.Orange,
        title: `${noteTitlesPrefix}.messaging`,
      }
    default:
      return { iconName: IconName.HelpCircle, iconColour: PairedColour.Navy, title: `${noteTitlesPrefix}.unknown` }
  }
}

export const getCheckboxesInitialState = (notes?: Maybe<Note[]>) =>
  Object.keys(FENoteType).map((noteType) => ({
    noteType: noteType as FENoteType,
    ...getNoteTypeDisplayDetails(noteType as FENoteType),
    checked: true,
    amount:
      notes?.reduce(
        (acc, { primary_type, communication_type }) =>
          beToFeNoteTypeMapping(primary_type, communication_type) === noteType ? acc + 1 : acc,
        0,
      ) || 0,
  }))

export enum CheckboxReducerActionType {
  ShowAll = 'showAll',
  Check = 'check',
  UpdateCount = 'updateCount',
}

export const checkboxReducer = (
  state: ReturnType<typeof getCheckboxesInitialState>,
  action:
    | { type: CheckboxReducerActionType.Check; payload: FENoteType }
    | { type: CheckboxReducerActionType.ShowAll; payload?: never }
    | { type: CheckboxReducerActionType.UpdateCount; payload: Note[] },
) => {
  switch (action.type) {
    case CheckboxReducerActionType.ShowAll:
      return state.map((props) => ({ ...props, checked: props.amount > 0  }))
    case CheckboxReducerActionType.Check:
      return (() => {
        const isAllChecked = state.every((props) => (props.amount > 0 && props.checked) || props.amount === 0)
        if (isAllChecked) {
          return state.map((props) => ({ ...props, checked: props.noteType === action.payload ? true : false }))
        } else if (action.payload === FENoteType.Messaging) {
          return state.map(({ noteType, checked, ...props }) => ({
            ...props,
            noteType,
            checked: noteType === action.payload ? !checked : false,
          }))
        } else {
          return state.map(({ noteType, checked, ...props }) => {
            if (noteType === FENoteType.Messaging)
              return {
                ...props,
                noteType,
                checked: false,
              }
            return {
              ...props,
              noteType,
              checked: noteType === action.payload ? !checked : checked,
            }
          })
        }
      })()
    case CheckboxReducerActionType.UpdateCount:
      return state.map((props) => ({
        ...props,
        amount:
          action.payload?.reduce(
            (acc, { primary_type, communication_type }) =>
              beToFeNoteTypeMapping(primary_type, communication_type) === props.noteType ? acc + 1 : acc,
            0,
          ) || 0,
      }))
    default:
      return [...state]
  }
}

export const beToFeNoteTypeMapping = (
  primary_type?: Maybe<NoteType>,
  communication_type?: Maybe<CommunicationType>,
) => {
  switch (primary_type) {
    case NoteType.Note:
      return FENoteType.General
    case NoteType.Calendar:
      return FENoteType.Calendar
    case NoteType.Communication:
      switch (communication_type) {
        case CommunicationType.Sms:
          return FENoteType.Sms
        case CommunicationType.Email:
          return FENoteType.Email
        case CommunicationType.Call:
          return FENoteType.Call
        case CommunicationType.SecureClientCommunication:
          return FENoteType.Messaging
        default:
          return null
      }
    default:
      return null
  }
}

export const feToBeNoteTypeMapping = (noteType: FENoteType) => {
  switch (noteType) {
    case FENoteType.General:
      return { primary_type: NoteType.Note, communication_type: null }
    case FENoteType.Calendar:
      return { primary_type: NoteType.Calendar, communication_type: null }
    case FENoteType.Email:
      return { primary_type: NoteType.Communication, communication_type: CommunicationType.Email }
    case FENoteType.Sms:
      return { primary_type: NoteType.Communication, communication_type: CommunicationType.Sms }
    case FENoteType.Call:
      return { primary_type: NoteType.Communication, communication_type: CommunicationType.Call }
    case FENoteType.Messaging:
      return { primary_type: NoteType.Communication, communication_type: CommunicationType.SecureClientCommunication }
  }
}

export const useAddNote = ({ orgId, caseId }: { orgId: string; caseId: string }) => {
  const updateOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetNotesDocument,
        variables: {
          params: {
            organisation_id: orgId,
            filter_case_id: caseId,
          },
        },
      },
    ],
  }

  const [addNoteOp, { loading }] = useMutation<AddNoteMutation>(AddNoteDocument, {
    ...updateOptions,
    onError: (err) => ErrorProvider.showError(new Error(err.message)),
  })
  const addNote = (note: NoteInput) => addNoteOp({ variables: { input: note } })
  return { addNote, loading }
}

export const useUpdateMessage = () => {
  const [updateMessageOp, { loading }] = useMutation<UpdateNoteMutation>(UpdateNoteDocument, {
    onError: (err) => ErrorProvider.showError(new Error(err.message)),
  })
  const updateMessage = (note: NoteInput) => updateMessageOp({ variables: { note_id: note.note_id, input: note } })
  return { updateMessage, loading }
}

export const getOrgIdForCaseNotes = (user: Maybe<User>, caseOwner: Maybe<User>, details: Case) => {
  let orgId: string

  const userId = user?.id
  const userOrgId = user?.organisation_id
  const caseOwnerOrgId = caseOwner?.organisation_id
  const ownerOrganisationId = details?.owner_organisation_id
  const caseOwnerId = details?.owner_user_id

  if (!caseOwnerId) {
    orgId = ownerOrganisationId as string
  } else if (userId === caseOwnerId) {
    orgId = userOrgId as string
  } else {
    orgId = caseOwnerOrgId as string
  }
  return orgId
}

export const getOrgIdsForAddingNote = (user: Maybe<User>, details: Case, orgIdForCaseNotes: string) => {
  const { organisation } = user || {}
  const caseOwnersOrgId = details?.owner?.organisation?.id
  const representativeOf = organisation?.representative_of

  if (caseOwnersOrgId && caseOwnersOrgId !== orgIdForCaseNotes) {
    return [orgIdForCaseNotes, caseOwnersOrgId]
  }

  if (representativeOf && representativeOf !== orgIdForCaseNotes) {
    return [orgIdForCaseNotes, representativeOf]
  }

  return [orgIdForCaseNotes]
}
