import React, { ComponentProps, useMemo } from 'react'
import { Box, IconButton, Paper, useTheme } from '@mui/material'
import FocusTrap from '@mui/material/Unstable_TrapFocus'
import {
  CloseButtonProps,
  DialogWrapperProps,
  NextStepButtonProps,
  PreviousStepButtonProps,
  ReactGrandTour,
  ReactGrandTourProps,
} from 'react-grand-tour'

import { Colour, Size } from '../../utils/types'
import ButtonNewest from '../ButtonNewest'
import FeatherIcon, { IconName } from '../FeatherIcon'

export interface GrandTourProps extends Omit<ReactGrandTourProps, 'children'> {
  children?: React.ReactNode
}

const CloseButton = ({ close, PositionalWrapper }: CloseButtonProps) => {
  const theme = useTheme()
  const color = theme.colours.new.grey4 as Colour

  return (
    <PositionalWrapper>
      <IconButton
        disableRipple
        onClick={() => close?.('close-btn')}
        sx={{
          color: 'unset',
          p: 0,
          mt: '0.5rem',
          '&.Mui-focusVisible': { outline: '2px solid blue' },
        }}
      >
        <FeatherIcon name={IconName.X} colour={color} size={Size.Small} />
      </IconButton>
    </PositionalWrapper>
  )
}

const NextStepButton = ({ currentStep, totalSteps, goNext, close }: NextStepButtonProps) => {
  const theme = useTheme()
  const color = theme.colours.new.grey4 as Colour
  const disabledColor = theme.colours.grey as Colour

  return currentStep === totalSteps - 1 ? (
    <ButtonNewest
      data-testid="e2e-TourCloseButton"
      variant="secondary"
      size="small"
      onClick={close}
      sx={{ '&.Mui-focusVisible': { outline: '2px solid blue' } }}
    >
      Done
    </ButtonNewest>
  ) : (
    <IconButton
      disableRipple
      onClick={goNext}
      sx={{
        color: 'unset',
        p: 0,
        '&.Mui-focusVisible': { outline: '2px solid blue' },
      }}
    >
      <FeatherIcon name={IconName.ArrowRight} colour={currentStep === totalSteps - 1 ? disabledColor : color} />
    </IconButton>
  )
}

const PreviousStepButton = ({ currentStep, goBack }: PreviousStepButtonProps) => {
  const theme = useTheme()
  const color = theme.colours.new.grey4 as Colour
  const disabledColor = theme.colours.grey as Colour

  return (
    <IconButton
      disableRipple
      disabled={currentStep === 0}
      onClick={goBack}
      sx={{
        color: 'unset',
        p: 0,
        '&.Mui-focusVisible': { outline: '2px solid blue' },
      }}
    >
      <FeatherIcon name={IconName.ArrowLeft} colour={currentStep === 0 ? disabledColor : color} />
    </IconButton>
  )
}

const DialogWrapper = ({
  closeButton,
  content,
  stepButtonWrapper,
  previousStepButton,
  nextStepButton,
  hideCloseButton,
  hideNextStepButton,
  hideStepButtons,
  hidePreviousStepButton,
}: DialogWrapperProps) => (
  <FocusTrap open>
    <Paper
      sx={{
        '&:focus': { outline: 'none' },
      }}
    >
      <Box p={2} display="flex" flexDirection="column" alignItems="stretch">
        {!hideCloseButton && closeButton}
        {content}
        <Box display="flex" justifyContent="space-between">
          {!hidePreviousStepButton && previousStepButton}
          {!hideStepButtons && stepButtonWrapper}
          {!hideNextStepButton && nextStepButton}
        </Box>
      </Box>
    </Paper>
  </FocusTrap>
)

const componentOverrides: Partial<ComponentProps<typeof ReactGrandTour>> = {
  closeButton: React.memo(CloseButton) as typeof CloseButton,
  nextStepButton: React.memo(NextStepButton) as typeof NextStepButton,
  previousStepButton: React.memo(PreviousStepButton) as typeof PreviousStepButton,
  dialogWrapper: React.memo(DialogWrapper) as typeof DialogWrapper,
}

const GrandTour = (props: GrandTourProps) => {
  const theme = useTheme()

  const styles = useMemo(
    () => ({
      primaryColor: theme.palette.primary.main,
      dotBorder: 'none',
      dotBackgroundColor: theme.palette.secondary.light,
      dotHoverBackgroundColor: theme.palette.primary.light,
    }),
    [theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.light],
  )

  return <ReactGrandTour {...props} {...componentOverrides} disableCloseOnBackdropClick stylingOverrides={styles} />
}

export default React.memo(GrandTour)
