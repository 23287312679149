import React, { ReactElement } from 'react'
import { styled, useTheme } from '@mui/material'

export const Div = styled('div')<{
  top: string
  bottom: string
  left: string
  right: string
}>(
  ({ top, bottom, left, right }) => `
  padding-top: ${top};
  padding-bottom: ${bottom};
  padding-left: ${left};
  padding-right: ${right};
`,
)

export type SpacerSizes = 0 | 1 | 2 | 3 | 4

export type SpacerProps = {
  children?: ReactElement | Array<ReactElement>
  top?: SpacerSizes
  bottom?: SpacerSizes
  left?: SpacerSizes
  right?: SpacerSizes
}

const Spacer = ({ top = 0, bottom = 0, left = 0, right = 0, children = [] }: SpacerProps) => {
  const theme = useTheme()

  const Sizes = {
    '0': '0',
    '1': theme.display.ms1,
    '2': theme.display.ms2,
    '3': theme.display.ms3,
    '4': theme.display.ms4,
  }

  return (
    <Div top={Sizes[top]} bottom={Sizes[bottom]} left={Sizes[left]} right={Sizes[right]}>
      {children}
    </Div>
  )
}

export default Spacer
