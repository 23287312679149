import { createContext, useContext } from 'react'

// the intention for this context is to help avoid prop drilling
// but it could be used for multiple purposes, so it is a general "utility" context
// as it could be used to replace most of our other contexts
export type UtilityContextType = {
  // we assume a meta object would be with various prpoerties
  // such as complex and primitive types
  [key: string]: { [key: string]: any }
}

// @ts-ignore - Empty context type
export const UtilityContext = createContext<UtilityContextType>({})
export const UtilityContextConsumer = UtilityContext.Consumer
export const UtilityContextProvider = UtilityContext.Provider

export const useUtilityContext = () => useContext<UtilityContextType>(UtilityContext)
