import { styled } from '@mui/material'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import { transparentize } from 'polished'

export const StyledStepper = styled(Stepper)({
  width: '100%',
  overflowX: 'hidden',

  '& .MuiStepConnector-line': {
    display: 'none',
  },
})

export const StyledStep = styled(Step)(({ theme, active, last }) => ({
  opacity: active ? 1 : 0.5,
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },

  '& .MuiStepContent-root': {
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    borderLeft: last ? 0 : `2px solid ${theme.colours.baseLight}`,
    paddingBottom: last ? 0 : theme.spacing(0.5),

    '& p': {
      padding: theme.spacing(0.5),
      borderRadius: theme.display.borderRadius,
      backgroundColor: active ? transparentize(0.9, theme.colours.primary) : 'transparent',
      color: active ? theme.colours.primary : '',
    },
  },
}))
