import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface LabelClasses {
  root: string
}

export type LabelClassKey = keyof LabelClasses

export function getLabelUtilityClass(slot: string) {
  return generateUtilityClass('Label', slot)
}

const labelClasses: LabelClasses = generateUtilityClasses('Label', ['root'])

export default labelClasses
