import { ReactNode, useContext, useMemo } from 'react'
import { SideMenuItem } from '@broker-crm-common'
import { Stack, Tooltip, Typography, useTheme } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { useFormatMessage } from '@acre/utils'
import { CaseStatus, ProtectionProductStatus } from '@acre/graphql'
import { IconName } from '@acre/design-system'

import { CaseSubNavContext } from '../CaseSubnavContext'

export const requiredSuitabilityGates = [
  '2707a2c1-c5bf-47df-83c3-6ac722f30edd', // Gate 3.3: Property Detail Validation
  'e272c0be-f340-4a77-a0ab-221f2de72917', // Gate 3.4: Case Detail Validation
  '98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b', // Gate 3.5.1: Mortgage Details Validation
  '1603231f-4362-4770-81c9-eea2bfa740d2', // Gate 3.6: Sourcing Reconciliation & Validation
]

export const requiredSuitabilityMortgageVerification = '260631d7-1874-4c65-85d5-c983d920e35a' // Case has SELECTED mortgage
export const requiredSuitabilityProtectionVerification = '71d9a2ba-832d-11eb-8dcd-0242ac130003' // Case has recommended protection product

const messagePrefix = 'cases.suitability'

const CaseSuitabilityNavItem = ({ children }: { children?: ReactNode }) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  const {
    case: { verifications, id, details, missingFieldsToggle, missingFieldsTotals },
    isLeadCase,
  } = useContext(CaseSubNavContext)

  const passedSuitabilityCollections = useMemo(
    () => requiredSuitabilityGates.every((gateId) => !verifications?.failed_collection_ids?.includes(gateId)),
    [verifications?.failed_collection_ids],
  )

  const tooltipContent = useMemo(() => {
    if (isLeadCase) {
      return (
        <Typography fontSize={theme.typography.pxToRem(12)}>
          <FormattedMessage id={`${messagePrefix}.cannotEditLeadCase`} />
        </Typography>
      )
    }

    if (details?.status === CaseStatus.Complete) {
      return (
        <Typography fontSize={theme.typography.pxToRem(12)}>
          <FormattedMessage id={`${messagePrefix}.cannotEditCompleteCase`} />
        </Typography>
      )
    }

    if (!passedSuitabilityCollections) {
      return (
        <>
          <Typography fontSize={theme.typography.pxToRem(12)}>
            <FormattedMessage id={`${messagePrefix}.tasksRequiredEdit`} />
          </Typography>
          <ul>
            {/* Gate 3.3: Property Detail Validation */}
            {verifications?.failed_collection_ids.includes('2707a2c1-c5bf-47df-83c3-6ac722f30edd') && (
              <li>
                <FormattedMessage id={`${messagePrefix}.completePropertyDetails`} />
              </li>
            )}
            {/* Gate 3.4: Case Detail Validation */}
            {verifications?.failed_collection_ids.includes('e272c0be-f340-4a77-a0ab-221f2de72917') && (
              <li>
                <FormattedMessage id={`${messagePrefix}.completeCaseDetails`} />
              </li>
            )}
            {/* Gate 3.5.1: Mortgage Details Validation */}
            {verifications?.failed_collection_ids.includes('98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b') && (
              <li>
                <FormattedMessage id={`${messagePrefix}.completeMortgageDetails`} />
              </li>
            )}
            {/* Gate 3.6: Sourcing Reconciliation & Validation */}
            {verifications?.failed_collection_ids.includes('1603231f-4362-4770-81c9-eea2bfa740d2') && (
              <li>
                <FormattedMessage id={`${messagePrefix}.recommendShortlistedMortgage`} />
              </li>
            )}
          </ul>
          {!location.pathname.endsWith(`/cases/${id}/overview`) && (
            <Link to={`/cases/${id}/overview`}>
              <Typography fontSize={theme.typography.pxToRem(12)}>
                <FormattedMessage id={`${messagePrefix}.viewTasksCaseOverview`} />
              </Typography>
            </Link>
          )}
        </>
      )
    }
  }, [
    details?.status,
    id,
    isLeadCase,
    passedSuitabilityCollections,
    theme.typography,
    verifications?.failed_collection_ids,
  ])

  return (
    <Tooltip
      placement="right"
      title={
        tooltipContent && (
          <Stack
            spacing={1}
            sx={{ ul: { fontSize: theme.typography.pxToRem(12), listStyle: 'inside', lineHeight: 2, ml: 2 } }}
          >
            <Typography fontSize={theme.typography.pxToRem(14)} fontWeight={theme.typography.fontWeightBold}>
              <FormattedMessage id={`${messagePrefix}.suitabilityNotAvailable`} />
            </Typography>
            {tooltipContent}
          </Stack>
        )
      }
    >
      {/* Div is required for tooltip behaviour to work correctly, due to the interaction with SideMenuItem */}
      <div>
        <SideMenuItem
          to={'../suitability'}
          label={formatMessage('cases.subnav.suitability')}
          id="CaseNavSuitability"
          openPath="/cases/:caseId/suitability"
          disabled={Boolean(tooltipContent)}
          iconName={IconName.UserCheck}
          missingFieldsCount={missingFieldsTotals.suitabilityTotal}
          showMissingFields={missingFieldsToggle}
        >
          {children}
        </SideMenuItem>
      </div>
    </Tooltip>
  )
}

const CaseMortgageSuitabilityReportNavItem = ({ children }: { children?: ReactNode }) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  const {
    case: { verifications, missingFieldsToggle, missingFieldsTotals },
    setSuitabilityMenuOpen,
    isProtectionCase,
  } = useContext(CaseSubNavContext)

  const passedSuitabilityCollections = useMemo(
    () => requiredSuitabilityGates.every((gateId) => !verifications?.failed_collection_ids?.includes(gateId)),
    [verifications?.failed_collection_ids],
  )

  if (isProtectionCase || !passedSuitabilityCollections) return null

  const isDisabled = verifications?.failed_verification_ids.includes(requiredSuitabilityMortgageVerification)

  const tooltipContent = (
    <Stack spacing={1} sx={{ ul: { fontSize: theme.typography.pxToRem(12), listStyle: 'inside', lineHeight: 1.6 } }}>
      <Typography fontSize={theme.typography.pxToRem(14)} fontWeight={theme.typography.fontWeightBold}>
        <FormattedMessage id={`${messagePrefix}.mortgageSuitabilityNotAvailable`} />
      </Typography>
      <Typography fontSize={theme.typography.pxToRem(12)}>
        <FormattedMessage id={`${messagePrefix}.recommendMortgageProduct`} />
      </Typography>
    </Stack>
  )

  return (
    <Tooltip placement="right" title={isDisabled ? tooltipContent : null}>
      <div>
        <SideMenuItem
          label={formatMessage('cases.mortgageHeading')}
          to={isDisabled ? location.pathname : '../suitability/mortgage'}
          isHeading
          disabled={isDisabled}
          onClick={() => setSuitabilityMenuOpen(true)}
          missingFieldsCount={missingFieldsTotals.suitability.mortgageSRMIssingFieldsCount}
          showMissingFields={missingFieldsToggle}
        >
          {children}
        </SideMenuItem>
      </div>
    </Tooltip>
  )
}

const CaseProtectionSuitabilityReportNavItem = ({ children }: { children?: ReactNode }) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  const {
    case: { verifications, details, missingFieldsToggle, missingFieldsTotals },
    setSuitabilityMenuOpen,
  } = useContext(CaseSubNavContext)

  const passedSuitabilityCollections = useMemo(
    () => requiredSuitabilityGates.every((gateId) => !verifications?.failed_collection_ids?.includes(gateId)),
    [verifications?.failed_collection_ids],
  )

  if (!passedSuitabilityCollections) return null

  const isDisabled =
    verifications?.failed_verification_ids.includes(requiredSuitabilityProtectionVerification) ||
    isEmpty(
      details?.protection_products?.filter(
        (product) => product.details.status !== ProtectionProductStatus.NotProceeding,
      ),
    )

  const tooltipContent = (
    <Stack spacing={1} sx={{ ul: { fontSize: theme.typography.pxToRem(12), listStyle: 'inside', lineHeight: 1.6 } }}>
      <Typography fontSize={theme.typography.pxToRem(14)} fontWeight={theme.typography.fontWeightBold}>
        <FormattedMessage id={`${messagePrefix}.protectionSuitabilityNotAvailable`} />
      </Typography>
      <Typography fontSize={theme.typography.pxToRem(12)}>
        <FormattedMessage id={`${messagePrefix}.recommendProtectionProduct`} />
      </Typography>
    </Stack>
  )

  return (
    <Tooltip placement="right" title={isDisabled ? tooltipContent : null}>
      <div>
        <SideMenuItem
          label={formatMessage('cases.protectionHeading')}
          to={isDisabled ? location.pathname : '../suitability/protection'}
          isHeading
          disabled={isDisabled}
          onClick={() => setSuitabilityMenuOpen(true)}
          missingFieldsCount={missingFieldsTotals.suitability.protectionSRMissingFieldsCount}
          showMissingFields={missingFieldsToggle}
        >
          {children}
        </SideMenuItem>
      </div>
    </Tooltip>
  )
}

export { CaseSuitabilityNavItem, CaseMortgageSuitabilityReportNavItem, CaseProtectionSuitabilityReportNavItem }
