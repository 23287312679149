import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { useUserContext } from '@acre/utils'
import {
  GetNotificationsTokenDocument,
  GetNotificationsTokenQuery,
  GetNotificationsTokenQueryVariables,
} from '@acre/graphql'

/**
 * Connect to a websocket server and return the socket so you can perform
 * operations
 * @param url The URL of the websocket to connect to
 * @returns websocket instance
 */
export const useSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket>(null!)
  const user = useUserContext()
  const { data, loading, refetch } = useQuery<GetNotificationsTokenQuery, GetNotificationsTokenQueryVariables>(
    GetNotificationsTokenDocument,
    {
      fetchPolicy: 'no-cache',
      skip: !user,
    },
  )

  const openSocket = async (token: string) => {
    // Initial connection
    const newSocket = new WebSocket(`${url}?token=${token}`)

    if (!socket || (socket && ([socket.CLOSED, socket.CLOSING] as number[]).includes(socket.readyState))) {
      newSocket.onclose = () => {
        // Reconnection
        refetch().then((query) => {
          if (query.data?.getNotificationsToken?.token) {
            openSocket(query.data?.getNotificationsToken?.token)
          }
        })
      }

      newSocket.onopen = () => setSocket(newSocket)
    }
  }

  useEffect(() => {
    if (url && !socket && data) {
      if (data?.getNotificationsToken?.token) {
        openSocket(data.getNotificationsToken?.token)
      }
    }
  }, [data, loading, socket])

  useEffect(() => {
    // Disconnect when whole component unmounts
    return () => {
      socket && socket.close()
    }
  }, [])

  return socket
}
