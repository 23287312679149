import React, { ChangeEvent, PropsWithChildren } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Location } from 'history'
import { useLocation } from 'react-router-dom'

import { trackEventFiltered } from '../UserTracker'

export type MatamoWrapperProps = PropsWithChildren<{
  id?: string
  eventType: string
  onChange?: (e: ChangeEvent<any>) => void
  trackEventTrigger?: 'onChange' | 'onBlur'
}>

// track event when onChange event is triggered by default because this is the current behaviour
const MatomoWrapper = ({ id, children, eventType, trackEventTrigger = 'onChange' }: MatamoWrapperProps) => {
  const { trackEvent } = useMatomo()
  // Yes, this is insane but there's no good way to mock `useLocation` with react-router v5
  let location: Location
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation() as Location
  } catch {
    // no-op
    // without this jest goes boom
  }

  const handleTrack = () => {
    if (window.hasOwnProperty('Cypress')) {
      return
    }
    trackEventFiltered(trackEvent, location, eventType, id)
  }

  return (
    <div
      onChange={trackEventTrigger === 'onChange' ? handleTrack : undefined}
      onBlur={trackEventTrigger === 'onBlur' ? handleTrack : undefined}
      style={{ width: '100%' }}
    >
      {children}
    </div>
  )
}

export default MatomoWrapper
