import { useCaseContext } from '@broker-crm-contexts'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useFormState } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import {
  BUTTON_DANGER_SECONDARY,
  ButtonNotDisabled,
  COMPACT_LEFT_ALIGNED,
  FormSubmitAction,
  trackEventFiltered,
} from '@acre/design-system'

import { messagePrefix } from '../CompleteTransitionTask.helpers'

type Props = {
  onClick?: () => void
}

const UpdateClientAddressFooter = ({ onClick }: Props) => {
  const { hasValidationErrors, values, submitting } = useFormState({
    subscription: { submitting: true, hasValidationErrors: true, values: true },
  })

  const { trackEvent } = useMatomo()
  const location = useLocation()

  const { id } = useCaseContext()

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <ButtonNotDisabled
          id="CancelUpdateClientAddressButton"
          buttonStyle={BUTTON_DANGER_SECONDARY}
          disabled={submitting}
          onClick={onClick}
          variant={COMPACT_LEFT_ALIGNED}
        >
          <FormattedMessage id={`${messagePrefix}.updateClientAddressModal.cancel`} />
        </ButtonNotDisabled>
      </Box>

      <FormSubmitAction
        id="UpdateClientAddressButton"
        disabled={hasValidationErrors || isEmpty(values?.selectedClients)}
        loading={submitting}
        onClick={() => trackEventFiltered(trackEvent, location, 'updateClientAddressOnCompletion', `update-${id}`)}
      >
        <FormattedMessage id={`${messagePrefix}.updateClientAddressModal.updateAddress`} />
      </FormSubmitAction>
    </Box>
  )
}

export default UpdateClientAddressFooter
