import React from 'react'
import {
  Box,
  FormHelperText,
  inputBaseClasses,
  InputLabel,
  selectClasses,
  Stack,
  StackProps,
  styled,
  unstable_composeClasses,
} from '@mui/material'
import classnames from 'classnames'

import type { FieldSize, FieldWrapperProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import fieldWrapperClasses, { getFieldWrapperUtilityClass } from './fieldWrapperClasses'

const FieldWrapperRoot = styled(Stack, {
  name: 'FieldWrapper',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, styles[props.variant], styles.label],
  shouldForwardProp: (prop) => prop !== 'layout' && prop !== 'size',
})<{ direction: StackProps['direction']; size?: FieldSize }>(({ direction, size, theme }) => ({
  alignItems: 'flex-start',
  width: '100%',
  flexWrap: 'wrap',
  ...(direction === 'row' && {
    [`& .${getFieldWrapperUtilityClass('inputContainer')}`]: {
      flex: 1,
    },
    alignItems: 'baseline',
    flexWrap: 'nowrap',
  }),
  ...(direction === 'column' && {
    alignItems: 'stretch',
    height: '100%',
  }),

  [`& input, & .${inputBaseClasses.input}, & .${inputBaseClasses.input}.${selectClasses.select}`]: {
    fontSize: theme.typography.pxToRem(14),

    ...(direction === 'column' && {
      ...(size === 'large' && {
        height: '3rem',
        fontSize: theme.typography.pxToRem(16),
        lineHeight: '3rem',
      }),
    }),
  },

  [`& .${inputBaseClasses.adornedEnd}, & .${inputBaseClasses.adornedStart}`]: {
    fontSize: theme.typography.pxToRem(14),

    ...(direction === 'column' && {
      ...(size === 'large' && {
        fontSize: theme.typography.pxToRem(16),
      }),
    }),
  },

  [`& .${fieldWrapperClasses.labelContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',

    ...(direction === 'column' && {
      marginBottom: theme.spacing(0.5),
    }),

    ...(direction === 'row' && {
      height: theme.spacing(4),
      flexBasis: '30%',

      '& + div': {
        flexBasis: '70%',
        marginLeft: 0,
      },
    }),
  },

  [`& .${fieldWrapperClasses.inputContainer}`]: {
    marginTop: 0,
  },

  '& label': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'wrap',

    ...(direction === 'column' && {
      lineHeight: 1.4,

      ...(size === 'large' && {
        fontSize: theme.typography.pxToRem(14),
      }),
    }),
  },
}))

const useUtilityClasses = (ownerState: Partial<FieldWrapperProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    inputContainer: ['inputContainer'],
    label: ['label'],
    labelContainer: ['labelContainer'],
    helperText: ['helperText'],
    error: ['error'],
  }

  return unstable_composeClasses(slots, getFieldWrapperUtilityClass, ownerState.classes)
}

const _FieldWrapper = (props: FieldWrapperProps) => {
  const {
    className,
    classes,
    label,
    children,
    error,
    helperText,
    InputLabelProps,
    ErrorTextProps,
    FormHelperTextProps,
    layout = 'row',
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, size: rootProps.size, layout })

  const { ariaId, descriptionId, errorId, testId, labelId } = useAriaProps(props)

  const actualTestId = props['data-testid'] ?? `${testId}-wrapper`

  return (
    <FieldWrapperRoot
      {...rootProps}
      data-testid={actualTestId}
      spacing={1}
      direction={layout}
      className={classnames(slotClasses.root, className)}
    >
      {label && (
        <Box className={slotClasses.labelContainer}>
          <InputLabel
            {...InputLabelProps}
            id={labelId}
            htmlFor={ariaId}
            className={classnames(slotClasses.label, InputLabelProps?.className)}
          >
            {label}
          </InputLabel>
        </Box>
      )}

      <Stack className={slotClasses.inputContainer}>
        {children}

        {helperText &&
          React.isValidElement(helperText) &&
          React.cloneElement(helperText as React.ReactElement, {
            ...FormHelperTextProps,
            id: descriptionId,
            className: classnames(slotClasses.helperText, FormHelperTextProps?.className),
          })}

        {helperText && !React.isValidElement(helperText) && typeof helperText !== 'boolean' && (
          <FormHelperText
            {...FormHelperTextProps}
            className={classnames(slotClasses.helperText, FormHelperTextProps?.className)}
            id={descriptionId}
          >
            {helperText}
          </FormHelperText>
        )}

        {error &&
          React.isValidElement(error) &&
          React.cloneElement(error as React.ReactElement, {
            ...ErrorTextProps,
            id: errorId,
            role: 'alert',
            className: classnames(slotClasses.error, ErrorTextProps?.className),
          })}

        {error && !React.isValidElement(error) && typeof error !== 'boolean' && (
          <FormHelperText
            {...ErrorTextProps}
            className={classnames(slotClasses.error, ErrorTextProps?.className)}
            error
            id={errorId}
            role="alert"
          >
            {error}
          </FormHelperText>
        )}
      </Stack>
    </FieldWrapperRoot>
  )
}

const FieldWrapper = React.memo(_FieldWrapper) as typeof _FieldWrapper

export default FieldWrapper
