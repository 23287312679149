export enum YesNo {
  Yes = 'YES',
  No = 'NO',
}

export const yesNoOptions = [
  {
    label: 'generic.YES',
    value: YesNo.Yes,
  },
  {
    label: 'generic.NO',
    value: YesNo.No,
  },
]

export const reverseYesNoOptions = [
  {
    label: 'generic.YES',
    value: YesNo.No,
  },
  {
    label: 'generic.NO',
    value: YesNo.Yes,
  },
]
