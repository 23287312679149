import type { PopperProps } from '@mui/material'

export interface GetHoverTrapStylesArgs {
  offsetX?: number
  offsetY?: number
  placement?: PopperProps['placement']
}

const placementPrefixToDirection = {
  bottom: 'Top',
  left: 'Right',
  top: 'Bottom',
  right: 'Left',
}

export const getHoverTrapStyles = ({ offsetX = 0, offsetY = 0, placement = 'bottom' }: GetHoverTrapStylesArgs) => {
  const styles: Record<string, string | number> = {}

  let offsetDirection = 'Top'

  const prefix = placement.split('-')[0] as keyof typeof placementPrefixToDirection

  if (placementPrefixToDirection[prefix]) {
    offsetDirection = placementPrefixToDirection[prefix]
  }

  if (offsetY && (offsetDirection === 'Top' || offsetDirection === 'Bottom')) {
    styles[offsetY < 0 ? `margin${offsetDirection}` : `padding${offsetDirection}`] = offsetY
  }

  if (offsetX && (offsetDirection === 'Left' || offsetDirection === 'Right')) {
    styles[offsetX < 0 ? `margin${offsetDirection}` : `padding${offsetDirection}`] = offsetX
  }

  return styles
}

export interface GetHitAreaDataArgs {
  placement: PopperProps['placement']
  anchorEl: PopperProps['anchorEl']
  targetEl: PopperProps['anchorEl']
}

export const getHitAreaData = ({ placement = 'bottom', anchorEl, targetEl }: GetHitAreaDataArgs) => {
  if (!anchorEl || !targetEl) {
    return undefined
  }

  const anchor = (typeof anchorEl === 'function' ? anchorEl() : anchorEl) as HTMLElement | SVGSVGElement
  const target = (typeof targetEl === 'function' ? targetEl() : targetEl) as HTMLElement | SVGSVGElement

  const anchorRect = (anchor as SVGSVGElement).getBBox?.() || anchor.getBoundingClientRect()
  const targetRect = (target as SVGSVGElement).getBBox?.() || target.getBoundingClientRect()

  const prefix = placement.split('-')[0]

  const clampDistance = 100

  if (prefix === 'left') {
    const maxTopY = anchorRect.y - clampDistance
    const maxBottomY = anchorRect.y + anchorRect.height + clampDistance

    return `
    M ${anchorRect.right} ${anchorRect.y}
    Q ${anchorRect.x} ${anchorRect.y},
      ${targetRect.x + targetRect.width} ${Math.max(targetRect.y, maxTopY)}
    V ${Math.min(targetRect.y + targetRect.height, maxBottomY)}
    Q ${anchorRect.x} ${anchorRect.bottom},
      ${anchorRect.x + anchorRect.width} ${anchorRect.bottom}
    h ${-anchorRect.width}
    v ${-anchorRect.height}
    z`
  }

  if (prefix === 'right') {
    const maxTopY = anchorRect.y - clampDistance
    const maxBottomY = anchorRect.y + anchorRect.height + clampDistance

    return `
    M ${anchorRect.x} ${anchorRect.y}
    Q ${anchorRect.x + anchorRect.width} ${anchorRect.y},
      ${targetRect.x} ${Math.max(targetRect.y, maxTopY)}
    V ${Math.min(targetRect.y + targetRect.height, maxBottomY)}
    Q ${anchorRect.x + anchorRect.width} ${anchorRect.bottom},
      ${anchorRect.x} ${anchorRect.bottom}
    h ${anchorRect.width}
    v ${-anchorRect.height}
    z`
  }

  if (prefix === 'bottom') {
    const maxLeftX = anchorRect.x - clampDistance
    const maxRightX = anchorRect.x + anchorRect.width + clampDistance

    return `
    M ${anchorRect.x} ${anchorRect.y}
    Q ${anchorRect.x} ${anchorRect.y + anchorRect.height},
      ${Math.max(targetRect.x, maxLeftX)} ${targetRect.y}
    H ${Math.min(targetRect.x + targetRect.width, maxRightX)}
    Q ${anchorRect.x + anchorRect.width} ${anchorRect.y + anchorRect.height},
      ${anchorRect.x + anchorRect.width} ${anchorRect.y}
    v ${anchorRect.height}
    h ${-anchorRect.width}
    z`
  }

  if (prefix === 'top') {
    const maxLeftX = anchorRect.x - clampDistance
    const maxRightX = anchorRect.x + anchorRect.width + clampDistance

    return `
    M ${anchorRect.x} ${anchorRect.y + anchorRect.height}
    Q ${anchorRect.x} ${anchorRect.y},
      ${Math.max(targetRect.x, maxLeftX)} ${targetRect.y + targetRect.height}
    H ${Math.min(targetRect.x + targetRect.width, maxRightX)}
    Q ${anchorRect.x + anchorRect.width} ${anchorRect.y},
      ${anchorRect.x + anchorRect.width} ${anchorRect.y + anchorRect.height}
    v ${-anchorRect.height}
    h ${-anchorRect.width}
    z`
  }
}

export interface GetPopperTransformOriginArgs {
  placement: PopperProps['placement']
  anchorEl: PopperProps['anchorEl']
  targetEl: PopperProps['anchorEl']
}

export const getPopperTranformOrigin = ({ placement = 'bottom', anchorEl, targetEl }: GetPopperTransformOriginArgs) => {
  if (!anchorEl || !targetEl) {
    return '0 0 0'
  }

  const anchor = (typeof anchorEl === 'function' ? anchorEl() : anchorEl) as HTMLElement | SVGSVGElement
  const target = (typeof targetEl === 'function' ? targetEl() : targetEl) as HTMLElement | SVGSVGElement

  const anchorMidX = anchor.getBoundingClientRect().x + anchor.getBoundingClientRect().width / 2
  const anchorMidY = anchor.getBoundingClientRect().y + anchor.getBoundingClientRect().height / 2

  const prefix = placement.split('-')[0]

  if (prefix === 'bottom') {
    return `${anchorMidX - target.getBoundingClientRect().x}px 0 0`
  }

  if (placement === 'bottom-start') {
    return `0 0 0`
  }

  if (placement === 'bottom-end') {
    return `100% 0 0`
  }

  if (prefix === 'top') {
    return `${anchorMidX}px 100% 0`
  }

  if (placement === 'top-start') {
    return `0 100% 0`
  }

  if (placement === 'top-end') {
    return `100% 100% 0`
  }

  if (prefix === 'left') {
    return `100% ${anchorMidY}px 0`
  }

  if (prefix === 'left-start') {
    return `100% 0 0`
  }

  if (prefix === 'left-end') {
    return `100% 100% 0`
  }

  if (prefix === 'right') {
    return `0 ${anchorMidY - target.getBoundingClientRect().y}px 0`
  }

  if (prefix === 'right-start') {
    return `0 0 0`
  }

  if (prefix === 'right-end') {
    return `0 100% 0`
  }

  return '0 0 0'
}
